<div class="py-4 px-2 px-md-4 position-relative">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <h4 class="text-bold pl-0 my-3">
    Eliminar tipo de GPS
  </h4>
  <app-select-gps [inputFormControl]="selectedGps" [validate]="validate"></app-select-gps>
  <div class="button-container">
    <button mat-raised-button class="primary-secondary button-width-auto" color="primary" (click)="dialogRef.close()"
      type="button">
      Cancelar
    </button>
    <button mat-raised-button color="primary" (click)="deleteGps()" class="button-width-auto bg-color-primary">
      Eliminar
    </button>
  </div>
</div>