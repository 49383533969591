<div class="row container-filter w-100 pr-3 align-items-center">
  <div class="col-12 col-lg-6 col-xl-2 d-flex align-items-center field-container">
    <mat-form-field appearance="outline" class="plate-field">
      <mat-label>Placa</mat-label>
      <input type="text" appLicensePlate matInput [formControl]="licensePlate">
      <i *ngIf="showCleanLicensePlate" matSuffix class="fas fa-times-circle" matTooltip="Borrar campo"
        (click)="cleanLicensePlate()"></i>
      <span *ngIf="!showCleanLicensePlate" class="input-icon d-flex align-items-center justify-content-around"
        matTooltip="Buscar placa" (click)="resetFilter()"><i class="fas fa-search"></i></span>
    </mat-form-field>
  </div>
  <div class="col-12 col-lg-6 col-xl-2 field-container">
    <app-autocomplete-city [inputFormControl]="cityControlOrigin" [options]="optionsCityOrigin"
      [validate]="validateCityOrigin">
    </app-autocomplete-city>
  </div>
  <div class="col-12 col-lg-6 col-xl-2 field-container">
    <app-autocomplete-city [inputFormControl]="cityControlDestination" [options]="optionsCityDestination"
      [validate]="validateCityDestination">
    </app-autocomplete-city>
  </div>

  <div class="col-12 col-lg-6 col-xl-2 d-flex align-items-center field-container">
    <mat-form-field class="date-field" appearance="outline">
      <mat-label>Desde</mat-label>
      <input matInput (click)="pickerLoadDate.open()" [matDatepickerFilter]="myFilter" [matDatepicker]="pickerLoadDate"
        [(formControl)]="filterDateStartInput" (dateChange)="onChangeDate('loadDate', $event)" readonly
        class="cursor-pointer">
      <i *ngIf="filterDateStartInput && filterDateStartInput.value" matSuffix class="fas fa-times-circle"
        matTooltip="Borrar campo" (click)="cleanDate()"></i>
      <mat-datepicker-toggle matPrefix [for]="pickerLoadDate">
      </mat-datepicker-toggle>
      <mat-datepicker #pickerLoadDate></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="col-12 col-lg-6 col-xl-2 d-flex align-items-center field-container">
    <mat-form-field class="date-field" appearance="outline">
      <mat-label>Hasta</mat-label>
      <input matInput (click)="pickerEndDate.open()" [min]="endDateOptions.min" [matDatepicker]="pickerEndDate"
        [(formControl)]="filterDateEndInput" [readonly]="utils.isEmpty(filterDateStartInput.value)"
        (dateChange)="onChangeDate('endDate', $event)" class="cursor-pointer">
      <i *ngIf="filterDateEndInput && filterDateEndInput.value" matSuffix class="fas fa-times-circle"
        matTooltip="Borrar campo" (click)="cleanDate()"></i>
      <mat-datepicker-toggle matPrefix [for]="pickerEndDate"></mat-datepicker-toggle>
      <mat-datepicker #pickerEndDate></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="button-container col-12 col-xl-2 d-flex justify-content-around align-items-start align-self-start">
    <button mat-raised-button class="secondary-sharp clean-filter" color="primary" (click)="cleanFilter()"
      matTooltip="Eliminar Filtros">
      <i class="fas fa-trash"></i>
    </button>
    <div class="d-flex justify-content-center align-items-center">
      <!-- <button type="button" class="btn btn-primary text-bold" (click)="moreFilters(true)"><i class="fas fa-filter mr-3"></i>Más filtros<span class="badge badge-danger ml-2">5</span></button> -->
      <button type="button" class="btn btn-primary text-bold" (click)="moreFilters(true)">
        <div class="icon-container">
          <i class="fas fa-filter"></i>
          <span *ngIf="filledInputs.data > 0 && filledInputs.display" class="badge"
            matTooltip="Número de filtros aplicados">{{filledInputs.data}}</span>
        </div>
      </button>
    </div>
  </div>
</div>