<div id="container" class="col-md-12S md-md-0 md-10 h-100-margin">
  <section class="container-list h-100-margin">
    <header class="container-header mb-3">
      <div class="title-component no-margin mr-5">
        <i class="fas fa-mail-bulk icon-mail "></i>
        {{ dialogParams.type === 'create' ? 'Crear Enturnamiento' : 'Editar Enturnamiento' }}
      </div>
      <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="close(false)"></i>
    </header>
    <article id="content">
      <form class="form_container row" (ngSubmit)="onSubmit()" [formGroup]="enturnamientoForm">
        <div class="col-md-4">
          <app-autocomplete-city [inputFormControl]="cityControlOrigin" [options]="optionsCityOrigin"
            [validate]="validateCityOrigin">
          </app-autocomplete-city>
        </div>
        <div class="col-md-4">
          <app-autocomplete-city [inputFormControl]="cityControlDestination" [options]="optionsCityDestination"
            [validate]="validateCityOrigin">
          </app-autocomplete-city>
        </div>
        <div class="col-md-4 license-plate">
          <mat-form-field appearance="standard">
            <mat-label>Placa del Vehículo<span class="asterisk">*</span></mat-label>
            <input appLicensePlate type="text" matInput id="vehicleField" [matAutocomplete]="auto"
              [formControl]="vehicleControl">
            <mat-error *ngIf="vehicleControl.errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-hint class="custom-hint" *ngIf="!vehicleControl.errors?.required">
              *Seleccione una placa válida de las opciones. Si no existe oprima el botón
              <b>Crear vehículo</b>
            </mat-hint>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
              <mat-option *ngFor="let vehicle of listVehicles | async" [value]="vehicle.id"
                data-namedriver="{{vehicle.driver.name || 'a'}}"
                data-vehicletype="{{vehicle.vehicleType && vehicle.vehicleType.name ? vehicle.vehicleType.name : null}}"
                data-bodyworkType="{{vehicle.bodywork && vehicle.bodywork.description ? vehicle.bodywork.description : ''}}"
                data-iddriver="{{vehicle.driver.document}}"
                data-numberOfTrips="{{vehicle.numberOfTrips ? vehicle.numberOfTrips : 0}}">
                <span>
                  {{vehicle.id}}
                </span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-md-4 volume-input">
          <mat-form-field appearance="standard">
            <mat-label>Capacidad en volumen</mat-label>
            <input matInput mask="separator.0" thousandSeparator="." placeholder="Por ej. 100"
              formControlName="loadCapacityVolume">
            <mat-hint class="custom-hint">Valores de volumen en metros cúbicos</mat-hint>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>Fecha de disponibilidad<span class="asterisk">*</span></mat-label>
            <input matInput [matDatepickerFilter]="myFilter" [matDatepicker]="datepicker" formControlName="date"
              readonly>
            <mat-error *ngIf="enturnamientoForm.get('date').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-datepicker-toggle matSuffix [for]="datepicker">
            </mat-datepicker-toggle>
            <mat-datepicker #datepicker></mat-datepicker>
          </mat-form-field>
        </div>
      </form>
    </article>
    <footer id="footer" class="mt-2">
      <div class="col-md-12 d-flex flex-row justify-content-around">
        <mat-card-actions class="card-actions button-center">
          <button mat-raised-button color="primary" (click)="onSubmit()" class="button-width-auto">
            {{ dialogParams.type === 'create' ? 'Guardar' : 'Actualizar' }}
          </button>
        </mat-card-actions>
        <mat-card-actions class="card-actions button-center" *ngIf="notVehicle">
          <button mat-raised-button color="primary" (click)="validateVehicle(vehicleControl.value)"
            class="button-width-auto">
            Crear vehículo
          </button>
        </mat-card-actions>
      </div>
      <span class=" separator-horizontal"></span>
    </footer>
  </section>
</div>