import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileStorage } from 'src/app/core/interfaces/fileStorage';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { VehiclesService } from '../list-vehicles.service';
import { VehicleDocumentCatalog, VehicleDocuments } from 'src/app/core/interfaces/vehicleDocuments';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { MatDialog, MatDialogConfig, MatSelectChange } from '@angular/material';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { VehicleExtraDocument } from 'src/app/core/interfaces/vehicleExtraDocument';
import mime from 'src/assets/mimetypes.json';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { environment } from 'src/environments/environment';
import { FileNamePipe } from 'src/app/core/pipe/fileName.pipe';
import { DatePipe } from '@angular/common';
import { Patterns } from 'src/app/core/resources/patterns';
import { Subscription } from 'rxjs';
import { Global } from 'src/app/core/resources/global';
@Component({
  selector: 'app-vehicle-documents',
  templateUrl: './vehicle-documents.component.html',
  styleUrls: ['./vehicle-documents.component.scss'],
  providers: [FileNamePipe, DatePipe]
})
export class VehicleDocumentsComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() hideBtn: boolean;
  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  @ViewChild('inputLeftLateralPicture', { static: false }) inputLeftLateralPicture: ElementRef;
  @ViewChild('inputRightLateralPicture', { static: false }) inputRightLateralPicture: ElementRef;
  @ViewChild('inputFrontalImage', { static: false }) inputFrontalImage: ElementRef;
  @ViewChild('inputPropertyCard', { static: false }) inputPropertyCard: ElementRef;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef<HTMLInputElement>;
  public validated: FormControl = new FormControl(false);
  public instanceLogo: FileStorage;
  public selectDocumentView: { name: string, value: string, mandatory: boolean };
  public nameFileSelected: string;
  public currentIndexExtra: string = null;
  public path: string = '';
  public vehicle: Vehicle = null;
  vehicleSub: Subscription;
  public mustBeImages: string[] =
    [
      'Fotografía frontal',
      'Fotografía lateral izquierdo',
      'Fotografía lateral derecho'
    ];
  public listTypeDocuments: { value: string, name: string, mandatory: boolean }[] = [];

  constructor(
    public utils: Utils,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private angularFireStorage: AngularFireStorage,
    private vehiclesService: VehiclesService,
    public matDialog: MatDialog,
    private authService: AuthService,
    public global: Global,
    private permissionRole: PermissionRole,
    public fileNamePipe: FileNamePipe,
    public datePipe: DatePipe,
    public patterns: Patterns
  ) { }

  /**
  * @description Updates the listTypeDocuments, executes the getVehicle method with vehicle's licenseplate by form, and makes a subscription to update them on changes
  */
  ngOnInit() {
    this.getDocumentTypes();
    this.getVehicle(this.form.get('vehicle.id').value);
    this.vehicleSub = this.form.get('vehicle.id').valueChanges.subscribe(
      licensePlate => {
        this.getVehicle(licensePlate);
      }
    );
  }

  private async getDocumentTypes() {
    try {
      const documents = await this.vehiclesService.checkDocumentRules(this.vehicle);
      this.listTypeDocuments = documents && documents.length ? documents.sort((a, b) => {
        if (a.mandatory && !b.mandatory) return -1;
        else if (!a.mandatory && b.mandatory) return 1;
        else if (a.name !== a.value && b.name === b.value) return -1;
        else if (a.name === a.value && b.name !== b.value) return 1;
        return 0;
      }) : [];
    } catch (error) {
      this.listTypeDocuments = [];
    }
  }

  /**
  * @param {string} licensePlate is the license plate to obtain a vehicle
  * @description Gets the vehicle by license plate and updates the vehicle local variable and its extraImages
  */
  private getVehicle(licensePlate: string) {
    this.vehiclesService
      .getVehicle(licensePlate)
      .subscribe(
        (vehicle: Vehicle) => {
          !!vehicle ? this.vehicle = vehicle : this.vehicle = this.form.get('vehicle').value;
          if (!this.utils.isDefined(this.vehicle.extraImages) || !Array.isArray(this.vehicle.extraImages)) {
            this.vehicle.extraImages = [];
          }
        }
      );
  }

  /**
  * @param {{name:string, value: string, mandatory:boolean}} doc is the document to check
  * @returns {boolean} returns true if the document is static, otherwise false
  * @description Verifies if the current document is static
  */
  isStaticDocument(doc: { name: string, value: string, mandatory: boolean }): boolean {
    return this.global.listTypeDocuments.some(typeDoc => doc && typeDoc.name === doc.value);
  }

  /**
  * @param {{name:string, value: string, mandatory:boolean}} doc is the document to check
  * @returns {boolean} returns true if the document is uploaded, otherwise false
  * @description Verifies if the current document is uploaded already
  */
  isUploadedDocument(doc: { name: string, value: string, mandatory: boolean }): boolean {
    if (!this.vehicle) return false;
    if (this.isStaticDocument(doc)) return this.vehicle[doc.value];
    return this.vehicle.extraImages && this.vehicle.extraImages.some(img => img.type && img.type.name === doc.name && !!img.path);
  }

  /**
  * @param {{name:string, value: string, mandatory:boolean}} doc is the document to check
  * @returns {boolean} returns true if the document is validated, otherwise false
  * @description Verifies if the current document is validated already
  */
  isValidatedDocument(doc: { name: string, value: string, mandatory: boolean }): boolean {
    if (!this.vehicle) return false;
    if (this.isStaticDocument(doc)) return this.vehicle[doc.value];
    else return this.vehicle.extraImages && this.vehicle.extraImages.some(img => img.type && img.type.name === doc.name && img.validated);
  }

  /**
  * @description Gets the document selected and users the method translateFileName if is already uploaded
  */
  public onChangeTypeDocument() {
    this.path = '';
    this.validated.setValue(false);
    this.nameFileSelected = null;
    this.fileInput && this.fileInput.nativeElement ? this.fileInput.nativeElement.value = '' : undefined;

    // Documentos estáticos
    if (this.selectDocumentView && this.isStaticDocument(this.selectDocumentView)) {
      const value = this.vehicle[this.selectDocumentView.value];
      if (value) this.traslateFileName(value);
    } else {
      //Documentos adicionales
      const extra = this.getExtraDocumentByName(this.selectDocumentView.name);
      if (extra.document) {
        this.traslateFileName(extra.document.path);
        this.validated.setValue(extra.document.validated);
      } else this.path = 'notLoadedFile';
      this.currentIndexExtra = extra.index;
    }
  }

  /**
  * @param {string} fileName is the document's name to check
  * @description Translates the document's name to legible name to puts it on nameFileSelected variable
  */
  private traslateFileName(fileName: string) {
    this.path = fileName;
    const nameSplit = fileName.split('/');
    const fileNameFormatted = nameSplit.length > 0 ? nameSplit[nameSplit.length - 1] : nameSplit[0];
    if (this.patterns.FILE_FORMAT_FULL.test(fileNameFormatted) || this.patterns.FILE_FORMAT.test(fileNameFormatted)) this.nameFileSelected = this.userViewFileName(fileNameFormatted);
    else this.nameFileSelected = fileName;
  }

  /**
  * @param {string} filename is the file's name to check
  * @returns {Promise<string>} returns an url to download the file expected, or an error message
  * @description Gets the ur to download a file by its name
  */
  private async getURLDocument(filename: string): Promise<string> {
    const storage = AuthService.fStorage;
    const pathReference = storage.ref(filename);
    this.spinner.show();
    let result: string = null
    await pathReference.getDownloadURL().then(
      (url) => {
        this.spinner.hide();
        result = url;
      },
      (error) => {
        this.spinner.hide();
        result = error;
      }
    );
    return result;
  }

  /**
  * @description Iterates by every document to get the path and downloads them
  */
  public downloadAll() {
    for (const i in this.listTypeDocuments) {
      const isExtra = !this.listTypeDocuments[i].name;
      const document = (!isExtra ? this.listTypeDocuments[i].name : this.listTypeDocuments[i].name);

      let path = !!this.vehicle[document] ? this.vehicle[document] : null;
      if (isExtra) {
        const extraDoc = this.getExtraDocumentByName(document);
        path = !!extraDoc.document ? extraDoc.document.path : null;
      }

      if (!!path)
        this.download(path, this.listTypeDocuments[i].name);
    }
  }

  /**
  * @param {string} document is the document to check
  * @returns {{ document: VehicleExtraDocument, index: string }} returns the document and index of the expected document if exists in vehicle.extraImages
  * @description Gets a document and its index if it exists in vehicle
  */
  private getExtraDocumentByName(document: string): { document: VehicleExtraDocument, index: string } {
    for (const i in this.vehicle.extraImages) {
      if (document == this.vehicle.extraImages[i].type.name)
        return { document: this.vehicle.extraImages[i], index: i };
    }
    return { document: null, index: '-1' };
  }

  /**
  * @param {string} path is the path of the document to download
  * @param {string} name is the name of the document to download
  * @description Downloads a document and opens it to another tab
  */
  private async download(path: string, name: string): Promise<void> {
    const url = await this.getURLDocument(path);
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = () => {
      const blob = xhr.response;
      const mimetype = blob.type;
      const ext = mime.mime2extension[mimetype];
      const url = window.URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      anchor.setAttribute("href", url);
      anchor.setAttribute("download", ext ? `${this.vehicle.id}-${name}.${ext}` : url);
      anchor.removeAttribute("onclick");
      anchor.click();

      window.URL.revokeObjectURL(url);
    };
    xhr.open("GET", url);
    xhr.send();
  }

  /**
  * @description Downloads the current document if exists
  */
  public async downloadCurrentFile() {
    if (this.nameFileSelected) {
      this.download(this.nameFileSelected, this.selectDocumentView.value);
    } else this.snackBarService.openSnackBar('Debe seleccionar un archivo', undefined, 'alert');
  }

  /**
  * @param {Event} e is the event with selected document from input file
  * @description Opens a dialog to confirm the changing of current file, or changes it if the current file doesn't exists with submit method
  */
  onFileSelected(e: Event) {
    if (this.nameFileSelected) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: '¿Realmente desea reemplazar el archivo actual?',
        showYesBtn: true,
        showNoBtn: true,
        hideBtnCancel: true,
      };
      dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
      dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
      dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
      dialogConfig.autoFocus = false;
      const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        this.submit(result, e);
      })
    } else {
      this.submit({ state: true }, e);
    }
  }

  /**
  * @param {{state?:boolean}} result is the decision to upload or not the file
  * @param {Event} e is the event with the file from input
  * @description Uploads a file if result.state is true, and updates it into vehicle
  */
  private submit(result: { state?: boolean }, e: Event) {
    if (result && result.state) {
      this.spinner.show();
      const splittedNameFile = e.target['files'][0]['name'].split('.');
      const formatFile = splittedNameFile[splittedNameFile.length - 1];
      const dateInMs = new Date().getTime();
      const fileName = `${this.selectDocumentView && this.selectDocumentView.name === this.selectDocumentView.value ? this.selectDocumentView.name : 'vehicleDocument'}_${dateInMs}.${formatFile}`;
      const modifiedFile = new File([e.target['files'][0]], fileName, {
        type: e.target['files'][0].type
      });
      const file = modifiedFile as File;
      if (
        this.mustBeImages.indexOf(this.selectDocumentView.name) !== -1 &&
        file.type.indexOf('image') === -1
      ) {
        this.snackBarService.openSnackBar(`El archivo para "${this.selectDocumentView.name}" tiene que ser una imágen, el formato no es compatible`, undefined, "error");
        return;
      }

      const path = `vehicle/${this.vehicle.id}/${fileName}`
      this.instanceLogo = {
        fileData: {
          file: file,
          name: fileName,
          uploaded: true,
          size: this.utils.bytesToSize(file.size),
          url: null
        },
        storageData: {
          storageRef: this.angularFireStorage.ref(path),
          uploadTask: this.angularFireStorage.upload(path, file),
          uploadProgress: null
        }
      };

      this.instanceLogo.storageData.uploadTask.then(
        () => {
          this.instanceLogo.storageData.storageRef.getDownloadURL().subscribe(
            () => {
              if (this.selectDocumentView && this.isStaticDocument(this.selectDocumentView)) {
                this.vehicle[this.selectDocumentView.value] = path;
                this.form.get(`vehicle.${this.selectDocumentView.value}`).setValue(path);
                const data: VehicleDocuments = {
                  propertyCard: this.vehicle.propertyCard,
                  frontPicture: this.vehicle.frontPicture,
                  rightLateralPicture: this.vehicle.rightLateralPicture,
                  leftLateralPicture: this.vehicle.leftLateralPicture
                }
                this.vehiclesService
                  .updateVehicleDocuments(this.vehicle.id, data)
                  .subscribe((success) => {
                    this.snackBarService.openSnackBar('Los documentos han sido modificados satisfactoriamente', undefined, 'success');
                    this.traslateFileName(path);
                    this.spinner.hide();
                    this.emitToParent.emit(success);
                  }, () => {
                    this.spinner.hide();
                    this.snackBarService.openSnackBar('Ha ocurrido un error al guardar los elementos en el servidor', undefined, 'error');
                  });
              } else {
                this.validated.setValue(false);
                const extraImages: VehicleExtraDocument = {
                  type: {
                    id: this.selectDocumentView.value,
                    name: this.selectDocumentView.name,
                  },
                  path: path,
                  validated: this.validated.value
                };

                if (!Array.isArray(this.vehicle.extraImages)) {
                  this.vehicle.extraImages = [];
                }

                if (!Array.isArray(this.form.get('vehicle.extraImages').value)) {
                  this.form.get('vehicle.extraImages').setValue([]);
                }


                if (this.currentIndexExtra !== '-1') {
                  this.vehicle.extraImages[parseInt(this.currentIndexExtra)] = extraImages;
                } else {
                  const index = this.vehicle.extraImages.push(extraImages) - 1;
                  this.currentIndexExtra = `${index}`;
                }
                this.form.get('vehicle.extraImages').patchValue(this.vehicle.extraImages);
                this.vehiclesService
                  .saveExtraDocs(this.vehicle.id, extraImages)
                  .subscribe((success) => {
                    this.snackBarService.openSnackBar('Los documentos han sido modificados satisfactoriamente', undefined, 'success');
                    this.traslateFileName(path);
                    this.spinner.hide();
                    this.emitToParent.emit(success);
                  }, () => {
                    this.spinner.hide();
                    this.snackBarService.openSnackBar('Ha ocurrido un error al guardar los elementos en el servidor', undefined, 'error');
                  });
              }
            }
          );
        },
        () => {
          this.snackBarService.openSnackBar('Ha ocurrido un error al cargar el archivo a firestore', undefined, 'error');
          this.spinner.hide();
        }
      );
    } else {
      (document.getElementById('file') as HTMLInputElement).value = '';
    }
  }

  /**
  * @param {MatSelectChange} e is the event with the validate value selected
  * @description Updates the vehicle's documents validations
  */
  setValidation(e: MatSelectChange) {
    if (this.selectDocumentView && this.isStaticDocument(this.selectDocumentView))
      return;

    if (this.currentIndexExtra == '-1') {
      this.onChangeTypeDocument();
      return;
    }

    this.validated.setValue(e.value);
    this.vehicle.extraImages[this.currentIndexExtra].validated = this.validated.value;
    this.form.get('vehicle.extraImages').patchValue(this.vehicle.extraImages);
    this.vehiclesService
      .saveExtraDocs(this.vehicle.id, this.vehicle.extraImages[this.currentIndexExtra])
      .subscribe((success) => {
        this.snackBarService.openSnackBar('Se ha realizado la validación del documento', undefined, 'success');
        this.spinner.hide();
        this.emitToParent.emit(success);
      }, () => {
        this.snackBarService.openSnackBar('No se ha podido validar el documento por un error interno del servidor', undefined, 'error');
        this.spinner.hide();
      });
  }

  /**
  * @returns {boolean} returns true if the user has the permission "validateVehicleDocuments", otherwise false
  * @description Checks if user has permission to validate documents
  */
  get hasPermissionToValidateDocuments(): boolean {
    return this.permissionRole.hasPermission(Permission.administration.module, Permission.administration.validateVehicleDocuments);
  }

  /**
  * @param {string} fileName is the name of the file to transform
  * @returns {string} returns the file's name as legible name
  * @description Transforms a file's name to legible name
  */
  private userViewFileName(fileName: string): string {
    return this.fileNamePipe.transform(fileName);
  }

  ngOnDestroy() {
    if (this.vehicleSub) this.vehicleSub.unsubscribe();
  }
}
