import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Gps } from 'src/app/core/interfaces/gps';
import { User } from 'src/app/core/interfaces/user';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { GpsTypePipe } from 'src/app/core/pipe/gpsType.pipe';
import { Utils } from 'src/app/core/resources/utils';
import { VehicleManager } from 'src/app/core/managers/vehicle.manager';
import { MatDialog } from '@angular/material';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { AuthService } from 'src/app/core/services/authentication.service';

@Component({
  selector: 'app-driver-summary',
  templateUrl: './driver-summary.component.html',
  styleUrls: ['./driver-summary.component.scss']
})
export class DriverSummaryComponent {

  @Input() driver: User;
  @Input() vehicle: Vehicle;
  public vehicleManager: VehicleManager;
  public permission = Permission;

  public showPeopleCards: boolean = false;

  constructor(
    public utils: Utils,
    private gpsTypePipe: GpsTypePipe,
    public dialog: MatDialog,
    public permissionRole: PermissionRole,
    public snackBar: SnackBarService
  ) {
    this.vehicleManager = new VehicleManager(
      dialog,
      utils
    );
  }
  /**
  * This method listen the changes related with the driver.
  * @param {Date | string} changes (Date or string) listen the changes of the driver.
  */
  private ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.driver && changes.driver.currentValue) {
      this.getURlPictureDriver();
    }
  }
  /**
  * This method open in a new window the page of the gps related.
  * @param {Gps} gps (Gps) is the information of the gps.
  */
  public openGPSSite(gps: Gps): void {
    if (!this.utils.isEmpty(gps) && !this.utils.isEmpty(gps.gpsType)) {
      const gpsUrl = this.gpsTypePipe.transform(gps.gpsType);
      if (!this.utils.isEmpty(gpsUrl)) {
        window.open(gpsUrl, '_blank');
      }
    }
  }
  /**
  * This method consumes a service that brings the url of the picture of the driver.
  */
  private getURlPictureDriver() {
    const storage = AuthService.fStorage;
    if (this.utils.isDefined(this.driver) && !this.utils.isDefined(this.driver.profilePictureUrl) && this.utils.isDefined(this.driver.profilePicture)) {
      const pathReference = storage.ref(this.driver.profilePicture);
      pathReference.getDownloadURL().then(
        (data) => {
          this.driver.profilePictureUrl = data;
        },
        (error) => {
          this.driver.profilePictureUrl = '';
        }
      );
    }
  }
  /**
  * This method copy to clipboard the text related.
  * @param {string} value (string) is the data to copy.
  */
  public copy(value: string) {
    navigator.clipboard.writeText(value);
    this.snackBar.openSnackBar('Copiado al portapapeles', undefined, "info");
  }

  public get showPersonalInformation() {
    return this.permissionRole.hasPermission(Permission.cargo.module, Permission.cargo.readDriverDetails);
  }

  get hasSatrackGps(): boolean {
    return this.vehicle && this.vehicle.gps && this.vehicle.gps.gpsType
      && this.vehicle.gps.gpsType.toLowerCase() === 'satrack';
  }

}
