import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { MatButtonToggleChange, MatDialog, MatDialogConfig, MatSelectChange } from '@angular/material';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';
import { CalculatorMeasurementsComponent } from '../calculator-measurements/calculatorMeasurements.component';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { FormArray, Validators } from '@angular/forms';
import { Utils } from 'src/app/core/resources/utils';

@Component({
  selector: 'app-load-measurements',
  templateUrl: './load-measurements.component.html',
  styleUrls: ['./load-measurements.component.scss'],
  providers: [AuthService, Global]
})
export class LoadMeasurementsComponent implements OnInit {

  panelOpenState = true;
  public dataVehicle: any;
  minQuantity: number = 1;
  minTotalMeasurement: number = 1;
  maxTotalMeasurement: number = 0;
  minTotalWeigth: number = 1;
  minTotalAmount: number = 1;
  maxTotalAmount: number = 0;
  constructor(
    public dialogMaterial: MatDialog,
    public snackBarService: SnackBarService,
    public manualCreationCargoService: ManualCreationCargoService,
    private router: Router,
    public utils: Utils
  ) {

  }

  ngOnInit() {
    this.setValidators();
    this.getPesoVehicule();
  }
  setValidators() {
    for (let control of this.manualCreationCargoService.formDestinations.controls) {
      let arrayAddresses = control.get('addresses') as FormArray;
      for (let addressControl of arrayAddresses.controls) {
        addressControl.get('cargoMeasure.quantity').setValidators([Validators.required, Validators.min(this.minQuantity)]);
        if (!this.isEmptyContainer) {
          addressControl.get('cargoMeasure.totalMeasurement').setValidators([Validators.required, Validators.min(this.minTotalMeasurement)]);
          addressControl.get('cargoMeasure.totalWeigth').setValidators([Validators.required, Validators.min(this.minTotalWeigth), Validators.max(
            addressControl.get('cargoMeasure.unit').value === '1' ? AmountsCargoEnum.MAX_WEIGHT_ALLOWED_KG : AmountsCargoEnum.MAX_WEIGHT_ALLOWED_GL
          )])
          addressControl.get('cargoMeasure.amount').setValidators([Validators.required, Validators.min(this.minTotalAmount)]);
        }
        else {
          addressControl.get('cargoMeasure.totalMeasurement').setValidators([Validators.required, Validators.max(this.maxTotalMeasurement)]);
          addressControl.get('cargoMeasure.totalWeigth').setValidators([Validators.required, Validators.max(0)]);
          addressControl.get('cargoMeasure.amount').setValidators([Validators.required, Validators.max(this.maxTotalAmount)]);
        };
      }
    }
  }
  public getPesoVehicule() {
    this.manualCreationCargoService.getVehicleWeightById('').subscribe(response => {
      this.dataVehicle = response;
    })
  }

  onChangeUnit($event, iDestination: number, indexAddress: number) {
    if ($event.value === '1' && !this.isEmptyContainer) {
      this.manualCreationCargoService.formDestinations.controls[iDestination]['controls'].addresses.controls[indexAddress].get('cargoMeasure.totalWeigth').setValidators([Validators.required, Validators.min(this.minTotalWeigth), Validators.max(AmountsCargoEnum.MAX_WEIGHT_ALLOWED_KG)])
      this.manualCreationCargoService.formDestinations.controls[iDestination]['controls'].addresses.controls[indexAddress].get('cargoMeasure.totalWeigth').updateValueAndValidity();
    } else if ($event.value === '2' && !this.isEmptyContainer) {
      this.manualCreationCargoService.formDestinations.controls[iDestination]['controls'].addresses.controls[indexAddress].get('cargoMeasure.totalWeigth').setValidators([Validators.required, Validators.min(this.minTotalWeigth), Validators.max(AmountsCargoEnum.MAX_WEIGHT_ALLOWED_GL)]);
      this.manualCreationCargoService.formDestinations.controls[iDestination]['controls'].addresses.controls[indexAddress].get('cargoMeasure.totalWeigth').updateValueAndValidity();
    }
  }

  onChangeMeasureType($event: MatButtonToggleChange, iDestination: number, indexAddress: number) {
    this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].uploadDownload.controls.destination
      .controls[iDestination].controls.addresses.controls[indexAddress].controls.cargoMeasure.controls.type.setValue(
        $event.value
      );
  }

  get AmountsCargoEnum() {
    return AmountsCargoEnum
  }

  nextStep() {
    if (this.manualCreationCargoService.isValidDownloadAddressesCargoMeasure() || this.manualCreationCargoService.formDestinations.valid) {
      this.addressRouting();
      this.manualCreationCargoService.getComponentActive().setId(3);
      this.manualCreationCargoService.getComponentActive().getStep().setId(1);
      this.router.navigate([this.manualCreationCargoService.steps.upload.url]);
    } else {
      this.markAllAsTouchedRef();
      if (!this.findErrorOnDestinations()) this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'error');
    }
  }

  addressRouting() {
    for (let control of this.manualCreationCargoService.formDestinations.controls) {
      let arrayAddresses = control.get('addresses') as FormArray;
      for (let addressControl of arrayAddresses.controls) {
        let amount = addressControl.get('cargoMeasure.amount');
        amount.setValue(Number(amount.value));
      }
    }
  }

  findErrorOnDestinations() {
    let haveErrors = false;
    for (let [i, control] of this.manualCreationCargoService.formDestinations.controls.entries()) {
      let arrayAddresses = control.get('addresses') as FormArray;
      for (let [j, addressControl] of arrayAddresses.controls.entries()) {
        if (!haveErrors) {
          if (this.utils.errorMessagesCustomized(addressControl.get('cargoMeasure.quantity'), `cantidad del destino ${i + 1} de la dirección ${j + 1}`, null, null, this.minQuantity)) {
            haveErrors = true;
            break;
          }
          else if (this.utils.errorMessagesCustomized(addressControl.get('cargoMeasure.totalMeasurement'), `medida total del destino ${i + 1} de la dirección ${j + 1}`, null, null, this.minTotalMeasurement, this.maxTotalMeasurement)) {
            haveErrors = true;
            break;
          }
          else if (this.utils.errorMessagesCustomized(addressControl.get('cargoMeasure.totalWeigth'), `peso total del destino ${i + 1} de la dirección ${j + 1} (medida en ${addressControl.get('cargoMeasure.unit').value === '1' ? 'kilos' : 'galones'})`, null, null, this.minTotalWeigth, this.isEmptyContainer ? 0 : addressControl.get('cargoMeasure.unit').value === '1' ? AmountsCargoEnum.MAX_WEIGHT_ALLOWED_KG : AmountsCargoEnum.MAX_WEIGHT_ALLOWED_GL)) {
            haveErrors = true;
            break;
          }
          else if (this.utils.errorMessagesCustomized(addressControl.get('cargoMeasure.amount'), `valor del servicio del destino ${i + 1} de la dirección ${j + 1}`, null, null, this.minTotalAmount, this.maxTotalAmount)) {
            haveErrors = true;
            break;
          }
        } else break;
      }
      if (haveErrors) break;
    }
    return haveErrors;
  }

  markAllAsTouchedRef() {
    for (let control of this.manualCreationCargoService.formDestinations.controls) {
      let arrayAddresses = control.get('addresses') as FormArray;
      for (let addressControl of arrayAddresses.controls) {
        addressControl.get('cargoMeasure.quantity').markAsTouched();
        addressControl.get('cargoMeasure.totalMeasurement').markAsTouched();
        addressControl.get('cargoMeasure.totalWeigth').markAsTouched();
        addressControl.get('cargoMeasure.amount').markAsTouched();
      }
    }
  }

  openDialogCalculatorMeasurements(iDestination: number, indexAddress: number) {
    const thisClass = this;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialogMaterial.open(CalculatorMeasurementsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(totalSize => {
      if (totalSize) {
        thisClass.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].uploadDownload.controls.destination
          .controls[iDestination].controls.addresses.controls[indexAddress].controls.cargoMeasure.controls.totalMeasurement.setValue(
            totalSize
          );
      }
    });
  }

  get isEmptyContainer() {
    return this.manualCreationCargoService.getCargoForm().get('cargoFeature.productType.name').value === "CONTENEDOR VACIO";
  }

  stepBack() {
    this.manualCreationCargoService.getComponentActive().setId(2);
    this.manualCreationCargoService.getComponentActive().getStep().setId(5);
    this.router.navigate([this.manualCreationCargoService.steps.optional.url]);
  }

}
