import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Router } from '@angular/router';
import { Utils } from 'src/app/core/resources/utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { VehiclesService } from '../list-vehicles.service';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { Company } from 'src/app/core/interfaces/company';
import { BankAccount } from 'src/app/core/interfaces/userBank';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { FormControl } from '@angular/forms';
import { Patterns } from 'src/app/core/resources/patterns';
import { SelectCompanyComponent } from 'src/app/shared/select-company/select-company.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { ConventionsComponent } from 'src/app/shared/conventions/conventions.component';
import { DateManager } from 'src/app/core/managers/date.manager';
import { CatalogItem } from 'src/app/core/interfaces/catalogItem';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';

@Component({
  selector: 'app-list-vehicles',
  templateUrl: './list-vehicles.component.html',
  styleUrls: ['./list-vehicles.component.scss'],
  providers: [AuthService, PermissionRole]
})
export class ListVehiclesComponent implements OnInit {
  @ViewChild(SelectCompanyComponent, { static: false }) selectComp: SelectCompanyComponent;
  showAllCompanies: boolean;
  permission = Permission;
  companyNitCtrl: FormControl = new FormControl();
  company: Company;
  filterlicensePlate: FormControl = new FormControl('');
  vehicleState: FormControl = new FormControl(null);
  stateAccount: FormControl = new FormControl('');
  activeSelectItem = false;
  itemsSelecteds: Array<Vehicle> = [];
  freightList: Array<Vehicle> = [];
  typeregister: 'balance' | 'advance';
  listStateAccountBank = [
    {
      value: '0',
      name: 'Todos'
    },
    {
      value: '1',
      name: 'Anticipo Registrado'
    },
    {
      value: '2',
      name: 'Saldo Registrado'
    },
    {
      value: '3',
      name: 'Anticipo y Saldo Registrado'
    },
    {
      value: '5',
      name: 'Anticipo sin Registrar'
    },
    {
      value: '6',
      name: 'Saldo sin Registrar'
    },
    {
      value: '4',
      name: 'Anticipo y Saldo sin Registrar'
    }

  ];
  optionsCompany = {
    title: 'Compañía',
    initialNit: '',
    appearance: 'outline',
  };
  owner: FormControl = new FormControl('');
  vehicleTypes: CatalogItem[] = [];
  type: FormControl = new FormControl('');
  filtersValues: string = '';
  constructor(
    private authService: AuthService,
    public service: VehiclesService,
    public utils: Utils,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    private router: Router,
    public permissionRole: PermissionRole,
    private patterns: Patterns,
  ) { }

  /**
  * @description Initializes optionsCompany, resets the paginationlist and executes getVehicles
  */
  ngOnInit() {
    this.showAllCompanies = this.authService.userIsFromRootNit();
    this.optionsCompany['optionAll'] = this.showAllCompanies;
    this.optionsCompany.initialNit = this.getInitNit();
    this.getVehicleTypes();
    this.checkStorageFilters();
  }

  /**
  * @description Applies the filters and resets the paginationList
  */
  checkStorageFilters() {
    const filtersValues = sessionStorage.getItem('vehiclesFilters');
    if (filtersValues) {
      const filters = filtersValues.split('&');
      filters.forEach(filter => {
        const [key, value] = filter.split('=');
        if (key === 'companyNitCtrl') this.companyNitCtrl.setValue(JSON.parse(value));
        if (key === 'id') this.filterlicensePlate.setValue(value.trim());
        if (key === 'stateAccount') this.stateAccount.setValue(value);
        if (key === 'state') this.vehicleState.setValue(value === 'true');
        if (key === 'owner') this.owner.setValue(value);
        if (key === 'type') this.type.setValue(value);
      });
    }
    this.applyFilters();
  }

  /**
  * @description Brings the vehicle types list
  */
  getVehicleTypes() {
    const vehicleTypesObserver = {
      next: (data: { catalog: CatalogItem[] }) => {
        this.spinner.hide();
        if (data && data.catalog && data.catalog.length) this.vehicleTypes = data.catalog;
        else this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      },
      error: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      },
    };
    this.spinner.show();
    this.service.getVehicleTypes().subscribe(vehicleTypesObserver);
  }

  /**
  * @returns {string | null} returns the user's company nit if doesn't belong to a principal company, null otherwise
  * @description Gets the initial nit if user doesn't belong to a principal company
  */
  private getInitNit(): string | null {
    if (!this.showAllCompanies) return this.authService.getCompany().companyId;
    return null;
  }

  /**
  * @param {string} licencePlate is the value of the filter by license plate
  * @param {string} bankAccountState is the value of the filter by bank account's state
  * @param {boolean} vehicleState is the value of the filter by vehicle's state
  * @description Gets the list of vehicles with filters applied and assigns it into service.paginationList's list
  */
  private getVehicles(concat?: boolean): void {
    let filtersToSend = this.processFilters();
    this.spinner.show();
    this.service.getListVehicles(
      this.service.paginationList.getPageKey(),
      this.service.paginationList.getPageSize(),
      filtersToSend
    ).subscribe(
      (success: []) => {
        if (!this.utils.isDefined(success) || success.length < 1) {
          this.service.paginationList.setEnablePagingScroll(false);
        } else {
          if (this.service.paginationList.getList().length && concat) {
            this.service.paginationList.setList(
              this.service.paginationList.getList().concat(success));
          } else {
            this.service.paginationList.setList(success);
          }
          if (success.length < this.service.paginationList.getPageSize()) {
            this.service.paginationList.setEnablePagingScroll(false);
          }
        }
        this.spinner.hide();
      },
      (error) => {
        this.service.paginationList.setList([]);
        this.spinner.hide();
      }
    );
  }

  processFilters(): string {
    const companyId: string = this.companyNitCtrl && this.companyNitCtrl.value && this.companyNitCtrl.value.companyId ? this.companyNitCtrl.value.companyId : this.getInitNit();
    const holderCompanyId: string = this.authService.getUserSession() && this.authService.getUserSession().clientCompanyId ? this.authService.getUserSession().clientCompanyId : null;
    let filtersToSend = '';
    if (this.filtersValues) {
      let filters = new URLSearchParams(this.filtersValues);
      if (this.filtersValues.includes('companyNitCtrl')) {
        filters.delete('companyNitCtrl');
      }
      if (this.filtersValues.includes('stateAccount')) {
        filtersToSend += this.service.getAccountState(filters.get('stateAccount'));
        filters.delete('stateAccount');
      }
      filtersToSend += `&${filters.toString()}`;
    }
    if (companyId && companyId !== '-1') filtersToSend += `&companyId=${companyId}`;
    if (holderCompanyId) filtersToSend += `&holderCompany=${holderCompanyId}`;
    return filtersToSend;
  }

  /**
  * @param {'balance'|'advance'} type is the type of the bank account
  * @description Gets the list of vehicles with the param bank account type, and passes it to "getFileForInscriptionAccount" method
  */
  getlistBankAccount(type: 'balance' | 'advance'): void {
    let listObject: Array<BankAccount> = [];
    let vehiclesWithoutAccount: string[] = [];
    this.typeregister = type;
    if (type === 'advance') {
      listObject = this.itemsSelecteds.map((accounts: Vehicle) => {
        if (!accounts.bankAccountAdvance) vehiclesWithoutAccount.push(accounts.id);
        const data = {
          ...accounts.bankAccountAdvance,
          licensePlate: accounts.id
        };
        return data;
      });
    } else {
      listObject = this.itemsSelecteds.map((accounts) => {
        if (!accounts.bankAccountBalance) vehiclesWithoutAccount.push(accounts.id);
        const data = {
          ...accounts.bankAccountBalance,
          licensePlate: accounts.id
        };
        return data;
      });
    }
    if (vehiclesWithoutAccount.length) {
      this.snackBarService.openSnackBar(
        'Los vehículos ' + vehiclesWithoutAccount.join(', ') + " no tienen diligenciada la información bancaría completa", undefined, 'error'
      );
      return;
    }
    this.getFileForInscriptionAccount(listObject, this.typeregister);

  }

  /**
  * @param {BankAccount[]} data is the list of bank accounts to filter
  * @param {'balance'|'advance'} type is the type of the bank account
  * @description Gets the file for inscription account and downloads it
  */
  private getFileForInscriptionAccount(data?: BankAccount[], type?: 'balance' | 'advance') {

    this.service.getFileForInscriptionAccount(data, type).subscribe(
      (success) => {
        this.getVehicles();
        this.spinner.hide();
        this.snackBarService.openSnackBar('Se descargará el archivo en breve');
        this.utils.downloadFile(success.body, 'inscripcion de-' + (type === 'balance' ? 'Saldo' : 'Anticipo') + '-Cuentas-' + DateManager.dateToString(new Date(), 'YYYY-MM-DD'));
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Ocurrió un error al enviar la informacion', undefined, 'error');
      }
    );
  }

  /**
  * @description Updates the paginationList variables and executes "getVehicles" method
  */
  onScrollDown() {
    if (this.service.paginationList.getEnablePagingScroll()) {
      this.service.paginationList.setPageKey(
        this.service.paginationList.getPageKey() + 1
      );
      this.getVehicles(true);
    }
  }

  /**
  * @description Resets the paginationList variables
  */
  private resetValuesPagingScroll() {
    this.service.paginationList.setEnablePagingScroll(true);
    this.service.paginationList.setList([]);
    this.service.paginationList.setPageKey(1);
  }



  applyFilters() {
    this.resetValuesPagingScroll();
    this.itemsSelecteds = [];
    const filterControls = [
      { control: this.companyNitCtrl, label: 'companyNitCtrl' },
      { control: this.filterlicensePlate, label: 'id' },
      { control: this.stateAccount, label: 'stateAccount' },
      { control: this.vehicleState, label: 'state' },
      { control: this.owner, label: 'owner' },
      { control: this.type, label: 'type' },
    ];
    this.filtersValues = filterControls
      .filter(({ control }) => control.value !== null && control.value !== '')
      .map(({ control, label }) => {
        let value = control.value;
        if (label === 'id') value = value.toUpperCase().trim();
        if (label === 'companyNitCtrl') value = JSON.stringify(value);
        return `&${label}=${value}`;
      })
      .join('');
    if (this.filtersValues.length > 0) sessionStorage.setItem('vehiclesFilters', this.filtersValues);
    else sessionStorage.removeItem('vehiclesFilters');
    this.getVehicles()

  }

  /**
  * @description Cleans all filters and executes resetValuesPagingScroll and getVehicles methods
  */
  cleanFilter() {
    this.itemsSelecteds = [];
    this.companyNitCtrl.setValue({
      nit: null,
      businessName: 'Todas'
    });
    this.selectComp.companyCtrl.setValue('');
    this.stateAccount.setValue('');
    this.filterlicensePlate.setValue('');
    this.vehicleState.setValue(null);
    this.type.setValue('');
    this.owner.setValue('');
    this.filtersValues = '';
    sessionStorage.removeItem('vehiclesFilters');
    this.resetValuesPagingScroll();
    this.getVehicles();
  }

  /**
  * @description Navigates to vehicles/create
  */
  goToCreate() {
    this.router.navigate(['administration/vehicles/create']);
  }

  /**
  * @param {{ vehicle: Vehicle, selected: boolean }} $event is the vehicle selected from vehicle-item
  * @description If the $event.selected is true makes a push of the vehicle into itemsSelected array, otherwise removes the vehicle from array
  */
  onSelectItem($event: { vehicle: Vehicle, selected: boolean }): void {
    if ($event.selected && !this.itemsSelecteds.includes($event.vehicle)) {
      this.itemsSelecteds.push($event.vehicle);
    }
    else if (this.itemsSelecteds.includes($event.vehicle) && !$event.selected) {
      let index = this.itemsSelecteds.indexOf($event.vehicle);
      if (index !== -1) this.itemsSelecteds.splice(index, 1);
    }
  }

  /**
  * @param {string} plate is the license plate to check
  * @description Checks the validity of plate param as license plate
  */
  private validatePlate(plate: string): boolean {
    return this.patterns.GET_REGEX('LICENSE_PLATES').test(plate);
  }

  /**
  * @description Opens a dialog with the vehicle's conventions
  */
  openConventions(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      vehicle: true
    };
    dialogConfig.autoFocus = false;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    this.dialog.open(ConventionsComponent, dialogConfig);
  }

  /**
  * @description Cleans the service's vehicleSelected
  */
  ngOnDestroy(): void {
    this.service.vehicleSelected = null;
  }
}
