import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { City } from 'src/app/core/interfaces/city';
import { CityService } from '../city.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { IcaFormComponent } from '../ica-form/ica-form.component';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { AuthService } from 'src/app/core/services/authentication.service';
import { IntegrationEnum } from 'src/app/core/enums/integration.enum';

@Component({
  selector: 'app-city-item',
  templateUrl: './city-item.component.html',
  styleUrls: ['./city-item.component.scss']
})
export class CityItemComponent implements OnInit {

  @Input() city: City;
  permission = Permission;
  constructor(
    public cityService: CityService,
    public matDialog: MatDialog,
    private router: Router,
    private permissionRole: PermissionRole,
    private authService: AuthService,
  ) { }

  ngOnInit() {

  }

  public goToDetailCity() {
    CityService.citySelected = this.city;
    this.router.navigate(['administration/cities/detail', this.city.id]);
    /* this.router.navigate(['administration/cities/detail', this.city["mainCity"]["id"]]); */
  }

  get reteica() {
    return this.cityService.getReteicaCity(this.city);
  }

  get isMainCity() {
    return (this.city && !this.city.mainCity && this.city.reteica && this.city.debitAccount && this.city.icaAccount &&
      (!this.hasSiigoIntegration || (this.city.productSiigo && this.city.taxSiigo && this.city.icaSiigo)));
  }

  get isSubCity() {
    return this.city.mainCity;
  }

  get canLinkCity() {
    return this.permissionRole.hasPermission(this.permission.administration.module, this.permission.administration.linkCities);
  }

  public editRteica() {
    if (this.isMainCity) return;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      city: this.city
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    this.matDialog.open(IcaFormComponent, dialogConfig).afterClosed()
      .subscribe((result) => {
        if (result && result.city) {
          this.city = result.city;
        }
      });

  }

  get hasSiigoIntegration(): boolean {
    return this.authService.getCompany().integrationCredentials &&
      this.authService.getCompany().integrationCredentials.length &&
      this.authService.getCompany().integrationCredentials.some(integration => integration && integration.integrationChannel && integration.integrationChannel.toLowerCase() === IntegrationEnum.SIIGO.toLowerCase() && integration.state);
  }
}
