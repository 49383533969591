import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Model, Receivable } from 'src/app/core/interfaces/receivable';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ReceivableService } from '../receivable.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/resources/utils';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { ReceivableMessages } from 'src/app/core/messages/receivable-messages.enum';

@Component({
  selector: 'app-receivable-create',
  templateUrl: './receivable-create.component.html',
  styleUrls: ['./receivable-create.component.scss'],
  providers: [Model]
})
export class ReceivableCreateComponent extends ReactiveForm implements OnInit {
  validate = '';
  constructor(
    public dialogRef: MatDialogRef<ReceivableCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogParams: any,
    public ModelReceivable: Model,
    public formBuilder: FormBuilder,
    public SnackBarService: SnackBarService,
    public receivableService: ReceivableService,
    public spinner: NgxSpinnerService,
    public utils: Utils
  ) {
    super(
      formBuilder,
      ModelReceivable.modelReceivable,
    );
    this.setValidatorsForm(
      ModelReceivable.modelValidators,
      this.form
    );
  }

  ngOnInit() { }

  onSubmit() {
    this.validate = "touched";
    if (this.form.invalid) {
      if (this.utils.errorMessagesCustomized(this.form.get('cargoConsecutive'), "id del servicio asociado")) { }
      else if (this.utils.errorMessagesCustomized(this.form.get('amountInitial'), "monto inicial de la cartera")) { }
      else if (this.utils.errorMessagesCustomized(this.form.get('reason'), "razón de la cartera")) { }
      else if (this.utils.errorMessagesCustomized(this.form.get('whoReports.documentTypeName'), "tipo de identificación")) { }
      else if (this.utils.errorMessagesCustomized(this.form.get('whoReports.document'), "n° de identificación", 3, 15)) { }
      else if (this.utils.errorMessagesCustomized(this.form.get('whoReports.name'), "nombre")) { }
      else this.SnackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, "alert");

      return;
    } else if (!this.form.get('whoReports.documentTypeName').value || !this.form.get('whoReports.name').value) {
      this.SnackBarService.openSnackBar("Por favor seleccione un usuario válido en el campo n° de identificacion", undefined, "alert")
      return;
    }
    const numberAmountInitial = parseInt(this.form.get('amountInitial').value);
    this.form.get('amountInitial').setValue(numberAmountInitial);
    this.spinner.show();
    this.receivableService.createReceivable(this.form.getRawValue()).subscribe({
      next: () => {
        this.spinner.hide();
        this.SnackBarService.openSnackBar(FormMessages.SUCCESS_RECEIVABLE, undefined, "success");
        this.dialogRef.close({ state: true });
      },
      error: (error) => {
        this.spinner.hide();
        if (error && error.error && error.error.message)
          this.SnackBarService.openSnackBar(ReceivableMessages.LOAD_NOT_FOUND, undefined, "error")
        else
          this.SnackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, "error");
      },
      complete: () => {
        this.spinner.hide();
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
