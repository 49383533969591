<article class="wrapper__article d-flex flex-column">
  <div class="wrapper__article__title">
    <h5>
      <i class="fas fa-building"></i>
      {{title}}
    </h5>
  </div>
  <div class="wrapper__article__body--tab">
    <mat-tab-group (selectedIndexChange)="onSelectTab($event)" class="fullList">

      <mat-tab label="Información general">
        <form class="wrapper__article__form" [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="wrapper__article__form--content">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title class="text-strong">
                  Información General
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">

                <div class="col-md-4">
                  <mat-form-field appearance="standard">
                    <mat-label>NIT<span class="asterisk">*</span></mat-label>
                    <input matInput type="text" onlyNumbersInt appDocumentType [documentTypeId]="'3'"
                      name="nitCompanyForm" formControlName="companyId">
                    <mat-error *ngIf="form.get('companyId').errors?.required">
                      {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                    <mat-error *ngIf="form.get('companyId').errors?.minlength">
                      {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'compañia', 9) }}
                    </mat-error>
                    <mat-error *ngIf="form.get('companyId').errors?.maxlength">
                      {{ utils.giveMessageError('MAX_LENGTH_EXCEED', 'compañia', 9) }}
                    </mat-error>
                    <mat-hint class="color-secondary-dark text-bold"
                      *ngIf="form.get('companyId') && !form.get('companyId').value">
                      Por favor introduce el nit sin dígito de verificación
                    </mat-hint>
                  </mat-form-field>
                </div>

                <div class="col-md-4">
                  <mat-form-field appearance="standard">
                    <mat-label>Razón social<span class="asterisk">*</span></mat-label>
                    <input matInput type="text" name="businessNameForm" formControlName="name">
                    <mat-error *ngIf="form.get('name').errors?.required">
                      {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                    <mat-error *ngIf="form.get('name').errors?.minlength">
                      {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'razón social', 3) }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-md-4">
                  <mat-form-field appearance="standard">
                    <mat-label>Sitio web</mat-label>
                    <input matInput type="text" name="webSiteForm" formControlName="webSite">
                  </mat-form-field>
                </div>

                <div class="col-md-4">
                  <mat-form-field appearance="standard">
                    <mat-label>Correo Electrónico<span class="asterisk">*</span></mat-label>
                    <input matInput type="email" name="emailForm" formControlName="email">
                    <mat-error *ngIf="form.get('email').errors?.required">
                      {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                    <mat-error *ngIf="form.get('email').errors?.pattern">
                      {{ utils.giveMessageError('INCORRECT_FORMAT', 'correo') }}
                    </mat-error>
                    <mat-error *ngIf="form.get('email').errors?.maxlength">
                      {{ utils.giveMessageError('MAX_LENGTH_EXCEED', 'correo', 100) }}
                    </mat-error>
                    <mat-icon matSuffix class="cursor-pointer" color="primary"
                      *ngIf="form.get('email').errors && form.get('email').errors.pattern"
                      matTooltip="No se permiten caracteres especiales al principio('.test@mail.com'), caracteres especiales seguidos ('te..st@mail.com'), más de un arroba ('te@st@mail.com'), espacios ('te st@mail.com'), caracteres especiales al terminar el dominio ('test.@mail.com'), ni correos de extensión mayor a 100 caracteres">help</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <app-autocomplete-city [options]="optionsCity" [inputFormControl]="cityControl"
                    [validate]="cityValidate">
                  </app-autocomplete-city>
                </div>
                <div class="col-md-4">
                  <app-autocomplete-address [title]="'Dirección'" [value]="form.get('address').value"
                    [simpleAddress]="true" (emitToParent)="onSelectAddress($event)"></app-autocomplete-address>
                  <span class="pattern pattern--autocomplete font-secondary-dark" *ngIf="form.get('address').errors">
                    Seleccione una dirección de la lista
                  </span>
                </div>
                <div class="col-md-4">
                  <mat-form-field appearance="standard">
                    <mat-label>N° de Teléfono<span class="asterisk">*</span></mat-label>
                    <input matInput type="number" name="phone" formControlName="phone">
                    <mat-error *ngIf="form.get('phone').errors?.required">
                      {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                    <mat-error *ngIf="form.get('phone').errors?.pattern">
                      {{ utils.giveMessageError('INCORRECT_FORMAT', 'teléfono') }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field appearance="standard">
                    <mat-label>Comercial</mat-label>
                    <input type="text" matInput [formControl]="formControlCommercial">
                  </mat-form-field>
                </div>
                <div class="col-md-4" *ngIf="!hasEscortServicesCompany">
                  <mat-form-field appearance="standard">
                    <mat-label>Rol<span class="asterisk">*</span></mat-label>
                    <mat-select [formControl]="formControlRole" (selectionChange)="onSelectRole($event)">
                      <mat-option *ngFor="let role of roles" [value]="role">
                        {{role.alias}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formControlRole.errors?.required">
                      {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4" style="margin-top: 20px;">
                  <mat-label>Identificador externo</mat-label>
                  <i class="fas fa-info-circle fa-lg cursor-pointer mr-2"
                    matTooltip="Este es el identificador del cliente asociado con el servicio. Si se posee integración con la empresa se enviaran los datos con este identificador."></i>
                  <mat-button-toggle-group #group="matButtonToggleGroup" name="externalIdForm"
                    formControlName="externalId">
                    <mat-button-toggle [value]="true">
                      Si
                    </mat-button-toggle>
                    <mat-button-toggle [value]="false">
                      No
                    </mat-button-toggle>
                  </mat-button-toggle-group>

                </div>
              </div>
            </mat-expansion-panel>

            <span class="separator-horizontal"></span>

            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title class="text-strong">
                  Información para Facturación
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <div class="col-md-4" formGroupName="contact">
                  <mat-form-field appearance="standard">
                    <mat-label>Nombres<span class="asterisk">*</span></mat-label>
                    <input matInput type="text" name="name" formControlName="name">
                    <mat-error *ngIf="form.get('utility').errors?.required">
                      {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                    <mat-error *ngIf="form.get('contact.name').errors?.minlength">
                      {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'nombre', 3) }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4" formGroupName="contact">
                  <mat-form-field appearance="standard">
                    <mat-label>Apellidos<span class="asterisk">*</span></mat-label>
                    <input matInput type="text" name="lastName" formControlName="lastName">
                    <mat-error *ngIf="form.get('utility').errors?.required">
                      {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                    <mat-error *ngIf="form.get('contact.lastName').errors?.minlength">
                      {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'apellido', 3) }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4" formGroupName="contact">
                  <mat-form-field appearance="standard">
                    <mat-label>Correo Electrónico<span class="asterisk">*</span></mat-label>
                    <input matInput type="text" name="email" formControlName="email">
                    <mat-error *ngIf="form.get('utility').errors?.required">
                      {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                    <mat-error *ngIf="form.get('contact.email').errors?.pattern">
                      {{ utils.giveMessageError('INCORRECT_FORMAT', 'correo') }}
                    </mat-error>
                    <mat-error *ngIf="form.get('contact.email').errors?.maxlength">
                      {{ utils.giveMessageError('MAX_LENGTH_EXCEED', 'correo', 100) }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </mat-expansion-panel>

            <span class="separator-horizontal"></span>

            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title class="text-strong">
                  Información financiera
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <div class="col-md-4 mb-3">
                  <mat-form-field appearance="standard">
                    <mat-label>Utilidad<span class="asterisk">*</span></mat-label>
                    <input matInput type="number" name="utility" onlyNumbersInt formControlName="utility">
                    <i class="fas fa-info-circle fa-lg cursor-pointer" matSuffix
                      matTooltip="Porcentaje de utilidad mínimo requerido al crear o editar servicios"></i>
                    <mat-error *ngIf="form.get('utility').errors?.required">
                      {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                    <mat-error *ngIf="form.get('utility').errors?.min">
                      {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'utilidad', minUtility) }}
                    </mat-error>
                    <mat-error *ngIf="form.get('utility').errors?.max">
                      {{ utils.giveMessageError('MAX_VALUE_EXCEED', 'utilidad', "100" )}}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4 mb-3" *ngIf="hasPermissionAdditionalCosts">
                  <mat-form-field appearance="standard">
                    <mat-label>Utilidad de servicios adicionales<span class="asterisk">*</span></mat-label>
                    <input matInput type="number" name="utilityAdditionalService"
                      formControlName="utilityAdditionalService" onlyNumbersInt>
                    <i class="fas fa-info-circle fa-lg cursor-pointer" matSuffix
                      matTooltip="Porcentaje de utilidad mínimo requerido al crear servicios adicionales"></i>
                    <mat-error *ngIf="form.get('utilityAdditionalService').errors?.required">
                      {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                    <mat-error *ngIf="form.get('utilityAdditionalService').errors?.min">
                      {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'utilidad de servicios adicionales',
                      minUtilityAdittionalService) }}
                    </mat-error>
                    <mat-error *ngIf="form.get('utilityAdditionalService').errors?.max">
                      {{ utils.giveMessageError('MAX_VALUE_EXCEED', 'utilidad de servicios adicionales', "100" )}}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4 mb-3">
                  <mat-form-field appearance="standard">
                    <mat-label>Porcentaje de anticipo<span class="asterisk">*</span></mat-label>
                    <input matInput type="number" name="advancePercentage" formControlName="advancePercentage"
                      onlyNumbersInt>
                    <i class="fas fa-info-circle fa-lg cursor-pointer" matSuffix
                      matTooltip="Porcentaje de anticipo máximo permitido al crear o editar servicios"></i>
                    <mat-error *ngIf="form.get('advancePercentage').errors?.required">
                      {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                    <mat-error *ngIf="form.get('advancePercentage').errors?.min">
                      {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'porcentaje de anticipo', minAdvancePercentage)
                      }}
                    </mat-error>
                    <mat-error *ngIf="form.get('advancePercentage').errors?.max">
                      {{ utils.giveMessageError('MAX_VALUE_EXCEED', 'porcentaje de anticipo', maxAdvancePercentage )}}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </mat-expansion-panel>

            <span class="separator-horizontal"></span>

            <mat-expansion-panel [expanded]="true" *ngIf="isPrincipalCompany && hasMinistry">
              <mat-expansion-panel-header>
                <mat-panel-title class="text-strong">
                  Información para el Ministerio de Transporte
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row pl-2">
                <div class="col-12 text-strong">Integración Web service Ministerio</div>
                <div class="col-md-6" formGroupName="rndcCredentials">
                  <mat-form-field appearance="standard">
                    <mat-label>Usuario<span class="asterisk">*</span></mat-label>
                    <input matInput type="text" name="userName" formControlName="userName" autocomplete="false">
                    <mat-error *ngIf="form.get('rndcCredentials.userName').errors?.required">
                      {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6" formGroupName="rndcCredentials">
                  <mat-form-field appearance="standard">
                    <mat-label>Contraseña<span class="asterisk">*</span></mat-label>
                    <input matInput [type]="showPasswordRNDC?'text':'password'" name="password"
                      formControlName="password" autocomplete="new-password">
                    <i matSuffix class="fas color-primary cursor-pointer" (click)="showPasswordRNDC=!showPasswordRNDC"
                      [ngClass]="{'fa-eye':showPasswordRNDC, 'fa-eye-slash':!showPasswordRNDC}"></i>
                    <mat-error *ngIf="form.get('rndcCredentials.password').errors?.required">
                      {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <!-- <div class="col-12 mt-3">
                  <span class="text-strong">Prefijos</span>
                </div>
                <div class="col-md-3">
                  <mat-form-field appearance="standard">
                    <mat-label>Manifiesto</mat-label>
                    <input matInput type="text" name="manifestPrefix" [formControl]="getPrefix(0).get('name')">
                    <i matSuffix class="fas fa-info-circle fa-lg cursor-pointer"
                      matTooltip="Es el prefíjo con el que se generarán los manifiestos (Ej: ManCom1234)"></i>
                    <mat-error *ngIf="getPrefix(0).get('name').errors?.required">
                      {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field appearance="standard">
                    <mat-label>Remesa</mat-label>
                    <input matInput type="text" name="consigmentPrefix" [formControl]="getPrefix(1).get('name')">
                    <i matSuffix class="fas fa-info-circle fa-lg cursor-pointer"
                      matTooltip="Es el prefíjo con el que se generarán las remesas (Ej: RemCom1234)"></i>
                    <mat-error *ngIf="getPrefix(1).get('name').errors?.required">
                      {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field appearance="standard">
                    <mat-label>Servicios nacionales</mat-label>
                    <input matInput type="text" name="nationalPrefix" [formControl]="getPrefix(2).get('name')">
                    <i matSuffix class="fas fa-info-circle fa-lg cursor-pointer"
                      matTooltip="Es el prefíjo con el que se generarán los servicios nacionales (Ej: NacCom1234)"></i>
                    <mat-error *ngIf="getPrefix(2).get('name').errors?.required">
                      {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field appearance="standard">
                    <mat-label>Servicios urbanos y última milla</mat-label>
                    <input matInput type="text" name="urbanPrefix" [formControl]="getPrefix(3).get('name')">
                    <i matSuffix class="fas fa-info-circle fa-lg cursor-pointer"
                      matTooltip="Es el prefíjo con el que se generarán los servicios urbanos y última milla(Ej: UrbCom1234)"></i>
                    <mat-error *ngIf="getPrefix(3).get('name').errors?.required">
                      {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                  </mat-form-field>
                </div> -->
                <div class="col-md-6" *ngIf="company?.rndcCredentials?.companyId">
                  <mat-form-field appearance="standard">
                    <mat-label>NIT<span class="asterisk">*</span></mat-label>
                    <input matInput type="text" name="formControlNitRndc" [formControl]="formControlNitRndc">
                  </mat-form-field>
                </div>
                <div class="col-md-6" *ngIf="company?.rndcCredentials?.state">
                  <mat-form-field appearance="standard">
                    <mat-label>Estado<span class="asterisk">*</span></mat-label>
                    <input matInput type="text" name="formControlStateRndc" [formControl]="formControlStateRndc">
                  </mat-form-field>
                </div>
              </div>
              <div class="row pl-2">
                <div class="col-12 text-strong">Póliza</div>
                <div class="col-md-4">
                  <mat-form-field appearance="standard" formGroupName="insuranceInformation">
                    <mat-label>N° de Póliza<span class="asterisk">*</span></mat-label>
                    <input matInput type="text" onlyNumbersInt name="policyNumber" formControlName="policyNumber">
                    <mat-error *ngIf="form.get('insuranceInformation.policyNumber').errors?.required">
                      {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field appearance="standard" formGroupName="insuranceInformation">
                    <mat-label>SOAT<span class="asterisk">*</span></mat-label>
                    <input matInput type="text" onlyNumbersInt name="companyName" formControlName="companyName">
                    <mat-error *ngIf="form.get('insuranceInformation.companyName').errors?.required">
                      {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field appearance="standard">
                    <mat-label>Fecha de expiración<span class="asterisk">*</span></mat-label>
                    <input type="text" matInput [matDatepicker]="datePicker" [formControl]="formControlExpirationDate"
                      (click)="datePicker.open()" (dateChange)="onChangeDatePicker($event)" readonly
                      [matDatepickerFilter]="fromNowFilter">
                    <mat-error *ngIf="formControlExpirationDate.errors?.required">
                      {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                    <mat-datepicker #datePicker></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </mat-expansion-panel>
            <span class="separator-horizontal"></span>
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title class="text-strong">
                  Configuración
                </mat-panel-title>
              </mat-expansion-panel-header>
              <section class="check-box-section">
                <div class="row">
                  <div class="col-md-4">
                    <mat-checkbox name="driverVisibilityForm" formControlName="driverVisibility">Compañía visible al
                      Conductor</mat-checkbox>
                  </div>
                  <div class="col-md-4" *ngIf="!company || company.companyId !== environment.rootNit">
                    <mat-checkbox name="validateFreightForm" formControlName="validateFreight">Deshabilitar valor del
                      flete</mat-checkbox>
                  </div>
                  <div class="col-md-4">
                    <mat-checkbox name="exclusiveForm" formControlName="exclusive">Compañía exclusiva
                      <i class="fas fa-info-circle fa-lg"
                        matTooltip="Los usuarios y vehículos de compañías exclusivas sólo verán la información asociada a dicha compañía."></i>
                    </mat-checkbox>
                  </div>
                </div>
              </section>
              <span class="separator-horizontal"></span>
              <div class="row" formGroupName="colorConfiguration" *ngIf="isPrincipalCompany">
                <div class="col-md-6">
                  <mat-form-field appearance="standard">
                    <mat-label>Color principal<span class="asterisk">*</span></mat-label>
                    <input matInput type="color" name="primaryColor" formControlName="primaryColor">
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field appearance="standard">
                    <mat-label>Color secundario<span class="asterisk">*</span></mat-label>
                    <input matInput type="color" name="secondaryColor" formControlName="secondaryColor">
                  </mat-form-field>
                </div>
              </div>
            </mat-expansion-panel>
          </div>
          <div class="wrapper__article__form--footer button-container" *ngIf="createUpdatePermission">
            <button mat-raised-button color="primary" type="submit" class="button-width-auto bg-color-primary">
              Guardar
            </button>
          </div>
        </form>
      </mat-tab>
      <mat-tab label="Requisitos de cumplido" *ngIf="company?.companyId" permission-display
        [module]="permission.administration.module" [functionality]="permission.administration.updateCompany">
        <app-compliments [company]="company" *ngIf="activeTab === 'Requisitos de cumplido'"
          (refreshList)="getCompany()"></app-compliments>
      </mat-tab>
      <mat-tab label="Correos" *ngIf="company?.companyId && (hasPermissionToAddEmails || company.emails?.length)">
        <app-email-list [emails]="company?.emails || []" [nitCompany]="company.companyId"
          (refreshList)="getCompany()"></app-email-list>
      </mat-tab>
      <mat-tab label="Centros de Costo" *ngIf="company?.companyId">
        <app-cost-center-list [costCenters]="company.costCenter" [company]="company" (refreshList)="getCompany()"
          [activeTab]="activeTab"></app-cost-center-list>
      </mat-tab>
      <mat-tab label="Recursos" *ngIf="company?.companyId" permission-display
        [module]="permission.administration.module" [functionality]="permission.administration.updateCompany">
        <app-company-resources *ngIf="activeTab === 'Recursos'" [company]="company"
          (refreshList)="getCompany()"></app-company-resources>
      </mat-tab>
      <mat-tab label="Métricas e históricos" *ngIf="company?.companyId && isPrincipalCompany">
        <app-history-and-metrics-company *ngIf="activeTab === 'Métricas e históricos'"
          [companyId]="company.companyId"></app-history-and-metrics-company>
      </mat-tab>
      <mat-tab label="Configuración" *ngIf="company?.companyId && isPrincipalCompany" permission-display
        [module]="permission.administration.module" [functionality]="permission.administration.updateCompany">
        <app-company-config [company]="company" (refreshList)="getCompany()"></app-company-config>
      </mat-tab>
      <mat-tab label="Etiquetas" *ngIf="company?.companyId && isPrincipalCompany" permission-display
        [module]="permission.administration.module" [functionality]="permission.administration.updateCompany">
        <app-tags *ngIf="activeTab === 'Etiquetas'" [company]="company" (refreshList)="getCompany()"></app-tags>
      </mat-tab>
    </mat-tab-group>
  </div>
</article>