<aside class="col-md-12 third__party__consignment mb-4 position-relative">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <div class="title-component mr-4">
    <div class="col-md-12 mt-4">
      <i class="fas fa-building"></i>
      {{modalTitle}}
    </div>
  </div>
  <form class="third__party__consignment-form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row">
      <app-autocomplete-city [inputFormControl]="cityControl" class="col-12" [options]="cityOptions"
        [validate]="validate">
      </app-autocomplete-city>
      <!-- Document Type  -->
      <div class="col-md-6" *ngIf="!dataDialog?.options?.thirdParty">
        <mat-form-field appearance="outline">
          <mat-label>Tipo de documento<span class="asterisk">*</span></mat-label>
          <mat-select [formControl]="documentTypeNameControl" [displayWith]="displayDocumentTypeName"
            (selectionChange)="onChangeDocumentType($event)">
            <mat-option *ngFor="let type of global.documenTypes" [value]="type">
              {{type.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="documentTypeNameControl.errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
        </mat-form-field>
      </div>
      <!-- Number Document -->
      <div class="col-md-6">
        <mat-form-field appearance="outline" formGroupName="information">
          <mat-label>Número de documento<span class="asterisk">*</span></mat-label>
          <input matInput type="text" onlyNumbersInt name="document" formControlName="document" autocomplete="off">
          <mat-error *ngIf="form.get('information.document').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
          <mat-error *ngIf="form.get('information.document').errors?.minlength">
            {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'número de documento', minDocument) }}
          </mat-error>
          <mat-error *ngIf="form.get('information.document').errors?.maxlength">
            {{ utils.giveMessageError('MAX_LENGTH_EXCEED', 'número de documento', maxDocument) }}
          </mat-error>
        </mat-form-field>
      </div>
      <!-- Name -->
      <div class="col-md-6">
        <mat-form-field appearance="outline" formGroupName="information">
          <mat-label>Nombre<span class="asterisk">*</span></mat-label>
          <input matInput type="text" name="name" formControlName="name" autocomplete="off">
          <mat-error *ngIf="form.get('information.name').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
        </mat-form-field>
      </div>
      <!-- Address -->
      <div class="col-md-6" [ngClass]="{'col-md-12':dataDialog?.options?.thirdParty}">
        <app-autocomplete-address [title]="'Ingrese la dirección'" appearance="outline" [location]="location"
          (emitToParent)="onSelectAddress($event)"
          [value]="form.get('address.address').value"></app-autocomplete-address>
      </div>
      <!-- Map -->
      <div class="col-12 map mt-4">
        <app-standard-map [options]="mapOptions" (clickMap)="onClickMap($event)"></app-standard-map>
      </div>
    </div>
    <span class="separator-horizontal"></span>
    <div class="button-container">
      <button mat-raised-button color="primary" type="submit" class="button-width-auto bg-color-primary">
        Guardar
      </button>
    </div>
  </form>
</aside>