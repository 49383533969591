<section [ngClass]="{'py-5 px-3 px-md-5 position-relative': isModal}">
    <i *ngIf="isModal" matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="ref.close()"></i>
    <div class="row">
        <div class="col-12" *ngIf="!isModal">
            <h2 class="title">Viáticos</h2>
        </div>
        <div class="col-12 text-center" *ngIf="isModal">
            <h2 class="title">Agregar viáticos</h2>
        </div>
    </div>
    <br>
    <form [formGroup]="form" *ngIf="form">
        <div formArrayName="travelExpenses">
            <div *ngFor="let expense of travelExpensesControls; let i = index;">
                <div [formGroupName]="i" class="d-flex flex-wrap align-items-center mb-4 position-relative">
                    <mat-form-field class="col-md-6" appearance="outline">
                        <mat-label>Concepto del viático<span class="asterisk">*</span></mat-label>
                        <mat-select formControlName="travelExpensesType" [compareWith]="compareTravelExpensesType">
                            <mat-option *ngFor="let concept of concepts" [value]="concept">
                                {{concept.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="expense.get('travelExpensesType').errors?.required">
                            {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-md-6" appearance="outline">
                        <mat-label>Valor del viático<span class="asterisk">*</span></mat-label>
                        <input matInput type="text" currencyMask min="0" formControlName="totalPaid">
                        <mat-error *ngIf="expense.get('totalPaid').errors?.required">
                            {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                        <mat-error *ngIf="expense.get('totalPaid').errors?.min">
                            {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'valor', 100) }}
                        </mat-error>
                    </mat-form-field>
                    <i class="fas fa-trash trash-expense" (click)="remove(i)"
                        *ngIf="!travelExpenses?.at(i)?.value?.paid"></i>
                </div>
            </div>
        </div>
    </form>
    <div class="d-flex flex-wrap justify-content-between" *ngIf="travelExpensesControls.length > 0">
        <h5 class="text-bold mr-2">Total viáticos a desembolsar</h5>
        <span class="text-bold text-right">{{totalValue | currency:'COP':'code'}}</span>
    </div>
    <div class="col-12" *ngIf="concepts?.length">
        <div class="add-travel-expenses-line" role="button" (click)="addTravelExpense()">
            <i class="fas fa-plus-circle"></i>
            Agregar viático (opcional)
        </div>
    </div>
    <div class="alert alert-danger text-bold text-center" *ngIf="!concepts?.length">
        No tiene viáticos disponibles
    </div>
    <br *ngIf="isModal">
    <div class="row" *ngIf="isModal">
        <div class="col-12 text-center">
            <button mat-raised-button class="primary-secondary" color="primary" (click)="ref.close()">Cancelar</button>
            <button mat-raised-button class="btn-primary bg-color-primary" color="primary"
                (click)="save()">Guardar</button>
        </div>
    </div>
</section>
