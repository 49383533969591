import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { VehiclesService } from '../../vehicles/list-vehicles.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DetailVehicleComponent } from '../../vehicles/detail-vehicle/detail-vehicle.component';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { Router } from '@angular/router';
import { MaintenanceService } from '../maintenance.service';
import { DateEnum } from 'src/app/core/enums/date.enum';
import { DateManager } from 'src/app/core/managers/date.manager';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
@Component({
  selector: 'app-maintenance-item-vehicle',
  templateUrl: './maintenance-item-vehicle.component.html',
  styleUrls: ['./maintenance-item-vehicle.component.scss']
})
export class MaintenanceItemVehicleComponent implements OnInit {
  @Input() vehicle: Vehicle;
  permission = Permission;
  constructor(
    private snackbarService: SnackBarService,
    private matDialog: MatDialog,
    private router: Router,
    private maintenanceService: MaintenanceService,
    public permissionRole: PermissionRole
  ) { }
  ngOnInit() { }

  async openMaintenanceDetail(vehicle: Vehicle) {
    if (vehicle && vehicle.id) {
      await this.maintenanceService.getVehicle(vehicle.id);
      this.router.navigate(['/administration/maintenance/detail', vehicle.id]);
    }
    else this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error')
  }

  openDetailVehicle(licensePlate: string) {
    if (licensePlate) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        id: licensePlate
      };
      dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
      dialogConfig.width = ModalEnum.LARGE_WIDTH;
      dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
      dialogConfig.autoFocus = false;
      this.matDialog.open(DetailVehicleComponent, dialogConfig);
    } else this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');

  }

  createMaintenance() {
    if (this.vehicle && this.vehicle.id) {
      this.maintenanceService.setLoadingRoute('/administration/maintenance/list');
      this.router.navigate(['/administration/maintenance/create', this.vehicle.id]);
    }
    else this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
  }

  // GETTERS
  getCalculateMaintenancePeriod(expirationDate: string): number {
    return this.maintenanceService.getCalculateMaintenancePeriod(expirationDate);
  }

  getMaintenanceDatesFormatted(date: string): string {
    return DateManager.formatDate(date, DateEnum.YYYY_MM_DD_HH_mm_ZZ, 'DD/MM/YYYY');
  }

}
