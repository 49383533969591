const fb_env = environment.listFirebaseConfig[environment.rootNit];

import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import es from '@angular/common/locales/es';
import { CommonModule, CurrencyPipe, registerLocaleData } from '@angular/common';
registerLocaleData(es);
import { environment } from '../../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { AngularFormsInputMasksModule } from 'angular-forms-input-masks';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { IconsModule } from 'angular-bootstrap-md'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatMenuModule } from '@angular/material/menu';
import {
  MatNativeDateModule,
  MatIconModule,
  MatButtonModule,
  MatCheckboxModule,
  MatToolbarModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatRadioModule,
  MatListModule,
  MatDatepickerModule,
  MatSidenavModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatTabsModule,
  MatExpansionModule,
  MatButtonToggleModule,
  MatDialogModule,
  MatTooltipModule,
  MatProgressBarModule,
  MatStepperModule,
  MatSnackBarModule,
  MatDialogRef,
  MatChipsModule,
  MAT_DIALOG_DATA,
  MatPaginatorModule,
  MatTreeModule,
  MatBadgeModule,

} from '@angular/material';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AuthService } from './services/authentication.service';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { Global } from './resources/global';
import { Patterns } from './resources/patterns';
import { Endpoints } from './resources/endpoints';
import { AppInterceptorService } from './interceptors/app.interceptor';
import { Utils } from './resources/utils';
import { File } from './models/file';
import { AsyncPipe } from '@angular/common';
import { GeocodeService } from './services/geocoder.service';
import { AppPasswordDirective } from './directives/password.directive';
import { OnlyNumberDoubleDirective } from './directives/only-numbers-double.directive';
import { OnlyNumberIntDirective } from './directives/only-numbers-int.directive';
import { AgmJsMarkerClustererModule, ClusterManager } from '@agm/js-marker-clusterer';
import { MapComponent } from 'src/app/shared/map/map.component';
import { GoogleMapsComponent } from 'src/app/shared/google-maps/google-maps.component';
import { SafePipe } from './pipe/safe.pipe';
import { RoleAccessDirective } from './directives/role-access.directive';
import { RoleService } from './services/role.service';
import { CatalogService } from './services/catalog.service';
import { DateFormatPipe } from './pipe/dateFormat.pipe';
import { ExportListComponent } from 'src/app/shared/export-list/export-list.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { PICK_FORMATS } from './resources/formats-mat';
import { PickDateAdapter } from './resources/pick-date-adapter';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { Titles } from './resources/titles';
import { MillisecondsDigitalClockPipe } from './pipe/millisecondsDigitalClock.pipe';
import { CreateCargoFromGuidesService } from 'src/app/modules/guides/create-cargo-from-guides/create-cargo-from-guides.service';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CurrencyMaskDirective } from './directives/currency-mask.directive';
import { SelectItemListDirective } from './directives/select-item-list.directive';
import { NgxCaptchaModule } from 'ngx-captcha';
import { LayoutModule } from '@angular/cdk/layout';
import { MToKmPipe } from './pipe/mToKm.pipe';
import { StateGuidePipe } from './pipe/stateGuide.pipe';
import { DurationPipe } from './pipe/duration.pipe';
import { RoleTranslatePipe } from './pipe/roleTranslate.pipe';
import { MessagingService } from './services/messages.services';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TrackGuideDetailComponent } from '../public/track-guide/track-guide-detail/track-guide-detail.component';
import { TrackGuideSearchComponent } from '../public/track-guide/track-guide-search/track-guide-search.component';
import { TrackGuideComponent } from '../public/track-guide/track-guide.component';
import { ItemCargoFromGuidesComponent } from '../modules/guides/create-cargo-from-guides/item-cargo-from-guides/item-cargo-from-guides.component';
import { HeaderComponent } from '../layout/header/header.component';
import { FooterComponent } from '../layout/footer/footer.component';
import { NavComponent } from '../layout/nav/nav.component';
import { NotificationComponent } from '../layout/notifications/notifications.component';
import { InactivityModalComponent } from '../layout/inactivity-modal/inactivity-modal.component';
import { FilesStorageComponent } from 'src/app/shared/files-storage/files-storage.component';
import { PathsStorages } from 'src/app/core/resources/paths-storages';
import { PermissionTabDirective } from './directives/permission-tab.directive';
import { ModelRoleComany } from './interfaces/roleCompany';
import { ValidateBtnCreateCargoDirective } from './directives/validate-btn-create-cargo.directive';
import { DialogComponent } from '../shared/dialog/dialog.component';
import { AutocompleteCityComponent } from '../shared/autocomplete-city/autocomplete-city.component';
import { VehicleBodyWorkTypeComponent } from '../modules/cargo/manual-creation-cargo/components/vehicle-body-work-type/vehicle-body-work-type.component';
import { ListFilterComponent } from '../shared/list-filter/list-filter.component';
import { ConventionsComponent } from '../shared/conventions/conventions.component';
import { AutocompleteAddressComponent } from '../shared/autocomplete-address/autocomplete-address.component';
import { FileComponent } from '../shared/files/file.component';
import { MatTableModule } from '@angular/material';
import { GoogleService } from './services/google.service';
import { LicensePlateComponent } from '../shared/license-plate/license-plate.component';
import { CargoEvidenceComponent } from 'src/app/modules/cargo/cargo-evidence/cargo-evidence.component';
import { ReadXLSX } from './resources/readXLSX';
import { RoleFunctionalityPipe } from './pipe/roleFunctionality.pipe';
import { RoleModulePipe } from './pipe/roleModule.pipe';
import { CompanyNamePipe } from './pipe/companyName.pipe';
import { BasicPersonComponent } from '../shared/basic-person/basic-person.component';
import { PatternFieldDirective } from './directives/pattern-field.directive';
import { DocumentTypeDirective } from './directives/document-type.directive';
import { InputDocumentUserComponent } from '../shared/input-document-user/input-document-user.component';
import { BasicStatePipe } from './pipe/basicState.pipe';
import { PhoneNumberPipe } from './pipe/phone-number.pipe';
import { CapitalizeTextPipe } from './pipe/capitalize.pipe';
import { OpenImgComponent } from '../shared/open-img/open-img.component';
import { DriverCargoComponent } from '../modules/cargo/driver-cargo/driver-cargo.component';
import { PermissionDisplayDirective } from './directives/permission-display.directive';
import { StateGuideComponent } from '../modules/guides/state-guide/state-guide.component';
import { FileService } from '../shared/files/file.service';
import { PermissionRole } from './resources/permission-role';
import { StatusCode } from './resources/status-code';
import { Dialog } from './resources/dialog';
import { ChangeStateGuideComponent } from '../modules/guides/change-state-guide/change-state-guide.component';
import { PdfTitlePipe } from './pipe/pdfTitle.pipe';
import { InfoBoxComponent } from 'src/app/shared/info-box/info-box.component';
import { LicensePlateDirective } from './directives/licensePlate.directive';
import { StateUserPipe } from './pipe/stateInactive.pipe';
import { InputLicensePlateTrailerComponent } from '../shared/input-license-plate-trailer/input-license-plate-trailer';
import { SigService } from '../modules/sig/sig.service';
import { PaymentTypePipe } from './pipe/paymentType.pipe';
import { SelectGpsComponent } from '../shared/select-gps/select-gps.component';
import { GpsTypePipe } from './pipe/gpsType.pipe';
import { AssignedCargoListComponent } from '../modules/cargo/assigned-cargo-list/assigned-cargo-list.component';
import { AssignedCargoItemListComponent } from '../modules/cargo/assigned-cargo-item-list/assigned-cargo-item-list.component';
import { AlertCargoComponent } from '../shared/alert-cargo/alert-cargo.component';
import { SelectCompanyComponent } from '../shared/select-company/select-company.component';
import { MediaService } from './services/media.service';
import { ModalLicensePlateComponent } from '../modules/cargo/modal-license-plate/modal-license-plate.component';
import { CompanyManager } from './managers/company.manager';
import { DateManager } from './managers/date.manager';
import { QuickPayComponent } from '../public/quick-pay/quick-pay.component';
import { DialogSurveyComponent } from '../shared/dialog-survey/dialog-survey.component';
import { ConfirmComponent } from '../shared/confirm/confirm.component';
import { ContainerNumberDirective } from './directives/containerNumber.directive';
import { ConfirmEndCargoComponent } from '../modules/cargo/confirm-end-cargo/confirm-end-cargo.component';
import { UserDocumentInfoComponent } from '../shared/user-document-info/user-document-info.component';
import { UpperCaseDirective } from './directives/upperCaseText.directive';
import { TicketTypes } from './interfaces/ticketTypes';
import { FragmentedAddressComponent } from '../shared/fragmented-address/fragmented-address.component';
import { RequestTermsComponent } from '../shared/input-document-user/request-terms/request-terms.component';
import { UserCardComponent } from '../shared/user-card/user-card.component';
import { AutocompleteThirdpartyComponent } from '../shared/autocomplete-thirdparty/autocomplete-thirdparty.component';
import { StandardMapComponent } from '../shared/standard-map/standard-map.component';
import { ModalSelectionMapComponent } from '../shared/modal-selection-map/modal-selection-map.component';
import { MultipleAddressesComponent } from '../shared/multiple-addresses/multiple-addresses.component';
import { DynamicHostDirective } from './directives/dynamic-host.directive';
import { StandardFileComponent } from '../shared/standard-file/standard-file.component';
import { ListOperationsComponent } from '../shared/list-operations/list-operations.component';
import { FileNamePipe } from './pipe/fileName.pipe';
import { Companies } from './resources/companies';
import { PermissionFlowComponent } from '../shared/permission-flow/permission-flow.component';
import { PermissionFlowDialogComponent } from '../shared/permission-flow-dialog/permission-flow-dialog.component';
import { FormControlPipe } from './pipe/formControl.pipe';
import { SelectLicensePlateComponent } from '../shared/select-license-plate/select-license-plate.component';
import { GpsNotificationComponent } from '../shared/gps-notification/gps-notification.component';
import { MasterTestingMenuDialogComponent } from '../shared/master-testing-menu-dialog/master-testing-menu-dialog.component';
import { MasterTestigChangeRoleComponent } from '../shared/master-testig-change-role/master-testig-change-role.component';
import { MasterTestigRepresentJsonComponent } from '../shared/master-testig-represent-json/master-testig-represent-json.component';
import { NGX_MAT_DATE_FORMATS, NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { PreviewNotificationComponent } from '../layout/preview-notification/preview-notification.component';
import { ApproveCargoDialogComponent } from '../shared/approve-cargo-dialog/approve-cargo-dialog.component';
import { DatetimePickerComponent } from '../shared/datetime-picker/datetime-picker.component';
import { CargoManager } from './managers/cargo.manager';
import { EmailTypeNamePipe } from '../pipe/email-type-name.pipe';
import { MassivePaymentsDirective } from './directives/massivePayments.directive';
import { GuideListDetailsComponent } from '../public/track-guide/guide-list-details/guide-list-details.component';

import { AlertrndcComponent } from '../modules/home/alertrndc/alertrndc.component';
import { CargoTaxesPipe } from './pipe/cargo-taxes.pipe';
import { ImageThumbnailListComponent } from '../shared/image-thumbnail-list/image-thumbnail-list.component';
import { FromStoragePipe } from '../pipe/from-storage.pipe';
import { LegalizeStatePipe } from './pipe/legalize-state.pipe';
import { TaxesOwnFleetPipe } from './pipe/taxes-own-fleet.pipe';
import { PublishingZonePipe } from './pipe/publishing-zone.pipe';
import { UploadResourcesComponent } from '../shared/upload-resources/upload-resources.component';
import { MaxCharDirective } from './directives/max-char.directive';
import { AutocompleteThirdpartyAddressComponent } from '../shared/autocomplete-thirdparty-address/autocomplete-thirdparty-address.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'
import { TypeVehicleComponent } from '../modules/cargo/manual-creation-cargo/components/type-vehicle/type-vehicle.component';
import { ManualDataVehicleInputComponent } from '../modules/administration/vehicles/manual-data-vehicle-input/manual-data-vehicle-input.component';
import { PersonInfoComponent } from '../modules/cargo/person-info/person-info.component';
import { GenerateManifestActionComponent } from '../modules/cargo/generate-manifest-action/generate-manifest-action.component';
import { AssignCostCenterComponent } from '../modules/administration/companies/assign-cost-center/assign-cost-center.component';
import { PrevisualizationResourcesComponent } from '../modules/administration/previsualization-resources/previsualization-resources.component';
import { FilterTrackingReportsComponent } from '../modules/cargo/filter-tracking-reports/filter-tracking-reports.component';
const maskConfig: Partial<IConfig> = {
  validation: false,
};

const DATETIME_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD HH:mm:ss'
  },
  display: {
    dateInput: 'YYYY-MM-DD HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ServiceWorkerModule.register('firebase-messaging-sw.js', { enabled: true }),
    LayoutModule,
    NgbModule,
    MDBBootstrapModule.forRoot(),
    IconsModule,
    InfiniteScrollModule,
    AngularEditorModule,
    AngularFormsInputMasksModule,
    // Firebase
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
    fb_env.database ?
      AngularFireModule.initializeApp(fb_env, fb_env.database) :
      AngularFireModule.initializeApp(fb_env),
    // AGM
    AgmCoreModule.forRoot({
      apiKey: environment.apyKeyGoogleMaps,
      libraries: ['places']
    }),
    AgmDirectionModule,
    AgmJsMarkerClustererModule,
    // Ngx
    NgScrollbarModule,
    NgxMaskModule.forRoot(maskConfig),
    NgxSpinnerModule,
    NgxCaptchaModule,
    NgxSkeletonLoaderModule,
    // Material
    MatMenuModule,
    MatNativeDateModule,
    MatDatepickerModule,
    NgxMatMomentModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatRadioModule,
    MatSidenavModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatStepperModule,
    MatSnackBarModule,
    MatTableModule,
    MatChipsModule,
    MatTreeModule,
    NgxMatMomentModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule
  ],
  declarations: [
    // Directives
    AppPasswordDirective,
    OnlyNumberDoubleDirective,
    OnlyNumberIntDirective,
    PatternFieldDirective,
    RoleAccessDirective,
    CurrencyMaskDirective,
    SelectItemListDirective,
    PermissionTabDirective,
    PermissionDisplayDirective,
    ValidateBtnCreateCargoDirective,
    DocumentTypeDirective,
    CurrencyMaskDirective,
    LicensePlateDirective,
    ContainerNumberDirective,
    DynamicHostDirective,
    UpperCaseDirective,
    MassivePaymentsDirective,
    MaxCharDirective,
    // Pipes
    MillisecondsDigitalClockPipe,
    SafePipe,
    RoleTranslatePipe,
    DurationPipe,
    StateGuidePipe,
    DateFormatPipe,
    PhoneNumberPipe,
    CapitalizeTextPipe,
    PaymentTypePipe,
    FormControlPipe,
    MToKmPipe,
    RoleFunctionalityPipe,
    RoleModulePipe,
    CompanyNamePipe,
    FileNamePipe,
    BasicStatePipe,
    PdfTitlePipe,
    StateUserPipe,
    GpsTypePipe,
    CargoTaxesPipe,
    TaxesOwnFleetPipe,
    PublishingZonePipe,
    // Components
    MapComponent,
    GoogleMapsComponent,
    OpenImgComponent,
    ExportListComponent,
    ModalComponent,
    TrackGuideComponent,
    TrackGuideDetailComponent,
    GuideListDetailsComponent,
    TrackGuideSearchComponent,
    ItemCargoFromGuidesComponent,
    HeaderComponent,
    FooterComponent,
    NavComponent,
    NotificationComponent,
    PreviewNotificationComponent,
    InactivityModalComponent,
    FilesStorageComponent,
    SelectCompanyComponent,
    SelectLicensePlateComponent,
    AlertCargoComponent,
    SelectGpsComponent,
    StateGuideComponent,
    LicensePlateComponent,
    DialogComponent,
    ConfirmComponent,
    DialogSurveyComponent,
    AutocompleteCityComponent,
    TypeVehicleComponent,
    VehicleBodyWorkTypeComponent,
    ListFilterComponent,
    ListOperationsComponent,
    ConventionsComponent,
    AutocompleteAddressComponent,
    AutocompleteThirdpartyComponent,
    AutocompleteThirdpartyAddressComponent,
    FragmentedAddressComponent,
    FileComponent,
    CargoEvidenceComponent,
    ConfirmEndCargoComponent,
    BasicPersonComponent,
    InputDocumentUserComponent,
    InputLicensePlateTrailerComponent,
    ModalLicensePlateComponent,
    DriverCargoComponent,
    ChangeStateGuideComponent,
    InfoBoxComponent,
    ChangeStateGuideComponent,
    AssignedCargoListComponent,
    AssignedCargoItemListComponent,
    QuickPayComponent,
    UserDocumentInfoComponent,
    RequestTermsComponent,
    UserCardComponent,
    StandardMapComponent,
    ModalSelectionMapComponent,
    GpsNotificationComponent,
    PreviewNotificationComponent,
    MultipleAddressesComponent,
    StandardFileComponent,
    MasterTestingMenuDialogComponent,
    MasterTestigChangeRoleComponent,
    MasterTestigRepresentJsonComponent,
    MultipleAddressesComponent,
    StandardFileComponent,
    PermissionFlowComponent,
    PermissionFlowDialogComponent,
    ApproveCargoDialogComponent,
    DatetimePickerComponent,
    EmailTypeNamePipe,
    LegalizeStatePipe,
    AlertrndcComponent,
    ImageThumbnailListComponent,
    FromStoragePipe,
    UploadResourcesComponent,
    ManualDataVehicleInputComponent,
    GenerateManifestActionComponent,
    PersonInfoComponent,
    AssignCostCenterComponent,
    PrevisualizationResourcesComponent,
    FilterTrackingReportsComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgbModule,
    MDBBootstrapModule,
    IconsModule,
    AngularEditorModule,
    AngularFormsInputMasksModule,
    // Ngx
    NgScrollbarModule,
    NgxMaskModule,
    NgxSpinnerModule,
    NgxCaptchaModule,
    NgxSkeletonLoaderModule,
    // Firebase
    AngularFireModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
    // AGM
    AgmCoreModule,
    AgmDirectionModule,
    AgmJsMarkerClustererModule,
    InfiniteScrollModule,
    // Material
    MatMenuModule,
    MatNativeDateModule,
    MatDatepickerModule,
    NgxMatMomentModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatRadioModule,
    MatSidenavModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatStepperModule,
    MatSnackBarModule,
    MatChipsModule,
    MatGoogleMapsAutocompleteModule,
    MatTableModule,
    MatTreeModule,
    MatBadgeModule,
    MatPaginatorModule,
    // Components
    MapComponent,
    GoogleMapsComponent,
    OpenImgComponent,
    ExportListComponent,
    ModalComponent,
    TrackGuideComponent,
    TrackGuideDetailComponent,
    GuideListDetailsComponent,
    TrackGuideSearchComponent,
    ItemCargoFromGuidesComponent,
    HeaderComponent,
    FooterComponent,
    NavComponent,
    NotificationComponent,
    PreviewNotificationComponent,
    InactivityModalComponent,
    FilesStorageComponent,
    SelectCompanyComponent,
    SelectLicensePlateComponent,
    AlertCargoComponent,
    SelectGpsComponent,
    StateGuideComponent,
    LicensePlateComponent,
    AutocompleteCityComponent,
    TypeVehicleComponent,
    VehicleBodyWorkTypeComponent,
    ListFilterComponent,
    ListOperationsComponent,
    ConventionsComponent,
    AutocompleteAddressComponent,
    AutocompleteThirdpartyComponent,
    AutocompleteThirdpartyAddressComponent,
    FragmentedAddressComponent,
    FileComponent,
    CargoEvidenceComponent,
    ConfirmEndCargoComponent,
    BasicPersonComponent,
    InputDocumentUserComponent,
    InputLicensePlateTrailerComponent,
    ModalLicensePlateComponent,
    ChangeStateGuideComponent,
    InfoBoxComponent,
    AssignedCargoListComponent,
    AssignedCargoItemListComponent,
    QuickPayComponent,
    UserDocumentInfoComponent,
    StandardMapComponent,
    ModalSelectionMapComponent,
    DatetimePickerComponent,
    MultipleAddressesComponent,
    StandardFileComponent,
    DatetimePickerComponent,
    AlertrndcComponent,
    AssignCostCenterComponent,
    PrevisualizationResourcesComponent,
    FilterTrackingReportsComponent,
    // Directives
    AppPasswordDirective,
    OnlyNumberDoubleDirective,
    OnlyNumberIntDirective,
    PatternFieldDirective,
    RoleAccessDirective,
    CurrencyMaskDirective,
    SelectItemListDirective,
    PermissionTabDirective,
    PermissionDisplayDirective,
    ValidateBtnCreateCargoDirective,
    DocumentTypeDirective,
    DriverCargoComponent,
    CurrencyMaskDirective,
    LicensePlateDirective,
    ContainerNumberDirective,
    DynamicHostDirective,
    UpperCaseDirective,
    MassivePaymentsDirective,
    MaxCharDirective,
    // Pipes
    MillisecondsDigitalClockPipe,
    SafePipe,
    RoleTranslatePipe,
    DurationPipe,
    StateGuidePipe,
    DateFormatPipe,
    MToKmPipe,
    RoleFunctionalityPipe,
    RoleModulePipe,
    PhoneNumberPipe,
    CapitalizeTextPipe,
    GpsTypePipe,
    MToKmPipe,
    PaymentTypePipe,
    FormControlPipe,
    CompanyNamePipe,
    FileNamePipe,
    BasicStatePipe,
    PdfTitlePipe,
    StateUserPipe,
    EmailTypeNamePipe,
    CargoTaxesPipe,
    TaxesOwnFleetPipe,
    LegalizeStatePipe,
    UserCardComponent,
    NgxMatMomentModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    GpsNotificationComponent,
    PreviewNotificationComponent,
    ApproveCargoDialogComponent,
    ImageThumbnailListComponent,
    FromStoragePipe,
    PublishingZonePipe,
    UploadResourcesComponent,
    ManualDataVehicleInputComponent,
    GenerateManifestActionComponent,
    PersonInfoComponent,
  ],
  entryComponents: [
    DialogComponent,
    ConfirmComponent,
    DialogSurveyComponent,
    MapComponent,
    GoogleMapsComponent,
    OpenImgComponent,
    SelectCompanyComponent,
    SelectLicensePlateComponent,
    AlertCargoComponent,
    SelectGpsComponent,
    StateGuideComponent,
    CargoEvidenceComponent,
    ConfirmEndCargoComponent,
    InputDocumentUserComponent,
    InputLicensePlateTrailerComponent,
    ModalLicensePlateComponent,
    InactivityModalComponent,
    TrackGuideDetailComponent,
    GuideListDetailsComponent,
    UserDocumentInfoComponent,
    RequestTermsComponent,
    UserCardComponent,
    StandardMapComponent,
    ModalSelectionMapComponent,
    GpsNotificationComponent,
    PreviewNotificationComponent,
    AutocompleteAddressComponent,
    StandardFileComponent,
    AutocompleteAddressComponent,
    StandardFileComponent,
    MasterTestingMenuDialogComponent,
    ApproveCargoDialogComponent,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        File,
        Utils,
        Dialog,
        AuthService,
        MediaService,
        RoleService,
        GoogleService,
        CatalogService,
        FileService,
        ManualCreationCargoService,
        SigService,
        Global,
        StatusCode,
        Companies,
        Titles,
        MessagingService,
        CreateCargoFromGuidesService,
        SafePipe,
        AsyncPipe,
        DateFormatPipe,
        GpsTypePipe,
        LegalizeStatePipe,
        PublishingZonePipe,
        Endpoints,
        GeocodeService,
        PathsStorages,
        ModelRoleComany,
        TicketTypes,
        ReadXLSX,
        Patterns,
        BasicStatePipe,
        PdfTitlePipe,
        StateUserPipe,
        PaymentTypePipe,
        FormControlPipe,
        CargoTaxesPipe,
        TaxesOwnFleetPipe,
        CurrencyPipe,
        CompanyManager,
        CargoManager,
        // Enums
        PermissionRole,
        DateManager,
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: LOCALE_ID, useValue: 'es-*' },
        { provide: HTTP_INTERCEPTORS, useClass: AppInterceptorService, multi: true },
        ClusterManager,
        { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
        { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS },
        { provide: DateAdapter, useClass: PickDateAdapter },
        { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
        { provide: NGX_MAT_DATE_FORMATS, useValue: DATETIME_FORMATS }
      ]
    };
  }
}
