import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { VehiclesService } from '../list-vehicles.service';
import { BasicResponse } from 'src/app/core/interfaces/basicResponse';

@Component({
  selector: 'app-create-gps-vehicle',
  templateUrl: './create-gps-vehicle.component.html',
  styleUrls: ['./create-gps-vehicle.component.scss']
})

export class CreateGpsVehicleComponent {

  reactiveForm: ReactiveForm;
  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  public arrayGPS: { name: string, id: string }[] = [];

  GPSForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    id: new FormControl('', [Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<CreateGpsVehicleComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogParams: any,
    public utils: Utils,
    private spinner: NgxSpinnerService,
    private vehiclesService: VehiclesService,
    private snackBarService: SnackBarService,
    public formBuilder: FormBuilder,
  ) { }

  /**
  * @description Verifies if the GPSForm is valid to push a new GPS it into arrayGPS
  */
  public addGPS(): void {
    this.GPSForm.markAllAsTouched();
    if (this.utils.errorMessagesCustomized(this.GPSForm.get('name'), 'nombre')) return;
    if (this.utils.errorMessagesCustomized(this.GPSForm.get('id'), 'url')) return;
    this.arrayGPS.push(this.GPSForm.value);
    this.GPSForm.controls['name'].setValue('');
    this.GPSForm.controls['id'].setValue('');
  }

  /**
  * @param {number} index is the index of the GPS to delete of arrayGPS 
  * @description Deletes a GPS from arrayGPS
  */
  public deleteGPS(index: number) {
    this.arrayGPS.splice(index, 1);
  }

  /**
  * @description Creates the list of GPS added on arrayGPS and closes the dialog if the process is success
  */
  public onSubmit() {
    if (this.arrayGPS.length > 0) {
      this.spinner.show();
      this.vehiclesService.createGPS(this.arrayGPS).toPromise()
        .then((success: BasicResponse) => {
          this.emitToParent.emit();
          this.snackBarService.openSnackBar('GPS creado correctamente');
          this.dialogRef.close({ state: true });
          this.spinner.hide();
        })
        .catch((error) => {
          console.error(error)
          this.snackBarService.openSnackBar('Ocurrió un error al crear el GPS', undefined, 'error');
        })
        .finally(() => {
          this.spinner.hide();
        });
    } else {
      this.snackBarService.openSnackBar('Debe añadir como minimo un GPS', undefined, 'alert');
    }
  }
}
