import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { PaginationList } from 'src/app/core/models/pagination-list';
import { Permission } from 'src/app/core/resources/permission';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { MaintenanceService } from '../maintenance.service';

@Component({
  selector: 'app-maintenance-list-vehicle',
  templateUrl: './maintenance-list-vehicle.component.html',
  styleUrls: ['./maintenance-list-vehicle.component.scss']
})
export class MaintenanceListVehicleComponent implements OnInit {
  filters: string = '';
  paginationList: PaginationList = new PaginationList();
  permission = Permission;
  holderCompany: string = this.authService.getUserSession() && this.authService.getUserSession().clientCompanyId ? this.authService.getUserSession().clientCompanyId : null;
  listVehicles: Vehicle[] = [];
  flag: boolean = false;
  constructor(
    private utils: Utils,
    private spinner: NgxSpinnerService,
    private snackbarService: SnackBarService,
    private maintenanceService: MaintenanceService,
    private authService: AuthService,
  ) { }

  async ngOnInit() {
    if (!(this.maintenanceService && this.maintenanceService.vehicleTypes && this.maintenanceService.vehicleTypes.length)) await this.maintenanceService.getVehicleTypes();
    if (!sessionStorage.getItem('maintenanceFilters')) this.getListVehicles();
  }

  getListVehicles($event?: string) {
    this.flag = false;
    let paramsFilters: string = '';
    paramsFilters += `&holderCompany=${this.holderCompany}`;
    paramsFilters += $event ? $event : '';
    const listVehiclesObserver = {
      next: (data: Vehicle[]) => {
        this.spinner.hide();
        if (!this.utils.isDefined(data) || data.length === 0) {
          this.paginationList.setEnablePagingScroll(false);
          data = [];
          return;
        }

        data.forEach(vehicle => {
          vehicle.typeVehiclePath = this.maintenanceService.getNameIconVehicle(vehicle);
        });
        if (data.length < this.paginationList.getPageSize()) this.paginationList.setEnablePagingScroll(false);
        if (this.paginationList.getList().length) this.paginationList.setList(this.paginationList.getList().concat(data));
        else this.paginationList.setList(data);
        this.flag = true;
      },
      error: () => {
        this.spinner.hide();
        this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
        if (!(this.paginationList.getList().length > 0)) {
          this.resetPagging();
          this.paginationList.setList([]);
        }
        this.flag = true;
      },
      complete: () => {
        this.flag = true;
      }
    };
    this.spinner.show();
    this.maintenanceService.getListVehiclesDetail(
      paramsFilters,
      this.paginationList.getPageKey(),
      this.paginationList.getPageSize()
    ).subscribe(listVehiclesObserver);

  }


  resetPagging() {
    this.paginationList.setPageKey(1);
    this.paginationList.setList([]);
    this.paginationList.setEnablePagingScroll(true);
  }

  refreshList($event?: string) {
    !this.paginationList
      ? this.paginationList.setList([])
      : this.resetPagging();

    this.filters = $event;
    this.getListVehicles(this.filters);
  }

  onScrollDown() {
    if (
      this.paginationList.getEnablePagingScroll()
    ) {
      this.paginationList.setPageKey(
        this.paginationList.getPageKey() + 1
      );
      this.getListVehicles(this.filters);
    }
  }

  processFilters($event: { type: string, filtersValues: string }) {
    this.refreshList($event && $event.filtersValues ? $event.filtersValues : '');
  }



}
