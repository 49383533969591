<div *ngIf="address">

  <div class="view-detail-evidences">
    <div class="main-image-container hide-main-expand">

      <img *ngIf="resourceType === 'image'" class="main-image" id="main-image-id"
        [ngClass]="{'full-size':expandedImage}" [src]="mainImageUrl" alt="">
      <iframe *ngIf="resourceType === 'pdf'" class="main-image" width="100%" height="500px" [src]="mainImageUrl"
        frameBorder="0"></iframe>
      <div *ngIf="resourceType === 'notResource' || resourceType === 'errorLoadedResource'" class="not-resource">
        <p>{{ resourceType === 'notResource' ? 'No se han subido evidencias' : 'Hubo un error cargando la evidencia'}}
        </p>
      </div>
    </div>
    <div class="preview-image-container" *ngIf="!expandedImage">
      <div class="d-flex flex-row justify-content-between">
        <p class="address-title"> {{ type === 'origin' ? 'Origen' : 'Destino'}} {{index + 1}}: {{address.address}}
        </p>
        <div class="xmark-container"><i class="fas fa-times"
            (click)="emitToParent.emit({type: 'closeDetail', value: false})"></i></div>
      </div>

      <div class="main-image-container hide-main-small">
        <img *ngIf="resourceType === 'image'" class="main-image" id="main-image-id"
          [ngClass]="{'full-size':expandedImage}" [src]="mainImageUrl" alt="">
        <iframe *ngIf="resourceType === 'pdf'" class="main-image" width="100%" height="500px" [src]="mainImageUrl"
          frameBorder="0"></iframe>
        <div *ngIf="resourceType === 'notResource' || resourceType === 'errorLoadedResource'" class="not-resource">
          <p>{{ resourceType === 'notResource' ? 'No se han subido evidencias' : 'Hubo un error cargando la evidencia'}}
          </p>
        </div>
      </div>


      <div>
        <p class="doc-title">Evidencias</p>
        <div class="d-flex flex-row align-items-center">
          <i *ngIf="address?.imagesDocument?.length" class="fas fa-chevron-left color-primary cursor-pointer"
            (click)="carrouselChange('left', address?.imagesDocument?.length, 'document')"
            [ngClass]="{'ds-color': lastItemLeftDocument}"></i>
          <div *ngIf="address?.imagesDocument?.length; else notEvidencesDocument" class="d-flex">
            <div *ngFor="let imageDocument of address.imagesDocument; let i=index;">
              <div>
                <img *ngIf="i >= startIndexDocument && i < endIndexDocument" (click)="changeActiveImage(imageDocument)"
                  class="preview-image"
                  [ngClass]="{'active-image': imageDocument?.sanitizeUrlPdf ? imageDocument?.sanitizeUrlPdf === mainImageUrl : getErrorLoadedResource(imageDocument?.url) && imageDocument?.url === mainImageUrl}"
                  [src]="imageDocument?.url" alt="">
              </div>
            </div>
          </div>
          <ng-template #notEvidencesDocument>
            <div class="d-flex flex-column w-100">
              <div class="not-evidences-title text-center">
                No se han subido evidencias
              </div>
              <div class="not-evidences-icon">
              </div>
            </div>
          </ng-template>
          <i *ngIf="address?.imagesDocument?.length" class="fas fa-chevron-right color-primary cursor-pointer"
            (click)="carrouselChange('right', address?.imagesDocument?.length, 'document')"
            [ngClass]="{'ds-color': lastItemRightDocument}"></i>
        </div>
      </div>

      <div *ngIf="!!address?.imagesTruck?.length">
        <p class="doc-title">Evidencia del camión cargado</p>
        <div class="d-flex flex-row align-items-center">
          <i *ngIf="address?.imagesTruck?.length" class="fas fa-chevron-left color-primary cursor-pointer"
            (click)="carrouselChange('left', address?.imagesTruck?.length, 'truck')"
            [ngClass]="{'ds-color': lastItemLeftTruck}"></i>
          <div *ngIf="address?.imagesTruck?.length; else notEvidencesTruck" class="d-flex">
            <div *ngFor="let imagesTruck of address.imagesTruck; let i=index;">
              <div>
                <img *ngIf="i >= startIndexTruck && i < endIndexTruck" (click)="changeActiveImage(imagesTruck)"
                  class="preview-image"
                  [ngClass]="{'active-image': imagesTruck?.sanitizeUrlPdf ? imagesTruck?.sanitizeUrlPdf === mainImageUrl : getErrorLoadedResource(imagesTruck?.url) && imagesTruck?.url === mainImageUrl}"
                  [src]="imagesTruck?.url" alt="">
              </div>
            </div>
          </div>
          <ng-template #notEvidencesTruck>
            <div class="d-flex flex-column w-100">
              <div class="not-evidences-title text-center">
                No se han subido evidencias
              </div>
              <div class="not-evidences-icon">
              </div>
            </div>
          </ng-template>
          <i *ngIf="address?.imagesTruck?.length" class="fas fa-chevron-right color-primary cursor-pointer"
            (click)="carrouselChange('right', address?.imagesTruck?.length, 'truck')"
            [ngClass]="{'ds-color': lastItemRightTruck}"></i>
        </div>
      </div>

      <div *ngIf="type === 'origin'; else destination">
        <p class="cargo-detail-title mb-1">Detalles de {{isEscortedService?'origen':'cargue'}}</p>
        <p class="cargo-detail-items m-0">Ciudad origen: {{ dataCargo?.city ? dataCargo?.city : '-'}}</p>
        <p class="cargo-detail-items m-0">Fecha de {{isEscortedService?'llegada al origen':'cargue'}}:
          {{dataCargo?.dateLoad ? (formatDate(dataCargo?.dateLoad)) :
          '-'}}</p>
        <p class="cargo-detail-items m-0">Hora de {{isEscortedService?'llegada al origen':'cargue'}}: {{address?.time ?
          (address?.time | dateFormat: 'only-time') :
          '-'}}</p>
        <p class="cargo-detail-items m-0">Inicio de {{isEscortedService? 'inicio del servicio':'cargue'}}:
          {{address?.durationTime?.startDate ?
          (formatDate(address?.durationTime?.startDate)) : '-'}}</p>
        <p class="cargo-detail-items m-0" *ngIf="!isEscortedService">Duración de cargue:
          {{address?.durationTime?.duration ?
          (address?.durationTime?.duration | millisecondsDigitalClock) : '-'}}</p>
      </div>
      <ng-template #destination>
        <p class="cargo-detail-title mb-1">Detalles de {{isEscortedService?'destino':'descargue'}}</p>
        <p class="cargo-detail-items m-0">Ciudad destino: {{dataCargo?.city ? dataCargo?.city : '-'}}</p>
        <p class="cargo-detail-items m-0">Fecha de {{isEscortedService?'llegada al destino':'descargue'}}: {{
          dataCargo?.dateLoad ? (formatDate(dataCargo?.dateLoad))
          : '-'}}</p>
        <p class="cargo-detail-items m-0">Hora de {{isEscortedService?'llegada al destino':'descargue'}}: {{
          address?.time ? (address?.time | dateFormat:
          'only-time') : '-'}}</p>
        <p class="cargo-detail-items m-0" *ngIf="!isEscortedService">Inicio de descargue: {{
          address?.durationTime?.startDate ?
          (formatDate(address?.durationTime?.startDate)) : '-'}}</p>
        <p class="cargo-detail-items m-0" *ngIf="!isEscortedService">Duración de descargue: {{
          address?.durationTime?.duration ?
          (address?.durationTime?.duration | millisecondsDigitalClock) : '-'}}</p>
      </ng-template>


    </div>
  </div>
</div>