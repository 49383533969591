<div class="position-relative">
  <i *ngIf="dialogParams && dialogParams.enableClose" matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions"
    (click)="dialogRef.close()"></i>
  <!--Primary-->
  <div class="wrapper-modal" *ngIf="cargo && cargo.id && utils.isEmpty(state) ">
    <p class="title">
      ¿Recibiste bien tu servicio?
    </p>
    <p class="container-ubication">
      <span class="text-strong">
        {{cargo.cargoFeature.uploadDownload.origin.name}}
      </span>
    <figure class="arrow-right-ubication">
      <img src="/assets/svg/icons/icon-arrow-right.png" alt="">
    </figure>
    <span class="text-strong">
      {{cargo.cargoFeature.uploadDownload.destination[cargo.cargoFeature.uploadDownload.destination.length - 1].name}}
    </span>
    </p>
    <p class="text-center">
      Nº Documento remitente
    </p>
    <p class="text-strong text-center">
      {{cargo.numberDocumentSender}}
    </p>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="cargoReceived()" mdbWavesEffect>Si</button>
      <a (click)="cargoNotReceived()">No</a>
    </mat-card-actions>
  </div>
  <!-- Modal Confirm Load Negative -->
  <div class="modal-body wrapper-modal" *ngIf="cargo && cargo.id && state=='NoRecived'">
    <p class="title">
      ¿Por qué no recibiste bien tu servicio?
    </p>
    <p class="container-checks">
      <mat-checkbox class="mx-2">Faltantes</mat-checkbox>
      <mat-checkbox class="mx-2">Avería</mat-checkbox>
      <mat-checkbox class="mx-2">Demora</mat-checkbox>
      <mat-checkbox class="mx-2">Otro</mat-checkbox>
    </p>
    <p class="container-checks">
      <textarea class="text-area-comment form-control" placeholder="Envíanos tus comentarios"></textarea>
    </p>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="onNoClick()" mdbWavesEffect>Enviar</button>
      <a (click)="openModalConfirmCargo()">Cancelar</a>
    </mat-card-actions>
  </div>
  <!-- Modal Confirm Load Positive -->
  <div class="modal-body wrapper-modal px-2" *ngIf="cargo && cargo.id&& state=='recived'">
    <p class="title">
      Califica a tu conductor
    </p>
    <p class="container-driver">
      <ngb-rating [(rate)]="currentRate" [max]="5">
        <ng-template let-fill="fill" let-index="index">
          <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
        </ng-template>
      </ngb-rating>
    </p>
    <p class="container-checks px-3">
      <textarea class="text-area-comment form-control" [(ngModel)]="commentRateCargo"
        placeholder="Envíanos tus comentarios"></textarea>
    </p>
    <div class="button-container mt-2 mb-4">
      <a (click)="openModalConfirmCargo()">Cancelar</a>
      <button mat-raised-button class="bg-color-primary" color="primary" (click)="onSubmit()"
        [disabled]="disabledButtonRate()" mdbWavesEffect>Enviar calificación</button>
    </div>
  </div>
</div>