<div class="main_track-guide">
  <div id="imgtruck" class="carousel slide" data-ride="carousel" style="width: 100%">
    <div class="container-fluid p-0">
      <div class="content">
        <div class="text1">
          Localiza el servicio
        </div>
        <div class="container">
          <div class="row">
            <div class="col align-self-center" class="track-guide-aplication">
              <div class="row align-items-baseline">
                <div class="col-12 col-md-4 align-center">
                  <label class="label-field"> Escribe el ID del servicio </label>
                </div>
                <div class="col-12 col-md-4 align-center mb-4 mb-md-0">
                  <mat-form-field>
                    <mat-label>ID del servicio<span class="asterisk">*</span></mat-label>
                    <input matInput type="text" [formControl]="cargoId" onlyNumbersInt placeholder="ID del servicio">
                    <mat-error *ngIf="cargoId.errors?.required">
                      {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                    <mat-error *ngIf="cargoId.errors?.maxlength">
                      {{ utils.giveMessageError('MAX_LENGTH_EXCEED', 'ID del servicio', 6) }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-4 button-container">
                  <button mat-raised-button class="bg-color-primary" type="button" (click)="onSubmit()">
                    Buscar servicio
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>