import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatAutocompleteSelectedEvent, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { BehaviorSubject, zip } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { CargoService } from 'src/app/core/services/cargo.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { CargoDetailService } from '../cargo-detail/cargo-detail.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { OptionsDialog } from 'src/app/core/interfaces/options-dialog';

type DataMoveAdvance = {
  cargos: Array<Cargo>
}

@Component({
  selector: 'app-move-advance-dialog',
  templateUrl: './move-advance-dialog.component.html',
  styleUrls: ['./move-advance-dialog.component.scss']
})
export class MoveAdvanceDialogComponent implements OnInit {
  valid = false;
  form: FormGroup = new FormGroup({
    origins: new FormArray([], [Validators.required]),
    destinations: new FormArray([], [Validators.required])
  });

  cargoEvents: { [key: string]: EventEmitter<Array<Cargo>> } = {};
  lastSearchId = null;

  constructor(
    public dialogRef: MatDialogRef<MoveAdvanceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataMoveAdvance,
    private cargoService: CargoService,
    private cargoDetailService: CargoDetailService,
    private snackbarService: SnackBarService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) {
    for (const index in data.cargos) {
      const cargo = data.cargos[index];

      (this.form.get('origins') as FormArray)
        .push(new FormControl(cargo.consecutive, [Validators.required]));

      (this.form.get('destinations') as FormArray)
        .push(new FormControl(null, [Validators.required]))

      this.cargoEvents[index] = new EventEmitter<Array<Cargo>>();
    }
  }

  ngOnInit() { }

  getMovements() {
    const origins = this.form.get('origins') as FormArray;
    const destinations = this.form.get('destinations') as FormArray;

    let movements = '';
    for (let i = 0; i < origins.length; i++) {
      movements += `${origins.at(i).value} a ${destinations.at(i).value},`;
    }
    return movements.slice(0, -1);
  }

  submit() {
    const config = new MatDialogConfig();
    config.data = {
      title: `
        Está a punto de trasladar los anticipos de:
        ${this.getMovements()}
        ¿Desea trasladar los anticipos?`,
      showNoBtn: true,
      showYesBtn: true,
      hideBtnCancel: true,
      icon: false
    };
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.width = ModalEnum.EXTRA_SMALL_WIDTH;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    config.autoFocus = false;
    this.dialog.open(DialogComponent, config)
      .afterClosed()
      .subscribe(
        (result) => {
          if (result && result.state) {
            this.moveAdvance();
          }
        }
      );
  }

  moveAdvance() {
    const origins = this.form.get('origins') as FormArray;
    const destinations = this.form.get('destinations') as FormArray;

    const requests = [];
    for (let i = 0; i < origins.length; i++) {
      requests.push(
        this.cargoService
          .moveAdvance(
            origins.at(i).value,
            destinations.at(i).value
          )
      );
    }

    const error_config = new MatDialogConfig();
    error_config.data = {
      title: `Operación fallida`,
      description: `Es posible que uno o varios de los traslados no se hayan producido correctamente.`,
      iconError: true,
      hideBtnConfirm: false,
      hideBtnCancel: true,
    } as OptionsDialog;

    error_config.maxHeight = ModalEnum.MAX_HEIGHT;
    error_config.maxWidth = ModalEnum.MAX_WIDTH;
    error_config.width = ModalEnum.SMALL_WIDTH;
    error_config.autoFocus = false;

    this.spinner.show();
    zip(...requests)
      .subscribe(
        () => {
          this.spinner.hide();
          this.snackbarService
            .openSnackBar('Operación completada.');
        },
        _ => {
          this.spinner.hide();
          this.dialog.open(DialogComponent, error_config)
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  changeSelectedCargo(index: number, event: MatAutocompleteSelectedEvent) {
    const consecutive = `${event.option.value}`;

    (this.form.get('destinations') as FormArray).at(index).setValue(consecutive);
    this.valid = this.form.valid;
  }

  searchCargo(index: string, event: Event) {
    const consecutive = (event.target as HTMLInputElement).value;
    if (consecutive == '')
      return this.cargoEvents[index].emit([]);

    if (this.lastSearchId)
      clearTimeout(this.lastSearchId);

    this.lastSearchId = setTimeout(
      () => {
        this.spinner.show();

        try {
          this.cargoDetailService
            .detailCargoByConsecutive(consecutive)
            .subscribe(
              (cargo: Cargo) => {
                this.cargoEvents[index].emit([cargo]);
                this.spinner.hide();
              },
              () => {
                this.cargoEvents[index].emit([]);
                this.spinner.hide();
              },
              () => this.spinner.hide()
            );
        } catch (error) {
          this.cargoEvents[index].emit([]);
          this.spinner.hide();
        }
      },
      600
    );
  }
}
