<div class="receivable-form-container">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <div class="receivable-title">
    <i class="fas fa-file-invoice-dollar mr-2"></i>Creación de cartera
  </div>
  <form [formGroup]="form" (submit)="onSubmit()" class="pt-3">
    <div class="row">
      <div class="col-12 col-md-6 mb-2">
        <mat-form-field class="cargo-id-input">
          <mat-label>Id del servicio asociado<span class="asterisk">*</span></mat-label>
          <input type="text" matInput onlyNumbersInt formControlName="cargoConsecutive" placeholder="Ej. 11223">
          <mat-error *ngIf="form.get('cargoConsecutive').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6 mb-2">
        <mat-form-field class="amount-initial-input">
          <mat-label>Monto inicial de la cartera<span class="asterisk">*</span></mat-label>
          <input mask="separator.0" thousandSeparator="." type="text" matInput formControlName="amountInitial"
            placeholder="Ej. 300.000">
          <mat-error *ngIf="form.get('amountInitial').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 mb-2">
        <mat-form-field class="reason-input">
          <mat-label>Razón de la cartera<span class="asterisk">*</span></mat-label>
          <textarea type="text" matInput formControlName="reason"
            placeholder="Ej. dos botellas de whisky rotas..."></textarea>
          <mat-error *ngIf="form.get('reason').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="who-title col-12">
        <i class="fas fa-user-lock mr-2 mb-2"></i>¿Quién lo reportó?
      </div>
      <app-user-document-info [docInfo]="form.get('whoReports')" [validate]="validate"
        class="col-12"></app-user-document-info>
    </div>
    <div class="row button-container">
      <button type="button" mat-raised-button class="primary-secondary" color="primary" (click)="closeDialog()">
        Cancelar
      </button>
      <button mat-raised-button class="submit-button" color="primary" type="submit">
        Crear cartera
      </button>
    </div>
  </form>
</div>