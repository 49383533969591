import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
  name: "roleFunctionality",
})
export class RoleFunctionalityPipe implements PipeTransform {
  constructor() { }
  transform(functionality) {
    switch (functionality) {
      //ADMINISTRATION MODULE
      case "adminTerms":
        return "Términos y Condiciones";
      case "adminVehicles":
        return "Vehículos";
      case "adminTrailers":
        return "Trailers";
      case "adminCompanies":
        return "Empresas";
      case "adminUsers":
        return "Usuarios";
      case "setupMonitorIntegration":
        return "Habilitar/Deshabilitar integración GPS (Servicio con costo)";
      case "truoraConfiguration":
        return "Habilitar/Deshabilitar estudio de seguridad";
      case "vehicleDocumentsConfiguration":
        return "Configurar documentos adicionales requeridos del vehículo";
      case "showDocumentsPendingDrivers":
        return "Mostrar conductores con documentos por validar";
      case "validateDocumentsPendingDrivers":
        return "Validar documentos pendientes de conductores";
      case "showSecurityAnalysis":
        return "Mostrar análisis de seguridad";
      case "disableEnableUsers":
        return "Desactivar / Activar usuarios";
      case "disableEnableDriver":
        return "Desactivar / Activar conductores";
      case "checkUser":
        return "Validar estudio de seguridad";
      case "activateByScore":
        return "Activar conductores por puntaje bajo";
      case "createTermsConditions":
        return "Crear terminos y condiciones";
      case "downloadFileRegistreBank":
        return "Descargar archivo para pagos";
      case "createVehicle":
        return "Crear vehículos";
      case "updateVehicle":
        return "Editar información de vehículos";
      case "editFieldsRUNT":
        return "Editar campos RUNT";
      case "activateVehicle":
        return "Activar vehículos";
      case "updateBankAdvance":
        return "Actualizar información bancaria de anticipos | Vehículos";
      case "updateBankBalance":
        return "Actualizar información bancaria de saldos | Vehículos";
      case "createCompany":
        return "Crear empresas";
      case "updateCompany":
        return "Actualizar empresas";
      case "createRoles":
        return "Crear roles";
      case "updateRoles":
        return "Actualizar roles";
      case "changeRole":
        return "Cambiar usuario de rol";
      case "adminRoles":
        return "Roles";
      case "adminModules":
        return "Módulos";
      case "changeDriverVehicle":
        //No se usa esta funcionalidad por ahora
        return "Cambiar conductor del vehículo";
      case "validateAndRemarkVehicleReferences":
        return "Validar y anotar las referencias de los vehículos";
      case "showCreateGps":
        //No se usa ni está referenciada en ningúna parte
        return "Crear GPS";
      case "modifyBasicFieldsUser":
        return "Modificar información básica del usuario";
      case "showDeleteGps":
        return "Eliminar GPS";
      case "useGpsPortable":
        return "Usar GPS portable";
      case "alertValidGps":
        return "Alertar GPS válido para iniciar servicios";
      case "editCompliments":
        return "Editar requisitos de cumplido";
      case "createCompanyComplimentType":
        return "Agregar tipos de requisitos de cumplido";
      case "editTags":
        return "Editar etiquetas del servicio";
      case "updateCity":
        return "Actualizar ciudades";
      case "linkCities":
        return "Enlazar subciudades y ciudades principales";
      case "addSubCities":
        return "Añadir subciudades";
      case "deleteSubCities":
        return "Eliminar subciudades";
      case "createEmailCompany":
        return "Añadir correos de la compañía";
      case "forceVehicleActivation":
        return "Forzar la activación de un vehículo";
      case "deactivateVehicle":
        return "Desactivar vehículo";
      case "validateVehicleDocuments":
        return "Validar los documentos de un vehículo";
      case "editAdminVehicle":
        return "Editar Administrador de un Vehículo";
      case "editOwnerVehicle":
        return "Editar propietario de un vehículo";
      case "editGpsVehicle":
        return "Editar GPS de un vehículo";
      case "editReferencesVehicle":
        return "Editar referencias y contacto de un vehículo";
      case "turnCreation":
        return "Crear enturnamiento";
      case "reactivateVehicleByInactivity":
        return "Reactivar vehículo por inactividad";
      case "changeFeeToOwnedFleet":
        return "Cambiar el cargo de los impuestos a flota propia";
      case "changeValidationRules":
        return "Cambiar las reglas de validación del vehículo";
      case "changeRolNotifications":
        return "Cambiar las notificaciones de un rol";
      case "automaticTotalCostCalculation":
        return "Calculo automático del flete"
      case "maintenanceDetail":
        return "Detalle de mantenimiento";
      case "createMaintenance":
        return "Crear mantenimiento";
      case "editMaintenance":
        return "Editar mantenimiento";
      case "deleteMaintenance":
        return "Eliminar mantenimiento";
      case "duplicateTransportRequest":
        return "Duplicar Servicio";
      case "updatePolicy":
        return "Actualizar póliza(s)";
      case "setUpPolicy":
        return "Configurar póliza(s)";
      case "changeCommercial":
        return "Cambiar comercial asociado a empresa";
      case "createDriver":
        return "Crear conductor";
      //CARGO MODULE
      case "createManualCargo":
        return "Crear servicio manual";
      case "createCargoRequest":
        return "Crear solicitudes de servicio";
      case "changeManifestRequirement":
        return "Cambiar requerimiento de manifiesto";
      case "searchCargo":
        return "Rastrear servicios"
      case "createCargoByGuides":
        return "Crear servicio por guías";
      case "createMassiveCargo":
        return "Crear servicio masivo";
      case "createCargoFromRoute":
        return "Crear servicio desde una ruta";
      case "createEscortService":
        return "Crear servicios de escolta";
      case "cargoRequest":
        return "Solicitudes";
      case "cargoRequestNegotiation":
        return "Negociación B2B";
      case "cargosInRoute":
        return "Servicios en ruta";
      case "scheduledCargo":
        return "Servicios programados";
      case "cargosInNegotiation":
        return "Servicios en negociación";
      case "finishedCargos":
        return "Servicios terminados";
      case "reports":
        return "Reportes";
      case "cargoItemFinancier":
        return "Servicio financiero";
      case "registerLocation":
        return "Agregar localización de conductor";
      case "showManifestError":
        return "Mostrar servicios con error en el manifiesto";
      case "rateCargo":
        return "Calificar servicios";
      case "uploadExternalManifest":
        return "Cargar manifiesto externo";
      case "editTravelExpenses":
        return "Editar viáticos de servicios";
      case "removeTravelExpenses":
        return "Eliminar viáticos de servicios";
      case "removeAcceptedTravelExpenses":
        return "Eliminar viáticos de servicios aunque estén aprobados";
      case "readDriverDetails":
        return "Leer los detalles del conductor";
      case "documentManifest":
        return "Acceder al manifiesto del servicio";
      case "documentManifestUrban":
        return "Acceder a la Planilla urbana";
      case "documentConsignment":
        return "Acceder a la remesa del servicio";
      case "documentConsignmentUrban":
        return "Acceder a la guía terrestre";
      case "documentOrderVehicle":
        return "Acceder a la orden de cargue/servicio";
      case "readManifest":
        return "Leer el manifiesto del servicio o equivalente";
      case "createRoundedCargo":
        return "Crear servicio redondo";
      case "watchLiveGPS":
        //Está declarado pero no se usa
        return "Ver el GPS en vivo";
      case "modifyShippingCost":
        return "Agregar bonificaciones y descuentos";
      case "editCompliment":
        return "Editar cumplido inicial";
      case "readCargoCompliments":
        return "Consultar requisitos de cumplido";
      case "readDeletedCargoCompliments":
        return "Ver documentos eliminados de requisitos de cumplido";
      case "editCargoCompliments":
        return "Editar requisitos de cumplido del servicio";
      case "editCargoComplimentRequirements":
        return "Editar solo requisitos de cumplido individuales";
      case "uploadEvidences":
        return "Cargar evidencias";
      case "uploadExtraDocumentsCargo":
        return "Cargar evidencias extras";
      case "continueTripWithoutEvidences":
        return "Terminar servicio sin evidencias";
      case "removeEvidences":
        return "Eliminar las evidencias de un servicio";
      case "confirmLicensePlate":
        return "Confirmar vehículos";
      case "assignLicensePlate":
        return "Asignar vehículo";
      case "startCargo":
        return "Iniciar servicios";
      case "removeCargo":
        return "Eliminar servicios";
      case "cancelCargo":
        return "Desasociar vehiculo de servicio";
      case "approvePayments":
        return "Aprobación de servicios";
      case "expiredCargo":
        return "Servicios expirados";
      case "showRateCargo":
        return "Mostrar tarifa de servicio";
      case "createRequestFromManualCreationCargo":
        return "Crear solicitud desde creación de servicio";
      case "createCargoFromRequest":
        return "Crear servicio desde solicitud";
      case "showTotalCostCargo":
        return "Mostrar flete de servicio";
      case "showPaymentCargo":
        return "Mostrar historial de pagos";
      case "showAnomaliesCargo":
        return "Mostrar historial de novedades";
      case "restoreCargo":
        return "Restaurar servicio";
      case "editCargo":
        return "Editar servicios"
      case "finishCargo":
        return "Finalizar servicios";
      case "changeCompany":
        return "Cambiar compañía";
      case "reportAnomalies":
        return "Reportar novedades";
      case "changeRteica":
        return "Cambiar Reteica";
      case "transportedValueSemaphore":
        return "Semáforo por valor declarado o perfil de riesgo";
      case "advanceRequest":
        return "Solicitar pago de anticipo";
      case "moveAdvance":
        return "Trasladar anticipo";
      case "changeCargoLoadDate":
        return "Modificar la fecha de un servicio";
      case "paymentSemaphore":
        return "Semáforo de pago";
      case 'additionalCosts':
        return 'Servicios adicionales';
      case "additionalCostCreate":
        return "Crear servicios adicionales";
      case "additionalCostUpdate":
        return "Actualizar servicios adicionales";
      case "additionalCostDelete":
        return "Eliminar servicios adicionales";
      case "approveSemaphore":
        //Está declarado pero no se usa
        return "Semáforo de aprobación";
      case "showBillingData":
        return "Mostrar información de Facturación";
      case "generateAccountingFiles":
        return "Generar archivos contables";
      case "downloadJournalCargoPDF":
        return "Descargar PDF Comprobante Contable";
      case "generateJournalCargo":
        return "Generar Comprobante Contable";
      case "deletedCargo":
        return "Servicios eliminados";
      case "changeRateFullAccess":
        return "Agregar bonificaciones y descuentos sin restricción";
      case "assignVehicleFullAccess":
        return "Asignar vehículos sin restricción";
      //Ver el GPS en vivo (PERMISO ERRONEO!)
      case "createCargoByRoute":
        //Permiso declarado pero no se usa
        return 'Crear servicio por ruta';
      case 'showCostCargo':
        return 'Mostrar costos de servicio'
      case 'editAnomalies':
        return 'Modificar eventos de trazabilidad'
      case "confirmationAdditionalMandatoryServices":
        return "Confirmación de servicios adicionales obligatorios";
      case "editWeightConsignment":
        return "Editar peso de la remesa";
      case "editCargoAmount":
        return "Editar el valor declarado de la mercancia";
      case "bonusAndDiscountsInCargoRejected":
        return "Bonos y descuentos a servicio rechazado";
      case "sendEmailReportsLastLocations":
        return "Agregar correos envío reporte últimas localizaciones";
      case "createCargosWithoutMinimumUtility":
        return "Crear servicios urbanos de contenedor vacío sin restricción de utilidad";
      case 'showCreationDateCargo':
        return "Ver fecha de creación de servicios";
      case "editCargoTags":
        return "Editar etiquetas del servicio";
      case "editCargoCompliments":
        return "Editar cumplidos del servicio";
      case 'restartCargo':
        return 'Reiniciar servicios';
      case 'approveCargoUnrestricted':
        return 'Aprobar servicios sin restricción';
      case 'removeCargoUnrestricted':
        return 'Anular servicios sin restricción';
      case 'removeCargoWithPayments':
        return 'Anular servicios con pagos';
      case "loadDownloadStatePoints":
        return "Estado puntos del servicio";
      case "trackingReports":
        return "Reportes de seguimiento";
      case "addDocumentsSupport":
        return "Agregar soporte de documentos";
      case "saveDriverDocuments":
        return "Guardar documentos del conductor";
      case "sendEmailReport":
        return "Enviar reportes al correo";
      case "editAdvance":
        return "Editar anticipo";
      case "deletePayment":
        return "Eliminar pagos de un servicio";
      case "deleteBalancePayment":
        return "Eliminar pago del saldo de un servicio";
      case "editTypeVehicle":
        return "Editar tipo de vehículo";
      case "addObservation":
        return "Agregar observacion al servicio";
      case "earlyUploadEvidences":
        return "Subir evidencias de forma temprana";
      case "readVehicleDetails":
        return "Leer los detalles del vehículo";
      case "turn":
        return "Enturnamiento";
      case "editTurn":
        return "Editar enturnamiento";
      case "deleteTurn":
        return "Eliminar enturnamiento";
      case "viewAvailableLoads":
        return "Visualizar servicios disponibles";
      case "assignAvailableLoads":
        return "Asignar servicios disponibles";
      case "viewTurnVehicles":
        return "Visualizar vehículos enturnados";
      case "assignTurnVehicles":
        return "Asignar vehículos enturnados";
      case "additionalServicesWithoutUtilityRestriction":
        return "Servicios adicionales sin restricción";
      case "viewApproveInfo":
        return "Visualizar detalles de aprobación"
      case "approvedBelowSicetac":
        return "Aprobar servicios por debajo del SICETAC";
      case "evidencesFingerprint":
        return "Visualizar quien registró las evidencias";
      case "complimentsFingerprint":
        return "Visualizar quien registró los documentos requeridos de cumplido";
      case "startServiceWithArlExpired":
        return "Iniciar servicios con ARL vencida";
      case "startServiceWithEpsExpired":
        return "Iniciar servicios con EPS vencida";
      case "startServiceWithAdditionalCertificationsInvalid":
        return "Iniciar servicios con certificados adicionales inválidos";
      case "enableSimultaneousInrouteLoading":
        return "Habilitar servicios en ruta simultánea";
      case "useCarWithExpiredDocuments":
        return "Utilizar vehículos con documentación vencida";
      case "payWithTravelExpenses":
        return "Pagar los viáticos en el pago de anticipos";
      case "changeApprovedTravelExpenses":
        return "Realizar cambios a viáticos legalizados";
      case "reverseInitialComplitment":
        return "Anular los cumplidos iniciales";
      case "createBonusOrPenaltyToCargo":
        return "Crear conceptos de modificaciones al flete y tarifa de un servicio";
      case "serviceRequests":
        return "Solicitudes de servicio";
      case "createTransportRequests":
        return "Crear solicitudes";
      case "listRequestsAndApplications":
        return "Listar Solicitudes y Postulaciones";
      case "showOriginAddresses":
        return "Mostrar direcciones de origen";
      case "showDestinationAddresses":
        return "Mostrar direcciones de destino";
      case "allowToEditGeneralDataModule":
        return "Sección editar datos generales del servicio";
      case "showEditTagsModule":
        return "Sección editar etiquetas del servicio";
      case "editServiceTypeModule":
        return "Sección editar tipo de servicio";
      case "editPublicationZone":
        return "Editar zona de publicación del servicio";
      case "showServiceTypeModule":
        return "Sección ver tipo de servicio";
      case "editServiceCharacteristicsModule":
        return "Sección editar características del servicio";
      case "showServiceCharacteristicsModule":
        return "Sección ver características del servicio";
      case "editLoadDownloadModule":
        return "Sección editar origen y destino del servicio";
      case "showInitialFulfill":
        return "Sección ver cumplidos iniciales";
      case "showLoadDownloadModule":
        return "Sección ver origen y destino del servicio";
      case "showFinancialInformationModule":
        return "Sección ver información financiera del servicio";
      case "showEditFinancialInformationModule":
        return "Sección editar información financiera del servicio";
      case "showEditServiceNegotiationModule":
        return "Sección editar negociación del servicio";
      case "showEditRequestNegotiationModule":
        return "Sección editar negociación de la solicitud";
      case "showEndServiceInfoModule":
        return "Sección ver información de finalización del servicio";
      case "showDeleteServiceInfoModule":
        return "Sección ver información de eliminación del servicio";
      case "showAssignedServiceInfoModule":
        return "Sección ver información de asignación del servicio";
      case "showApprovalServiceInfoModule":
        return "Sección ver información de confirmación del vehículo del servicio";
      case "showDriverInfoModule":
        return "Sección ver información del conductor del servicio";
      case "showEscortInfoModule":
        return "Sección ver información de escolta del servicio";
      case "showActionsServiceModule":
        return "Sección ver acciones del servicio";
      case "createService":
        return "Crear Remesas/Servicios";
      case "skipReteicaValidation":
        return "Omitir validación de Reteica";
      case "createCargoWithRateOrCostZero":
        return "Crear servicios con tarifa o costo cero";
      case "generateManifest":
        return "Generar manifiesto";
      case "changeAssignSecondDriver":
        return "Cambiar o asignar segundo conductor";
      case "deleteSecondDriver":
        return "Eliminar segundo conductor";
      case "activateSecondDriver":
        return "Activar segundo conductor";
      case "createServicesWithExpiredPolicy":
        return "Crear servicios con póliza vencida";
      case "editConsignment":
        return "Editar remesas";
      case "assignUnassignCostCenterToService":
        return "Asignar/Desasignar centro de costo a un servicio";
      //PAYMENT MODULE
      case "approveBankAdvance":
        return "Aprobar información bancaria de anticipos | Vehículos";
      case "approveBankBalance":
        return "Aprobar información bancaria de saldos | Vehículos";
      case "adminCities":
        return "Ciudades";
      case "charges":
        return "Facturar servicios";
      case "createCostCenter":
        return "Crear centros de costo";
      case "associateCostCenter":
        return "Asociar centros de costo";
      case "paymentExtraAdvance":
        return "Pagar sobreanticipos";
      case "billingXML":
        return "Realizar facturación XML";
      case "reverseApproval":
        return "Reversar aprobaciones";
      case 'paymentTravelExpenses':
        return 'Pagar viáticos';
      case "paymentBalance":
        return "Pagar saldos";
      case "paymentAdvance":
        return "Pagar anticipos";
      case "paymentCargo":
        return "Pagar servicios";
      case "editSimpleRegimen":
        return "Editar régimen simple";
      case "modifyAdditionalCostBilled":
        return "Editar servicios adicionales con servicio facturado";
      case "modifyAdditionalCostpayed":
        return "Editar tarifa de servicios adicionales pagados";
      case "addAdditionalServiceCargoPaid":
        return "Crear servicios adicionales en servicios pagados";
      case "addAdditionalServiceCargoCashed":
        return "Crear servicios adicionales en servicios facturados";
      case "addAdditionalServiceCargoApproved":
        return "Crear servicios adicionales en servicios aprobados";
      case "editRateCargoUnrestricted":
        return "Editar tarifa sin restricción";
      case "fastPaymentRequest":
        return "Solicitud de pronto pago";
      case "adminReceivable":
        return "Cartera";
      case "createReceivable":
        return "Crear carteras";
      case "deleteReceivable":
        return "Eliminar carteras";
      case "viewPaymentsReceivables":
        return "Ver abonos de carteras";
      case "billingWithFlexibilityChoice":
        return "Facturación con flexibilidad de elección";
      case "unrestrictedAdvancePayments":
        return "Pagar anticipos sin restricción";
      case "unrestrictedAdvancePaymentsRequest":
        return "Solicitar anticipos sin restricción";
      case "adminMaintenance":
        return "Mantenimientos";
      case "deleteCostCenter":
        return "Eliminar/desasociar centro de costo";
      //GUIDES MODULE
      case "loadGuides":
        return "Cargar guías";
      case "changeStateGuides":
        return "Cambiar estado de guías";
      case "createGuidesFromExcel":
        return "Crear guías desde XLS";
      case "guideList":
        return "Listado de guías";
      case "trackGuide":
        return "Rastreo de guías";

      //POINTS MODULE
      case "createPoint":
        return "Crear puntos";
      case "listPoints":
        return "Ver puntos";
      case "removePoint":
        return "Eliminar puntos";
      case "updatePoint":
        return "Actualizar puntos";

      //CONFIG MODULE
      case "companyDocuments":
        return "Documentos de la empresa";
      case "changePassword":
        return "Cambiar contraseña";
      case "termsAndConditions":
        return "Términos y condiciones";
      case "privacyPolicy":
        return "Políticas de privacidad";
      case "personalDataPolicies":
        return "Políticas tratamiento de datos personales";
      case "habeasData":
        return "HABEAS DATA";
      case "termsManifest":
        return "Anexo manifiesto de servicio";
      case "bascPolicies":
        return "Políticas BASC";
      case "quickPay":
        return "Pronto pago";
      // ROUTES MODULE
      case "routes":
        return "Rutas";
      case "routePlanning":
        return "Planes de ruta";
      case "createRoutePlanning":
        return "Crear planes de ruta";
      case "createRouteItinerary":
        return "Crear itinerarios de ruta";
      case "editRouteItinerary":
        return "Editar itinerarios de ruta";
      case "routeSites":
        return "Sitios Autorizados"
      case "createRouteSites":
        return "Crear sitios autorizados";
      case "editRouteSites":
        return "Editar sitios autorizados";
      case "deleteRouteSites":
        return "Eliminar sitios autorizados";
      case "accessCargoRoutePlanning":
        return "Ver el PDF del plan de ruta de un servicio";
      case "editCargoRoutePlanning":
        return "Editar el plan de ruta de un servicio";

      //HELP DESK MODULE
      case "createTicket":
        return "Crear ticket";
      case "listTicket":
        return "Ver tickets";
      case "replyTechnicalTicket":
        return "Responder tickets de problemas técnicos";
      case "replyWebTicket":
        return "Responder tickets de problemas web";
      case "replyMobileTicket":
        return "Responder tickets de problemas en movil";
      case "replyOtherTicket":
        return "Responder tickets generales";
      case "reopenTicket":
        return "Re-abrir tickets";


      case "useCarNearExpirationDocuments":
        return "Utilizar vehículos con documentación próxima a expirar";

      case "approveBalanceDriver":
        return "Aprobar pago saldos";
      case "specialLicensePlate":
        return "Asignar licencia especial";

      //MONITORING MODULE
      case "monitorlogging":
        return "App logging";
      case "fingerprintslogging":
        return "Solicitudes por usuario";

      //ANALYTICS MODULE
      case "analyticsOperations":
        return "Operaciones";
      case "vehicleAnalytics":
        return "Vehículos";
      case "frequentRoutes":
        return "Rutas frecuentes";
      case "operationITR":
        return "Operación ITR";
      case "operationsTurn":
        return "Operaciones Enturnamiento";
      case "comercialTurn":
        return "Comercial Enturnamiento";
      case "dispatch":
        return "Despachos";
      case "stopedCargos":
        return "Servicios detenidos";
      case "historicalKPI":
        return "Métricas históricas";
      case "analyticsBilling":
        return "Facturación";
      //ROUTING MODULE
      case "createRouting":
        return "Crear ruteo";
      case "routingList":
        return "Listado de ruteos";

      default:
        return functionality;
    }
  }
}
