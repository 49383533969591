<section class="px-3 px-md-5 py-5 position-relative">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="ref.close()"></i>
    <h2 class="title py-4 text-center">Agregar viáticos de {{travelExpense.travelExpensesType.name}}</h2>
    <form [formGroup]="form" (submit)="onSubmit()">
        <div class="d-flex flex-wrap align-items-center">
            <mat-form-field class="col-md-4" appearance="outline">
                <mat-label>Descripción (opcional)</mat-label>
                <input matInput type="text" formControlName="description">
            </mat-form-field>
            <mat-form-field class="col-md-3" appearance="outline">
                <mat-label>Valor del viático<span class="asterisk">*</span></mat-label>
                <input matInput type="text" currencyMask min="1" formControlName="value">
                <mat-error *ngIf="form.get('value').errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
            </mat-form-field>
            <mat-card-content class="col-md-4">
                <input #inputFile class="display-none" type="file" (change)="handleFileInput($event)" multiple
                    accept="image/*,application/pdf">
                <ng-container>
                    <label class="file">
                        <div class="icon-type">
                            <img src="assets/svg/icons/icon-upload.svg" alt="">
                        </div>
                        <div class="data-type">
                            <span class="label-load-file" (click)="openWindowFile()">
                                <a>Cargar archivo</a>
                            </span>
                        </div>
                    </label>
                </ng-container>
            </mat-card-content>
        </div>
        <div class="d-flex flex-wrap align-items-center" *ngIf="listPreviewImages?.length">
            <div class="col-md-12">
                <b class="text-bold">
                    Fotos subidas
                </b>
                <br>
                <app-image-thumbnail-list [images]="listPreviewImages"></app-image-thumbnail-list>
            </div>
        </div>
        <br>

        <div class="d-flex">
            <h5 class="text-bold w-100 text-center">Total viáticos desembolsados {{travelExpense.totalPaid |
                currency:'COP':'code'}}</h5>
        </div>
        <br>
        <div class="row">
            <div class="col-12 text-center">
                <button mat-raised-button class="primary-secondary" color="primary" type="button"
                    (click)="cancel()">Cancelar</button>
                <button mat-raised-button class="btn-primary bg-color-primary" color="primary"
                    type="submit">Guardar</button>
            </div>
        </div>
    </form>
</section>
