<aside class="vehicle__contacts">
  <ng-template matStepLabel>
    <span>Referencias & Contacto</span>
  </ng-template>
  <!-- EmergencyContact -->
  <ng-container>
    <span class="separator-horizontal"></span>
    <app-contact-person-vehicle [form]="emergencyContactControls" [title]="'Contacto de emergencia'">
    </app-contact-person-vehicle>
  </ng-container>
  <hr>
  <!-- ReferenceLaboral -->
  <ng-container>
    <span class="separator-horizontal"></span>
    <app-contact-person-vehicle [form]="referenceLaboralControls" [title]="'Referencia laboral'">
    </app-contact-person-vehicle>
  </ng-container>
  <hr>
  <!-- ReferencePersonal -->
  <ng-container>
    <span class="separator-horizontal"></span>
    <app-contact-person-vehicle [form]="referencePersonalControls" [title]="'Referencia personal'">
    </app-contact-person-vehicle>
  </ng-container>
  <div class="alert alert-info d-flex justify-content-between flex-sm-row flex-wrap" role="alert"
    *ngIf="driver?.emergencyContact?.fingerPrintValidation">
    <div class="mr-3">
      <span class="text-bold">Última validación: </span>
      {{driver.emergencyContact.fingerPrintValidation.date |dateFormat}}
    </div>
    <div>
      <span class="text-bold">Responsable: </span>
      {{driver.emergencyContact.fingerPrintValidation.userName}}
      ({{driver.emergencyContact.fingerPrintValidation.userId}})
    </div>
  </div>
  <div permission-display [module]="permission.administration.module"
    [functionality]="permission.administration.editReferencesVehicle" class="col-md-12" *ngIf="!fromCreation">
    <div class="button-container">
      <button mat-raised-button color="primary" type="button" class="primary-secondary button-width-auto"
        (click)="onSubmit('delete')">
        Eliminar referencias
      </button>
      <button mat-raised-button color="primary" type="button" class="button-width-auto bg-color-primary"
        (click)="onSubmit('edit')">
        Guardar
      </button>
    </div>
  </div>
</aside>