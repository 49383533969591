import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'publishingZone'
})
export class PublishingZonePipe implements PipeTransform {

  constructor() { }

  transform(type: string) {
    switch (type) {
      case 'private':
        return 'Privada';
      case 'public':
        return 'Pública';
      default:
        return type;
    }
  }
}
