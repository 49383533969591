import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'roleModule'
})
export class RoleModulePipe implements PipeTransform {

  constructor() { }
  transform(module) {
    switch (module) {
      case 'administration':
        return 'Módulo de administración';
      case 'cargo':
        return 'Módulo de servicios';
      case 'guide':
        return 'Módulo de guías';
      case 'point':
        return 'Módulo de puntos';
      case 'payments':
        return 'Módulo de pagos';
      case 'help-desk':
        return 'Soporte';
      case 'notifications':
        return 'Notificaciones';
      case 'setting':
        return 'Configuración';
      case 'general':
        return 'General';
      case 'monitoring':
        return 'Monitoring'
      case 'routes':
        return 'Módulo de rutas';
      case 'analytics':
        return 'Analítica';
      case 'routing':
        return 'Ruteo';
      default:
        return module;
    }
  }

}
