import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailTypeName'
})
export class EmailTypeNamePipe implements PipeTransform {
  private readonly types = {
    reportBatchFastPayment: "Reporte pronto pago",
    tracking: "Seguimiento de servicios",
    reportBatchDelayedCargos: "Reporte servicios retrasados",
    accounting: "Contabilidad",
    applications: "Postulaciones",
    deleteCargoWithPayments: "Eliminar servicios con pagos",
  };

  transform(value: any, ...args: any[]): any {
    return this.types[value] ? this.types[value] : value;
  }

}
