<mat-form-field *ngIf="title === 'Propietario del servicio' && !simpleAddress" class="property" appearance="standard">
  <mat-label>{{title}}<span class="asterisk">*</span></mat-label>
  <input matInput [matAutocomplete]="autocompleteCompanies" [placeholder]="title" [formControl]="companySelectCtrl">
  <mat-autocomplete #autocompleteCompanies="matAutocomplete" [displayWith]="displayCompanyName"
    (optionSelected)="onSelectCompany($event)">
    <mat-option *ngFor="let company of listCompaniesAsync | async" [value]="company">
      <span>{{company.name}}</span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<!-- Third Parties -->

<mat-form-field class="property" appearance="standard"
  *ngIf="title !== 'Propietario del servicio' && manualCreationCargoService.getCargoForm().get('ministry').value && !simpleAddress && !isInternational">
  <mat-label>{{title}}<span class="asterisk">*</span></mat-label>
  <input (keyup)="selectionChange()" matInput [matAutocomplete]="autocompleteThirdParties" [placeholder]="title"
    [formControl]="thirdPartySelectCtrl">
  <div matSuffix class="spinner-border text-info" role="status" *ngIf="loadingThirdParties">
    <span class="sr-only">Loading...</span>
  </div>
  <mat-autocomplete #autocompleteThirdParties="matAutocomplete" [displayWith]="displayThirdPartyName"
    (optionSelected)="onSelectThirdParty($event)">
    <mat-option [value]="null" *ngIf="!isInternational">
      <span class="font-italic">Agregar {{title}}</span>
    </mat-option>
    <mat-option *ngFor="let thirdParty of listThirdPartiesAsync | async" [value]="thirdParty">
      <span>{{thirdParty.name}}</span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<mat-form-field class="property" appearance="standard"
  *ngIf="(showAddressField && !simpleAddress && !isInternational) || (!!simpleAddress && simpleAddress.title === cargoStateEnum.REQUEST)">
  <mat-label>{{title === 'Propietario del servicio'? 'Sede central' : 'Dirección'}}<span
      class="asterisk">*</span></mat-label>
  <input (keyup)="addressChange()" matInput [matAutocomplete]="autocompleteAddress" placeholder="Direcciones"
    [formControl]="inputFormControl.get('address')">
  <div matSuffix class="spinner-border text-info" role="status" *ngIf="loadingAddresses">
    <span class="sr-only">Loading...</span>
  </div>
  <mat-autocomplete #autocompleteAddress="matAutocomplete" [displayWith]="displayAddressName"
    (optionSelected)="onSelectAddress($event)">
    <mat-option [value]="null" *ngIf="simpleAddress?.title !== cargoStateEnum.REQUEST">
      <span class="font-italic">Agregar dirección</span>
    </mat-option>
    <mat-option *ngFor="let address of listAddressesAsync | async" [value]="address">
      <span>{{address.address.address}}</span>
    </mat-option>
  </mat-autocomplete>

  <mat-hint
    *ngIf="(title !== 'Propietario del servicio' && addressSelected && !addressSelected.location?.lat && !addressSelected.location?.lng) && simpleAddress?.title !== cargoStateEnum.REQUEST"
    class="color-secondary-dark text-bold">La dirección seleccionada requiere <u class="cursor-pointer"
      (click)="openAddLatLng()">asociar longitud y latitud</u></mat-hint>
</mat-form-field>

<!-- Address and New Address -->
<div class="property"
  *ngIf="newAddress || !manualCreationCargoService.getCargoForm().get('ministry').value || isInternational">
  <app-autocomplete-city
    *ngIf="!cityNewAddress && title === 'Propietario del servicio' && manualCreationCargoService.getCargoForm().get('ministry').value"
    [options]="optionsCity" [inputFormControl]="cityControl"></app-autocomplete-city>
  <button mat-button class="icon-edit confirm" (click)="createThirdParty()"
    *ngIf="(cityNewAddress || title !== 'Propietario del servicio') && manualCreationCargoService.getCargoForm().get('ministry').value && !isInternational">
    <i class="fas fa-check"></i>
  </button>
  <button mat-button class="icon-edit cancel" (click)="clearProperty('addressSelected')"
    *ngIf="manualCreationCargoService.getCargoForm().get('ministry').value&&!isInternational">
    <i class="fas fa-times"></i>
  </button>
  <app-autocomplete-address *ngIf="((cityNewAddress || title !== 'Propietario del servicio') && manualCreationCargoService.getCargoForm().get('ministry').value)
    || (!manualCreationCargoService.getCargoForm().get('ministry').value && title !== 'Propietario del servicio')"
    [title]="'Ingrese la dirección'" [value]="inputFormControl.get('address').value" [internationalCode]="options?.code"
    [address]="addressSelected ? addressSelected.address: null" [options]="options"
    (emitToParent)="onSelectNewAddress($event)"></app-autocomplete-address>
  <span class="pattern pattern--invalid"
    *ngIf="inputFormControl.get('address').errors && inputFormControl.get('address').errors.addressEqualCity">
    La Dirección no puede ser la misma que la Ciudad
  </span>
</div>