
import { MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material';
import { Platform } from '@angular/cdk/platform';
import { Inject, Injectable } from '@angular/core';
import { DateManager } from '../managers/date.manager';

@Injectable({ providedIn: 'root' })
export class PickDateAdapter extends NativeDateAdapter {

    constructor(
        @Inject(MAT_DATE_LOCALE) matDateLocale: string,
        private platform: Platform,
    ) {
        super(matDateLocale, platform);
    }

    format(date: Date, displayFormat: Object): string {
        return DateManager.formatDate(date, 'YYYY-MM-DD HH:mm ZZ', 'YYYY-MM-DD');
    }
}