<form (ngSubmit)="onSubmit()" [formGroup]="massCreationCargoForm">
  <aside class="mass__creation__cargo h-100 w-100 py-3 px-2 px-sm-3 d-flex flex-column">
    <div class="title-component">
      Creación de Servicios Masivos
    </div>
    <div class="row align-items-baseline">
      <div class="col-md-6">
        <mat-card-content>
          <app-file [inputFormControl]="massCreationCargoForm.get('file')" (emitToParent)="handleFileInput($event)"
            [options]="{storageName: 'massCreation', format: '.XLSX' }">
          </app-file>
        </mat-card-content>
      </div>
      <!-- Sheet Name -->
      <div class="col-md-6">
        <mat-form-field appearance="outline" *ngIf="showSheetSelector">
          <mat-label>Hoja</mat-label>
          <mat-select formControlName="sheetName">
            <mat-option *ngFor="let sheet of sheets" [value]="sheet.value">
              {{sheet.key}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- Company -->
      <div class="col-lg-4 col-md-6" *ngIf="massCreationCargoForm?.get('file')?.value">
        <mat-card-content class="company-select">
          <app-select-company [options]="optionsCompany" [inputFormControl]="massCreationCompany">
          </app-select-company>
        </mat-card-content>
      </div>
      <div class="col-lg-4 col-md-6" *ngIf="massCreationCargoForm?.get('file')?.value">
        <mat-form-field appearance="outline">
          <mat-label>N° fila inicial<span class="asterisk">*</span></mat-label>
          <input type="number" id="inputRow" min="1" matInput formControlName="firstRow">
          <mat-error *ngIf="massCreationCargoForm.get('firstRow').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-4" *ngIf="massCreationCargoForm.get('file').value">
        <label class="text-strong">¿Requiere Manifiesto?&nbsp;&nbsp;</label>
        <mat-button-toggle-group #group="matButtonToggleGroup" [formControl]="massCreationMinistryCtrl">
          <mat-button-toggle [value]="true">
            Si
          </mat-button-toggle>
          <mat-button-toggle [value]="false">
            No
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <label class="label-field text-left mt-4"
      *ngIf="getObjectKeys(titleFieldTemporal).length && massCreationCargoForm.get('file').value && massCreationCargoForm.get('companyId').value">
      Por favor seleccione el campo que se relaciona con los datos de cada columna
    </label>
    <div class="table-responsive scroller w-100"
      *ngIf="massCreationCargoForm.get('file').value && massCreationCargoForm.get('companyId').value">
      <table class="table table-xslx">
        <thead>
          <tr>
            <th *ngFor="let index of getObjectKeys(titleFieldTemporal)">
              <ng-template [ngIf]="!titleFieldTemporal[index] || !titleFieldTemporal[index].value">
                <mat-form-field class="example-full-width">
                  <mat-select (selectionChange)="onChangeTitleField($event?.value, index)">
                    <mat-option style="font-size: 14px;" *ngFor="let title of listTitleFieldsColumnsOrdered"
                      [value]="title.id" [disabled]="title.disabled" [title]="title.title" [ngClass]="{
                        'green-bg':title.disabled && title.mandatory,
                        'text-bold':title.mandatory
                      }">
                      {{title.title}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-template>
              <div *ngIf="titleFieldTemporal[index].value" class="title-field-selected">
                {{titleFieldTemporal[index].title}}
              </div>
              <i class="fas fa-times-circle clear-value-icon" *ngIf="titleFieldTemporal[index].value"
                (click)="clearValueTitle(titleFieldTemporal[index].value, index)"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td *ngFor="let title of getObjectKeys(getRowXLSXToShow())">
              {{getRowXLSXToShow()[title]}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p class="w-100 alert alert-warning fs-14"
      *ngIf="getObjectKeys(titleFieldTemporal)?.length && massCreationCargoForm?.get('file')?.value && massCreationCargoForm.get('companyId').value">
      Los campos con * son obligatorios. Debe seleccionar por lo menos una ciudad de Origen y Destino con una dirección.
    </p>
    <span class="separator-horizontal"></span>
    <div class="row">
      <div class="col-md-12 text-center">
        <mat-card-actions>
          <button type="submit" mat-raised-button color="primary" class="button-width-auto">Crear servicios</button>
        </mat-card-actions>
      </div>
    </div>
  </aside>
</form>