import {
  Component,
  OnInit,
  Inject,
  ViewChildren,
  QueryList,
  ViewChild,
} from "@angular/core";
import { AuthService } from "src/app/core/services/authentication.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { User } from "src/app/core/interfaces/user";
import { Utils } from "src/app/core/resources/utils";
import { Global } from "src/app/core/resources/global";
import { NgxSpinnerService } from "ngx-spinner";
import { SnackBarService } from "src/app/core/services/snackBar.service";
import { Titles } from "src/app/core/resources/titles";
import { VehiclesService } from "../list-vehicles.service";
import { Vehicle, Model } from "src/app/core/interfaces/vehicle";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { CatalogItem } from "src/app/core/interfaces/catalogItem";
import { ManualCreationCargoService } from "src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service";
import { VehicleType } from "src/app/core/interfaces/vehicleType";
import { Bank } from "src/app/core/interfaces/bank";
import { Observable } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from "rxjs/operators";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MatTooltip,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { DialogComponent } from "src/app/shared/dialog/dialog.component";
import { AdminUsersService } from "../../admin-users/admin-users.service";
import { FileComponent } from "src/app/shared/files/file.component";
import { Patterns } from "src/app/core/resources/patterns";
import { BasicStatePipe } from "src/app/core/pipe/basicState.pipe";
import { CompanyNamePipe } from "src/app/core/pipe/companyName.pipe";
import { CreateDriverComponent } from "../create-driver/create-driver.component";
import { Dialog } from "src/app/core/resources/dialog";
import { DateFormatPipe } from "src/app/core/pipe/dateFormat.pipe";
import { Permission } from "src/app/core/resources/permission";
import { StateUserPipe } from "src/app/core/pipe/stateInactive.pipe";
import { VehicleManager } from "src/app/core/managers/vehicle.manager";
import { ReactiveForm } from "src/app/core/resources/reactive-form";
import { ModalLicensePlateComponent } from "src/app/modules/cargo/modal-license-plate/modal-license-plate.component";
import { PermissionRole } from "src/app/core/resources/permission-role";
import { SetAdministratorComponent } from "../set-administrator/set-administrator.component";
import { DatePipe } from "@angular/common";
import { UserState } from "src/app/core/enums/user-state.enum";
import { ErrorRNDC } from "src/app/core/interfaces/errorRNDC";
import { ModalEnum } from "src/app/core/enums/modal.enum";
import { _configFactory } from "ngx-mask";
import { ChangeDriverComponent } from "src/app/modules/cargo/change-driver/change-driver.component";
import { RelatedInfoUser } from "src/app/core/interfaces/relatedInfoUser";
import { DateManager } from "src/app/core/managers/date.manager";
import { ServiceMessages } from "src/app/core/messages/service-messages.enum";
import { Trailer } from "src/app/core/interfaces/trailer";
import { UpdateVehicleFieldsComponent } from "../update-vehicle-fields/update-vehicle-fields.component";
import { DriverLicenseCategory } from "src/app/core/interfaces/driverLicenseCategory";
import { ExtraDocument } from "src/app/core/interfaces/extraDocument";
import { Companies } from "src/app/core/resources/companies";
import { AdditionalCertification } from "src/app/core/interfaces/additionalCertification";
import { AdditionalCertificationEnum } from "src/app/core/enums/additionalCertification.enum";
import { StatusSecurityValidationStatus, StatusSecurityValidationType } from "src/app/core/interfaces/statusSecurityValidation";

@Component({
  selector: "app-detail-vehicle",
  templateUrl: "./detail-vehicle.component.html",
  styleUrls: ["./detail-vehicle.component.scss"],
  providers: [
    AuthService,
    ManualCreationCargoService,
    Model,
    CompanyNamePipe,
    DateFormatPipe,
    DatePipe,
  ],
})
export class DetailVehicleComponent extends VehicleManager implements OnInit {
  @ViewChildren(FileComponent) fileComponent: QueryList<FileComponent>;
  @ViewChild("tooltipTrailer", { static: false }) tooltipTrailer: MatTooltip;
  // @ViewChild(DriverCargoComponent, { static: false }) driverCargo: DriverCargoComponent;
  // @ViewChildren(DriverCargoComponent) driverCargoComponent: QueryList<DriverCargoComponent>;
  errorsRNDC: { driver: ErrorRNDC, owner: ErrorRNDC, vehicle: ErrorRNDC } = { driver: null, owner: null, vehicle: null };
  permission = Permission;
  hasAdministratorCtrl: FormControl = new FormControl(true);
  driverCopy: User;
  vehicle: Vehicle;
  documenTypes: CatalogItem[];
  selectDocumentView: string;
  vehicleTypes: VehicleType[];
  nameFileAdvanceSelected: string;
  nameFileBalanceSelected: string;
  nameFileVehicleSelected: string;
  private bankList: Bank[] = [];
  filteredBanksAdvance: Observable<Bank[]>;
  filteredBanksBalance: Observable<Bank[]>;
  driver: User = {};
  owner: User = {};
  admin: User = {};
  showAlertLicenseExpirationDate: boolean = false;
  listTypeDocumentBankView: { name: string, value: string }[] = [
    {
      name: "Cerificacion Bancaria",
      value: "certificate",
    },
    {
      name: "Autorizacion de pago a Tercero",
      value: "authorization",
    },
    {
      name: "Rut",
      value: "rut",
    },
  ];
  private listAccountType: { name: string, id: string }[] = [
    {
      name: "Ahorros",
      id: "1",
    },
    {
      name: "Corriente",
      id: "2",
    },
  ];
  documentsValidator = true;
  scoreTruoraCtrl = new FormControl();
  reactiveForm: ReactiveForm;
  reactiveFormCreate: ReactiveForm;
  dueDateArlFormatted: Date;
  dueDateEpsFormatted: Date;
  expireArl: boolean = false;
  expireEps: boolean = false;
  approvalByArl: string;
  approvalByEps: string;
  vehicleTypesIcons = [
    { name: 'AUTOMOVIL', icon: 'fa-car-side' },
    { name: 'CAMION', icon: 'fa-sharp fa-truck' },
    { name: 'TRACTOCAMION', icon: 'fa-truck-moving' },
    { name: 'MOTOCICLETA', icon: 'fa-sharp fa-motorcycle' },
    { name: 'CAMIONETA', icon: 'fa-truck-pickup' },
    { name: 'CAMPERO', icon: 'fa-truck-pickup' },
    { name: 'REMOLQUE', icon: 'fa-sharp fa-trailer' },
    { name: 'SEMIREMOLQUE', icon: 'fa-sharp fa-trailer' },
  ];
  vehiclesExemptedTwoYears: string[] = ['CAMION', 'TRACTOCAMION', 'REMOLQUE', 'SEMIREMOLQUE', 'CAMPERO', 'CAMIONETA'];
  vehiclesExemptedSixYears: string[] = ['AUTOMOVIL', 'MOTOCICLETA'];
  additionalCertifications: AdditionalCertification[] = [];
  public relatedInfoDriver: RelatedInfoUser = {
    vehicle: null,
    cargo: null,
    expireArl: false,
    expireEps: false,
    driver: {},
    owner: {},
  };
  unacceptedDocuments: Array<string> = [];
  missingDocuments: Array<string> = [];
  params: Params;
  userInfoSupportDriver: ExtraDocument[] = [];
  public vehicleDataBase: string = 'runt';
  currentVehicleByRtmExoneration: boolean = false;
  exonerationExpirationDate: Date = null;
  constructor(
    private dateFormatPipe: DateFormatPipe,
    public authService: AuthService,
    private titles: Titles,
    private vehiclesService: VehiclesService,
    public utils: Utils,
    private dialogUtils: Dialog,
    public global: Global,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private router: Router,
    private route: ActivatedRoute,
    private cargoService: ManualCreationCargoService,
    public formBuilder: FormBuilder,
    public modelVehicle: Model,
    public matDialog: MatDialog,
    private adminUsersService: AdminUsersService,
    public patterns: Patterns,
    private basicStatePipe: BasicStatePipe,
    private companyNamePipe: CompanyNamePipe,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DetailVehicleComponent>,
    @Inject(MAT_DIALOG_DATA)
    public paramsDialog: {
      vehicle;
      close;
      receivable;
      id;
    },
    private stateUserPipe: StateUserPipe,
    public adminUserService: AdminUsersService,
    private permissionRole: PermissionRole,
    private datePipe: DatePipe
  ) {
    super(dialog, utils);
    this.params = this.route.snapshot.params;
    this.initForm();
    this.initFormVehicle();
    this.getTypeVehicles();
    this.documenTypes = this.utils.clone(this.global.documenTypes);
  }

  vehicleExoneratedByRtm(vehicleType: string): boolean {
    let yearsExempted = null;
    if (this.vehiclesExemptedTwoYears.includes(vehicleType)) yearsExempted = 2;
    else if (this.vehiclesExemptedSixYears.includes(vehicleType)) yearsExempted = 6;
    this.exonerationExpirationDate = DateManager.add(this.vehicle.registrationDate, yearsExempted, 'years');
    return yearsExempted ? DateManager.dateDiff(DateManager.add(this.vehicle.registrationDate, yearsExempted, 'years'), null, DateManager.setStartOfDay(new Date())) > 0 : false;
  }

  /**
  * @description Executes initialConfig method
  */
  ngOnInit() {
    this.initialConfig();
  }

  /**
  * @description Verifies if vehicle's license plate exists on paramsDialog or params (route) to set it.
  */
  private initialConfig(): void {
    if ((!this.paramsDialog) && (!this.params || !this.params.id)) {
      this.snackBarService.openSnackBar(
        "Ocurrió un error intentando traer el detalle",
        undefined,
        "alert"
      );
      this.goToList();
      return;
    }
    if (this.paramsDialog && this.paramsDialog.vehicle) this.validateDialogParams();
    else if (this.paramsDialog && this.paramsDialog.id) this.setDetailVehicle(this.paramsDialog.id);
    else this.setDetailVehicle(this.params.id);
    this.listBank();
    this.initFilteredBanks();
  }

  /**
  * @description Cleans the vehiclesService's vehicleSelected
  */
  ngOnDestroy(): void {
    this.vehiclesService.vehicleSelected = null;
  }

  /**
  * @description Initializes the vehicle's detail form
  */
  private initForm() {
    this.reactiveForm = new ReactiveForm(
      this.formBuilder,
      this.modelVehicle.model,
      {
        bankAccountAdvance: this.modelVehicle.model.bankAccountAdvance,
        bankAccountBalance: this.modelVehicle.model.bankAccountBalance,
        selectDocumentView: this.modelVehicle.model,
      },
      {
        bankAccountAdvance: this.modelVehicle.model.bankAccountAdvance,
        bankAccountBalance: this.modelVehicle.model.bankAccountBalance,
        selectDocumentView: this.modelVehicle.model,
      }
    );
    this.reactiveForm.setValidatorsForm(
      this.modelVehicle.modelValidators,
      this.reactiveForm.form
    );
  }

  /**
 * @description Initializes the vehicle's create form
 */
  private initFormVehicle(): void {
    this.reactiveFormCreate = new ReactiveForm(
      this.formBuilder,
      this.modelVehicle.modelCreate
    );
    this.reactiveFormCreate.setValidatorsForm(
      this.modelVehicle.vehicleCreationValidators,
      this.reactiveFormCreate.form
    );
    this.reactiveFormCreate.form.get("vehicle.extraImages").setValue([]);
  }

  /**
 * @param {Date} date is the date to transform
 * @returns {string} is the date transformed on expected format
 * @description Transforms a date to some expected format
 */
  formatDate(date: Date): string {
    return this.datePipe.transform(date, "d MMMM yyyy");
  }

  /**
  * @description Sets the paramsDialog's vehicle and obtains the driver, owner and admin from vehicle info
  */
  private validateDialogParams() {
    this.setVehicle(this.paramsDialog.vehicle);
    this.reactiveFormCreate.form.get("vehicle").patchValue(this.vehicle);
    if (this.vehicle.driver && this.vehicle.driver.document) {
      this.getDataDriver(true);
    }
    if (this.vehicle.owner && this.vehicle.owner.document) {
      this.getDataOwner();
    }
    if (this.vehicle.administrator && this.vehicle.administrator.document) {
      this.getDataAdmin();
    }
    this.reactiveForm.updateForm(this.vehicle);
    const vehicleModel: Vehicle = this.utils.clone(this.modelVehicle.model);
    delete vehicleModel.administrator;
    delete vehicleModel.bankAccountAdvance;
    delete vehicleModel.bankAccountBalance;
    this.reactiveForm.disabledFieldsForm(
      vehicleModel,
      this.reactiveForm.form
    );

  }

  /**
  * @description Validates the driver's arl and eps dueDate to check its expiration date
  */
  private validateDocuments() {
    if (this.driver && this.driver.extraDocuments && this.driver.extraDocuments.length) {
      this.expireArl = this.adminUserService.isExpiredDoc(this.driver, 'ARL');
      this.relatedInfoDriver.expireArl = this.expireArl;
      this.expireEps = this.adminUserService.isExpiredDoc(this.driver, 'EPS');
      this.relatedInfoDriver.expireEps = this.expireEps;
    }
  }

  /**
  * @param {'ARL'|'EPS'} type is the type of the document
  * @returns {boolean} returns true if the userInfoSupport has the document required
  * @description Indicates if the userInfoSupport has the document required
  */
  getDocumentSupport(type: 'ARL' | 'EPS'): boolean {
    return this.adminUsersService.getDocumentSupport(type, this.userInfoSupportDriver);
  }

  /**
  * @param {string} userDocument is the user's document
  * @description Gets the list of user's additional certifications
  */
  private async getAdditionalCertifications(userDocument: string) {
    try {
      const result = await this.adminUsersService.getUserAdditionalCertifications(userDocument).toPromise();
      this.additionalCertifications = result && result.length ? result : [];
    } catch (e) {
      this.additionalCertifications = [];
    }
  }

  /**
  * @param {AdditionalCertificationEnum} type is the type of the certification
  * @returns {AdditionalCertification} returns the certification required if exists
  * @description Indicates if the driver has the certification required and returns it.
  */
  getAdditionalCertification(type: AdditionalCertificationEnum): AdditionalCertification {
    if (!this.additionalCertifications || !this.additionalCertifications.length) return null;
    return this.additionalCertifications.find(cert => cert && cert.id === type);
  }

  /**
  * @description Validates the driver's licenses to check its expiration date
  */
  private validateLicenseExpirationDate() {
    if (this.driverCopy.driverLicenseCategory) {
      this.driverCopy.driverLicenseCategory.map((license) => {
        const expirationDate = DateManager.stringToDate(license.expirationDate, 'YYYY-MM-DDTHH:mm:ssT');
        let isExpired = DateManager.isSameOrBefore(expirationDate, new Date());
        if (isExpired) {
          license['isLicenseExpirationDate'] = true;
          if (license && license.category && !license.category.toUpperCase().includes('A')) {
            this.showAlertLicenseExpirationDate = true;
          }
        } else
          license['isLicenseExpirationDate'] = false;
      });
    }
    else this.showAlertLicenseExpirationDate = false;
  }

  /**
  * @description Shows a modal to read driver's license plates
  */
  public showDialogLicense(license?: DriverLicenseCategory) {
    if (this.driverCopy && this.driverCopy.driverLicenseCategory) {
      const dialogConfig = new MatDialogConfig();
      if (license) {
        dialogConfig.data = {
          licenses: [license],
          title: "Detalles de la licencia",
          driver: this.driver
        };
      } else {
        dialogConfig.data = {
          licenses: this.driverCopy.driverLicenseCategory,
          title: "Licencias",
          driver: this.driver
        };
      }

      dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
      dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
      dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
      dialogConfig.autoFocus = false;
      this.dialog.open(ModalLicensePlateComponent, dialogConfig).afterClosed().subscribe(() => this.initialConfig());
    } else {
      this.snackBarService.openSnackBar(
        "No se tienen registros de licencias",
        undefined,
        "error"
      );
    }
  }

  /**
  * @description Gets the vehicle's bank list allowed
  */
  private listBank() {
    this.vehiclesService.getListBank().subscribe((success: []) => {
      this.bankList = success;
    });
  }

  /**
  * @description Checks the vehicle's banks accounts and subscribes to bank name's value changes to update the filteredBanks arrays
  */
  private initFilteredBanks() {
    this.filteredBanksAdvance =
      this.reactiveForm.form.controls.bankAccountAdvance.controls.bank.controls.name.valueChanges.pipe(
        startWith(""),
        debounceTime(400),
        distinctUntilChanged(),
        map((value: string) => {
          return this.filterBanks(value);
        })
      );
    this.filteredBanksBalance =
      this.reactiveForm.form.controls.bankAccountBalance.controls.bank.controls.name.valueChanges.pipe(
        startWith(""),
        debounceTime(400),
        distinctUntilChanged(),
        map((value: string) => {
          return this.filterBanks(value);
        })
      );
  }

  /**
  * @param {string} bankName is the name of the bank to find
  * @returns {Bank[]} returns a list of banks who includes the searched value
  * @description Filters the bank list by bank's name param
  */
  private filterBanks(bankName: string): Bank[] {
    if (bankName) {
      const bankNameLowerCase = bankName.toLowerCase();
      return this.bankList.filter((bank) => {
        return bank.name.toLowerCase().indexOf(bankNameLowerCase) !== -1;
      });
    } else {
      return [];
    }
  }

  /**
  * @description Shows a modal to confirm the vehicle deactivation, and executes the "disableVehicleState" method by modal's results
  */
  openDialogDisableVehicle() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `¿Por que deseas desactivar el vehiculo ${this.vehicle.id}?`,
      textArea: true,
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state && result.message) {
        this.disableVehicleState(result.message);
      }
    });
  }

  /**
  * @description Shows a modal to confirm the vehicle approvement, and approves it by modal's results
  */
  openDialogActiveVehicle() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `¿Deseas Aprobar el vehiculo ${this.vehicle.id}?`,
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.spinner.show;
        let dataVehicle = {
          userId: this.authService
            .getUserSession()
            .information.document,
          userName: this.authService
            .getUserSession()
            .information.document,
        };
        this.vehiclesService
          .approveVehicle(this.vehicle.id, dataVehicle)
          .subscribe(
            (success) => {
              this.spinner.hide();
              this.vehiclesService.vehicleSelected = success;
              this.snackBarService.openSnackBar(
                "Vehiculo aprobado correctamente"
              );
            },
            (error) => {
              this.spinner.hide();
              this.snackBarService.openSnackBar(
                error.error.message
                  ? error.error.message
                  : "Error al aprobar vehiculo",
                undefined,
                "error"
              );
            }
          );
      } else {
        this.spinner.hide();
        this.snackBarService.openSnackBar(
          "Ocurrió un error al aprobar vehiculo",
          undefined,
          "error"
        );
      }
    });
  }

  /**
  * @param {string} reason is the reason to deactivate the vehicle
  * @description Deactivates a vehicle by param reason and executes the "getVehicle" method to update it
  */
  private disableVehicleState(reason: string) {
    const idVehicle: string = this.vehicle.id;
    this.spinner.show();
    this.vehiclesService
      .disableVehicle(idVehicle, reason)
      .toPromise()
      .then((success: string) => {
        if (success) {
          this.snackBarService.openSnackBar(
            "Vehiculo desactivado correctamente",
            undefined,
            "success"
          );
          this.getVehicle(this.vehicle.id);
        } else {
          this.snackBarService.openSnackBar(
            "Ocurrió un error desactivando el vehiculo",
            undefined,
            "error"
          );
        }
      })
      .catch((error) => {
        this.snackBarService.openSnackBar(
          "Ocurrió un error desactivando al vehiculo",
          undefined,
          "error"
        );
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  /**
  * @description Obtains the vehicle type list and saves it into vehicleTypes local variable
  */
  private getTypeVehicles() {
    this.cargoService.getVehicleType().subscribe(
      (data: any) => {
        this.spinner.hide();
        if (data && data.catalog) {
          this.vehicleTypes = data.catalog;
        } else {
          const modelListVehicles = JSON.stringify(
            this.cargoService.listVehicles
          );
          const modelListVehiclesObj = JSON.parse(modelListVehicles);
          this.vehicleTypes = modelListVehiclesObj;
        }
      },
      (error) => {
        this.spinner.hide();
        const modelListVehicles = JSON.stringify(
          this.cargoService.listVehicles
        );
        const modelListVehiclesObj = JSON.parse(modelListVehicles);
        this.vehicleTypes = modelListVehiclesObj;
      }
    );
  }

  /**
  * @param {string} id is the vehicle's license plate
  * @param {boolean} showAlerts indicates if the alerts
  * @description Updates the vehicle's detail with its driver, owner and admin
  */
  private setDetailVehicle(id?: string, showAlerts = false) {
    const vehicleModel: Vehicle = this.utils.clone(this.modelVehicle.model);
    delete vehicleModel.administrator;
    // delete vehicleModel.gps;
    delete vehicleModel.bankAccountAdvance;
    delete vehicleModel.bankAccountBalance;
    this.reactiveForm.disabledFieldsForm(vehicleModel, this.reactiveForm.form);
    if (this.utils.isDefined(this.vehiclesService.vehicleSelected)) {
      this.setVehicle(this.vehiclesService.vehicleSelected)
      this.reactiveFormCreate.form.get("vehicle").patchValue(this.vehicle);
      this.validateBankAccountType();
      this.validateBank();
      this.validateDocumentType();
      if (this.vehicle.driver && this.vehicle.driver.document) {
        this.getDataDriver(showAlerts);
      }
      if (this.vehicle.owner && this.vehicle.owner.document) {
        this.getDataOwner();
      }
      if (this.vehicle.administrator && this.vehicle.administrator.document) {
        this.getDataAdmin();
      } else this.admin = null;
      this.reactiveForm.updateForm(this.vehicle);
    } else if (this.utils.isDefined(id)) {
      this.getVehicle(id, showAlerts);
    } else {
      this.goToList();
    }
  }

  /**
  * @description Validates the vehicle's bank accounts to update its value
  */
  private validateBankAccountType() {
    const bankAccounts = ['bankAccountAdvance', 'bankAccountBalance'];
    const accountTypes = ['accountType', 'accountTypeCode'];
    bankAccounts.forEach(bankAccount => {
      accountTypes.forEach(accountType => {
        const anotherAccountType = accountType === "accountType" ? "accountTypeCode" : "accountType";
        if (this.vehicle[bankAccount] && this.vehicle[bankAccount][accountType] && !this.vehicle[bankAccount][anotherAccountType]) {
          const accountTypeList = this.listAccountType.filter((accountTypeEl) =>
            accountType === "accountType"
              ? accountTypeEl.name === this.vehicle[bankAccount][accountType]
              : accountTypeEl.id === this.vehicle[bankAccount][accountType]
          );
          if (accountTypeList.length) {
            this.vehicle[bankAccount][anotherAccountType] = accountType === "accountType" ? accountTypeList[0].id : accountTypeList[0].name;
            this.reactiveForm.form
              .get(`${bankAccount}.${anotherAccountType}`)
              .setValue(this.vehicle[bankAccount][anotherAccountType]);
          }
        }
      })
    });
  }

  /**
  * @description Validates the vehicle's bank accounts to update the form's bank account
  */
  private validateBank() {
    try {
      const bankAccounts = ["bankAccountAdvance", "bankAccountBalance"];
      bankAccounts.forEach(bankAccount => {
        if (this.vehicle[bankAccount] && this.vehicle[bankAccount].bank &&
          this.vehicle[bankAccount].bank.name && this.vehicle[bankAccount].bank.name.trim()) {
          const bankList = this.bankList.filter(
            (bank) =>
              bank.name.toLowerCase() ===
              this.vehicle[bankAccount].bank.name.trim().toLowerCase()
          );
          if (bankList.length) {
            this.vehicle[bankAccount].bank = bankList[0];
            this.reactiveForm.form.get(`${bankAccount}.bank`).patchValue(this.vehicle[bankAccount].bank);
          }
        }
      })
    } catch (e) { }
  }

  /**
  * @description Validates the document type of each bank account to update its value
  */
  private validateDocumentType() {
    try {
      const bankAccounts = ["bankAccountAdvance", "bankAccountBalance"];
      const documents = ["documentTypeId", "documentTypeName"];
      bankAccounts.forEach(bankAccount => {
        documents.forEach(document => {
          const auxDocument = document === "documentTypeId" ? "documentTypeName" : "documentTypeId";
          if (this.vehicle[bankAccount] && this.vehicle[bankAccount][document] &&
            !this.vehicle[bankAccount][auxDocument]) {
            const typeDocumentList = this.documenTypes.filter(
              (documentType) =>
                document === "documentTypeId"
                  ? documentType.id.toLowerCase() === this.vehicle[bankAccount][document].trim().toLowerCase()
                  : documentType.name.toLowerCase() === this.vehicle[bankAccount][document].trim().toLowerCase()
            );
            if (typeDocumentList.length) {
              this.vehicle[bankAccount][auxDocument] = typeDocumentList[0].name;
              this.reactiveForm.form.get(`${bankAccount}.${auxDocument}`).patchValue(this.vehicle[bankAccount][auxDocument]);
            }
          }
        })
      })
    } catch (e) { }
  }

  /**
  * @description Updates scoreTruoraCtrl form control and disables it
  */
  private setValueStatesDriver() {
    this.scoreTruoraCtrl.setValue(this.scoreTruora);
    this.scoreTruoraCtrl.disable();
  }

  /**
  * @returns {boolean} returns true if the vehicle has more than 25 years and is repowered
  * @description Checks if the vehicle is repowered
  */
  get canActivateVehicle(): boolean {
    return (
      this.vehicle &&
      this.vehicle.state &&
      !this.vehicle.state.active &&
      this.vehicle.repowered &&
      this.vehicle.state.description ==
      "Este vehículo tiene mas de 25 años y para circular debe ser autorizado" &&
      this.permissionRole.hasPermission(
        Permission.administration.module,
        Permission.administration.activateVehicle
      )
    );
  }

  /**
  * @param {boolean} showAlerts indicates if the alerts must to be shown
  * @description Gets the driver's info and updates the driver and its related info
  */
  private getDataDriver(showAlerts = false) {
    if (!this.utils.getNestedValue(this.vehicle, 'driver.document')) return;
    this.spinner.show();
    this.adminUsersService
    .getUsersDetailByDocument(this.vehicle.driver.document)
    .subscribe(
      (success: User) => {
        this.spinner.hide();
        if (success && success.extraDocuments && success.extraDocuments.length) {
          this.userInfoSupportDriver = success.extraDocuments;
        } else this.userInfoSupportDriver = [];
        if (success && success.information) {
          this.driver = success;
          this.relatedInfoDriver.driver = success;
          if (this.utils.isDefined(this.driver['state']) && this.utils.isDefined(this.driver['state']['active']) && !this.driver.state.active) {
            if (this.utils.isDefined(this.driver.errorRNDC)) {
              this.errorsRNDC.driver = (this.driver.errorRNDC);
            }
          }
          this.validateDocuments();
          this.getAdditionalCertifications(this.vehicle.driver.document);
          this.driverCopy = this.utils.copyElementJSON(success);
          this.validateLicenseExpirationDate();
          this.reactiveFormCreate.form.get("driver").patchValue(this.driver);
          this.setValueStatesDriver();

          if (
            this.invalidReferences.length && showAlerts &&
            this.permissionRole.hasPermission(
              Permission.administration.module,
              Permission.administration.validateAndRemarkVehicleReferences
            )
          ) {
            this.dialogUtils.openDialog({
              title: `Referencias & Contacto`,
              description: `No se han validado las referencias del conductor para el vehículo "${this.vehicle.id}"`,
              iconError: true,
              hideBtnConfirm: false,
              hideBtnCancel: true,
            }).then().catch(err => err);
          }
        } else {
          this.driver = null;
          this.relatedInfoDriver.driver = {};;
        }
      },
      (error) => {
        this.driver = null;
        this.relatedInfoDriver.driver = {};
        this.spinner.hide();
      }
    );
    
  }

  /**
  * @description Gets the owner's info and updates the owner and its related info
  */
  private getDataOwner() {
    this.spinner.show();
    this.adminUsersService
      .getUsersDetailByDocument(this.vehicle.owner.document)
      .subscribe(
        (success: User) => {
          this.spinner.hide();
          if (success && success.information) {
            this.owner = success;
            this.relatedInfoDriver.owner = success;
            if (this.owner && this.owner.state && this.utils.isDefined(this.owner.state.active) && !this.owner.state.active) {
              if (this.utils.isDefined(this.owner.errorRNDC)) {
                this.errorsRNDC.owner = (this.owner.errorRNDC);
              }
            }
            this.reactiveFormCreate.form.get("owner").patchValue(this.owner);
          } else {
            this.owner = null;
            this.relatedInfoDriver.owner = {};
          }
        },
        (error) => {
          this.owner = null;
          this.relatedInfoDriver.owner = {};
          this.spinner.hide();
        }
      );
  }

  /**
  * @description Gets the admin's info and updates the admin and its related info
  */
  private getDataAdmin() {
    this.spinner.show();
    this.adminUsersService
      .getUsersDetailByDocument(this.vehicle.administrator.document)
      .subscribe(
        (success: User) => {
          this.spinner.hide();
          if (success && success.information) {
            this.admin = success;
            this.relatedInfoDriver.administrator = success;
            this.reactiveFormCreate.form.get("administrator").patchValue(this.owner);
          } else {
            this.admin = null;
            this.relatedInfoDriver.administrator = null;
          }
        },
        (error) => {
          this.admin = null;
          this.relatedInfoDriver.administrator = null;
          this.spinner.hide();
        }
      );
  }

  /**
  * @param {string} id is the vehicle's license plate
  * @param {boolean} showAlerts indicates if the alerts must to be shown
  * @description Gets the vehicle's info and updates the vehicle and its related info
  */
  getVehicle(id: string, showAlerts = false) {
    this.vehiclesService.getVehicle(id).subscribe(
      (success: any) => {
        if (success) {
          this.setVehicle(success);
          this.reactiveFormCreate.form.get("vehicle").patchValue(this.vehicle);
          this.validateTrailer(showAlerts);
          this.validateBankAccountType();
          this.validateBank();
          this.validateDocumentType();
          if (this.vehicle.driver && this.vehicle.driver.document) {
            this.getDataDriver(showAlerts);
          }
          if (this.vehicle.owner && this.vehicle.owner.document) {
            this.getDataOwner();
          }
          if (this.vehicle.administrator && this.vehicle.administrator.document) {
            this.getDataAdmin();
          } else this.admin = null;
          this.reactiveForm.updateForm(this.vehicle);
        } else {
          this.vehicle = null;
          this.goToList();
        }
      },
      (error) => {
        this.vehicle = null;
        this.goToList();
      }
    );
  }

  /**
  * @param {boolean} showAlerts indicates if the alerts must to be shown
  * @description If the vehicle has vehicleType "TRACTOCAMION" validates if has trailer or shows errors
  */
  private validateTrailer(showAlerts = false) {
    if (
      this.vehicle &&
      this.vehicle.vehicleType &&
      this.vehicle.vehicleType.name === "TRACTOCAMION"
    ) {
      if (this.vehicle.trailerId) {
        this.getDetailTrailer();
      } else if (showAlerts) {
        this.trailerMissing();
      }
    }
  }

  /**
  * @description Shows a modal to remember user to register a trailer
  */
  private trailerMissing() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `Recuerde, debe registrar un trailer`,
      iconError: true,
      hideBtnCancel: true,
      confirmBtn: "Aprobar",
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const thisClass = this;
    const dialog = this.matDialog.open(DialogComponent, dialogConfig);
    dialog.afterClosed().subscribe(() => {
      if (thisClass.tooltipTrailer) {
        thisClass.tooltipTrailer.show();
      }
    });
  }

  /**
  * @description Gets the vehicle's trailer and updates it on relatedInfoDriver local variable
  */
  private getDetailTrailer() {
    this.vehiclesService
      .detailTrailer(this.vehicle.id, this.vehicle.trailerId)
      .subscribe(
        (success: Trailer[]) => {
          if (success && success.length) {
            this.relatedInfoDriver.trailer = success[0];
          }
        },
        (error) => {
          this.relatedInfoDriver.trailer = null;
        }
      );
  }

  /**
  * @description Empties the nameFile variables and selectDocumentView
  */
  onSelectTab() {
    this.nameFileAdvanceSelected = null;
    this.nameFileBalanceSelected = null;
    this.nameFileVehicleSelected = null;
    this.selectDocumentView = null;
  }

  /**
  * @description Goes back to vehicles or receivables list
  */
  goToList() {
    if (this.paramsDialog.receivable) {
      this.router.navigate(["administration/receivable/list"]);
    } else {
      this.router.navigate(["administration/vehicles/list"]);
    }

  }

  /**
  * @param {"bankAccountAdvance"|"bankAccountBalance"|"documentVehicleView"} typeBankAccount is the type of the bank account selected
  * @description Clears the variables with its names and charges the variable of the type selected with the selectDowcumentView
  */
  onChangeTypeDocument(typeBankAccount: "bankAccountAdvance" | "bankAccountBalance" | "documentVehicleView") {
    const controlAssociated = {
      bankAccountAdvance: "nameFileAdvanceSelected",
      bankAccountBalance: "nameFileBalanceSelected",
      documentVehicleView: "nameFileVehicleSelected"
    }
    Object.values(controlAssociated).forEach(control => this[control] = null);
    if (this.reactiveForm.form.controls[typeBankAccount].controls[this.selectDocumentView].value)
      this[controlAssociated[typeBankAccount]] = this.reactiveForm.form.controls[typeBankAccount].controls[this.selectDocumentView].value;
  }


  /**
  * @param {'advance'|'balance'} paymentType is the document's type
  * @param {boolean} isApprove indicates if the document must to be approved or rejected
  * @description Approves or Rejects a document
  */
  approvalDocuments(paymentType: 'advance' | 'balance', isApprove: boolean) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      title:
        paymentType === "balance"
          ? isApprove
            ? this.titles.titleApproveDocumentsBalance
            : this.titles.titleNoApproveDocumentsBalance
          : isApprove
            ? this.titles.titleApproveDocumentsAdvance
            : this.titles.titleNoApproveDocumentsAdvance,
    };
    if (!isApprove) {
      dialogConfig.data.textArea = true;
    }
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const modalDialog = this.matDialog.open(DialogComponent, dialogConfig);
    modalDialog.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.spinner.show();
        this.vehiclesService
          .approveDocuments(
            this.vehicle.id,
            isApprove,
            paymentType,
            !isApprove ? result.message : null
          )
          .subscribe(
            (success: Vehicle) => {
              this.spinner.hide();
              this.vehiclesService.vehicleSelected = success;
              this.setDetailVehicle();
              this.snackBarService.openSnackBar(
                "Se han" +
                (isApprove ? " aprobado " : " rechazado ") +
                "correctamente los documentos"
              );
            },
            (error) => {
              this.spinner.hide();
              this.snackBarService.openSnackBar(
                "Ocurrió un error al " +
                (isApprove ? " aprobar " : " rechazar ") +
                " los documentos",
                undefined,
                "error"
              );
            }
          );
      }
    });
  }

  /**
  * @description Assigns a driver for first time to vehicle
  */
  assignDriver() {
    const formControl = new FormControl();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Asignación de conductor",
      label: 'Ingresa el número de documento',
      options: {
        typeUser: "driver",
      },
      inputFormControl: formControl,
      idVehicle: this.vehicle.id,
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const modalDialog = this.dialog.open(
      ChangeDriverComponent,
      dialogConfig
    );
    modalDialog.afterClosed().subscribe((result) => {
      if (result && result.state) {
        if (result.data === null) {
          this.openDialogCreateDriver();
        } else if (this.utils.isDefined(result.data)) {
          this.vehicleChangeDriver(result.data, result.securityStudyValidation);
        }
      }
    });
  }

  /**
  * @description Shows a modal to create a driver, and another one to confirm its assignment to finally executes "vehicleChangeDriver" method
  */
  private openDialogCreateDriver() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      licensePlate: this.vehicle.id,
    };
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(CreateDriverComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state && result.data) {
        this.dialogUtils
          .openDialog({
            title: `¿Estás seguro que deseas asignar a ${result.data.information.name} como conductor?`,
          })
          .then(() => {
            this.vehicleChangeDriver(result.data, result.securityStudyValidation);
          })
          .catch(err => err);
      }
    });
  }

  /**
  * @description Changes a vehicle's driver and shows a message with the result
  */
  vehicleChangeDriver(user: User, securityStudyValidation: boolean) {
    this.spinner.show();
    this.vehiclesService
      .vehicleChangeDriver(this.vehicle.id, user, securityStudyValidation)
      .toPromise()
      .then((success: User) => {
        if (success) {
          if (success && success.errorRNDC && success.errorRNDC.error === "Debe ingresar un conductor diferente al actual") {
            this.snackBarService.openSnackBar(
              success.errorRNDC.error,
              undefined,
              "alert"
            );
          } else {
            this.snackBarService.openSnackBar(
              "Conductor asignado correctamente"
            );
            this.getVehicle(this.vehicle.id, true);
          }
        } else {
          this.snackBarService.openSnackBar(
            "Ocurrió un error al cambiar de conductor",
            undefined,
            "error"
          );
        }
      })
      .catch((error) => {
        this.snackBarService.openSnackBar(
          "Ocurrió un error al cambiar de conductor",
          undefined,
          "error"
        );
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  /**
  * @returns {boolean} returns true if the vehicle is "TRACTOCAMION" and the driver's state is not pending, otherwise false
  * @description Checks if the trailer's tab must to be shown
  */
  get showTabTrailer(): boolean {
    return (
      this.vehicle.vehicleType &&
      this.vehicle.vehicleType.name === "TRACTOCAMION" &&
      ((this.driver &&
        this.driver.state &&
        this.driver.state.description !== UserState.PENDING) || !this.driver )
    );
  }

  /**
  * @returns {string|number} returns the driver's Truora score * 10 if exists, otherwise returns a message with pending validation
  * @description Transforms the driver's eps state as a legible string
  */
  get scoreTruora(): string | number {
    if (this.driver && this.driver.truora && this.driver.truora.scoreTruora) {
      return this.driver.truora.scoreTruora * 10;
    }
    return "Pendiente de Validacion";
  }

  /**
  * @description Executes the "getVehicle" method to update it
  */
  updateDriver() {
    this.getVehicle(this.vehicle.id);
  }

  /**
  * @returns {number} returns the number of remaining days to SOAT expiration
  * @description Gets the SOAT expiration day's countdown
  */
  get remainingDaysSoat(): number {
    let diff = null;
    if (this.vehicle && this.vehicle.validSOAT && this.vehicle.validSOAT.expirationDate)
      diff = DateManager.dateDiff(this.vehicle.validSOAT.expirationDate, 'YYYY-MM-DDTHH:mm:ssZ', new Date());
    return diff;
  }

  /**
  * @returns {number} returns the number of remaining days to RTM expiration
  * @description Gets the RTM expiration day's countdown
  */
  get remainingDaysRTM(): number {
    let diff = null;
    if (this.vehicle && this.vehicle.validRTM && this.vehicle.validRTM.expirationDate)
      diff = DateManager.dateDiff(this.vehicle.validRTM.expirationDate, 'YYYY-MM-DDTHH:mm:ssZ', new Date());
    return diff;
  }

  /**
  * @returns {number} returns the number of remaining days to additional certification expiration
  * @description Gets the additional certification expiration day's countdown
  */
  getRemainingDaysAdditionalCertification(type: AdditionalCertificationEnum): number {
    let diff = null;
    let additional = this.getAdditionalCertification(type)
    if (additional && additional.dueDate)
      diff = DateManager.dateDiff(additional.dueDate, 'YYYY-MM-DDTHH:mm:ssZ', new Date());
    return diff;
  }

  /**
  * @returns {number|string} returns the RTM expiration date as expected format or a message if doesn't exist
  * @description Gets the RTM expiration date as expected format
  */
  get rtmExpedirationDate(): number | string {
    let date = "No se registra fecha de expiración";
    if (this.vehicle && this.vehicle.validRTM && this.vehicle.validRTM.expirationDate)
      date = DateManager.formatDate(this.vehicle.validRTM.expirationDate, 'YYYY-MM-DDTHH:mm:ssZ', 'YYYY-MM-DD');
    return date;
  }

  /**
  * @returns {number|string} returns the SOAT expiration date as expected format or a message if doesn't exist
  * @description Gets the SOAT expiration date as expected format
  */
  get soatExpedirationDate(): number | string {
    let date = "No se registra fecha de expiración";
    if (this.vehicle && this.vehicle.validSOAT && this.vehicle.validSOAT.expirationDate)
      date = DateManager.formatDate(this.vehicle.validSOAT.expirationDate, 'YYYY-MM-DDTHH:mm:ssZ', 'YYYY-MM-DD');
    return date;
  }

  /**
  * @returns {FormGroup} returns the form's bankAccountAdvance as FormGroup
  * @description Gets the form's bankAccountAdvance as FormGroup
  */
  get formBankAccountAdvance(): FormGroup {
    return this.reactiveForm.form.get("bankAccountAdvance") as FormGroup;
  }
  /**
  * @returns {FormGroup} returns the form's bankAccountBalance as FormGroup
  * @description Gets the form's bankAccountBalance as FormGroup
  */
  get formBankAccountBalance(): FormGroup {
    return this.reactiveForm.form.get("bankAccountBalance") as FormGroup;
  }

  /**
  * @description Copies a message of vehicle, driver, owner and vehicle's state
  */
  copyMessage() {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = `Mensaje: ${this.stateUserPipe.transform(
      this.vehicle.state.description
    )} - Vehiculo: ${this.vehicle.id} - Conductor: ${this.vehicle.driver.document
      } - Propietario: ${this.vehicle.owner.document}`;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);

    if (selBox.value != "") {
      this.snackBarService.openSnackBar(
        "Texto copiado al portapapeles",
        undefined,
        "success"
      );
    }
  }

  /**
  * @param {string} licensePlate is the license plate of the vehicle to reactivate
  * @description Shows a modal to confirm the vehicle reactivate and executes "reactivateVehicleByInactivity" method by modal result
  */
  confirmReactivateVehicleByInactivity(licensePlate: string) {
    var daysAfterLastUpload;
    if (this.vehicle && this.vehicle.lastUploadDate) daysAfterLastUpload = Math.abs(DateManager.dateDiff(this.vehicle.lastUploadDate, 'DD/MM/YYYY', new Date()));
    let description = daysAfterLastUpload ? `<p class="text-center">Han pasado ${daysAfterLastUpload} días desde su último uso</p>` : '';
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `¿Seguro que deseas reactivar este vehículo?`,
      descriptionHTML: description,
      labelButton1: 'Cancelar',
      labelButton2: 'Reactivar',
      hideBtnCancel: true,
      hideBtnConfirm: true,
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    this.matDialog.open(DialogComponent, dialogConfig)
      .afterClosed()
      .subscribe(
        (result) => {
          if (result && result.state && result.refuse === "Reactivar") this.reactivateVehicleByInactivity(licensePlate);
        }
      );
  }

  /**
  * @param {string} licensePlate is the license plate of the vehicle to reactivate
  * @description Reactivates a vehicle by inactivity
  */
  private reactivateVehicleByInactivity(licensePlate: string) {
    const reactiveVehicleObserver = {
      next: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar("Se ha reactivado el vehículo correctamente");
        this.getVehicle(licensePlate);
      },
      error: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      },
      complete: () => {
        this.spinner.hide();
      }
    };
    this.spinner.show();
    this.vehiclesService.setStateVehicle(licensePlate, true).subscribe(reactiveVehicleObserver);
  }

  /**
  * @param {boolean} forceCreation indicates if the vehicle must to be created if doesn't exist or not
  * @description Sends a validation to check the vehicle state
  */
  checkValidateVehicle(forceCreation?: boolean) {
    const vehicleDetail = this.utils.clone(this.vehicle);
    let service = this.vehicle && this.vehicle.driver && this.vehicle.checkId && this.utils.getNestedValue(this.relatedInfoDriver, 'driver.truora.checkId') && this.vehicle.checkId === this.relatedInfoDriver.driver.truora.checkId ? this.adminUserService
    .checkValidationVehicle(vehicleDetail.id, forceCreation) : this.vehiclesService.basicCheck(this.vehicle.id);
    this.spinner.show();
      service.toPromise()
      .then((success: any) => {
        this.snackBarService.openSnackBar(
          "Validación en proceso",
          undefined,
          "success"
        );
      })
      .catch((error) => {
        this.snackBarService.openSnackBar(
          "Ocurrio un error en la validacion",
          undefined,
          "error"
        );
      })
      .finally(() => {
        this.getVehicle(this.vehicle.id, true);
        this.spinner.hide();
      });
  }

  /**
  * @description Shows a modal indicating the vehicle's errors
  */
  openDetailMissingDocuments() {
    let missingDocs = this.missingDocuments && this.missingDocuments.length;
    let unacceptedDocs = this.unacceptedDocuments && this.unacceptedDocuments.length;
    let invalidRefs = this.invalidReferences && this.invalidReferences.length;
    let dataListDocs = [];
    if (missingDocs && unacceptedDocs) {
      var unacceptedDocsToSend = this.unacceptedDocuments.filter(document => !this.missingDocuments.includes(document));
      if (unacceptedDocsToSend && unacceptedDocsToSend.length) dataListDocs.push({ label: 'Falta validar los siguientes documentos obligatorios:', data: unacceptedDocsToSend });
    } else if (!missingDocs && unacceptedDocs) dataListDocs.push({ label: 'Falta validar los siguientes documentos obligatorios:', data: this.unacceptedDocuments });
    if (missingDocs) dataListDocs.push({ label: 'Falta cargar los siguientes documentos obligatorios:', data: this.missingDocuments });
    if (invalidRefs && this.vehicle && this.vehicle.driver && this.vehicle.driver.document) dataListDocs.push({ label: 'Falta validar las siguientes referencias y contactos:', data: this.invalidReferences });
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Información obligatoria pendiente',
      dataList: dataListDocs,
      hideBtnCancel: true
    };
    dialogConfig.height = 'auto';
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    this.dialog.open(DialogComponent, dialogConfig);
  }

  /**
  * @returns {string} returns a message by vehicle's bank accounts state
  * @description Gets a message indicating the vehicle's bank accounts state
  */
  get stateProgressBar(): string {
    const bankAccountAdvance = this.utils.isDefined(this.vehicle.bankAccountAdvance);
    const bankAccountBalance = this.utils.isDefined(this.vehicle.bankAccountBalance);

    if (bankAccountAdvance && !bankAccountBalance) return "Cuenta de Saldos sin Registrar";
    if (!bankAccountAdvance && bankAccountBalance) return "Cuenta de Anticipos sin Registrar";
    if (!bankAccountAdvance && !bankAccountBalance) return "Cuentas Bancarias sin Registrar";

    if (this.vehicle.bankAccountAdvance.state && this.vehicle.bankAccountBalance.state)
      return "Cuentas Registradas Aprobadas";
    if (!this.vehicle.bankAccountAdvance.state && this.vehicle.bankAccountBalance.state)
      return "Cuentas Registradas, Falta Aprobar Cuenta de Anticipos";
    if (this.vehicle.bankAccountAdvance.state && !this.vehicle.bankAccountBalance.state)
      return "Cuentas Resgistradas, Falta Aprobar Cuenta de Saldos";

    return "Cuentas Registradas sin Aprobar";
  }

  /**
  * @returns {boolean} Returns true if there is some missing or unaccepted document or invalid references, otherwise false
  * @description Checks if the current vehicle has some errors
  */
  get missingOrUnacceptedDocs(): boolean {
    return !!((this.unacceptedDocuments && this.unacceptedDocuments.length)
      || (this.missingDocuments && this.missingDocuments.length)
      || (this.invalidReferences && this.invalidReferences.length));
  }

  /**
  * @returns {boolean} Returns true if vehicle or driver are inactive, otherwise false
  * @description Checks if the current vehicle and driver are inactive
  */
  public get disabledOptions(): boolean {
    return (
      !this.utils.isEmpty(this.vehicle) &&
      !this.utils.isEmpty(this.driver) &&
      !this.utils.isEmpty(this.vehicle.state) &&
      !this.utils.isEmpty(this.driver.state) &&
      !this.utils.isEmpty(this.vehicle.state.active) &&
      !this.utils.isEmpty(this.driver.state.active) &&
      !this.utils.isEmpty(this.vehicle.state.description) &&
      !this.utils.isEmpty(this.driver.state.description)
    );
  }

  /**
  * @returns {boolean} Returns true if vehicle or driver has state Pending, otherwise false
  * @description Checks if the current vehicle and driver has state Pending
  */
  public get notAllowedEdit(): boolean {
    return this.disabledOptions && this.driver.state.description === "Pending" && this.vehicle.state.description === "Pending"
  }

  /**
  * @description Executes "getVehicle" method to update the vehicle info
  */
  public refreshVehicleData(): void {
    this.getVehicle(this.vehicle.id);
  }

  /**
  * @description Executes "openGps" method
  */
  public editGps(): void {
    this.openGps(
      this.vehicle,
      this.reactiveFormCreate.form
    ).subscribe(
      (success) => {
        this.refreshVehicleData();
      },
      (error) => { }
    );
  }

  get hasSatrackGps(): boolean {
    return this.vehicle && this.vehicle.gps && this.vehicle.gps.gpsType
      && this.vehicle.gps.gpsType.toLowerCase() === 'satrack';
  }

  /**
  * @description Executes "updateInformation" method
  */
  public openEditVehicle() {
    this.updateInformation(this.vehicle).subscribe((success) => {
      this.getVehicle(this.vehicle.id);
    });
  }

  /*public get administrator() {
    let user: User;
    if (this.vehicle && this.vehicle.administrator) {
      user = {
        information: this.vehicle.administrator,
        role: "Admin",
      };
    }
    return user;
  }*/

  /**
  * @returns {Array<string>} Returns an array with driver's invalid references
  * @description Gets an array of driver's invalid references
  */
  public get invalidReferences(): Array<string> {
    return this.driver && this.driver.information && this.driver.information.document ? this.vehiclesService.invalidReferences(this.driver) : [];
  }

  /**
  * @returns {string} Returns the vehicle's registration date if exists
  * @description Gets the vehicle's registration date if exists
  */
  public get registrationDate(): string {
    if (!!this.vehicle.registrationDate)
      return DateManager.formatDate(this.vehicle.registrationDate, 'YYYY-MM-DD HH:mm ZZ', 'YYYY-MM-DD');
    return 'No encontrada';
  }

  /**
  * @param {Vehicle} vehicle is the vehicle info to update
  * @description Updates the current vehicle using vehicle param
  */
  private async setVehicle(vehicle: Vehicle) {
    this.vehicle = vehicle;
    if (this.vehicle && this.vehicle.registrationDate && this.vehicle.vehicleType && this.vehicle.vehicleType.name) this.currentVehicleByRtmExoneration = this.vehicleExoneratedByRtm(this.vehicle.vehicleType.name);
    this.errorsRNDC.vehicle = vehicle.errorRNDC;
    this.documentsValidator = await this.vehiclesService.documentsValidator(this.vehicle);
    this.unacceptedDocuments = await this.vehiclesService.getMissingDocuments(vehicle, 'unaccepted');
    this.missingDocuments = await this.vehiclesService.getMissingDocuments(vehicle, 'missing');
    if (this.relatedInfoDriver && !this.utils.getNestedValue(this.relatedInfoDriver, 'driver.information.document')) {
      let driverDocuments = ["Documento de identidad", "Licencia de conducción"]
      this.unacceptedDocuments = this.unacceptedDocuments.filter(document => !driverDocuments.includes(document))
      this.missingDocuments = this.missingDocuments.filter(document => !driverDocuments.includes(document))
    }
    this.relatedInfoDriver.vehicle = this.vehicle;
  }

  /**
  * @returns {string} Returns a string with driver, owner and vehicle errors as HTML format
  * @description Gets the driver, owner and vehicle errors as HTML format
  */
  private getHTMLFromRNDCErrors(): string {
    let descriptionHTML = ``;
    if (this.errorsRNDC.driver && this.errorsRNDC.driver.error)
      descriptionHTML += `<b>Conductor: </b> ${this.errorsRNDC.driver.error}<br/>`;
    if (this.errorsRNDC.owner && this.errorsRNDC.owner.error)
      descriptionHTML += `<b>Propietario: </b> ${this.errorsRNDC.owner.error}<br/>`;
    if (this.errorsRNDC.vehicle && this.errorsRNDC.vehicle.error)
      descriptionHTML += `<b>Vehículo: </b> ${this.errorsRNDC.vehicle.error}<br/>`;

    return descriptionHTML;
  }

  /**
  * @description Opens a modal to show the RNDC errors
  */
  public openRNDCErrors(): void {
    const descriptionHTML = this.getHTMLFromRNDCErrors();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `Errores reportados por el RNDC`,
      iconError: true,
      hideBtnCancel: true,
      confirmBtn: "Cerrar",
      descriptionHTML
    };
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    this.matDialog.open(DialogComponent, dialogConfig);
  }

  /**
  * @returns {boolean} Returns true if the user the "updateVehicle" permission, otherwise false
  * @description Verifies if the user has the "updateVehicle" permission
  */
  get canEditVehicleInfo(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.administration.module,
      this.permission.administration.updateVehicle
    );
  }

  /**
  * @returns {boolean} Returns true if the user's SaaS company has escort services
  * @description Verifies if the user's SaaS company has escort services
  */
  get hasEscortServicesCompany(): boolean {
    return this.authService.getCompanySaaS() && this.authService.getCompanySaaS().companyId === Companies.companiesNIT.SEGURIDAD_EXTREMA;
  }

  /**
  * @param {string} field is the editableFields field to edit
  * @param {boolean} activate indicates if the field must to be able to edit or not
  * @description Updates an editableFields field to edit it
  */
  get canOverwriteVehicleInfo(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.administration.module,
      this.permission.administration.editFieldsRUNT
    );
  }

  /**
  * @returns {boolean} Returns true if the user has some permission to edit vehicle fields, otherwise false
  * @description Verifies if the user has some permission to edit vehicle fields
  */
  get showEditVehicleMessage(): boolean {
    return !!this.vehicle && (this.canEditVehicleInfo || this.canOverwriteVehicleInfo);
  }

  get correctVehicleData(): boolean {
    let message = 'Los datos de propietario suministrados no corresponden con los propietarios activos del vehiculo';
    return this.vehicle && this.vehicle.state && this.vehicle.state.description !== message;
  }

  /**
  * @description Opens a modal to edit the vehicle fields, and refresh the vehicle if some change has been applied
  */
  editVehicleInfo(type: 'SOAT' | 'RTM' | 'vehicle' | 'license', license?: DriverLicenseCategory) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { type };
    if (type === 'license') {
      if (!!license) dialogConfig.data['license'] = license;
      dialogConfig.data['driver'] = this.driver;
    }
    else dialogConfig.data['vehicle'] = this.vehicle;

    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    this.matDialog.open(UpdateVehicleFieldsComponent, dialogConfig).afterClosed().subscribe((result) => {
      if (result && result.state) this.initialConfig();
    })
  }

  /**
  * @description Opens a modal to confirm and Forces the vehicle activation
  */
  forzeActivation() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `¿Realmente desea forzar la activación de este vehículo?`,
      description: `Asegúrese de haber revisado que toda la información adjunta es considerada válida y el vehículo tiene un estado correcto y aprobado por el área de análisis de seguridad y tráfico para forzar la activación.`,
      confirmBtn: "Forzar",
    };
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    this.matDialog.open(DialogComponent, dialogConfig)
      .afterClosed()
      .subscribe(
        (result) => {
          if (result && result.state) {
            this.vehicle.state.active = true;
            this.vehicle.state.description = 'Active';
            this.vehiclesService.updateVehicle(this.vehicle).subscribe(
              () => {
                this.initialConfig();
              }
            );
          }
        }
      );
  }

  //GETTERS
  getPendingApproval(type: string): boolean {
    return this.driver && this.driver.extraDocuments && this.driver.extraDocuments.some(document => document.type === type && !document.approvalBy);
  }

  getValidateVehicle(): boolean {
    return !!(
      this.utils.isDefined(this.vehicle) &&
      this.utils.isDefined(this.vehicle.vehicleType) &&
      this.utils.isDefined(this.vehicle.vehicleType.name) &&
      this.vehicle.vehicleType.name !== '' &&
      this.utils.isDefined(this.vehicle.bodywork) &&
      this.utils.isDefined(this.vehicle.bodywork.description) &&
      this.vehicle.bodywork.description !== '' &&
      this.utils.isDefined(this.vehicle.brand) &&
      this.utils.isDefined(this.vehicle.brand.description) &&
      this.vehicle.brand.description !== '' &&
      this.utils.isDefined(this.vehicle.registrationDate) &&
      this.vehicle.registrationDate !== ''
    );
  }

  getValidateVehicleDocuments(type: 'RTM' | 'SOAT'): boolean {
    const securityValidationType = type === 'RTM' ? StatusSecurityValidationType.RTM : StatusSecurityValidationType.SOAT;
    let typeDocument = type === 'RTM' ? 'validRTM' : 'validSOAT';
    const hasVehicleDocumentActive =
      this.utils.isDefined(this.vehicle) &&
      this.utils.isDefined(this.vehicle[typeDocument]) &&
      this.utils.isDefined(this.vehicle[typeDocument].expirationDate) &&
      this.vehicle[typeDocument].expirationDate !== '' &&
      this.utils.isDefined(this.vehicle[typeDocument].active);
    const hasSecurityValidation =
      this.vehicle.statusesSecurityValidation &&
      this.vehicle.statusesSecurityValidation.length &&
      this.vehicle.statusesSecurityValidation.some(status => status && status.status === StatusSecurityValidationStatus.FOUND && status.type === securityValidationType);
    return hasVehicleDocumentActive || hasSecurityValidation;
  }

  getValidateLicense(): boolean {
    const hasLicense =
      this.utils.isDefined(this.driver) &&
      this.utils.isDefined(this.driver.driverLicenseCategory) &&
      this.utils.isDefined(this.driver.driverLicenseCategory.length);
    const hasSecurityValidation =
      this.driver.truora &&
      this.driver.truora.statusesSecurityValidation &&
      this.driver.truora.statusesSecurityValidation.length &&
      this.driver.truora.statusesSecurityValidation.some(status => status && status.status === StatusSecurityValidationStatus.FOUND && status.type === StatusSecurityValidationType.DRIVING_LICENSES);
    return hasLicense || hasSecurityValidation;
  }

  getValidateDriverDocuments(type: 'arl' | 'eps'): boolean {
    const hasDocument =
      this.utils.isDefined(this.driver) &&
      this.utils.isDefined(this.driver[type]) &&
      this.utils.isDefined(this.driver[type].active) &&
      this.utils.isDefined(this.driver[type].description) &&
      this.driver[type].description !== '';
    const securityValidationType = type === 'arl' ? StatusSecurityValidationType.ARL : StatusSecurityValidationType.EPS;
    const hasSecurityValidation =
      this.driver.truora &&
      this.driver.truora.statusesSecurityValidation &&
      this.driver.truora.statusesSecurityValidation.length &&
      this.driver.truora.statusesSecurityValidation.some(status => status && status.status === StatusSecurityValidationStatus.FOUND && status.type === securityValidationType);
    return hasDocument || hasSecurityValidation;
  }

  get additionalCertificationEnum(): typeof AdditionalCertificationEnum {
    return AdditionalCertificationEnum;
  }
}
