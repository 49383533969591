import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { PaginationList } from 'src/app/core/models/pagination-list';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { VehiclesService } from '../../vehicles/list-vehicles.service';
import { MaintenanceDetail } from 'src/app/core/interfaces/maintenance-detail';
import { MaintenanceService } from '../maintenance.service';
import { Pagination } from 'src/app/core/interfaces/pagination';

@Component({
  selector: 'app-maintenance-list-detail',
  templateUrl: './maintenance-list-detail.component.html',
  styleUrls: ['./maintenance-list-detail.component.scss']
})
export class MaintenanceListDetailComponent implements OnInit {
  filters: string = '';
  paginationList: PaginationList = new PaginationList();
  vehicle: Vehicle;
  viewActive: string = 'all';
  flag: boolean = false;
  constructor(
    private spinner: NgxSpinnerService,
    private snackbarService: SnackBarService,
    private utils: Utils,
    private maintenanceService: MaintenanceService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(async (params: { id: string }) => {
      if (params && params.id) {
        let vehicle = await this.maintenanceService.getVehicle(params.id);
        if (vehicle) {
          this.vehicle = vehicle;
          if (sessionStorage.getItem('maintenanceDetailFilters')) this.processFilters(sessionStorage.getItem('maintenanceDetailFilters'));
          else this.processFilters('all');
        }
        else this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
      else this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
    });
  }


  getListMaintenance($event?: string) {
    this.flag = false;
    let paramsFilters: string = '';
    paramsFilters += `&licensePlate=${this.vehicle.id}&state=true`;
    paramsFilters += $event ? $event : '';

    const listMaintenanceVehicleObserver = {
      next: (data: { pagination: Pagination, results: MaintenanceDetail[] }) => {
        this.spinner.hide();
        if (!this.utils.isDefined(data) || !this.utils.isDefined(data.results) || data.results.length === 0) {
          this.paginationList.setEnablePagingScroll(false);
        } else {
          if (this.paginationList.getList().length) {
            this.paginationList.setList(
              this.paginationList.getList().concat(data.results)
            );
          } else this.paginationList.setList(data.results);
        }
        this.flag = true;
      },
      error: () => {
        this.spinner.hide();
        this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
        if (!(this.paginationList.getList().length > 0)) {
          this.resetPagging();
          this.paginationList.setList([]);
        }
        this.flag = true;
      },
      complete: () => {
        this.flag = true;
      }
    };
    this.spinner.show();
    this.maintenanceService.getListMaintenanceVehicle(
      paramsFilters,
      this.paginationList.getPageKey(),
      this.paginationList.getPageSize()
    ).subscribe(listMaintenanceVehicleObserver);

  }


  resetPagging() {
    this.paginationList.setPageKey(1);
    this.paginationList.setList([]);
    this.paginationList.setEnablePagingScroll(true);
  }

  refreshList($event?: string) {
    !this.paginationList
      ? this.paginationList.setList([])
      : this.resetPagging();
    this.filters = $event;
    this.getListMaintenance(this.filters);
  }

  onScrollDown() {
    if (
      this.paginationList.getEnablePagingScroll()
    ) {
      this.paginationList.setPageKey(
        this.paginationList.getPageKey() + 1
      );
      this.getListMaintenance(this.filters);
    }
  }

  ngOnDestroy() {
    sessionStorage.removeItem('maintenanceDetailFilters');
  }

  processFilters($event?: string) {
    let filters: string = $event ? $event !== 'all' ? `&maintenanceType=${$event}` : '' : sessionStorage.getItem('maintenanceDetailFilters') && sessionStorage.getItem('maintenanceDetailFilters') !== 'all' ? `&maintenanceType=${sessionStorage.getItem('maintenanceDetailFilters')}` : '';
    this.viewActive = $event ? $event : sessionStorage.getItem('maintenanceDetailFilters') ? sessionStorage.getItem('maintenanceDetailFilters') : 'all';
    this.refreshList(filters);
    if ($event) sessionStorage.setItem('maintenanceDetailFilters', $event);

  }

}
