import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { VehicleNegotiationInfoUser } from 'src/app/core/interfaces/negotiation/vehicle-negotiation-info-user';
import { ResponseList } from 'src/app/core/interfaces/response-list';
import { VehicleNegotiationParams } from 'src/app/core/interfaces/VehicleNegotiationsParams';
import { Dialog } from 'src/app/core/resources/dialog';
import { CargoService } from 'src/app/core/services/cargo.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { TransportRequestsService } from 'src/app/src/app/core/services/transport-requests.service';
import { CargoDetailService } from '../cargo-detail/cargo-detail.service';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { AssignVehicleCargoComponent } from '../assign-vehicle-cargo/assign-vehicle-cargo.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { VehiclesService } from '../../administration/vehicles/list-vehicles.service';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { DetailVehicleComponent } from '../../administration/vehicles/detail-vehicle/detail-vehicle.component';

@Component({
  selector: 'app-vehicle-applications-transport-requests',
  templateUrl: './vehicle-applications-transport-requests.component.html',
  styleUrls: ['./vehicle-applications-transport-requests.component.scss'],
  providers: [TransportRequestsService]
})
export class VehicleApplicationsTransportRequestsComponent implements OnInit {

  public responseList: ResponseList<VehicleNegotiationInfoUser> = {
    results: [],
    pagination: {
      pageKey: 1,
      pageSize: 25,
      total: 0
    }
  };
  public request: Cargo;
  private vehicle: Vehicle;

  constructor(
    private route: ActivatedRoute,
    private transportRequestsService: TransportRequestsService,
    private cargoDetailService: CargoDetailService,
    private dialog: Dialog,
    private snackbarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private matDialog: MatDialog,
    private vehiclesService: VehiclesService
  ) {
    this.getRequest();
  }

  ngOnInit() {

  }

  public onRefuse(negotiation: VehicleNegotiationInfoUser): void {
    this.dialog.openDialog({
      title: `¿Desea rechazar al conductor ${negotiation.applicantId} con placa ${negotiation.licensePlate}?`,
      iconError: true
    }).then(() => {
      this.spinner.show();
      this.transportRequestsService.negotiationsVehiclesOffers(negotiation.id, 'Refuse').subscribe(
        (response) => {
          console.log(response);
          this.spinner.hide();
          this.getRequest();
          this.dialog.openDialog({
            title: `El conductor ${negotiation.applicantId} con placa ${negotiation.licensePlate} ha sido rechazado`,
            hideBtnCancel: true
          });
        },
        (error) => {
          console.error(error);
          this.spinner.hide();
          this.snackbarService.openSnackBar(`Ocurrió un error al intentar rechazar al conductor ${negotiation.applicantId} con placa ${negotiation.licensePlate}?`, undefined, 'error');
        }
      );
    });
  }

  public onAccepted(negotiation: VehicleNegotiationInfoUser): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Datos del Vehículo Asignado",
      cargo: this.request,
      type: 'assign',
      licensePlate: negotiation.licensePlate
    };
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    const dialogRef = this.matDialog.open(AssignVehicleCargoComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result && result.state) {
        this.getRequest();
        this.dialog.openDialog({
          title: `El conductor ${negotiation.applicantId} con placa ${negotiation.licensePlate} ha sido correctamente asignado a la carga ID ${this.request.consecutive} `,
          hideBtnCancel: true
        });
      }
    });
  }

  private getRequest(): void {
    this.spinner.show();
    const params = this.route.snapshot.params;
    this.cargoDetailService.detailCargo(params.id).subscribe(
      (response) => {
        this.request = (response as Cargo);
        this.getVehicleNegotiations(params.id);
        this.spinner.hide();
      },
      (error) => {
        console.error(error);
        this.spinner.hide();
      }
    );
  }

  private getVehicleNegotiations(cargoId: string): void {
    const vehicleNegotiationParams: VehicleNegotiationParams = {
      cargoId
    };
    this.spinner.show();
    this.transportRequestsService.getVehicleNegotiations(vehicleNegotiationParams).subscribe(
      (response) => {
        this.responseList = response;
        this.spinner.hide();
      }, (error) => {
        console.error(error)
        this.spinner.hide();
      }
    );
  }

  public async getDetailVehicle(negotiation: VehicleNegotiationInfoUser) {
    if (negotiation.licensePlate) {
      return new Promise((resolve, reject) => {
        this.vehiclesService.getVehicle(negotiation.licensePlate).subscribe(
          (success: any) => {
            if (success) {
              this.vehicle = success;
              this.openDetailVehicle();
              resolve(true);
            } else {
              this.vehicle = null;
              reject(false)
            }
          },
          (error) => {
            this.vehicle = null;
            reject(false);
          }
        );
      })
    }
  }

  private openDetailVehicle(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      vehicle: this.vehicle,
      close: true,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    this.matDialog.open(DetailVehicleComponent, dialogConfig);
  }

}
