<aside class="maintenance-list-vehicle">
  <div class="maintenance-list-vehicle__filter mt-3">
    <app-maintenance-filter (emitFilters)="processFilters($event)"></app-maintenance-filter>
  </div>
  <div
    *ngIf="paginationList.getList().length && flag"
    class="maintenance-list-vehicle__body"
    infinite-scroll
    [infiniteScrollDistance]="paginationList.getScrollDistance()"
    [infiniteScrollThrottle]="paginationList.getThrottle()"
    [scrollWindow]="false"
    (scrolled)="onScrollDown()">
      <div class="maintenance-list-vehicle__body--item" *ngFor="let vehicle of paginationList.getList(); let i = index;">
        <app-maintenance-item-vehicle [vehicle]="vehicle"></app-maintenance-item-vehicle>
      </div>
  </div>
  <div
    *ngIf="!paginationList.getList().length && flag"
    class="maintenance-list-vehicle__not-content">
    <div class="maintenance-list-vehicle__not-content--icon"><i class="fas fa-calendar-times"></i></div>
      <h4 class="text-bold text-center">No hay resultados que coincidan con la búsqueda</h4>
  </div>
  <div class="maintenance-list-vehicle__skeleton-container h-100" *ngIf="!flag">
    <ngx-skeleton-loader [theme]="{height: '164px'}" count="4" appearance="line" class="maintenance-list-vehicle__skeleton-container--item"></ngx-skeleton-loader>
  </div>
</aside>
