import { Component, OnInit, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material';
import { ReportsService } from './../reports.service';
import { Utils } from 'src/app/core/resources/utils';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Titles } from 'src/app/core/resources/titles';
import { Company } from 'src/app/core/interfaces/company';
import { Patterns } from 'src/app/core/resources/patterns';
import { DateEnum } from 'src/app/core/enums/date.enum';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { environment } from 'src/environments/environment';
import { CargoStateEnum } from 'src/app/core/enums/cargoState.enum';
import { Subscription } from 'rxjs';
import { Companies } from 'src/app/core/resources/companies';

@Component({
  selector: 'app-reports-filter',
  templateUrl: './reports-filter.component.html',
  styleUrls: ['./reports-filter.component.scss'],
  providers: [AuthService]
})
export class ReportsFilterComponent implements OnInit {

  @Output() onCloseModal = new EventEmitter<any>();
  @Output() onFilter = new EventEmitter<any>();
  @Output() openModalExportReportEmitter = new EventEmitter<any>();
  @Output() generateExcel = new EventEmitter<any>();
  @Output() chartOptionsTrigger = new EventEmitter<boolean>();
  @Input() isPlainReport;
  @Input() isPDFReport;
  onFilterData: { response, titleReport, idReportType, units } = {
    response: null,
    titleReport: null,
    idReportType: null,
    units: null
  };
  formFilter: FormGroup;
  reportTypeSub: Subscription;
  companyUserData: Company;
  loadDateOptions = {
    min: null,
    max: new Date()
  };
  endDateOptions = {
    min: null,
    max: new Date()
  };
  reportResponse = {
    response: {
      reports: {
        'abc123': {
          average: 0,
          totalCases: 0,
          averageable: {
            consecutive: 0,
            guideId: 0,
            numberDocumentSender: "0",

          },
          totalValue: 0
        }
      }
    },
    titleReport: 'Previsualización gráfica',
    idReportType: 'Empty',
    units: ''
  }
  typesReports = [];
  optionAllCompanies: boolean;
  states: Array<any> = [];
  selectedStates = new FormControl([]);
  selectAll: boolean = false;
  cleanChart: boolean = false;
  //messages
  errorStatesSelectedMessageExcel: string = 'Por favor seleccione el o los estado del servicio que requiere para poder generar el excel';
  errorStatesSelectedMessage: string = 'Por favor seleccione el o los estado del servicio que desea buscar';
  errorNotSelectedTypeReportMessage: string = 'Por favor seleccione un tipo de reporte para buscar';
  errorNotSelectedDates: string = 'Por favor especifique el rango de fechas';
  optionsCompany = {
    title: 'Compañía',
    appearance: 'outline'
  };
  rotateArrowStates: boolean = false;
  validCheckboxes: boolean = false;

  private reportRange = environment.rootNit == this.authService.getCompany().companyId ? 1 : 3;
  constructor(
    private authService: AuthService,
    private titles: Titles,
    private reportsService: ReportsService,
    public utils: Utils,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private patterns: Patterns
  ) {
    this.companyUserData = this.authService.getCompany();
    if (
      this.utils.isDefined(this.companyUserData) &&
      this.utils.isDefined(this.companyUserData.configuration) &&
      this.utils.isDefined(this.companyUserData.configuration.reports)
    ) {
      this.typesReports = this.companyUserData.configuration.reports;
    }
  }

  ngOnInit() {
    this.optionAllCompanies = this.authService.userIsFromRootNit();
    this.optionsCompany['optionAll'] = this.optionAllCompanies;
    if (!this.optionAllCompanies) this.optionsCompany['initialNit'] = this.authService.getCompany().companyId;
    this.selectedStates.setValue([])
    this.createFormFilter();
    this.formFilter.get('loadDate').setValidators(Validators.required);
    this.formFilter.get('endDate').setValidators(Validators.required);
    this.formFilter.get('reportType').setValidators(Validators.required);
    this.reportTypeSub = this.formFilter.get('reportType').valueChanges.subscribe(value => {
      this.states = [];
      this.generateExcel.emit(false);
      this.selectedStates.setValue([])
      this.typesReports.forEach(report => {
        if (report.type === value) {
          if (report.states && report.states.length > 1) {
            const reportState = report.states;
            this.states = reportState.map(state => {
              let stateName = '';
              stateName = this.selectStateName(state)
              return {
                name: stateName,
                state: state,
                disabled: false
              };
            });
            this.states.push({
              name: 'Todos los servicios',
              state: 'All cargo',
              disabled: false
            });

            if (this.selectedStates.value.length === 0) {
              this.validCheckboxes = false;
              this.generateExcel.emit({ valid: false, message: this.errorStatesSelectedMessageExcel });
            }
          } else if (report.states && report.states.length === 1) {
            this.selectedStates.setValue(report.states);
            this.generateExcel.emit(true);
          } else {
            this.selectedStates.setValue([]);
            this.generateExcel.emit(true);
          }
        }

      })
    })
  }

  onClickDiv() {
    this.rotateArrowStates = !this.rotateArrowStates;
  }

  selectStateName(state: string) {
    switch (state) {
      case CargoStateEnum.CREATED:
        return 'Servicios programados'
      case CargoStateEnum.ACCEPTED:
        return 'Servicios aceptados'
      case CargoStateEnum.START_SERVICE:
        return 'Servicios Iniciados'
      case CargoStateEnum.END_SERVICE:
        return 'Servicios Terminados'
    }
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit() {
    this.formFilter.controls.reportType.setValue(null);
    this.setChartInfo()
  }
  setChartInfo() {
    this.onFilter.emit(this.reportResponse);
  }


  createFormFilter() {
    this.formFilter = new FormGroup({
      company: new FormControl({
        value: null
      }),
      loadDate: new FormControl({
        value: ''
      }),
      endDate: new FormControl({
        value: ''
      }),
      reportType: new FormControl({
        value: ''
      }),
      ministry: new FormControl({
        value: -1
      }),
      plate: new FormControl({
        value: -1
      }),
      finishedBy: new FormControl(
        null,
        Validators.compose(
          [Validators.required]
        )
      ),
    });
    this.cleanFilter(false);
  }

  onChangeDate(typeDate: string, $event: MatDatepickerInputEvent<Date>) {
    if (typeDate === 'loadDate') {
      this.endDateOptions.min = $event.value;
      this.endDateOptions.max = DateManager.isSameOrBefore(new Date(), DateManager.add($event.value, this.reportRange, 'months'), 'days')
        ? new Date()
        : DateManager.add($event.value, this.reportRange, 'months');
    } else {
      this.loadDateOptions.min = DateManager.substract($event.value, this.reportRange, 'months');
      this.loadDateOptions.max = $event.value;
    }
  }

  onSubmit(fileName?: string, reportType?: 'Excel' | 'PDF' | 'Plain') {
    if (this.formFilter.value.reportType === null) {
      this.snackBarService.openSnackBar(this.errorNotSelectedTypeReportMessage, undefined, 'alert')
      return;
    }
    if (this.states && this.states.length > 1 && this.selectedStates.value.length === 0) {
      this.snackBarService.openSnackBar(this.errorStatesSelectedMessage, undefined, 'alert')
      return;
    }
    if (this.formFilter.get('loadDate').invalid || this.formFilter.get('endDate').invalid) {
      this.snackBarService.openSnackBar(this.errorNotSelectedDates, undefined, 'alert')
      return;
    }
    this.chartOptionsTrigger.emit(true);
    let thisClass = this;
    let loadDate = "";
    let endDate = "";
    let ministry = null;
    let plate = null;
    let finishedBy = null;
    let email = false;

    if (this.formFilter.controls.loadDate.value) {
      loadDate = DateManager.dateToString(this.formFilter.controls.loadDate.value, 'YYYY-MM-DD ZZ');
    }

    if (this.formFilter.controls.endDate.value) {
      endDate = DateManager.dateToString(this.formFilter.controls.endDate.value, 'YYYY-MM-DD ZZ');
    }
    if (
      loadDate &&
      endDate &&
      DateManager.dateDiff(this.formFilter.controls.endDate.value, null, this.formFilter.controls.loadDate.value) >= DateEnum.MAX_DAYS_TO_EXCEL
    ) {
      email = true;
    }

    if (typeof this.formFilter.get('ministry').value === 'boolean') {
      ministry = this.formFilter.controls.ministry.value;
    }

    if (!this.utils.isEmpty(this.formFilter.get('plate').value)) {
      plate = this.formFilter.controls.plate.value;
    }

    if (!this.utils.isEmpty(this.formFilter.get('finishedBy').value)) {
      finishedBy = this.formFilter.get('finishedBy').value;
    }

    if (this.formFilter.get('reportType').value === 'useApp' && this.utils.isEmpty(finishedBy)) {
      this.snackBarService.openSnackBar('Debe seleccionar el campo finalizado por', undefined, 'alert');
      return;
    }

    if (!this.utils.isEmpty(plate)) {
      if (!this.patterns.GET_REGEX('LICENSE_PLATES').test(plate)) {
        this.snackBarService.openSnackBar("Formato de placa incorrecto", undefined, 'alert');
        return;
      }
    }

    this.spinner.show();
    this.reportsService.getReports(
      loadDate,
      endDate,
      ministry,
      plate,
      this.formFilter.get('company').value.companyId,
      this.formFilter.get('company').value.exclusive,
      this.formFilter.controls.reportType.value,
      email,
      reportType,
      finishedBy,
      this.selectedStates.value
    ).subscribe(
      (success: any) => {
        this.spinner.hide();
        if (email) {
          if (success && success.message) {
            this.snackBarService.openSnackBar(success.message);
          } else {
            this.snackBarService.openSnackBar('No hay datos para exportar', undefined, 'alert');
          }
          this.onCloseModal.emit();
        }
        else if (!!reportType) {
          if (success && success.body) {
            this.utils.downloadFile(success.body, fileName);
          } else {
            this.snackBarService.openSnackBar('No hay datos para exportar', undefined, 'alert');
          }
          this.onCloseModal.emit();
        } else {
          this.chartData(success);
        }
      },
      (error) => {
        this.spinner.hide();
        if (reportType == 'Excel') {
          this.snackBarService.openSnackBar(this.titles.errorExportFile, undefined, 'error');
        } else {
          this.snackBarService.openSnackBar(this.titles.errorGetReports, undefined, 'error');
          this.onFilter.emit(null);
        }
      }
    )
  }

  chartData(success) {
    this.onFilterData.response = success;
    this.onFilterData.titleReport = this.getNameTitleReport(this).title;
    this.onFilterData.idReportType = this.formFilter.controls.reportType.value;
    this.onFilterData.units = this.reportsService.getDataTypeReportById(
      this.formFilter.controls.reportType.value, this.typesReports
    ).units;
    this.onFilter.emit({
      response: success,
      titleReport: this.getNameTitleReport(this).title,
      idReportType: this.formFilter.controls.reportType.value,
      units: this.reportsService.getDataTypeReportById(this.formFilter.controls.reportType.value, this.typesReports).units
    });
  }

  cleanFilter(clean: boolean) {
    this.chartOptionsTrigger.emit(false);
    this.generateExcel.emit(false);
    this.cleanChart = clean;
    this.formFilter.controls.loadDate.setValue('');
    this.formFilter.controls.endDate.setValue('');
    this.formFilter.controls.reportType.setValue(null);
    this.formFilter.controls.plate.setValue('');
    this.loadDateOptions.min = null;
    this.loadDateOptions.max = new Date();
    this.endDateOptions.min = null;
    this.endDateOptions.max = new Date();
    this.selectedStates.setValue([]);
    this.onFilter.emit(this.reportResponse);
    if (this.cleanChart) {
      this.snackBarService.openSnackBar('Filtros limpios', undefined, 'success')
    }
    this.formFilter.get('reportType').markAsUntouched();
  }

  exportReport(reportType: 'Excel' | 'PDF' | 'Plain') {
    const thisClass = this;
    this.onSubmit(this.getNameTitleReport(thisClass).title, reportType);
  }

  onChange(event: any) {
    this.validCheckboxes = false;
    const value = this.selectedStates.value as string[];
    const checked = event.checked;

    const index = value.indexOf(event.source.value);
    if (checked && index === -1) {
      if (this.selectedStates.value) {
        this.validCheckboxes = true;
        this.generateExcel.emit(true);
      }
      if (event.source.value !== 'All cargo') {
        value.push(event.source.value);
        this.selectedStates.setValue(value);
        if (this.selectedStates.value.length === 4 || (this.formFilter.get('reportType').value === 'fulfill' && this.selectedStates.value.length === 2)) {
          const statesValue = this.states.map(state => state.state)
          this.selectedStates.setValue(statesValue);
        }
      }

      if (event.source.value === 'All cargo') {
        this.selectAll = true;
        const statesValue = this.states.map(state => state.state)
        this.selectedStates.setValue(statesValue);
      }

    } else if (!checked && index > -1 && event.source.value !== 'All cargo') {

      value.splice(index, 1);
      this.selectedStates.setValue(value);
      if (this.selectedStates.value.includes('All cargo')) {
        const allCargoIndex = this.selectedStates.value.indexOf('All cargo');
        this.selectedStates.value.splice(allCargoIndex, 1);
      }

    } else if (!checked && event.source.value === 'All cargo') {
      this.selectAll = false;
      this.selectedStates.setValue([]);
    }
    if (!this.selectedStates.value.length) {
      this.validCheckboxes = false;
      this.generateExcel.emit({ valid: false, message: this.errorStatesSelectedMessageExcel });
    }
  }

  getNameTitleReport(thisClass) {
    const data = _.find(this.typesReports, (type) => {
      return type.type === thisClass.formFilter.controls.reportType.value;
    });

    return data;
  }

  openModalExportReport(typeFile) {
    if (this.formFilter && this.formFilter.get('reportType') && this.formFilter.get('reportType').value && this.validCheckboxes) {
      let typeFileName = typeFile && typeFile === 'Plain' ? 'archivo de texto plano' : typeFile;
      if (typeFile === 'Plain' && !this.isPlainReport) {
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.INVALID_TYPE_REPORT, typeFileName, 'UIAF'), undefined, 'alert');
        return;
      }
      else if (typeFile === 'PDF' && !this.isPDFReport) {
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.INVALID_TYPE_REPORT, typeFileName, 'preliquidación'), undefined, 'alert');
        return;
      }
    }
    this.openModalExportReportEmitter.emit(typeFile);

  }

  /**
  * @returns {boolean} Returns true if the user's SaaS company has escort services
  * @description Verifies if the user's SaaS company has escort services
  */
  get hasEscortServicesCompany(): boolean {
    return this.authService.getCompanySaaS() && this.authService.getCompanySaaS().companyId === Companies.companiesNIT.SEGURIDAD_EXTREMA;
  }

  ngOnDestroy() {
    if (this.reportTypeSub) this.reportTypeSub.unsubscribe();
  }
}
