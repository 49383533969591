<div class="h-100" *ngIf="options?.showAutocomplete; else cards">
  <mat-form-field appearance="outline">
    <mat-label>
      Tipo de vehiculo
      <span class="asterisk" *ngIf="utils.isRequiredField(vehicleCtrl)">*</span>
      <span class="optional" *ngIf="!utils.isRequiredField(vehicleCtrl)">(opcional)</span>
    </mat-label>
    <input (keyup)="changeValue()" type="text" matInput [matAutocomplete]="auto" [formControl]="vehicleCtrl">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayVehicleType"
      (optionSelected)="onSelectValue($event)">
      <mat-option *ngFor="let type of vehicles" [value]="type">
        <span>{{type.name}}</span>
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="vehicleCtrl.errors?.required">
      {{ utils.giveMessageError('MANDATORY_FIELD') }}
    </mat-error>
  </mat-form-field>
</div>

<ng-template #cards>
  <div class="container">
    <div id="mainFreightCharacteristics" class="col-md-12 mb-md-0 mb-12 container">
      <div class="row">
        <!-- Title -->
        <div class="col-md-12 mb-md-0 mb-12">
          <label class="title">
            Selecciona un tipo de vehículo
          </label>
        </div>
        <!-- Boxs -->
        <div class="container">
          <div class="col-md-12 mb-md-0 mb-12 container-type-trips">
            <div class="row">
              <div class="row">

                <div class="col-md-2 mb-md-0 mb-2" *ngFor="let vehicle of vehicles; let i = index;">
                  <div class="type-vehicle" (click)="selectTypeVehicle(vehicle)"
                    [ngClass]="{'option-step-selected': vehicle.selected}">
                    <div class="vehicle">
                      <div class="container-icon">
                        <div class="vehicle-icon">
                          <img src="{{getNameIconVehicle(vehicle)}}" alt="">
                        </div>
                      </div>
                      <div class="vehicle-title">
                        {{vehicle.name}}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- Button -->
        <div class="col-md-12 mb-md-0 mb-12">
          <div class="row">
            <div class="button-container justify-content-center mt-3 w-100">
              <button mat-raised-button class="secondary-sharp mr-4" (click)="stepBack()">Atrás</button>
              <button mat-raised-button class="bg-color-primary" (click)="nextStep()">Siguiente</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>