import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Utils } from '../resources/utils';
import { Subscription } from 'rxjs';

@Directive({
  selector: 'input[currencyMask]',
})
export class CurrencyMaskDirective implements OnInit {
  private subscription: Subscription;
  constructor(
    public ngControl: NgControl,
    private elementRef: ElementRef,
    public utils: Utils,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    const initialValue = this.ngControl.value;
    if (initialValue) this.checkValueState(initialValue);
    if (this.ngControl && this.ngControl.control) {
      this.subscription = this.ngControl.control.valueChanges.subscribe(value => {
        if (value !== null) this.checkValueState(value);
      });
    }
  }

  @HostListener('input', ['$event']) onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.checkValueState(input.value);
  }

  checkValueState(value: string | number): void {
    if (typeof value === 'number') this.setValues('formatStorageValues', value.toString());
    else this.setValues('formatNumber', value);
  }

  setValues(method: 'formatStorageValues' | 'formatNumber', value: string): void {
    const viewValue = this[method](value);
    const normalizeNumber = this.normalizeNumber(viewValue);
    this.ngControl.control.setValue(normalizeNumber, { emitEvent: false });
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', viewValue);
  }

  formatNumber(value: string): string {
    let validValue = value.replace(/[^0-9,]/g, '');
    const commaIndex = validValue.indexOf(',');
    const commaCount = (validValue.match(/,/g) || []).length;
    if (commaCount > 1) validValue = validValue.slice(0, commaIndex + 1) + validValue.slice(commaIndex + 1).replace(/,/g, '');
    let cleanedValue = validValue.replace(/\./g, '');
    const [integerPart] = cleanedValue.split(',');
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + (commaIndex !== -1 ? validValue.slice(commaIndex) : '');
    return formattedIntegerPart;
  }

  formatStorageValues(value: string) {
    let [integerPart, decimalPart] = value.split('.');
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return decimalPart ? `${integerPart},${decimalPart}` : integerPart;
  }

  normalizeNumber(value: string): number {
    let cleanedValue = value.replace(/\./g, '');
    cleanedValue = cleanedValue.replace(/,/g, '.');
    return parseFloat(cleanedValue);
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
