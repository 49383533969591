<mat-form-field [appearance]="options?.appearance? options.appearance : 'standard'">
    <mat-label>{{options.title}}<span class="asterisk"
            *ngIf="utils.isRequiredField(formControlThirdParty)">*</span></mat-label>
    <input (keyup)="changeValue()" matInput [matAutocomplete]="autocompleteThirdParties"
        [formControl]="formControlThirdParty">
    <div matSuffix class="spinner-border text-info" role="status" *ngIf="loadingThirdParties">
        <span class="sr-only">Loading...</span>
    </div>
    <mat-autocomplete #autocompleteThirdParties="matAutocomplete" [displayWith]="displayThirdPartyName"
        (optionSelected)="onSelectThirdParty($event)">
        <mat-option *ngFor="let thirdParty of thirdPartiesFiltered" [value]="thirdParty">
            <span>{{thirdParty.name}}</span>
        </mat-option>
        <mat-option [value]="null" (click)="openDialog()">
            <span class="font-italic">Agregar {{options.title}}</span>
        </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="formControlThirdParty.errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
    </mat-error>
</mat-form-field>