import { Roles } from "../enums/roles.enum";

export const FIELDS_STATE_SCHEMA: FieldsSchema = {
  eps: 'eps',
  arl: 'arl',
  driverLicense: 'driverLicense',
};

export const FIELDS_SECURITY_STUDY_SCHEMA: FieldsSchema = {
  truora: 'truora',
};

export var FIELDS_STATE: Fileds = {
  [Roles.DRIVER]: [],
  [Roles.ADMIN]: [],
  [Roles.OWNER]: []
};

export var FIELDS_SECURITY_STUDY: Fileds = {
  [Roles.DRIVER]: [],
  [Roles.ADMIN]: [],
  [Roles.OWNER]: []
};


export interface Fileds {
  [Roles.DRIVER]?: string[];
  [Roles.ADMIN]?: string[];
  [Roles.OWNER]?: string[];
}

export interface FieldsSchema {
  [key: string]: string;
};
