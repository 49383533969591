import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Global } from 'src/app/core/resources/global';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { CargoResources } from '../../resources/cargo';
import { FormThirdPartyConsignmentService } from './form-third-party-consignment.service';
import PlaceResult = google.maps.places.PlaceResult;
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { StandardMapComponent } from 'src/app/shared/standard-map/standard-map.component';
import { ThirdParty } from 'src/app/core/interfaces/thirdParty';
declare var google: any;

@Component({
  selector: 'app-form-third-party-consignment',
  templateUrl: './form-third-party-consignment.component.html',
  styleUrls: ['./form-third-party-consignment.component.scss'],
  providers: [FormThirdPartyConsignmentService]
})
export class FormThirdPartyConsignmentComponent extends ReactiveForm implements OnInit {

  @ViewChild('inputAutocomplete', { static: false }) inputAutocomplete: any;
  @ViewChild(StandardMapComponent, { static: false }) standardMap: StandardMapComponent;
  activeLocation: object = {
    typeLocation: 'thirdParty'
  };
  cityControl = new FormControl('', Validators.required);
  cityOptions: OptionsAutocomplete = {
    title: 'Ciudad',
    appearance: 'outline',
    requireFullCity: true
  };
  validate = '';
  location: any;
  documentTypeNameControl: FormControl = new FormControl('', Validators.required);
  minDocument: number = 5;
  maxDocument: number = 15;
  keyMarker: string = 'newThirdParty'
  mapOptions: OptionsAutocomplete = {
    keyMarkerListener: this.keyMarker,
    clickMapIcon: this.global.pathMarkerOrigin,
    initialClickMap: true,
  };
  constructor(
    private manualCreationCargoService: ManualCreationCargoService,
    private thirdPartyConsignmentService: FormThirdPartyConsignmentService,
    public dialog: MatDialog,
    public global: Global,
    public formBuilder: FormBuilder,
    private cargoResources: CargoResources,
    public utils: Utils,
    private snackService: SnackBarService,
    public dialogRef: MatDialogRef<FormThirdPartyConsignmentComponent>,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public dataDialog: {
      title: 'Remitente' | 'Destinatario',
      options: {
        city?: { municipalityCode?: string }, //Solo para creación manual de la carga
        municipalitycode?: string,
        parent?: string,
        thirdParty?: ThirdParty
      }
    }
  ) {
    super(
      formBuilder,
      cargoResources.thirdParty,
      cargoResources.thirdParty,
      cargoResources.thirdParty,
    );
    if (this.dataDialog && this.dataDialog.options) {
      if (this.dataDialog.options.city && this.dataDialog.options.city.municipalityCode) {
        this.form.controls.municipalityCode.setValue(this.dataDialog.options.city.municipalityCode);
        this.cityOptions['initialMunicipalityCode'] = this.dataDialog.options.city.municipalityCode;
        this.cityOptions = { ...this.cityOptions };
        this.form.controls.parent.setValue(this.manualCreationCargoService.formCargoOwner.value.documentNumber);
      }
      if (this.dataDialog.options.municipalitycode) {
        this.form.controls.municipalityCode.setValue(this.dataDialog.options.city.municipalityCode);
        this.cityOptions['initialMunicipalityCode'] = this.dataDialog.options.city.municipalityCode;
        this.cityOptions = { ...this.cityOptions };
      }
      if (this.dataDialog.options.thirdParty) {
        this.form.get('information').patchValue(this.dataDialog.options.thirdParty);
        this.form.get('information').disable();
        this.documentTypeNameControl.setValue({
          id: this.form.get('information.documentTypeId').value,
          name: this.form.get('information.documentTypeName').value
        });
        this.documentTypeNameControl.disable();
      }

    }
  }

  ngOnInit() {
    this.setValidators();
  }

  setValidators() {
    this.form.get('information.documentTypeId').setValidators(Validators.required);
    this.form.get('information.document').setValidators([Validators.required, Validators.minLength(this.minDocument), Validators.maxLength(this.maxDocument)]);
    this.form.get('information.name').setValidators(Validators.required);
    this.form.get('address.address').setValidators(Validators.required);
    this.form.get('parent').setValidators(null);
    this.form.get('municipalityCode').setValidators(null);
    this.form.updateValueAndValidity();
  }

  onSubmit() {
    this.validate = 'touched';
    if (this.utils.errorMessagesCustomized(this.cityControl, 'ciudad')) return;
    if (this.form.invalid) {
      if (this.utils.errorMessagesCustomized(this.form.get('information.documentTypeId'), 'tipo de documento')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('information.document'), 'número de documento', this.minDocument, this.maxDocument)) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('information.name'), 'nombre')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('address.address'), 'dirección')) return;
      else this.snackService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
      return;
    }
    let body = this.form.getRawValue();
    body['municipalityCode'] = this.cityControl.value.id;
    this.spinner.show();
    this.thirdPartyConsignmentService.createThirdParty(body).subscribe(
      (success: any) => {
        this.spinner.hide();
        if (success && success.errorRNDC && success.errorRNDC.error) {
          this.snackService.openSnackBar(success.errorRNDC.error, undefined, 'error');
        } else {
          this.snackService.openSnackBar(this.dataDialog.title + ' creado exitosamente');
          this.dialogRef.close({ state: true });
        }
      },
      (error) => {
        this.spinner.hide();
        this.snackService.openSnackBar('Ocurrió un problema creando el ' + this.dataDialog.title, undefined, 'error');
      }
    );
  }

  async onClickMap($event) {
    let address = await this.transformCoordinatesToAddress($event.latLng.lat(), $event.latLng.lng());
    let location = { lat: $event.latLng.lat(), lng: $event.latLng.lng() }
    if (address && location) this.form.controls.address.patchValue({ address, location });
  }

  async transformCoordinatesToAddress(lat: number, lng: number): Promise<string> {
    let geocoderService = new google.maps.Geocoder;
    let latlng = { lat: lat, lng: lng };
    let address = '';
    this.spinner.show();
    await geocoderService.geocode({ 'location': latlng }, (results, status) => {
      if (results.length) {
        this.spinner.hide();
        address = results[0].formatted_address;
      } else this.spinner.hide();
    });
    return address;
  }

  onSelectAddress($event) {
    if ($event && $event.data && $event.data.location && $event.data.location.lat && $event.data.location.lng) {
      this.standardMap.removeMarker(this.keyMarker);
      this.standardMap.createMarker(this.keyMarker, $event.data.location.lat, $event.data.location.lng, null, this.global.pathMarkerOrigin);
      this.standardMap.setCenterMap($event.data.location.lat, $event.data.location.lng, 16);
      this.form.controls.address.patchValue($event.data);
    }

  }

  onChangeDocumentType($event: MatSelectChange) {
    if ($event && $event.value) {
      this.form.controls.information.patchValue({
        documentTypeId: $event.value.id,
        documentTypeName: this.utils.toCapitalize($event.value.name)
      });
      this.maxDocument = $event.value.name === "NIT" ? 9 : 15;
      this.setValidators();
    }
  }

  displayDocumentTypeName(documentType): string | undefined {
    return documentType && documentType.name ? documentType.name : undefined;
  }

  get modalTitle(): string {
    if (!this.dataDialog || !this.dataDialog.title) return "Nueva dirección de tercero";
    if (this.dataDialog.title.toLowerCase().includes('dirección')) return `Nueva ${this.dataDialog.title.toLowerCase()}`;
    return `Nuevo ${this.dataDialog.title}`;
  }

}
