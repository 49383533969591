<div mat-dialog-content class="position-relative ">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <form [formGroup]="calculatorForm" id="mainFreightCalculatorMeasuremetns"
    class="d-flex flex-wrap align-items-baseline align-items-md-start py-4 px-2 px-md-5" (ngSubmit)="calculate()">
    <!-- Title -->
    <label class="col-md-12 mb-4 title">
      Calcula el cubicaje
    </label>
    <p class="col-md-12 mb-3">
      Para calcular el cubicaje ingresa los siguientes datos por cada caja a transportar:
    </p>

    <div class="col-sm-6 col-md-3 mb-4">
      <label class="text-strong mr-3 mb-0">Unidad</label>
      <mat-button-toggle-group formControlName="unit">
        <mat-button-toggle [value]="'m'">
          m
        </mat-button-toggle>
        <mat-button-toggle [value]="'cm'">
          cm
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <mat-form-field appearance="outline" class="col-sm-6 col-md-3 mb-4">
      <mat-label>Alto<span class="asterisk">*</span></mat-label>
      <input matInput type="text" onlyNumbersDouble placeholder="Alto" formControlName="tall">
      <mat-error *ngIf="calculatorForm.get('tall').errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
      <mat-error *ngIf="calculatorForm.get('tall').errors?.min">
        {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'altura', 0.01) }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-sm-6 col-md-3 mb-4">
      <mat-label>Ancho<span class="asterisk">*</span></mat-label>
      <input matInput type="text" onlyNumbersDouble placeholder="Ancho" formControlName="width">
      <mat-error *ngIf="calculatorForm.get('width').errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
      <mat-error *ngIf="calculatorForm.get('width').errors?.min">
        {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'anchura', 0.01) }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-sm-6 col-md-3 mb-4">
      <mat-label>Profundidad<span class="asterisk">*</span></mat-label>
      <input matInput type="text" onlyNumbersDouble placeholder="Profundidad" formControlName="depth">
      <mat-error *ngIf="calculatorForm.get('depth').errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
      <mat-error *ngIf="calculatorForm.get('depth').errors?.min">
        {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'profundidad', 0.01) }}
      </mat-error>
    </mat-form-field>

    <p class="col-12 text-bold mb-3">Total de cajas</p>
    <mat-form-field appearance="outline" class="col-12 mb-4">
      <mat-label>Cantidad de cajas a transportar<span class="asterisk">*</span></mat-label>
      <input matInput type="text" formControlName="quantityUnits" onlyNumbersInt>
      <mat-error *ngIf="calculatorForm.get('quantityUnits').errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
      <mat-error *ngIf="calculatorForm.get('quantityUnits').errors?.min">
        {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'cantidad de cajas', 1) }}
      </mat-error>
    </mat-form-field>

    <div class="button-container my-2 col-12">
      <button mat-raised-button color="primary" class="bg-color-primary" type="submit">Calcular</button>
    </div>

  </form>
</div>