<div class="create-edit-maintenance-form pb-3 pb-lg-0 pt-4 px-3 px-lg-5">
  <p class="create-edit-maintenance-form__title m-0 mb-4 px-2 text-break">Registro de mantenimiento</p>
  <form *ngIf="maintenanceForm" [formGroup]="maintenanceForm" (submit)="onSubmit()" class="create-edit-maintenance-form__body row m-0 p-0">
    <!-- Type maintenance -->
    <mat-form-field [ngClass]="{'col-xl-6': maintenance, 'col-lg-6': maintenance}" class="px-2 col-12 col-md-6 col-lg-4 create-edit-maintenance-form__type-maintenance" appearance="outline">
      <mat-label>Tipo de mantenimiento <span class="asterisk">*</span></mat-label>
      <mat-select formControlName="maintenanceType">
        <mat-option *ngFor="let maintenanceType of maintenanceTypeList" [value]="maintenanceType">
          {{maintenanceType.name}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="maintenanceForm?.get('maintenanceType')?.errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
    </mat-form-field>

    <!-- Execution date -->
    <mat-form-field appearance="outline" (click)="datepickerExecution.open()"
      [ngClass]="{'col-xl-6': maintenance, 'col-lg-6': maintenance}" class="px-2 col-12 col-md-6 col-lg-4 create-edit-maintenance-form__execution-date cursor-pointer">
      <mat-label>Fecha de ejecución <span class="asterisk">*</span></mat-label>
      <input matInput [matDatepickerFilter]="executionDateFilter" [matDatepicker]="datepickerExecution"
        formControlName="executionDate" readonly class="cursor-pointer">
      <mat-datepicker-toggle matSuffix [for]="datepickerExecution">
      </mat-datepicker-toggle>
      <mat-datepicker #datepickerExecution></mat-datepicker>
      <mat-error *ngIf="maintenanceForm?.get('executionDate')?.errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
    </mat-form-field>

    <!-- Expiration date -->
    <mat-form-field appearance="outline" (click)="datepickerExpiration.open()"
      [ngClass]="{'col-xl-6': maintenance, 'col-lg-6': maintenance}" class="px-2 col-12 col-md-6 col-lg-4 create-edit-maintenance-form__expiration-date cursor-pointer">
      <mat-label>Fecha de vencimiento (Opcional)</mat-label>
      <input matInput [matDatepickerFilter]="expirationDateFilter" [matDatepicker]="datepickerExpiration"
        formControlName="expirationDate" readonly class="cursor-pointer">
      <i *ngIf="maintenanceForm?.get('expirationDate')?.value" matSuffix class="fas fa-times-circle"
        matTooltip="Borrar campo" (click)="cleanDate()"></i>
      <mat-datepicker-toggle matPrefix [for]="datepickerExpiration">
      </mat-datepicker-toggle>
      <mat-datepicker #datepickerExpiration></mat-datepicker>
    </mat-form-field>

    <!-- Currency period -->
    <p [ngClass]="{'col-xl-6': maintenance, 'col-lg-6': maintenance}" class="px-2 col-12 col-md-6 col-lg-4 create-edit-maintenance-form__currency-period d-flex align-items-center">
      Periodo de vigencia:
      {{ maintenanceForm?.get('expirationDate')?.value ?
      getCalculateMaintenancePeriod(maintenanceForm?.get('expirationDate')?.value) <= 0 ? '0 día(s)' : getCalculateMaintenancePeriod(maintenanceForm?.get('expirationDate')?.value) + ' día(s)' : '-' }}
    </p>

    <!-- alert -->
    <div [ngClass]="{'col-xl-6': maintenance, 'col-lg-6': maintenance}" class="px-2 col-12 col-md-6 col-lg-4 create-edit-maintenance-form__alert d-flex">
      <p>
        Alertar al terminar vigencia
      </p>
      <mat-button-toggle-group formControlName="alert" #group="matButtonToggleGroup">
        <mat-button-toggle [value]="true">Si</mat-button-toggle>
        <mat-button-toggle [value]="false">No</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <!-- Km at the time -->
    <div
      [ngClass]="{'col-xl-6': maintenance, 'col-lg-6': maintenance}" class="px-2 col-12 col-md-6 col-lg-4 d-flex align-items-center justify-content-center create-edit-maintenance-form__mileage">
      <p class="mr-2">
        Km al momento de realizar <span>(Opcional)</span>
      </p>
      <mat-form-field>
        <mat-label>Kilómetros</mat-label>
        <input matInput currencyMask onlyNumbersInt min="0" placeholder="00000" formControlName="mileage">
      </mat-form-field>
      <p>km</p>
    </div>

    <!-- Evidence -->
    <div [ngClass]="{'col-xl-6': maintenance, 'col-lg-6': maintenance}" class="px-2 col-12 col-md-6 col-lg-4 create-edit-maintenance-form__evidence">
      <p>Foto de evidencia <span class="asterisk">*</span></p>
      <app-upload-resources #uploadResourcesComponent (storageResources)="processPaths($event)" [path]="maintenancePath" [showAlertResources]="true" [media]="maintenance?.evidence" [showSuccess]="false" [unableDeleteAllEvidences]="true" class="w-100"></app-upload-resources>
    </div>
    <!-- Type services tags -->
    <div class="px-2 col-12 create-edit-maintenance-form__type-services-cards">
      <div *ngFor="let maintenanceRecordTag of maintenanceForm?.get('maintenanceRecords')?.value; let i = index">
        <div class="create-edit-maintenance-form__type-services-cards--card">
          <span>{{maintenanceRecordTag.name}}</span><i class="fas fa-times" (click)="removeTag(i)"></i>
        </div>
      </div>
    </div>
    <!-- Type services select -->
    <mat-form-field appearance="outline" [ngClass]="{'col-xl-6': maintenance, 'col-lg-6': maintenance}" class="px-2 col-12 col-md-6 col-lg-4 create-edit-maintenance-form__type-services">
      <mat-label>Tipos de servicio <span class="asterisk">*</span></mat-label>
      <mat-select formControlName="maintenanceRecords" multiple (selectionChange)="onMaintenanceRecordsChange($event)">
        <mat-option *ngFor="let maintenanceRecord of maintenanceRecordsList" [value]="maintenanceRecord">
          {{ maintenanceRecord.name }}
        </mat-option>
      </mat-select>
      <mat-hint>
        Selecciona uno o varios tipos de servicio (máximo 10)
      </mat-hint>
      <mat-error *ngIf="maintenanceForm?.get('maintenanceRecords')?.errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
    </mat-form-field>

    <!-- Observations -->
    <mat-form-field class="px-2 col-12 create-edit-maintenance-form__observations mt-4" appearance="outline">
      <mat-label>Observaciones (Opcional)</mat-label>
      <textarea matInput formControlName="observations"></textarea>
      <mat-hint align="end">{{ maintenanceForm.get('observations').value?.length || 0 }}/{{maxLengthObservations}}</mat-hint>
      <mat-error *ngIf="maintenanceForm.get('observations').errors?.maxlength">
        {{ utils.giveMessageError('MAX_LENGTH_EXCEED', 'observaciones', maxLengthObservations) }}
      </mat-error>
    </mat-form-field>

    <!-- Submit button -->
    <div class="button-container my-3 create-edit-maintenance-form__actions col-12">
      <button type="button" mat-raised-button color="primary" class="primary-secondary bg-color-primary" (click)="cancelAction(maintenance ? 'update': 'create')">Cancelar</button>
      <button mat-raised-button color="primary" class="bg-color-primary">{{ maintenance ? 'Guardar' : 'Enviar'}}</button>
    </div>
  </form>
</div>
