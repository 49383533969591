import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { VehiclesService } from 'src/app/modules/administration/vehicles/list-vehicles.service';

@Injectable({ providedIn: 'root' })
export class SecurityRulesResolver implements Resolve<any> {

    constructor(
        private vehiclesService: VehiclesService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            this.vehiclesService.getValidationRules().subscribe(() => resolve(true), () => reject());
        });
    }

}
