export class Companies {

  static companies = [
    {
      "businessName": "TECLOGI",
      "commonName": "TECLOGI",
      "nit": "9013312320"
    },
    {
      "businessName": "BULKMATIC DE COLOMBIA S.A.S",
      "commonName": "BULKMATIC",
      "nit": "9009704395"
    },
    {
      "businessName": "INVERTRAICO S.A.S",
      "commonName": "INVERTRAICO",
      "nit": "9011981505"
    },
    {
      "businessName": "JL & RB S.A.S",
      "commonName": "JL & RB",
      "nit": "9007387182"
    },
    {
      "businessName": "GRUPO NUTRESA",
      "commonName": "GRUPO NUTRESA",
      "nit": "9003410860"
    },
    {
      "businessName": "CARGA PÁRAMO S.A.S",
      "commonName": "CARGA PÁRAMO",
      "nit": "9014171480"
    },
    {
      "businessName": "TRANSPORTE LA ESTRELLA SAS",
      "commonName": "TRANSPORTE LA ESTRELLA",
      "nit": "8000130291"
    },
    {
      "businessName": "SEGURIDAD EXTREMA LTDA",
      "commonName": "SEGURIDAD EXTREMA",
      "nit": "9013522401"
    },
    {
      "businessName": "TRANSALUD",
      "commonName": "TRANSALUD",
      "nit": "9012861321"
    },
    {
      "businessName": "LOGISTICA DE TRANSPORTES CARRILLO PAEZ SAS",
      "commonName": "TRANSPORTES CARRILLO",
      "nit": "9011282346"
    },
    {
      "businessName": "OPPERAR COLOMBIA S.A.S.",
      "commonName": "OPPERAR COLOMBIA",
      "nit": "9007435921"
    }
  ];

  static companiesWithDisabledRegistration = [
    "8000130291"
  ];

  static companiesNIT = {
    LOGGIAPP: "default",
    TECLOGI: "9013312320",
    BULKMATIC: "9009704395",
    INVERTRAICO: "9011981505",
    JL_RB: "9007387182",
    NUTRESA: "9003410860",
    PARAMO: "9014171480",
    LA_ESTRELLA: "8000130291",
    SEGURIDAD_EXTREMA: "9013522401",
    TRANSALUD: "9012861321",
    TRANSPORTES_CARRILLO: "9011282346",
    OPPERAR_COLOMBIA: "9007435921"
  };


  static companiesAccess(route: string, clientNit: string, saasNit: string): boolean {
    switch (route) {
      case '/analytics/dispatch':
      case '/analytics/vehicleAnalytics':
        //Solo usuarios Teclogi, no sus clientes
        return [this.companiesNIT.TECLOGI].includes(clientNit);
      case '/administration/companies/config':
        //Solo usuarios de las empresas SaaS, no sus clientes
        return this.companies.some(company => company.nit === clientNit);
      case '/analytics/frequentRoutes':
      case '/analytics/stopedCargos':
      case '/analytics/historicalKPI':
        //Usuarios y clientes de Teclogi y Bulkmatic
        return [this.companiesNIT.BULKMATIC, this.companiesNIT.TECLOGI].includes(saasNit);
      case '/analytics/analyticsBilling':
      case '/analytics/operationITR':
        //Usuarios Teclogi y Bulkmatic, no sus clientes
        return [this.companiesNIT.BULKMATIC, this.companiesNIT.TECLOGI].includes(clientNit);
      case '/administration/adminTrailers':
      case '/administration/modifyAdditionalCostBilled':
      case '/administration/modifyAdditionalCostpayed':
      case '/administration/addAdditionalServiceCargoPaid':
      case '/administration/addAdditionalServiceCargoCashed':
      case '/administration/addAdditionalServiceCargoApproved':
      case '/cargo/documentManifest':
      case '/cargo/documentManifestUrban':
      case '/cargo/documentConsignment':
      case '/cargo/documentConsignmentUrban':
      case '/cargo/readManifest':
      case '/cargo/additionalCosts':
      case '/cargo/additionalCostCreate':
      case '/cargo/additionalCostUpdate':
      case '/cargo/additionalCostDelete':
      case '/cargo/confirmationAdditionalMandatoryServices':
      case '/cargo/additionalServicesWithoutUtilityRestriction':
      case '/cargo/uploadExternalManifest':
      case '/cargo/createCargoFromRequest':
      case '/cargo/createCargoFromRoute':
      case '/cargo/createMassiveCargo':
      case '/cargo/createCargoByGuides':
      case '/cargo/createManualCargo':
      case '/cargo/createCargoRequest':
      case '/cargo/changeManifestRequirement':
      case '/cargo/cargoRequest':
      case '/cargo/cargoRequestNegotiation':
      case '/cargo/createRequestFromManualCreationCargo':
      case '/cargo/serviceRequests':
      case '/cargo/createTransportRequests':
      case '/cargo/listRequestsAndApplications':
        //Empresas SaaS que no tengan servicios de escolta
        return ![this.companiesNIT.SEGURIDAD_EXTREMA].includes(saasNit);
      case '/cargo/createEscortService':
      case '/administration/useGpsPortable':
      case '/administration/alertValidGps':
        //Empresas SaaS que tengan servicios de escolta
        return [this.companiesNIT.SEGURIDAD_EXTREMA].includes(saasNit);
      default:
        return true;
    }
  }

  static permissionAccess(permission: string, clientNit: string, saasNit: string): boolean {
    switch (permission) {
      case 'dispatch':
      case 'vehicleAnalytics':
        //Solo usuarios Teclogi, no sus clientes
        return [this.companiesNIT.TECLOGI].includes(clientNit);
      case 'frequentRoutes':
      case 'stopedCargos':
      case 'historicalKPI':
        return [this.companiesNIT.BULKMATIC, this.companiesNIT.TECLOGI].includes(saasNit);
      case 'analyticsBilling':
      case 'operationITR':
        //Usuarios Teclogi y Bulkmatic, no sus clientes
        return [this.companiesNIT.BULKMATIC, this.companiesNIT.TECLOGI].includes(clientNit);
      case 'adminTrailers':
      case 'modifyAdditionalCostBilled':
      case 'modifyAdditionalCostpayed':
      case 'addAdditionalServiceCargoPaid':
      case 'addAdditionalServiceCargoCashed':
      case 'addAdditionalServiceCargoApproved':
      case 'documentManifest':
      case 'documentManifestUrban':
      case 'documentConsignment':
      case 'documentConsignmentUrban':
      case 'readManifest':
      case 'additionalCosts':
      case 'additionalCostCreate':
      case 'additionalCostUpdate':
      case 'additionalCostDelete':
      case 'confirmationAdditionalMandatoryServices':
      case 'additionalServicesWithoutUtilityRestriction':
      case 'uploadExternalManifest':
      case 'createCargoFromRequest':
      case 'createCargoFromRoute':
      case 'createMassiveCargo':
      case 'createCargoByGuides':
      case 'createManualCargo':
      case 'createCargoRequest':
      case 'changeManifestRequirement':
      case 'cargoRequest':
      case 'cargoRequestNegotiation':
      case 'createRequestFromManualCreationCargo':
      case 'serviceRequests':
      case 'createTransportRequests':
      case 'listRequestsAndApplications':
        //Empresas SaaS que no tengan servicios de escolta
        return ![this.companiesNIT.SEGURIDAD_EXTREMA].includes(saasNit);
      case 'createEscortService':
      case 'useGpsPortable':
      case 'alertValidGps':
        //Empresas SaaS que tengan servicios de escolta
        return [this.companiesNIT.SEGURIDAD_EXTREMA].includes(saasNit);
      default:
        return true;
    }
  }

  static modulesAccess(module: string, clientNit: string, saasNit: string): boolean {
    switch (module) {
      case 'guide':
      case 'point':
        return false;
      case 'routing':
      case 'routes':
      case 'analytics':
        //Empresas SaaS que no tengan servicios de escolta
        return ![this.companiesNIT.SEGURIDAD_EXTREMA].includes(saasNit);
      default:
        return true;
    }
  }

}
