import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ParamsCargoList } from 'src/app/core/interfaces/paramsCargoList';
import { Filter } from 'src/app/core/models/filter';
import { PaginationList } from 'src/app/core/models/pagination-list';
import { CargoService } from 'src/app/core/services/cargo.service';
import { TransportRequestsService } from 'src/app/src/app/core/services/transport-requests.service';
import { FreightListService } from '../cargo-list/cargo-list.service';
import { Utils } from 'src/app/core/resources/utils';
import moment from 'moment';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { FreightListService as listTurn } from '../cargo-list-turn/cargo-list-turn.service';
import { CargoStateEnum } from 'src/app/core/enums/cargoState.enum';
import { Cargo } from 'src/app/core/interfaces/cargo';


interface ConsignmentsStatus {
  title: string;
  selected: boolean;
  params: { states: string[] };
}

@Component({
  selector: 'app-list-transport-requests',
  templateUrl: './list-transport-requests.component.html',
  styleUrls: ['./list-transport-requests.component.scss'],
  providers: [CargoService, TransportRequestsService]
})
export class ListTransportRequestsComponent implements OnInit {

  @Input() paramsCargoList: ParamsCargoList = {};
  @Input() typeList: string;
  public optionsFilter: Filter;
  public transportRequets = [];
  public paginationList: PaginationList = new PaginationList();
  private customeFilters: string;
  public consignmentsStatus: ConsignmentsStatus[] = [
    {
      title: 'Todo',
      selected: true,
      params: {
        states: ['Accepted', 'Request', 'Created']
      }
    },
    {
      title: 'Con Remesa',
      selected: false,
      params: {
        states: ['Accepted', 'Created']
      }
    },
    {
      title: 'Pendiente de Remesa',
      selected: false,
      params: {
        states: ['Request']
      }
    },
  ];

  turn = [];
  filteredCargosTurns = {};

  constructor(
    private cargoService: CargoService,
    private spinner: NgxSpinnerService,
    private freightListService: FreightListService,
    private utils: Utils,
    private snackBarService: SnackBarService,
    private listTurn: listTurn,
  ) {
    this.getTurns();
    this.paginationList.setPageSize(10);
  }

  ngOnInit() {
    this.optionsFilter = this.freightListService.getOptionsFilter(this.typeList);
  }

  private createParams(): any {
    const params = this.utils.clone(this.paramsCargoList);
    const consignmentStatusSelected = this.consignmentsStatus.filter(status => status.selected)[0];
    params.states = this.paramsCargoList.states.filter(state => consignmentStatusSelected.params.states.includes(state));
    return params;
  }

  private getList(): void {
    this.createParams();
    const params: ParamsCargoList = {
      pageKey: this.paginationList.getPageKey(),
      pageSize: this.paginationList.getPageSize(),
      ...this.createParams(),
    };
    this.spinner.show();
    this.cargoService.cargoList(params, this.customeFilters).subscribe(
      (response) => {
        if (!this.utils.isDefined(response) || response.length === 0) {
          this.paginationList.setEnablePagingScroll(false);
          response = [];
        }
        let data: Cargo[] = this.paginationList.getList().concat(response);
        this.paginationList.setList(data);
        this.spinner.hide();
      }, (error) => {
        this.spinner.hide();
        console.error(error)
      }
    );
  }

  public onScrollDown(): void {
    if (this.paginationList.getEnablePagingScroll()) {
      this.paginationList.setPageKey(this.paginationList.getPageKey() + 1);
      this.getList();
    }
  }

  public refreshList($event?) {
    this.resetPagging();
    if ($event) this.customeFilters = $event;
    this.getList();
  }

  public changeConsignmentStatus(status: ConsignmentsStatus): void {
    status.selected = true;
    this.consignmentsStatus = this.consignmentsStatus.map((item) => {
      item.title === status.title ? item.selected = true : item.selected = false;
      return item;
    });
    this.refreshList();
  }

  private resetPagging(): void {
    this.customeFilters = null;
    this.paginationList.setPageKey(1);
    this.paginationList.setList([]);
    this.paginationList.setEnablePagingScroll(true);
  }

  getTurns() {
    let filterData = '';
    let currentDateFormatted = moment().subtract(2, 'days').format('YYYY-MM-DD ZZ');
    filterData += '&date=' + currentDateFormatted;
    // filterData += '&state=Available'
    const turnObserver = {
      next: (data) => {
        this.spinner.hide();
        if (data && data.length) this.turn = data.filter(turn => turn.state && turn.state !== 'Deleted');
      },
      error: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar("No se ha podido enviar la información", undefined, 'error');
      },
      complete: () => {
        this.spinner.hide();
      }
    };
    this.spinner.show();
    this.listTurn.getListTurn(filterData).subscribe(turnObserver);
  }

  getFilteredCargo(consecutive: string, vehicleType: string, origin: string, dateLoad: string) {
    if (this.paginationList.getList().every(cargo => cargo.state === CargoStateEnum.CREATED)) {
      let matches = [];
      let dateLoadFormatted = moment(dateLoad).utc().format('YYYY-MM-DD ZZ');
      if (this.filteredCargosTurns && this.filteredCargosTurns[origin] && this.filteredCargosTurns[origin][vehicleType]) matches = this.filteredCargosTurns[origin][vehicleType].filter(turn => this.validDates(dateLoadFormatted, turn.date));
      return { cargoConsecutive: consecutive, matches: matches };
    }
    return { cargoConsecutive: consecutive, matches: [] };
  }

  validDates(originDateLoad, turnDate) {
    switch (originDateLoad) {
      case moment(turnDate, 'YYYY-MM-DD ZZ').utc().format('YYYY-MM-DD ZZ'):
      case moment(turnDate, 'YYYY-MM-DD ZZ').add(1, 'days').utc().format('YYYY-MM-DD ZZ'):
      case moment(turnDate, 'YYYY-MM-DD ZZ').add(2, 'days').utc().format('YYYY-MM-DD ZZ'):
        return true;
      default:
        return false;
    }
  }

}
