import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CompanyManager } from 'src/app/core/managers/company.manager';
// import { Cargo } from 'src/app/core/models/cargo.model';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { CargoItemService } from '../cargo-item/cargo-item.service';
import { ManualCreationCargoService } from '../manual-creation-cargo/manual-creation-cargo.service';
import { CargoResources } from '../manual-creation-cargo/resources/cargo';
import { MatDialog, MatDialogConfig, MatSelectChange } from '@angular/material';
import { ItemAdditionalService } from './item-additional-service.service';
import { Dialog } from 'src/app/core/resources/dialog';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { AdditionalCostUpdate } from 'src/app/core/interfaces/additionalCostCargo';
import { BasicResponse } from 'src/app/core/interfaces/basicResponse';
import { NgxSpinnerService } from 'ngx-spinner';
import { RateCostAdditionalServicesComponent } from '../rate-cost-additional-services/rate-cost-additional-services.component';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { CargoDetailService } from '../cargo-detail/cargo-detail.service';
import { ShippingCost } from 'src/app/core/enums/shipping-cost.enum';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { Utils } from 'src/app/core/resources/utils';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/core/services/authentication.service';
import { AdditionalCost } from 'src/app/core/interfaces/additionalCost';
import { CargoShippingCostDialogComponent } from '../cargo-shipping-cost-dialog/cargo-shipping-cost-dialog.component';
import { Company } from 'src/app/core/interfaces/company';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
@Component({
  selector: 'app-item-additional-service',
  templateUrl: './item-additional-service.component.html',
  styleUrls: ['./item-additional-service.component.scss']
})
export class ItemAdditionalServiceComponent implements OnInit {
  @Input() cargo: Cargo;
  @Input() cargoId: string;
  @Input() additionalServiceGroupCtrl: FormGroup;
  @Input() index: number;
  @Input() companyId: string;
  @Input() approvalState: string;
  @Input() cargoApproval: string;
  @Input() public listAdditionalService: AdditionalCost[] = [];
  @Output() removeAdditionalCostItem: EventEmitter<number> = new EventEmitter();
  @Output() editAdditionalCostItem: EventEmitter<number> = new EventEmitter();
  public validUtility: boolean;
  utilityAditionalServicesCargoResul: number;
  activeSave: boolean = false;
  observationFieldState: any;
  activeEdit: boolean = false;
  additionalServices: Array<string> = ['Servicios de escolta', 'Servicio descargue', 'Sello satelital', 'Servicio cargue', 'Servicio de almacenamiento', 'Stand by', 'Servicio ITR']
  permission = Permission;
  isCollapsedhistoryAdditionalServices: boolean = false;
  isCollapsedFingerPrint: boolean = false;
  showArrowDown: boolean = true;
  checkUtility: boolean = true;
  utilityRequired: number = 0;
  companyUser: Company;
  companyName: string;

  constructor(
    public manualCreationCargoService: ManualCreationCargoService,
    public cargoResources: CargoResources,
    public cargoItemService: CargoItemService,
    public managerComany: CompanyManager,
    public dialog: MatDialog,
    public itemAdditionalService: ItemAdditionalService,
    public matDialog: Dialog,
    public snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private permissionRole: PermissionRole,
    public cargoDetailService: CargoDetailService,
    public utils: Utils,
    private authService: AuthService
  ) {
  }

  async ngOnInit() {
    await this.getUtilityByCompany();
    this.additionalServiceGroupCtrl.setValidators(this.utilityValidator.bind(this));
    this.additionalServiceGroupCtrl.updateValueAndValidity();
    this.companyUser = this.authService.getCompany()
    this.companyName = this.companyUser.name;
    // Eliminar de las opciones al conductor cuando el servicio está aprobado
    if (!this.additionalServiceGroupCtrl.disabled) {
      if (this.cargoApproval === 'Approved' || this.cargoApproval === 'Rejected')
        this.cargoResources.listUploadDownloadFor = this.cargoResources.listUploadDownloadFor.filter(i => i.code != 'C');
    }
  }

  private utilityValidator(form): { [key: string]: any } {
    if (!this.checkUtility)
      return null;

    let utility: number = this.cargoItemService.getUtilityCargo(
      parseInt(form.get('financialInformation.rate').value.toString().replace(/[.]/g, '')),
      parseInt(form.get('financialInformation.cost').value.toString().replace(/[.]/g, ''))
    );
    if (utility < this.utilityRequired) return { 'utility': true };
    else return null;
  }

  onKeyUp($event, key: string) {
    this.additionalServiceGroupCtrl.get(key).setValue(
      parseInt($event.target.value)
    );
  }

  async onChangeUploadDownloadFor($event) {
    const uploadDownloadFor = this.cargoResources.listUploadDownloadFor.filter((obj) => {
      return obj.code === $event.value;
    });
    if (uploadDownloadFor.length) {
      this.additionalServiceGroupCtrl.get('toPay').patchValue({
        code: uploadDownloadFor[0].code,
        description: uploadDownloadFor[0].description
      });
    }
    await this.getUtilityByCompany();
    this.additionalServiceGroupCtrl.setValidators(this.utilityValidator.bind(this));
    this.additionalServiceGroupCtrl.updateValueAndValidity();
  }

  async onSelectBodyGuardType($event) {
    this.additionalServiceGroupCtrl.get('observation').setValue(
      $event.value
    );
  }

  editAdditionalService() {
    if (this.additionalServiceGroupCtrl.get('financialInformation.paid').value !== null) {
      if (this.cargo.shippingCost.cashed === true) {
        if (this.permissionRole.hasPermission(this.permission.payments.module, this.permission.payments.modifyAdditionalCostBilled)) {
          if (!this.additionalServiceGroupCtrl.get('financialInformation.paid').value && this.approvalState === 'Pending approval') {
            this.activeSave ? this.activeSave = false : this.activeSave = true;
            this.validateTypeAdditionalService();
          } else {
            this.manualCreationCargoService.showMessageNotAllowedEdit();
          }
        } else {
          this.snackBarService.openSnackBar('No es posible editar el servicio adicional. El servicio ya está facturado. Comunicarse con el equipo de facturación', undefined, 'alert');
        }
      } else {
        if (!this.additionalServiceGroupCtrl.get('financialInformation.paid').value && this.approvalState === 'Pending approval') {
          this.activeSave ? this.activeSave = false : this.activeSave = true;
          this.validateTypeAdditionalService();
        } else {
          if (this.approvalState && this.approvalState === 'Approved') this.manualCreationCargoService.showMessageNotAllowedEditAdditionalService();
          else this.manualCreationCargoService.showMessageNotAllowedEdit();
        }
      }
    }
  }

  deleteAdditionalService() {
    if (this.cargo.shippingCost.cashed === true) {
      this.snackBarService.openSnackBar('No se puede eliminar el servicio adicional. El servicio ya está facturado.', undefined, 'alert')
      return;
    }
    if (this.approvalState && this.approvalState === 'Approved') {
      this.manualCreationCargoService.showMessageNotAllowedDeleteAdditionalService();
      return;
    }
    //si no esta pago entonces se puede eliminar sino saldra un mensaje de error
    if (!this.additionalServiceGroupCtrl.get('financialInformation.paid').value) {
      this.matDialog.openDialog({
        title: '¿Está seguro que desea eliminar el servicio adicional?',
        showYesBtn: true,
        showNoBtn: true,
        hideBtnCancel: true
      }).then(() => {

        if (this.additionalServiceGroupCtrl.value.id === undefined || this.additionalServiceGroupCtrl.value.id === null || this.additionalServiceGroupCtrl.value.id === "") {
          this.snackBarService.openSnackBar("No es posible borrar el servicio adicional por favor contactar al equipo de desarrollo", undefined, "alert");
        } else {
          this.itemAdditionalService.deleteAdditionalService(this.cargoId, this.additionalServiceGroupCtrl.value.id).subscribe(
            success => {
              this.removeAdditionalCostItem.emit(this.index);
              this.manualCreationCargoService.showMessageSuccessDelete();
            },
            error => {
              this.manualCreationCargoService.showMessageErrorDelete();
            }
          )
        }

      }).catch(err => err)

    } else {
      this.manualCreationCargoService.showMessageNotAllowedDelete();
    }

  }

  cancelEdit() {
    this.validateTypeAdditionalService();
    this.activeSave = false;
  }

  saveEdit() {
    if (this.additionalServiceGroupCtrl.valid) {
      this.spinner.show();
      this.activeSave = false;
      this.validateTypeAdditionalService();
      let body = this.validateBodyToUpdate();
      this.itemAdditionalService.editAdditionalService(body, this.cargoId)
        .subscribe((success: BasicResponse) => {
          this.spinner.hide();
          if (success.message === 'Actualizado') {
            this.manualCreationCargoService.showMessageSuccessEdit();
          } else if (success.message) {
            this.spinner.hide();
            this.snackBarService.openSnackBar(success.message, undefined, 'error');
          } else {
            this.spinner.hide();
            this.snackBarService.openSnackBar('Ocurrió un error al realizar la actualización, contacte al equipo de desarrollo', undefined, 'error');
          }
        },
          error => {
            this.snackBarService.openSnackBar('Ocurrió un error al enviar la información', undefined, 'error');
            this.spinner.hide();
          })

    } else {
      this.snackBarService.openSnackBar(FormMessages.MINIMUN_UTILITY_NOT_REACHED_DEFAULT, undefined, 'alert');
    }

  }

  validateBodyToUpdate() {
    let body: AdditionalCostUpdate = {
      id: this.additionalServiceGroupCtrl.value.id,
      toPay: {
        code: "",
        description: ""
      },
      observation: "",
      financialInformation: {
        rate: this.additionalServiceGroupCtrl.get('financialInformation.rate').value,
        cost: this.additionalServiceGroupCtrl.get('financialInformation.cost').value
      }
    }
    if (!(this.additionalServiceGroupCtrl.get('toPay.description')) || !(this.additionalServiceGroupCtrl.get('toPay.code'))) {
      delete body.toPay;
      if (!(this.additionalServiceGroupCtrl.get('observation'))) {
        delete body.observation;
      } else {
        body.observation = this.additionalServiceGroupCtrl.get('observation').value;
      }
    } else {
      body.toPay.code = this.additionalServiceGroupCtrl.get('toPay.code').value;
      body.toPay.description = this.additionalServiceGroupCtrl.get('toPay.description').value;
      delete body.observation;
    }
    return body;
  }

  validateTypeAdditionalService() {
    this.additionalServices.forEach(service => {
      if (this.additionalServiceGroupCtrl.value.type.name === service) {
        if (this.additionalServiceGroupCtrl.status === 'DISABLED') {
          this.activeEdit = true;
          this.additionalServiceGroupCtrl.enable();
          this.additionalServiceGroupCtrl.get('financialInformation.cost').disable();
          this.additionalServiceGroupCtrl.get('financialInformation.rate').disable();
        } else {
          this.activeEdit = false;
          this.additionalServiceGroupCtrl.disable();
        }
      }
    });
  }

  showRateCostAdditionalService() {
    if (this.additionalServiceGroupCtrl.get('financialInformation.paid').value !== null) {
      //si ya esta facturado no puede editar a menos que tenga el permiso
      if (this.cargo.shippingCost.cashed === true) {
        if (this.permissionRole.hasPermission(this.permission.payments.module, this.permission.payments.modifyAdditionalCostBilled)) {
          this.openBonusAndDiscounts();
        } else {
          this.snackBarService.openSnackBar('No es posible editar el servicio adicional. El servicio ya está facturado. Comunicarse con el equipo de facturación', undefined, 'alert');
        }
      } else {
        // si aun no esta pago el servicio adicional ni tampoco aprobado puede editar
        this.openBonusAndDiscounts();
      }
    }
  }

  openBonusAndDiscounts() {
    if ((this.permissionRole.hasPermission(this.permission.payments.module, this.permission.payments.modifyAdditionalCostpayed))) {
      this.RateCostAdditionalService(true);
    } else if (!this.additionalServiceGroupCtrl.get('financialInformation.paid').value && (this.approvalState === 'Pending approval' || this.approvalState === 'Rejected')) {
      this.RateCostAdditionalService(false);
    } else {
      this.manualCreationCargoService.showMessageNotAllowedEdit();
    }
  }

  RateCostAdditionalService(permission: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      finantialInfo: this.additionalServiceGroupCtrl,
      person: this.personCost,
      companyId: this.companyId,
      cargo: this.cargo,
      adittionalServiceId: this.additionalServiceGroupCtrl.value.id,
      enablePermissionModifyRate: permission
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    const dialogRef = this.dialog.open(RateCostAdditionalServicesComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.state) {
        this.editAdditionalCostItem.emit(result.state)
      }
    })
  }


  openHistoryDiscountsAndBonusesAdditionalServices(type) {
    const history = this.cargoDetailService.getHistoryDiscountsAndBonusesAdditionalServices(this.additionalServiceGroupCtrl.value, type);

    const config = new MatDialogConfig();
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.width = ModalEnum.MEDIUM_WIDTH;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    config.autoFocus = false;
    config.data = {
      shippingCostToDisplay: history,
      typePayment: 'DiscountsAndBonuses'
    };
    this.dialog
      .open(CargoShippingCostDialogComponent, config)
      .afterClosed()
      .subscribe(
        () => { }
      );
  }

  toggleCollapsehistoryAdditionalServices() {
    this.isCollapsedhistoryAdditionalServices = !this.isCollapsedhistoryAdditionalServices;
    this.showArrowDown = !this.showArrowDown
  }
  showFingerPrintInfo() {
    this.isCollapsedFingerPrint = !this.isCollapsedFingerPrint;
  }

  get showtoggleCollapsehistoryOption() {
    const haveTotalBonusesOrDiscounts = this.cargoItemService.getTotalDiscountsRateAdditionalServices(this.additionalServiceGroupCtrl.value) || this.cargoItemService.getTotalDiscountsCostAdditionalServices(this.additionalServiceGroupCtrl.value) || this.cargoItemService.getTotalBonusesRateAdditionalServices(this.additionalServiceGroupCtrl.value) || this.cargoItemService.getTotalBonusesCostAdditionalServices(this.additionalServiceGroupCtrl.value);

    if (haveTotalBonusesOrDiscounts) {
      if (this.showAdditionalCost && (this.cargoItemService.getTotalDiscountsCostAdditionalServices(this.additionalServiceGroupCtrl.value) || this.cargoItemService.getTotalBonusesCostAdditionalServices(this.additionalServiceGroupCtrl.value))) {
        return true;
      } else if (this.showAdditionalRate && (this.cargoItemService.getTotalDiscountsRateAdditionalServices(this.additionalServiceGroupCtrl.value) || this.cargoItemService.getTotalBonusesRateAdditionalServices(this.additionalServiceGroupCtrl.value))) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  get showBtnDeleteAdditionalService() {
    if (this.permissionRole.hasPermission(this.permission.cargo.module,
      this.permission.cargo.additionalCostDelete
    )) {
      return true;
    } else {
      return false;
    }
  }

  get showBtnEditAdditionalService() {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.additionalCostUpdate)
  }

  get showAdditionalCost() {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.showCostCargo) || this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.additionalCostUpdate) || this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.additionalCostCreate)
  }

  get showAdditionalRate() {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.showRateCargo) || this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.additionalCostUpdate) || this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.additionalCostCreate)
  }

  get personCost(): string {
    let person = '';
    switch (this.additionalServiceGroupCtrl.value.type.name) {
      case 'Stand by':
        person = 'CONDUCTOR';
        break;
      case 'Servicio descargue':
      case 'Servicio cargue':
        if (this.additionalServiceGroupCtrl.get('toPay') && this.additionalServiceGroupCtrl.get('toPay.description')) {
          person = this.additionalServiceGroupCtrl.get('toPay.description').value;
        }
        break;
      case 'Servicios de escolta':
      case 'Sello satelital':
      case 'Servicio de almacenamiento':
      case 'Servicio ITR':
        person = 'PROVEEDOR';
        break;
    }
    return person;
  }

  async getUtilityByCompany() {

    //Utilidad por servicio adicional por whoToPay
    let utilityByWhoToPay: number;
    const service: AdditionalCost = this.listAdditionalService.find(service => service && service.name && service.name.toLocaleUpperCase() === this.additionalServiceGroupCtrl.value.type.name.toLocaleUpperCase());
    if (service) {
      switch (this.additionalServiceGroupCtrl.value.type.name) {
        case 'Stand by':
        case 'Servicio descargue':
        case 'Servicio cargue':
          if (this.additionalServiceGroupCtrl.get('toPay') && this.additionalServiceGroupCtrl.get('toPay.code') && service.whoToPaysUtilities && service.whoToPaysUtilities.length) {
            const whoToPay = service.whoToPaysUtilities.find(who => who.whoToPayId === this.additionalServiceGroupCtrl.get('toPay.code').value);
            if (whoToPay && this.utils.isDefined(whoToPay.utility)) utilityByWhoToPay = whoToPay.utility;
          }
          break;
        default:
          if (this.utils.isDefined(service.utility)) utilityByWhoToPay = service.utility;
          break;
      }
    }

    //Utilidad por compañía propietaria
    const utilityResult = await this.managerComany.getutilityAdditionalServicesByCompanyId(this.companyId);
    const utilityOwner = this.manualCreationCargoService.getutilityAdditionalServiceOwner();
    const utilityByCompany = utilityOwner ? utilityOwner : utilityResult;

    //Utilidad por defecto
    const defaultMinUtility = this.authService.getCompany() && this.authService.getCompany().companyId && this.authService.getCompany().companyId === environment.rootNit
      ? this.ShippingCost.MINIMUN_UTILITY_ADDITIONAL_COST_TECLOGI
      : this.ShippingCost.MINIMUN_UTILITY_ADDITIONAL_COST;


    this.utilityRequired = this.utils.isDefined(utilityByWhoToPay)
      ? utilityByWhoToPay
      : this.utils.isDefined(utilityByCompany)
        ? utilityByCompany
        : defaultMinUtility;
    if (this.additionalServicesWithoutUtilityRestriction)
      this.checkUtility = false;
    else
      this.checkUtility = !!this.utilityRequired;
  }

  get ShippingCost() {
    return ShippingCost;
  }

  get currentUtility(): number {
    let rate: number = this.additionalServiceGroupCtrl.get('financialInformation.rate').value | 0;
    let cost: number = this.additionalServiceGroupCtrl.get('financialInformation.cost').value | 0;
    if (rate === 0) return 0;
    return (rate - cost) / rate * 100;
  }

  get additionalServicesWithoutUtilityRestriction(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.additionalServicesWithoutUtilityRestriction)
  }

}
