import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Utils } from 'src/app/core/resources/utils';
import { Global } from 'src/app/core/resources/global';
import { Model } from 'src/app/core/interfaces/vehicle';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { CatalogItem } from 'src/app/core/interfaces/catalogItem';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { VehicleType } from 'src/app/core/interfaces/vehicleType';
import { MatDatepickerInputEvent, MatDialog, MatDialogConfig, MatSelectChange } from '@angular/material';
import { Patterns } from 'src/app/core/resources/patterns';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DateManager } from 'src/app/core/managers/date.manager';
import { AdminUsersService } from '../../admin-users/admin-users.service';
import { PrevisualizationSettings } from 'src/app/core/interfaces/previsualizationSettings';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { AdditionalCertificationDict, AdditionalCertificationEnum } from 'src/app/core/enums/additionalCertification.enum';
import { DriverLicensesDTO, User } from 'src/app/core/interfaces/user';
import { UserModel } from 'src/app/core/models/user.model';
import { CompanyNamePipe } from 'src/app/core/pipe/companyName.pipe';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Fmt } from 'src/app/core/messages/fmt';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { DocumentEvent } from 'src/app/core/interfaces/documentEvent';
import { NgxSpinnerService } from 'ngx-spinner';
import { Companies } from 'src/app/core/resources/companies';
import { AdditionalCertification } from 'src/app/core/interfaces/additionalCertification';
import { UploadTaskSnapshot } from '@angular/fire/storage/interfaces';
import { FileService } from 'src/app/shared/files/file.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { VehicleContactsComponent } from '../vehicle-contacts/vehicle-contacts.component';
import { DriverLicenseCategory } from 'src/app/core/interfaces/driverLicenseCategory';
import { basicDataFieldsEdition, contactsAndReferencesFields } from 'src/app/core/layouts/createDriver.layout';
import { VehiclesService } from '../list-vehicles.service';
import { ValidationRule } from 'src/app/core/interfaces/validation-rule';

@Component({
  selector: 'app-person-vehicle',
  templateUrl: './person-vehicle.component.html',
  styleUrls: ['./person-vehicle.component.scss'],
  providers: [AuthService, ManualCreationCargoService, Model, CompanyNamePipe]
})
export class PersonVehicleComponent implements OnInit {
  permission = Permission;
  // Inputs
  @Input() title: 'Conductor' | 'Administrador' | 'Propietario';
  @Input() typeAction: 'createDriver' | 'editDriver' | 'createVehicle' = 'createVehicle';
  @Input() extraInformationUserOptions: {
    isTeclogiUserOrClient: boolean;
  }
  @Input() user: UserModel;
  @Input() paramsDialog: {
    driver: User;
    expireArl?: string;
    expireEps?: string;
    showDocuments?: boolean;
    driverIsSameOwner?: boolean;
    ownerIsSameAdmin?: boolean;
    validateTruora?: boolean;
  };
  @Input() form: FormGroup;
  @Input() onlyRequiredFieldsInForm: boolean = false;
  @Input() previewPrevizualizationSettings: PrevisualizationSettings;
  @Input() userDocument: string;
  @Input() documentValidation: number = 1;
  @Input() requiresDriversFields: boolean = false;
  // Outputs
  @Output() emitPreviewsResources: EventEmitter<PrevisualizationSettings> = new EventEmitter<PrevisualizationSettings>();
  @Output() emitUploadDocument: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() emitRefreshLoad: EventEmitter<string> = new EventEmitter<string>();
  @Output() emitLoadUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() emitExistingUserDocument: EventEmitter<string> = new EventEmitter<string>();
  @Output() emitTypedDocRules: EventEmitter<string[]> = new EventEmitter<string[]>();
  @ViewChild(VehicleContactsComponent, { static: false }) vehicleContactsComponent: VehicleContactsComponent;
  documenTypes: CatalogItem[];
  vehicleTypes: VehicleType[];
  expeditionDateId: FormControl = new FormControl('', Validators.required);
  optionsCity = {
    title: 'Ciudad',
    disabled: new BehaviorSubject<boolean>(false)
  }
  validateCity: string = '';
  validateDocument: string = '';
  cityControl: FormControl = new FormControl('', Validators.required);
  optionsDocumentUser: OptionsAutocomplete = {
    title: 'N° de identificación'
  }
  documentControl: FormControl = new FormControl('', Validators.required);
  today = new Date();
  formSub: Subscription;
  citySub: Subscription;
  documentSub: Subscription;
  documentControlSub: Subscription;
  expeditionDateSub: Subscription;
  visualArl: FormControl = new FormControl({ value: '', disabled: true });
  visualEps: FormControl = new FormControl({ value: '', disabled: true });
  visualOperationalAccreditation: FormControl = new FormControl({ value: '', disabled: true });

  docTypes: { name: string, loaded: boolean }[] = [{ name: 'arl', loaded: false }, { name: 'eps', loaded: false }, { name: AdditionalCertificationEnum.OPERATIONAL_ACCREDITATION, loaded: false }];
  licenseArray: FormArray;
  existingUser: string = '';
  maxLicenses: number = 3;
  additionalCertifications: AdditionalCertification[] = [];
  scoreTruoraControl: FormControl = new FormControl({ value: '', disabled: true });
  scoreTeclogiControl: FormControl = new FormControl({ value: '', disabled: true });
  basicInformationEnabled: boolean = false;
  docsAndCertificationsEnabled: boolean = false;
  licensesEnabled: boolean = false;
  contactsAndReferencesEnabled: boolean = false;
  editableContactsAndReferences = ['name', 'lastName', 'phone', 'remark', 'validated'];
  editableBasicInformation = ['information.name', 'phone', 'expeditionDateId', 'email', 'address', 'simpleRegimen'];
  editableDriverLicenseCategory = ['active', 'numberLicense', 'restrictions', 'expeditionDate', 'expirationDate'];
  basicFieldsToSend: string[] = ["information", "phone", "expeditionDateId", "city", "municipalityCode", "email", "address", "simpleRegimen"];
  cityCopy: string;
  copiedValues: User[] = [];
  previousLicenses: DriverLicenseCategory[] = [];
  loadingResources: boolean = false;
  docRules: ValidationRule[];
  typedDocRules: string[] = [];
  docsStateValidation: number = 1;
  constructor(
    public utils: Utils,
    private global: Global,
    private patterns: Patterns,
    public adminUsersService: AdminUsersService,
    public dialog: MatDialog,
    public companyNamePipe: CompanyNamePipe,
    private snackBarService: SnackBarService,
    public permissionRole: PermissionRole,
    public authService: AuthService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private fileService: FileService,
    private vehiclesService: VehiclesService
  ) {
    this.documenTypes = this.utils.clone(this.global.documenTypes);
  }

  /**
  * @description Initializes the optionsDocumentUser, deletes the documentType by title input, and execute setSubscription and setOptions methods
  */
  async ngOnInit(): Promise<void> {
    await this.getValidationRules();
    if ((this.typeAction === 'createDriver' || this.typeAction === 'editDriver') && this.driverLicenseCategory.length === 0) this.addLicenseForm();
    if ((this.typeAction === 'createVehicle' || (this.typeAction === 'editDriver') && this.extraDocuments.length === 0)) this.addExtraDocumentForm();
    this.optionsDocumentUser.mustAcceptTerms = this.title.includes('Conductor');
    this.optionsDocumentUser.typeUser = (this.title.includes('Conductor') ? 'driver' : (this.title === 'Propietario' ? 'owner' : 'admin'));
    const indexDoc = { Conductor: '3', Propietario: '4', Administrador: '5' };
    const indexNit = this.documenTypes.findIndex((obj) => {
      return obj.id === indexDoc[this.title];
    });
    if (indexNit >= 0) {
      this.documenTypes.splice(indexNit, 1);
    }
  }
  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  private async getValidationRules() {
    try {
      this.docRules = await this.vehiclesService.getValidationRules().toPromise();
      this.typedDocRules = this.docRules.filter(rule => rule.roleName === 'Driver' && rule.active).map(rule => rule.ruleName);
      this.emitTypedDocRules.emit(this.typedDocRules);
    }
    catch (error) {
      this.docRules = [];
    }
  }

  addLicenseForm($event?: string, enableField: boolean = false) {
    if (this.driverLicenseCategory.length >= 3) return this.snackBarService.openSnackBar(Fmt.string(FormMessages.MAX_LICENSES, this.maxLicenses), undefined, 'alert');
    const validators = this.typedDocRules.includes('driverLicense') ? [Validators.required] : [];
    this.driverLicenseCategory.push(new FormGroup({
      category: new FormControl({ value: '', disabled: this.typeAction === 'editDriver' && !(!!$event) && !enableField }, [Validators.minLength(2), Validators.maxLength(2), ...validators]),
      numberLicense: new FormControl('', validators),
      restrictions: new FormControl(),
      expeditionDate: new FormControl('', validators),
      expirationDate: new FormControl('', validators),
      active: new FormControl(true, validators),
      preview: new FormControl(!!$event, validators)
    }));
  }

  addExtraDocumentForm() {
    this.docTypes.forEach(type => {
      if (type.name !== AdditionalCertificationEnum.OPERATIONAL_ACCREDITATION) {
        const validators = this.typedDocRules.includes(type.name) ? [Validators.required] : [];
        this.extraDocuments.push(new FormGroup({
          type: new FormControl(type.name, validators),
          path: new FormControl('', validators),
          dueDate: new FormControl('', validators),
          approvalBy: new FormControl('')
        }))
      }
    })
  }

  deleteLicenseElement(index: number) {
    this.driverLicenseCategory.removeAt(index);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.form && changes.form.currentValue) {
      this.setSubscription();
      this.setOptions();
      this.setAdditionalControls();
    }
    if (changes && changes.userDocument && changes.userDocument.currentValue) {
      this.optionsDocumentUser['initialValue'] = changes.userDocument.currentValue;
      this.optionsDocumentUser = { ...this.optionsDocumentUser };
    }
    if (changes && changes.onlyRequiredFieldsInForm && changes.onlyRequiredFieldsInForm.currentValue && this.onlyRequiredFieldsInForm) {
      this.cityControl.setValidators(null);
      this.cityControl.updateValueAndValidity();
    }
    if (changes && changes.previewPrevizualizationSettings && changes.previewPrevizualizationSettings.currentValue && (this.typeAction === 'createDriver' || this.typeAction === 'editDriver')) this.setValues(changes.previewPrevizualizationSettings.currentValue);

    if (changes && changes.documentValidation) this.setAdditionalControls();

  }
  /**
  * @description Creates some subscriptions to valueChanges of form controls and local variables, to update local variables and form controls
  */
  private setSubscription() {
    this.formSub = this.form.valueChanges.subscribe(() => {
      this.form.get('expeditionDateId') && this.form.get('expeditionDateId').disabled ? this.expeditionDateId.disable() : this.expeditionDateId.enable();
    });
    if (this.form.get('city')) {
      this.cityControl.setValue(this.form.get('city').value && this.form.get('municipalityCode').value ? { name: this.form.get('city').value, id: this.form.get('municipalityCode').value } : null);
      let $citySubscription = this.form.get('city').valueChanges
        .subscribe(value => {
          if (value) {
            this.optionsCity['initialValue'] = value;
            this.optionsCity = { ...this.optionsCity };
            $citySubscription.unsubscribe();
          }
        })
      this.documentSub = this.form.get('information.documentTypeId').valueChanges
        .subscribe(value => {
          if (!this.title.includes('Conductor')) {
            if (value === '3') {
              this.form.get('city').setValidators(Validators.required);
              this.cityControl.setValidators(Validators.required);
              this.validateCity = 'setValidators';
              this.validateDocument = 'setValidators';
            } else {
              this.form.get('city').clearValidators();
              this.cityControl.clearValidators();
              this.validateCity = 'clearValidators';
              this.validateDocument = 'clearValidators';
            }
            this.form.get('city').updateValueAndValidity();
            this.cityControl.updateValueAndValidity();
          }
        })
    }
    this.citySub = this.cityControl.valueChanges
      .subscribe(value => this.citySelected = value ? value : '');
    this.documentControlSub = this.documentControl.valueChanges
      .subscribe(async value => {
        if (value && value.information && (value.information.name || value.information.document)) {
          if (value.operationId) delete value.operationId;
          if (value.city) {
            this.optionsCity['initialValue'] = value.city;
            this.optionsCity = { ...this.optionsCity };
          }
          const documentTypeId = this.form.get('information.documentTypeId').value;
          const documentTypeName = this.form.get('information.documentTypeName').value;
          this.form.reset();
          const filteredValue = {
            ...value,
            information: {
              ...value.information,
              documentTypeId: value.information.documentTypeId ? value.information.documentTypeId : documentTypeId,
              documentTypeName: value.information.documentTypeName ? value.information.documentTypeName : documentTypeName,
            },
          };
          this.setDocumentsArray(filteredValue);
          this.form.patchValue(filteredValue);
          if (this.typeAction === 'editDriver') {
            this.form.disable();
            this.validateDocument = 'disable';
            this.validateCity = 'disable';
            this.setAdditionalControls();
            this.previousLicenses = [...this.driverLicenseCategory.getRawValue().filter(license => license.category)];
            this.docsStateValidation += 1;
          }
          if (this.hasEscortServicesCompany) await this.getAdditionalCertifications(filteredValue);
          if ((this.typeAction === 'createDriver' || this.typeAction === 'editDriver')) this.setValues(this.previewPrevizualizationSettings);
        } else this.form.get('information.document').setValue(value && typeof value === 'string' ? value : '');
        this.emitLoadUpdated.emit(true);
      });

    let $documentSubscription = this.form.get('information.document').valueChanges
      .subscribe(value => {
        if (value) {
          this.setOptions();
          $documentSubscription.unsubscribe();
        }
      });
    if (this.form.controls.expeditionDateId) {
      this.expeditionDateSub = this.form.controls.expeditionDateId.valueChanges.subscribe((value) => {
        this.expeditionDateId.setValue(DateManager.stringToDate(value, 'YYYY-MM-DD'));
      });
    }
  }

  setAdditionalControls() {
    this.scoreTruoraControl.setValue(this.scoreTruora);
    this.scoreTeclogiControl.setValue(this.scoreTeclogi);
  }

  setDocumentsArray(filteredValue: User) {
    if ((this.typeAction === 'createDriver' || this.typeAction === 'editDriver')) {
      this.processExtraDocuments(filteredValue);
      this.processLicenseForm(filteredValue);
      this.adminUsersService.previewPrevizualizationSettings = {};
      this.adminUsersService.previsulizationSettings = {};
    }
  }

  /**
* @param {User} filteredValue is the user
* @description Gets the list of user's additional certifications
*/
  private async getAdditionalCertifications(filteredValue: User) {
    try {
      const result = await this.adminUsersService.getUserAdditionalCertifications(filteredValue.information.document).toPromise();
      this.additionalCertifications = result && result.length ? result : [];
      const operationalAccreditation = this.getAdditionalCertification(AdditionalCertificationEnum.OPERATIONAL_ACCREDITATION);
      if (this.form.get('extraDocuments')) {
        if (operationalAccreditation) {
          this.extraDocuments.push(new FormGroup({
            type: new FormControl(AdditionalCertificationEnum.OPERATIONAL_ACCREDITATION, Validators.required),
            path: new FormControl(operationalAccreditation.path, Validators.required),
            dueDate: new FormControl(operationalAccreditation.dueDate, Validators.required),
            approvalBy: new FormControl(operationalAccreditation && operationalAccreditation.approvalBy && operationalAccreditation.approvalBy.userName ? operationalAccreditation.approvalBy.userName : '', Validators.required),
            active: new FormControl(operationalAccreditation.active, Validators.required)
          }));
        }
        else {
          const validators = this.typedDocRules.includes(AdditionalCertificationEnum.OPERATIONAL_ACCREDITATION) ? [Validators.required] : [];
          this.extraDocuments.push(new FormGroup({
            type: new FormControl(AdditionalCertificationEnum.OPERATIONAL_ACCREDITATION, validators),
            path: new FormControl('', validators),
            dueDate: new FormControl('', validators),
            approvalBy: new FormControl('', validators),
            active: new FormControl(false, validators)
          }));
        }
      }
      this.docsStateValidation += 1;
    } catch (e) {
      this.additionalCertifications = [];
    }
  }

  /**
 * @param {AdditionalCertificationEnum} type is the type of the certification
 * @returns {AdditionalCertification} returns the certification required if exists
 * @description Indicates if the driver has the certification required and returns it.
 */
  getAdditionalCertification(type: AdditionalCertificationEnum): AdditionalCertification {
    if (!this.additionalCertifications || !this.additionalCertifications.length) return;
    return this.additionalCertifications.find(cert => cert && cert.id === type);
  }

  processExtraDocuments(filteredValue: User) {
    this.extraDocuments.clear();
    if (filteredValue.extraDocuments && filteredValue.extraDocuments.length > 0) {
      filteredValue.extraDocuments.forEach((document: { type: string, path: string, dueDate: string, approvalBy: string }) => {
        const existingDocIndex = this.extraDocuments.controls.findIndex(doc => doc.get('type') && (doc.get('type').value.toLowerCase() === document.type.toLowerCase() || doc.get('type').value === document.type));
        this.setExtraDocuments(this.extraDocuments, existingDocIndex, document);
      })
    } else {
      this.addExtraDocumentForm();
    }
  }

  processLicenseForm(filteredValue: User) {
    this.driverLicenseCategory.clear();
    if (filteredValue.driverLicenseCategory && filteredValue.driverLicenseCategory.length > 0) {
      for (let i = 0; i < filteredValue.driverLicenseCategory.length; i++) {
        this.addLicenseForm();
      }
    } else this.addLicenseForm(null, true);

  }
  /**
  * @description Updates the documentUser's options
  */
  private setOptions() {
    if (this.form.get('expeditionDateId') && this.form.get('expeditionDateId').value) this.expeditionDateId.setValue(DateManager.stringToDate(this.form.controls.expeditionDateId.value, 'YYYY-MM-DD'));
    if (this.typeAction === 'createDriver' || this.typeAction === 'createVehicle') {
      this.optionsDocumentUser['initialValue'] = this.form && this.form.get('information.document') && this.form.get('information.document').value ? this.form.get('information.document').value : '';
      this.optionsDocumentUser['documentTypeId'] = this.form && this.form.get('information.documentTypeId') && this.form.get('information.documentTypeId').value ? this.form.get('information.documentTypeId').value : null;
      this.optionsDocumentUser = { ...this.optionsDocumentUser };
    }

  }

  setValues(previewPrevizualizationSettings: PrevisualizationSettings) {
    this.adminUsersService.checkRenderResources(this.docTypes, previewPrevizualizationSettings, null, this.extraDocuments);
    this.processResourcesControls();
  }

  async processResourcesControls(): Promise<void> {
    let paths = [];
    this.visualArl.setValue('');
    this.visualEps.setValue('');
    this.visualOperationalAccreditation.setValue('');
    if (this.extraDocuments) {
      this.docTypes.forEach(type => {
        let controlValue = this.getDocumentControl(type.name).value;
        if (controlValue) {
          let control = this[`visual${type.name.charAt(0).toUpperCase() + type.name.slice(1)}`];
          control.enable();
          control.setValue(this.adminUsersService.traslateFileName(controlValue));
          control.disable();
        }
        // Si esta cargado se agrega a paths para que se consulte en loadResources
        if (type.loaded) paths = controlValue ? [...paths, { path: controlValue, type }] : paths;
      });

      if (paths && paths.length) {
        this.loadingResources = true;
        await this.adminUsersService.loadResources(paths);
        this.loadingResources = false;
      }
    }
  }

  getDocumentControl(type: string): FormControl {
    if (this.extraDocuments) {
      const document = this.extraDocuments.controls.find((document: FormGroup) => {
        if (document && document.get('type') && document.get('type').value) {
          return document.get('type').value.toLowerCase() === type || document.get('type').value === type;
        }
        return false;
      });
      return document ? document.get('path') as FormControl : new FormControl();
    }
    return new FormControl();
  }

  /**
  * @param {CatalogItem} documentType is the document type to check
  * @description If the documentType exists and has a name, returns the name otherwise returns an empty string
  */
  displayFnDocumentType(documentType: CatalogItem): string {
    return documentType ? documentType.name : '';
  }

  /**
  * @param {CatalogItem} documentType is the document type to check
  * @description If the documentType exists and has an id, returns the id otherwise returns undefined
  */
  returnFnDocumentType(documentType: CatalogItem): string | undefined {
    return documentType ? documentType.id : undefined;
  }

  /**
  * @param {MatSelectChange} $event is the event of the document type selected
  * @description Updates the form and its validations with the document type selected
  */
  onChangeDocumentType($event: MatSelectChange) {
    const documentType = this.documenTypes.filter((obj) => {
      return obj.id === $event.value;
    });
    this.form.get('information.documentTypeId').setValue(documentType[0].id);
    this.form.get('information.documentTypeName').setValue(documentType[0].name);
    if (this.form && this.form.get('information.documentTypeId') && this.form.get('information.documentTypeId').value && this.form.get('information.documentTypeId').value === '3') {
      this.form.get('phone').setValidators([
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(12),
        Validators.pattern(this.patterns.CELLPHONEANDLANDLINE.source),
      ])
      this.form.get('information.document').setValidators([
        Validators.required,
        Validators.minLength(AmountsCargoEnum.MIN_DOCUMENT_LENGTH),
        Validators.maxLength(AmountsCargoEnum.MAX_NIT_LENGTH),
      ])
    } else {
      this.form.get('phone').setValidators([
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(12),
        Validators.pattern(this.patterns.CELLPHONE.source),
      ])
      this.form.get('information.document').setValidators([
        Validators.required,
        Validators.minLength(AmountsCargoEnum.MIN_DOCUMENT_LENGTH),
        Validators.maxLength(AmountsCargoEnum.MAX_DOCUMENT_LENGTH),
      ])
    }
    this.form.updateValueAndValidity();
    this.optionsDocumentUser['documentTypeId'] = this.form.get('information.documentTypeId').value;
    delete this.optionsDocumentUser.initialValue;
    this.optionsDocumentUser = { ...this.optionsDocumentUser };
  }

  /**
  * @param {MatDatepickerInputEvent<Date>} $event is the event of the date selected
  * @description Updates the form's expeditionDateId with the date selected in format expected
  */
  onChangeDatePicker($event: MatDatepickerInputEvent<Date>) {
    this.form.get('expeditionDateId').setValue(DateManager.dateToString($event.value, 'YYYY-MM-DD'));
  }

  /**
  * @param {{id: string, name: string}} city is the event of the city selected
  * @description Updates the form's city and municipalityCode controls with the city selected
  */
  set citySelected(city: { id: string, name: string }) {
    this.form.get('city').setValue(city && city.name ? city.name : null);
    this.form.get('municipalityCode').setValue(city && city.id ? city.id : null);
  }

  /**
  * @param {string} $event is the address selected
  * @description Updates the form's address with the address selected
  */
  onSelectAddress($event: string) {
    if (this.utils.isDefined($event) && this.form.get('address')) {
      this.form.get('address').setValue($event);
    }
  }

  async onUploadDocument(event: DocumentEvent) {
    const isArlOrEps = this.docTypes.some(docType => docType.name === event.type.toLowerCase());
    this.setPrevisulizationSettings(isArlOrEps ? event.type.toLowerCase() : event.type, event);
    if (this.docTypes.find(docType => docType.name === event.type.toLowerCase() || docType.name === event.type)) this.docTypes.find(docType => docType.name === event.type.toLowerCase() || docType.name === event.type).loaded = false;
    let extraDocumentsInForm = this.form.get('extraDocuments') as FormArray;
    const existingDocIndex = extraDocumentsInForm.controls.findIndex(doc => doc.get('type') && doc.get('type').value && (doc.get('type').value.toLowerCase() === event.type.toLowerCase() || doc.get('type').value === event.type));
    this.setExtraDocuments(extraDocumentsInForm, existingDocIndex, { type: isArlOrEps ? event.type.toLowerCase() : event.type, path: event.filePath, dueDate: event.date, approvalBy: event.approvalBy, active: event.active });

    if (this.typeAction === 'editDriver') await this.loadDocuments(isArlOrEps, event);
    this.processResourcesControls();
  }

  async loadDocuments(isArlOrEps: boolean, event: DocumentEvent) {
    if (isArlOrEps) await this.userUploadDocument(event.type as 'ARL' | 'EPS', event);
    else await this.userUploadDocument(event.type as AdditionalCertificationEnum, event);
  }


  /**
 * @param {'ARL|EPS'|AdditionalCertificationEnum} type is the type of the document to upload
 * @param path is the file to upload
 * @param date is the expiration date of the file
* @description Uploads a user's file
*/
  private async userUploadDocument(type: 'ARL' | 'EPS' | AdditionalCertificationEnum, event: DocumentEvent): Promise<void> {
    await this.uploadStorage(type);
    let promise: Promise<Object>;
    if (type === 'ARL' || type === 'EPS')
      promise = this.adminUsersService.userUploadDocument({ type, path: event.filePath, dueDate: event.date }, this.form.get('information.document').value).toPromise();
    else
      promise = this.adminUsersService.updateUserAdditionalCertifications(
        this.form.get('information.document').value,
        { id: type, active: false, path: event.filePath, dueDate: event.date, description: AdditionalCertificationDict[type] }
      ).toPromise();

    this.spinner.show();
    promise.then((success: any) => {
      this.emitRefreshLoad.emit(this.form.get('information.document').value);
      this.snackBarService.openSnackBar(
        "Soporte cargado correctamente, a la espera de aprobación"
      );
    }).catch((error: any) => {
      this.snackBarService.openSnackBar(
        "Ocurrió un error al cargar el soporte",
        undefined,
        "error"
      );
    }).finally(() => {
      if (this.paramsDialog && this.paramsDialog.driver) this.emitUploadDocument.emit(true);
      this.spinner.hide();
    });
  }

  setPrevisulizationSettings(typeDocument: string, file: DocumentEvent) {
    this.adminUsersService.previsulizationSettings[typeDocument] = {
      file: file.resourceEvent.file,
      filePath: file.filePath,
      imageFile: file.resourceEvent.imageFile,
      loaded: false
    };
  }

  setExtraDocuments(extraDocumentsInForm: FormArray, existingDocIndex: number, document: { type: string, path: string, dueDate: string, approvalBy: string, active?: boolean }) {
    if (existingDocIndex !== -1) {
      // Si existe, actualizar los valores del FormGroup existente
      const existingDoc = extraDocumentsInForm.at(existingDocIndex) as FormGroup;
      existingDoc.patchValue({
        path: document.path,
        dueDate: document.dueDate,
        approvalBy: document.approvalBy
      });
    } else {
      // Si no existe, agregar un nuevo FormGroup
      const validators = this.typedDocRules.includes(document.type) ? [Validators.required] : [];
      extraDocumentsInForm.push(new FormGroup({
        type: new FormControl(document.type, validators),
        path: new FormControl(document.path, validators),
        dueDate: new FormControl(document.dueDate, validators),
        approvalBy: new FormControl(document.approvalBy),
        ...(document.type === 'operationalAccreditation' ? { active: new FormControl(document.active, validators) } : {})
      }));
    }
  }

  onEditSection(section: string, action: string) {
    let controlState = action === 'edit' ? 'enable' : 'disable';
    switch (section) {
      case 'basicInformation':
        if (action === 'save') {
          if (this.checkFieldErrors(basicDataFieldsEdition)) return;
        } else {
          this.basicInformationEnabled = !this.basicInformationEnabled;
          this.validateCity = controlState;
          this.enableDisableControls(this.form, this.editableBasicInformation, controlState);
        }
        break;
      case 'licenses':
        if (action === 'save') {
          if (this.checkFieldErrors([{ control: 'licenses' }])) return;
          else if (this.adminUsersService.checkDuplicatedLicenses(this.form)) return this.snackBarService.openSnackBar(FormMessages.DUPLICATED_LICENSES, undefined, "alert");
        }
        else this.enableDisableLicenses(controlState);
        break;
      case 'contactsAndReferences':
        if (action === 'save') if (this.checkFieldErrors(contactsAndReferencesFields)) return;
        this.contactsAndReferencesEnabled = !this.contactsAndReferencesEnabled;
        let references = ['emergencyContact', 'referenceLaboral', 'referencePersonal'];
        references.forEach(reference => {
          this.enableDisableControls(this.form.get(reference) as FormGroup, this.editableContactsAndReferences, controlState);
        })
        break;
      default:
        break;
    }
    if (action === 'edit' || action === 'cancel') this.setAndCopyValues(section, action);
    else this.saveSection(section, controlState);
  }


  enableDisableLicenses(controlState: string) {
    this.licensesEnabled = !this.licensesEnabled;
    let licenses = this.form.get('driverLicenseCategory') as FormArray;
    licenses.controls.forEach(license => {
      this.enableDisableControls(license as FormGroup, license.get('category') && license.get('category').value && controlState === 'enable' && this.previousLicenses.some(prevLicense => prevLicense.category === license.get('category').value) ? this.editableDriverLicenseCategory : [...this.editableDriverLicenseCategory, 'category'], controlState);
    })
  }

  setAndCopyValues(section: string, action: string) {
    if (action === 'edit') {
      this.copiedValues[section] = { ...this.form.value };
      if (section === 'basicInformation') {
        this.cityCopy = this.copiedValues[section].municipalityCode;
      }

    } else if (action === 'cancel') {
      this.form.patchValue(this.copiedValues[section]);
      if (section === 'basicInformation') {
        this.optionsCity['initialMunicipalityCode'] = this.cityCopy;
        this.optionsCity = { ...this.optionsCity };
      }




      if (section === 'licenses') {
        let previewLicenses = this.utils.clone((this.form.get('driverLicenseCategory') as FormArray).getRawValue());
        const indexesToRemove = previewLicenses
          .map((license: DriverLicenseCategory, index: number) => (license.preview ? index : -1))
          .filter((index: number) => index !== -1);
        indexesToRemove
          .reverse()
          .forEach((index: number) => this.driverLicenseCategory.removeAt(index));
      }
    }
  }

  saveSection(section: string, controlState: string) {
    if (section === 'basicInformation') {
      let formToSend = Object.keys(this.form.getRawValue())
        .filter((key) => this.basicFieldsToSend.includes(key)) // Solo los campos en la lista
        .reduce((obj, key) => {
          obj[key] = this.form.getRawValue()[key]; // Agregar los campos filtrados al nuevo objeto
          return obj;
        }, {});
      if (
        this.copiedValues[section]
        && this.form.get('expeditionDateId')
        && this.form.get('expeditionDateId').value
        && this.copiedValues[section].expeditionDateId
        && this.copiedValues[section].expeditionDateId !== this.form.get('expeditionDateId').value
        && !this.authService.getCompanySaaS().allowCreatingWithoutTruora)
        this.validateUser(this.form.get('expeditionDateId').value, formToSend);


      this.saveBasicInformation(formToSend, controlState);


    }
    else if (section === 'licenses') this.processLicenses(controlState);
    else if (section === 'contactsAndReferences') this.vehicleContactsComponent.onSubmit('edit');

  }

  saveBasicInformation(formToSend: User, controlState: string) {
    const observer = {
      next: () => {
        this.spinner.hide();
        this.emitRefreshLoad.emit(this.form.get('information.document').value);
        this.basicInformationEnabled = !this.basicInformationEnabled;
        this.validateCity = controlState;
        this.enableDisableControls(this.form, this.editableBasicInformation, controlState);
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_UPDATE_SUCCESS, undefined, 'success');
      },
      error: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    };
    this.spinner.show();
    this.adminUsersService.saveBasicInformation(formToSend).subscribe(observer);
  }

  processLicenses(controlState: string) {
    let driverLicensesCopy: DriverLicensesDTO = {
      driverLicense: {
        active: false
      },
      driverLicenseCategory: [...(this.driverLicenseCategory && this.driverLicenseCategory.getRawValue() ? this.driverLicenseCategory.getRawValue() : [])]
    };
    driverLicensesCopy.driverLicenseCategory.forEach(license => {
      license.category = license.category.toUpperCase();
      license.expeditionDate = DateManager.dateToString(new Date(license.expeditionDate), 'YYYY-MM-DDTHH:mm:ssZ');
      license.expirationDate = DateManager.dateToString(new Date(license.expirationDate), 'YYYY-MM-DDTHH:mm:ssZ');
      if (!license.restrictions) delete license.restrictions;
      if (this.utils.isDefined(license.preview)) delete license.preview;
    })


    const everyLicenseExpired: boolean = driverLicensesCopy.driverLicenseCategory.every((license: DriverLicenseCategory) => {
      let diff = DateManager.dateDiff(new Date(), null, license.expirationDate, 'YYYY-MM-DDTHH:mm:ssZ');
      return diff >= 0 || !license.active;
    });
    driverLicensesCopy.driverLicense = {
      active: !everyLicenseExpired,
      description: everyLicenseExpired ? 'Licencia de conducción no activa' : 'Licencia de conducción activa'
    };
    return this.saveUpdateLicenses(this.form.get('information.document').value, driverLicensesCopy, controlState);

  }

  /**
* @param {string} document is the driver's document
* @param {} body is the vehicle's licenses
* @description updates the vehicle fields and closes the dialog
*/
  public saveUpdateLicenses(document: string, body: DriverLicensesDTO, controlState: string) {
    this.spinner.show();
    this.vehiclesService.updateUserLicenses(document, body).subscribe(
      () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_SUCCESS);
        this.enableDisableLicenses(controlState);
        this.emitRefreshLoad.emit(this.form.get('information.document').value);
      },
      () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      },
    );
  }

  checkFieldErrors(fields: { control: string, name?: string }[]) {
    this.form.markAllAsTouched();
    let haveErrors = false;
    for (const field of fields) {
      const control = field.control;
      if (!haveErrors && this.form.get(control)) {
        if (this.utils.errorMessagesCustomized(this.form.get(control), field.name) && control !== 'licenses') {
          haveErrors = true;
          break;
        }
        if (control === 'licenses') {
          const customValidators = {
            licenses: () => this.findErrorOnLicense(this.licenses),
          };
          if (customValidators[control] && customValidators[control]()) {
            haveErrors = true;
            break;
          }
        }
      } else break;
    }
    return haveErrors;
  }

  findErrorOnLicense(licenses: AbstractControl[]) {
    let haveErrors = false;
    for (let [i, license] of licenses.entries()) {
      if (!haveErrors) {
        if (this.utils.errorMessagesCustomized(license.get('category'), `categoria de la licencia ${i + 1}`)) {
          haveErrors = true;
          break;
        }
        else if (this.utils.errorMessagesCustomized(license.get('numberLicense'), `número de la licencia ${i + 1}`)) {
          haveErrors = true;
          break;
        }
        else if (this.utils.errorMessagesCustomized(license.get('expeditionDate'), `fecha de expedición de la licencia ${i + 1}`)) {
          haveErrors = true;
          break;
        }
        else if (this.utils.errorMessagesCustomized(license.get('expirationDate'), `fecha de expiración de la licencia ${i + 1}`)) {
          haveErrors = true;
          break;
        }
      } else break;
    }
    return haveErrors;
  }





  processFormToSend(form: User) {
    let formToSend: User = { ...form };
    if (
      formToSend &&
      formToSend.extraDocuments &&
      formToSend.extraDocuments.length
    ) {
      formToSend.extraDocuments = formToSend.extraDocuments.filter(
        extraDocument => extraDocument.type !== 'operationalAccreditation'
      );
    }
    return formToSend;
  }

  validateUser(expeditionDate: string, formToSend: User) {
    this.spinner.show();
    const driverDetail = this.utils.clone(formToSend);
    driverDetail.expeditionDateId = expeditionDate;
    this.adminUsersService
      .validateUser(driverDetail, true)
      .toPromise()
      .then((response) => {
        this.emitRefreshLoad.emit(this.form.get('information.document').value);
        this.snackBarService.openSnackBar("Fecha actualizada correctamente");
      })
      .catch((error) => {
        this.snackBarService.openSnackBar(
          "Ocurrió un error al actualizar la fecha",
          undefined,
          "error"
        );
      })
      .finally(() => {
        this.spinner.hide();
      });
  }


  enableDisableControls(form: FormGroup, controls: string[], action: string) {
    controls.forEach(control => {
      if (form && form.get(control)) form.get(control)[action]();
    })
  }



  async uploadStorage(type: AdditionalCertificationEnum | 'ARL' | 'EPS'): Promise<void> {
    const fileToUpload = this.getFileToUpload(type);
    if (!fileToUpload.path) return;
    this.spinner.show();
    try {
      const loadedResponse = await this.fileService.uploadFile(fileToUpload);
      await this.updateFileMetadata(loadedResponse);
    } catch (error) {
      console.error("Error during upload or save resources:", error);
      this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
    } finally {
      this.spinner.hide();
    }
  }

  private getFileToUpload(type: AdditionalCertificationEnum | 'ARL' | 'EPS'): { path: string; imageFile: File; key: string } {
    return { path: this.adminUsersService.previsulizationSettings[type === 'ARL' || type === 'EPS' ? type.toLowerCase() : type].filePath, imageFile: this.adminUsersService.previsulizationSettings[type === 'ARL' || type === 'EPS' ? type.toLowerCase() : type].imageFile, key: type }
  }

  private async updateFileMetadata(loadedFile: { success?: UploadTaskSnapshot; key: string }): Promise<void> {
    if (!loadedFile.success) {
      throw new Error(`Cannot update metadata for failed upload: ${loadedFile.key}`);
    }
    return loadedFile.success.ref.getDownloadURL()
      .then(url => {
        const formatFile = this.adminUsersService.previsulizationSettings[loadedFile.key === 'ARL' || loadedFile.key === 'EPS' ? loadedFile.key.toLowerCase() : loadedFile.key].filePath.split('.').pop();
        this.adminUsersService.previsulizationSettings[loadedFile.key === 'ARL' || loadedFile.key === 'EPS' ? loadedFile.key.toLowerCase() : loadedFile.key] = {
          file: {
            url: url,
            type: formatFile
          },
          loaded: true
        };
      })
      .catch(error => {
        console.error(`Error getting download URL for key ${loadedFile.key}:`, error);
        throw error;
      });


  }


  emitExistingUser(user: User) {
    if (user && user.information && user.information.document && (user.information.documentTypeId || user.information.documentTypeName)) {
      this.existingUser = user.information.document;
      this.emitExistingUserDocument.emit(user.information.document);
    }
    else
      this.existingUser = null;
  }

  ngOnDestroy() {
    this.emitPreviewsResources.emit(this.adminUsersService.previsulizationSettings);
    if (this.formSub) this.formSub.unsubscribe();
    if (this.citySub) this.citySub.unsubscribe();
    if (this.documentSub) this.documentSub.unsubscribe();
    if (this.documentControlSub) this.documentControlSub.unsubscribe();
    if (this.expeditionDateSub) this.expeditionDateSub.unsubscribe();
  }

  // GETTERS

  get extraDocuments() {
    return this.form && this.form.get('extraDocuments') instanceof FormArray
      ? (this.form.get('extraDocuments') as FormArray)
      : new FormArray([]);
  }

  get driverLicenseCategory() {
    return this.form && this.form.get('driverLicenseCategory') instanceof FormArray
      ? (this.form.get('driverLicenseCategory') as FormArray)
      : new FormArray([]);
  }

  get licenses(): AbstractControl[] {
    return (this.form.get('driverLicenseCategory') as FormArray).controls;
  }

  /**
  * @returns {boolean} Returns true if the user has role Driver
  * @description Checks if the user has role Driver
  */
  get isDriver(): boolean {
    return this.form && this.form.get('role') && this.form.get('role').value === 'Driver';
  }
  /**
  * @returns {boolean} Returns true if the user has role Owner
  * @description Checks if the user has role Owner
  */
  get isOwner(): boolean {
    return this.form && this.form.get('role') && this.form.get('role').value === 'Owner';
  }
  /**
  * @returns {boolean} Returns true if the user has role Admin
  * @description Checks if the user has role Admin
  */
  get isAdmin(): boolean {
    return this.form && this.form.get('role') && this.form.get('role').value === 'Admin';
  }

  /**
  * @returns {boolean} Returns true if the user the "updateVehicle" permission, otherwise false
  * @description Verifies if the user has the "updateVehicle" permission
  */
  get canEditVehicleInfo(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.administration.module,
      this.permission.administration.updateVehicle
    );
  }

  /**
  * @param {string} field is the editableFields field to edit
  * @param {boolean} activate indicates if the field must to be able to edit or not
  * @description Updates an editableFields field to edit it
  */
  get canOverwriteVehicleInfo(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.administration.module,
      this.permission.administration.editFieldsRUNT
    );
  }

  /**
  * @returns {boolean} Returns true if the user's SaaS company has escort services
  * @description Verifies if the user's SaaS company has escort services
  */
  get hasEscortServicesCompany(): boolean {
    return this.authService.getCompanySaaS() && this.authService.getCompanySaaS().companyId === Companies.companiesNIT.SEGURIDAD_EXTREMA;
  }

  /**
  * @returns {number|string} if the user has Truora's score returns it as readable number
  * @description returns a readable number of user's Truora's score
  */
  get scoreTruora(): number | string {
    if (this.form && (this.isDriver || this.isOwner) && this.form.get('truora') && this.form.get('truora').value && this.form.get('truora').value.scoreTruora)
      return this.form.get('truora').value.scoreTruora * 10;
    return "Pendiente de Validacion";
  }

  /**
  * @returns {number|string} if the user has Teclogi's score returns it as readable number
  * @description returns a readable number of user's Teclogi's score
  */
  get scoreTeclogi(): number | string {
    if (this.form && this.isDriver && this.form.get('teclogiScore') && this.form.get('teclogiScore').value && this.form.get('teclogiScore').value.globalScore)
      return this.form.get('teclogiScore').value.globalScore;
    return "Pendiente de Valoración";
  }

  get canEditContactsAndReferences(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.administration.module,
      this.permission.administration.editReferencesVehicle
    );
  }

  getDocsStateValidation() {
    return `${this.docsStateValidation}-${this.documentValidation}`
  }


  /**
* @returns {boolean} returns true if the user has the modifyBasicFieldUser permission, false in other case
* @description Verifies if the user has the permission to update user's basic fields
*/
  get canEditBasicFields(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.administration.module,
      this.permission.administration.modifyBasicFieldsUser
    );
  }

}
