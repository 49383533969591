<div class="container modal-body wrapper-modal position-relative">
    <p class="confirm_icon confirm_icon-error">
        <img src="/assets/svg/icons/icon-error-purple.svg" alt="">
    </p>
    <p *ngIf="data?.isDriverCreationEdition" class="title">Aceptación de información requerida para el documento {{data?.document}}</p>
    <p *ngIf="!data?.isDriverCreationEdition" class="title">¡No se han encontrado resultados para {{data?.document}}!</p>
    <div class="description">
        Para continuar con la creación, por favor complete los siguientes datos para solicitar la aprobación al
        conductor de:
        <ol>
            <li>Términos y Condiciones</li>
            <li>Política de Tratamiento de Datos Personales</li>
            <li>Otros documentos legales que puedan ser requeridos para la manipulación, consulta y almacenamiento de
                los datos.</li>
        </ol>
        Una vez el conductor los acepte, podrás continuar con el registro.
    </div>

    <form [formGroup]="form" (submit)="onSubmit()">
        <mat-form-field appearance="outline" class="col-md-6 col-sm-12">
            <mat-label>Nombre Completo<span class="asterisk">*</span></mat-label>
            <input matInput type="text" name="name" formControlName="name">
            <mat-error *ngIf="form.get('name')?.errors?.required">
                {{ utils.giveMessageError('MISSING_FIELD', 'nombre') }}
            </mat-error>
            <mat-error *ngIf="form.get('name')?.errors?.pattern">
                {{ utils.giveMessageError('INCORRECT_FORMAT', 'nombre') }}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-md-6 col-sm-12">
            <mat-label>Celular / WhatsApp<span class="asterisk">*</span></mat-label>
            <input matInput type="tel" name="phone" formControlName="phone">
            <mat-error *ngIf="form.get('phone')?.errors?.required">
                {{ utils.giveMessageError('MISSING_FIELD', 'celular') }}
            </mat-error>
            <mat-error *ngIf="form.get('phone')?.errors?.pattern">
                {{ utils.giveMessageError('INCORRECT_FORMAT', 'celular') }}
            </mat-error>
            <mat-error *ngIf="form.get('phone')?.errors?.duplicatedPhone">
                El número de celular ya está asociado a alguien
            </mat-error>
        </mat-form-field>

        <div class="text-center">
            <button mat-raised-button color="primary" type="button" class="primary-secondary"
                (click)="close()">Cancelar</button>
            &nbsp;
            <button mat-raised-button color="accent" type="submit"
                [disabled]="form.invalid || form.untouched">Solicitar</button>
        </div>
    </form>
</div>