<div class="container">
  <div id="mainFreightValue" class="col-md-12 mb-md-0 mb-12">
    <div class="row">
      <!-- Title -->
      <div class="col-md-12 mb-md-0 mb-12">
        <label class="title">
          Ingresa el valor del servicio
        </label>
      </div>
      <!-- Boxs -->
      <div class="container">
        <div class="col-md-12 mb-md-0 mb-12 wraper-additional-service">
          <mat-form-field>
            <mat-label>
              Valor de la Tarifa<span class="asterisk">*</span>
            </mat-label>
            <input matInput type="text" currencyMask min="0" placeholder="$000.000"
              [formControl]="manualCreationCargoService.formShippingCost.controls.rate">
            <i matSuffix class="fas fa-info-circle color-secondary-dark"
              matTooltip="Hace referencia al valor que {{companyName}} cobra al CLIENTE"
              matTooltipClass="tooltip__alert" matTooltipPosition="right"></i>
            <mat-error *ngIf="manualCreationCargoService.formShippingCost.controls.rate.errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-error *ngIf="manualCreationCargoService.formShippingCost.controls.rate.errors?.min">
              {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'tarifa', minRateCost) }}
            </mat-error>
            <mat-hint currencyMask class="color-secondary-dark text-bold">
              $ {{ValueAdditionalCostRate | currency:'COP':'code' }} en servicios adicionales
            </mat-hint>
          </mat-form-field>
          <mat-form-field class="mt-4">
            <mat-label>
              Valor del flete<span class="asterisk">*</span>
            </mat-label>
            <input matInput type="text" currencyMask placeholder="$000.000"
              [formControl]="manualCreationCargoService.formShippingCost.controls.freightCost">
            <i matSuffix class="fas fa-info-circle color-secondary-dark"
              matTooltip="Hace referencia al valor que se paga al CONDUCTOR" matTooltipClass="tooltip__alert"
              matTooltipPosition="right"></i>
            <mat-error *ngIf="manualCreationCargoService.formShippingCost.controls.freightCost.errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-error *ngIf="manualCreationCargoService.formShippingCost.controls.freightCost.errors?.min">
              {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'flete', minRateCost) }}
            </mat-error>
            <mat-hint currencyMask class="color-secondary-dark text-bold">
              $ {{ValueAdditionalCost | currency:'COP':'code' }} en servicios adicionales
            </mat-hint>
          </mat-form-field>

          <div class="alert alert-danger col-md-12 mb-md-0 d-flex flex-row align-items-center mt-4" role="alert"
            *ngIf="utilityCargo < manualCreationCargoService.getUtilityOwner() && !hasPermissionNoMinimumUtility && !isAutomaticTotalCostCalculation">
            <i class="fas fa-exclamation-circle fa-lg mr-3"></i> <span class="text-bold">La utilidad no debe ser
              menor a {{manualCreationCargoService.getUtilityOwner()}}%</span>
          </div>

          <div class="alert alert-warning col-md-12 mb-md-0 d-flex flex-row align-items-center mt-4" role="alert"
            *ngIf="utilityCargo > 50 && manualCreationCargoService.formShippingCost.controls.freightCost.value > 0 && !isAutomaticTotalCostCalculation">
            <i class="fas fa-exclamation-circle fa-lg mr-3"></i> <span class="text-bold">La utilidad es mayor al
              50%</span>
          </div>
          <mat-form-field class="mt-4">
            <mat-label>
              Porcentaje de anticipo<span class="asterisk">*</span>
            </mat-label>
            <input matInput type="number" onlyNumbersInt
              [formControl]="manualCreationCargoService.formShippingCost.controls.advancePercentage">
            <i class="fas fa-info-circle color-secondary-dark" matSuffix
              [matTooltip]="messageAdvancePercentageAllowed"></i>
            <mat-error *ngIf="manualCreationCargoService.formShippingCost.controls.advancePercentage.errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-error *ngIf="manualCreationCargoService.formShippingCost.controls.advancePercentage.errors?.min">
              {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'porcentaje de anticipo', 0) }}
            </mat-error>
            <mat-error *ngIf="manualCreationCargoService.formShippingCost.controls.advancePercentage.errors?.max">
              {{ utils.giveMessageError('MAX_VALUE_EXCEED', 'porcentaje de anticipo',
              maxAdvancePercentage )}}
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Tiempo de pago del saldo (en días)<span class="asterisk">*</span></mat-label>
            <mat-select
              [formControl]="manualCreationCargoService.cargoForm.controls.shippingCost['controls'].paymentTime">
              <mat-option *ngFor="let paymentTime of paymentTimeOptions | keyvalue" [value]="paymentTime.value"
                (click)="othersSelected(false)">
                {{ paymentTime.key }}
              </mat-option>
              <mat-option [value]="otherFreightCost.value" (click)="othersSelected(true)">
                Otro
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="standard" *ngIf="showOtherFreightCost">
            <mat-label>Otro valor (en días)<span class="asterisk">*</span></mat-label>
            <input matInput type="number" min="0" step="1" placeholder="valor" [formControl]="otherFreightCost"
              oninput="this.value=(parseInt(this.value)||0)">
            <mat-error *ngIf="otherFreightCost.errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-error *ngIf="otherFreightCost.errors?.min">
              {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'valor', 0) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="col-12" permission-display [module]="permission.cargo.module"
        [functionality]="permission.cargo.editTravelExpenses">
        <app-travel-expenses [form]="manualCreationCargoService.travelExpensesForm"></app-travel-expenses>
      </div>
      <!-- Button -->
      <div class="col-12 button-container justify-content-center mt-3">
        <button mat-raised-button class="secondary-sharp mr-4" (click)="stepBack()">Atrás</button>
        <app-request-button></app-request-button>
        <button mat-raised-button color="primary" class="bg-color-primary" (click)="nextStep()">Siguiente</button>
      </div>
    </div>
  </div>
</div>