import { Observable } from "rxjs";
import { DriverLicenseCategory } from "../interfaces/driverLicenseCategory";
import { Utils } from "../resources/utils";
import { Injectable } from "@angular/core";
import { ServiceMessages } from "../messages/service-messages.enum";

@Injectable()
export class LicenseDriverManager {

  constructor(
    public utils: Utils
  ) {

  }

  public async licenseActiveForTripType(driverLicenseCategory: DriverLicenseCategory[], tripTypeName: string, allowCreatingWithoutTruora?: boolean): Promise<{ active: boolean, category: string }> {
    if (allowCreatingWithoutTruora) return Promise.resolve({ active: true, category: "" });
    return new Promise((resolve, reject) => {
      let response: { active: boolean, category: string } = {
        category: null,
        active: false
      };
      const keyCategory: string = this.getKeyCategoryByTripType(tripTypeName);
      try {
        if (!this.utils.isEmpty(driverLicenseCategory) && !this.utils.isEmpty(keyCategory)) {
          const filter = driverLicenseCategory.filter((category: DriverLicenseCategory) => (category.category.toLowerCase().search(keyCategory) >= 0) && !!category.active);
          response.active = filter.length > 0;
          response.category = keyCategory.toUpperCase();
          if (!response.category) throw new Error(ServiceMessages.NOT_CATEGORY_OR_LICENSE_PLATE);
        } else throw new Error(ServiceMessages.NOT_CATEGORY_OR_LICENSE_PLATE);
        resolve(response);
      } catch (e) {
        console.error(e);
        reject(ServiceMessages.NOT_CATEGORY_OR_LICENSE_PLATE);
      }
    });
  }

  public async licenseActiveForVehicleType(driverLicenseCategory: DriverLicenseCategory[], vehicleType: string, allowCreatingWithoutTruora?: boolean): Promise<{ active: boolean, category: string }> {
    if (allowCreatingWithoutTruora) return Promise.resolve({ active: true, category: "" });
    return new Promise((resolve, reject) => {
      let response: { active: boolean, category: string } = {
        category: null,
        active: false
      };
      const keyCategory: string = vehicleType === 'MOTOCICLETA' ? "a" : "b";
      try {
        if (!this.utils.isEmpty(driverLicenseCategory) && !this.utils.isEmpty(keyCategory)) {
          const filter = driverLicenseCategory.filter((category: DriverLicenseCategory) => (category.category.toLowerCase().search(keyCategory) >= 0) && !!category.active);
          response.active = filter.length > 0;
          response.category = keyCategory.toUpperCase();
          if (!response.category) throw new Error(ServiceMessages.NOT_CATEGORY_OR_LICENSE_PLATE);
        } else throw new Error(ServiceMessages.NOT_CATEGORY_OR_LICENSE_PLATE);
        resolve(response);
      } catch (e) {
        console.error(e);
        reject(ServiceMessages.NOT_CATEGORY_OR_LICENSE_PLATE);
      }
    });
  }

  private getKeyCategoryByTripType(tripTypeName: string): string {
    const keysTripType = {
      'Importación': 'c',
      'Exportación': 'c',
      'Nacional': 'c',
      'Internacional': 'c',
      'Contenedor vacío': 'c',
      'Urbana': 'b',
      'Última milla': 'c'
    };
    return keysTripType[tripTypeName];
  }

}


/*
si es nacional, contenefores, impos expos, debe tener minimo una C activa
si es urbana b
si tiene a b c y es nacional la carga pues solo necesita c asi b y a esten vencidas

<mat-option value="Importación">
          Importación
        </mat-option>
        <mat-option value="Exportación">
          Exportación
        </mat-option>
        <mat-option value="Nacional">
          Nacional
        </mat-option>
        <mat-option value="Urbana">
          Urbana
        </mat-option>
        <mat-option value="Contenedor vacío">
          Contenedor vacío
        </mat-option>

*/
