<mat-sidenav-container class="example-container sidenav-container p-0">
  <mat-sidenav #sidenav [mode]="'over'" class="sidenav-content-in row" position="end">
    <div>
      <i class="fas fa-times fa-2xl mb-4" title="Cerrar" (click)="sidenav.toggle()"></i>
    </div>

    <div class="title-more-filters-component no-margin col-12">
      <i class="fas fa-filter"></i>
      Más filtros
    </div>

    <form [formGroup]="filterParams" class="col-12 fields-container">
      <mat-form-field class="col-12">
        <mat-label>Estado</mat-label>
        <mat-select formControlName="turnState">
          <mat-option *ngFor="let state of state" [value]="state.value">
            {{state.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-12">
        <mat-label>Tipo de vehículo</mat-label>
        <mat-select formControlName="vehicleType" (selectionChange)="onVehicleTypeSelected($event)">
          <mat-option *ngFor="let vehicle of vehicles" [value]="vehicle">
            {{ vehicle.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-12">
        <mat-label>Tipo de carrocería</mat-label>
        <mat-select formControlName="bodyWorkType">
          <mat-option *ngFor="let bodyWork of bodyWorkList" [value]="bodyWork.name">
            {{ bodyWork.name }}
          </mat-option>
        </mat-select>
        <mat-hint class="custom-hint" *ngIf="!bodyWorkList || filterParams.get('vehicleType').value === null">Por favor
          seleccione un tipo vehículo para poder seleccionar un tipo de carrocería</mat-hint>
      </mat-form-field>
      <mat-form-field class="col-12 mt-3">
        <mat-label>Capacidad en volumen</mat-label>
        <input (keyup)="controlChanges('volume')" matInput mask="separator.0" thousandSeparator="."
          placeholder="Por ej. 100" formControlName="loadCapacityVolume">
        <mat-hint class="custom-hint">Valores de volumen en metros cúbicos</mat-hint>
      </mat-form-field>
      <mat-form-field class="col-12">
        <mat-label>Capacidad</mat-label>
        <input (keyup)="controlChanges('weight')" matInput mask="separator.0" thousandSeparator="."
          placeholder="Por ej. 5000" formControlName="loadCapacityWeight">
        <mat-hint class="custom-hint">Valores en kilos</mat-hint>
      </mat-form-field>
      <mat-form-field class="col-12">
        <mat-label>Teléfono de usuario</mat-label>
        <input matInput onlyNumbersInt placeholder="Por ej. 10203040" formControlName="cellphone" autocomplete="off"
          spellcheck="false">
      </mat-form-field>
    </form>

    <div class="col-12 d-flex buttons-container">
      <div class="btn-action d-flex justify-content-center">
        <button mat-raised-button class="primary-secondary m-0 w-100" color="primary" (click)="cleanFilter()"
          matTooltip="Eliminar Filtros">
          <span class="d-none d-sm-inline">Borrar</span>
          <i class="fas fa-trash d-sm-none"></i>
        </button>
      </div>
      <div class="d-flex justify-content-center btn-action">
        <button mat-raised-button class="btn-filter m-0 w-100" color="primary" (click)="applyFilter()"
          matTooltip="Aplicar Filtros">
          <span class="d-none d-sm-inline">Aplicar</span>
          <i class="fas fa-filter d-sm-none"></i>
        </button>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content class="sidenav-content-out h-100">
    <div class="interactive-turn-container d-flex flex-column align-items-center">
      <div class="container-create d-flex flex-row align-items-center justify-content-between">
        <p class="title-component">Enturnamiento</p>
        <button permission-display [module]="permission.cargo.module" [functionality]="permission.cargo.turnCreation"
          type="button" class="btn btn-primary" (click)="Create()">Crear enturnamiento</button>
      </div>
      <div class="container-list-filter align-self-start">
        <app-turn-filter (clearFilters)="clearFilters($event)" [filterParams]="filterParams"
          [cleanFilters]="cleanFilters" [applyFilters]="applyFilters" [filledInputs]="filledInputs"
          (onFilter)="onFilter($event)" (emitDates)="datesEmitted($event)"
          (showMoreFilters)="showMoreFilters($event)"></app-turn-filter>
      </div>
    </div>
    <aside class="col-md-12 mb-md-0 mb-12 pl-4 aside-container-list">
      <div id="mainListFreightList" class="container-list h-100">
        <!--  <div class="container-list-filter">
          <app-cargo-list-filter [itemsSelecteds]="itemsSelecteds"
            *ngIf="typeList !== 'loadsInNegotiation' && typeList !== 'cargoRequestNegotiation'" [options]="optionsFilter"
            [typeList]="typeList" (onFilter)="onFilter($event)" (refreshList)="refreshList($event)">
          </app-cargo-list-filter>
        </div> -->
        <!-- <div class="container-create mt-2 pr-3">
          <button type="button" class="btn btn-primary text-bold" (click)="Create()">Crear enturnamiento</button>
        </div> -->
        <!-- <div class="container-list-filter">
          <app-turn-filter (clearFilters)="clearFilters($event)" [filterParams]="filterParams" [cleanFilters]="cleanFilters" [applyFilters]="applyFilters" (onFilter)="onFilter($event)" (emitDates)="datesEmitted($event)" (showMoreFilters)="showMoreFilters($event)"></app-turn-filter>
        </div> -->
        <div *ngIf="service.paginationList.getList().length" class="container-list-body" infinite-scroll
          [infiniteScrollDistance]="service.paginationList.getScrollDistance()"
          [infiniteScrollThrottle]="service.paginationList.getThrottle()" [scrollWindow]="false"
          (scrolled)="onScrollDown()">
          <div class="mb-md-0 mb-12" *ngFor="let enturnamiento of service.paginationList.getList()">
            <app-cargo-item-turn [enturnamiento]="enturnamiento" [listPagination]="service.paginationList.getList()"
              (applyFilter)="onFilter($event)" (refreshListTurn)="refreshListTurn($event)" (refreshList)="refreshList()"
              [filteredCargosTurns]="getFilteredCargo(enturnamiento.id, enturnamiento.origin.id, enturnamiento.date, enturnamiento.vehicleType)">
            </app-cargo-item-turn>
          </div>
        </div>



        <div *ngIf="!service.paginationList.getList().length"
          class="d-flex align-items-center justify-content-center flex-column h-100">
          <i class="fas fa-calendar-times mb-4"></i>
          <h4 class="text-bold text-center">No hay resultados que coincidan con la búsqueda</h4>
        </div>
        <div class="d-flex justify-content-center align-items-center mt-2 mb-2 conventions-container">
          <div class="text-bold">
            Convenciones:
          </div>
          <div>
            <i class="ml-2 mr-2 fas fa-circle" style="color: #198754"></i>Disponibilidad > 1 día de la fecha actual
          </div>
          <div>
            <i class="ml-2 mr-2 fas fa-circle" style="color: #FFC107"></i>Disponibilidad el mismo dia de la fecha
            actual
          </div>
          <div>
            <i class="ml-2 mr-2 fas fa-circle" style="color: #DC3545"></i>Disponibilidad < 1 día de la fecha actual
              </div>
          </div>
        </div>
    </aside>
  </mat-sidenav-content>
</mat-sidenav-container>