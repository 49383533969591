import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from "@angular/core";
import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { FreightListService } from 'src/app/modules/cargo/cargo-list/cargo-list.service';
import { Catalog } from '../interfaces/catalog';
import { CatalogItem } from '../interfaces/catalogItem';
import { City } from '../interfaces/city';
import { Company } from '../interfaces/company';
import { Endpoints } from '../resources/endpoints';
import { Global } from '../resources/global';
import { Utils } from '../resources/utils';
import { environment } from './../../../environments/environment'
import { AuthService } from './authentication.service';
import { AmountsCargoEnum } from '../enums/amountsCargo.enum';
import { AccountService } from 'src/app/modules/account/account.service';

@Injectable({ providedIn: 'root' })
export class CompanyService {
  constructor(
    private authService: AuthService,
    public utils: Utils,
    private freightListService: FreightListService,
    private accountService: AccountService
  ) { }

  public getListCompaniesOwners(): Observable<Company[]> {
    let listCompanies: Company[] = [];
    return new Observable((observer: Observer<Company[]>) => {
      if (this.authService.getCompany() && this.authService.getCompany().subCompanies) {
        listCompanies = this.authService.getCompany().subCompanies.map((company) => {
          return {
            businessName: company.description,
            nit: company.code,
            exclusive: this.authService.getCompany().exclusive,
            ministry: this.authService.getCompany().ministry,
            utility: this.authService.getCompany().utility,
            utilityAdditionalService: this.authService.getCompany().utilityAdditionalService,
            advancePercentage: this.authService.getCompany().advancePercentage || (this.authService.getCompany() && this.authService.getCompany().companyId && (this.authService.getCompany().companyId === environment.rootNit) ? AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_TECLOGI : AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_ALL_COMPANIES),
          };
        });
        listCompanies.unshift({
          name: this.authService.getCompany().name,
          companyId: this.authService.getCompany().companyId,
          exclusive: this.authService.getCompany().exclusive,
          ministry: this.authService.getCompany().ministry,
          utility: this.authService.getCompany().utility,
          utilityAdditionalService: this.authService.getCompany().utilityAdditionalService,
          advancePercentage: this.authService.getCompany().advancePercentage || (this.authService.getCompany() && this.authService.getCompany().companyId && (this.authService.getCompany().companyId === environment.rootNit) ? AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_TECLOGI : AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_ALL_COMPANIES),
        });
      } else if (this.authService.getUserSession().clientCompanyId === environment.rootNit) {
        this.freightListService.getAllCompanies().subscribe(
          (success: Company[]) => {
            listCompanies = success.map((company: Company) => {
              return {
                name: company.name,
                companyId: company.companyId,
                exclusive: company.exclusive,
                ministry: this.utils.isDefined(company.ministry) ? company.ministry : false,
                siigoId: company.siigoId ? company.siigoId : null,
                utility: company.utility,
                utilityAdditionalService: company.utilityAdditionalService,
                advancePercentage: company.advancePercentage || (this.authService.getCompany() && this.authService.getCompany().companyId && (this.authService.getCompany().companyId === environment.rootNit) ? AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_TECLOGI : AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_ALL_COMPANIES),
              };
            });
            observer.next(listCompanies);
          },
          (error) => {
            observer.error(error);
          }
        );
      } else {
        listCompanies = [{
          name: this.authService.getCompany().name,
          companyId: this.authService.getCompany().companyId,
          exclusive: this.authService.getCompany().exclusive,
          ministry: this.utils.isDefined(this.authService.getCompany().ministry) ? this.authService.getCompany().ministry : false,
          utility: this.authService.getCompany().utility,
          utilityAdditionalService: this.authService.getCompany().utilityAdditionalService,
          advancePercentage: this.authService.getCompany().advancePercentage || (this.authService.getCompany() && this.authService.getCompany().companyId && (this.authService.getCompany().companyId === environment.rootNit) ? AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_TECLOGI : AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_ALL_COMPANIES),
        }];
      }
      observer.next(listCompanies);
    });
  }

  public get holderCompany(): Company {
    return this.authService.getCompanySaaS();
  }
}
