<div class="mt-3">
    <a class="btn-back text-bold" href="/#/cargo/transport-requests">
        <i class="fa fa-arrow-left"></i> Solicitudes
    </a>

    <div class="vehicle-transport">
        <div class="vehicle-transport__header" *ngIf="request">
            <div class="vehicle-transport__column">
                <h6 class="vehicle-transport__column-title">ID</h6>
                <p class="vehicle-transport__column-text">{{request.consecutive}}</p>
            </div>
            <div class="vehicle-transport__column">
                <h6 class="vehicle-transport__column-title">Contratante</h6>
                <p class="vehicle-transport__column-text">{{request.cargoOwner?.name || '-'}}</p>
            </div>
            <div class="vehicle-transport__column">
                <h6 class="vehicle-transport__column-title">Origen</h6>
                <p class="vehicle-transport__column-text">{{request.cargoFeature?.uploadDownload?.origin?.name || '-'}}
                </p>
            </div>
            <div class="vehicle-transport__column">
                <h6 class="vehicle-transport__column-title">Fecha de cargue</h6>
                <p class="vehicle-transport__column-text">{{request.dateLoad | dateFormat:'only-date'}}</p>
            </div>
            <div class="vehicle-transport__column">
                <h6 class="vehicle-transport__column-title">Destino</h6>
                <p class="vehicle-transport__column-text">{{request.cargoFeature?.uploadDownload?.destination[0]?.name
                    || '-'}}
                </p>
            </div>
            <div class="vehicle-transport__column">
                <h6 class="vehicle-transport__column-title">Fecha de descargue</h6>
                <p class="vehicle-transport__column-text">
                    {{request.cargoFeature.uploadDownload.destination[0].downloadDate | dateFormat:'only-date'}}</p>
            </div>
            <div class="vehicle-transport__column">
                <h6 class="vehicle-transport__column-title">Flete</h6>
                <p class="vehicle-transport__column-text">{{request.shippingCost?.totalCost | currency:'COP':'code' ||
                    '-'}}
                </p>
            </div>
            <div class="vehicle-transport__column">
                <h6 class="vehicle-transport__column-title">Observaciones SAC</h6>
                <p class="vehicle-transport__column-text">{{request.observation}}</p>
            </div>
        </div>
        <div class="vehicle-transport__body">
            <div class="table-responsive">
                <table>
                    <thead>
                        <tr>
                            <th>
                                <div>Fecha de postulación</div>
                            </th>
                            <th>
                                <div>Placa</div>
                            </th>
                            <th>
                                <div>Score estudio de seguridad</div>
                            </th>
                            <th>
                                <div>Número de identificación</div>
                            </th>
                            <th>
                                <div>Número de viajes con la empresa</div>
                            </th>
                            <th>
                                <div>Número de viajes RNDC</div>
                            </th>
                            <th>
                                <div>Negociación de flete</div>
                            </th>
                            <th>
                                <div>Score dado por las empresas</div>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let result of responseList.results">
                            <td data-cell="Fecha de postulación">
                                <div>
                                    <span>{{result.fingerprint.date | dateFormat:'only-date'}}</span>
                                </div>
                            </td>
                            <td data-cell="Placa">
                                <div><span class="underline"
                                        (click)="getDetailVehicle(result)">{{result.licensePlate}}</span></div>
                            </td>
                            <td data-cell="Score estudio de seguridad">
                                <div><span>{{result.securityScore}}</span></div>
                            </td>
                            <td data-cell="Número de identificación">
                                <div><span class="underline">{{result.applicantId}}</span></div>
                            </td>
                            <td data-cell="Número de viajes con la empresa">
                                <div><span>{{result.numberOfTripsApp}}</span></div>
                            </td>
                            <td data-cell="Número de viajes RNDC">
                                <div><span>{{result.numberOfTripsRNDC}}</span></div>
                            </td>
                            <td data-cell="Negociación de flete">
                                <div><span>{{result.finalValue | currency:'COP':'code'}}</span></div>
                            </td>
                            <td data-cell="Score dado por las empresas">
                                <div><span>{{result.applicantScore}}</span></div>
                            </td>
                            <td>
                                <div class="vehicle-transport__actions">
                                    <span *ngIf="!request.assignedDriver && result.state === 'Created'">
                                        <button mat-raised-button class="primary-secondary" color="primary"
                                            type="button" (click)="onRefuse(result)">
                                            Rechazar
                                        </button>

                                        <button mat-raised-button class="primary" color="primary" type="button"
                                            (click)="onAccepted(result)">
                                            Asignar
                                        </button>
                                    </span>
                                    <span class="vehicle-transport__actions-state" *ngIf="result.state !== 'Created'">
                                        {{result.state === 'Accepted' ? 'Aceptado' : 'Rechazado'}}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="!responseList.results?.length" class="vehicle-transport__watermark">
                <i class="fas fa-info-circle"></i>
                No hay postulaciones
            </div>
        </div>
    </div>
</div>