<div class="p-3 position-relative">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <figure class="container-icon-correct" *ngIf="isRequest">
        <img src="/assets/svg/icons/icon-correct-purple.svg" alt="">
    </figure>
    <figure class="container-icon-correct" *ngIf="!isRequest">
        <img src="/assets/svg/icons/icon-error-purple.svg" alt="">
    </figure>
    <div class="col-md-12 text-center">
        <h1 mat-dialog-title class="mb-1">
            {{isRequest?'¿Deseas solicitar el pago de anticipo para el servicio ' :'¿Deseas cancelar la solicitud del
            pago
            de anticipo para el servicio ' }}<b class="title-purple">{{cargo?.consecutive}}</b> ?
        </h1>
        <p *ngIf="paramsDialog.description" class="color-primary-dark">
            Anticipo {{ paramsDialog.description }}
        </p>
    </div>
    <div class="col-12 d-flex justify-content-center">
        <div class="col-md-8 d-flex flex-column">
            <div class="d-flex justify-content-between label">
                <div>Origen</div>
                <div class="text-bold">{{cargo?.cargoFeature?.uploadDownload?.origin?.name ?
                    cargo.cargoFeature.uploadDownload.origin.name:
                    '-'}}</div>
            </div>
            <div class="d-flex justify-content-between">
                <div>Destino</div>
                <div class="text-bold">
                    {{cargo?.cargoFeature?.uploadDownload?.destination[cargo?.cargoFeature?.uploadDownload?.destination?.length
                    -1]?.name ?
                    cargo.cargoFeature.uploadDownload.destination[cargo.cargoFeature.uploadDownload.destination.length -
                    1].name: '-'}}
                </div>
            </div>
            <div class="d-flex justify-content-between">
                <div>Placa</div>
                <div class="text-bold">{{cargo?.licensePlate ? cargo.licensePlate: '-'}}</div>
            </div>
            <div class="d-flex justify-content-between">
                <div>Conductor</div>
                <div class="text-bold">{{cargo?.driver ? cargo.driver: '-'}}</div>
            </div>
            <div class="d-flex justify-content-between"
                *ngIf="cargo?.shippingCost?.advancePercentage && cargo?.shippingCost?.valueAdvance">
                <div>Valor del anticipo</div>
                <div class="text-bold title-purple">
                    {{cargo.shippingCost.valueAdvance | currency :'COP':'code'}}
                    ({{cargo.shippingCost.advancePercentage}}%)
                </div>
            </div>
        </div>
    </div>
    <div class="button-container my-3">
        <button type="button" (click)="closeDialog()" class="button-width-auto primary-secondary" mat-raised-button>
            Cancelar
        </button>
        <button mat-raised-button class="button-width-auto bg-color-primary" color="primary" (click)="requestAdvance()">
            Confirmar
        </button>
    </div>
</div>