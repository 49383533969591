<div class="routing__modal--content position-relative py-4 px-4 px-sm-5">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <div class="routing__modal--header">
        <h3 class="routing__modal--title pr-5 text-bold">{{scenario?.response?.description | titlecase}}</h3>
        <div class="alert text-center text-bold text-break" [ngClass]="{
                'alert-success':isReadyToCreateCargos,
                'alert-danger':isBlocked,
                'alert-primary':isReadyToBeOptimized, 
                'alert-dark':!isReadyToCreateCargos && !isBlocked && !isReadyToBeOptimized
            }">
            {{scenarioState}}
        </div>
    </div>

    <div class="routing__modal--body row">
        <div class="col-sm-6" *ngIf="scenario?.response?.schemaName">
            <p class="text-bold mb-1">Esquema</p>
            <p class="mb-0">{{scenario?.response?.schemaName}}</p>
        </div>
        <div class="col-sm-6" *ngIf="scenario?.response?.deployDate">
            <p class="text-bold mb-1">Fecha de creación</p>
            <p class="mb-0">{{scenario?.response?.deployDate | dateFormat: "only-date"}}</p>
        </div>
    </div>

    <div class="routing__modal--footer button-container">
        <button mat-raised-button color="primary" type="button" class="bg-color-primary" (click)="goToDetail()"
            *ngIf="isReadyToCreateCargos">
            Crear servicios
        </button>
        <button mat-raised-button color="primary" type="button" class="bg-color-primary" (click)="optimize()"
            *ngIf="isReadyToBeOptimized">
            Optimizar
        </button>
    </div>

</div>