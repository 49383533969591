<aside id="mainCreateCargoFromGuides" class="col-md-12 mb-md-0 mb-12 h-100-margin">
  <div class="container-list h-100-margin">
    <div class="container-list-filter">
      <div class="row">
        <div class="col-md-12 align-right filter-actions">
          <mat-card-actions>
            <button mat-raised-button color="primary" class="button-create-cargo" (click)="createCargo()">Crear
              servicio</button>
          </mat-card-actions>
        </div>
      </div>
    </div>
    <div class="container-list-body">
      <mat-tab-group>
        <mat-tab label="{{tab.label}}" *ngFor="let tab of tabsGuides">
          <app-list-cargo-from-guides [state]="tab.state" (onSelectGuideEvent)="onSelectGuide($event)">
          </app-list-cargo-from-guides>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</aside>