<div class="mb-3 mx-3 p-0 vehicle" *ngIf="vehicle">
  <div class="vehicle__container">
    <div class="vehicle__content row py-4 w-100 m-0">
      <div class="col-12 col-xl-3 d-flex justify-content-center align-items-center p-0 pr-xl-2">
        <div class="row p-0 m-0 h-100 w-100 pr-0 pr-xl-2">
          <div class="col-12 col-xl-5 p-0 d-flex justify-content-center vehicle__icon pr-0 pr-xl-3">
            <img src="{{vehicle?.typeVehiclePath ? vehicle.typeVehiclePath : ''}}" alt="truck-icon">
          </div>
          <div class="col-12 col-xl-7 d-flex flex-column justify-content-center p-0 vehicle__characteristics w-100">
            <!-- License plate -->
            <div class="vehicle__characteristics--license-plate d-flex flex-column justify-content-center p-0 pb-3">
              <span class="vehicle__characteristics--license-plate--title">
                Placa
              </span>
              <span class="text-bold vehicle__characteristics--license-plate--value color-secondary-dark" (click)="openDetailVehicle(vehicle.id)">
                {{ vehicle?.id ? vehicle.id : '-' }}
              </span>
            </div>
            <!-- Vehicle type -->
            <div class="vehicle__characteristics--type d-flex flex-column justify-content-center p-0">
              <span class="vehicle__characteristics--type--title">
                Tipo de vehículo
              </span>
              <span class="text-bold color-primary-dark vehicle__characteristics--type--value">
                {{ vehicle?.vehicleType?.name ? vehicle.vehicleType.name : '-' }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6 vehicle__info p-0 pl-3">
        <div class="row vehicle__detail m-0 h-100">
          <!-- License plate -->
          <div class="col-12 col-md-6 vehicle__license-plate d-flex flex-column justify-content-center p-0 pb-3 d-inline d-xl-none">
            <span class="vehicle__license-plate--title">
              Placa
            </span>
            <span class="text-bold vehicle__license-plate--value color-secondary-dark" (click)="openDetailVehicle(vehicle.id)">
              {{ vehicle?.id ? vehicle.id : '-' }}
            </span>
          </div>

          <!-- Vehicle type -->
          <div class="col-12 col-md-6 vehicle__type d-flex flex-column justify-content-center p-0 d-inline d-xl-none">
            <span class="vehicle__type--title">
              Tipo de vehículo
            </span>
            <span class="text-bold color-primary-dark vehicle__type--value">
              {{ vehicle?.vehicleType?.name ? vehicle.vehicleType.name : '-' }}
            </span>
          </div>

          <!-- Preventive maintenance -->
          <div class="col-12 col-md-6 vehicle__preventive-maintenance d-flex flex-column justify-content-center p-0 pb-3">
            <span class="vehicle__preventive-maintenance--title">
              Mantenimiento preventivo
            </span>
            <span class="text-bold color-primary-dark vehicle__preventive-maintenance--value">
              {{ this.vehicle?.preventiveExpirationDate ? this.getCalculateMaintenancePeriod(this.vehicle.preventiveExpirationDate) <= 0 ? 'Vencido' : 'Vigente' : '-' }}
            </span>
          </div>
          <!-- Last preventive registration -->
          <div class="col-12 col-md-6 vehicle__last-preventive-registration d-flex flex-column justify-content-center p-0 pb-3">
            <span class="vehicle__last-preventive-registration--title">
              Último registro preventivo
            </span>
            <span class="text-bold color-primary-dark vehicle__last-preventive-registration--value">
              {{ vehicle?.preventiveExecutionDate ? getMaintenanceDatesFormatted(vehicle.preventiveExecutionDate) : '-' }}
            </span>
          </div>

          <!-- Preventive maintenance -->
          <div class="col-12 col-md-6 vehicle__preventive-maintenance d-flex flex-column justify-content-center p-0 pb-3">
            <span class="vehicle__preventive-maintenance--title">
              Mantenimiento correctivo
            </span>
          </div>

          <!-- Last corrective registration -->
          <div class="col-12 col-md-6 vehicle__last-corrective-registration d-flex flex-column justify-content-center p-0">
            <span class="vehicle__last-corrective-registration--title">
              Último registro correctivo
            </span>
            <span class="text-bold color-primary-dark vehicle__last-corrective-registration--value">
              {{ vehicle?.correctiveExecutionDate ? getMaintenanceDatesFormatted(vehicle.correctiveExecutionDate) : '-' }}
            </span>
          </div>
        </div>

      </div>
      <div class="col-12 col-xl-3 button-container vehicle__actions p-0">
        <button
        permission-display
        [module]="permission.administration.module"
        [functionality]="permission.administration.maintenanceDetail"
        mat-raised-button
        class="primary vehicle__actions--maintenance-detail"
        color="primary"
        (click)="openMaintenanceDetail(vehicle)">
          <span>Detalle de mantenimiento</span>
        </button>
        <button
        permission-display
        [module]="permission.administration.module"
        [functionality]="permission.administration.createMaintenance"
        mat-raised-button
        class="m-0 primary-secondary vehicle__actions--vehicle-detail"
        color="primary"
        (click)="createMaintenance()">
          <div><i class="fas fa-plus-circle mr-2 d-none d-sm-inline"></i><span>Registrar mantenimiento</span></div>
        </button>
      </div>
    </div>
  </div>
</div>
