import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { MaintenanceDetail } from 'src/app/core/interfaces/maintenance-detail';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { MaintenanceEvidencesComponent } from '../maintenance-evidences/maintenance-evidences.component';
import { DateManager } from 'src/app/core/managers/date.manager';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { MaintenanceService } from '../maintenance.service';
import { CreateEditMaintenanceComponent } from '../create-edit-maintenance/create-edit-maintenance.component';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { DateEnum } from 'src/app/core/enums/date.enum';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
@Component({
  selector: 'app-maintenance-item-detail',
  templateUrl: './maintenance-item-detail.component.html',
  styleUrls: ['./maintenance-item-detail.component.scss']
})
export class MaintenanceItemDetailComponent implements OnInit {
  // Inputs
  @Input() maintenance: MaintenanceDetail;
  @Input() vehicle: Vehicle;
  // Outputs
  @Output() refreshList: EventEmitter<boolean> = new EventEmitter();
  showObservationAndServices: boolean = false;
  showButton: boolean = false;
  permission = Permission;
  constructor(
    private matDialog: MatDialog,
    private snackbarService: SnackBarService,
    public dialog: MatDialog,
    private maintenanceService: MaintenanceService,
    private spinner: NgxSpinnerService,
    public permissionRole: PermissionRole
  ) { }

  ngOnInit() { }
  toggleText() {
    this.showObservationAndServices = !this.showObservationAndServices;
  }


  openMaintenanceEvidences() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      maintenance: this.maintenance
    };
    dialogConfig.maxHeight = ModalEnum.MEDIUM_HEIGHT;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.SMALL_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(MaintenanceEvidencesComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) this.refreshList.emit(true);
    })
  }

  editMaintenance(maintenance: MaintenanceDetail) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      vehicleId: this.vehicle && this.vehicle.id ? this.vehicle.id : '',
      maintenance: maintenance
    };
    dialogConfig.maxHeight = ModalEnum.MEDIUM_HEIGHT;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(CreateEditMaintenanceComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) this.refreshList.emit(true);
    })

  }

  confirmDelete(maintenanceId: string) {
    const dialogConfig = new MatDialogConfig();
    let title: string = '';
    title = `¿Estas seguro que deseas eliminar el mantenimiento?`;
    dialogConfig.data = {
      title: title,
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) this.deleteMaintenance(maintenanceId);
    });
  }

  deleteMaintenance(maintenanceId: string) {
    const deleteMaintenanceObserver = {
      next: (data: { message: string }) => {
        this.spinner.hide();
        if (data && data.message) {
          this.snackbarService.openSnackBar(ServiceMessages.SUCCESS_DELETE_MAINTENANCE, undefined, 'success');
          this.refreshList.emit(true);
        }
      },
      error: () => {
        this.spinner.hide();
        this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    };
    this.spinner.show();
    this.maintenanceService.deleteMaintenanceVehicle(
      maintenanceId
    ).subscribe(deleteMaintenanceObserver);
  }
  // GETTERS
  getCalculateMaintenancePeriod(expirationDate: string): number {
    return this.maintenanceService.getCalculateMaintenancePeriod(expirationDate);
  }
  getMaintenanceDatesFormatted(date: string): string {
    return DateManager.formatDate(date, DateEnum.YYYY_MM_DD_HH_mm_ZZ, 'DD/MM/YYYY');
  }

  get enableToActions(): boolean {
    return this.permissionRole.hasPermission(this.permission.administration.module, this.permission.administration.editMaintenance) || this.permissionRole.hasPermission(this.permission.administration.module, this.permission.administration.deleteMaintenance);
  }

}
