<div class="resource-container">
    <i *ngIf="srcToRender && !errorLoadResource" class="fas fa-external-link-alt resource-container__external-link" matTooltip="Abrir en una nueva pestaña" (click)="openPreviewResource()"></i>
    <div class="resource-viewer" *ngIf="srcToRender && !errorLoadResource">
        <div *ngIf="!srcToRender?.loaded && srcToRender?.type === 'image'" class="resource-viewer__spinner">
            <i class="fas fa-spinner fa-spin"></i>
        </div>
        <ng-container *ngIf="srcToRender?.type === 'image'; else isPdf">
        <img
            [src]="srcToRender?.src"
            alt="resource"
            class="resource-viewer__image"
            (load)="onImageLoad(srcToRender)"
            (error)="onErrorRenderImage($event)"
            [hidden]="!srcToRender?.loaded">
        </ng-container>
        <ng-template #isPdf>
        <i class="fas fa-file-pdf resource-viewer__pdf-icon"></i>
        </ng-template>
    </div>

    <div *ngIf="errorLoadResource" class="alert alert-danger" role="alert">
        Ha ocurrido un error al cargar el recurso, por favor intenta de nuevo.
    </div>
</div>