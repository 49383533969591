import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceRequests } from 'src/app/core/interfaces/service-requests';
import { DateManager } from 'src/app/core/managers/date.manager';
@Component({
  selector: 'app-service-requests-item',
  templateUrl: './service-requests-item.component.html',
  styleUrls: ['./service-requests-item.component.scss']
})
export class ServiceRequestsItemComponent implements OnInit {
  @Input() serviceRequest: ServiceRequests;
  public dateManager = DateManager;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  selectOffer(serviceRequest: ServiceRequests) {
    this.router.navigate(['cargo/company-offers', serviceRequest.id]);
  }

  openDetailServiceRequest(serviceRequest: ServiceRequests) {
  }

}
