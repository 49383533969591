import { Component, OnInit } from '@angular/core';
import { TokenHandlerService } from './token-handler.service';
import { ErrorResponse } from 'src/app/core/interfaces/error-response';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { MicrosoftTokenResult } from 'src/app/core/interfaces/microsoft-token-result';
import { AuthService } from 'src/app/core/services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';

@Component({
  selector: 'app-token-handler',
  templateUrl: './token-handler.component.html',
  styleUrls: ['./token-handler.component.scss']
})
export class TokenHandlerComponent implements OnInit {

  constructor(
    private tokenHandlerService: TokenHandlerService,
    private snackBarService: SnackBarService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router
  ) { }
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const token = params['token'];
      const flowNumber = params['flow'];
      if (token) this.validateToken(token, flowNumber);
      else {
        this.router.navigate(['/home']);
        this.snackBarService.openSnackBar(FormMessages.NOT_TOKEN, undefined, 'error');
      }
    });
  }

  validateToken(token: string, flowNumber: string) {
    const tokenObserver = {
      next: (res: MicrosoftTokenResult) => {
        this.spinner.hide();
        let email: string = res && res.decoded_token && res.decoded_token.email ? res.decoded_token.email : res.decoded_token.unique_name ? res.decoded_token.unique_name : '';
        if (email) this.createUserSession(email.toLowerCase(), flowNumber);
      },
      error: (err: ErrorResponse) => {
        this.spinner.hide();
        if (err && err.error && err.error.message) this.snackBarService.openSnackBar(err.error.message, undefined, 'error');
        else if (err && err.error && err.error.response) this.snackBarService.openSnackBar(err.error.response, undefined, 'error');
        else this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    };
    this.spinner.show();
    this.tokenHandlerService.validateToken(token).subscribe(tokenObserver);
  }

  createUserSession(email: string, flowNumber: string) {
    const userSessionObserver = {
      next: async (res: { status: string, response: string }) => {
        if (res && res.response) await this.authService.signInWithCustomToken(res.response, email);
        if (flowNumber) sessionStorage.setItem('flowNumber', flowNumber);
        sessionStorage.setItem('signInType', 'redirection');
        this.spinner.hide();
      },
      error: (err: ErrorResponse) => {
        this.spinner.hide();
        if (err && err.error && err.error.message) this.snackBarService.openSnackBar(err.error.message, undefined, 'error');
        else if (err && err.error && err.error.response) this.snackBarService.openSnackBar(err.error.response, undefined, 'error');
        else this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    };
    this.spinner.show();
    this.tokenHandlerService.createUserSession(email).subscribe(userSessionObserver);
  }



}
