import { Component, Input } from '@angular/core';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { EscortedVehicle } from 'src/app/core/interfaces/escortedVehicle';

@Component({
  selector: 'app-driver-escorted-vehicle',
  templateUrl: './driver-escorted-vehicle.component.html',
  styleUrls: ['./driver-escorted-vehicle.component.scss']
})
export class DriverEscortedVehicleComponent {
  @Input() cargo: Cargo;

  get escortedVehicle(): EscortedVehicle {
    if (this.cargo && this.cargo.escortedVehicle)
      return this.cargo.escortedVehicle;
    return null;
  }

  get driver() {
    if (this.escortedVehicle && this.escortedVehicle.driver)
      return this.escortedVehicle.driver;
    return null;
  }

}
