import moment, { CalendarSpec, Moment } from 'moment';
import { DateEnum } from '../enums/date.enum';
import { DateFilterOptions } from '../interfaces/date-filter-options';
import { DateFilter } from '../interfaces/date-filter';

export class DateManager {

  constructor() {
    moment.locale('es');
  }

  /**
  * @param {Date | string} date (Date or string) is the date to use
  * @param {string} format (string - optional) is the format of the date if it is a string (default: 'YYYY-MM-DD HH:mm ZZ')
  * @returns {boolean} (boolean) returns the validity of the date
  * @description Verifies if the date (in format Date or string) is valid
  */
  static isValidDate(date: Date | string, format: string = DateEnum.YYYY_MM_DD_HH_mm_ZZ): boolean {
    return date
      ? typeof date === "string"
        ? moment(date, format).isValid()
        : moment(date).isValid()
      : false;
  }

  /**
  * @param {Date | string} date (Date or string - optional) is the date to use
  * @param {string} format (string - optional) is the format of the date if it is a string (default: 'YYYY-MM-DD HH:mm ZZ')
  * @returns {number} (number) returns the year.
  * @description returns the year(number) of a Date, or the current year if the param "date" is missing
  */
  static getYear(date?: Date | string, format: string = DateEnum.YYYY_MM_DD_HH_mm_ZZ): number {
    if (date) {
      if (!this.isValidDate(date, format)) return -1;
      return typeof date === "string"
        ? moment(date, format).year()
        : moment(date).year();
    }
    return moment(new Date()).year();
  }

  /**
  * @param {Date | string} date (Date or string - optional) is the date to use
  * @param {string} format (string - optional) is the format of the date if it is a string (default: 'YYYY-MM-DD HH:mm ZZ')
  * @returns {number} (number) returns the number of the month.
  * @description returns the month(number) of a Date, or the current month if the param "date" is missing
  */
  static getMonth(date?: Date | string, format: string = DateEnum.YYYY_MM_DD_HH_mm_ZZ): number {
    if (date) {
      if (!this.isValidDate(date, format)) return -1;
      return typeof date === "string"
        ? moment(date, format).month()
        : moment(date).month();
    }
    return moment(new Date()).month();
  }

  /**
  * @param {Date | string} date (Date or string - optional) is the date to use
  * @param {string} format (string - optional) is the format of the date if it is a string (default: 'YYYY-MM-DD HH:mm ZZ')
  * @returns {number} (number) returns the number of the day of the month.
  * @description returns the day(number) of the date's month, or the current day of the month if the param "date" is missing
  */
  static getMonthDay(date?: Date | string, format: string = DateEnum.YYYY_MM_DD_HH_mm_ZZ): number {
    if (date) {
      if (!this.isValidDate(date, format)) return -1;
      return typeof date === "string"
        ? moment(date, format).day()
        : moment(date).day();
    }
    return moment(new Date()).day();
  }

  /**
  * @param {Date | string} date (Date or string - optional) is the date to use
  * @param {string} format (string - optional) is the format of the date if it is a string (default: 'YYYY-MM-DD HH:mm ZZ')
  * @returns {Date} (Date) returns the date of the last day of the month.
  * @description If the param "date" is missing returns the last day of the current month in format Date, in other case returns the last day of the month of the date.
  */
  static getLastMonthDay(date?: Date | string, format: string = DateEnum.YYYY_MM_DD_HH_mm_ZZ): Date {
    if (date) {
      if (!this.isValidDate(date, format)) return null;
      return typeof date === "string"
        ? moment(date, format).endOf('month').toDate()
        : moment(date).endOf('month').toDate();
    }
    return moment(new Date()).endOf('month').toDate();
  }

  /**
  * @param {Date | string} date (Date or string - optional) is the date to use
  * @param {string} format (string - optional) is the format of the date if it is a string (default: 'YYYY-MM-DD HH:mm ZZ')
  * @returns {Date} (Date) returns the date of the first day of the month.
  * @description If the param "date" is missing returns the first day of the current month in format Date, in other case returns the first day of the month of the date.
  */
  static getFirstMonthDay(date?: Date | string, format: string = DateEnum.YYYY_MM_DD_HH_mm_ZZ): Date {
    if (date) {
      if (!this.isValidDate(date, format)) return null;
      return typeof date === "string"
        ? moment(date, format).startOf('month').toDate()
        : moment(date).startOf('month').toDate();
    }
    return moment(new Date()).startOf('month').toDate();
  }

  /**
  * @param {Date | string} date (Date or string - optional) is the date to use
  * @param {string} format (string - optional) is the format of the date if it is a string (default: 'YYYY-MM-DD HH:mm ZZ')
  * @returns {number} (number) returns the day of the year.
  * @description returns the day(number) of the date's year, or the current day of the year if the param "date" is missing
  */
  static getYearDay(date?: Date | string, format: string = DateEnum.YYYY_MM_DD_HH_mm_ZZ): number {
    if (date) {
      if (!this.isValidDate(date, format)) return -1;
      return typeof date === "string"
        ? moment(date, format).dayOfYear()
        : moment(date).dayOfYear();
    }
    return moment(new Date()).dayOfYear();
  }

  /**
  * @param {Date} date (Date) is the date to use
  * @param {string} formatDestination (string - optional) is the format to get as response (default: 'YYYY-MM-DD HH:mm ZZ')
  * @returns {string} (string) returns the date in the format expected.
  * @description transforms the date received into the format expected.
  */
  static dateToString(date: Date, formatDestination: string = DateEnum.YYYY_MM_DD_HH_mm_ZZ): string {
    return this.isValidDate(date)
      ? moment(date).format(formatDestination)
      : '';
  }

  /**
  * @param {string} date (string) is the date to use
  * @param {string} formatOrigin (string - optional) is the format of the param "date" (default: 'YYYY-MM-DD HH:mm ZZ')
  * @returns {Date} (Date) returns the date in format Date.
  * @description transforms the date received from string format to Date format.
  */
  static stringToDate(date: string, formatOrigin?: string): Date {
    return this.isValidDate(date, formatOrigin)
      ? moment(date, formatOrigin ? formatOrigin : DateEnum.YYYY_MM_DD_HH_mm_ZZ).toDate()
      : null;
  }

  /**
  * @param {Date | string} date (Date or string) is the date to use
  * @param {string} formatOrigin (string - optional) is the format of the date if it is a string (default: 'YYYY-MM-DD HH:mm ZZ')
  * @param {string} formatDestination (string - optional) is the format expected (default: 'YYYY-MM-DD HH:mm ZZ')
  * @returns {string} (string) returns the date in the format expected.
  * @description transforms the date from some string format or Date format into another string format
  */
  static formatDate(date: Date | string, formatOrigin: string = DateEnum.YYYY_MM_DD_HH_mm_ZZ, formatDestination: string = DateEnum.YYYY_MM_DD_HH_mm_ZZ): string {
    if (!this.isValidDate(date, formatOrigin)) return null;
    return typeof date === "string"
      ? moment(date, formatOrigin).format(formatDestination)
      : moment(date).format(formatDestination);
  }

  /**
  * @param {Date | string} date (Date or string) is the date to use
  * @param {string} format (string - optional) is the format of the date if it is a string (default: 'YYYY-MM-DD HH:mm ZZ')
  * @returns {string} (string) returns the date in format UFC ('YYYY-MM-DD HH:mmZZ').
  * @description transforms the date from some string format or Date format into UFC format
  */
  static transformDateUFC(date: Date | string, format: string = DateEnum.YYYY_MM_DD_HH_mm_ZZ): string {
    return this.isValidDate(date, format)
      ? this.formatDate(date, format, DateEnum.YYYY_MM_DD_HH_mmZZ)
      : '-';
  }

  /**
  * @param {Date | string} date (Date or string) is the date to use
  * @param {number} quantity (number) is the quantity of days, minutes, seconds or units to add
  * @param {string} type (string) is the unit to add (as 'days', 'minutes', 'months', ...)
  * @param {string} formatOrigin (string - optional) is the format of the date if it is a string (default: 'YYYY-MM-DD HH:mm ZZ')
  * @returns {string} (string) returns the date with the unit added as expected
  * @description Adds the quantity of units expected to a Date
  */
  static add(date: Date | string, quantity: number, type: 'days' | 'months' | 'years' | 'weeks' | 'hours' | 'minutes' | 'seconds', formatOrigin: string = DateEnum.YYYY_MM_DD_HH_mm_ZZ): Date {
    if (!this.isValidDate(date, formatOrigin)) return null;
    return typeof date === "string"
      ? moment(date, formatOrigin).add(quantity, type).toDate()
      : moment(date).add(quantity, type).toDate();
  }

  /**
  * @param {Date | string} date (Date or string) is the date to use
  * @param {number} quantity (number) is the quantity of days, minutes, seconds or units to substract
  * @param {string} type (string) is the unit to substract (as 'days', 'minutes', 'months', ...)
  * @param {string} formatOrigin (string - optional) is the format of the date if it is a string (default: 'YYYY-MM-DD HH:mm ZZ')
  * @returns {string} (string) returns the date with the unit substracted as expected
  * @description Substracts the quantity of units expected to a Date
  */
  static substract(date: Date | string, quantity: number, type: 'days' | 'months' | 'years' | 'weeks' | 'hours' | 'minutes' | 'seconds', formatOrigin: string = DateEnum.YYYY_MM_DD_HH_mm_ZZ): Date {
    if (!this.isValidDate(date, formatOrigin)) return null;
    return typeof date === "string"
      ? moment(date, formatOrigin).subtract(quantity, type).toDate()
      : moment(date).subtract(quantity, type).toDate();
  }

  /**
  * @param {Date | string} date1 (Date or string) is the date to use
  * @param {string} format1 (string - optional) is the format of the date1 if it is a string (default: 'YYYY-MM-DD HH:mm ZZ')
  * @param {Date | string} date2 (Date or string - optional) is the date to compare (default: 'new Date()')
  * @param {string} format2 (string - optional) is the format of the date2 if it is a string (default: 'YYYY-MM-DD HH:mm ZZ')
  * @param {string} unit (string - optional) is the unit to compare (default: 'days')
  * @returns {number} (number) returns the difference between two dates depending of the unit
  * @description Calculates the difference between two dates using as metric the unit param
  */
  static dateDiff(date1: Date | string, format1: string = DateEnum.YYYY_MM_DD_HH_mm_ZZ, date2: Date | string = new Date(), format2: string = DateEnum.YYYY_MM_DD_HH_mm_ZZ, unit: 'days' | 'hours' | 'minutes' | 'seconds' = 'days'): number {
    if (!this.isValidDate(date1, format1) || !this.isValidDate(date2, format2)) return null;
    let currentDate1 = typeof date1 === "string" ? moment(date1, format1) : moment(date1);
    let currentDate2 = typeof date2 === "string" ? moment(date2, format2) : moment(date2);

    let diff = moment.duration(currentDate1.diff(currentDate2));
    let result = unit === "minutes"
      ? diff.asMinutes()
      : unit === "seconds"
        ? diff.asSeconds()
        : unit === 'hours'
          ? diff.asHours()
          : diff.asDays();
    return Math.ceil(result);
  }

  /**
  * @param {Date | string} date (Date or string) is the date to use
  * @param {string} format (string - optional) is the format of the date if it is a string (default: 'YYYY-MM-DD HH:mm ZZ')
  * @param {boolean} preffix (boolean - optional) if it is true, the preffix is showed (default: true)
  * @returns {string} (string) returns a message with the time diff from now (as 'Hace 2 días', 'Hace 3 horas', 'Hace 2 semanas')
  * @description Calculates the difference between the param "date" and now as an explicit message
  */
  static dateDiffFromNow(date: Date | string, format: string = DateEnum.YYYY_MM_DD_HH_mm_ZZ, preffix: boolean = true): string {
    moment.locale('es');
    if (!this.isValidDate(date, format)) return null;
    return typeof date === "string"
      ? moment(date, format).fromNow(preffix)
      : moment(date).fromNow(preffix);
  }

  /**
  * @param {Date | string} date (Date or string) is the date to use
  * @param {string} format (string - optional) is the format of the date if it is a string (default: 'YYYY-MM-DD HH:mm ZZ')
  * @returns {string} (string) returns a message with the time diff until the date (as 'Faltan 2 días', 'Faltan 3 horas', 'Faltan 2 semanas')
  * @description Calculates the difference between the param "date" and now as an explicit message
  */
  static getCalendar(date: Date | string, format: string = DateEnum.YYYY_MM_DD_HH_mm_ZZ, options?: CalendarSpec): string {
    moment.locale('es');
    if (!this.isValidDate(date, format)) return null;
    const momentDate = typeof date === "string"
      ? moment(date, format)
      : moment(date);
    return options
      ? momentDate.calendar(null, options)
      : momentDate.calendar();
  }

  /**
  * @param {number} duration (number) is the quantity to convert
  * @param {string} unit (string) is the unit of the duration, can be "seconds" or "milliseconds"
  * @returns {Object} (Object) returns an object with the specific duration in years, months, days, hours, minutes and seconds
  * @description Calculates the duration in format {years, months, days, hours, minutes, seconds}
  */
  static durationFormat(duration: number, unit: 'seconds' | 'milliseconds'): { years: number, months: number, days: number, hours: number, minutes: number, seconds: number } {
    if (!duration) return { years: 0, months: 0, days: 0, hours: 0, minutes: 0, seconds: 0 }
    let currentDuration = moment.duration(duration, unit);
    return {
      years: currentDuration.years(),
      months: currentDuration.months(),
      days: currentDuration.days(),
      hours: currentDuration.hours(),
      minutes: currentDuration.minutes(),
      seconds: currentDuration.seconds(),
    }
  }

  /**
  * @param {number} duration (number) is the quantity to convert on seconds
  * @returns {string} (string) returns an string with the specific duration in format HH:mm:ss
  * @description Calculates the duration in format HH:mm:ss
  */
  static secondsToCronometer(duration: number): string {
    if (!duration) return null;
    return moment.utc(moment.duration(duration, 'seconds').as('milliseconds')).format("HH:mm:ss");
  }

  /**
  * @param {Date} date (Date) is the date to use
  * @returns {Date} (Date) returns the same date with time 00:00
  * @description Resets the hours and minutes of a date as 00:00
  */
  static setStartOfDay(date: Date): Date {
    if (!this.isValidDate(date)) return null;
    return moment(date).startOf('day').toDate();
  }

  /**
  * @param {Date} date (Date) is the date to use
  * @returns {Date} (Date) returns the same date with time 00:00
  * @description Resets the hours and minutes of a date as 00:00
  */
  static setEndOfDay(date: Date): Date {
    if (!this.isValidDate(date)) return null;
    return moment(date).endOf('day').toDate();
  }

  /**
  * @param {DateFilterOptions} options from and until dates to be used, could be null or undefined;
  * @returns {DateFilter} returns a valid function to be user in matDatepickerFilter
  * @description Take some options and produces a valid matDatePickerFilter range opened.
  */
  static filter(options: DateFilterOptions): DateFilter {
    let from: Date | null = null;
    let until: Date | null = null;

    if (!!options.from && this.isValidDate(options.from))
      from = (moment(options.from).toDate());
    if (!!options.until && this.isValidDate(options.until))
      until = (moment(options.until).toDate());

    return (date: Date | null): boolean => {
      if (!date)
        return false;

      date.setHours(0, 0, 0, 0);

      if (from !== null && until !== null) {
        from.setHours(0, 0, 0, 0);
        until.setHours(0, 0, 0, 0);
        return date >= from && date <= until;
      } else if (from !== null) {
        from.setHours(0, 0, 0, 0);
        return date >= from;
      } else if (until !== null) {
        until.setHours(0, 0, 0, 0);
        return date <= until;
      }
      return true;
    }
  }

  /**
  * @param {Date} date (Date) is the date to use
  * @param {number} hours (number - optional) is the number of hours to set in the date
  * @param {number} minutes (number - optional) is the number of minutes to set in the date
  * @param {number} seconds (number - optional) is the number of seconds to set in the date
  * @returns {Date} (Date) returns the same date with the time expected
  * @description Transforms the time of the date into the hours, minutes and seconds expected
  */
  static setTimeToDate(date: Date, hours?: number, minutes?: number, seconds?: number): Date {
    if (!this.isValidDate(date)) return null;
    let currentDate = moment(date);
    if (hours || hours === 0) currentDate.set('hour', hours);
    if (minutes || minutes === 0) currentDate.set('minute', minutes);
    if (seconds || seconds === 0) currentDate.set('second', seconds);

    return currentDate.toDate();
  }

  /**
  * @param {Date} date1 (Date) is the date to use
  * @param {Date} date2 (Date) is the date to compare
  * @param {string} unit (string - optional) is the unit to compare (default: 'days')
  * @returns {boolean} (boolean) returns true if the date1 is before than date2 in the established unit, false in othercase
  * @description Compares two dates to verify if the first one is before than the second one in the established unit
  */
  static isBefore(date1: Date, date2: Date, unit: 'days' | 'minutes' | 'seconds' = 'days'): boolean {
    if (!this.isValidDate(date1) || !this.isValidDate(date2)) return null;
    return moment(date1).isBefore(date2, unit);
  }

  /**
  * @param {Date} date1 (Date) is the date to use
  * @param {Date} date2 (Date) is the date to compare
  * @param {string} unit (string - optional) is the unit to compare (default: 'days')
  * @returns {boolean} (boolean) returns true if the date1 is before or equal than date2 in the established unit, false in othercase
  * @description Compares two dates to verify if the first one is before or equal than the second one in the established unit
  */
  static isSameOrBefore(date1: Date, date2: Date, unit: 'days' | 'minutes' | 'seconds' = 'days'): boolean {
    if (!this.isValidDate(date1) || !this.isValidDate(date2)) return null;
    return moment(date1).isSameOrBefore(date2, unit);
  }

  /**
  * @param {Date} date1 (Date) is the date to use
  * @param {Date} date2 (Date) is the date to compare
  * @param {string} unit (string - optional) is the unit to compare (default: 'days')
  * @returns {boolean} (boolean) returns true if the date1 is equal than date2 in the established unit, false in othercase
  * @description Compares two dates to verify if the first one is equal than the second one in the established unit
  */
  static isSame(date1: Date, date2: Date, unit: 'days' | 'minutes' | 'seconds' = 'days'): boolean {
    if (!this.isValidDate(date1) || !this.isValidDate(date2)) return null;
    return moment(date1).isSame(date2, unit);
  }

  static onlyTime(date: Date) {
    return moment(date).zone('-0500').format('LT');
  }
}
