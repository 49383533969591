import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { environment } from 'src/environments/environment';
import { Utils } from 'src/app/core/resources/utils';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { AuthService } from 'src/app/core/services/authentication.service';
import { User } from 'src/app/core/interfaces/user';
import { catchError, finalize, map, timeout } from 'rxjs/operators';
import { PaginationList } from 'src/app/core/models/pagination-list';
import { CargoGpsDTO, Gps } from 'src/app/core/interfaces/gps';
import { StateUserPipe } from 'src/app/core/pipe/stateInactive.pipe';
import { VehicleDocumentCatalog, VehicleDocumentRequired, VehicleDocuments } from 'src/app/core/interfaces/vehicleDocuments';
import { TrailerBrand } from 'src/app/core/interfaces/TrailerBrand';
import { Observable, of } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { UserState } from 'src/app/core/enums/user-state.enum';
import { VehicleMessages } from 'src/app/core/messages/vehicle-messages.enum';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { VehicleExtraDocument } from 'src/app/core/interfaces/vehicleExtraDocument';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Company } from 'src/app/core/interfaces/company';
import { VehicleConfig } from 'src/app/core/interfaces/vehicleConfig';
import { Bank } from 'src/app/core/interfaces/bank';
import { VehicleCreate } from 'src/app/core/interfaces/vehicleCreate';
import { BodyWorkType } from 'src/app/core/models/body-work-type.model';
import { BasicResponse } from 'src/app/core/interfaces/basicResponse';
import { Trailer } from 'src/app/core/interfaces/trailer';
import { BankAccount } from 'src/app/core/interfaces/userBank';
import { ValidationRule } from 'src/app/core/interfaces/validation-rule';
import { FIELDS_SECURITY_STUDY, FIELDS_STATE } from 'src/app/core/config/user.config';
import { Roles } from 'src/app/core/enums/roles.enum';
import { Global } from 'src/app/core/resources/global';
import { CatalogItem } from 'src/app/core/interfaces/catalogItem';

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {

  public paginationList: PaginationList = new PaginationList();
  vehicleSelected: Vehicle;
  data: string;

  constructor(
    private http: HttpClient,
    private endpointResources: Endpoints,
    private utils: Utils,
    private authService: AuthService,
    private stateUserPipe: StateUserPipe,
    private matDialog: MatDialog,
    private global: Global,
  ) { }

  /**
  * @returns {{value: string, name: string}[]} returns a list of mandatory documents
  * @description Gets a list of mandatory documents
  */
  private async getMandatoryDocuments(): Promise<{ value: string, name: string, mandatory: boolean }[]> {
    try {
      const docs: VehicleDocumentRequired[] = await this.getCompanyDocumentTypes(true).toPromise();
      if (!docs || !docs.length) return [];
      return docs.map(doc => { if (doc.active) return { value: doc.id, name: doc.name, mandatory: doc.mandatory } });
    } catch (e) {
      return [];
    }
  };

  getVehicleDocumentName(name: string): string {
    const dictionary = {
      frontPicture: "Fotografía frontal",
      leftLateralPicture: "Fotografía lateral izquierdo",
      rightLateralPicture: "Fotografía lateral derecho",
      propertyCard: "Tarjeta de propiedad"
    };
    if (dictionary[name]) return dictionary[name];
    return name;
  }

  /**
  * @param {Vehicle} vehicle is the vehicle to check
  * @returns {VehicleExtraDocument[]} returns a list of vehicle's extra images
  * @description Gets a list of a vehicle's extra images
  */
  private getExtraImagesTypes(vehicle: Vehicle): VehicleExtraDocument[] {
    const extraImagesLoaded = [];
    if (vehicle && vehicle.extraImages) {
      vehicle.extraImages.forEach((extraDocument) => {
        extraImagesLoaded.push(extraDocument);
      });
    }
    return extraImagesLoaded;
  }

  /**
  * @returns {boolean} returns true if the user belongs to Teclogi, otherwise false
  * @description Checks if the user belongs to Teclogi
  */
  get isFromTeclogi(): boolean {
    return this.authService.getCompany() && this.authService.getCompany().companyId && (this.authService.getCompany().companyId === environment.rootNit)
  }

  /**
  * @returns {Observable<Vehicle>} returns the vehicle expected if exists
  * @description Gets a vehicle from its license plate
  */
  public getValidationRules(): Observable<Array<ValidationRule>> {
    return this.http.get<Array<ValidationRule>>(
      environment.urlServerTeclogi + this.endpointResources.validationRules
    ).pipe(
      map((rules: ValidationRule[]) => {
        if (rules && rules.length) {
          const roles = [Roles.ADMIN, Roles.DRIVER, Roles.OWNER];
          roles.forEach(role => {
            FIELDS_STATE[role] = [];
            FIELDS_SECURITY_STUDY[role] = [];
          })
          rules.forEach(rule => this.assign(rule));
        }
        return rules;
      })
    );
  }

  assign(validationRule: ValidationRule) {
    if (validationRule.active) {
      if (validationRule.ruleType === 'state') {
        if (FIELDS_STATE[validationRule.roleName as Roles])
          FIELDS_STATE[validationRule.roleName as Roles].push(validationRule.ruleName);
      } else {
        if (FIELDS_SECURITY_STUDY[validationRule.roleName as Roles])
          FIELDS_SECURITY_STUDY[validationRule.roleName as Roles].push(validationRule.ruleName);
      }
    }
  }

  /**
  * @param {Vehicle} vehicle is the vehicle to check
  * @param {'missing' | 'unaccepted'} mode is the state of the documents to check
  * @returns {Array<string>} returns a list of vehicle's documents unaccepted or missing
  * @description Gets a list of a vehicle's documents unaccepted or missing
  */
  public async getMissingDocuments(vehicle: Vehicle, mode: 'missing' | 'unaccepted' = 'missing'): Promise<Array<string>> {
    const extraImagesLoaded = this.getExtraImagesTypes(vehicle);
    const missingDocuments = [];
    const mandatoryDocuments = await this.checkDocumentRules(vehicle);
    mandatoryDocuments.forEach((doc) => {
      doc.mandatory && !(
        vehicle[doc.value] ?
          vehicle[doc.value].length > 0 :
          extraImagesLoaded.some(
            img => img.type && !this.global.listTypeDocuments.some(typeDoc => typeDoc.name === doc.value) && (mode === 'unaccepted' ? img.validated : !!img.path)
          )
      ) && missingDocuments.push(doc.name);
    });
    return missingDocuments;
  }

  /**
  * @param {Vehicle} vehicle is the vehicle to check
  * @returns {{ value: string, name: string }[]} returns a list of vehicle's mandatoy documents with or without RTM as mandatory
  * @description Gets a list of a vehicle's mandatory documents with or without RTM as mandatory
  */
  public async checkDocumentRules(vehicle: Vehicle): Promise<{ value: string, name: string, mandatory: boolean }[]> {
    const documents = await this.getMandatoryDocuments();
    const documentsToCheck = documents.find((document) => document.name === 'RTM');
    try {
      if (documentsToCheck) {
        const position = documents.findIndex((document) => document.name === 'RTM');
        const expirationDate = DateManager.stringToDate(vehicle.validRTM.expirationDate, 'YYYY-MM-DD ZZ').getTime();
        const now = new Date().getTime();
        if (expirationDate > now)
          documents[position].mandatory = false;
      }
    } catch (e) { }
    return documents;
  }

  /**
  * @param {Vehicle} vehicle is the vehicle to check
  * @returns {boolean} returns true if the vehicle has unaccepted documents, otherwise false
  * @description Checks if the vehicle has unaccepted documents
  */
  public async documentsValidator(vehicle: Vehicle): Promise<boolean> {
    let docs = await this.getMissingDocuments(vehicle, 'unaccepted');
    return docs.length == 0;
  }

  /**
  * @param {User} driver is the driver to check
  * @returns {Array<string>} returns a list of driver's invalid references (not validated)
  * @description Gets a list of a driver's invalid references (not validated)
  */
  public invalidReferences(driver: User): Array<string> {
    let invalidReferences = [];
    if (!driver || !driver.emergencyContact || !driver.emergencyContact.validated) invalidReferences.push("Contacto de emergencia");
    if (!driver || !driver.referenceLaboral || !driver.referenceLaboral.validated) invalidReferences.push("Referencia laboral");
    if (!driver || !driver.referencePersonal || !driver.referencePersonal.validated) invalidReferences.push("Referencia personal");
    return invalidReferences;
  }

  /**
  * @param {string} companyId is the vehicle's company id
  * @param {string} holderCompanyId is the vehicle's holder company id
  * @param {number} pageKey is the key of the expected page
  * @param {number} pageSize is the size of the expected page
  * @param {string} id is the vehicle's id
  * @param {string} state is the vehicle's bank account state
  * @param {boolean} vehicleState is the vehicle's state
  * @returns {Observable<Vehicle[]>} returns a list of vehicles filtered
  * @description Gets a list of a vehicles filtered
  */
  public getListVehicles(
    pageKey: number,
    pageSize: number,
    filters: string
  ): Observable<Vehicle[]> {
    let params = this.getParamsFormatted(filters, pageKey, pageSize);
    return this.http.get<Vehicle[]>(`${environment.urlServerTeclogi}${this.endpointResources.getVehicles}`, { params });
  }


  getParamsFormatted(paramsFilter: string, pageKey?: number, pageSize?: number): HttpParams {
    let params = new HttpParams();
    if (paramsFilter) {
      paramsFilter.split('&').forEach(param => {
        if (param) {
          const [key, value] = param.split('=');
          if (key && value) {
            params = params.append(key, value);
          }
        }
      });
    }
    if (pageKey && pageSize && !paramsFilter.includes("id")) {
      params = params.append('pageKey', pageKey.toString());
      params = params.append('pageSize', pageSize.toString());
    }
    return params;
  }

  getAccountState(state: string): string {
    let url = '';
    if (this.utils.isDefined(state) && state.length) {
      switch (state) {
        case '0':
          url
          break
        case '1':
          url += '&stateAccountAdvance=true';
          break
        case '2':
          url += '&stateAccountBalance=true';
          break
        case '3':
          url += '&stateAccountAdvance=true&stateAccountBalance=true'
          break
        case '4':
          url += '&stateAccountAdvance=false&stateAccountBalance=false'
          break
        case '5':
          url += '&stateAccountAdvance=false'
          break
        case '6':
          url += '&stateAccountBalance=false'
          break
      }
    }
    return url;
  }

  /**
  * @param {string} id is the vehicle's license plate
  * @returns {Observable<Vehicle>} returns the vehicle expected if exists
  * @description Gets a vehicle from its license plate
  */
  public getVehicle(id: string): Observable<Vehicle> {
    return this.http.get<Vehicle>(
      environment.urlServerTeclogi + this.endpointResources.getVehicle + id
    );
  }

  /**
  * @param {string} idVehicle is the vehicle's license plate
  * @param {string} holderCompany is the vehicle's holder company
  * @returns {Observable<Vehicle[]>} returns the vehicle expected if exists into as the first element of array
  * @description Gets a vehicle from its license plate and holder company as the first element of array
  */
  getVehicleById(idVehicle: string, holderCompany?: string): Observable<Vehicle[]> {
    let url = `${environment.urlServerTeclogi}${this.endpointResources.getVehiclesById}${idVehicle}`;
    if (holderCompany) url += '&holderCompany=' + holderCompany;
    return this.http.get<Vehicle[]>(url);
  }

  /**
  * @returns {Observable<Bank[]>} returns a list of banks registered
  * @description Gets a list of banks registered
  */
  public getListBank(): Observable<Bank[]> {
    this.data = environment.urlServerTeclogi + this.endpointResources.getBanck;
    return this.http.get<Bank[]>(this.data);
  }

  /**
  * @param {Vehicle} dataVehicleBank is the vehicle to register its bank account
  * @returns {Observable<Vehicle>} returns the vehicle updated
  * @description Updates a vehicle bank account
  */
  public sendDataRegisterBankAccount(dataVehicleBank: Vehicle): Observable<Vehicle> {
    return this.http.post<Vehicle>(
      environment.urlServerTeclogi + this.endpointResources.registerBanckAccount,
      dataVehicleBank
    );
  }

  /**
  * @param {string} licensePlate is the vehicle's license plate
  * @param {string} reason is the reason to disable the current vehicle
  * @returns {Observable<string>} returns a message indicating the result of the process
  * @description Disables a vehicle
  */
  public disableVehicle(licensePlate: string, reason: string): Observable<string> {
    return this.http.post<string>(
      environment.urlServerTeclogi + this.endpointResources.disableVehicle + licensePlate, { message: reason }
    );

  }

  /**
  * @param {string} licensePlate is the vehicle's license plate
  * @param {boolean} approval indicates if the document must to be approved or not
  * @param {'advance'|'balance'} paymentType is the type of the payment
  * @param {string} reason is the reason to approve the documents
  * @returns {Observable<Vehicle>} returns the vehicle updated
  * @description Approve a vehicle's documents
  */
  approveDocuments(licensePlate: string, approval: boolean, paymentType: 'advance' | 'balance', reason: string): Observable<Vehicle> {
    let params = new HttpParams();
    params = params.append('approval', approval.toString());
    const body = {
      licensePlate,
      paymentType,
      reason: (reason || ''),
      userId: this.authService.getUserSession().information.document
    };
    return this.http.post<Vehicle>(
      environment.urlServerTeclogi + this.endpointResources.approveDocumentsVehicle,
      body,
      { params }
    );
  }

  /**
  * @param data is the body to create the vehicle, driver, owner and admin
  * @returns {Observable<VehicleCreate>} returns the vehicle created
  * @description Creates a vehicle and its driver, owner and admin if is required
  */
  public createVehicleDriverOwner(data): Observable<VehicleCreate> {
    return this.http.post<VehicleCreate>(
      `${environment.urlServerTeclogi}${this.endpointResources.createVehicleDriverOwner}`,
      data
    );
  }

  /*public getTrailerConfig() {
    return this.http.get(
      `${environment.urlServerTeclogi}${this.endpointResources.urlCatalogTrailerConfig}`
    );
  }*/

  /**
  * @param {number} vehicletype is the code of the vehicle's class selected
  * @returns {Observable<BodyWorkType[]>} returns a list of bodywork types
  * @description Gets a list of bodyworks types by vehicle's class
  */
  public getBodyWork(vehicletype: number): Observable<BodyWorkType[]> {
    return this.http.get<BodyWorkType[]>(
      `${environment.urlServerTeclogi}${this.endpointResources.catalogBodywork}?${this.endpointResources.vehicleType}=${vehicletype}`
    );
  }

  /**
  * @returns {Observable<TrailerBrand>} returns a catalog of trailer brands
  * @description Gets a catalog of trailer brands
  */
  public getTrailerBrand(): Observable<TrailerBrand> {
    return this.http.get<TrailerBrand>(
      `${environment.urlServerTeclogi}${this.endpointResources.urlCatalog}/TrailerBrand`
    )
  }

  /**
  * @param {number} axles are the number of vehicle's axles
  * @returns {Observable<VehicleConfig[]>} returns a list of vehicle config
  * @description Gets a list of vehicle config
  */
  public getVehicleConfig(axles: number): Observable<VehicleConfig[]> {
    return this.http.get<VehicleConfig[]>(
      `${environment.urlServerTeclogi}${this.endpointResources.vehicleConfiguration}?axles=${axles}&trailersOnly=true`
    );
  }

  /**
  * @param data is the body to create the trailer
  * @returns {Observable<BasicResponse>} returns the result of the process
  * @description Creates a trailer
  */
  public createTrailer(data): Observable<BasicResponse> {
    return this.http.post<BasicResponse>(
      `${environment.urlServerTeclogi}${this.endpointResources.createTrailer}`,
      data
    );
  }

  /**
  * @param {{name:string, id:string}[]} data are the list of gps to create
  * @returns {Observable<BasicResponse>} returns the result of the process
  * @description Creates a list of gps
  */
  public createGPS(data: { name: string, id: string }[]): Observable<BasicResponse> {
    var headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

    return this.http.post<BasicResponse>(
      environment.urlServerTeclogi + this.endpointResources.createGps,
      data,
      { headers, responseType: 'text' as 'json' }
    );
  }

  /**
  * @param {string} vehicle is the vehicle's license plate
  * @param {string} id is the trailer's license plate
  * @returns {Observable<Trailer[]>} returns the trailer expected as the first element of array
  * @description Gets a trailer from its license plate and its vehicle's license plate as the first element of array
  */
  public detailTrailer(vehicle: string, id: string): Observable<Trailer[]> {
    return this.http.get<Trailer[]>(
      `${environment.urlServerTeclogi}${this.endpointResources.listTrailer}vehicle=${vehicle}&id=${id}`
    );
  }

  /**
  * @param {Trailer} data is the trailer to check
  * @returns {Trailer} returns the trailer modified
  * @description Gets the trailer modified deleting address and municipalityCode to owner and admin with NIT
  */
  public getTrailerObject(data: Trailer): Trailer {
    const dataTrailer = this.utils.clone(data);
    if (data && data.owner && data.owner.documentTypeId != '3') {
      delete dataTrailer.owner.municipalityCode;
      delete dataTrailer.owner.address;
    }
    if (data && data.administrator && data.administrator.documentTypeId != '3') {
      delete dataTrailer.administrator.municipalityCode;
      delete dataTrailer.administrator.address;
    }
    return dataTrailer;
  }

  /**
  * @param {string} filter are the filters applied
  * @returns {Observable<Trailer[]>} returns a list of trailers
  * @description Gets a list of trailers filtered
  */
  public getTrailerList(filter?: string): Observable<Trailer[]> {
    return this.http.get<Trailer[]>(
      `${environment.urlServerTeclogi}${this.endpointResources.getTrailerList}${filter}`
    );

  }

  /**
  * @param {Trailer} trailer is the complete trailer
  * @param {string} licencePlate is the vehicle's license plate
  * @returns {Observable<BasicResponse>} returns the result of the process
  * @description Updates a vehicle's trailer
  */
  public updateTrailer(trailer: Trailer, licencePlate: string) {
    return this.http.post<BasicResponse>(
      `${environment.urlServerTeclogi}${this.endpointResources.updateTrailer}${licencePlate}`,
      trailer
    );

  }

  /**
  * @param {string} licensePlate is the vehicle's license plate
  * @param {User} driver is the driver to set
  * @returns {Observable<User>} returns the driver updated
  * @description Changes a vehicle's driver
  */
  public vehicleChangeDriver(licensePlate: string, driver: User): Observable<User> {
    let params = new HttpParams();
    params = params.append('licensePlate', licensePlate);
    return this.http.post<User>(
      `${environment.urlServerTeclogi}${this.endpointResources.vehicleChangeDriver}`,
      driver,
      { params }
    );
  }

  /**
  * @param {BankAccount[]} data is the list of bank accounts of vehicles
  * @param {'balance' | 'advance'} type is the type of the bank account
  * @returns {Observable<{body}>} returns a file with the respective inscription account
  * @description Gets a file with the respective inscription account
  */
  public getFileForInscriptionAccount(data: BankAccount[], type: 'balance' | 'advance'): Observable<{ body }> {
    var url = environment.urlServerTeclogi + this.endpointResources.registerBank + 'type=' + type;
    return this.http.post(
      url,
      data,
      {
        responseType: 'blob',
        observe: 'response'
      }
    ).pipe(
      timeout(960000)
    );
  }

  /**
  * @param {string} stateAccount is the type of vehicle's banks accounts states
  * @param {'Vehicle} vehicle is the vehicle to check
  * @returns {boolean>} returns true if the vehicle's bank accounts are active, otherwise false
  * @description Verifies the vehicle's bank accounts state
  */
  getActiveItem(stateAccount: string, vehicle: Vehicle): boolean {
    switch (stateAccount) {
      // 'Anticipo y Saldo sin Registrar'
      case '4':
        return vehicle.bankAccountAdvance && vehicle.bankAccountAdvance.state && vehicle.bankAccountAdvance.state.active &&
          vehicle.bankAccountBalance && vehicle.bankAccountBalance.state && vehicle.bankAccountBalance.state.active;
      // 'Anticipo sin Registrar'
      case '5':
        return vehicle.bankAccountAdvance && vehicle.bankAccountAdvance.state && vehicle.bankAccountAdvance.state.active;
      // 'Saldo sin Registrar'
      case '6':
        return vehicle.bankAccountBalance && vehicle.bankAccountBalance.state && vehicle.bankAccountBalance.state.active;
      default:
        return false;
    }
  }

  /**
  * @param {User} body is the user to set as administrator
  * @param {string} licensePlate is the vehicle's license plate
  * @returns {Observable<Vehicle>} returns the vehicle updated with new admin associated
  * @description Updates a vehicle's admin
  */
  public updateAdministrator(body: User, licensePlate: string): Observable<Vehicle> {
    if (body && body.information && body.information.documentTypeId != '3') {
      if (body.municipalityCode)
        delete body.municipalityCode;
      if (body.address)
        delete body.address;
    }

    return this.http.post<Vehicle>(
      environment.urlServerTeclogi + this.endpointResources.updateAdministrator + licensePlate,
      body
    );
  }

  /**
  * @description Resets the paginationList values
  */
  resetPaginationList() {
    this.paginationList.setEnablePagingScroll(true);
    this.paginationList.setList([]);
    this.paginationList.setPageKey(1);
  }

  /**
  * @param {Vehicle} vehicle is the vehicle to get the state
  * @returns {string} returns the vehicle's state transformed if exists
  * @description Gets and transforms the vehicle's state to be shown.
  */
  getVehicleState(vehicle: Vehicle): string {
    if (!this.utils.isEmpty(vehicle.state) && !this.utils.isEmpty(vehicle.state.active)) {
      return this.stateUserPipe.transform(vehicle.state.description);
    }
    return this.stateUserPipe.transform(UserState.PENDING);
  }

  /**
  * @param {string} idVehicle is the vehicle's license plate
  * @param {{userId: string, userName: string}} data is the data to approve the vehicle
  * @returns {Observable<Vehicle>} returns the vehicle approved
  * @description Approves a vehicle
  */
  approveVehicle(idVehicle: string, data: { userId: string, userName: string }): Observable<Vehicle> {
    return this.http.post<Vehicle>(
      environment.urlServerTeclogi + this.endpointResources.approveVehicle + idVehicle,
      data
    );
  }

  /**
  * @param {string} licensePlate is the vehicle's license plate
  * @param {VehicleDocuments} body is vehicle's document to change
  * @returns {Observable<Vehicle>} returns the vehicle updated
  * @description Updates the vehicle's documents
  */
  public updateVehicleDocuments(licensePlate: string, body: VehicleDocuments): Observable<Vehicle> {
    let params = new HttpParams();
    params = params.append('licensePlate', licensePlate);
    return this.http.post<Vehicle>(
      environment.urlServerTeclogi + this.endpointResources.updateDocumentsVehicle,
      body,
      { params }
    );
  }

  /**
  * @param {string} licensePlate is the vehicle's license plate
  * @param {Gps} body is vehicle's gps to change
  * @returns {Observable<Vehicle>} returns the vehicle updated
  * @description Updates the vehicle's gps
  */
  public updateGpsDocuments(licensePlate: string, body: Gps): Observable<Vehicle> {
    let params = new HttpParams();
    params = params.append('licensePlate', licensePlate);
    return this.http.post<Vehicle>(
      environment.urlServerTeclogi + this.endpointResources.updateGpsVehicle,
      body,
      { params }
    );
  }

  /**
  * @returns {Observable<{catalog: VehicleDocumentCatalog[]}>} returns the document types list
  * @description Gets the document types list
  */
  public getDocumentTypes(): Observable<{ catalog: VehicleDocumentCatalog[] }> {
    return this.http.get<{ catalog: VehicleDocumentCatalog[] }>(
      `${environment.urlServerTeclogi}${this.endpointResources.vehicleDocumentTypes}`
    );
  }

  /**
  * @returns {Observable<VehicleDocumentRequired[]>} returns the document types list configured
  * @description Gets the document types list configured
  */
  public getCompanyDocumentTypes(active?: boolean): Observable<VehicleDocumentRequired[]> {
    let params = new HttpParams();
    if (this.utils.isDefined(active))
      params = params.append('active', active.toString());
    return this.http.get<VehicleDocumentRequired[]>(
      `${environment.urlServerTeclogi}company/saas/${this.authService.getCompanySaaS().companyId}/vehicle-documents`,
      { params }
    );
  }

  /**
  * @param {string} licensePlate is the vehicle's license plate
  * @param {VehicleExtraDocument} extraImage is new vehicle's extra image
  * @returns {Observable<BasicResponse>} returns the result of the update process
  * @description Updates a vehicle's extra images
  */
  public saveExtraDocs(licensePlate: string, extraImage: VehicleExtraDocument): Observable<BasicResponse> {
    return this.http.post<BasicResponse>(
      `${environment.urlServerTeclogi}${this.endpointResources.vehicleDocuments}?licensePlate=${licensePlate}`,
      extraImage
    );
  }

  /**
  * @param {string} licensePlate is the vehicle's license plate
  * @param {Date} from is the date to compare
  * @param {Company} cargoCompany is the company of the cargo to check
  * @returns {Observable<VehicleMessages>} returns the list of vehicle's errors and alerts from SOAT and RTM expiration
  * @description Gets a list of vehicle's errors and alerts from SOAT and RTM expirations
  */
  public validateExpirationRTMSOAT(licensePlate: string, lastDestinationDate: Date, cargoCompany: Company): Observable<VehicleMessages> {
    return new Observable(
      observer => {
        const $ = this.getVehicle(licensePlate).subscribe(
          (vehicle: Vehicle) => {
            let RTMexp: Date;
            let SOATexp: Date;
            // SOAT
            if (!!vehicle.validSOAT) {
              if (!!vehicle.validSOAT.expirationDate) {
                SOATexp = DateManager.stringToDate(vehicle.validSOAT.expirationDate, 'YYYY-MM-DD');
                const daysToAlertExpirationSOAT = cargoCompany && cargoCompany.daysToAlertExpirationSOAT ? cargoCompany.daysToAlertExpirationSOAT : VehicleMessages.DAYS_TO_ALERT_EXPIRATION_DEFAULT;
                const daysToBlockSOAT = cargoCompany && cargoCompany.daysToBlockSOAT ? cargoCompany.daysToBlockSOAT : VehicleMessages.DAYS_TO_BLOCK_DEFAULT;
                const soatAlertsAndBlocksEnabled = cargoCompany && this.utils.isDefined(cargoCompany.soatAlertsAndBlocksEnabled) ? cargoCompany.soatAlertsAndBlocksEnabled : true;

                if (DateManager.isBefore(lastDestinationDate, SOATexp) && !soatAlertsAndBlocksEnabled) {
                  observer.next(VehicleMessages.SOAT_VALID);
                } else if (DateManager.isBefore(DateManager.add(lastDestinationDate, daysToAlertExpirationSOAT, 'days'), SOATexp) && soatAlertsAndBlocksEnabled) {
                  observer.next(VehicleMessages.SOAT_MORE_N_DAYS);
                } else if (DateManager.isBefore(DateManager.add(lastDestinationDate, daysToBlockSOAT, 'days'), SOATexp) && soatAlertsAndBlocksEnabled) {
                  observer.next(VehicleMessages.SOAT_LESS_ALERT_N_DAYS);
                } else if (DateManager.isBefore(lastDestinationDate, SOATexp) && soatAlertsAndBlocksEnabled) {
                  observer.next(VehicleMessages.SOAT_LESS_BLOCK_N_DAYS);
                } else {
                  observer.next(VehicleMessages.SOAT_EXPIRATED);
                }
              } else {
                observer.next(VehicleMessages.SOAT_NO_EXPIRATION);
              }
            } else {
              observer.next(VehicleMessages.SOAT_NO_INFORMATION);
            }
            // RTM
            if (!!vehicle.validRTM) {
              if (!!vehicle.validRTM.expirationDate) {
                RTMexp = DateManager.stringToDate(vehicle.validRTM.expirationDate, 'YYYY-MM-DD');
                const daysToAlertExpirationRTM = cargoCompany && cargoCompany.daysToAlertExpirationRTM ? cargoCompany.daysToAlertExpirationRTM : VehicleMessages.DAYS_TO_ALERT_EXPIRATION_DEFAULT;
                const daysToBlockRTM = cargoCompany && cargoCompany.daysToBlockRTM ? cargoCompany.daysToBlockRTM : VehicleMessages.DAYS_TO_BLOCK_DEFAULT;
                const rtmAlertsAndBlocksEnabled = cargoCompany && this.utils.isDefined(cargoCompany.rtmAlertsAndBlocksEnabled) ? cargoCompany.rtmAlertsAndBlocksEnabled : true;

                if (DateManager.isBefore(lastDestinationDate, RTMexp) && !rtmAlertsAndBlocksEnabled) {
                  observer.next(VehicleMessages.RTM_VALID);
                } else if (DateManager.isBefore(DateManager.add(lastDestinationDate, daysToAlertExpirationRTM, 'days'), RTMexp) && rtmAlertsAndBlocksEnabled) {
                  observer.next(VehicleMessages.RTM_MORE_N_DAYS);
                } else if (DateManager.isBefore(DateManager.add(lastDestinationDate, daysToBlockRTM, 'days'), RTMexp) && rtmAlertsAndBlocksEnabled) {
                  observer.next(VehicleMessages.RTM_LESS_ALERT_N_DAYS);
                } else if (DateManager.isBefore(lastDestinationDate, RTMexp) && rtmAlertsAndBlocksEnabled) {
                  observer.next(VehicleMessages.RTM_LESS_BLOCK_N_DAYS);
                } else {
                  observer.next(VehicleMessages.RTM_EXPIRATED);
                }
              } else {
                const exoneration: Date = !!vehicle.registrationDate
                  ? DateManager.add(vehicle.registrationDate, 5, 'years', 'YYYY-MM-DD HH:mm ZZ')
                  : !!vehicle.manufacturingYear
                    ? DateManager.add(vehicle.manufacturingYear, 5, 'years', 'YYYY')
                    : null;
                if (vehicle && (vehicle.registrationDate || vehicle.manufacturingYear)) {
                  if (DateManager.isBefore(lastDestinationDate, exoneration)) {
                    observer.next(VehicleMessages.RTM_EXONERATED);
                  } else {
                    let maximumExoneratedDate: Date = DateManager.add(vehicle.manufacturingYear, 1, 'years', 'YYYY');
                    if (DateManager.isBefore(lastDestinationDate, maximumExoneratedDate)) {
                      observer.next(VehicleMessages.RTM_POSSIBLE_EXONERATED);
                    } else {
                      observer.next(VehicleMessages.RTM_NO_EXPIRATION);
                    }
                  }
                } else {
                  observer.next(VehicleMessages.RTM_NO_EXPIRATION);
                }
              }
            } else {
              observer.next(VehicleMessages.RTM_NO_INFORMATION);
            }

            $.unsubscribe();
            observer.complete();
          }
        );
      }
    );
  }

  /**
  * @param {string} phone is the user's phone
  * @returns {Observable<User[]>} returns the list of users with the same phone
  * @description Gets the list of users with the same phone
  */
  public getUsersByPhone(phone: string): Observable<User[]> {
    return this.http.get<User[]>(`${environment.urlServerTeclogi}${this.endpointResources.usersList}?phone=${phone}`);
  }

  /**
  * @param {string} phone is the phone number to check
  * @returns {Observable<Vehicle>} returns the phone without "57" prefix
  * @description Deletes a "57" phone prefix if exists
  */
  removeCountryCode(phone: string): string {
    return phone.startsWith('57') ? phone.slice(2) : phone;
  }

  /**
  * @param {string} phone is the phone number to check
  * @returns {Observable<Vehicle>} returns the phone with "57" prefix
  * @description Adds a "57" phone prefix if is necessary
  */
  addCountryCode(phone: string): string {
    return phone.startsWith('57') ? phone : `57${phone}`;
  }

  /**
  * @param {Vehicle} vehicle is the vehicle to update
  * @returns {Observable<BasicResponse>} returns the result of the process
  * @description Updates a vehicle
  */
  updateVehicle(vehicle: Vehicle): Observable<BasicResponse> {
    return this.http.post<BasicResponse>(`${environment.urlServerTeclogi}${this.endpointResources.getVehicle}`, vehicle);
  }

  /**
  * @param {Vehicle} vehicle is the vehicle to update
  * @returns {Observable<BasicResponse>} returns the result of the process
  * @description Updates a vehicle's fields
  */
  updateVehicleFields(vehicle: Vehicle): Observable<BasicResponse> {
    return this.http.put<BasicResponse>(`${environment.urlServerTeclogi}${this.endpointResources.updateVehicleFields}`, vehicle);
  }

  /*public createThirdParty(typeBankAccount: string, licensePlate: string, data: any) {
    let params = new HttpParams();

    params = params.append('type', typeBankAccount);
    params = params.append('licensePlate', licensePlate);

    return this.http.post(
      environment.urlServerTeclogi + this.endpointResources.createThirdPartyBank,
      data,
      { params }
    )
  }*/

  /**
  * @param {string} id is the vehicle's license plate
  * @param {boolean} state indicates if the vehicle has to be activated or not
  * @returns {Observable<BasicResponse>} returns the result of the process
  * @description Updates a vehicle's state
  */
  public setStateVehicle(id: string, state: boolean): Observable<BasicResponse> {
    let data = {
      id,
      state: {
        active: state,
        description: state ? 'Active' : 'Inactive'
      }
    }
    return this.http.post<BasicResponse>(`${environment.urlServerTeclogi}${this.endpointResources.stateVehicle}/${id}`, data)
  }

  public getVehicleTypes() {
    return this.http.get<{ catalog: CatalogItem[] }>(`${environment.urlServerTeclogi}${this.endpointResources.vehicleTypeCatalog}`);
  }
}
