import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CatalogItem } from 'src/app/core/interfaces/catalogItem';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Utils } from 'src/app/core/resources/utils';
import { MaintenanceService } from '../maintenance.service';
import { UploadResourcesComponent } from 'src/app/shared/upload-resources/upload-resources.component';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Evidence, MaintenanceDetail } from 'src/app/core/interfaces/maintenance-detail';
import { Router } from '@angular/router';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { MatSelectChange } from '@angular/material';
import { Fmt } from 'src/app/core/messages/fmt';
import { DateEnum } from 'src/app/core/enums/date.enum';
@Component({
  selector: 'app-create-edit-form-maintenance',
  templateUrl: './create-edit-form-maintenance.component.html',
  styleUrls: ['./create-edit-form-maintenance.component.scss']
})
export class CreateEditFormMaintenanceComponent implements OnInit {
  @ViewChild('uploadResourcesComponent', { static: false }) uploadResourcesComponent: UploadResourcesComponent;
  maintenanceTypeList: CatalogItem[];
  maintenanceForm: FormGroup;
  executionDateFilter = DateManager.filter({ until: new Date() });
  expirationDateFilter = (date: Date | null): boolean => {
    const controlValue = this.maintenanceForm && this.maintenanceForm.get('expirationDate') && this.maintenanceForm.get('expirationDate').value;
    if (controlValue) {
      const controlDate = new Date(controlValue);
      if (date && controlDate.getTime() === date.getTime()) return true;
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date ? date >= today : false;
  };
  // Inputs
  @Input() licensePlate: string = '';
  @Input() maintenance: MaintenanceDetail;
  // Outputs
  @Output() refreshList: EventEmitter<boolean> = new EventEmitter();
  maintenancePath: string = '';
  maintenanceRecordsList: CatalogItem[] = [];
  evidence: Evidence[];
  maxLengthObservations: number = 500;
  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private snackbarService: SnackBarService,
    public utils: Utils,
    private maintenanceService: MaintenanceService,
    private router: Router
  ) { }

  async ngOnInit() {
    await this.getMaintenanceTypes();
    await this.getMaintenanceRecords();
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.licensePlate) this.maintenancePath = `maintenances/evidences/${this.licensePlate}/maintenanceEvidence`;
  }

  getMaintenanceTypes(): Promise<void> {
    return new Promise((resolve, reject) => {
      const maintenanceTypeObserver = {
        next: (data: { catalog: CatalogItem[] }) => {
          this.spinner.hide();
          if (data && data.catalog && data.catalog.length) this.maintenanceTypeList = data.catalog;
          resolve();
        },
        error: (error) => {
          this.spinner.hide();
          this.initForm();
          reject(error);
        }
      };
      this.spinner.show();
      this.maintenanceService.getMaintenanceTypes().subscribe(maintenanceTypeObserver);
    });
  }

  getMaintenanceRecords(): Promise<void> {
    return new Promise((resolve, reject) => {
      const maintenanceRecordObserver = {
        next: (data: { catalog: CatalogItem[] }) => {
          this.spinner.hide();
          if (data && data.catalog && data.catalog.length) this.maintenanceRecordsList = data.catalog;
          resolve();
        },
        error: (error) => {
          this.spinner.hide();
          this.initForm();
          reject(error);
        }
      };
      this.spinner.show();
      this.maintenanceService.getMaintenanceRecords().subscribe(maintenanceRecordObserver);
    });
  }

  initForm() {
    this.maintenanceForm = this.fb.group({
      maintenanceType: ['', [Validators.required]],
      executionDate: ['', [Validators.required]],
      expirationDate: [''],

      alert: [false, Validators.required],
      mileage: [0],

      maintenanceRecords: [[], Validators.required],

      observations: ['', Validators.maxLength(this.maxLengthObservations)],
    });

    if (this.maintenance) {
      const selectedTypeMaintenance = this.maintenanceTypeList.find(
        type => type.id === this.maintenance.maintenanceType.id
      );
      const selectedMaintenanceRecords = this.maintenance.maintenanceRecords.map(
        record => this.maintenanceRecordsList.find(item => item.id === record.id)
      );
      this.maintenanceForm.patchValue({
        ...this.maintenance,
        maintenanceType: selectedTypeMaintenance,
        executionDate: DateManager.stringToDate(this.maintenance.executionDate, DateEnum.YYYY_MM_DD_HH_mm_ZZ),
        expirationDate: DateManager.stringToDate(this.maintenance.expirationDate, DateEnum.YYYY_MM_DD_HH_mm_ZZ),
        maintenanceRecords: selectedMaintenanceRecords
      });
    }

  }

  async onSubmit() {
    this.maintenanceForm.markAllAsTouched();
    let invalidEvidences = !this.areEvidencesPreview && ((this.maintenance && (!this.maintenance.evidence || (this.maintenance.evidence && !this.maintenance.evidence.length))) || !this.maintenance);
    if (this.maintenanceForm.invalid || invalidEvidences) {
      if (this.utils.errorMessagesCustomized(this.maintenanceForm.get('maintenanceType'), 'tipo de mantenimiento')) return;
      else if (this.utils.errorMessagesCustomized(this.maintenanceForm.get('executionDate'), 'fecha de ejecución')) return;
      else if (invalidEvidences) {
        this.snackbarService.openSnackBar(FormMessages.NOT_EVIDENCES, undefined, 'alert');
        return;
      }
      else if (this.utils.errorMessagesCustomized(this.maintenanceForm.get('maintenanceRecords'), 'tipos de servicio')) return;
      else if (this.utils.errorMessagesCustomized(this.maintenanceForm.get('observations'), 'observaciones', null, this.maxLengthObservations)) return;
      else this.snackbarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
      return;
    }
    this.areEvidencesPreview && await this.uploadResourcesComponent.addEvidenciesToStorage();
    let body = {
      ...this.utils.clone(this.maintenanceForm.value),
      evidence: this.evidence ? this.evidence : this.maintenance && this.maintenance.evidence ? this.maintenance.evidence : null,
      licensePlate: this.licensePlate,
      executionDate: DateManager.dateToString(this.maintenanceForm.get('executionDate').value, DateEnum.YYYY_MM_DD_HH_mm_ZZ),
      expirationDate: DateManager.dateToString(this.maintenanceForm.get('expirationDate').value, DateEnum.YYYY_MM_DD_HH_mm_ZZ)
    }
    this.createEditMaintenance(body, this.maintenance);
  }

  onMaintenanceRecordsChange($event: MatSelectChange) {
    if ($event.value && $event.value.length && $event.value.length > 10) {
      this.snackbarService.openSnackBar(Fmt.string(FormMessages.LIMIT_TYPE_SERVICES, '10'), undefined, 'alert');
      this.maintenanceForm.get('maintenanceRecords').setValue($event.value.slice(0, 10));
    }

  }

  createEditMaintenance(body: MaintenanceDetail | { evidence: Evidence[] }, maintenance: MaintenanceDetail) {
    let service = maintenance ? this.maintenanceService.updateMaintenanceVehicle(maintenance.id, body) : this.maintenanceService.createMaintenance(body);
    const createMaintenanceObserver = {
      next: (data: MaintenanceDetail) => {
        this.spinner.hide();
        if (data) {
          if ('evidence' in body && !('licensePlate' in body)) this.snackbarService.openSnackBar(Fmt.string(ServiceMessages.EVIDENCE_SUCCESS_DELETE, 'eliminada(s)'), undefined, 'success');
          else {
            this.snackbarService.openSnackBar(Fmt.string(ServiceMessages.SUCCESS_CREATE_EDIT_MAINTENANCE, maintenance ? 'editado' : 'creado'), undefined, 'success');
            if (maintenance) this.refreshList.emit(true);
            else this.router.navigate(['/administration/maintenance/detail', this.licensePlate]);
          }
        }
        else this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      },
      error: (error) => {
        this.spinner.hide();
        this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    };
    this.spinner.show();
    service.subscribe(createMaintenanceObserver);
  }

  cleanDate() {
    if (this.maintenanceForm && this.maintenanceForm.get('expirationDate')) this.maintenanceForm.get('expirationDate').setValue('');
  }

  processPaths($event: { result: { path: string }[], type: string }) {
    if ($event) {
      if ($event.type === 'add') {
        if (this.maintenance && this.maintenance.evidence && this.maintenance.evidence.length) this.maintenance.evidence = [...$event.result, ...this.maintenance.evidence];
        else this.evidence = $event.result;
      } else {
        $event.result.forEach((file) => {
          const index = this.maintenance.evidence.findIndex(evidence => evidence.path === file.path);
          if (index !== -1) this.maintenance.evidence.splice(index, 1);
          this.createEditMaintenance({ evidence: this.maintenance.evidence }, this.maintenance);
        });
      }
    }
  }

  removeTag(index: number) {
    if (this.maintenanceForm && this.maintenanceForm.get('maintenanceRecords') && this.maintenanceForm.get('maintenanceRecords').value) {
      const currentRecords = this.maintenanceForm.get('maintenanceRecords').value;
      const updatedRecords = currentRecords.filter((_: CatalogItem, i: number) => i !== index);
      this.maintenanceForm.get('maintenanceRecords').setValue(updatedRecords);
    }
  }

  cancelAction(type: string) {
    if (type === 'update') this.refreshList.emit(true);
    else this.router.navigate(['/administration/maintenance/detail', this.licensePlate]);
  }

  // GETTERS

  getCalculateMaintenancePeriod(expirationDate: string): number {
    return this.maintenanceService.getCalculateMaintenancePeriod(expirationDate);
  }

  get areEvidencesPreview(): boolean {
    return this.uploadResourcesComponent.areEvidencesPreview;
  }
}
