import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../resources/utils';
import { UserState } from '../enums/user-state.enum';

@Pipe({
    name: 'StateUser'
})
export class StateUserPipe implements PipeTransform {

    constructor(
        public utils: Utils
    ) { }

    transform(state: string): string {
        if (!this.utils.isEmpty(state)) {
            switch (state) {
                case UserState.WRONG_DRIVER_DATA:
                    return UserState.WRONG_DRIVER_DATA;
                case UserState.PENDING:
                    return 'Estudio de seguridad en proceso';
                case UserState.PENDING_VALIDATE_DOCUMENTS:
                    return 'Pendiente validar documentos';
                case UserState.TRUORA_WRONG_DRIVER_DATA:
                    return UserState.TRUORA_WRONG_DRIVER_DATA;
                case UserState.INACTIVE:
                    return 'Inactivo';
                case UserState.ACTIVE:
                    return 'Activo';
                case UserState.DRIVER_LICENCE_NOT_ACTIVE:
                    return UserState.DRIVER_LICENCE_NOT_ACTIVE;
                case UserState.DRIVER_LICENCE_ACTIVE:
                    return UserState.DRIVER_LICENCE_ACTIVE;
                case UserState.ACTIVATE_BY_SCORE:
                    return UserState.ACTIVATE_BY_SCORE;
                case UserState.WITHOUT_VALIDATION:
                    return 'Activo';
                default:
                    return state;
            }
        }
        return state;
    }

}
