import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  Inject,
} from "@angular/core";
import * as firebase from "firebase/app";
import 'firebase/storage';
import { FreightForwarderEvidenceService } from "./cargo-evidence.service";
import { SnackBarService } from "src/app/core/services/snackBar.service";
import { AuthService } from "src/app/core/services/authentication.service";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AccountService } from "../../account/account.service";
import { Utils } from "src/app/core/resources/utils";
import { Global } from "src/app/core/resources/global";
import { FormBuilder } from "@angular/forms";
import { ContactService } from "src/app/public/contact/contact.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AddressCargo } from "src/app/core/interfaces/addressCargo";
import { FileStorage } from "src/app/core/interfaces/fileStorage";
import { Cargo } from "src/app/core/interfaces/cargo";
import { AngularFireStorage } from "@angular/fire/storage";
import { TrackGuide } from "src/app/core/interfaces/trackGuide";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { OpenImgComponent } from "src/app/shared/open-img/open-img.component";
import { FileService } from "src/app/shared/files/file.service";
import { Permission } from "src/app/core/resources/permission";
import { PermissionRole } from "src/app/core/resources/permission-role";
import { DialogComponent } from "src/app/shared/dialog/dialog.component";
import { ManualCreationCargoService } from "../manual-creation-cargo/manual-creation-cargo.service";
import { stat } from "fs";
declare var google: any;
import { ConfirmEndCargoComponent } from "../confirm-end-cargo/confirm-end-cargo.component";
import { ModalEnum } from "src/app/core/enums/modal.enum";
import { CargoStateEnum } from "src/app/core/enums/cargoState.enum";
import { ImageByAddress, imageEvidences } from "src/app/core/interfaces/imageByAddress";
import { Fingerprint } from "src/app/core/interfaces/fingerprint";
import { DatePipe } from "@angular/common";
import { DateFormatPipe } from "src/app/core/pipe/dateFormat.pipe";
import { DateManager } from "src/app/core/managers/date.manager";
import { FormMessages } from "src/app/core/messages/form-messages.enum";
import { Fmt } from "src/app/core/messages/fmt";
@Component({
  selector: "app-cargo-evidence",
  templateUrl: "./cargo-evidence.component.html",
  styleUrls: ["./cargo-evidence.component.scss"],
  providers: [FreightForwarderEvidenceService, DateFormatPipe, DatePipe],
})
export class CargoEvidenceComponent implements OnInit {
  public address: AddressCargo;
  public cargo: Cargo;
  public onlyShow: boolean;
  public typeAddress: string;
  public guide: TrackGuide;
  public showEndCargoBtn: boolean;
  public loadedEvidences: EventEmitter<any> = new EventEmitter();
  pathNoImage: string = "/assets/svg/icons/icon-no-file.svg";
  instanceModalEvidences: NgbModalRef;
  @ViewChild("modalEvidences", { static: false }) modalEvidences: ElementRef;
  @ViewChild('documentFileInput', { static: false }) documentFileInput: ElementRef<HTMLInputElement>;
  @ViewChild('truckFileInput', { static: false }) truckFileInput: ElementRef<HTMLInputElement>;
  instancesFilesStorageDocument: FileStorage[] = [];
  instancesFilesStorageTruck: FileStorage[] = [];
  addEvidency: boolean;
  public parent: number;
  public index: number;
  // parent: number;
  // index: number;
  basePath: string;
  listImageGuies: Array<any> = [];
  permission = Permission;
  allEvidences = [];
  endCargoBtnClicked: boolean = false;
  removeEvidence: boolean = false;
  onlySaveBtn: boolean = false;
  evidencesLoaded: boolean = false;

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private snackBarService: SnackBarService,
    private service: FreightForwarderEvidenceService,
    private authService: AuthService,
    private accountService: AccountService,
    public utils: Utils,
    private global: Global,
    private formBuilder: FormBuilder,
    private contactService: ContactService,
    private modalService: NgbModal,
    private angularFireStorage: AngularFireStorage,
    public dialogRef: MatDialogRef<CargoEvidenceComponent>,
    public datePipe: DatePipe,
    public dateFormatPipe: DateFormatPipe,
    @Inject(MAT_DIALOG_DATA)
    public paramsDialog: {
      addEvidency;
      parent;
      index;
      cargo;
      typeAddress;
      address;
      guide;
      showEndCargoBtn;
      changeState;
      evidenceRemoved;
      showBtnWithoutEvidence;
      vehicle;
      driver;
    },
    public dialog: MatDialog,
    private fileService: FileService,
    private permissionRole: PermissionRole,
    public manualCreationCargoService: ManualCreationCargoService
  ) {
    if (paramsDialog) {
      this.validateDialogParams();
      if (this.utils.isDefined(paramsDialog.addEvidency)) {
        this.showEvidence();
      } else {
        this.showEvidence();
      }
      if (this.utils.isDefined(paramsDialog.cargo)) {
        this.cargo.cargoFeature.uploadDownload.origin.addresses.forEach(
          (addr) => {
            this.getAllEvidences(addr);
          }
        );
        this.cargo.cargoFeature.uploadDownload.destination.forEach((dest) => {
          dest.addresses.forEach((addr) => {
            this.getAllEvidences(addr, dest.id);
          });
        });
      }
    }
  }

  ngOnInit() {
    if (this.cargo) {
      this.basePath =
        "/driver/" + this.cargo.driver + "/" + this.cargo.id + "/";
    }
  }

  validateDialogParams() {
    if (this.utils.isDefined(this.paramsDialog.addEvidency)) {
      this.addEvidency = this.paramsDialog.addEvidency;
    }
    if (this.utils.isDefined(this.paramsDialog.parent)) {
      this.parent = this.paramsDialog.parent;
    }
    if (this.utils.isDefined(this.paramsDialog.index)) {
      this.index = this.paramsDialog.index;
    }
    if (this.utils.isDefined(this.paramsDialog.guide)) {
      this.guide = this.paramsDialog.guide;
    }
    if (this.utils.isDefined(this.paramsDialog.cargo)) {
      this.cargo = this.paramsDialog.cargo;
    }
    if (this.utils.isDefined(this.paramsDialog.typeAddress)) {
      this.typeAddress = this.paramsDialog.typeAddress;
    }
    if (this.utils.isDefined(this.paramsDialog.address)) {
      this.address = this.paramsDialog.address;
    }
  }

  showEvidence() {
    if (this.cargo && this.address && !this.addEvidency) {
      if (
        (this.address.imagesTruck === undefined &&
          this.address.imagesDocument === undefined) ||
        (this.address.imagesTruck.length === 0 &&
          this.address.imagesDocument.length === 0)
      ) {
        this.getEvidencesCargo();
      }
    }

    if (this.guide) {
      this.guide.images.forEach((image) => {
        const storage = AuthService.fStorage;
        const pathReference = storage.ref(image);
        this.spinner.show();
        pathReference
          .getDownloadURL()
          .then(
            (data) => {
              this.listImageGuies.push(data);
            },
            (error) => {
              this.listImageGuies.push(this.pathNoImage);
            }
          )
          .catch(() => {
            this.listImageGuies.push(this.pathNoImage);
          })
          .finally(() => {
            this.spinner.hide();
          });
      });
    }
  }

  getEvidencesCargo() {
    this.evidencesLoaded = false;
    this.spinner.show();
    this.service
      .getImageByAddress(
        this.cargo.id,
        this.address.address,
        this.address.id,
        this.parent
      )
      .toPromise()
      .then((data: ImageByAddress) => {
        if (data && data.imageByAddressFingerprint && data.imageByAddressFingerprint.length) {
          this.setListImagesTruck(data);
          this.setListImagesDocument(data);
        }
        this.evidencesLoaded = true;
      })
      .catch((error) => {
        this.evidencesLoaded = true;
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  getAllEvidences(address, parent?) {
    this.spinner.show();
    this.service
      .getImageByAddress(this.cargo.id, address.address, address.id, parent)
      .toPromise()
      .then((data: ImageByAddress) => {
        if (data.imageByAddressFingerprint && data.imageByAddressFingerprint.length) {
          data.imageByAddressFingerprint.forEach((img) => {
            this.allEvidences.push(img);
          });
        }
      })
      .catch(() => { })
      .finally(() => {
        this.spinner.hide();
      });
  }

  setListImagesTruck(data: ImageByAddress) {
    const listImagesTruck = [];
    this.address.imagesTruck = [];
    if (data.imageByAddressFingerprint[0].imageTruck.length) {
      for (const image of data.imageByAddressFingerprint[0].imageTruck) {
        listImagesTruck.push(image);
      }
    }
    //la lista de las url publicas
    listImagesTruck.forEach((image: imageEvidences) => {
      this.getUrlFirestorage(image, this.address.imagesTruck);
    });
  }

  setListImagesDocument(data: ImageByAddress) {
    const listImagesDocument = [];
    this.address.imagesDocument = [];

    if (data.imageByAddressFingerprint[0].imageDocument.length) {
      for (const image of data.imageByAddressFingerprint[0].imageDocument) {
        listImagesDocument.push(image);
      }
    }
    listImagesDocument.forEach((image: imageEvidences) => {
      this.getUrlFirestorage(image, this.address.imagesDocument);
    });
  }

  getUrlFirestorage(image: imageEvidences, listImages: any[]) {
    const storage = AuthService.fStorage;
    const pathReference = storage.ref(image.path);
    this.spinner.show();
    pathReference
      .getDownloadURL()
      .then(
        (success) => {
          listImages.push({
            path: image.path,
            url: success,
            fingerprint: image && image.fingerprint ? image.fingerprint : null
          });
        },
        (error) => {
          listImages.push({
            path: image.path,
            url: this.pathNoImage,
            fingerprint: image && image.fingerprint ? image.fingerprint : null
          });
        }
      )
      .catch(() => {
        listImages.push({
          path: image.path,
          url: this.pathNoImage,
          fingerprint: image && image.fingerprint ? image.fingerprint : null
        });
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  openImageNewTab(url) {
    if (url === this.pathNoImage) {
      this.snackBarService.openSnackBar(
        "Lo sentimos, esta imágen ya no existe",
        undefined,
        "alert"
      );
    } else {
      //this.openImg(url);
      window.open(url);
    }
  }

  handleFileInput(e: Event & { target: { files: FileList } }, type: string) {


    if (e && e.target && e.target['files']) {
      const allowedExtensions = ['pdf', 'jpeg', 'jpg', 'png'];
      let unsupportedFile: boolean = false;
      for (let i = 0; i < e.target.files.length; i++) {
        const extension = e.target.files[i].name.split('.').reverse()[0].toLowerCase();
        if (!allowedExtensions.includes(extension)) {
          const extensionsList = allowedExtensions.join(', ');
          this.snackBarService.openSnackBar(Fmt.string(FormMessages.UNSUPPORTED_FILE, extensionsList), 'x', 'error');
          unsupportedFile = true;
        }
      }
      if (!unsupportedFile) this.getPreviewImage(e.target['files'], type);
    }

    if (this.documentFileInput && this.documentFileInput.nativeElement)
      this.documentFileInput.nativeElement.value = '';
    if (this.truckFileInput && this.truckFileInput.nativeElement)
      this.truckFileInput.nativeElement.value = '';
  }

  getPreviewImage(files: FileList, type: string) {
    Object.keys(files).forEach(async (key) => {
      const file = files[key];
      let url;
      if (file.type && file.type.match(/image\/*/))
        url = await this.utils.getImageBase64(file);
      else
        url = this.pathNoImage;

      if (url) this.addInstanceFileStorage(file, url, type);
      else this.snackBarService.openSnackBar(`No fue posible subir el archivo ${Number(key) + 1}`);
    });
  }

  onErrorRenderImage($event) {
    $event.srcElement.src = this.pathNoImage;
  }

  addInstanceFileStorage(file: File, url: string, type: string) {
    const splittedNameFile = file['name'].split('.');
    const formatFile = splittedNameFile[splittedNameFile.length - 1];
    const dateInMs = new Date().getTime();
    const fileName = `cargoEvidences_${dateInMs}.${formatFile}`;
    const modifiedFile = new File([file], fileName, {
      type: file.type
    });

    const i =
      this.instancesFilesStorageDocument.length > 0
        ? this.instancesFilesStorageDocument.length - 1
        : 0;
    const dataFile = {
      fileData: {
        file: modifiedFile,
        name:
          (type === "document" ? "cargo_document_" : "loaded_truk_") +
          i + '_' +
          fileName,
        uploaded: true,
        size: this.utils.bytesToSize(file.size),
        url,
      },
      storageData: {
        storageRef: null,
        uploadTask: null,
        uploadProgress: null,
      },
    };

    if (type === "document") {
      this.instancesFilesStorageDocument.push(dataFile);
    } else {
      this.instancesFilesStorageTruck.push(dataFile);
    }
  }

  public confirmDelete(type: string, img: object) {
    if (
      !this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.removeEvidences
      )
    ) {
      this.snackBarService.openSnackBar(
        "No tienes los permisos necesarios, solicitalo a seguridad",
        undefined,
        "error"
      );
      return;
    }
    const dialogConfig = new MatDialogConfig();
    let title;
    title =
      type === "document"
        ? `¿Estás seguro que deseas eliminar el documento?`
        : `¿Estás seguro que deseas eliminar la imagen?`;
    dialogConfig.data = {
      title: title,
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        if (this.paramsDialog.evidenceRemoved) {
          this.removeEvidence = true;
        }
        this.removeInstanceFileStorage(type, img);
      }
    });
  }

  removeInstanceFileStorage(type: string, img: object) {
    //esto es una copia de address
    let copyObjAddress = JSON.parse(JSON.stringify(this.address));
    let newObj: ImageByAddress = {
      idCargo: this.cargo.id,
      imageByAddressFingerprint: [],
    };

    if (type === "document") {
      let index = this.address.imagesDocument.findIndex(
        (image) => image.path === img["path"]
      );
      this.address.imagesDocument.splice(index, 1);
    } else {
      let index = this.address.imagesTruck.findIndex(
        (image) => image.path === img["path"]
      );
      this.address.imagesTruck.splice(index, 1);
    }
    let newAddress = {
      address: this.address.address,
      addressId: this.address.id,
      type: this.typeAddress,
      imageTruck: this.address.imagesTruck.map(function (obj) {
        return {
          path: obj.path,
          fingerprint: obj.fingerprint
        }
      }),
      imageDocument: this.address.imagesDocument.map(function (obj) {
        return {
          path: obj.path,
          fingerprint: obj.fingerprint
        }
      }),
    };

    if (this.typeAddress !== "Origin") {
      newAddress["destinationId"] = this.paramsDialog.parent;
    }
    if (this.allEvidences && this.allEvidences.length) {
      let oldAddressIndex = this.allEvidences.findIndex((addr) => {
        if (this.typeAddress === "Origin") {
          return (
            addr.type === this.typeAddress && addr.addressId === this.address.id
          );
        } else {
          return (
            addr.type === this.typeAddress && addr.addressId === this.address.id
          );
        }
      });
      if (oldAddressIndex !== -1) {
        this.allEvidences[oldAddressIndex] = newAddress;
        newObj.imageByAddressFingerprint = this.allEvidences;
        this.service.updateEvidence(newObj).subscribe((response) => {
          if (response) {
            this.snackBarService.openSnackBar(
              "Se ha eliminado la evidencia exitosamente"
            );
          } else {
            this.snackBarService.openSnackBar(
              "Ocurrio un error al eliminar la evidencia",
              undefined,
              "error"
            );
            this.address = copyObjAddress;
          }
        });
      } else {
        this.snackBarService.openSnackBar(
          "Ocurrio un error al eliminar la evidencia",
          undefined,
          "error"
        );
      }
    } else {
      this.snackBarService.openSnackBar(
        "Ocurrio un error al eliminar la evidencia",
        undefined,
        "error"
      );
    }
  }

  addEvidenciesToStorage() {
    this.spinner.show();
    if (
      this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.finishCargo
      )
    ) {
      this.onlySaveBtn = true;
    }
    let count = 1;
    const evidencies = this.instancesFilesStorageDocument.concat(
      this.instancesFilesStorageTruck
    );

    evidencies.map((file) => {
      this.fileService
        .addMultipleFilesToStorages(
          this.basePath,
          file.fileData.name,
          file.fileData.file
        )
        .then((success) => {
          if (count === evidencies.length) {
            this.setStatusAddress(false, false, this.address);
            return;
          } else {
            count++;
          }
        })
        .catch((error) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar(
            "Ocurrió un error al guardar las evidencias",
            undefined,
            "error"
          );
          return;
        });
    });
  }

  get hasEvidens() {
    return !this.utils.isEmpty(this.address.imagesTruck) &&
      !this.utils.isEmpty(this.address.imagesDocument) &&
      this.address.imagesTruck.length &&
      this.address.imagesDocument.length;
  }

  get hasImagesTruck() {
    return !!(!this.utils.isEmpty(this.address.imagesTruck) &&
      this.address.imagesTruck.length);
  }

  get hasImagesDocument() {
    return !!(!this.utils.isEmpty(this.address.imagesDocument) &&
      this.address.imagesDocument.length);
  }

  endCargo() {
    this.endCargoBtnClicked = true;
    this.setStatusAddress(false, false, this.address);
  }

  public get hasImages() {
    return (
      this.instancesFilesStorageTruck.length > 0 ||
      this.instancesFilesStorageDocument.length > 0
    );
  }

  public get showSaveButton() {
    return this.hasImages && (this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.uploadEvidences
    ) ||
      this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.uploadExtraDocumentsCargo
      ) ||
      this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.finishCargo
      ));
  }

  setStatusAddress(
    imagesEmpty: boolean,
    newTypeAddress: boolean,
    address: AddressCargo,
    destinationId?: number
  ) {
    let typeAddress = this.typeAddress;

    let state = null;

    if (newTypeAddress) {
      // Finalizó en el origen e inicia el viaje al destino
      typeAddress = "Destination";
      state = "Start trip";
    } else {
      if (typeAddress === "Origin")
        state = imagesEmpty ? "Arrived to pickup cargo" : "Pickup load";
      else if (typeAddress === "Destination")
        state = imagesEmpty ? "Destination arrived" : "Cargo unloaded";
    }

    let data = {
      idCargo: this.cargo.id,
      addressId: address.id,
      address: address.address,
      type: typeAddress,
      imageTruck: [],
      imageDocument: [],
    };

    if (this.utils.isDefined(this.parent) || destinationId > -1) {
      data["destinationId"] = this.parent || destinationId;
    }

    if (imagesEmpty) {
      data.imageTruck = [];
      data.imageDocument = [];
    } else {
      data.imageTruck = this.instancesFilesStorageTruck.map((instance) => {
        return this.basePath + instance.fileData.name;
      });
      data.imageDocument = this.instancesFilesStorageDocument.map(
        (instance) => {
          return this.basePath + instance.fileData.name;
        }
      );
    }

    //
    if ((this.hasEvidens || this.hasImagesTruck || this.hasImagesDocument || (this.cargo && this.cargo.startTripDate)) && state !== "Cargo unloaded") {
      this.addExtraImagesEvidences(data);
    } else if (this.cargo.state === CargoStateEnum.END_SERVICE) {
      this.addExtraImagesEvidences(data);
    } else if (
      state === "Cargo unloaded"
    ) {
      //this.addExtraImagesEvidences(data);
      this.setStateAddressTracking(data, state);
    } else {
      if (state !== "Cargo unloaded") {
        this.setStateAddressTracking(data, state);
      } else if (this.paramsDialog.changeState) {
        this.setStateAddressTracking(data, state);
      }
    }
    //

    /*const hasImagesToSave = data.imageDocument.length > 0 || data.imageTruck.length > 0;
    hasImagesToSave ? this.addExtraImagesEvidences(data) : 0;
    this.setStateAddressTracking(data, state);*/
  }

  addExtraImagesEvidences(data) {
    this.spinner.show();
    this.service.addExtraImageToBack(data).subscribe(
      (success: Cargo) => {
        this.spinner.hide();
        this.dialogRef.close({
          state: true,
          data: this.utils.clone(success),
        });
      },
      (error) => {
        this.spinner.hide();
        this.showErrorSendingEvidences();
      }
    );
  }

  showErrorSendingEvidences() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `Ocurrió un error al enviar las evidencias`,
      iconError: true,
      descriptionHTML: '<p class="text-center">No hay comunicación con el RNDC, por favor intente más tarde</p>',
      hideBtnConfirm: true,
    }
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    this.dialog.open(DialogComponent, dialogConfig);
  }

  confirmEndService(data, state) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      cargo: this.cargo,
      user: this.authService.getUserSession().information.name,
      driver: this.paramsDialog.driver,
      vehicle: this.paramsDialog.vehicle
    }
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    const dialogRef = this.dialog.open(ConfirmEndCargoComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.setStateAddressTracking(data, state);
      }
    });
  }

  setStateAddressTracking(data, state) {
    this.spinner.show();
    this.service.setStateAddressTracking(data, state).subscribe(
      (success: Cargo) => {
        this.spinner.hide();
        this.dialogRef.close({
          state: true,
          data: this.utils.clone(success),
          changeState: true
        });
      },
      (error) => {
        this.spinner.hide();
        this.showErrorSendingEvidences();
      }
    );
  }

  public enableEvidenceActions(): boolean {
    return (
      (this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.uploadEvidences
      ) &&
        this.cargo.approval !== "Approved") ||
      this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.uploadExtraDocumentsCargo
      )
    );
  }

  public get allowToEndService(): boolean {
    if (this.paramsDialog.showEndCargoBtn) {
      return this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.finishCargo
      );
    }
    return this.paramsDialog.address.state !== "Cargo unloaded";
  }

  public get showContinueWithoutPhotos(): boolean {
    return (
      this.typeAddress !== "Origin" &&
      !this.hasEvidens &&
      (!this.instancesFilesStorageTruck.length ||
        !this.instancesFilesStorageDocument.length)
    );
  }

  public goSetStateAddress() {
    this.setStatusAddress(false, false, this.address);
  }

  formatDate(date: string): string {
    return this.datePipe.transform(DateManager.stringToDate(date));
  }
  //GETTERS
  getFingerprintToolTip(fingerprint: Fingerprint): string {
    return 'Subido por: ' + fingerprint.userName + '\n' + 'Fecha: ' + this.formatDate(fingerprint.date) + '\n' + 'Hora: ' + this.dateFormatPipe.transform(fingerprint.date, 'time');
  }
}
