import { ModuleRole } from './moduleRole';
import { RolAccess } from './rolAccess';

export interface Role {
    role?: string;
    access?: RolAccess[];
    id: string;
    nit: string;
    name: string;
    modules?: ModuleRole[];
    notificationTypes?: Array<string>;
}


export class RoleModel {
    public model = {
        nit: '',
        id: '',
        name: '',
    };
}
