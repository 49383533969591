import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { NgxSpinnerService } from "ngx-spinner";
import { CargoGpsDTO, Gps } from "src/app/core/interfaces/gps";
import { Model, Vehicle } from "src/app/core/interfaces/vehicle";
import { ReactiveForm } from "src/app/core/resources/reactive-form";
import { Utils } from "src/app/core/resources/utils";
import { SnackBarService } from "src/app/core/services/snackBar.service";
import { SelectGpsComponent } from "src/app/shared/select-gps/select-gps.component";
import { CreateGpsVehicleComponent } from "../create-gps-vehicle/create-gps-vehicle.component";
import { VehiclesService } from "../list-vehicles.service";
import { Permission } from "src/app/core/resources/permission";
import { DeleteGpsVehicleComponent } from "../delete-gps-vehicle/delete-gps-vehicle.component";
import { FormMessages } from "src/app/core/messages/form-messages.enum";
import { ModalEnum } from "src/app/core/enums/modal.enum";
import { OptionsAutocomplete } from "src/app/core/interfaces/optionsAutocomplete";
import { Patterns } from "src/app/core/resources/patterns";
import { Subscription } from "rxjs";
import { Fmt } from "src/app/core/messages/fmt";
import { Cargo } from "src/app/core/interfaces/cargo";
import { PermissionRole } from "src/app/core/resources/permission-role";
@Component({
  selector: "app-gps-vehicle",
  templateUrl: "./gps-vehicle.component.html",
  styleUrls: ["./gps-vehicle.component.scss"],
  providers: [Model],
})
export class GpsVehicleComponent implements OnInit {
  @Input() title: string;
  @Input() form: FormGroup;
  @Input() updateGPS?: boolean;
  @Input() vehicle?: Vehicle;
  reactiveForm: ReactiveForm;
  permission = Permission;
  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  @ViewChild(SelectGpsComponent, { static: false })
  selectGpsComponent: SelectGpsComponent;
  gpsTypeSub: Subscription;
  isPortableSub: Subscription;
  validate: string = '';
  optionsGps: OptionsAutocomplete = {
    hint: 'Por favor seleccione una opcion de la lista, no copiar url del GPS'
  };
  constructor(
    public dialogRef: MatDialogRef<GpsVehicleComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogParams: {
      form?: FormGroup,
      onlyGPS?: boolean,
      vehicle?: Vehicle,
      checkPortableUnit?: boolean,
      cargo?: Cargo
    },
    public utils: Utils,
    private spinner: NgxSpinnerService,
    private vehiclesService: VehiclesService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    public patterns: Patterns,
    private permissionRole: PermissionRole,
  ) {
    if (this.dialogParams && this.dialogParams.form) {
      if (this.dialogParams.form) {
        this.form = this.dialogParams.form;
        this.form.enable();
        this.optionsGps['initialValue'] = this.gpsControls.value;
      }
      if (this.dialogParams.onlyGPS)
        this.updateGPS = this.dialogParams.onlyGPS;

      if (this.dialogParams.vehicle)
        this.vehicle = this.dialogParams.vehicle;
    }
    if (this.updateGPS) {
      this.form.get('gpsType').setValidators(Validators.required);
      this.form.get('userGps').setValidators(Validators.required);
      this.form.get('passwordGps').setValidators(Validators.required);
      if (this.isPortable && this.dialogParams.checkPortableUnit && this.isSatrack)
        this.form.get('gpsId').setValidators(Validators.required);
    }
  }
  ngOnInit(): void {
    this.setSubscription();
  }

  private setSubscription() {
    this.gpsTypeSub = this.form.get('gpsType').valueChanges.subscribe((gps: string) => {
      if (gps) {
        if (!this.isSatrack) {
          this.form.get('userGps').setValidators(Validators.required);
          this.form.get('passwordGps').setValidators(Validators.required);
          this.form.get('isPortableUnit').setValue(false);
        }
      } else {
        this.form.get('userGps').setValidators(null);
        this.form.get('passwordGps').setValidators(null);
        this.form.get('isPortableUnit').setValue(false);
      }
      this.form.updateValueAndValidity();
    });
    this.isPortableSub = this.form.get('isPortableUnit').valueChanges.subscribe((isP: boolean) => {
      if (isP && this.dialogParams.checkPortableUnit && this.isSatrack)
        this.form.get('gpsId').setValidators(Validators.required);
      else
        this.form.get('gpsId').setValidators(null);
      this.form.get('gpsId').updateValueAndValidity();
    })
  }

  /**
  * @returns {FormControl} returns the form's gpsType as FormControl
  * @description Gets the form's gpsType as FormControl
  */
  public get gpsControls(): FormControl {
    return this.form.get("gpsType") as FormControl;
  }

  /**
  * @returns {boolean} returns true if the vehicle has satrack gps
  * @description Gets true if the vehicle has satrack gps
  */
  get isSatrack(): boolean {
    return this.gpsControls && this.gpsControls.value && typeof this.gpsControls.value === "string"
      && this.gpsControls.value.toLowerCase() === 'satrack';
  }

  /**
  * @returns {boolean} returns true if the vehicle has portable unit
  * @description Gets true if the vehicle has portable unit
  */
  get isPortable(): boolean {
    return this.form.get("isPortableUnit") && !!this.form.get("isPortableUnit").value;
  }

  /**
  * @returns {boolean} returns true if the user has portable gps permission
  * @description Gets true if the user has portable gps permission
  */
  get hasPortablePermission(): boolean {
    return this.permissionRole.hasPermission(this.permission.administration.module, this.permission.administration.useGpsPortable);
  }

  /**
  * @description Verifies if the form is valid and updates the vehicle's gps and closes the modal
  */
  public onSubmit() {
    this.validate = 'touched';
    this.form.markAllAsTouched();
    const gpsType: string = this.form.get('gpsType').value;
    const userGps = this.form.get('userGps').value;
    const passwordGps = this.form.get('passwordGps').value;

    !gpsType ? this.form.get('gpsType').setErrors({ required: true }) : this.form.get('gpsType').setErrors(null);
    !userGps ? this.form.get('userGps').setErrors({ required: true }) : this.form.get('userGps').setErrors(null);
    !passwordGps ? this.form.get('passwordGps').setErrors({ required: true }) : this.form.get('passwordGps').setErrors(null);

    this.form.updateValueAndValidity();

    if (this.form.get('gpsType').invalid) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.AT_LEAST_ONE_OPTION_VAR, 'tipo de gps'), undefined, 'alert');
      return;
    }
    if (this.utils.errorMessagesCustomized(this.form.get('gpsType'), 'tipo de GPS')) return;
    if (this.utils.errorMessagesCustomized(this.form.get('userGps'), 'usuario del GPS')) return;
    if (this.utils.errorMessagesCustomized(this.form.get('passwordGps'), 'contraseña de GPS')) return;
    if (this.utils.errorMessagesCustomized(this.form.get('gpsId'), 'código del GPS portable')) return;
    if (this.form.invalid) {
      this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
      return;
    }
    if (!this.isPortable)
      this.form.get('gpsId').setValue('');

    this.updateVehicleGPS(this.form.value);
  }

  private updateVehicleGPS(body: Gps) {
    this.spinner.show();
    this.vehiclesService.updateGpsDocuments(this.vehicle.id, body).subscribe(
      (success) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(
          "Datos guardados correctamente",
          undefined,
          "success"
        );
        this.emitToParent.emit();
        this.dialogRef.close({ state: true });
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(
          "Ocurrió un error al guardar los datos",
          undefined,
          "error"
        );
      }
    );
  }

  /**
  * @description Opens a modal to create a new GPS
  */
  public createGPS(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const modalDialog = this.dialog.open(
      CreateGpsVehicleComponent,
      dialogConfig
    );
    modalDialog.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.emitToParent.emit();
        this.refreshListGps();
      }
    });
  }

  /**
  * @description Opens a modal to delete some GPS
  */
  public deleteGPS(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const modalDialog = this.dialog.open(
      DeleteGpsVehicleComponent,
      dialogConfig
    );
    modalDialog.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.emitToParent.emit();
        this.refreshListGps();
      }
    });
  }

  /**
  * @description Closes the current modal
  */
  public closeDialog(value?: boolean): void {
    if (this.dialogRef && this.dialogRef.close) {
      if (this.utils.isDefined(value)) {
        this.dialogRef.close({ state: true });
      } else {
        this.dialogRef.close();
      }
    }
  }

  private refreshListGps(): void {
    if (this.selectGpsComponent && this.selectGpsComponent.getListGpsTypes) {
      this.selectGpsComponent.getListGpsTypes();
    }
  }

  ngOnDestroy(): void {
    if (this.gpsTypeSub) this.gpsTypeSub.unsubscribe();
    if (this.isPortableSub) this.isPortableSub.unsubscribe();
  }
}
