import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { Media } from 'src/app/core/interfaces/media';
import { UploadSource } from 'src/app/core/interfaces/upload-source';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { AuthService } from 'src/app/core/services/authentication.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { DateFormatPipe } from 'src/app/core/pipe/dateFormat.pipe';
import { Fingerprint } from 'src/app/core/interfaces/fingerprint';
import { DateManager } from 'src/app/core/managers/date.manager';
import { DatePipe } from '@angular/common';
import { FileService } from '../files/file.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { DialogComponent } from '../dialog/dialog.component';
import { element, utils } from 'protractor';
@Component({
  selector: 'app-upload-resources',
  templateUrl: './upload-resources.component.html',
  styleUrls: ['./upload-resources.component.scss'],
  providers: [DateFormatPipe, DatePipe]
})
export class UploadResourcesComponent implements OnInit {
  // Childs
  @ViewChild('resourceUpload', { static: false }) resourceUpload: ElementRef<HTMLInputElement>;
  // Inputs
  @Input() media: Media[] = [];
  @Input() path: string = '';
  @Input() textName: string = 'archivos';
  @Input() showButtonActions: boolean = false;
  @Input() showAlertResources: boolean = false;
  @Input() unableDeleteAllEvidences: boolean = false;
  @Output() storageResources: EventEmitter<{ result: { path: string }[], type: string }> = new EventEmitter();
  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter();
  // Properties
  resources: UploadSource[] = [];
  previewUrls: string[] = [];
  pathNoImage: string = "/assets/svg/icons/icon-no-file.svg";
  allRender: boolean = false;
  showSuccess: boolean = false;
  constructor(
    private snackBarService: SnackBarService,
    public domSanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    public dateFormatPipe: DateFormatPipe,
    public datePipe: DatePipe,
    private fileService: FileService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.getResources();
  }
  async getResources() {
    if (this.media && this.media.length) {
      const promises = this.media.map(element => {
        if (element) return this.getUrlFirestorage(element);
      });
      try {
        await Promise.all(promises);
      } catch (error) {
        console.error("Error loading media:", error);
      }
    } else this.allRender = true;
  }

  onImageLoad(image: { src: string, loaded: boolean }, index: number) {
    if (index === this.resources.length - 1) this.allRender = true;
    image.loaded = true;
  }
  onErrorRenderImage($event: { srcElement: { src: string } }) {
    $event.srcElement.src = this.pathNoImage;
  }

  handleFileInput(e: Event & { target: { files: FileList } }) {
    if (e && e.target && e.target['files']) {
      const allowedExtensions = ['pdf', 'jpeg', 'jpg', 'png'];
      let unsupportedFile: boolean = false;
      for (let i = 0; i < e.target.files.length; i++) {
        const extension = e.target.files[i].name.split('.').reverse()[0].toLowerCase();
        if (!allowedExtensions.includes(extension)) {
          const extensionsList = allowedExtensions.join(', ');
          this.snackBarService.openSnackBar(Fmt.string(FormMessages.UNSUPPORTED_FILE, extensionsList), 'x', 'error');
          unsupportedFile = true;
        }
      }
      if (!unsupportedFile) this.getPreviewImage(e);
    } else this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error')

    if (this.resourceUpload && this.resourceUpload.nativeElement) this.resourceUpload.nativeElement.value = '';
  }

  getPreviewImage(event: Event): void {
    const fileInput = event.target as HTMLInputElement;
    const files = fileInput.files;
    if (files && files.length > 0) {
      Array.from(files).forEach(file => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const fileUrl = URL.createObjectURL(file);
          let src: string | SafeResourceUrl = this.sanitizeUrl(fileUrl);
          let formatFile: string = file.name.split('.').reverse()[0].toLowerCase();
          const dateInMs = new Date().getTime();
          const path = `${this.path ? this.path : 'resource'}_${dateInMs}.${formatFile}`;
          this.resources.push({ src: src, loaded: false, state: 'preview', type: formatFile === 'pdf' ? 'pdf' : 'image', path: path, file: file });
        };
        reader.readAsDataURL(file);
      });
    }
  }

  sanitizeUrl(url: string) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getUrlFirestorage(media: Media): Promise<void> {
    return new Promise((resolve, reject) => {
      const storage = AuthService.fStorage;
      const pathReference = storage.ref(media.path);

      pathReference
        .getDownloadURL()
        .then(success => {
          this.resources.push({
            src: success,
            loaded: true,
            state: 'storage',
            fingerprint: media && media.fingerprint ? media.fingerprint : null,
            type: media.path.split('.').pop().toLowerCase() === 'pdf' ? 'pdf' : 'image',
            path: media.path
          });
          resolve();
        })
        .catch(() => {
          this.resources.push({
            src: this.pathNoImage,
            loaded: true,
            state: 'storage',
            fingerprint: media && media.fingerprint ? media.fingerprint : null,
            type: media.path.split('.').pop().toLowerCase() === 'pdf' ? 'pdf' : 'image',
            path: media.path
          });
          reject();
        });
    });
  }

  // Save Files
  async addEvidenciesToStorage() {
    this.spinner.show();
    const previewResources = this.resources.filter((resource) => resource.state === 'preview');

    try {
      const uploadPromises = previewResources.map(async (resource: UploadSource) => {
        try {
          await this.fileService.addMultipleFilesToStorages(
            null,
            null,
            resource.file,
            resource.path
          );
          return { success: true, resource };
        } catch {
          return { success: false, resource };
        }
      });
      const results = await Promise.all(uploadPromises);
      this.spinner.hide();
      const allSuccessful = results.every(result => result.success);

      if (allSuccessful) {
        this.showSuccess && this.snackBarService.openSnackBar(
          Fmt.string(ServiceMessages.SUCCESS_LOAD_RESOURCES, this.textName),
          undefined,
          "success"
        );
      } else {
        this.snackBarService.openSnackBar(
          Fmt.string(ServiceMessages.ERROR_LOAD_RESOURCES, this.textName),
          undefined,
          "error"
        );
      }
      const response: { path: string }[] = results.map((result) => ({
        path: result.resource.path,
      }));
      this.storageResources.emit({ result: response, type: 'add' });

    } catch (error) {
      this.spinner.hide();
      this.snackBarService.openSnackBar(
        Fmt.string(ServiceMessages.ERROR_LOAD_RESOURCES, this.textName),
        undefined,
        "error"
      );
    }
  }


  formatDate(date: string): string {
    return this.datePipe.transform(DateManager.stringToDate(date));
  }

  confirmDelete(event: MouseEvent, resource: UploadSource, index: number) {
    event.stopPropagation();
    if (resource.state === 'preview') this.resources.splice(index, 1);
    else {
      if (this.unableDeleteAllEvidences && this.resources.length === 1) {
        this.snackBarService.openSnackBar(
          FormMessages.UNABLE_DELETE_ALL_EVIDENCES,
          undefined,
          "error"
        );
        return;
      }
      const dialogConfig = new MatDialogConfig();
      let title: string = '';
      title = `¿Estas seguro que deseas eliminar ${this.textName ? `la ${this.textName}` : 'el archivo'}?`;
      dialogConfig.data = {
        title: title,
      };
      dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
      dialogConfig.autoFocus = false;
      dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
      dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
      const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((result) => {
        if (result && result.state) {
          this.removeInstanceFileStorage(resource.path, index);
        }
      });
    }
  }

  private removeInstanceFileStorage(path: string, index: number) {
    this.spinner.show();
    this.fileService.deleteFilesFromStorage(path)
      .then((success) => {
        this.spinner.hide();
        if (success) {
          this.storageResources.emit({ result: [{ path: this.resources[index].path }], type: 'remove' })
          this.resources.splice(index, 1);
        }
      })
      .catch((error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(
          "Ocurrió un error al guardar las evidencias",
          undefined,
          "error"
        );
        return;
      });
  }



  //GETTERS
  getFingerprintToolTip(fingerprint: Fingerprint): string {
    return 'Subido por: ' + fingerprint.userName + '\n' + 'Fecha: ' + this.formatDate(fingerprint.date) + '\n' + 'Hora: ' + this.dateFormatPipe.transform(fingerprint.date, 'time');
  }

  get areEvidencesPreview(): boolean {
    return this.resources.some((resource) => {
      return resource.state === 'preview';
    });
  }
}
