import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { DateManager } from 'src/app/core/managers/date.manager';
import { AdminUsersService } from '../../admin-users/admin-users.service';
import { User } from 'src/app/core/interfaces/user';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { Router } from '@angular/router';
import { MaintenanceService } from '../maintenance.service';
import { Permission } from 'src/app/core/resources/permission';
@Component({
  selector: 'app-maintenance-summary',
  templateUrl: './maintenance-summary.component.html',
  styleUrls: ['./maintenance-summary.component.scss']
})
export class MaintenanceSummaryComponent implements OnInit {
  @Input() vehicle: Vehicle;
  @Input() enableActions: boolean = false;
  defaultTypeVehiclePath: string = '/assets/svg/icons/icon-camion.svg';
  driver: User;
  permission = Permission;
  constructor(
    private adminUserService: AdminUsersService,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private router: Router,
    private maintenanceService: MaintenanceService
  ) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.vehicle && changes.vehicle.currentValue) {
      if (changes.vehicle.currentValue && changes.vehicle.currentValue.driver && changes.vehicle.currentValue.driver.document) {
        this.getDriverInfo(this.vehicle.driver.document);
      }
    }
  }


  getDriverInfo(document: string) {
    const driverInfoObserver = {
      next: (data: User) => {
        this.spinner.hide();
        if (data) this.driver = data;
      },
      error: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    };
    this.spinner.show();
    this.adminUserService.getUsersDetailByDocument(document).subscribe(driverInfoObserver);
  }

  createMaintenance() {
    this.maintenanceService.setLoadingRoute('/administration/maintenance/detail', this.vehicle.id);
    this.router.navigate(['/administration/maintenance/create', this.vehicle.id]);
  }

  // Getters
  getDocumentExpedirationDate(expirationDate: string): string {
    return DateManager.formatDate(expirationDate, 'YYYY-MM-DDTHH:mm:ssZ', 'DD/MM/YYYY');
  }


}
