import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Utils } from 'src/app/core/resources/utils';

@Component({
  selector: 'app-license-form',
  templateUrl: './license-form.component.html',
  styleUrls: ['./license-form.component.scss']
})
export class LicenseFormComponent {
  expeditionDateFilter = DateManager.filter({ until: new Date() });
  expirationDateFilter = DateManager.filter({ from: new Date() });
  // Inputs
  @Input() form: FormGroup;
  @Input() options: {inputStyle: string};
  @Input() index: number;
  @Input() enableDeleteLicense: boolean = false;
  // Outputs
  @Output() emitIndexLicense: EventEmitter<number> = new EventEmitter<number>();
  // Properties
  categories = ['A1', 'A2', 'B1', 'B2', 'B3', 'C1', 'C2', 'C3'];
  constructor(
    public utils: Utils
  ) { }
}
