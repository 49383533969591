<div class="back-button ml-4" [routerLink]="['/administration/maintenance/list']">
  <i class="fas fa-arrow-left"></i><p class="m-0">Listado de vehículos</p>
</div>
<aside class="maintenance-list-detail">
  <div class="maintenance-list-detail__summary">
    <app-maintenance-summary [vehicle]="vehicle" [enableActions]="true"></app-maintenance-summary>
  </div>
  <div class="maintenance-list-detail__filter d-flex justify-content-center justify-content-sm-start align-items-center px-2 pl-sm-4 pt-2 pt-md-0 flex-wrap mb-3">
    <div class="px-4" [ngClass]="{'maintenance-list-detail__filter--active': viewActive === 'all'}" (click)="processFilters('all')">
        Todos
    </div>
    <div class="px-4" [ngClass]="{'maintenance-list-detail__filter--active': viewActive === 'Mantenimiento correctivo'}" (click)="processFilters('Mantenimiento correctivo')">
        Correctivo
    </div>
    <div class="px-4" [ngClass]="{'maintenance-list-detail__filter--active': viewActive === 'Mantenimiento preventivo'}" (click)="processFilters('Mantenimiento preventivo')">
        Preventivo
    </div>
  </div>
  <div
    *ngIf="paginationList.getList().length && flag"
    class="maintenance-list-detail__body"
    infinite-scroll
    [infiniteScrollDistance]="paginationList.getScrollDistance()"
    [infiniteScrollThrottle]="paginationList.getThrottle()"
    [scrollWindow]="false"
    (scrolled)="onScrollDown()">
      <div class="maintenance-list-detail__body--item" *ngFor="let maintenance of paginationList.getList(); let i = index;">
        <app-maintenance-item-detail [maintenance]="maintenance" [vehicle]="vehicle" (refreshList)="processFilters()"></app-maintenance-item-detail>
      </div>
  </div>
  <div
    *ngIf="!paginationList.getList().length && flag"
    class="maintenance-list-detail__not-content">
    <div class="maintenance-list-detail__not-content--icon"><i class="fas fa-calendar-times"></i></div>
      <h4 class="text-bold text-center">No hay resultados que coincidan con la búsqueda</h4>
  </div>
  <div class="maintenance-list-detail__skeleton-container h-100" *ngIf="!flag">
    <ngx-skeleton-loader [theme]="{height: '191px'}" count="3" appearance="line" class="maintenance-list-detail__skeleton-container--item"></ngx-skeleton-loader>
  </div>
</aside>
