<form [formGroup]="form" *ngIf="form">
  <span class="separator-horizontal"></span>
  <div class="row title-2 text-strong color-secondary-dark">
    <div class="col-md-12">
      {{title}}
    </div>
  </div>

  <div class="row mt-3">
    <!-- Name -->
    <div class="col-sm">
      <mat-form-field appearance="standard">
        <mat-label>
          {{!form.get('lastName')?'Empresa':'Nombre'}}<span class="asterisk"
            *ngIf="utils.isRequiredField(form.get('name'))">*</span>
        </mat-label>
        <input matInput type="text" formControlName="name">
        <mat-error *ngIf="form.get('name').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
    </div>
    <!-- lastName -->
    <div class="col-sm" *ngIf="form.get('lastName')">
      <mat-form-field appearance="standard">
        <mat-label>Apellido<span class="asterisk"
            *ngIf="utils.isRequiredField(form.get('lastName'))">*</span></mat-label>
        <input matInput type="text" formControlName="lastName">
        <mat-error *ngIf="form.get('lastName').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
    </div>
    <!-- phone -->
    <div class="col-sm">
      <mat-form-field appearance="standard">
        <mat-label>Celular<span class="asterisk" *ngIf="utils.isRequiredField(form.get('phone'))">*</span></mat-label>
        <input matInput type="text" onlyNumbersInt formControlName="phone">
        <mat-error *ngIf="form.get('phone').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
        <mat-error *ngIf="form.get('phone').errors?.pattern">
          {{ utils.giveMessageError('INCORRECT_FORMAT', 'celular') }}
        </mat-error>
      </mat-form-field>
    </div>
    <!-- remak -->
    <div class="col-md" *ngIf="canValidateAndRemarkVehicleReferences">
      <mat-form-field appearance="standard">
        <mat-label>Observaciones</mat-label>
        <input matInput type="text" formControlName="remark">
      </mat-form-field>
    </div>
    <!-- validated -->
    <div class="col-md" *ngIf="canValidateAndRemarkVehicleReferences">
      <mat-form-field appearance="standard">
        <mat-label>¿Validado?</mat-label>
        <mat-select formControlName="validated">
          <mat-option [value]="true">Sí</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </div>
</form>