<div class="license-form">
    <p *ngIf="utils.isDefined(index)" class="text-strong color-secondary-dark">Licencia {{index + 1}}</p>
    <i *ngIf="index && enableDeleteLicense" matTooltip="Eliminar licencia" class="fas fa-trash fa-lg license-form__delete-button" (click)="emitIndexLicense.emit(index)"></i>
    <mat-form-field [appearance]="options?.inputStyle || 'outline'" class="col-lg-4 col-md-6">
        <mat-label>Categoría<span class="asterisk"
            *ngIf="utils.isRequiredField(form?.get('category'))">*</span></mat-label>
        <mat-select [formControl]="form?.get('category')">
          <mat-option *ngFor="let category of categories" [value]="category">
            {{category}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form?.get('category').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
    </mat-form-field>
    <mat-form-field [appearance]="options?.inputStyle || 'outline'" class="col-lg-4 col-md-6">
        <mat-label>N° de licencia<span class="asterisk"
            *ngIf="utils.isRequiredField(form?.get('numberLicense'))">*</span></mat-label>
        <input matInput type="text" onlyNumbersInt [formControl]="form?.get('numberLicense')">
        <mat-error *ngIf="form?.get('numberLicense').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
    </mat-form-field>
    <mat-form-field [appearance]="options?.inputStyle || 'outline'" class="col-lg-4 col-md-6">
        <mat-label>Restricciones</mat-label>
        <input matInput type="text" [formControl]="form?.get('restrictions')">
    </mat-form-field>
    <mat-form-field [appearance]="options?.inputStyle || 'outline'" class="col-lg-4 col-md-6">
        <mat-label>Fecha de expedición<span class="asterisk"
            *ngIf="utils.isRequiredField(form?.get('expeditionDate'))">*</span></mat-label>
        <input matInput [matDatepicker]="licenseExpeditionPicker" [matDatepickerFilter]="expeditionDateFilter" [formControl]="form.get('expeditionDate')"
            readonly (click)="licenseExpeditionPicker.open()">
        <mat-datepicker-toggle matSuffix [for]="licenseExpeditionPicker"></mat-datepicker-toggle>
        <mat-datepicker #licenseExpeditionPicker></mat-datepicker>
        <mat-error *ngIf="form?.get('expeditionDate').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
    </mat-form-field>
    <mat-form-field [appearance]="options?.inputStyle || 'outline'" class="col-lg-4 col-md-6">
        <mat-label>Fecha de expiración<span class="asterisk"
            *ngIf="utils.isRequiredField(form?.get('expirationDate'))">*</span></mat-label>
        <input matInput [matDatepicker]="licenseExpirationPicker" [matDatepickerFilter]="expirationDateFilter" [formControl]="form.get('expirationDate')"
            readonly (click)="licenseExpirationPicker.open()">
        <mat-datepicker-toggle matSuffix [for]="licenseExpirationPicker"></mat-datepicker-toggle>
        <mat-datepicker #licenseExpirationPicker></mat-datepicker>
        <mat-error *ngIf="form?.get('expirationDate').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
    </mat-form-field>
    <div class="col-lg-4 col-md-6">
        <label class="label-radio-button">¿Está activa?<span class="asterisk"
            *ngIf="utils.isRequiredField(form?.get('active'))">*</span></label>
        <mat-radio-group [formControl]="form.get('active')" class="d-flex justify-content-around flex-wrap">
            <mat-radio-button [value]="true">
                Si
            </mat-radio-button>
            <mat-radio-button [value]="false">
                No
            </mat-radio-button>
        </mat-radio-group>
    </div>
</div>