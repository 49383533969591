<form class="py-4 px-2 px-md-4 position-relative" [formGroup]="GPSForm" (submit)="onSubmit()">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <h4 class="text-bold pl-0 my-3">
        Crear tipo de GPS
    </h4>
    <div class="d-flex flex-wrap container-form">
        <mat-form-field appearance="outline" class="col-lg-4 col-md-6">
            <mat-label>Nombre<span class="asterisk">*</span></mat-label>
            <input matInput type="text" formControlName="name">
            <mat-error *ngIf="GPSForm.get('name').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-lg-4 col-md-6">
            <mat-label>URL<span class="asterisk">*</span></mat-label>
            <input matInput type="text" formControlName="id">
            <mat-error *ngIf="GPSForm.get('id').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="addGPS()" type="button"
            class="primary-secondary button-width-auto col-lg-4 col-12 m-0">
            <i class="fas fa-plus-circle"></i>
            <span>Añadir</span>
        </button>
    </div>
    <div class="container-list" *ngIf="arrayGPS && arrayGPS.length>0">
        <div class="row container-card-gps" *ngFor="let gps of arrayGPS; let i = index">
            <div class="col-md-6 col-12 word-wrap">
                <section class="title-item">Nombre</section>
                {{gps.name}}
            </div>
            <div class="col-md-6 col-12 word-wrap">
                <section class="title-item">url</section>
                <a href="{{gps.id}}">{{gps.id}}</a>
            </div>
            <i class="fas fa-times" (click)="deleteGPS(i)"></i>
        </div>
    </div>
    <div class="w-100 button-container">
        <button mat-raised-button class="primary-secondary button-width-auto" color="primary"
            (click)="dialogRef.close()" type="button">
            Cancelar
        </button>
        <button mat-raised-button color="primary" type="submit" class="button-width-auto bg-color-primary">
            Guardar
        </button>
    </div>
</form>