<div class="cargo-container">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <h2 class="title-component mt-2 mb-4">Servicios disponibles para la placa {{ dialogParams.licensePlate}}</h2>
  <!-- <div class="col-12 mt-3 p-0 mb-4" *ngIf="!PermissionAssignVehicle"> -->
  <div class="col-12 mt-3 p-0 mb-4" *ngIf="!PermissionAssignVehicle">
    <div class="container-vehicle">
      <div class="vehicle-data text-center"
        *ngIf="dialogParams && dialogParams.numberOfTrips < tripsToCargo.TRIPS_TO_MEDIUM_END">
        <div class="alert alert-warning" role="alert">
          Este vehículo solo puede llevar servicios con un valor menor a <b>{{amountsCargoEnum.LOW_END |
            currency:'COP':'code'}} </b>
        </div>
      </div>
      <div class="vehicle-data text-center"
        *ngIf="dialogParams && dialogParams.numberOfTrips >= tripsToCargo.TRIPS_TO_MEDIUM_END && dialogParams.numberOfTrips < tripsToCargo.TRIPS_TO_HIGH_END">
        <div class="alert alert-warning" role="alert">
          Este vehículo solo puede llevar servicios con un valor entre <b>{{amountsCargoEnum.LOW_END |
            currency:'COP':'code'}}</b> y <b>{{amountsCargoEnum.MEDIUM_END | currency:'COP':'code'}}</b>
        </div>
      </div>
    </div>
  </div>
  <div class="cards-container">
    <div *ngFor="let match of dialogParams.filteredCargosTurns.matches; let i = index" class="card">
      <mat-card class="card-overlay d-flex flex-row">
        <mat-card-content *ngIf="match">
          <div class="row">
            <div *ngIf="match.idCompany" class="col-12 col-md-6">
              <span class="title-purple">
                Operación:
              </span>
              <span class="text-bold label-field">
                {{ match && match.idCompany?(match.idCompany | companyName).split(' ').length>5?(match.idCompany |
                companyName).split(' ').slice(0,5).join(' ')+'...':(match.idCompany | companyName):''}}
              </span>
            </div>
            <div *ngIf="match.consecutive" class="col-12 col-md-6">
              <span class="title-purple">
                Consecutivo de servicio N°:
              </span>
              <span class="text-bold label-field">
                {{ match.consecutive}}
              </span>
            </div>
            <div *ngIf="match.dateLoad" class="col-12 col-md-6">
              <span class="title-purple">
                Día de cargue:
              </span>
              <span class="text-bold label-field">
                {{ match.dateLoad | dateFormat:'only-date'}}
              </span>
            </div>
            <div
              *ngIf="match.cargoFeature && match.cargoFeature.uploadDownload && match.cargoFeature.uploadDownload.origin && match.cargoFeature.uploadDownload.origin.name"
              class="col-12 col-md-6">
              <span class="title-purple">
                Origen:
              </span>
              <span class="text-bold label-field">
                {{ match.cargoFeature.uploadDownload.origin.name}}
              </span>
            </div>
            <div
              *ngIf="match.cargoFeature && match.cargoFeature.uploadDownload && match.cargoFeature.uploadDownload && match.cargoFeature.uploadDownload.destination && match.cargoFeature.uploadDownload.destination.length > 0"
              class="col-12 col-md-6">
              <span class="title-purple">
                Destino:
              </span>
              <span class="text-bold label-field">
                {{ match.cargoFeature.uploadDownload.destination[match.cargoFeature.uploadDownload.destination.length -
                1].name }}
              </span>
            </div>
            <div *ngIf="match.cargoFeature && match.cargoFeature.vehicleType && match.cargoFeature.vehicleType.name"
              class="col-12 col-md-6">
              <span class="title-purple">
                Tipo de vehiculo:
              </span>
              <span class="text-bold label-field">
                {{ match.cargoFeature.vehicleType.name }}
              </span>
            </div>
            <div
              *ngIf="match.cargoFeature && match.cargoFeature.vehicleType && match.cargoFeature.vehicleType.bodyWorkType && match.cargoFeature.vehicleType.bodyWorkType.name"
              class="col-12 col-md-6">
              <span class="title-purple">
                Tipo de carrocería:
              </span>
              <span class="text-bold label-field">
                {{ match.cargoFeature.vehicleType.bodyWorkType.name}}
              </span>
            </div>
            <div *ngIf="match.cargoFeature && match.cargoFeature.cargoMeasure && match.cargoFeature.cargoMeasure.amount"
              class="col-12 col-md-6">
              <span class="title-purple">
                Valor del servicio:
              </span>
              <span class="text-bold label-field">
                {{ match.cargoFeature.cargoMeasure.amount | currency:'COP':'code'}}
              </span>
            </div>
            <div class="col-12 mt-4">
              <div class="actions-card d-flex justify-content-around align-items-center">
                <button *ngIf="assignTurnVehicles" class="assign-button" mat-raised-button color="primary"
                  (click)="assignCargoToTurn(match)">
                  <span>Asignar vehículo</span>
                  <i class="fas fa-truck"></i>
                </button>
                <button mat-raised-button class="primary-secondary" color="primary" (click)="openDetailCargo(match)">
                  <span>Ver detalles del servicio</span>
                  <i class="fas fa-info-circle"></i>
                </button>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <app-assign-vehicle-cargo hidden [cargo]="match"
        (onAssignVehicle)="onAssignVehicle($event)"></app-assign-vehicle-cargo>
    </div>
  </div>
</div>