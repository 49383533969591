import { Component } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { ManualCreationCargoService } from '../manual-creation-cargo/manual-creation-cargo.service';
import { Permission } from 'src/app/core/resources/permission';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-cargo-tabs-create',
  templateUrl: './cargo-tabs-create.component.html',
  styleUrls: ['./cargo-tabs-create.component.scss'],
  providers: [AuthService, Global, DatePipe]
})

export class CargoCreateTabsComponent {

  // DatePicker
  displayMonths = 2;
  navigation = 'select';
  showWeekNumbers = false;
  outsideDays = 'visible';
  showListLatestRequests = false;
  permission = Permission;
  tabs: { alias: string, permission: string }[] = [
    { alias: 'Manual', permission: this.permission.cargo.createManualCargo },
    { alias: 'Solicitud', permission: this.permission.cargo.createCargoRequest },
    { alias: 'Por guías', permission: this.permission.cargo.createCargoByGuides },
    { alias: 'Masivas', permission: this.permission.cargo.createMassiveCargo },
    { alias: 'Desde una ruta', permission: this.permission.cargo.createCargoFromRoute },
    { alias: "Servicio de escolta", permission: this.permission.cargo.createEscortService }
  ]

  constructor(
    public manualCreationCargoService: ManualCreationCargoService,
    private authService: AuthService
  ) { }

  onSelectTab(event) {
    switch (event) {
      case 1:
        this.showListLatestRequests = true;
        break;
    }
  }

  get permissions(): string[] {
    return this.tabs.map(tab => tab.permission);
  }

}
