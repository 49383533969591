import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { CatalogItem } from 'src/app/core/interfaces/catalogItem';
import { TravelConcept, TravelExpense, TravelExpensesFormState } from 'src/app/core/interfaces/travel-expense';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { TravelExpensesService } from 'src/app/core/services/travel-expenses.service';

@Component({
  selector: 'app-travel-expenses',
  templateUrl: './travel-expenses.component.html',
  styleUrls: ['./travel-expenses.component.scss']
})
export class TravelExpensesComponent implements OnInit {
  isModal: boolean = false;
  concepts: Array<TravelConcept> = [];

  totalValue: number = 0;

  @Input() form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { cargoId: string, cargoConsecutive: string, form: { travelExpenses: TravelExpense[] }, addEmpty?: boolean },
    public ref: MatDialogRef<TravelExpensesComponent>,
    private travelExpensesService: TravelExpensesService,
    private spinner: NgxSpinnerService,
    private snackbarService: SnackBarService,
    public utils: Utils,
  ) {
    if (this.data && this.data.cargoId) {
      this.isModal = true;
    }
    if (this.data && this.data.form) {
      this.form = new FormGroup({
        travelExpenses: new FormArray([])
      });
      if (this.data.form.travelExpenses) {
        this.data.form.travelExpenses.forEach(
          (travelExpense: TravelExpense) => {
            const travelExpenseGroup = new FormGroup({
              id: new FormControl(travelExpense.id),
              cargoId: new FormControl(travelExpense.cargoId),
              cargoConsecutive: new FormControl(travelExpense.cargoConsecutive),
              totalPaid: new FormControl(travelExpense.totalPaid, [Validators.required, Validators.min(100)]),
              travelExpensesType: new FormControl(travelExpense.travelExpensesType, [Validators.required]),
              state: new FormControl(travelExpense.state, [Validators.required]),
              approval: new FormControl(travelExpense.approval, [Validators.required]),
              paid: new FormControl(travelExpense.paid)
            });
            (this.form.get('travelExpenses') as FormArray).push(travelExpenseGroup);
          }
        );
      }
    }

    this.travelExpensesService.getTypes().subscribe(
      (travelExpenseConcepts: CatalogItem[]) => {
        if (travelExpenseConcepts && travelExpenseConcepts.length)
          this.concepts = travelExpenseConcepts as TravelConcept[];
        else
          this.concepts = [];
      }, (error) => {
        this.concepts = [];
      });
  }

  ngOnInit() {
    this.form.valueChanges.subscribe(() => {
      TravelExpensesService.state = this.form.value as TravelExpensesFormState;
      this.calculateTotal();
    });
    this.calculateTotal();

    if (this.data && this.data.addEmpty) {
      this.addTravelExpense();
    }
  }

  calculateTotal() {
    let total = 0;
    const travelExpenses = (this.form.get('travelExpenses') as FormArray);

    travelExpenses.controls.forEach((control) => {
      const totalPaid = parseInt(control.get('totalPaid').value || 0);
      total += totalPaid;
    });
    this.totalValue = total;
  }

  addTravelExpense() {
    const travelExpenses = (this.form.get('travelExpenses') as FormArray);
    travelExpenses.push(
      new FormGroup({
        cargoId: new FormControl(''),
        cargoConsecutive: new FormControl(''),
        travelExpensesType: new FormControl(null, [Validators.required]),
        totalPaid: new FormControl(0, [Validators.required, Validators.min(100)])
      })
    )
  }

  remove(index: number) {
    const travelExpenses = (this.form.get('travelExpenses') as FormArray);
    const id = travelExpenses.at(index) && (travelExpenses.at(index) as FormGroup).get('id')
      ? (travelExpenses.at(index) as FormGroup).get('id').value
      : null;
    if (!this.isModal || !id) {
      travelExpenses.removeAt(index);
      return;
    }
    this.spinner.show();
    this.travelExpensesService.removeTravelExpense(id).subscribe(
      () => {
        this.snackbarService.openSnackBar('Se ha eliminado el viático correctamente');
        travelExpenses.removeAt(index);
        this.spinner.hide();
      },
      () => {
        this.snackbarService.openSnackBar('Se ha eliminado el viático correctamente');
        travelExpenses.removeAt(index);
        this.spinner.hide();
      }
    );
  }

  save() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      const travelExpenses = (this.form.get('travelExpenses') as FormArray);
      let errorFound = false;
      for (let i = 0; i < travelExpenses.controls.length; i++) {
        const travel = travelExpenses.controls[i];
        if (this.utils.errorMessagesCustomized(travel.get('travelExpensesType'), `concepto del viático ${i + 1}`))
          errorFound = true;
        else if (this.utils.errorMessagesCustomized(travel.get('totalPaid'), `valor del viático ${i + 1}`, null, null, 100))
          errorFound = true;
        if (errorFound) break;
      }
      if (!errorFound) this.snackbarService.openSnackBar(FormMessages.INVALID_TRAVEL_EXPENSES_FORM, 'x', 'error');
      return;
    }

    this.spinner.show();
    const travelExpenses = (this.form.get('travelExpenses') as FormArray);

    const promises = [];

    travelExpenses.controls.forEach(
      (group: FormGroup) => {
        group.get('cargoId').setValue(this.data.cargoId);
        group.get('cargoConsecutive').setValue(this.data.cargoConsecutive);
        const travelExpense = group.value;

        if (!!travelExpense.id) {
          // Update existing travel expense
          const { travelExpensesType, totalPaid, state, approval } = travelExpense;
          const promise = this.travelExpensesService
            .updateTravelExpense(travelExpense.id, { travelExpensesType, totalPaid, state, approval })
            .toPromise();
          promises.push(promise);
        } else {
          // Add new travel expense
          const promise = this.travelExpensesService
            .createTravelExpense(travelExpense)
            .toPromise()
          promises.push(promise);
        }

      }
    );

    Promise.all(promises)
      .then(() => this.spinner.hide(), () => this.spinner.hide())
      .catch(() => {
        this.snackbarService.openSnackBar('Uno o varios viáticos no se han guardado correctamente.', undefined, 'error');
        this.spinner.hide()
      }).finally(() => this.ref.close());
  }

  compareTravelExpensesType(obj1: TravelConcept | null, obj2: TravelConcept | null) {
    if (obj1 === null && obj2 === null)
      return true;
    return obj1.id && obj2.id && obj1.id === obj2.id;
  }

  get travelExpenses(): FormArray {
    return this.form ? this.form.get('travelExpenses') as FormArray : null;
  }

  get travelExpensesControls(): AbstractControl[] {
    return this.travelExpenses ? this.travelExpenses.controls : [];
  }

}
