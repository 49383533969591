<div class="mb-3 mx-3 p-0 service-request" *ngIf="serviceRequest">
    <div class="service-request__container">
      <div class="service-request__content row p-0 w-100 m-0">
        <div class="col-12 col-xl-9 service-request__info p-0">
          <div class="row service-request__detail m-0 pl-4 pb-3">
            <!-- Creation date -->
            <div class="col-sm-6 col-md-3 col-lg-3 service-request__creation-date">
              <span class="service-request__creation-date--text color-secondary-dark text-bold">
                Fecha: {{ serviceRequest?.creationFingerprint?.date ? (serviceRequest?.creationFingerprint?.date | dateFormat:'only-date') : '-'}}
              </span>
            </div>

            <!-- Publishing zone -->
            <div class="col-sm-6 col-md-3 col-lg-6 service-request__publishing-zone">
              <span class="service-request__publishing-zone--text color-secondary-dark text-bold" >
                Zona de publicación: {{ serviceRequest?.publishingZone ? (serviceRequest?.publishingZone | publishingZone) : '-'}}
              </span>
            </div>

            <!-- Consecutive -->
            <div class="col-sm-6 col-md-3 col-lg-3 service-request__consecutive">
              <span class="service-request__consecutive--text color-secondary-dark text-bold">
                Consecutivo: {{ serviceRequest?.consecutive ? serviceRequest?.consecutive : '-'}}
              </span>
            </div>
            <!-- Origin -->
            <div class="col-sm-6 col-md-3 col-lg-3 service-request__origin">
              <span class="service-request__origin--title">
                Origen
              </span>
              <br>
              <span class="text-bold color-primary-dark service-request__origin--value">
                {{ serviceRequest?.cargoFeature?.uploadDownload?.origin?.name ? serviceRequest?.cargoFeature?.uploadDownload?.origin?.name : '-' }}
                {{serviceRequest?.cargoFeature?.uploadDownload?.origin?.country ?
                  '('+serviceRequest?.cargoFeature?.uploadDownload?.origin?.country .name+')':''}}
              </span>
            </div>

            <!-- Date load -->
            <div class="col-sm-6 col-md-3 col-lg-3 service-request__date-load">
              <span class="service-request__date-load--title">
                Fecha de cargue
              </span>
              <br>
              <span class="text-bold color-primary-dark service-request__date-load--value">
                {{ serviceRequest?.dateLoad ? (dateManager.transformDateUFC(serviceRequest.dateLoad)|dateFormat:'only-date') : '-' }}
              </span>
            </div>

            <!-- Destination -->
            <div class="col-sm-6 col-md-3 col-lg-3 service-request__destination">
              <span class="service-request__destination--title">
                Destino
              </span>
              <br>
              <span class="text-bold color-primary-dark service-request__destination--value">
                {{
                  serviceRequest?.cargoFeature?.uploadDownload?.destination?.length
                  ? serviceRequest.cargoFeature.uploadDownload.destination[
                  serviceRequest.cargoFeature.uploadDownload.destination.length - 1
                  ]?.name
                  : '-'
                  }}
                  {{
                  serviceRequest?.cargoFeature?.uploadDownload?.destination?.length &&
                  serviceRequest.cargoFeature.uploadDownload.destination[
                  serviceRequest.cargoFeature.uploadDownload.destination.length - 1
                  ]?.country
                  ? '(' +
                  serviceRequest.cargoFeature.uploadDownload.destination[
                  serviceRequest.cargoFeature.uploadDownload.destination.length - 1
                  ].country?.name +
                  ')'
                  : ''
                  }}
              </span>
            </div>

             <!-- Date download -->
             <div class="col-sm-6 col-md-3 col-lg-3 service-request__date-download">
              <span class="service-request__date-download--title">
                Fecha de descargue
              </span>
              <br>
              <span class="text-bold color-primary-dark service-request__date-download--value">
                {{
                  serviceRequest?.cargoFeature?.uploadDownload?.destination?.length
                  ? (dateManager.transformDateUFC(serviceRequest.cargoFeature.uploadDownload.destination[
                  serviceRequest.cargoFeature.uploadDownload.destination.length - 1
                  ]?.downloadDate) | dateFormat:'only-date')
                  : '-'
                  }}
              </span>
            </div>

            <!-- Rate -->
            <div class="col-sm-6 col-md-3 col-lg-3 service-request__rate">
              <span class="service-request__rate--title">
                Tarifa
              </span>
              <br>
              <span class="text-bold color-primary-dark service-request__rate--value">
                {{ serviceRequest?.rate ? (serviceRequest.rate|currency:'COP':'code') : '-' }}
              </span>
            </div>

            <!-- Vehicle type -->
            <div class="col-sm-6 col-md-3 col-lg-3 service-request__vehicle-type">
              <span class="service-request__vehicle-type--title">
                Tipo de vehículo
              </span>
              <br>
              <span class="text-bold color-primary-dark service-request__vehicle-type--value">
                {{ serviceRequest?.cargoFeature?.vehicleType?.name ? serviceRequest?.cargoFeature?.vehicleType?.name : '-' }}
              </span>
            </div>

            <!-- bodyWorkType -->
            <div class="col-sm-6 col-md-3 col-lg-3 service-request__bodyWork-type">
              <span class="service-request__bodyWork--title">
                Tipo de carrocería
              </span>
              <br>
              <span class="text-bold color-primary-dark service-request__bodyWork--value">
                {{ serviceRequest?.cargoFeature?.vehicleType?.bodyWorkType?.name ? serviceRequest?.cargoFeature?.vehicleType?.bodyWorkType?.name : '-' }}
              </span>
            </div>

            <!-- Vehicle quantity -->
            <div class="col-sm-6 col-md-3 col-lg-3 service-request__vehicle-quantity">
              <span class="service-request__vehicle-quantity--title">
                Cantidad de vehículos
              </span>
              <br>
              <span class="text-bold color-primary-dark service-request__vehicle-quantity--value">
                {{ serviceRequest?.cargoFeature?.vehicleType?.quantity ? serviceRequest?.cargoFeature?.vehicleType?.quantity : '-' }}
              </span>
            </div>

            <!-- Total weight -->
            <div class="col-sm-6 col-md-3 col-lg-3 service-request__total-weight">
              <span class="service-request__total-weight--title">
                Peso de servicio
              </span>
              <br>
              <span class="text-bold color-primary-dark service-request__total-weight--value">
                {{ serviceRequest?.cargoFeature?.cargoMeasure?.totalWeigth ? serviceRequest?.cargoFeature?.cargoMeasure?.totalWeigth : '-' }}
              </span>
            </div>


            <!-- Cubic capacity -->
            <div class="col-sm-6 col-md-3 col-lg-3 service-request__cubic-capacity">
              <span class="service-request__cubic-capacity--title">
                Cubicaje
              </span>
              <br>
              <span class="text-bold color-primary-dark service-request__cubic-capacity--value">
                {{ serviceRequest?.cargoFeature?.cargoMeasure?.totalMeasurement ? serviceRequest?.cargoFeature?.cargoMeasure?.totalMeasurement + 'm³' : '-' }}
              </span>
            </div>

            <!-- Observations -->
            <div class="col-sm-6 col-md-3 col-lg-3 service-request__observations" *ngIf="serviceRequest?.observation">
              <span class="service-request__observations--title">
                Observaciones
              </span>
              <br>
              <span class="text-bold color-primary-dark cursor-pointer service-request__observations--value">
                <span *ngIf="serviceRequest?.observation"><u matTooltip="{{serviceRequest?.observation}}" matTooltipPosition="above"
                    matTooltipClass="extended-tooltip">
                    Servicio
                  </u></span>
                <span *ngIf="serviceRequest?.observationDriver"> {{ serviceRequest?.observation ? '-' : ''}} <u matTooltip="{{serviceRequest?.observationDriver}}"
                    matTooltipPosition="above" matTooltipClass="extended-tooltip">
                    Conductor
                  </u></span>
              </span>
            </div>

        </div>

        </div>
        <div class="col-12 col-xl-3 button-container service-request__actions p-3 px-sm-4">
          <button mat-raised-button class="primary service-request__actions--select-offer" color="primary" (click)="selectOffer(serviceRequest)">
            <div>
              5
            </div>
            <span>Seleccionar oferta</span>
          </button>
          <button mat-raised-button class="primary-secondary service-request__actions--continue-editing" color="primary" (click)="openDetailServiceRequest(serviceRequest)">
            <div><span>Seguir diligenciando</span><i class="fa fa-pencil-alt" color="primary"></i></div>
          </button>
          <u class="service-request__actions--options">Opciones</u>
        </div>
      </div>
    </div>
</div>
