<!-- Upload arl -->
<div class="col-md-4 doc-container">
    <mat-form-field appearance="standard">
        <mat-label>Arl<span class="asterisk"
        *ngIf="utils.isRequiredField(visualArl)">*</span></mat-label>
        <input type="text" matInput [formControl]="visualArl">
        <app-docs-state
        *ngIf="form?.get('extraDocuments')?.value?.length && getDocumentSupport('ARL') && typeAction === 'editDriver' && (form.get('state.description').value !== 'Pending' || authService?.getCompanySaaS()?.allowCreatingWithoutTruora) && adminUsersService.getExtraDocumentByType('ARL', form.getRawValue())"
        matSuffix [expireDocs]="expireArl" [documents]="form.get('extraDocuments').value"
        [userState]="getState('ARL')" [dueDateDoc]="getDueDate('ARL')"
        type="ARL"></app-docs-state>
        <i 
        *ngIf="form.get('state.description').value !== 'Pending' || authService?.getCompanySaaS()?.allowCreatingWithoutTruora"
        matSuffix 
        (click)="uploadDocument('ARL')"
        class="fas fa-plus-circle color-primary cursor-pointer ml-1"
        matTooltip="Agregar soporte de ARL" matTooltipPosition="above"></i>


    </mat-form-field>
    <span *ngIf="typeAction === 'editDriver' && arlState" class="badge badge-warning badge-size doc-container--state" [ngClass]="getBadgeClass(arlState)">{{ arlState }}</span>
    <div *ngIf="loadingResources && visualArl?.value" class="spinner-container">
        <i class="fas fa-spinner fa-spin"></i>
    </div>
    <ng-container *ngIf="adminUsersService?.getResourceFile('arl') || adminUsersService?.getErrorLoadResource('arl')">
        <app-previsualization-resources matSuffix [resource]="adminUsersService?.getResourceFile('arl')" [errorLoadResource]="adminUsersService?.getErrorLoadResource('arl')"></app-previsualization-resources>
    </ng-container>
</div>
    <!-- Upload eps -->
<div class="col-md-4 doc-container">
    <mat-form-field appearance="standard">
        <mat-label>Eps<span class="asterisk"
        *ngIf="utils.isRequiredField(visualEps)">*</span></mat-label>
        <input type="text" matInput [formControl]="visualEps">
        <app-docs-state
        *ngIf="form?.get('extraDocuments')?.value?.length && getDocumentSupport('EPS') && typeAction === 'editDriver' && (form.get('state.description').value !== 'Pending' || authService?.getCompanySaaS()?.allowCreatingWithoutTruora) && adminUsersService.getExtraDocumentByType('EPS', form.getRawValue())"
        matSuffix [expireDocs]="expireEps" [documents]="form.get('extraDocuments').value"
        [userState]="getState('EPS')" [dueDateDoc]="getDueDate('EPS')"
        type="EPS"></app-docs-state>
        <i 
        *ngIf="form.get('state.description').value !== 'Pending' || authService?.getCompanySaaS()?.allowCreatingWithoutTruora"
        matSuffix 
        (click)="uploadDocument('EPS')"
        class="fas fa-plus-circle color-primary cursor-pointer ml-1"
        matTooltip="Agregar soporte de EPS" matTooltipPosition="above"></i>
    </mat-form-field>
    <span *ngIf="typeAction === 'editDriver' && epsState" class="badge badge-warning badge-size doc-container--state" [ngClass]="getBadgeClass(epsState)">{{ epsState }}</span>
    <div *ngIf="loadingResources && visualEps?.value" class="spinner-container">
        <i class="fas fa-spinner fa-spin"></i>
    </div>
    <ng-container *ngIf="adminUsersService?.getResourceFile('eps') || adminUsersService?.getErrorLoadResource('eps')">
        <app-previsualization-resources matSuffix [resource]="adminUsersService?.getResourceFile('eps')" [errorLoadResource]="adminUsersService?.getErrorLoadResource('eps')"></app-previsualization-resources>
    </ng-container>
</div>

<div class="col-md-4 doc-container" *ngIf="hasEscortServicesCompany">
    <!-- *ngIf="hasEscortServicesCompany && formAdditionalCertifications?.get('OPERATIONAL_ACCREDITATION')"> -->
    <mat-form-field appearance="standard">
    <mat-label>Acreditación de personal operativo<span *ngIf="utils.isRequiredField(visualOperationalAccreditation)" class="asterisk">*</span></mat-label>
    <input matInput type="text" [formControl]="visualOperationalAccreditation">
    <app-docs-state
    *ngIf="form?.get('extraDocuments')?.value?.length && getDocumentSupport(additionalCertificationEnum.OPERATIONAL_ACCREDITATION) && typeAction === 'editDriver' && (form.get('state.description').value !== 'Pending' || authService?.getCompanySaaS()?.allowCreatingWithoutTruora) && adminUsersService.getExtraDocumentByType('EPS', form.getRawValue())"
    matSuffix [expireDocs]="expireOperationalAccreditation" [documents]="form.get('extraDocuments').value"
    [userState]="getStateOperationalAccreditation()" [dueDateDoc]="getDueDateOperationalAccreditation()"
    [type]="additionalCertificationEnum.OPERATIONAL_ACCREDITATION"></app-docs-state>
    <i *ngIf="form.get('state.description').value !== 'Pending' || authService?.getCompanySaaS()?.allowCreatingWithoutTruora" matSuffix permission-display [module]="permission.cargo.module"
        [functionality]="permission.cargo.addDocumentsSupport"
        (click)="uploadDocument(additionalCertificationEnum.OPERATIONAL_ACCREDITATION)"
        class="fas fa-plus-circle color-primary cursor-pointer ml-1"
        matTooltip="Agregar soporte de Acreditación" matTooltipPosition="above"></i>
    </mat-form-field>
    <div *ngIf="loadingResources && visualOperationalAccreditation?.value" class="spinner-container">
        <i class="fas fa-spinner fa-spin"></i>
    </div>
    <span *ngIf="typeAction === 'editDriver' && operationalAccreditationState" class="badge badge-warning badge-size doc-container--state" [ngClass]="getBadgeClass(operationalAccreditationState)">{{ operationalAccreditationState }}</span>
    <ng-container *ngIf="adminUsersService?.getResourceFile(additionalCertificationEnum.OPERATIONAL_ACCREDITATION) || adminUsersService?.getErrorLoadResource(additionalCertificationEnum.OPERATIONAL_ACCREDITATION)">
        <app-previsualization-resources matSuffix [resource]="adminUsersService?.getResourceFile(additionalCertificationEnum.OPERATIONAL_ACCREDITATION)" [errorLoadResource]="adminUsersService?.getErrorLoadResource(additionalCertificationEnum.OPERATIONAL_ACCREDITATION)"></app-previsualization-resources>
    </ng-container>
</div>