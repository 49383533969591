import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/authentication.service';
import { PasswordValidator } from 'src/app/core/validators/password.validator';
import { AngularFireAuth } from '@angular/fire/auth';
import { AccountService } from '../account.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/resources/utils';
import { FullUser } from 'src/app/core/interfaces/fullUser';
import { LegalTermsService } from '../../administration/legal-terms/legal-terms.service';
import { Company } from 'src/app/core/interfaces/company';
import { MatCheckboxChange, MatSelectChange } from '@angular/material';
import { Global } from 'src/app/core/resources/global';
import 'firebase/auth'
import { FirebaseApi } from 'src/app/core/classes/firebase-api';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Patterns } from 'src/app/core/resources/patterns';
import { AdminUsersService } from '../../administration/admin-users/admin-users.service';
import { UserClient } from 'src/app/core/interfaces/userClient';
import { BasicCompany } from 'src/app/core/interfaces/basicCompany';
import { environment } from 'src/environments/environment';
import { Companies } from 'src/app/core/resources/companies';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./../account.component.scss', './register.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [AuthService, AccountService]
})
export class RegisterComponent implements OnInit {

  windowRef: any;
  registerForm;
  formStepOne: FormGroup;
  registrationSteps;
  companyTypes: Array<object> = new Array();
  documentTypes: Array<object> = this.utils.clone(this.global.documenTypes);
  passwordConfirmation = '';
  userRegister: any = {};
  verificationCode: string;
  appVerifier: any;
  message = null;
  showMessageValidationCodeOne = false;
  showMessageValidationCodeTwo = false;
  userAlreadyExist = false;
  companyAlreadyExist = false;
  showConfirmPassword: boolean = false;
  showPassword: boolean = false;
  companyId: string = '';
  minLengthNit: number = 10;
  subCompanyNit: FormControl = new FormControl('', [Validators.required, Validators.minLength(this.minLengthNit)]);
  showSubcompanyField: boolean = false;
  showSubcompanyOption: boolean = false;
  listCompanies: Array<BasicCompany> = [];

  constructor(
    private snackBarService: SnackBarService,
    public formBuilder: FormBuilder,
    private router: Router,
    public authService: AuthService,
    public afAuth: AngularFireAuth,
    public accountService: AccountService,
    private spinner: NgxSpinnerService,
    public utils: Utils,
    private global: Global,
    private termsAndConditionsService: LegalTermsService,
    private patterns: Patterns,
    private adminUsersService: AdminUsersService
  ) {
    this.windowRef = window;
    this.registrationSteps = new Object({
      stepActive: 1,
      stepsTotal: 4,
      stepsCompleted: false,
      stepOne: {
        stepNumber: 1,
        stepCompleted: false,
        processActive: 1,
        process: [
          {
            processNumber: 1,
            processTitle: 'Registra tu empresa'
          },
          {
            processNumber: 2,
            processTitle: 'Tu empresa es'
          },
        ]
      },
      stepTwo: {
        stepNumber: 2,
        stepCompleted: false,
        stepTitle: 'Regístrate',
        isExisting: false
      },
      stepThree: {
        stepNumber: 3,
        stepCompleted: false,
      },
      stepFour: {
        stepNumber: 4,
        stepCompleted: false,
        stepTitle: 'Crea tu contraseña',
        parametersPassword: [
          {
            parameterLabel: 'Mínimo 6 letras',
            isValid: false,
            init: false
          },
          {
            parameterLabel: 'Debe tener un número',
            isValid: false,
            init: false
          }
        ]
      },
    });
    this.getCompanyTypes();
    this.getListCompanies();
  }
  getListCompanies() {
    this.listCompanies = this.accountService.listRegisterCompanies();
  }

  ngOnInit() {

    this.registerForm = new Object({
      stepOne: {
        stepOneProcessOne: this.formBuilder.group({
          companyName: ['', Validators.required]
        }),
        stepOneProcessTwo: this.formBuilder.group({
          companyTypeId: ['', Validators.required],
          companyTypeName: ['', Validators.required]
        })
      },
      stepTwo: this.formBuilder.group({
        userName: ['', Validators.required],
        documentTypeName: ['', Validators.required],
        documentTypeId: ['', Validators.required],
        document: ['', Validators.required],
        documentTypeControl: ['', Validators.required]
      }),
      stepThree: {
        stepThreeProcessOne: this.formBuilder.group({
          userCellPhone: ['', Validators.required],
          userCorporateMail: ['', Validators.compose([
            Validators.email,
            Validators.required,
            Validators.pattern(this.patterns.EMAIL.source)
          ])],
        })
      },
      stepFour: new FormGroup({
        userPassword: new FormControl('', Validators.compose([
          Validators.minLength(6),
          Validators.required,
          Validators.pattern(this.patterns.PASSWORD_REGISTER_FORMAT)
        ])),
        passwordConfirmation: new FormControl('', Validators.required)
      }, (formGroup: FormGroup) => {
        return PasswordValidator.areEqual(formGroup);
      })
    })
  }

  getCompanyTypes() {
    this.companyTypes.push(new Object({
      id: 1,
      name: 'Generadora de carga',
      description: 'Empresa productora de mercancía',
      iconName: 'icon-box'
    }));
    this.companyTypes.push(new Object({
      id: 2,
      name: 'Empresa de transporte',
      description: 'Empresa encargada del transporte de la mercancía.',
      iconName: 'icon-delivery-truck-2'
    }));
  }

  onSubmit() {
  }

  validateStepOne() {
    if (this.registerForm.stepOne.stepOneProcessOne.invalid || this.subCompanyNit.invalid) {
      if (this.utils.errorMessagesCustomized(this.registerForm.stepOne.stepOneProcessOne.get('companyName'), 'compañía')) return;
      else if (this.utils.errorMessagesCustomized(this.subCompanyNit, 'nit del cliente', this.minLengthNit)) return;
      else this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
      return;
    }
    if (this.companyId === environment.rootNit && this.companyId === this.subCompanyNit.value) {
      this.snackBarService.openSnackBar('La compañia y el cliente no puede ser iguales', undefined, 'alert');
      return;
    } else if (this.listCompanies.some(company => company.nit === this.subCompanyNit.value && company.nit !== this.companyId)) {
      this.snackBarService.openSnackBar('El NIT ingresado en el cliente corresponde a una compañía', undefined, 'alert');
      return;
    }
    this.spinner.show(),
      this.accountService.validateEntity(2, this.subCompanyNit.value).subscribe(
        (data: Company) => {
          this.spinner.hide();
          if (data && data.companyId) {
            this.companyAlreadyExist = true;
            this.registrationSteps['stepActive'] = 2;
            this.nextStep(this.registrationSteps['stepTwo'].stepNumber);
          } else {
            this.companyAlreadyExist = false;
            this.snackBarService.openSnackBar('El cliente no existe', undefined, 'error');
          }
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }

  changeValue($event) {
    this.subCompanyNit.setValue('');
    this.showSubcompanyOption = false;
    if ($event && $event.srcElement && $event.srcElement.value) this.validateCompany();
    else {
      this.showSubcompanyField = false;
      this.registerForm.stepOne.stepOneProcessOne.get('companyName').setErrors({ required: true });
    }
  }

  private comparableString(string: string) {
    return string.normalize('NFD').replace(/\p{Diacritic}/gu, '').toUpperCase();
  }

  // Step one - process one
  async validateCompany() {
    this.showSubcompanyField = false;
    let inputCompany = this.registerForm.stepOne.stepOneProcessOne.get('companyName').value.toUpperCase().replace(/\s+/g, ' ').trim();
    let matchCompany = this.accountService
      .listRegisterCompanies()
      .find(company => {
        const commonName = this.comparableString(company.commonName);
        const businessName = this.comparableString(company.businessName);
        const search = this.comparableString(inputCompany);
        return [commonName, businessName].includes(search);
      });
    if (matchCompany && matchCompany.nit && !Companies.companiesWithDisabledRegistration.filter((nit) => {
      return nit === matchCompany.nit;
    }).length) {
      this.companyId = matchCompany.nit;
    } else {
      this.registerForm.stepOne.stepOneProcessOne.get('companyName').setErrors({ notFound: true });
      this.companyId = ''
    };
    sessionStorage.setItem('_companyId', this.companyId);
    if (this.companyId) {
      const loadConfig = await FirebaseApi.configFirebaseByCompanyId(this.companyId);
      if (loadConfig) {
        this.registerForm.stepOne.stepOneProcessOne.get('companyName').setErrors(null);
      } else {
        this.registerForm.stepOne.stepOneProcessOne.get('companyName').setErrors({ notFound: true });
        return;
      }
      if (this.companyId === '9013312320') this.showSubcompanyField = true;
      else {
        this.subCompanyNit.setValue(this.companyId);
        this.showSubcompanyOption = true;
      }
    }
  }

  // Step one - process two
  selecTypeCompany(companyType: object) {
    this.registerForm['stepOne'].stepOneProcessTwo.patchValue({
      companyTypeId: companyType['id'],
      companyTypeName: companyType['name']
    });
    this.nextStep(this.registrationSteps['stepTwo'].stepNumber);
  }

  onChangeSubcompanyOption(event: MatCheckboxChange) {
    this.subCompanyNit.setValue(event.checked ? '' : this.companyId);
  }

  changeValueUserCorporateMail() {
    const emailControl: FormControl = this.registerForm.stepThree.stepThreeProcessOne.get('userCorporateMail');
    emailControl.setValue((emailControl.value as string).toLowerCase());

    if (emailControl &&
      emailControl.value &&
      (emailControl.value.includes('@teclogi.com') ||
        emailControl.value.includes('@innovbo.com')) &&
      this.companyId == environment.rootNit
    ) {
      emailControl.setErrors({ teclogiUser: true })
    } else {
      emailControl.setErrors(null)
      emailControl.setValidators([Validators.email,
      Validators.required,
      Validators.pattern(this.patterns.EMAIL.source)])
      emailControl.updateValueAndValidity();
    }
  }
  // Step two
  validateUser() {
    if (this.registerForm.stepTwo.invalid) {
      if (this.utils.errorMessagesCustomized(this.registerForm.stepTwo.get('userName'), 'nombre')) return;
      else if (this.utils.errorMessagesCustomized(this.registerForm.stepTwo.get('documentTypeId'), 'tipo de documento')) return;
      else if (this.utils.errorMessagesCustomized(this.registerForm.stepTwo.get('document'), 'documento')) return;
      else this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
      return;
    }

    this.spinner.show();
    this.adminUsersService.getUserClientByDocument(this.registerForm['stepTwo'].value.document.trim()).subscribe(
      (success: UserClient[]) => {
        this.spinner.hide();
        if (success && success.length) {
          this.userAlreadyExist = true;
        } else {
          this.userAlreadyExist = false;
          this.nextStep(this.registrationSteps['stepThree'].stepNumber);
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  // Step three - Step four - Step five
  nextStep(step: number) {
    this.message = '';
    this.showMessageValidationCodeOne = false;
    this.showMessageValidationCodeTwo = false;
    switch (this.registrationSteps['stepActive']) {
      case 1:
        if (this.registrationSteps['stepOne'].processActive === 1) {
          this.registrationSteps['stepOne'].processActive = 2;
        } else {
          this.registrationSteps['stepActive'] = step;
        }
        break;
      case 2:
        this.registrationSteps['stepActive'] = step;
        break;
      case 3:
        if (this.registerForm.stepThree.stepThreeProcessOne.invalid) {
          if (this.utils.errorMessagesCustomized(this.registerForm.stepThree.stepThreeProcessOne.get('userCellPhone'), 'numero de celular')) return;
          else if (this.utils.errorMessagesCustomized(this.registerForm.stepThree.stepThreeProcessOne.get('userCorporateMail'), 'correo corporativo')) return;
          else this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
          return;
        }
        this.message = '';
        this.showMessageValidationCodeOne = false;
        this.showMessageValidationCodeTwo = false;
        this.registrationSteps['stepActive'] = 4;
        break;
      case 4:
        if (this.registerForm.stepFour.invalid) {
          if (this.utils.errorMessagesCustomized(this.registerForm.stepFour.get('userPassword'), 'contraseña', 6)) return;
          else if (this.utils.errorMessagesCustomized(this.registerForm.stepFour.get('passwordConfirmation'), 'confirmación de contraseña')) return;
          else this.snackBarService.openSnackBar(FormMessages.NOT_MATCH_PASSWORD, undefined, 'alert');
          return;
        }
        if (this.companyAlreadyExist) {
          this.setValueUser(true);
        } else {
          alert('Empresa no creada');
        }
        break;
    }
  }

  setValueUser(createUser) {
    this.userRegister = {
      email: this.registerForm['stepThree'].stepThreeProcessOne.value.userCorporateMail.toLocaleLowerCase(),
      information: {
        documentTypeId: this.registerForm['stepTwo'].value.documentTypeId,
        documentTypeName: this.registerForm['stepTwo'].value.documentTypeName,
        document: this.registerForm['stepTwo'].value.document,
        name: this.registerForm['stepTwo'].value.userName,
      },
      clientCompanyId: this.subCompanyNit.value,
      phone: this.registerForm['stepThree'].stepThreeProcessOne.value.userCellPhone,
      password: this.registerForm['stepFour'].value.userPassword,
      role: this.global.defaultRoles.webUserPendingActivate.id,
    };
    if (createUser) this.registerUserFirebase();
  }


  registerUserFirebase() {
    this.spinner.show();
    let thisClass = this;
    this.authService.signUp(this.userRegister.email, this.userRegister.password)
      .then(() => {
        thisClass.spinner.hide();
        thisClass.registerUserDatabase(thisClass.userRegister);
      }).catch((error) => {
        thisClass.spinner.hide();
        thisClass.logOut();
        thisClass.showMessageValidationCodeTwo = true;
        switch (error.code) {
          case 'auth/email-already-in-use':
            thisClass.message = 'La dirección de correo electrónico ya está en uso por otra cuenta.'
            break
          case "auth/internal-error":
            thisClass.message = "Usuario o contraseña incorrectos";
            break;
          default:
            thisClass.message = error.message;
            break;
        }
      });
  }

  logOut() {
    this.authService.logOut().then(
      (data) => {
        this.authService.clearSession();
      },
      (error) => {
      }
    );
  }

  registerUserDatabase(userData: any) {
    this.spinner.show();
    this.accountService.createUser(userData)
      .subscribe(
        (data) => {
          this.spinner.hide();
          this.signInAuto(this, this.userRegister.email, this.userRegister.password);
        },
        (error) => {
          let user = this.authService.getCurrentUserFirebase();
          user.delete();
          this.spinner.hide();
          this.logOut();
        }
      );
  }

  signInAuto(thisClass, email, password) {
    this.spinner.show();
    this.authService.signIn(email, password).then(
      (result) => {
        thisClass.authService.setToken(result.user.uid);
        thisClass.authService.getFullUserByEmail(email)
          .subscribe(
            (data: FullUser) => {
              thisClass.spinner.hide();
              if (
                this.utils.isDefined(this.authService.getUserSession()) &&
                this.utils.isDefined(this.authService.getUserSession().information.document)
              ) {
                location.reload();
              } else {
                thisClass.logOut();
              }
            },
            (error) => {
              thisClass.spinner.hide();
              thisClass.logOut();
            }
          );
      }).catch((error) => {
        thisClass.spinner.hide();
        thisClass.logOut();
        thisClass.showMessageValidationCodeTwo = true;
        switch (error.code) {
          case 'auth/email-already-in-use':
            thisClass.message = 'La dirección de correo electrónico ya está en uso por otra cuenta.'
            break
          default:
            thisClass.message = error.message;
            break;
        }
      });
  }

  stepBack(stepActive: number, processActive: number) {
    if (stepActive) this.registrationSteps.stepActive = stepActive;
    if (processActive && this.registrationSteps.stepActive) {
      switch (this.registrationSteps.stepActive) {
        case 1:
          this.registrationSteps.stepOne.processActive = processActive;
          break;
        case 2:
          this.registrationSteps.stepOne.processActive = processActive;
          break;
      }

    }
  }

  goToPrivacyPolicy() {
    window.open('/#/privacy-policy', '_blank');
  }

  displayFnDocumentType(data: any): string {
    return data ? data.name : '';
  }

  returnFnDocumentType(data: any): string | undefined {
    return data ? data.id : undefined;
  }

  onChangeDocumentTypeId($event: MatSelectChange) {
    this.registerForm['stepTwo'].controls.documentTypeId.setValue($event.value.id);
    this.registerForm['stepTwo'].controls.documentTypeName.setValue($event.value.name);
  }
}
