import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { ManualCreationCargoService } from '../manual-creation-cargo/manual-creation-cargo.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { MassCreationCargoService } from './mass-creation-cargo.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from 'src/app/core/interfaces/user';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
import { AddressColumnCargo, ExcelField, FieldsColumnCargo } from 'src/app/core/interfaces/fieldsColumnCargo';
import { CargoFeature } from 'src/app/core/interfaces/cargoFeature';
import { Utils } from 'src/app/core/resources/utils';
import { ReadXLSX } from 'src/app/core/resources/readXLSX';
import { Company } from 'src/app/core/interfaces/company';
import { FileComponent } from 'src/app/shared/files/file.component';
import { runInDebugContext } from 'vm';
import { SelectCompanyComponent } from 'src/app/shared/select-company/select-company.component';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { RoutingMessages } from 'src/app/core/messages/routing-messages.enum';
import { FileStorage } from 'src/app/core/interfaces/fileStorage';

@Component({
  selector: 'app-mass-creation-cargo',
  templateUrl: './mass-creation-cargo.component.html',
  styleUrls: ['./mass-creation-cargo.component.scss'],
  providers: [AuthService, Global]
})

export class MassCreationCargoComponent implements OnInit {

  @ViewChildren(FileComponent) fileComponent: QueryList<FileComponent>;
  massCreationCargoForm: FormGroup;
  sheetSub: Subscription;
  fieldsExcelForm: FormGroup;
  jsonXLSX: any;
  listTitleFieldsColumns: ExcelField[];
  titleFieldTemporal: object = {};
  origins: AddressColumnCargo[] = [];
  destination: AddressColumnCargo[] = [];
  massCreationCompany = new FormControl('', Validators.required);
  companySub: Subscription;
  massCreationMinistryCtrl = new FormControl(true, Validators.required);
  sheets: Array<any>;
  companySession: Company;
  sheetName: string;
  titlesFile: any;
  @ViewChild(SelectCompanyComponent, { static: false }) selectCompanyComponent: SelectCompanyComponent;
  optionsCompany = {
    title: 'Compañía',
    appearance: 'outline'
  };
  constructor(
    private authService: AuthService,
    public manualCreationCargoService: ManualCreationCargoService,
    private spinner: NgxSpinnerService,
    private massCreationCargoService: MassCreationCargoService,
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder,
    private router: Router,
    public utils: Utils,
    private readXLSX: ReadXLSX,
    private global: Global,
  ) {
    this.companySession = this.utils.clone(this.authService.getCompany());
    this.resetTitleFieldsColumns();
  }

  private resetTitleFieldsColumns() {
    this.listTitleFieldsColumns = this.utils.clone(this.global.fieldsXLSXCargo);
  }

  ngOnInit() {
    this.createMassCreationCargoForm();
    this.createFieldsExcelForm();
    this.setSubscriptions();
  }

  private createMassCreationCargoForm() {
    this.massCreationCargoForm = new FormGroup({
      firstRow: new FormControl(1, Validators.required),
      companyId: new FormControl('', Validators.required),
      numberDocumentCreator: new FormControl(this.authService.getUserSession().information.document.toString()),
      file: new FormControl(null, Validators.required),
      sheetName: new FormControl('', Validators.required),
      isExclusive: new FormControl(''),
    });
  }

  private createFieldsExcelForm() {
    this.fieldsExcelForm = new FormGroup({
      nit: new FormControl(''),
      numberDocumentSender: new FormControl('', Validators.required),
      tripName: new FormControl('', Validators.required),
      cargoTypeName: new FormControl(''),
      serviceTypeName: new FormControl(''),
      productDescription: new FormControl(''),
      cargoMeasureQuantity: new FormControl(''),
      cargoMeasureTotal: new FormControl(''),
      cargoMeasureTotalWeigth: new FormControl(''),
      cargoMeasureTotalAmount: new FormControl(''),
      cargoMeasureTotalType: new FormControl(''),
      vehicleTypeName: new FormControl('', Validators.required),
      bodyWorkTypeName: new FormControl('', Validators.required),
      licensePlate: new FormControl(''),
      shippingCostTotal: new FormControl('', Validators.required),
      rate: new FormControl(''),
      advancePercentage: new FormControl(''),
      guide: new FormControl(''),
      observations: new FormControl(''),
      dateCargo: new FormControl('', Validators.required),
    });

    this.addOriginColumn();
    this.addOriginAddressColumn(this.origins.length - 1);
    this.addDestinationColumn();
    this.addDestinationAddressColumn(this.destination.length - 1);
  }

  private setSubscriptions() {
    this.companySub = this.massCreationCompany.valueChanges.subscribe((company: Company) => {
      if (company && company.companyId) {
        this.massCreationCargoForm.get('companyId').setValue(company);
        this.setPreConfigFieldsCompany(company);
      } else {
        this.massCreationCargoForm.get('companyId').setValue('');
      }
    });

    this.sheetSub = this.massCreationCargoForm.get('sheetName').valueChanges.subscribe(selectedValue => {
      if (this.sheetName !== selectedValue.key) {
        this.massCreationCargoForm.get('firstRow').setValue(1);
        this.handleFileInput(this.massCreationCargoForm.get('file').value.fileData.file, selectedValue);
      }
    });
  }

  private setPreConfigFieldsCompany(company: Company) {
    if (!company || !company.fieldsExcelCargo) {
      this.resetTitleFieldsColumns();
      this.titleFieldTemporal = this.utils.clone(this.titlesFile);
      this.origins = [];
      this.destination = [];
      this.createFieldsExcelForm();
      return;
    }
    for (const field in company.fieldsExcelCargo) {
      if (this.fieldsExcelForm.controls[field] && field !== 'companyId') {
        this.onChangeTitleField(field, (company.fieldsExcelCargo[field]));
      }
      if (this.massCreationCargoForm.controls[field] && (field !== 'companyId' && field !== 'numberDocumentCreator' && field !== 'firstRow')) {
        this.massCreationCargoForm.controls[field].setValue((company.fieldsExcelCargo[field]));
      } else if (this.massCreationCargoForm.controls[field] && field === 'firstRow') {
        this.massCreationCargoForm.controls[field].setValue((company.fieldsExcelCargo[field] + 1));
      }
    }
    if (company.fieldsExcelCargo.origin) {
      company.fieldsExcelCargo.origin.map((city, i) => {
        if (!this.findTitle(`origin${i}`)) this.addOriginColumn();
        this.onChangeTitleField('origin' + i, (city.city));
        city.addresses.map((address, j) => {
          if (!this.findTitle(`addressOrigin-${i}-${j}`)) this.addOriginAddressColumn(i);
          this.onChangeTitleField(`addressOrigin-${i}-${j}`, address);
        });
        if (city.recipientsNames) {
          city.recipientsNames.map((name, j) => {
            if (!this.findTitle(`addressOrigin-${i}-${j}`)) this.addOriginAddressColumn(i);
            this.onChangeTitleField(`recipientNameAddressOrigin-${i}-${j}`, name);
          });
        }
      });
    }
    if (company.fieldsExcelCargo.destination) {
      company.fieldsExcelCargo.destination.map((city, i) => {
        if (!this.findTitle(`destination${i}`)) this.addDestinationColumn();
        this.onChangeTitleField('destination' + i, (city.city));
        this.onChangeTitleField('deadline' + i, (city.deadline));
        city.addresses.map((address, j) => {
          if (!this.findTitle(`addressDestination-${i}-${j}`)) this.addDestinationAddressColumn(i);
          this.onChangeTitleField(`addressDestination-${i}-${j}`, address);
        });
        if (city.guides) {
          city.guides.map((guide, j) => {
            if (!this.findTitle(`addressDestination-${i}-${j}`)) this.addDestinationAddressColumn(i);
            this.onChangeTitleField(`guideAddressDestination-${i}-${j}`, guide);
          });
        }
        if (city.recipientsNames) {
          city.recipientsNames.map((name, j) => {
            if (!this.findTitle(`addressDestination-${i}-${j}`)) this.addDestinationAddressColumn(i);
            this.onChangeTitleField(`recipientNameAddressDestination-${i}-${j}`, name);
          });
        }
      });
    }
  }

  handleFileInput(file: File, sheetName?: { key: string }) {
    if (!file) return;
    const extension = file.name.split('.').reverse()[0];
    if (extension.toLowerCase() !== 'xlsx') {
      this.snackBarService.openSnackBar("El archivo debe tener formato excel", undefined, 'error');
      this.resetFields();
      return;
    }
    this.jsonXLSX = null;
    this.resetTitleFieldsColumns();
    this.titleFieldTemporal = {};
    this.origins = [];
    this.destination = [];
    this.titlesFile = null;
    this.createFieldsExcelForm();
    this.readXLSX.readFile(
      [file],
      this.firstRow - 1,
      sheetName
    )
      .then((result: { titles, jsonXLSX, sheets, sheetName }) => {
        if (result.titles) {
          this.titlesFile = this.utils.clone(result.titles);
          this.titleFieldTemporal = result.titles;
        }
        if (result.sheetName) {
          this.sheetName = result.sheetName.key;
          this.massCreationCargoForm.get('sheetName').setValue(result.sheetName);
        }
        if (result.sheets) {
          this.sheets = result.sheets;
        }
        this.sheets.forEach(sheet => {
          result.jsonXLSX[sheet.key] = result.jsonXLSX[sheet.key].filter(row => row.some(field => field && field !== '-'));
        })
        this.jsonXLSX = result.jsonXLSX;
        this.setPreConfigFieldsCompany(this.massCreationCargoForm.get('companyId').value);
      })
      .catch(() => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(RoutingMessages.INVALID_FILE, undefined, 'error');
        this.resetFields();
      });

  }

  getRowXLSXToShow() {
    if (this.jsonXLSX && this.sheetName) {
      return this.jsonXLSX[this.sheetName][this.firstRow - 1];
    }
    return {};
  }

  getObjectKeys(object) {
    try {
      return object ? Object.keys(object) : [];
    } catch (e) {
      return [];
    }
  }

  private findTitle(nameField: string): ExcelField {
    return _.find(this.listTitleFieldsColumns, (title) => {
      return title.id === nameField;
    });
  }

  onChangeTitleField(nameField: string, index) {
    const objectTitleField = this.findTitle(nameField);
    if (objectTitleField) {
      objectTitleField.disabled = true;
      this.titleFieldTemporal[index] = {};
      this.titleFieldTemporal[index].title = objectTitleField.title;
      this.titleFieldTemporal[index].value = nameField;
      if (objectTitleField.type)
        this.setIndexCityOrigin(objectTitleField, (index));
      else
        this.fieldsExcelForm.controls[nameField].setValue(index);
    }
  }

  clearValueTitle(value: string, index: string) {
    let indexList;
    this.titleFieldTemporal[index].value = null;
    const objectTitleField = _.find(this.listTitleFieldsColumns, (title: ExcelField) => {
      return title.id === value;
    });
    if (objectTitleField) {
      objectTitleField.disabled = false;

      switch (objectTitleField.type) {
        case 'cityOrigin':
        case 'cityDestination':
          if (objectTitleField.type === 'cityOrigin') {
            indexList = objectTitleField.id.split('origin').filter((i) => {
              return i.length;
            });
            if (indexList.length) {
              this.origins[parseInt(indexList[0])].city = null;
            }
          } else {
            indexList = objectTitleField.id.split('destination').filter((i) => {
              return i.length;
            });
            if (indexList.length) {
              this.destination[parseInt(indexList[0])].city = null;
            }
          }

          break;
        case 'deadline':
          indexList = objectTitleField.id.split('deadline').filter((i) => {
            return i.length;
          });
          if (indexList.length)
            this.destination[parseInt(indexList[0])].deadline = null;
          break;
        case 'addressOrigin':
        case 'addressDestination':

          if (objectTitleField.type === 'addressOrigin') {
            indexList = objectTitleField.id.split('-').filter((i) => {
              return i.length && i !== 'addressOrigin';
            });
            if (indexList.length)
              this.origins[parseInt(indexList[0])].addresses[parseInt(indexList[1])] = null;
          } else {
            indexList = objectTitleField.id.split('-').filter((i) => {
              return i.length && i !== 'addressDestination';
            });
            if (indexList.length)
              this.destination[parseInt(indexList[0])].addresses[parseInt(indexList[1])] = null;
          }

          break;
        case 'guideAddressDestination':
          indexList = objectTitleField.id.split('-').filter((i) => {
            return i.length && i !== 'guideAddressDestination';
          });
          if (indexList.length)
            this.destination[parseInt(indexList[0])].guides[parseInt(indexList[1])] = null;
          break;
        case 'recipientNameAddressDestination':
          indexList = objectTitleField.id.split('-').filter((i) => {
            return i.length && i !== 'recipientNameAddressDestination';
          });
          if (indexList.length)
            this.destination[parseInt(indexList[0])].recipientsNames[parseInt(indexList[1])] = null;
          break;
        case 'recipientNameAddressOrigin':
          indexList = objectTitleField.id.split('-').filter((i) => {
            return i.length && i !== 'recipientNameAddressOrigin';
          });
          if (indexList.length)
            this.origins[parseInt(indexList[0])].recipientsNames[parseInt(indexList[1])] = null;
          break;
        default:
          this.fieldsExcelForm.controls[value].setValue(null);
          break;
      }
    }
  }



  private setIndexCityOrigin(objectTitleField: any, indexColumn: number) {

    let indexList;
    switch (objectTitleField.type) {
      case 'cityOrigin':
      case 'cityDestination':
        if (objectTitleField.type === 'cityOrigin') {
          indexList = objectTitleField.id.split('origin').filter((i) => {
            return i.length;
          });
          if (indexList.length) {
            // tslint:disable-next-line: radix
            this.origins[parseInt(indexList[0])].city = indexColumn;
          }
        } else {
          indexList = objectTitleField.id.split('destination').filter((i) => {
            return i.length;
          });
          if (indexList.length) {
            if (!parseInt(indexList[0]))
              this.destination[parseInt(indexList[0])].city = indexColumn;
          }
        }
        break;
      case 'deadline':
        indexList = objectTitleField.id.split('deadline').filter((i) => {
          return i.length;
        });
        if (indexList.length) {
          // tslint:disable-next-line: radix
          this.destination[parseInt(indexList[0])].deadline = indexColumn;
        }
        break;
      case 'addressOrigin':
      case 'addressDestination':

        if (objectTitleField.type === 'addressOrigin') {
          indexList = objectTitleField.id.split('-').filter((i) => {
            return i.length && i !== 'addressOrigin';
          });
          if (indexList.length) {
            this.origins[0].addresses[parseInt(indexList[1])] = indexColumn;
          }
        } else {
          indexList = objectTitleField.id.split('-').filter((i) => {
            return i.length && i !== 'addressDestination';
          });
          if (indexList.length) {
            this.destination[parseInt(indexList[0])].addresses[parseInt(indexList[1])] = indexColumn;
          }
        }

        break;
      case 'guideAddressDestination':
        indexList = objectTitleField.id.split('-').filter((i) => {
          return i.length && i !== 'guideAddressDestination';
        });
        if (indexList.length) {
          // tslint:disable-next-line: radix
          if (!this.destination[parseInt(indexList[0])].guides) {
            this.destination[parseInt(indexList[0])].guides = [];
          }
          this.destination[parseInt(indexList[0])].guides[parseInt(indexList[1])] = indexColumn;
          // tslint:disable-next-line: radix
        }
        break;
      case 'recipientNameAddressDestination':
        indexList = objectTitleField.id.split('-').filter((i) => {
          return i.length && i !== 'recipientNameAddressDestination';
        });
        if (indexList.length) {
          // tslint:disable-next-line: radix
          if (!this.destination[parseInt(indexList[0])].recipientsNames) {
            this.destination[parseInt(indexList[0])].recipientsNames = [];
          }
          this.destination[parseInt(indexList[0])].recipientsNames[parseInt(indexList[1])] = indexColumn;
          // tslint:disable-next-line: radix
        }
        break;
      case 'recipientNameAddressOrigin':
        indexList = objectTitleField.id.split('-').filter((i) => {
          return i.length && i !== 'recipientNameAddressOrigin';
        });
        if (indexList.length) {
          // tslint:disable-next-line: radix
          if (!this.origins[parseInt(indexList[0])].recipientsNames) {
            this.origins[parseInt(indexList[0])].recipientsNames = [];
          }
          this.origins[parseInt(indexList[0])].recipientsNames[parseInt(indexList[1])] = indexColumn;
          // tslint:disable-next-line: radix
        }
        break;
    }
  }

  private addOriginColumn() {
    this.origins.push({
      city: null,
      addresses: []
    });
    this.listTitleFieldsColumns.push({
      id: `origin${this.origins.length - 1}`,
      type: 'cityOrigin',
      title: "Ciudad de origen*",
      disabled: false,
      mandatory: true
    });
  }

  private addOriginAddressColumn(indexCity: number) {
    const indexAddress = this.origins[indexCity].addresses.length;
    const objectTitle: ExcelField = {
      id: `addressOrigin-${indexCity}-${indexAddress}`,
      type: 'addressOrigin',
      title: `Origen - Dirección ${indexAddress + 1}*`,
      disabled: false,
      mandatory: true
    };
    const objectTitleRecipientName: ExcelField = {
      id: `recipientNameAddressOrigin-${indexCity}-${indexAddress}`,
      type: 'recipientNameAddressOrigin',
      title: `Origen - Remitente ${indexAddress + 1}`,
      disabled: false,
      mandatory: false
    };
    this.listTitleFieldsColumns.push(objectTitle);
    this.listTitleFieldsColumns.push(objectTitleRecipientName);

  }

  private addDestinationColumn() {
    this.destination.push({
      city: null,
      deadline: null,
      addresses: []
    });
    this.listTitleFieldsColumns.push({
      id: `destination${this.destination.length - 1}`,
      type: 'cityDestination',
      title: `Ciudad de destino ${this.destination.length}*`,
      disabled: false,
      mandatory: true
    });
    this.listTitleFieldsColumns.push({
      id: `deadline${this.destination.length - 1}`,
      type: 'deadline',
      title: `Destino ${this.destination.length} - Fecha Descargue*`,
      disabled: false,
      mandatory: true
    });

  }

  private addDestinationAddressColumn(indexCity: number) {
    const indexAddress = this.destination[indexCity].addresses.length;
    const objectTitle = {
      id: `addressDestination-${indexCity}-${indexAddress}`,
      type: 'addressDestination',
      title: `Destino ${indexCity + 1} - Dirección ${indexAddress + 1}*`,
      disabled: false,
      mandatory: true
    };
    const objectTitleGuide = {
      id: `guideAddressDestination-${indexCity}-${indexAddress}`,
      type: 'guideAddressDestination',
      title: `Destino ${indexCity + 1} - Guía ${indexAddress + 1}`,
      disabled: false,
      mandatory: false
    };
    const objectTitleRecipientName = {
      id: `recipientNameAddressDestination-${indexCity}-${indexAddress}`,
      type: 'recipientNameAddressDestination',
      title: `Destino ${indexCity + 1} - Destinatario ${indexAddress + 1}`,
      disabled: false,
      mandatory: false
    };
    this.listTitleFieldsColumns.push(objectTitle);
    this.listTitleFieldsColumns.push(objectTitleGuide);
    this.listTitleFieldsColumns.push(objectTitleRecipientName);
  }

  onSubmit() {
    this.massCreationCompany.markAllAsTouched();
    if (this.massCreationCargoForm.invalid) {
      if (this.utils.errorMessagesCustomized(this.massCreationCargoForm.get('file'), 'archivo')) return;
      if (this.utils.errorMessagesCustomized(this.massCreationCargoForm.get('companyId'), 'compañía')) return;
      if (this.utils.errorMessagesCustomized(this.massCreationCargoForm.get('firstRow'), 'no. fila inicial')) return;
      if (this.utils.errorMessagesCustomized(this.massCreationCargoForm.get('sheetName'), 'nombre de archivo')) return;
      this.snackBarService.openSnackBar(FormMessages.MISSING_FIELDS, undefined, 'alert');
      return;
    }
    if (this.fieldsExcelForm.invalid) {
      if (this.utils.errorMessagesCustomized(this.fieldsExcelForm.get('numberDocumentSender'), 'documento del remitente')) return;
      if (this.utils.errorMessagesCustomized(this.fieldsExcelForm.get('tripName'), 'tipo de viaje')) return;
      if (this.utils.errorMessagesCustomized(this.fieldsExcelForm.get('vehicleTypeName'), 'tipo de vehículo')) return;
      if (this.utils.errorMessagesCustomized(this.fieldsExcelForm.get('bodyWorkTypeName'), 'tipo de carrocería')) return;
      if (this.utils.errorMessagesCustomized(this.fieldsExcelForm.get('shippingCostTotal'), 'flete')) return;
      if (this.utils.errorMessagesCustomized(this.fieldsExcelForm.get('dateCargo'), 'fecha de cargue')) return;
      this.snackBarService.openSnackBar('Falta diligenciar información de origen y destino', undefined, 'alert');
      return;
    }
    let status: string;
    const thisClass = this;
    const formData = new FormData();
    const origins = this.origins.filter((i) => {
      return thisClass.utils.isDefined(i.city);
    });
    const destinations = this.destination.filter((i) => {
      return thisClass.utils.isDefined(i.city);
    });

    if (origins.length < 1) {
      this.snackBarService.openSnackBar('No se ha seleccionado la ciudad de origen', undefined, 'alert');
      return;
    }
    if (destinations.length < 1) {
      this.snackBarService.openSnackBar('No se ha seleccionado ninguna ciudad de destino', undefined, 'alert');
      return;
    }

    if (origins.length > 0 && !status) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < origins.length; i++) {
        if (!status) {
          let addresses = origins[i].addresses.filter((j) => {
            return thisClass.utils.isDefined(j);
          });
          if (addresses.length < 1)
            status = `No se ha seleccionado ninguna dirección para el origen`;
        }
      }
    }

    if (destinations.length > 0 && !status) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < destinations.length; i++) {
        if (!status) {
          let addresses = destinations[i].addresses.filter((j) => {
            return thisClass.utils.isDefined(j);
          });
          if (addresses.length < 1)
            status = `No se ha seleccionado ninguna dirección para el destino ${i + 1}`;
          else if (!thisClass.utils.isDefined(destinations[i].deadline))
            status = `No se ha seleccionado la fecha de descargue para el destino ${i + 1}`;
        }
      }
    }
    if (status) {
      this.snackBarService.openSnackBar(status, undefined, 'alert');
      return;
    }
    if (this.jsonXLSX[this.massCreationCargoForm.get('sheetName').value.key].length - 1 < this.firstRow - 1) {
      this.snackBarService.openSnackBar('La fila inicial no puede superar a la cantidad de filas llenas', undefined, 'alert');
      return;
    }

    const originsList = this.origins.filter((i) => {
      return thisClass.utils.isDefined(i.city);
    }).map((i) => {
      // tslint:disable-next-line: radix
      let data = {
        // tslint:disable-next-line: radix
        city: parseInt(i.city.toString()),
        addresses: i.addresses.filter((j) => {
          return thisClass.utils.isDefined(j);
        }).map((j) => {
          // tslint:disable-next-line: radix
          return parseInt(j.toString());
        }),

      };
      if (i.recipientsNames) {
        data['recipientsNames'] = i.recipientsNames.filter((j) => {
          return thisClass.utils.isDefined(j);
        }).map((j) => {
          // tslint:disable-next-line: radix
          return parseInt(j.toString());
        });
      }
      return data;
    });

    const destinationsList = this.destination.filter((i) => {
      return thisClass.utils.isDefined(i.city);
    }).map((i) => {
      // tslint:disable-next-line: radix
      let data = {
        // tslint:disable-next-line: radix
        city: parseInt(i.city.toString()),
        addresses: i.addresses.filter((j) => {
          return thisClass.utils.isDefined(j);
        }).map((j) => {
          // tslint:disable-next-line: radix
          return parseInt(j.toString());
        })
      };
      if (i.deadline) {
        data['deadline'] = parseInt(i.deadline.toString());
      }
      if (i.guides) {
        data['guides'] = i.guides.filter((j) => {
          return thisClass.utils.isDefined(j);
        }).map((j) => {
          // tslint:disable-next-line: radix
          return parseInt(j.toString());
        });
      }
      if (i.recipientsNames) {
        data['recipientsNames'] = i.recipientsNames.filter((j) => {
          return thisClass.utils.isDefined(j);
        }).map((j) => {
          // tslint:disable-next-line: radix
          return parseInt(j.toString());
        });
      }
      return data;
    });
    const fields: FieldsColumnCargo = {
      firstRow: this.firstRow - 1,
      finalRow: this.jsonXLSX[this.massCreationCargoForm.get('sheetName').value.key].length - 1,
      companyId: this.massCreationCargoForm.value.companyId.companyId,
      exclusive: this.massCreationCargoForm.value.companyId.exclusive,
      numberDocumentCreator: this.massCreationCargoForm.value.numberDocumentCreator,
      dateCargo: parseInt(this.fieldsExcelForm.value.dateCargo),
      shippingCostTotal: parseInt(this.fieldsExcelForm.value.shippingCostTotal),
      origin: originsList,
      destination: destinationsList,
      manifest: this.massCreationMinistryCtrl.value
    };
    if (!this.utils.isEmpty(this.fieldsExcelForm.value.vehicleTypeName)) {
      fields.vehicleTypeName = parseInt(this.fieldsExcelForm.value.vehicleTypeName);
    }
    if (!this.utils.isEmpty(this.fieldsExcelForm.value.nit)) {
      fields.nit = parseInt(this.fieldsExcelForm.value.nit);
    }
    if (!this.utils.isEmpty(this.fieldsExcelForm.value.serviceTypeName)) {
      fields.serviceTypeName = parseInt(this.fieldsExcelForm.value.serviceTypeName);
    }
    if (!this.utils.isEmpty(this.fieldsExcelForm.value.cargoTypeName)) {
      fields.cargoTypeName = parseInt(this.fieldsExcelForm.value.cargoTypeName);
    }
    if (!this.utils.isEmpty(this.fieldsExcelForm.value.tripName)) {
      fields.tripName = parseInt(this.fieldsExcelForm.value.tripName);
    }
    if (!this.utils.isEmpty(this.fieldsExcelForm.value.numberDocumentSender)) {
      fields.numberDocumentSender = parseInt(this.fieldsExcelForm.value.numberDocumentSender);
    }
    if (!this.utils.isEmpty(this.fieldsExcelForm.value.cargoMeasureQuantity)) {
      fields.cargoMeasureQuantity = parseInt(this.fieldsExcelForm.value.cargoMeasureQuantity);
    }
    if (!this.utils.isEmpty(this.fieldsExcelForm.value.cargoMeasureTotal)) {
      fields.cargoMeasureTotal = parseInt(this.fieldsExcelForm.value.cargoMeasureTotal);
    }
    if (!this.utils.isEmpty(this.fieldsExcelForm.value.cargoMeasureTotalWeigth)) {
      fields.cargoMeasureTotalWeigth = parseInt(this.fieldsExcelForm.value.cargoMeasureTotalWeigth);
    }
    if (!this.utils.isEmpty(this.fieldsExcelForm.value.cargoMeasureTotalAmount)) {
      fields.cargoMeasureTotalAmount = parseInt(this.fieldsExcelForm.value.cargoMeasureTotalAmount);
    }
    if (!this.utils.isEmpty(this.fieldsExcelForm.value.cargoMeasureTotalType)) {
      fields.cargoMeasureTotalType = parseInt(this.fieldsExcelForm.value.cargoMeasureTotalType);
    }
    if (!this.utils.isEmpty(this.fieldsExcelForm.value.productDescription)) {
      fields.productDescription = parseInt(this.fieldsExcelForm.value.productDescription);
    }
    if (!this.utils.isEmpty(this.fieldsExcelForm.value.bodyWorkTypeName)) {
      fields.bodyWorkTypeName = parseInt(this.fieldsExcelForm.value.bodyWorkTypeName);
    }
    if (!this.utils.isEmpty(this.fieldsExcelForm.value.guide)) {
      fields.guide = parseInt(this.fieldsExcelForm.value.guide);
    }
    if (!this.utils.isEmpty(this.fieldsExcelForm.value.advancePercentage)) {
      fields.advancePercentage = parseInt(this.fieldsExcelForm.value.advancePercentage);
    }
    if (!this.utils.isEmpty(this.fieldsExcelForm.value.rate)) {
      fields.rate = parseInt(this.fieldsExcelForm.value.rate);
    }
    if (!this.utils.isEmpty(this.fieldsExcelForm.value.licensePlate)) {
      fields.licensePlate = parseInt(this.fieldsExcelForm.value.licensePlate);
    }
    if (!this.utils.isEmpty(this.fieldsExcelForm.value.observations)) {
      fields.observations = parseInt(this.fieldsExcelForm.value.observations);
    }
    formData.append('fields', JSON.stringify(this.utils.cleanEmptyValues(fields)));
    formData.append('file', this.massCreationCargoForm.value.file.fileData.file);
    formData.append('sheet', this.massCreationCargoForm.get('sheetName').value.value);
    this.spinner.show();
    this.massCreationCargoService.massCreationCargo(formData).subscribe(
      (success) => {
        this.spinner.hide();
        this.authService.getAllCompanies().toPromise().finally();
        this.snackBarService.openSnackBar('Solicitudes de servicio en proceso');
        this.router.navigate(['/cargo/list/request']);
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(error && error.error && error.error.message ? error.error.message : 'Se generó un error intentando crear los servicios', undefined, 'error');
      }
    );
  }

  private resetFields() {
    this.resetTitleFieldsColumns();
    this.createMassCreationCargoForm();
    this.createFieldsExcelForm();
    this.fileComponent.toArray().map((component) => {
      component.deleteFile();
    });
    this.jsonXLSX = null;
    this.titleFieldTemporal = {};
    this.origins = [];
    this.titlesFile = null;
    this.destination = [];
    this.sheets = null;
    this.sheetName = null;
  }

  ngOnDestroy() {
    if (this.companySub) this.companySub.unsubscribe();
    if (this.sheetSub) this.sheetSub.unsubscribe();
  }

  get formOrigin() {
    return this.fieldsExcelForm.controls.origin as FormArray;
  }

  get firstRow(): number {
    if (this.massCreationCargoForm && this.massCreationCargoForm.get('firstRow') &&
      Number.isInteger(this.massCreationCargoForm.get('firstRow').value))
      return this.massCreationCargoForm.get('firstRow').value;
    return 0;
  }

  get showSheetSelector(): boolean {
    return (this.sheets && this.sheets.length && this.sheets.length > 1) &&
      this.getObjectKeys(this.titleFieldTemporal).length &&
      !!this.massCreationCargoForm.get('file').value;
  }

  get listTitleFieldsColumnsOrdered(): ExcelField[] {
    if (this.listTitleFieldsColumns && this.listTitleFieldsColumns.length)
      return this.listTitleFieldsColumns.sort((a, b) => {
        if (a.mandatory && !b.mandatory)
          return -1;
        if (b.mandatory && !a.mandatory)
          return 1;
        return 0;
      })
    return [];
  }
}

