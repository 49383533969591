import { Component, Inject, OnInit } from '@angular/core';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { MaintenanceService } from '../maintenance.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MaintenanceDetail } from 'src/app/core/interfaces/maintenance-detail';


@Component({
  selector: 'app-create-edit-maintenance',
  templateUrl: './create-edit-maintenance.component.html',
  styleUrls: ['./create-edit-maintenance.component.scss']
})
export class CreateEditMaintenanceComponent implements OnInit {
  vehicle: Vehicle;
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogParams: { vehicleId: string, maintenance: MaintenanceDetail },
    public dialogRef: MatDialogRef<CreateEditMaintenanceComponent>,
    private router: Router,
    private maintenanceService: MaintenanceService,
    private route: ActivatedRoute,
    private snackbarService: SnackBarService,
  ) { }


  ngOnInit() {
    this.route.params.subscribe(async (params: { id: string }) => {
      if ((params && params.id) || (this.dialogParams && this.dialogParams.vehicleId)) {
        let vehicle = await this.maintenanceService.getVehicle(params.id ? params.id : this.dialogParams.vehicleId);
        if (vehicle) this.vehicle = vehicle;
        else this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
      else this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
    });

  }

  returnListMaintenance() {
    let route = [this.maintenanceService.loadingRoute];
    if (this.maintenanceService.params) route.push(this.maintenanceService.params);
    this.router.navigate(route);
  }


}
