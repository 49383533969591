import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Model, User } from 'src/app/core/interfaces/user';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { PrevisualizationSettings } from 'src/app/core/interfaces/previsualizationSettings';
import { PersonVehicleComponent } from '../../vehicles/person-vehicle/person-vehicle.component';
import { AdminUsersService } from '../admin-users.service';
import { basicDataFields, contactsAndReferencesFields, driverDocumentsFields } from 'src/app/core/layouts/createDriver.layout';
import { UploadTaskSnapshot } from '@angular/fire/storage/interfaces';
import { FileService } from 'src/app/shared/files/file.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Router } from '@angular/router';
import { DateManager } from 'src/app/core/managers/date.manager';
import { AdditionalCertificationDict } from 'src/app/core/enums/additionalCertification.enum';
import { AdditionalCertificationEnum } from 'src/app/core/enums/additionalCertification.enum';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { ExtraDocument } from 'src/app/core/interfaces/extraDocument';
@Component({
  selector: 'app-create-driver-form',
  templateUrl: './create-driver-form.component.html',
  styleUrls: ['./create-driver-form.component.scss'],
  providers: [Model]
})
export class CreateDriverFormComponent extends ReactiveForm implements OnInit {
  @ViewChild("stepper", { static: false }) stepper: MatStepper;
  @ViewChild(PersonVehicleComponent, { static: false }) personVehicle: PersonVehicleComponent;
  previsualizationSettings: PrevisualizationSettings;
  docTypes: string[] = ['arl', 'eps'];
  basicDataFields = basicDataFields;
  contactsAndReferencesFields = contactsAndReferencesFields;
  basicDataFieldsWithoutTruora = [];
  constructor(
    public driverModel: Model,
    public formBuilder: FormBuilder,
    public utils: Utils,
    private snackBarService: SnackBarService,
    private adminUsersService: AdminUsersService,
    private fileService: FileService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private router: Router
  ) { 
    super(
      formBuilder,
      driverModel.modelCreateDriverFull
    );
    this.setValidatorsForm(driverModel.modelCreateDriverValidatorsFull, this.form);
  }

  ngOnInit() {
    if (this.form && this.form.get('information.document') && !this.form.get('information.document').value) this.adminUsersService.previsulizationSettings = {};
  }

  checkStep(){
    if (this.stepper && this.utils.isDefined(this.stepper.selectedIndex)){
      let fieldsWithoutTruora = ['licenses', 'extraDocuments'];

      switch (this.stepper.selectedIndex) {
        case 0:
          this.checkFieldErrors(this.enableSecurityStudy ? basicDataFields.filter(field => !fieldsWithoutTruora.includes(field.control)) : basicDataFields);
          break;
        case 1:
          this.checkFieldErrors(contactsAndReferencesFields);
          break;
        case 2:
          this.checkFieldErrors(driverDocumentsFields);
          break;
        default:
          break;
      }
    }
  }

  findErrorOnExtraDocuments(extraDocuments: AbstractControl[]) {
    let haveErrors = false;
    for (let [i, extraDocument] of extraDocuments.entries()) {
      if (!haveErrors) {
        if (this.utils.errorMessagesCustomized(extraDocument.get('path'), `${extraDocument.get('type').value === AdditionalCertificationEnum.OPERATIONAL_ACCREDITATION ? AdditionalCertificationDict[extraDocument.get('type').value] : extraDocument.get('type').value}`)) {
          haveErrors = true;
          break;
        }
      } else break;
    }
    return haveErrors;
  }

  findErrorOnLicense(licenses: AbstractControl[]) {
    let haveErrors = false;
    for (let [i, license] of licenses.entries()) {
      if (!haveErrors) {
        if (this.utils.errorMessagesCustomized(license.get('category'), `categoria de la licencia ${i + 1}`)) {
          haveErrors = true;
          break;
        }
        else if (this.utils.errorMessagesCustomized(license.get('numberLicense'), `número de la licencia ${i + 1}`)) {
          haveErrors = true;
          break;
        }
        else if (this.utils.errorMessagesCustomized(license.get('expeditionDate'), `fecha de expedición de la licencia ${i + 1}`)) {
          haveErrors = true;
          break;
        }
        else if (this.utils.errorMessagesCustomized(license.get('expirationDate'), `fecha de expiración de la licencia ${i + 1}`)) {
          haveErrors = true;
          break;
        }
      } else break;
    }
    return haveErrors;
  }

  checkFieldErrors(fields: {control: string, name?: string}[]){
    this.form.markAllAsTouched();
    let haveErrors = false;
    let arrayFields = ['extraDocuments', 'licenses'];
    for (const field of fields) {
      const control = field.control;
      if (!haveErrors) {
        if (this.utils.errorMessagesCustomized(this.form.get(control), field.name) && !arrayFields.includes(control)) {
          haveErrors = true;
          break;
        }
        if (arrayFields.includes(control)) {
          const customValidators = {
            extraDocuments: () => this.findErrorOnExtraDocuments(this.extraDocuments),
            licenses: () => this.findErrorOnLicense(this.licenses),
          };
          if (customValidators[control] && customValidators[control]()) {
            haveErrors = true;
            break;
          }
        }
      } else break;
    }
    if (!haveErrors){
      if (this.stepper.selectedIndex === 0 && this.adminUsersService.checkDuplicatedLicenses(this.form)) return this.snackBarService.openSnackBar(FormMessages.DUPLICATED_LICENSES, undefined, "alert");
      if (this.stepper.selectedIndex === this.stepper.steps.length - 1) this.onSubmit(); 
      else this.stepper.next();
    }
  }

  async onSubmit(){
    this.spinner.show();
    let formToSend: User = {...this.form.value};
    this.formatDates(formToSend);
    this.cleanFormToSend(formToSend);
    formToSend.role = 'Driver';
    let previsualizationSettingsToSend: PrevisualizationSettings = {...this.adminUsersService.previsulizationSettings};
    if (formToSend && formToSend.driverLicenseCategory && formToSend.driverLicenseCategory.length > 0) this.formatLicenses(formToSend);
    if (this.adminUsersService.previsulizationSettings && Object.keys(this.adminUsersService.previsulizationSettings).length > 0) this.updateBasePath(previsualizationSettingsToSend, formToSend);
    if (Object.keys(previsualizationSettingsToSend).length > 0) await this.uploadStorage(previsualizationSettingsToSend);
    if (formToSend.extraDocuments && formToSend.extraDocuments.length > 0 && formToSend.extraDocuments.some(extraDocument => !!extraDocument.path)) await this.processExtraDocuments(formToSend.extraDocuments);
    delete formToSend.extraDocuments;
    await this.createDriver(formToSend, !this.authService.getCompanySaaS().allowCreatingWithoutTruora);
    this.spinner.hide();
  }

  formatLicenses(formToSend: User): void {
    formToSend.driverLicenseCategory.forEach(license => delete license.preview);
    let isSomeLicenseActive = formToSend.driverLicenseCategory.some(license => license.active);
    formToSend.driverLicense = {
      active: isSomeLicenseActive,
      description: `Licencia de conducción ${isSomeLicenseActive ? 'activa' : 'no activa'}`
    }
  }

  cleanFormToSend(formToSend: User): void {
    if (formToSend.arl) delete formToSend.arl;
    if (formToSend.eps) delete formToSend.eps;
    if (this.utils.isDefined(formToSend.numberOfTrips)) delete formToSend.numberOfTrips;
    if (formToSend.state) delete formToSend.state;
    delete formToSend.truora;
  }

  formatDates(formToSend: User): void {
    formToSend.extraDocuments.forEach(extraDocument => {
      extraDocument.dueDate = DateManager.utcTransform(new Date(extraDocument.dueDate));
    });
    formToSend.driverLicenseCategory.forEach(license => {
      license.expeditionDate = DateManager.utcTransform(new Date(license.expeditionDate), 'YYYY-MM-DDTHH:mm:ss[Z]');
      license.expirationDate = DateManager.utcTransform(new Date(license.expirationDate), 'YYYY-MM-DDTHH:mm:ss[Z]');
    });
  }

  updateBasePath(previsualizationSettingsToSend: PrevisualizationSettings, formToSend: User): void {
    Object.keys(previsualizationSettingsToSend).forEach(key => {
      const setting = previsualizationSettingsToSend[key];
      if (setting && setting.filePath && setting.filePath.includes('temp') && !setting.loaded) {
        setting.filePath = setting.filePath.replace('temp', this.form.get('information.document').value);
        if ((key === 'arl' || key === 'eps' || key === 'operationalAccreditation') && formToSend.extraDocuments && formToSend.extraDocuments.length) formToSend.extraDocuments.find(extraDocument => extraDocument.type === 'ARL' || extraDocument.type === 'EPS' ? extraDocument.type.toLowerCase() === key : extraDocument.type === key).path = setting.filePath;
        else formToSend[key] = setting.filePath;
      }
    });
  }

  setPreviewsResources($event: PrevisualizationSettings){
    this.previsualizationSettings = $event;
  }

  async processExtraDocuments(extraDocuments: { type: string, path: string, dueDate: string }[]): Promise<void> {
    if (extraDocuments.length){
      let arlAndEpsDocs = extraDocuments.filter(extraDocument => extraDocument.type.toLowerCase() === 'arl' || extraDocument.type.toLowerCase() === 'eps');
      if (arlAndEpsDocs.length) await this.userUploadDocument(arlAndEpsDocs as ExtraDocument[]);
      else {
        let operationalAccreditationDoc = extraDocuments.find(extraDocuments => extraDocuments.type === AdditionalCertificationEnum.OPERATIONAL_ACCREDITATION);
        if (operationalAccreditationDoc) await this.userUploadDocument(operationalAccreditationDoc.type as AdditionalCertificationEnum, operationalAccreditationDoc.path, operationalAccreditationDoc.dueDate);
      }
    }
  }

  private async userUploadDocument(type: ExtraDocument[] | AdditionalCertificationEnum, path?: string, dueDate?: string): Promise<void> {
    if (!path && !Array.isArray(type)) return;
    try {
      if (Array.isArray(type)) {
        await this.adminUsersService.userUploadListDocuments(
          this.form.get('information.document').value,
          type
        ).toPromise();
      } else {
        await this.adminUsersService.updateUserAdditionalCertifications(
          this.form.get('information.document').value,
          {
            id: type,
            active: false,
            path,
            dueDate,
            description: AdditionalCertificationDict[type]
          }
        ).toPromise();
      }
    } catch (error) {
      console.error("Error during upload or save resources:", error);
    }
  }
  async uploadStorage(previsualizationSettingsToSend: PrevisualizationSettings): Promise<void> {
    const filesToUpload = this.getFilesToUpload(previsualizationSettingsToSend);
    if (!filesToUpload.length) return;
    try {
      const loadedResponse = await this.fileService.uploadMultipleFiles(filesToUpload);
      await this.handleUploadResponse(loadedResponse, previsualizationSettingsToSend);
    } catch (error) {
      console.error("Error during upload or save resources:", error);
      this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
    }
  }

  async createDriver(formToSend: User, allowCreatingWithoutTruora: boolean): Promise<void> {
    try {
      const success: User = await this.adminUsersService.checkValidationDynamicallyIndividual(formToSend, allowCreatingWithoutTruora).toPromise();
      this.adminUsersService.previewPrevizualizationSettings = {};
      this.adminUsersService.previsulizationSettings = {};
      this.router.navigate(['/administration/admin-users/detail', success.information.document]);
      this.snackBarService.openSnackBar(ServiceMessages.DRIVER_CREATED_SUCCESSFULLY, undefined, 'success');
    } catch (error) {
      console.error("error", error);
      this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
    }
  }
  
  private getFilesToUpload(previsualizationSettingsToSend: PrevisualizationSettings): { path: string; imageFile: File; key: string }[] {
    return Object.keys(previsualizationSettingsToSend)
      .filter(key => !previsualizationSettingsToSend[key].loaded && previsualizationSettingsToSend[key].filePath)
      .map(key => ({
        path: previsualizationSettingsToSend[key].filePath,
        imageFile: previsualizationSettingsToSend[key].imageFile,
        key: key
      }));
  }
  
  private async handleUploadResponse(loadedResponse: { status: boolean; success: UploadTaskSnapshot; key: string }[], previsualizationSettingsToSend: PrevisualizationSettings): Promise<void> {
    const updatePromises = loadedResponse.map(loadedFile => 
      this.updateFileMetadata(loadedFile, previsualizationSettingsToSend)
    );
  
    return Promise.all(updatePromises)
      .then(() => {});
  }
  
  private async updateFileMetadata(loadedFile: { success: UploadTaskSnapshot; key: string }, previsualizationSettingsToSend: PrevisualizationSettings): Promise<void> {
    return loadedFile.success.ref.getDownloadURL()
      .then(url => {
        const formatFile = previsualizationSettingsToSend[loadedFile.key].filePath.split('.').pop();
        previsualizationSettingsToSend[loadedFile.key] = {
          file: {
            url: url,
            type: formatFile
          },
          loaded: true
        };
      })
      .catch(error => {
        console.error(`Error getting download URL for key ${loadedFile.key}:`, error);
        throw error;
      });
  }
  

  // GETTERS
  get extraDocuments(): AbstractControl[] {
    return (this.form.get('extraDocuments') as FormArray).controls;
  }

  get licenses(): AbstractControl[] {
    return (this.form.get('driverLicenseCategory') as FormArray).controls;
  }

  get enableSecurityStudy(): boolean {
    return !(!!this.authService.getCompanySaaS().allowCreatingWithoutTruora);
  }
}
