<aside class="cargo__location__report">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <form class="row" *ngIf="reactiveForm && reactiveForm.form" [formGroup]="reactiveForm.form" (submit)="onSubmit()">
    <div class="col-md-12 title-component text-center mx-4">
      Seguimiento Servicio {{cargo?.consecutive}} - Placa {{cargo?.licensePlate}}
    </div>
    <div class="col-md-12" *ngIf="cargo.lastPointLocation && !editReport">
      <app-cargo-last-point-location [lastPointLocation]="cargo.lastPointLocation"
        [title]="'Último Seguimiento Registrado'" [cargo]="cargo" (lastPointChanged)="lastPointChanged($event)">
      </app-cargo-last-point-location>
    </div>

    <div class="col-12 mt-2" *ngIf="cargo.cargoFeature.vehicleType.quality">
      <label class="text-center temperature">
        <i class="fas fa-temperature-low"></i> Registro de temperatura del servicio
      </label>
      <div class="row temperature-register" formGroupName="temperature">
        <div class="col-lg-6">
          <mat-form-field appearance="standard">
            <mat-label>Temperatura<span class="asterisk">*</span></mat-label>
            <input type="number" formControlName="value" matInput>
            <mat-error *ngIf="reactiveForm.form.get('temperature.value').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-6 ">
          <label id="example-radio-group-label" class="text-center mr-2">Unidad de medida</label>
          <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
            formControlName="unit">
            <mat-radio-button class="example-radio-button" *ngFor="let type of temperatureUnits" [value]="type.unit">
              °{{type.unit}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>


    <div class="col-md-12">
      <mat-form-field appearance="standard">
        <mat-label>Reporte<span class="asterisk">*</span></mat-label>
        <mat-chip-list *ngIf="!utils.isEmpty(reactiveForm.form.get('name').value)">
          <mat-chip>{{reactiveForm.form.get('name').value}}</mat-chip>
          <span class="cursor-pointer cancel-value-select"
            (click)="reactiveForm.form.get('name').setValue(null)">x</span>
        </mat-chip-list>
        <input type="text" #inputAutoComplete matInput formControlName="name" [matAutocomplete]="auto"
          *ngIf="utils.isEmpty(reactiveForm.form.get('name').value)">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectAnomaly($event)">
          <mat-option *ngFor="let anomaly of listAnomalies.catalog" [value]="anomaly.name">
            {{anomaly.name}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="reactiveForm.form.get('name').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-12">
      <mat-form-field appearance="standard">
        <mat-label>Observaciones</mat-label>
        <textarea type="text" matInput formControlName="observation"></textarea>

      </mat-form-field>
    </div>
    <div class="col-md-12">
      <mat-form-field appearance="standard">
        <mat-label>Última ubicación<span class="asterisk">*</span></mat-label>
        <input matInput autocomplete="off" matGoogleMapsAutocomplete #inputLastPoint
          (onAutocompleteSelected)="onAutocompleteSelected(null, $event)" type="text"
          tooltipClass="tooltip-container-location" placement="bottom" triggers="manual" [formControl]="coordinates">
        <mat-error *ngIf="coordinates.errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
    </div>
    <br>

    <div class="col-md-12 d-flex flex-row mt-3 align-middle" *ngIf="coordinatesAddress">
      <p class="mr-2"><b>Dirección seleccionada: </b> </p><span> {{ coordinatesAddress}}</span>
    </div>

    <div class="col-md-12 mt-5" style="height: 500px;">
      <app-standard-map [options]="mapOptions" (clickMap)="onLoadMap($event)"></app-standard-map>
    </div>

    <div class="col-md-12 button-container mt-2">
      <button type="button" (click)="onCancel()" class="button-width-auto primary-secondary" mat-raised-button>
        Cancelar
      </button>
      <button class="button-width-auto bg-color-primary" mat-raised-button color="primary">
        Guardar
      </button>
    </div>
  </form>
</aside>