<aside class="user__documents">
  <div class="row title-2 text-strong color-secondary-dark">
    <div class="col-md-12">
      Documentos {{role ? 'del ' + (role | roleTranslate) : ''}} {{isSameOwner ? ' y del propietario' : ''}}
    </div>
  </div>
  <form class="row" [formGroup]="form" (submit)="onSubmit()">
    <!-- Profile Picture-->
    <div class="col-md-4">
      <input #inputImage class="display-none" type="file" (change)="setInstanceFileStorage($event, 'profilePicture')"
        [disabled]="isDisable">
      <mat-form-field appearance="standard" (click)="openDialogProfilePhoto()">
        <mat-label>Foto de perfil<span class="asterisk"
          *ngIf="utils.isRequiredField(visualProfilePicture)">*</span></mat-label>
        <input matInput type="text" class="field__form--input" readonly [formControl]="visualProfilePicture"
          [disabled]="isDisable">
        <mat-error *ngIf="form.get('profilePicture').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
        <i class="fas fa-camera i-picture" matSuffix for="upload-photo"></i>
      </mat-form-field>
      <div *ngIf="loadingResources && visualProfilePicture?.value" class="spinner-container">
        <i class="fas fa-spinner fa-spin"></i>
      </div>

      <ng-container *ngIf="adminUsersService.getResourceFile('profilePicture'+ (role ? '-' + role : '')) || adminUsersService.getErrorLoadResource('profilePicture'+ (role ? '-' + role : ''))">
        <app-previsualization-resources matSuffix [resource]="adminUsersService.getResourceFile('profilePicture'+ (role ? '-' + role : ''))" [errorLoadResource]="adminUsersService.getErrorLoadResource('profilePicture'+ (role ? '-' + role : ''))"></app-previsualization-resources>
      </ng-container>
    </div>

     <!-- Front Document-->
     <div class="col-md-4">
      <input #inputFrontDocument class="display-none" type="file"
        (change)="setInstanceFileStorage($event,'frontDocument')" [disabled]="isDisable">
      <mat-form-field appearance="standard" (click)="openWindowFile('inputFrontDocument')">
        <mat-label>Foto documento de identidad (parte frontal)<span class="asterisk"
          *ngIf="utils.isRequiredField(visualFrontDocument)">*</span></mat-label>
        <input matInput type="text" class="field__form--input" readonly [formControl]="visualFrontDocument"
          [disabled]="isDisable">
        <i class="fas fa-paperclip" matSuffix></i>
        <mat-error *ngIf="form.get('frontDocument').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
      <div *ngIf="loadingResources && visualFrontDocument?.value" class="spinner-container">
        <i class="fas fa-spinner fa-spin"></i>
      </div>
      <ng-container *ngIf="adminUsersService.getResourceFile('frontDocument'+ (role ? '-' + role : '')) || adminUsersService.getErrorLoadResource('frontDocument'+ (role ? '-' + role : ''))">
        <app-previsualization-resources matSuffix [resource]="adminUsersService.getResourceFile('frontDocument'+ (role ? '-' + role : ''))" [errorLoadResource]="adminUsersService.getErrorLoadResource('frontDocument'+ (role ? '-' + role : ''))"></app-previsualization-resources>
      </ng-container>
    </div>

    <!-- Back Document-->
    <div class="col-md-4">
      <input #inputBackDocument class="display-none" type="file"
        (change)="setInstanceFileStorage($event,'backDocument')" [disabled]="isDisable">
      <mat-form-field appearance="standard" (click)="openWindowFile('inputBackDocument')">
        <mat-label>Foto documento de identidad (parte trasera)<span class="asterisk"
          *ngIf="utils.isRequiredField(visualBackDocument)">*</span></mat-label>
        <input matInput type="text" class="field__form--input" readonly [formControl]="visualBackDocument"
          [disabled]="isDisable">
        <mat-error *ngIf="form.get('backDocument').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
        <i class="fas fa-paperclip" matSuffix></i>
      </mat-form-field>
      <div *ngIf="loadingResources && visualBackDocument?.value" class="spinner-container">
        <i class="fas fa-spinner fa-spin"></i>
      </div>
      <ng-container *ngIf="adminUsersService.getResourceFile('backDocument'+ (role ? '-' + role : '')) || adminUsersService.getErrorLoadResource('backDocument'+ (role ? '-' + role : ''))">
        <app-previsualization-resources matSuffix [resource]="adminUsersService.getResourceFile('backDocument'+ (role ? '-' + role : ''))" [errorLoadResource]="adminUsersService.getErrorLoadResource('backDocument'+ (role ? '-' + role : ''))"></app-previsualization-resources>
      </ng-container>
    </div>
    <div class="col-md-12 user__actions d-flex" *ngIf="!hideBtn" permission-display [module]="permission.cargo.module"
      [functionality]="permission.cargo.saveDriverDocuments">
      <mat-card-actions class="text-center">
        <button mat-raised-button color="primary" type="submit" class="button-width-auto user__actions--button">
          Guardar
        </button>
      </mat-card-actions>
    </div>
  </form>
</aside>