import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Router } from '@angular/router';
import { User } from 'src/app/core/interfaces/user';
import { Utils } from 'src/app/core/resources/utils';
import { Global } from 'src/app/core/resources/global';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Titles } from 'src/app/core/resources/titles';
import { RolesService } from '../roles.service';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { Company } from 'src/app/core/interfaces/company';
import { FreightListService } from 'src/app/modules/cargo/cargo-list/cargo-list.service';
import { FormControl } from '@angular/forms';
import { Role } from 'src/app/core/interfaces/role';
import { Permission } from 'src/app/core/resources/permission';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-list-roles',
  templateUrl: './list-roles.component.html',
  styleUrls: ['./list-roles.component.scss'],
  providers: [AuthService]
})
export class ListRolesComponent implements OnInit {

  pageKey = 1;
  pageSize = 25;
  throttle = 300;
  scrollDistance = 1;
  enablePagingScroll = true;
  listRoles: Role[] = [];
  companyUser: Company;
  companySelected: FormControl = new FormControl();
  companySub: Subscription;
  permission = Permission;
  optionsCompany = {
    title: 'Compañía',
    initialNit: this.authService.getCompany().companyId
  };
  constructor(
    public authService: AuthService,
    private titles: Titles,
    private rolesService: RolesService,
    public utils: Utils,
    private global: Global,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private router: Router,
    private freightListService: FreightListService
  ) { }

  ngOnInit() {
    this.companySub = this.companySelected.valueChanges.subscribe((value) => {
      if (value.companyId) this.getRoles();
    });
  }

  getRoles() {
    this.rolesService.getRolesByNit(this.companySelected.value.companyId).subscribe(
      (roles: Role[]) => {
        if (roles && roles.length)
          this.listRoles = roles.sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
          });
        else
          this.listRoles = [];
      },
      (error) => {
        this.listRoles = [];
      }
    );
  }

  goToCreate() {
    this.router.navigate(['administration/roles/create']);
  }

  ngOnDestroy() {
    if (this.companySub) this.companySub.unsubscribe();
  }

}
