import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AddressCargo } from 'src/app/core/interfaces/addressCargo';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { ServiceType } from 'src/app/core/interfaces/serviceType';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { DateFormatPipe } from 'src/app/core/pipe/dateFormat.pipe';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
@Component({
  selector: 'app-public-cargo-evidences-detail',
  templateUrl: './public-cargo-evidences-detail.component.html',
  styleUrls: ['./public-cargo-evidences-detail.component.scss'],
  providers: [DatePipe, DateFormatPipe]
})
export class PublicCargoEvidencesDetailComponent implements OnInit {
  @Input() address: AddressCargo;
  @Input() mainImageUrl: string = '';
  @Input() typeMainImageUrl: string = '';
  @Input() dataCargo: any = {};
  @Input() cargo: Cargo;
  @Input() index: number = 0;
  @Input() type: string = '';
  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  expandIcon: boolean = false;
  expandedImage: boolean = false;
  resourceType: string = '';
  startIndexDocument = 0;
  endIndexDocument = 5;
  startIndexTruck = 0;
  endIndexTruck = 5;
  lastItemRightDocument: boolean = false;
  lastItemLeftDocument: boolean = false;
  lastItemRightTruck: boolean = false;
  lastItemLeftTruck: boolean = false;
  constructor(
    public datePipe: DatePipe,
    public dateFormatPipe: DateFormatPipe,
    public snackbarService: SnackBarService,
    private utils: Utils,
  ) { }

  ngOnInit() {
    this.typeOfResource(this.typeMainImageUrl);
  }

  changeActiveImage(activeImageUrl: any) {
    this.typeOfResource(activeImageUrl.type);
    this.mainImageUrl = this.resourceType && this.resourceType === 'pdf' ? activeImageUrl.sanitizeUrlPdf : activeImageUrl.url;
  }

  typeOfResource(resource) {
    if (this.mainImageUrl && resource) this.resourceType = resource === 'pdf' ? resource : 'image';
    else if (resource === undefined) {
      this.resourceType = 'errorLoadedResource';
      this.snackbarService.openSnackBar(FormMessages.ERROR_LOAD_RESOURCE, undefined, 'error');
    }
    else this.resourceType = 'notResource';
  }

  formatDate(fecha: string): string {
    return this.datePipe.transform(fecha, "d MMMM yyyy");
  }

  expandImage() {
    this.expandedImage = !this.expandedImage;
    this.emitToParent.emit({ type: 'expandImage', value: this.expandedImage });
  }

  carrouselChange(direction: string, size: number, type: string) {
    if (type) {
      this.processDirection(direction, size, type);
    }
  }

  processDirection(direction: string, size: number, type: string) {
    let startIndex, endIndex;
    let lastItemRight = false;
    let lastItemLeft = false;
    if (type && type === 'document') {
      startIndex = this.startIndexDocument;
      endIndex = this.endIndexDocument;
    } else {
      startIndex = this.startIndexTruck;
      endIndex = this.endIndexTruck;
    }

    if (direction === 'right') {
      if (endIndex < size) {
        startIndex++;
        endIndex++;
      } else lastItemRight = !lastItemRight;
    } else if (direction === 'left') {
      if (startIndex > 0) {
        startIndex--;
        endIndex--;
      } else lastItemLeft = !lastItemLeft;
    }

    if (type && type === 'document') {
      this.startIndexDocument = startIndex;
      this.endIndexDocument = endIndex;
      this.lastItemRightDocument = lastItemRight;
      this.lastItemLeftDocument = lastItemLeft;
    } else {
      this.startIndexTruck = startIndex;
      this.endIndexTruck = endIndex;
      this.lastItemRightTruck = lastItemRight;
      this.lastItemLeftTruck = lastItemLeft;
    }
  }

  //Getters
  getErrorLoadedResource(url: string): boolean {
    if (url) {
      if (typeof url === 'string') return !(url.includes('icon-no-file.svg'));
      else return true;
    }
    return false;
  }

  get isEscortedService(): boolean {
    const serviceType: ServiceType = this.utils.getNestedValue(this.cargo, 'cargoModel.serviceType');
    return serviceType && serviceType.id === 'escortServices';
  }
}
