<aside class="wrapper-user-form">
  <div class="col-md-12">
    <div class="row ">
      <div class="col-12 py-2 px-1">
        <div *ngIf="!paramsDialog?.driver" (click)="backView()" class="back-view">
          <i class="fas fa-arrow-left"></i>
          Volver al listado
        </div>
      </div>
      <div class="col-md-12 wrapper-detail" *ngIf="userDocument">
        <i *ngIf="paramsDialog && paramsDialog.driver" matTooltip="Cerrar"
          class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
        <div class="title-component px-5 px-sm-0">
          <div class="col-md-12 p-0">
            <div class="row">  
              <div class="state-container">
                <i class="fas fa-user-edit"></i>
                {{this.mode === 'created' ? 'Crear' : 'Ver'}} usuario
                <span *ngIf="adminUsersService.getUserState(form.getRawValue())" class="badge badge-warning" [ngClass]="getBadgeClass(adminUsersService.getUserState(form.getRawValue()))">Estado: {{ adminUsersService.getUserState(form.getRawValue()) }}</span>
              </div>
              
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <br>
        <mat-tab-group>
          <mat-tab label="Información general">
              <app-person-vehicle 
                *ngIf="form" 
                [userDocument]="userDocument"
                [title]="roleTranslated()" 
                [typeAction]="'editDriver'" 
                [form]="form" 
                [onlyRequiredFieldsInForm]="true" 
                [paramsDialog]="paramsDialog" 
                [user]="user"
                [extraInformationUserOptions]="{isTeclogiUserOrClient: isTeclogiUserOrClient }"
                [documentValidation]="documentValidation"
                (emitUploadDocument)="dialogRef.close({ state: true })"
                (emitRefreshLoad)="getDetailUserByDocument($event)"
                (emitLoadUpdated)="loadUpdated($event)"
                (emitTypedDocRules)="typedDocRules = $event"
                >
              </app-person-vehicle>
              <!-- Terminos y condiciones -->
              <ng-container *ngIf="form?.get('lastPersonalDataPolicy')?.value?.fingerprint?.date || form?.get('lastPrivacyPolicies')?.value?.fingerprint?.date || form?.get('lastUserTerm')?.value?.fingerprint?.date">
                <ng-container *ngTemplateOutlet="termsAndConditions"></ng-container>
              </ng-container>
              <!-- Razones para desactivar -->
              <ng-container *ngIf="form?.get('state')?.value && !form?.get('state')?.value?.active">
                <ng-container *ngTemplateOutlet="cannotActivateReasons"></ng-container>
              </ng-container>
              <!-- Actions -->
              <ng-container *ngTemplateOutlet="actions"></ng-container>
          </mat-tab>
          <mat-tab label="Documentos">
            <app-user-documents 
              *ngIf="form && form.get('information.document').value" 
              [form]="form" 
              [hideBtn]="false" 
              [previewPrevizualizationSettings]="previsualizationSettings" 
              (emitPreviewsResources)="setPreviewsResources($event)">
            </app-user-documents>
          </mat-tab>
        </mat-tab-group>
      </div>



      <!-- Terms and conditions template -->
      
      <ng-template #termsAndConditions>
        <div class="col-12 mt-3">
          <div class="alert alert-info d-flex flex-md-row flex-column justify-content-between" role="alert">
            <div class="d-flex flex-column justify-content-between m-2" *ngIf="this.form?.get('lastUserTerm')?.value">
              <h5 class="text-bold">Terminos y condiciones</h5>
              <div>
                <div>Fecha de aceptación: {{ this.form?.get('lastUserTerm')?.value?.fingerprint?.date ?
                  formatDate(this.form?.get('lastUserTerm')?.value?.fingerprint?.date) : '-'}}</div>
                <div>Hora de aceptación: {{ this.form?.get('lastUserTerm')?.value?.fingerprint?.date ?
                  dm.onlyTime(this.form?.get('lastUserTerm')?.value?.fingerprint?.date) : '-'}}</div>
                <div>Version aceptada: {{ this.form?.get('lastUserTerm')?.value?.version ?
                  this.form?.get('lastUserTerm')?.value?.version : '-'}}</div>
              </div>
            </div>
            <div class="d-flex flex-column justify-content-between m-2"
              *ngIf="this.form?.get('lastPersonalDataPolicy')?.value">
              <h5 class="text-bold">Politica de datos personales</h5>
              <div>
                <div>Fecha de aceptación: {{ this.form?.get('lastPersonalDataPolicy')?.value?.fingerprint?.date ?
                  formatDate(this.form?.get('lastPersonalDataPolicy')?.value?.fingerprint?.date) : '-'}}</div>
                <div>Hora de aceptación: {{ this.form?.get('lastPersonalDataPolicy')?.value?.fingerprint?.date ?
                  dm.onlyTime(this.form?.get('lastPersonalDataPolicy')?.value?.fingerprint?.date) : '-'}}
                </div>
                <div>Version aceptada: {{ this.form?.get('lastPersonalDataPolicy')?.value?.version ?
                  this.form?.get('lastPersonalDataPolicy')?.value?.version : '-'}}</div>
              </div>
            </div>
            <div class="d-flex flex-column justify-content-between m-2"
              *ngIf="this.form?.get('lastPrivacyPolicies')?.value">
              <h5 class="text-bold">Politicas de privacidad</h5>
              <div>
                <div>Fecha de aceptación: {{ this.form?.get('lastPrivacyPolicies')?.value?.fingerprint?.date ?
                  formatDate(this.form?.get('lastPrivacyPolicies')?.value?.fingerprint?.date) : '-'}}</div>
                <div>Hora de aceptación: {{ this.form?.get('lastPrivacyPolicies')?.value?.fingerprint?.date ?
                  dm.onlyTime(this.form?.get('lastPrivacyPolicies')?.value?.fingerprint?.date) : '-'}}
                </div>
                <div>Version aceptada: {{ this.form?.get('lastPrivacyPolicies')?.value?.version ?
                  this.form?.get('lastPrivacyPolicies')?.value?.version : '-'}}</div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <!-- Cannot activate reason template -->
      <ng-template #cannotActivateReasons>
        <div class="col-md-12">
          <mat-label class="color-alert" *ngIf="cannotActivateReason?.length > 0">
            <ng-container *ngFor="let reason of cannotActivateReason;">
              <i class="fas fa-exclamation-triangle"></i>{{ reason }}
              <br>
            </ng-container>
          </mat-label>
        </div>
      </ng-template>


      <ng-template #actions>
        <div class="d-flex flex-wrap align-items-center justify-content-around mt-3">
          <button 
            mat-raised-button 
            type="button" 
            permission-display 
            [module]="permission.administration.module"
            [functionality]="permission.administration.validateDocumentsPendingDrivers"
            *ngIf="form?.get('state.description')?.value && (form?.get('state.description')?.value === userState.PENDING_VALIDATE_DOCUMENTS || getPendingApproval(form.getRawValue())) && 
            (adminUsersService.getExtraDocumentByType('ARL', form.getRawValue()) || adminUsersService.getExtraDocumentByType('EPS', form.getRawValue()) || adminUsersService.getExtraDocumentByType(additionalCertificationEnum.OPERATIONAL_ACCREDITATION, form.getRawValue())) && this.form?.get('state.description')?.value != 'Pending'"
            [matMenuTriggerFor]="menuOptionsDocuments" 
            class="primary-secondary mb-3 button-small" 
            color="primary">
            Validar documentos
          </button>
          <button mat-raised-button color="primary" permission-display [module]="permission.administration.module"
            [functionality]="permission.administration.showSecurityAnalysis"
            *ngIf="utils.isDefined(this.form.get('truora')) && utils.isDefined(this.form.get('truora').get('checkId')) && utils.isDefined(this.form.get('truora').get('checkId').value) && this.form?.get('state.description')?.value && this.form?.get('state.description')?.value != 'Pending'"
            class="primary-secondary button-width-auto mb-3 button-small" type="button" (click)="getUrlPDFTruora()">
            Ver análisis de seguridad
          </button>
          <button mat-raised-button *ngIf="showButtonInactivateActivateUser && !inactiveReasons && form?.get('information.document')?.value && this.form?.get('state.description')?.value !== 'Pending'"
            class="button-width-auto mb-3 button-small"
            [ngClass]="{'warn': this.form?.get('state.active')?.value, 'primary-secondary': !this.form?.get('state.active')?.value}"
            type="button" (click)="this.form?.get('state.active')?.value ? openDialogDeactivateUser() : confirmActive()">
            {{this.form?.get('state.active')?.value ? 'Desactivar Usuario' : 'Activar Usuario'}}
          </button>
          <button mat-raised-button color="primary" class="primary-secondary button-width-auto mb-3 button-small"
            permission-display [module]="permission.administration.module"
            [functionality]="permission.administration.activateByScore" *ngIf="showButtonForceActivation"
            type="button" (click)="confirmForceActivation()">
            Activar por Score
          </button>
          <button type="button" permission-display [module]="permission.administration.module"
            [functionality]="permission.administration.checkUser" *ngIf="showButtonConfirmCheckUser"
            class="button-width-auto primary-secondary mb-3 button-small" mat-raised-button color="primary"
            (click)="checkValidationUser()">
            Validar estudio de seguridad
          </button>
          <!-- <button mat-raised-button color="primary" type="submit" *ngIf="!disabledForm" class="mb-3 button-small">
            {{this.mode === 'created' ? 'Crear' : 'Guardar'}}
          </button> -->
        </div>
      </ng-template>
    </div>
  </div>

</aside>
<mat-menu #menuOptionsDocuments="matMenu">
  <button mat-menu-item (click)="getURLDocument('ARL')"
    *ngIf="adminUsersService.getExtraDocumentByType('ARL', form.getRawValue()) && getPendingApprovalByType('ARL', form.getRawValue())">
    ARL
  </button>
  <button mat-menu-item (click)="getURLDocument('EPS')"
    *ngIf="adminUsersService.getExtraDocumentByType('EPS', form.getRawValue()) && getPendingApprovalByType('EPS', form.getRawValue())">
    EPS
  </button>
  <button mat-menu-item (click)="getURLDocument(additionalCertificationEnum.OPERATIONAL_ACCREDITATION)"
    *ngIf="adminUsersService.getExtraDocumentByType(additionalCertificationEnum.OPERATIONAL_ACCREDITATION, form.getRawValue()) && getPendingApprovalByType(additionalCertificationEnum.OPERATIONAL_ACCREDITATION, form.getRawValue())">
    Acreditación de personal operativo
  </button>
</mat-menu>

<!-- Modal Force Activation User-->
<ng-template #modalConfirmForceActivation let-modal>
  <div class="modal-body wrapper-modal">
    <p class="title" *ngIf="user.getInformation().getDocument()">
      ¿Estás seguro que deseas <br> forzar la activacíon de <br> {{user.getInformation().getName()}}?
    </p>
    <mat-card-actions>
      <button mat-raised-button class="primary-secondary" color="primary" (click)="hideModals()">No</button>
      <button mat-raised-button color="primary" (click)="forzeActivation()">Si</button>
    </mat-card-actions>
  </div>
</ng-template>

<!-- Modal PDF TRUORA -->
<ng-template #modalPDFTruora let-modal>
  <div class="modal-body wrapper-modal modal-content-PDF" *ngIf="urlPDFTruora">
    <embed [src]="urlPDFTruora" type="application/pdf">
  </div>
</ng-template>