<form [formGroup]="formFilter" *ngIf="formFilter" class="row w-100">
  <b class="title col-12 pb-4">Filtro de reporte</b>

  <div class="col-xl-6 pr-0">
    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>Reporte<span class="asterisk">*</span></mat-label>
          <mat-select formControlName="reportType">
            <mat-option *ngFor="let type of typesReports" [value]="type.type">
              {{type.title}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="formFilter.get('reportType').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <app-select-company [options]="optionsCompany" [inputFormControl]="formFilter.get('company')">
        </app-select-company>
      </div>
      <div class="col-md-6">
        <mat-form-field class="date-field" appearance="outline">
          <mat-label>Desde*</mat-label>
          <input matInput [min]="loadDateOptions.min" [max]="loadDateOptions.max" [matDatepicker]="pickerLoadDate"
            placeholder="Desde*" formControlName="loadDate" name="loadDate" (click)="pickerLoadDate.open()"
            (dateChange)="onChangeDate('loadDate', $event)" readonly class="cursor-pointer">
          <mat-datepicker-toggle matPrefix [for]="pickerLoadDate"></mat-datepicker-toggle>
          <mat-datepicker #pickerLoadDate></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field class="date-field" appearance="outline">
          <mat-label>Hasta*</mat-label>
          <input matInput [max]="endDateOptions.max" [min]="endDateOptions.min" [matDatepicker]="pickerEndDate"
            placeholder="Hasta*" formControlName="endDate" name="endDate" (click)="pickerEndDate.open()"
            (dateChange)="onChangeDate('endDate', $event)" readonly class="cursor-pointer">
          <mat-datepicker-toggle matPrefix [for]="pickerEndDate"></mat-datepicker-toggle>
          <mat-datepicker #pickerEndDate></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-6" *ngIf="!hasEscortServicesCompany">
        <mat-form-field appearance="outline">
          <mat-label>Manifiesto</mat-label>
          <mat-select formControlName="ministry">
            <mat-option [value]="true">
              Con manifiesto
            </mat-option>
            <mat-option [value]="false">
              Sin manifiesto
            </mat-option>
            <mat-option [value]="-1">
              Todos
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12" [ngClass]="{'col-md-6': !hasEscortServicesCompany}">
        <mat-form-field appearance="outline">
          <mat-label>{{hasEscortServicesCompany? 'Placa del vehículo escolta':'Placa del vehículo'}}</mat-label>
          <input type="text" appLicensePlate matInput formControlName="plate">
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="col-xl-3 px-0 states" *ngIf="states && states.length > 0">
    <div class="states-container">
      <div *ngFor="let state of states; let i=index">
        <mat-checkbox [value]="state.state" [checked]="selectedStates.value.indexOf(state.state) > -1"
          (change)="onChange($event)">
          <b>{{ state.name }}</b>
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div [ngClass]="{'col-xl-6': !(states && states.length > 1)}" class="col-xl-3 p-0 align-self-end filter-container">
    <div class="button-container align-items-baseline justify-content-xl-start">

      <button mat-raised-button class="secondary-sharp clean-filter mx-2 mt-3" color="primary"
        (click)="cleanFilter(true)" matTooltip="Eliminar Filtros">
        <i class="fas fa-trash"></i>
      </button>

      <button type="button" class="btn btn-primary text-bold bg-color-secondary-dark mx-2" (click)="onSubmit()">
        Buscar
      </button>

      <div class="dropdown mx-2 mt-3">
        <input type="checkbox" id="dropdown" (click)="onClickDiv()" />
        <label for="dropdown" class="dropdown-btn">
          <span>Descargar</span>
          <div class="arrow-container ml-2">
            <i class="fas fa-chevron-down arrow" [class.rotated]="rotateArrowStates"></i>
          </div>
        </label>
        <ul class="dropdown-content" role="menu">
          <li
            [ngClass]="{'disable-option': !formFilter?.get('reportType')?.value || (states && states.length > 1 && selectedStates.value.length === 0)}"
            (click)="openModalExportReport('Excel')"><a><i class="fas fa-file-export mr-2"></i>XLSX</a></li>
          <li
            [ngClass]="{'disable-option': !isPDFReport || (states && states.length > 1 && selectedStates.value.length === 0)}"
            (click)="openModalExportReport('PDF')"><a><i class="fas fa-file-export mr-2"></i>PDF</a></li>
          <li
            [ngClass]="{'disable-option': !isPlainReport || (states && states.length > 1 && selectedStates.value.length === 0)}"
            (click)="openModalExportReport('Plain')"><a><i class="fas fa-file-export mr-2"></i>Plano</a></li>
        </ul>
      </div>

    </div>
  </div>
</form>