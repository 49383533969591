import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { environment } from '../../../../environments/environment';
import { User } from 'src/app/core/interfaces/user';
import { Vehicle } from 'src/app/core/interfaces/vehicle';

@Injectable({
  providedIn: 'root'
})
export class CargoTrackingService {

  private basePath = '/uploads';

  constructor(private _http: HttpClient, private _endpointResources: Endpoints) { }

  public rateCargo(idCargo, params) {
    return this._http.post(
      environment.urlServerTeclogi + this._endpointResources.urlRateCargo + idCargo + '&role=Creator',
      params
    );
  }

  public detailCargo(idCargo) {
    return this._http.get(
      environment.urlServerTeclogi + this._endpointResources.urlDetailCargo + idCargo
    );
  }

  public detailCargoByConsecutive(consecutive: string) {
    return this._http.get(
      environment.urlServerTeclogi + this._endpointResources.urlDetailCargoByConsecutive + consecutive
    );
  }

  public getImageByAddress(idCargo, address) {
    return this._http.get(
      environment.urlServerTeclogi + this._endpointResources.urlImageAddressCargo + idCargo + '&address=' + encodeURIComponent(address)
    );
  }

  public getCargoPDF(pdfType: string, cargoId: string) {
    let params = new HttpParams();
    params = params.append('pdfType', pdfType);
    params = params.append('cargoId', cargoId);
    return this._http.get(
      environment.urlServerTeclogi + this._endpointResources.cargoPDF,
      { params, responseType: 'blob' }
    );
  }

  public rebuildManifest(cargoId: string) {
    let params = new HttpParams();
    params = params.append('cargoId', cargoId);
    return this._http.get(
      `${environment.urlServerTeclogi}${this._endpointResources.rebuildManifest}`,
      { params }
    );
  }

  public fixOwnerMinistry(cargoId: string) {
    let params = new HttpParams();
    params = params.append('cargoId', cargoId);
    return this._http.post<User>(
      `${environment.urlServerTeclogi}${this._endpointResources.fixOwnerMinistry}`,
      {},
      { params }
    );
  }

  public fixVehicleMinistry(cargoId: string) {
    let params = new HttpParams();
    params = params.append('cargoId', cargoId);
    return this._http.post<Vehicle>(
      `${environment.urlServerTeclogi}${this._endpointResources.fixVehicleMinistry}`,
      {},
      { params }
    );
  }

  public fixTrailerVehicleMinistry(cargoId: string) {
    let params = new HttpParams();
    params = params.append('cargoId', cargoId);
    return this._http.post<Vehicle>(
      `${environment.urlServerTeclogi}${this._endpointResources.fixTrailerVehicleMinistry}`,
      {},
      { params }
    );
  }

  public fixUserMinistry(cargoId: string) {
    let params = new HttpParams();
    params = params.append('cargoId', cargoId);
    return this._http.post<User>(
      `${environment.urlServerTeclogi}${this._endpointResources.fixOwnerMinistry}`,
      {},
      { params }
    );
  }

  public registerLocation(CargoId: string, params) {
    return this._http.post(
      `${environment.urlServerTeclogi}${this._endpointResources.registerLocation}${CargoId}`,
      params
    );
  }

  public getTracking(CargoId: string) {
    return this._http.get(environment.urlServerTeclogi + this._endpointResources.urlGetTracking + CargoId)
  }

}
