import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { DateFormatPipe } from 'src/app/core/pipe/dateFormat.pipe';
import { Utils } from 'src/app/core/resources/utils';
import { CargoTrackingService } from '../cargo-tracking/cargo-tracking.service';
import { User } from 'src/app/core/interfaces/user';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { ServiceType } from 'src/app/core/interfaces/serviceType';
import { Cargo } from 'src/app/core/interfaces/cargo';
@Component({
  selector: 'app-confirm-end-cargo',
  templateUrl: './confirm-end-cargo.component.html',
  styleUrls: ['./confirm-end-cargo.component.scss'],
  providers: [DateFormatPipe]
})
export class ConfirmEndCargoComponent implements OnInit {
  userName: string = '';
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogParams: {
      cargo?: Cargo,
      driver?: User,
      user?: string,
      vehicle?: Vehicle,
    },
    public dialogRef: MatDialogRef<ConfirmEndCargoComponent>,
    public utils: Utils,
    public cargoTrackingService: CargoTrackingService
  ) { }

  ngOnInit() {
    if (/\(.*\)/.test(this.dialogParams.user))
      this.userName = this.dialogParams.user.replace(/\(.*\)/g, '').trim();
    else
      this.userName = this.dialogParams.user;
  }

  get isEscortedService(): boolean {
    if (!this.dialogParams || !this.dialogParams.cargo) return false;
    const serviceType: ServiceType = this.utils.getNestedValue(this.dialogParams.cargo, 'cargoModel.serviceType');
    return serviceType && serviceType.id === 'escortServices';
  }

}
