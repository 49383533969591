<div class="transport-request-listing-tabs col-md-12 mb-md-0 mb-12">
    <div class="content h-100">

        <mat-tab-group class="tab-group" (selectedIndexChange)="onSelectTab($event)">
            <mat-tab *ngFor="let list of listTransportRequests" label={{list.title}}>
                <app-list-transport-requests [paramsCargoList]="list.paramsCargoList"
                    [typeList]="list.typeList"></app-list-transport-requests>
            </mat-tab>
        </mat-tab-group>

    </div>
</div>