<span 
  *ngIf="fromCreation && typeAction === 'editDriver'" 
  permission-display 
  [module]="permission.administration.module"
  [functionality]="permission.administration.editReferencesVehicle" 
  class="cursor-pointer text-bold p-2 edit-section delete-references" 
  (click)="onSubmit('delete')">
  <i class="fas fa-trash"></i> Eliminar referencias
</span>
<aside class="vehicle__contacts">
  <!-- EmergencyContact -->
  <ng-container>
    <app-contact-person-vehicle [form]="emergencyContactControls" [title]="'Contacto de emergencia'" [typeAction]="typeAction">
    </app-contact-person-vehicle>
  </ng-container>
  <hr>
  <!-- ReferenceLaboral -->
  <ng-container>
    <span class="separator-horizontal"></span>
    <app-contact-person-vehicle [form]="referenceLaboralControls" [title]="'Referencia laboral'" [typeAction]="typeAction">
    </app-contact-person-vehicle>
  </ng-container>
  <hr>
  <!-- ReferencePersonal -->
  <ng-container>
    <span class="separator-horizontal"></span>
    <app-contact-person-vehicle [form]="referencePersonalControls" [title]="'Referencia personal'" [typeAction]="typeAction">
    </app-contact-person-vehicle>
  </ng-container>


  <ng-container *ngIf="form.get('emergencyContact')?.get('fingerPrintValidation')?.value; else driverForm">
    <ng-container *ngTemplateOutlet="lastValidation; context: { activeForm: form.getRawValue() }"></ng-container>
  </ng-container>
  <ng-template #driverForm>
    <ng-container *ngTemplateOutlet="lastValidation; context: { activeForm: driver }"></ng-container>
  </ng-template>
  <ng-template #lastValidation let-activeForm="activeForm">
    <div class="alert alert-info d-flex justify-content-between flex-sm-row flex-wrap" role="alert" *ngIf="activeForm?.emergencyContact?.fingerPrintValidation?.date">
      <div class="mr-3">
        <span class="text-bold">Última validación: </span>
        {{activeForm?.emergencyContact?.fingerPrintValidation?.date |dateFormat}}
      </div>
      <div>
        <span class="text-bold">Responsable: </span>
        {{activeForm?.emergencyContact?.fingerPrintValidation?.userName}}
        ({{activeForm?.emergencyContact?.fingerPrintValidation?.userId}})
      </div>
    </div>
  </ng-template>



  <div permission-display [module]="permission.administration.module"
  [functionality]="permission.administration.editReferencesVehicle" class="col-md-12" *ngIf="!fromCreation && !typeAction">
  <div class="button-container">
    <button mat-raised-button color="primary" type="button" class="primary-secondary button-width-auto"
      (click)="onSubmit('delete')">
      Eliminar referencias
    </button>
    <button mat-raised-button color="primary" type="button" class="button-width-auto bg-color-primary"
      (click)="onSubmit('edit')">
      Guardar
    </button>
  </div>
</div>
</aside>