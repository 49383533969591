<div class="position-relative">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <div class="row card-edit" *ngIf="data.title === 'Atar número de factura manualmente' else error">
    <div class="col-md-12">
      <label class="title-component">
        {{data.title}}
      </label>
    </div>
    <div class="col-md-12">
      <mat-form-field>
        <mat-label>
          Ingrese el número de factura asociado a los servicios seleccionados<span class="asterisk">*</span>
        </mat-label>
        <input type="text" matInput [formControl]="numCargo">
        <mat-error *ngIf="numCargo.errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
        <mat-error *ngIf="numCargo.errors?.pattern">
          {{ utils.giveMessageError('INCORRECT_FORMAT', 'número de factura') }}
        </mat-error>
      </mat-form-field>
    </div>
    <mat-card-actions class="text-center">
      <button mat-raised-button class="button-width-auto" color="primary" (click)="closeDialog()">
        Guardar
      </button>
    </mat-card-actions>
  </div>

  <ng-template #error>

    <div class="row card-edit" *ngIf="data.title === 'MENSAJE DE ERROR' else all">
      <div class="col-md-12">
        <label class="title-component">
          No se puede generar la factura ya que se encuentran los siguientes conflictos
        </label>
      </div>
      <div class="col-md-12 d-flex justify-content-center flex-column">
        <ul>
          <h4>Servicios facturados sin número de factura:</h4>
          <li *ngFor="let id of idsWithoutBill">{{id}}</li>
        </ul>
        <ul>
          <h4>Servicios sin facturar:</h4>
          <li *ngFor="let id of idsWithBill">{{id}}</li>
        </ul>
      </div>
      <mat-card-actions class="text-center">
        <button mat-raised-button class="button-width-auto" color="primary" (click)="dialogRef.close()">
          Cerrar
        </button>
      </mat-card-actions>
    </div>



    <ng-template #all>
      <div class="row card-edit">
        <div class="col-md-12">
          <label class="title-component">
            {{data.title}}
          </label>
        </div>
        <div class="col-md-12">
          <mat-form-field>
            <mat-label>
              Ingresa el nuevo número de ingreso<span class="asterisk">*</span>
            </mat-label>
            <input type="text" matInput [formControl]="numCargo">
            <mat-error *ngIf="numCargo.errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-error *ngIf="numCargo.errors?.pattern">
              {{ utils.giveMessageError('INCORRECT_FORMAT', 'número de ingreso') }}
            </mat-error>
          </mat-form-field>
        </div>
        <mat-card-actions class="text-center">
          <button mat-raised-button class="button-width-auto" color="primary" (click)="closeDialog()">
            Guardar
          </button>
        </mat-card-actions>
      </div>
    </ng-template>

  </ng-template>
</div>