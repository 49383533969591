import { Component, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateEnum } from 'src/app/core/enums/date.enum';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { DateManager } from 'src/app/core/managers/date.manager';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { DateFormatPipe } from 'src/app/core/pipe/dateFormat.pipe';
import { Utils } from 'src/app/core/resources/utils';
import { CargoService } from 'src/app/core/services/cargo.service';
import { GoogleService } from 'src/app/core/services/google.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';

const after = (date: Date): ValidatorFn => {
  return (control: AbstractControl) => {
    return control && control.value && date && DateManager.isSameOrBefore(control.value, date, 'seconds') ? { "minDate": date } : {};
  }
}

@Component({

  selector: 'app-end-cargo-check',
  templateUrl: './end-cargo-check.component.html',
  styleUrls: ['./end-cargo-check.component.scss'],
  providers: [DateFormatPipe]
})
export class EndCargoCheckComponent {

  //public current = new Date();
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<EndCargoCheckComponent>,
    @Inject(MAT_DIALOG_DATA) public cargo: Cargo,
    private spinner: NgxSpinnerService,
    public snackBarService: SnackBarService,
    private _cargoService: CargoService,
    public dialog: MatDialog,
    public utils: Utils,
  ) {
    this.initializeForm();
  }

  private initializeForm(): void {
    this.form = new FormGroup({
      //origin: new FormGroup({}),
      destinations: new FormGroup({})
    });
    // Origin
    /*const origin_form = (this.form.get('origin') as FormGroup);
    const origin = this.cargo.cargoFeature.uploadDownload.origin.addresses;
    for (const i in origin) {
      const address = origin[i];
      const start = address.durationTime && address.durationTime.startDate ?
        moment(address.durationTime.startDate) : null;
      const end = address.durationTime && address.durationTime.endDate ?
        moment(address.durationTime.endDate) : null;

      origin_form.addControl(`startDate_${i}`, new FormControl(start));
      origin_form.addControl(`endDate_${i}`, new FormControl(end));
    }*/

    // Destination
    const destinations_form = (this.form.get('destinations') as FormGroup);
    const destinations = this.cargo.cargoFeature.uploadDownload.destination;
    for (const i in destinations) {
      const destination = destinations[i];
      const destination_form = new FormGroup({});

      for (const j in destination.addresses) {
        const address = destination.addresses[j];

        const downloadDate = DateManager.formatDate(destination.downloadDate, DateEnum.YYYY_MM_DD).substring(0, 10) + ' ' + address.time;
        const hasStartDate = address.durationTime && address.durationTime.startDate;
        const dateStart = hasStartDate ? address.durationTime.startDate : downloadDate;
        const minDate = hasStartDate && DateManager.isSameOrBefore(new Date(dateStart), new Date(downloadDate)) ? dateStart : downloadDate;
        const hasEndDate = address.durationTime && address.durationTime.startDate && DateManager.isSameOrBefore(new Date(dateStart), new Date(address.durationTime.endDate));
        const dateEnd = hasEndDate ? address.durationTime.endDate : dateStart;

        destination_form.addControl(`minDate_${j}`, new FormControl({ value: DateManager.stringToDate(minDate), disabled: true }));
        destination_form.addControl(`startDate_${j}`, new FormControl({ value: DateManager.stringToDate(dateStart), disabled: true }));
        destination_form.addControl(`endDate_${j}`, new FormControl({ value: DateManager.stringToDate(dateEnd), disabled: true }));
        destination_form.addControl(`realDate_${j}`, new FormControl(
          { value: DateManager.add(minDate, 15, 'minutes'), disabled: !!address.approvalInitialConsignment },
          [after(DateManager.add(minDate, 15, 'minutes')), Validators.required]
        ));
      }

      destinations_form.addControl(`destination_${i}`, destination_form);
    }
  }

  getDownloadDate(indexCity, indexDestination): string {
    const destinations = this.cargo.cargoFeature.uploadDownload.destination;
    return DateManager.formatDate(destinations[indexCity].downloadDate, DateEnum.YYYY_MM_DD).substring(0, 10) + ' ' + destinations[indexCity].addresses[indexDestination].time;
  }

  public onSubmit(_event: Event): void {
    _event.preventDefault();
    _event.stopPropagation();
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      if (!this.findErrorOnDestinations()) this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
      return;
    }

    /*const origin = this.cargo.cargoFeature.uploadDownload.origin.addresses;
    const origin_form = (this.form.get('origin') as FormGroup);
    for (const i in origin) {
      const start = origin_form.get(`startDate_${i}`).value.toISOString();
      const end = origin_form.get(`endDate_${i}`).value.toISOString();
      this.cargo
        .cargoFeature
        .uploadDownload
        .origin
        .addresses[i]
        .durationTime = {
        startDate: DateManager.formatDate(start),
        endDate: DateManager.formatDate(end),
        duration: DateManager.dateDiff(end, undefined, start, undefined, "seconds")
      };
    }*/

    const destinations = this.cargo.cargoFeature.uploadDownload.destination;
    const destinations_form = (this.form.get('destinations') as FormGroup);
    for (const i in destinations) {
      const destination = destinations[i];
      for (const j in destination.addresses) {
        const end = DateManager.dateToString(destinations_form.get(`destination_${i}.realDate_${j}`).value);
        this.cargo.cargoFeature.uploadDownload.destination[i].addresses[j].durationTime.endDate = end
      }
    }

    this.spinner.show();
    this._cargoService
      .initialFulfillConsignment(this.cargo)
      .subscribe((success: Array<{ error?: string }>) => {
        this.spinner.hide();
        const errors = success.filter((v) => v.error);

        if (errors.length > 0) {
          this.showResponseInitialFulfill('Error', errors[0].error);
        } else {
          this.showResponseInitialFulfill('Success');
        }
      }, (error) => {
        this.spinner.hide();
        this.showResponseInitialFulfill('Error', error.error);
      });
  }

  findErrorOnDestinations(): boolean {
    let haveErrors = false;
    const destinations = this.cargo.cargoFeature.uploadDownload.destination;
    const destinations_form = (this.form.get('destinations') as FormGroup);
    for (const i in destinations) {
      const destination = destinations[i];
      for (const j in destination.addresses) {
        if (!haveErrors && destinations_form.get(`destination_${i}.realDate_${j}`) && destinations_form.get(`destination_${i}.realDate_${j}`).errors) {
          haveErrors = true;
          if (destinations_form.get(`destination_${i}.realDate_${j}`).errors.minDate)
            this.snackBarService.openSnackBar(`La fecha de finalización del descargue ${parseInt(i) + 1} dirección ${parseInt(j) + 1} debe ser superior a ${DateManager.dateToString(destinations_form.get(`destination_${i}.realDate_${j}`).errors.minDate, 'YYYY-MM-DD HH:mm a')}`, undefined, 'alert');
          else this.utils.errorMessagesCustomized(destinations_form.get(`destination_${i}.realDate_${j}`), `fecha de finalización del descargue ${parseInt(i) + 1} dirección ${parseInt(j) + 1}`);
          break;
        }
      }
      if (haveErrors) break;
    }
    return haveErrors;
  }

  private showResponseInitialFulfill(type: 'Success' | 'Error', message?: string) {
    const dialogConfig = new MatDialogConfig();
    let title = '';
    if (type == 'Success') {
      title = `Cumplido inicial generado correctamente para el servicio ${this.cargo.consecutive}`;
    } else {
      title = `Ocurrió un error al realizar el cumplido inicial del servicio ${this.cargo.consecutive}`;
    }
    dialogConfig.data = {
      title,
      icon: type === 'Success',
      iconError: type === 'Error',
      hideBtnCancel: true,
      description: type === 'Error' ? message : null
    };
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    const _modal = this.dialog.open(DialogComponent, dialogConfig);
    _modal.afterClosed().subscribe({
      next: () => {
        if (type === 'Success') {
          this.dialogRef.close(this.cargo);
        }
      }
    });
  }

}
