<div class="summary-balance-container">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <div class="d-flex flex-column align-items-center justify-content-center">
    <i class="fas fa-receipt mb-3"></i>
    <h2 class="text-bold">Detalle del saldo</h2>
  </div>
  <div class="balance-container d-flex flex-column align-items-center justify-content-center"
    *ngIf="dialogParams && dialogParams.data">
    <p><b>Valor del flete: </b><span class="text-bold">{{ dialogParams.data.initialCost ? (dialogParams.data.initialCost
        |
        currency:'COP':'code') : 0 }}</span></p>
    <p><b>Descuento por reteICA: </b><span class="text__color color--error text-bold">(-) {{ dialogParams.data.reteica ?
        (dialogParams.data.reteica |
        currency:'COP':'code') : 0 }}</span></p>
    <p><b>Descuento por ReteFuente: </b><span class="text__color color--error text-bold">(-) {{
        dialogParams.data.reteFuente ? (dialogParams.data.reteFuente |
        currency:'COP':'code') : 0 }}</span></p>
    <p><b>Bonos asociados al servicio: </b> <span class="text__color color--success text-bold">(+) {{
        dialogParams.data.bonus ? (dialogParams.data.bonus |
        currency:'COP':'code') : 0 }}</span></p>
    <p><b>Descuentos asociados al servicio: </b><span class="text__color color--error text-bold">(-) {{
        dialogParams.data.discounts ? (dialogParams.data.discounts |
        currency:'COP':'code') : 0 }}</span></p>
    <p><b>Descuento por Servicios Integrales: </b><span class="text__color color--error text-bold">(-) {{
        dialogParams.data.integral ? (dialogParams.data.integral |
        currency:'COP':'code') : 0 }}</span></p>
    <p *ngIf="dialogParams?.data?.travelExpenses < 0">
      <b>Viáticos pendientes: </b>
      <span class="text__color color--success text-bold">(+) {{ dialogParams.data.travelExpenses ?
        (dialogParams.data.travelExpenses | currency:'COP':'code') : 0 }}
      </span>
    </p>
    <p *ngIf="dialogParams?.data?.travelExpenses > 0">
      <b>Sobregiro de viáticos o no soportados: </b>
      <span class="text__color color--error text-bold">(-) {{ dialogParams?.data?.travelExpenses ?
        ((-1 * dialogParams?.data?.travelExpenses) | currency:'COP':'code') : 0 }}
      </span>
    </p>
    <div *ngIf="dialogParams.data.payments.length > 0"
      class="d-flex flex-column align-items-center justify-content-center">
      <h5 class="text-bold"><i>Pagos realizados</i></h5>
      <div *ngFor="let payment of dialogParams.data.payments">
        <p><b>{{ payment.label }}: </b><span class="text__color color--error text-bold">(-) {{ payment.amount ?
            (payment.amount |
            currency:'COP':'code') : 0 }}</span></p>
      </div>
    </div>
    <div *ngIf="!dialogParams.data.balanceState">
      <p><b>Saldo: </b><span class="text-bold">{{ dialogParams.data.balance ? (dialogParams.data.balance |
          currency:'COP':'code') : 0 }}</span></p>
    </div>
    <mat-card-actions class="text-center">
      <button mat-raised-button class="button-width-auto" color="primary" (click)="dialogRef.close()">
        Cerrar
      </button>
    </mat-card-actions>
  </div>
</div>