import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AssignVehicleCargoService } from './assign-vehicle-cargo.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { AngularFireStorage } from '@angular/fire/storage';
import { Utils } from 'src/app/core/resources/utils';
import * as _ from 'lodash';
import { CargoPayment } from 'src/app/core/interfaces/cargoPayment';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { Observable, Subscription, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { CargoDetailService } from '../cargo-detail/cargo-detail.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { FormVehicleComponent } from '../../administration/vehicles/form-vehicle/form-vehicle.component';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { DialogSurveyComponent } from 'src/app/shared/dialog-survey/dialog-survey.component';
import { CargoItemService } from '../cargo-item/cargo-item.service';
import { Dialog } from 'src/app/core/resources/dialog';
import { CargoManager } from 'src/app/core/managers/cargo.manager';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';
import { TripsToCargo } from 'src/app/core/enums/tripsPerVehicle.enum';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { User } from 'src/app/core/interfaces/user';
import { AccountService } from '../../account/account.service';
import { VehicleMessages } from 'src/app/core/messages/vehicle-messages.enum';
import { Fmt } from 'src/app/core/messages/fmt';
import { VehiclesService } from '../../administration/vehicles/list-vehicles.service';
import { Router } from '@angular/router';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { TRIP_TYPES } from 'src/app/core/enums/tripTypes.enum';
import { AdminUsersService } from '../../administration/admin-users/admin-users.service';
import { environment } from 'src/environments/environment';
import { Roles } from 'src/app/core/enums/roles.enum';
import { UserManager } from 'src/app/core/managers/user.manager';
import { Company } from 'src/app/core/interfaces/company';
import { AssignVehicle } from 'src/app/core/interfaces/assignVehicle';
import { VehicleCreateResponse } from 'src/app/core/interfaces/vehicleCreateResponse';
import { CargoService } from 'src/app/core/services/cargo.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { TurnMatch } from 'src/app/core/interfaces/turnMatch';
import { DateManager } from 'src/app/core/managers/date.manager';
import { ServiceType } from 'src/app/core/interfaces/serviceType';

@Component({
  selector: 'app-assign-vehicle-cargo',
  templateUrl: './assign-vehicle-cargo.component.html',
  styleUrls: ['./assign-vehicle-cargo.component.scss'],
  providers: [AuthService, Global, AssignVehicleCargoService, CargoManager, UserManager]
})

export class AssignVehicleCargoComponent implements OnInit {

  assignVehicleForm: FormGroup;
  @Input() cargo: Cargo;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onAssignVehicle: EventEmitter<any> = new EventEmitter();
  listVehicles: Observable<Vehicle[]>;
  filteredOptions: Observable<string[]>;
  vehicleSelected: Vehicle;
  stateDriverSelected = false;
  stateAdminSelected = false;
  stateOwnerSelected = false;
  haveAdmin: boolean = false;
  haveOwner: boolean = false;
  isNewDriver: boolean;
  userActive = this.authService.getUserSession().information;
  permission = Permission;
  mask: string = '';
  vehicleInfo: Vehicle;
  type: string = '';
  assignVehicleCargoComponent: AssignVehicleCargoComponent;
  licensePLate: string;
  vehicle: Vehicle;
  invalidVehicle: boolean = true;
  holderCompany: string;
  filterMatchDate = new FormControl(null);
  dateOptions = {
    lastWeek: 'dddd [pasado]',
    sameElse: 'L'
  };
  matchDateOptions = [
    {
      alias: 'Hoy',
      value: new Date()
    },
    {
      alias: 'Ayer',
      value: DateManager.substract(new Date(), 1, 'days')
    },
    {
      alias: DateManager.getCalendar(DateManager.substract(new Date(), 2, 'days'), null, this.dateOptions),
      value: DateManager.substract(new Date(), 2, 'days')
    },
  ];
  filterNumberOfTrips = new FormControl(null);
  numberOfTripsOptions = [
    { alias: 'Ningún viaje', value: 'zero' },
    { alias: '1 a 5 viajes', value: 'to five' },
    { alias: 'Más de 5 viajes', value: 'from six' },
  ];
  allowCreatingWithoutTruora: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AssignVehicleCargoComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogParams: {
      cargo?: Cargo,
      filteredCargosTurns?: {
        cargoConsecutive?: number,
        matches?: TurnMatch[]
      },
      title?: string,
      type?: string,
      licensePlate?: string,
    },
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private assignVehicleCargoService: AssignVehicleCargoService,
    private userManager: UserManager,
    private freightForwarderDetailService: CargoDetailService,
    public utils: Utils,
    public matDialog: MatDialog,
    public dialog: Dialog,
    private cargoManager: CargoManager,
    private permissionRole: PermissionRole,
    private accountService: AccountService,
    private vehiclesService: VehiclesService,
    private router: Router,
    private adminUsersService: AdminUsersService,
    private cargoService: CargoService,
    public service: CargoDetailService,
    private companyService: CompanyService
  ) {
    if (this.dialogParams) {
      if (this.dialogParams.cargo) this.cargo = this.dialogParams.cargo;
      if (this.dialogParams.type) this.type = this.dialogParams.type;
    }
  }
  /**
  * This method evaluates if type is assign or not.
  */
  ngOnInit() {
    this.infoTruora();
    if (this.type === 'assign' || this.cargo)
      this.createAssignVehicleForm();
    this.holderCompany = this.authService.getUserSession() && this.authService.getUserSession().clientCompanyId ? this.authService.getUserSession().clientCompanyId : null;
  }

  private infoTruora() {
    const holderCompany = this.companyService.holderCompany;
    if (holderCompany && holderCompany.companyId)
      this.allowCreatingWithoutTruora = !!(holderCompany && holderCompany.allowCreatingWithoutTruora);
  }
  /**
  * This method allows to create the required form to assign the vehicle to a load.
  */
  createAssignVehicleForm() {

    this.assignVehicleForm = new FormGroup({
      idCargo: new FormControl(
        this.cargo.id,
        Validators.compose(
          [Validators.required]
        )
      ),
      idUser: new FormControl(
        null,
        Validators.compose(
          [Validators.required]
        )
      ),
      idVehicle: new FormControl(
        '',
        Validators.compose(
          [Validators.required]
        )
      )
    });

    this.listVehicles = this.assignVehicleForm.controls.idVehicle.valueChanges
      .pipe(
        startWith(''),
        // debounceTime(400),
        distinctUntilChanged(),
        switchMap(val => {
          try {
            if (val.trim().length > 2) {
              return this.filter(val || '');
            } else {
              this.vehicleSelected = null;
              return [];
            }
          } catch (e) {
            return [];
          }
        })
      );

    if (this.dialogParams && this.dialogParams.licensePlate) {
      this.getDetailLicensePlate();
    }
  }

  private getDetailLicensePlate(): void {
    this.spinner.show();
    this.assignVehicleCargoService.getVehicleById(this.dialogParams.licensePlate, this.holderCompany).subscribe(
      (response) => {
        this.spinner.hide();
        if (response.length) {
          const vehicle: Vehicle = response[0];

          this.vehicleSelected = {
            id: vehicle.id,
            owner: {
              document: vehicle.owner.document,
              name: vehicle.owner.name,
              documentTypeId: vehicle.owner.documentTypeId,
            },
            driver: {
              document: vehicle.owner.document,
              name: vehicle.owner.name
            }
          };

          this.vehicleSelected.vehicleType = {
            name: vehicle.vehicleType.name
          };

          this.vehicleSelected.state = {
            active: vehicle.state.active
          };

          this.vehicleSelected.stateCompany = {
            active: vehicle.stateCompany.active,
            description: vehicle.stateCompany.description
          }
          if (vehicle.administrator && vehicle.administrator.document && this.authService.getCompany().companyId !== environment.rootNit) this.getStateUser(vehicle.administrator.document, 'admin');
          else if (vehicle.owner && vehicle.owner.document && this.authService.getCompany().companyId !== environment.rootNit) this.getStateUser(vehicle.owner.document, 'owner');

          if (vehicle.driver.document) this.getStateUser(vehicle.driver.document, 'driver');
        }
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      },
    );
  }
  /**
  * This method consumes a service that search matches of a vehicle in the BD with the license plata typed.
  * @param {string} value (string) is the license plate write by the user.
  * @returns {Observable<Vehicle[]>} (Observable<Vehicle[]>) returns a vehicle object if exists.
  */
  filter(value: string): Observable<Vehicle[]> {
    const filterValue = value.toUpperCase();
    if (this.holderCompany !== null) {
      return this.assignVehicleCargoService.getVehicleById(filterValue, this.holderCompany)
        .pipe(
          map(response => {
            if (response && response.length) {
              return response.filter(option => {
                return option.id.toLowerCase().indexOf(filterValue.toLowerCase()) === 0;
              })
            } else {
              return [];
            }
          }
          )
        );
    } else return of([]);
  }
  /**
  * This method set some information according to the selection of the user.
  * @param {any} $event (any) is the information of the selected vehicle.
  */
  optionSelected($event) {
    this.haveAdmin = false;
    this.haveOwner = false;
    if ($event.option._element.nativeElement.idowner) {
      this.isNewDriver = false;
      this.vehicleSelected = {
        id: $event.option.value,
        owner: {
          document: $event.option._element.nativeElement.idowner,
          name: $event.option._element.nativeElement.nameowner,
          documentTypeId: $event.option._element.nativeElement.typedocumentowner,
        },
        driver: {
          document: $event.option._element.nativeElement.iddriver,
          name: $event.option._element.nativeElement.namedriver
        }
      };

      this.vehicleSelected.vehicleType = {
        name: $event.option._element.nativeElement.vehicletype
      };

      this.vehicleSelected.state = {
        active: $event.option._element.nativeElement.vehiclestate
      };

      this.vehicleSelected.stateCompany = {
        active: $event.option._element.nativeElement.vehiclestatecompany,
        description: $event.option._element.nativeElement.vehiclestatecompanydescription
      }
      if ($event.option._element.nativeElement.idadmin && this.authService.getCompany().companyId !== environment.rootNit) this.getStateUser($event.option._element.nativeElement.idadmin, 'admin');
      else if ($event.option._element.nativeElement.idowner && this.authService.getCompany().companyId !== environment.rootNit) this.getStateUser($event.option._element.nativeElement.iddriver, 'owner');

      if ($event.option._element.nativeElement.iddriver) this.getStateUser($event.option._element.nativeElement.iddriver, 'driver');

    } else {
      this.isNewDriver = true;
      this.vehicleSelected = null;
      this.stateDriverSelected = false;
      this.stateAdminSelected = false;
      this.stateOwnerSelected = false;
      this.openDialogCreateVehicle();
    }
  }
  /**
  * This method open a dialog window to fill the fields necessaries to create a vehicle.
  */
  openDialogCreateVehicle() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      company: {
        exclusive: this.cargo.exclusive,
        nit: this.cargo.idCompany
      },
      cargoId: this.cargo.id
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(FormVehicleComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.state) {
        this.onSubmit(result.value);
      } else if (result && !result.state) {
        this.snackBarService.openSnackBar('Ocurrió un error al asignar el vehículo', undefined, 'error');
      }
    });
  }

  get amountsCargoEnum() {
    return AmountsCargoEnum;
  }

  get PermissionAssignVehicle() {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.assignVehicleFullAccess);
  }

  get TripsToCargo() {
    return TripsToCargo;
  }

  getMatchIndex(match: TurnMatch): number {
    if (!this.dialogParams || !this.dialogParams.filteredCargosTurns || !this.dialogParams.filteredCargosTurns.matches)
      return 0;
    const index = this.dialogParams.filteredCargosTurns.matches.findIndex(mat => mat.id === match.id);
    if (index !== -1) return index + 1;
    return 0;
  }

  get cargoMatchesFiltered(): TurnMatch[] {
    if (!this.dialogParams || !this.dialogParams.filteredCargosTurns || !this.dialogParams.filteredCargosTurns.matches)
      return [];
    let matches = this.dialogParams.filteredCargosTurns.matches;
    if (this.filterMatchDate.value) {
      matches = matches.filter(match => DateManager.isSame(this.filterMatchDate.value, DateManager.stringToDate(match.date)));
    }
    if (this.filterNumberOfTrips.value) {
      const trips = this.filterNumberOfTrips.value;
      matches = matches.filter(match => {
        return match && this.utils.isDefined(match.numberOfTrips) &&
          ((trips === 'zero' && match.numberOfTrips === 0) ||
            (trips === 'to five' && match.numberOfTrips && match.numberOfTrips <= 5) ||
            (trips === 'from six' && match.numberOfTrips && match.numberOfTrips > 5));
      });
    }
    return matches;
  }
  /**
  * This method evaluates the conditions of the vehicle necessaries to carry the load.
  * @param {Vehicle} vehicle (Vehicle - optional) is the infomation of the vehicle.
  * @param {string} type (string - optional) is the parameter to determined if is manual assign or autoassign.
  * @param {Cargo} cargo (Cargo - optional) is the information of the load.
  * @returns {boolean} (boolean) returns if the vehicle could carry the load or not.
  */
  secureTravelProtocol(vehicle?: Vehicle, type?: string, cargo?: Cargo): boolean {
    if (vehicle && type && cargo) {
      this.cargo = cargo;
    } else if (vehicle && type) {
      vehicle = vehicle[0];
    }
    if (this.cargo && this.cargo.cargoFeature && this.cargo.cargoFeature.cargoMeasure && this.cargo.cargoFeature.cargoMeasure.amount && !this.PermissionAssignVehicle) {
      if (this.cargo.cargoFeature.cargoMeasure.amount <= AmountsCargoEnum.LOW_END) {
        return false;
      } else if (this.cargo.cargoFeature.cargoMeasure.amount <= AmountsCargoEnum.MEDIUM_END) {
        return !(vehicle && vehicle.numberOfTrips && vehicle.numberOfTrips >= TripsToCargo.TRIPS_TO_MEDIUM_END);
      } else {
        return !(vehicle && vehicle.numberOfTrips && vehicle.numberOfTrips >= TripsToCargo.TRIPS_TO_HIGH_END);
      }
    }
    else {
      return false;
    }
  }
  /**
  * This method verify if the vehicle has driver.
  * @param {Vehicle} vehicle (Vehicle) is the information of the vehicle.
  * @returns {boolean} (boolean) returns if has or not driver.
  */
  hasDriver(vehicle: Vehicle): boolean {
    return !!(vehicle && vehicle.driver && vehicle.driver.document && vehicle.driver.name);
  }
  /**
  * This method evaluates the conditions to assign a vehicle.
  * @param {AssignVehicle} params (AssignVehicle) is the information neccesary to bring the vehicle and driver information.
  * @param {string} type (string - optional) if is turn o common assign vehicle.
  */
  public async assignVehicle(params: AssignVehicle, type?: string) {
    const vehicle: Vehicle = await this.vehiclesService.getVehicle(params.licensePlate).toPromise();
    if (!vehicle || !vehicle.driver || !vehicle.driver.document) {
      this.spinner.hide();
      this.snackBarService.openSnackBar('Ocurrió un error al traer el detalle del vehículo', undefined, 'error');
      return;
    }

    const driver = await this.adminUsersService.getUsersDetailByDocument(vehicle.driver.document).toPromise();
    this.spinner.hide();
    if (!driver || !driver.information) {
      this.snackBarService.openSnackBar('Ocurrió un error al traer el detalle del conductor', undefined, 'error');
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { title: "Error en la Información obligatoria del Vehículo", hideBtnConfirm: true };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    let missingMessage = '';

    // Documentación obligatoria faltante
    const missingDocuments = await this.vehiclesService.getMissingDocuments(vehicle, 'missing');
    // Documentación obligatoria pendiente por validar
    const unacceptedDocuments = await this.vehiclesService.getMissingDocuments(vehicle, 'unaccepted');
    // Referencias pendientes por validar
    const invalidReferences = this.vehiclesService.invalidReferences(driver);

    if (missingDocuments && missingDocuments.length) {
      missingDocuments.forEach(document => missingMessage += `<li>${document}</li>`);
      dialogConfig.data.descriptionHTML = `<p>${VehicleMessages.VEHICLE_DOCUMENTATION_INCOMPLETE}</p><ol>${missingMessage}</ol>`;
      this.matDialog.open(DialogComponent, dialogConfig);
    }
    else if (unacceptedDocuments && unacceptedDocuments.length) {
      unacceptedDocuments.forEach(document => missingMessage += `<li>${document}</li>`);
      dialogConfig.data.descriptionHTML = `<p>${VehicleMessages.VEHICLE_DOCUMENTATION_UNACCEPTED}</p><ol>${missingMessage}</ol>`;
      this.matDialog.open(DialogComponent, dialogConfig);
    }
    else if (invalidReferences && invalidReferences.length) {
      invalidReferences.forEach(document => missingMessage += `<li>${document}</li>`);
      dialogConfig.data.descriptionHTML = `<p>${VehicleMessages.VEHICLE_REFERENCES_UNACCEPTED}</p><ol>${missingMessage}</ol>`;
      this.matDialog.open(DialogComponent, dialogConfig);
    }
    else this.assignVehicleToCargo(params, type);
  }

  /**
  * This method consumes the service to assign a vehicle to a load.
  * @param {AssignVehicle} params (AssignVehicle) is the information neccesary to bring the vehicle and driver information.
  * @param {string} type (string - optional) if is turn o common assign vehicle.
  */
  private assignVehicleToCargo(params: AssignVehicle, type?: string) {
    this.spinner.show();
    this.freightForwarderDetailService.acceptCargo(params).subscribe(
      (data) => {
        // if (data) {
        //   this.dialogRef.close({ state: true })
        //   this.spinner.hide();
        //   this.snackBarService.openSnackBar('Se ha asignado el vehículo ' + params.licensePlate + ' correctamente');
        //   // this.onAssignVehicle.emit();
        // } else {
        //   this.spinner.hide();
        //   this.snackBarService.openSnackBar('Ocurrió un error al asignar el vehículo ' + params.licensePlate, undefined, 'error');
        // }
        this.cleanErrorFromExcel();
        this.belowSicetac(this.cargo, type, params);
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Ocurrió un error al asignar el vehículo ' + params.licensePlate, undefined, 'error');
      }
    );
  }
  /**
  * This method close the assign vehicle modal.
  */
  closeModal() {
    this.assignVehicleForm.reset();
    this.dialogRef.close({ state: false })
  }
  /**
  * This method set the license plate pattern to assign a vehicle.
  */
  getMask() {
    if (this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.specialLicensePlate) && this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.assignLicensePlate
    )) {
      return 'WWWWWWW';
    } else if (this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.specialLicensePlate)) {
      return 'CDCDDD';
    } else {
      return 'WWWWWWW';
    }
  }
  /**
  * This method brings the information of the user.
  * @param {string} document (string) is the identification of the user.
  * @param {string} type (string) is the type of user could be: admin, owner or driver.
  * @param {Cargo} cargo (Cargo) is the information of the load.
  */
  getStateUser(document: string, type: string, cargo?: Cargo): void {
    if (document) {
      this.accountService.validateEntity(1, document).subscribe(
        (data: User) => {
          // Is active or is NIT
          const role: Roles = type == 'admin' ? Roles.ADMIN : type == 'owner' ? Roles.OWNER : Roles.DRIVER;
          data = this.userManager.sanitizeUser(data, role);
          this.setStatesUser(type, data.information.documentTypeId == '3' || (data.state && data.state.active));
          if (cargo) {
            this.onAssignVehicle.emit(cargo);
          }
        }, () => {
          this.setStatesUser(type, false);
          if (cargo) {
            this.onAssignVehicle.emit(cargo);
          }
        })
    } else {
      this.setStatesUser(type, false);
      if (cargo) {
        this.onAssignVehicle.emit(cargo);
      }
    }

  }
  /**
  * This method evaluates the type of user and its state.
  * @param {string} type (string) is the type of user could be: admin, owner, driver.
  * @param {boolean} state (boolean) is the state of the user.
  */
  setStatesUser(type: string, state: boolean) {
    switch (type) {
      case 'admin':
        this.haveAdmin = true;
        this.stateAdminSelected = state;
        break;
      case 'owner':
        this.haveOwner = true;
        this.stateOwnerSelected = state;
        break;
      case 'driver':
        this.stateDriverSelected = state;
        break;
    }
  }
  /**
  * This method validates all the conditions necessary to assign a vehicle.
  * @param {VehicleCreateResponse} vehicleNew (VehicleCreateResponse - optional) is the information of the vehicle if is a new vehicle.
  * @param {Cargo} cargo (Cargo - optional) is the information of the load.
  * @param {string} type (string - optional) if is turn o common assign vehicle.
  */
  onSubmit(vehicleNew?: VehicleCreateResponse, cargo?: Cargo, type?: string) {
    if (cargo) {
      this.cargo = cargo;
    }
    let isRootNit = this.authService.getCompany().companyId === environment.rootNit;
    if (this.vehicleSelected && this.vehicleSelected.stateCompany.active.toString() === 'false' && this.vehicleSelected.stateCompany.description) {
      this.snackBarService.openSnackBar(this.vehicleSelected.stateCompany.description === 'Inactive' ? 'El vehículo se encuentra inactivo' : this.vehicleSelected.stateCompany.description, undefined, 'alert');
      return;
    }
    if (
      this.vehicleSelected &&
      this.vehicleSelected.state &&
      this.vehicleSelected.state.active.toString() === 'true' && (
        (this.stateDriverSelected && (isRootNit || (this.stateOwnerSelected && this.haveOwner) || (this.stateAdminSelected && this.haveAdmin)) &&
          (
            (this.cargo.cargoFeature.vehicleType && this.cargo.cargoFeature.vehicleType.name && this.cargo.cargoFeature.vehicleType.name.trim().toLowerCase() === this.vehicleSelected.vehicleType.name.trim().toLowerCase()) ||
            (this.utils.isEmpty(this.cargo.cargoFeature) || this.utils.isEmpty(this.cargo.cargoFeature.vehicleType) || this.utils.isEmpty(this.cargo.cargoFeature.vehicleType.name))
          )) ||
        this.allowCreatingWithoutTruora
      )
    ) {
      const params: AssignVehicle = {
        cargoId: this.cargo.id,
        applicantId: null,
        licensePlate: null
      };
      if (this.vehicleSelected) {
        params.applicantId = this.vehicleSelected.driver.document;
        params.licensePlate = this.vehicleSelected.id;
      }
      if (this.isNewDriver) {
        params.applicantId = vehicleNew.driver.information.document;
        params.licensePlate = vehicleNew.vehicle.id;
      }
      if (params && params.cargoId && params.cargoId && params.licensePlate) {
        //Inspeccionar vehiculo
        const tripTypesToInspection = [TRIP_TYPES.NATIONAL, TRIP_TYPES.IMPORT, TRIP_TYPES.EXPORT, TRIP_TYPES.INTERNATIONAL];
        if (tripTypesToInspection.includes(this.cargoManager.getTypeCargo(this.cargo)) && !this.isEscortedService) {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = {
            user: this.userActive,
            licensePlate: params.licensePlate,
            id: this.cargo.id
          };
          dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
          dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
          dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
          dialogConfig.autoFocus = false;
          const dialoRef = this.matDialog.open(DialogSurveyComponent, dialogConfig);
          dialoRef.afterClosed().subscribe(result => {
            if (result) {
              this.spinner.show();
              if (type) {
                this.assignVehicle(params, type);
              } else {
                this.assignVehicle(params);
              }

            }
          });
        } else {
          this.spinner.show();
          if (type) {
            this.assignVehicle(params, type);
          } else {
            this.assignVehicle(params);
          }
        }
      } else {
        this.spinner.hide();
        this.snackBarService.openSnackBar('No ha seleccionado un Vehículo', undefined, 'alert');
      }

    } else {
      if (!this.vehicleSelected) {
        this.spinner.hide();
        this.snackBarService.openSnackBar('No ha seleccionado un Vehículo', undefined, 'alert');
        return;
      }
      let title: string;
      if (!this.vehicleSelected) title = VehicleMessages.NOT_VEHICLE_SELECTED;
      else if (!this.vehicleSelected.vehicleType.name) title = VehicleMessages.NO_VEHICLE_TYPE_NAME;
      else if (!(this.cargo.cargoFeature.vehicleType.name.trim().toLowerCase() === this.vehicleSelected.vehicleType.name.trim().toLowerCase())) title = Fmt.string(VehicleMessages.VEHICLE_TYPE_DIFF, this.vehicleSelected.vehicleType.name, this.cargo.cargoFeature.vehicleType.name);
      else if (!(this.vehicleSelected.state && this.vehicleSelected.state.active.toString() === 'true')) title = VehicleMessages.STATE_INACTIVE;
      else if (!this.stateDriverSelected) title = VehicleMessages.DRIVER_STATE_INACTIVE;
      else if (this.haveOwner && !this.stateOwnerSelected) title = VehicleMessages.OWNER_STATE_INACTIVE;
      else if (this.haveAdmin && !this.stateAdminSelected) title = VehicleMessages.ADMIN_STATE_INACTIVE;
      this.snackBarService.openSnackBar(title, undefined, 'alert');
    }
  }

  get isEscortedService(): boolean {
    const serviceType: ServiceType = this.utils.getNestedValue(this.cargo, 'cargoModel.serviceType');
    return serviceType && serviceType.id === 'escortServices';
  }

  /**
  * This method consumes the service that brings the information of the vehicle.
  * @param {string} licensePLate (string) is the license plate of the vehicle on request.
  * @param {Cargo} needResponse (Cargo - optional) is the information of the load.
  * @param {number} numberOfTrips (number - optional) is the number of trips necessary for the trip.
  */
  getVehicleInfo(licensePLate: string, needResponse?: Cargo, numberOfTrips?: number) {
    if ((this.cargo.cargoFeature.cargoMeasure.amount >= AmountsCargoEnum.HIGH_START) && (numberOfTrips < TripsToCargo.TRIPS_TO_HIGH_END) && (!this.PermissionAssignVehicle)) {
      this.snackBarService.openSnackBar(`No es posible asignar este vehículo. El número mínimo de viajes requerido es de ${TripsToCargo.TRIPS_TO_HIGH_END}`, undefined, 'error')
      return;
    } else if ((this.cargo.cargoFeature.cargoMeasure.amount >= AmountsCargoEnum.MEDIUM_START) && (numberOfTrips < TripsToCargo.TRIPS_TO_MEDIUM_END) && (!this.PermissionAssignVehicle)) {
      this.snackBarService.openSnackBar(`No es posible asignar este vehículo. El número mínimo de viajes requerido es de ${TripsToCargo.TRIPS_TO_MEDIUM_END}`, undefined, 'error')
      return;
    }
    const vehicleInfoObserver = {
      next: (data: Vehicle[]) => {
        this.spinner.hide();
        this.vehicleInfo = data[0];
        this.assignTovehicleSelected(this.vehicleInfo, needResponse);
      },
      error: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar("Hubo un error procesando la información vuelva a intentarlo", undefined, 'error');
      },
      complete: () => {
        this.spinner.hide();
        // nada por ahora
      }
    };
    this.spinner.show();
    this.assignVehicleCargoService.getVehicleById(licensePLate, this.holderCompany).subscribe(vehicleInfoObserver)

  }
  /**
  * This method assign the information of the vehicle selected and the people associate.
  * @param {Vehicle} vehicleInfo (Vehicle) is the information of the vehicle selected.
  * @param {Cargo} cargo (Cargo - optional) is the information of the load.
  */
  assignTovehicleSelected(vehicleInfo: Vehicle, cargo?: Cargo) {
    this.vehicleSelected = {
      id: vehicleInfo.id,
      owner: {
        document: vehicleInfo.owner.document,
        name: vehicleInfo.owner.name,
        documentTypeId: vehicleInfo.owner.documentTypeId,
      },
      driver: {
        document: vehicleInfo.driver.document,
        name: vehicleInfo.driver.name
      }
    };

    this.vehicleSelected.vehicleType = {
      name: vehicleInfo.vehicleType.name
    };

    this.vehicleSelected.state = {
      active: vehicleInfo.state.active
    };
    if (vehicleInfo.driver.document) {
      this.getStateUser(vehicleInfo.driver.document, 'driver', cargo);
    }

  }

  get assignTurnVehicles(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.assignTurnVehicles);
  }

  private cleanErrorFromExcel() {
    if (!this.cargo || !this.cargo.errorFieldsExcelCargo ||
      !this.cargo.errorFieldsExcelCargo.licensePlate ||
      this.cargo.state !== 'Request')
      return;

    let data = { id: this.cargo.id, state: this.cargo.state };
    if (this.cargo.errorFieldsExcelCargo) {
      data['errorFieldsExcelCargo'] = this.cargo.errorFieldsExcelCargo;
      data['errorFieldsExcelCargo']['licensePlate'] = null;
    }
    const $updateCargoFeature = this.service.completeUpdateRequest(data, this.cargo).subscribe({
      next: (success) => { },
      error: (error) => console.error(error),
      complete: () => $updateCargoFeature.unsubscribe()
    });
  }

  private belowSicetac(cargo: Cargo, type?: string, params?: AssignVehicle) {
    const formatter = new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: true,
    });
    if (this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.approvedBelowSicetac)) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
      dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
      dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
      dialogConfig.autoFocus = false;
      dialogConfig.disableClose = true;
      this.spinner.show();
      this.service.detailCargoByConsecutive(cargo.consecutive.toString()).subscribe(
        (data: any) => {
          this.spinner.hide();
          cargo = data;
          if (cargo.belowSicetac && !cargo.freightValueApprovedBelowSicetac) {
            const min = formatter.format(cargo.minimumApprovedValueSicetac);
            dialogConfig.data = {
              title: 'Desea aprobar el flete por debajo del SICETAC',
              description: `El flete mínimo es ${min}, por favor realice una bonificación para ajustarse al valor mínimo permitido`,
              btnDetailCargo: true,
              path: `cargo/detail/${this.cargo.consecutive}`,
              closeModal: true,
            };
            const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(result => {
              if (result && result.state) {
                this.cargoService.approvedBelowSiceTac(cargo.id).subscribe(
                  () => {
                    this.snackBarService.openSnackBar("Se aprobó con éxito", undefined, 'success');
                  },
                  (error) => {
                    this.snackBarService.openSnackBar("No se pudo aprobar", undefined, 'error');
                  }
                );
              } else {
                this.snackBarService.openSnackBar("No se pudo aprobar", undefined, 'error');
              }
            })
          }
        }
      );
    } else {
      if (cargo.belowSicetac && !cargo.freightValueApprovedBelowSicetac) {
        const min = formatter.format(cargo.minimumApprovedValueSicetac);
        this.snackBarService.openSnackBar(`El flete mínimo es ${min}, por favor realice una bonificación para ajustarse al valor mínimo permitido`, undefined, 'alert');
      }
    }
    if (type && type === 'turns') {
      this.onAssignVehicle.emit('asignado');
      this.spinner.hide();
      this.snackBarService.openSnackBar('Se ha asignado el vehículo ' + params.licensePlate + ' correctamente');
      this.router.navigate(['/cargo/list/scheduledLoads']);
    }
    else {
      this.dialogRef.close({ state: true })
      this.spinner.hide();
      this.snackBarService.openSnackBar('Se ha asignado el vehículo ' + params.licensePlate + ' correctamente');
    }
  }
}
