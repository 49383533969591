import { Fingerprint } from "./fingerprint";

export type ApprovalState = 'Pending approval' | 'Approved' | 'Rejected';
export enum ApprovalStateEnum {
    PENDING = 'Pending approval',
    APPROVED = 'Approved',
    REJECTED = 'Rejected',
};

export interface TravelConcept {
    id: string,
    name: string
};

export interface ImageWithFingerprints {
    path: string;
    figerprint?: Fingerprint;
}

export interface TravelExpenseDetail {
    id: string;
    description: string;
    value: number;
    state: boolean;
    approval?: ApprovalState;
    imageWithFingerprints: Array<ImageWithFingerprints>;
    documentId: string;
}

export interface TravelExpense {
    paid?: boolean;
    cargoId?: string;
    cargoConsecutive?: number;
    travelExpensesType?: TravelConcept | null;
    totalPaid?: number;

    id?: string;
    totalSpentValue?: number;
    state?: boolean;
    approval?: ApprovalState;
    fingerPrint?: Fingerprint,
    travelExpensesDetail?: Array<TravelExpenseDetail>;
}


export interface TravelExpensesFormState {
    travelExpenses: Array<TravelExpense>;
}

export interface ImageFingerprint {
    path: string;
    fingerprint: Fingerprint;
}

export interface PreviewImagesMap {
    [key: string]: object[];
}

export interface TravelExpensesImages {
    [key: string]: ImageFingerprint[];
}


