import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileStorage } from 'src/app/core/interfaces/fileStorage';
import { UserDocuments } from 'src/app/core/interfaces/userDocuments';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { AdminUsersService } from '../admin-users.service';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { Permission } from 'src/app/core/resources/permission';
import { Roles } from 'src/app/core/enums/roles.enum';
import { FileNamePipe } from 'src/app/core/pipe/fileName.pipe';
import { DatePipe } from '@angular/common';
import { Patterns } from 'src/app/core/resources/patterns';
import { PrevisualizationSettings } from 'src/app/core/interfaces/previsualizationSettings';
import { FileService } from 'src/app/shared/files/file.service';
import { UploadTaskSnapshot } from '@angular/fire/storage/interfaces';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';

@Component({
  selector: 'app-user-documents',
  templateUrl: './user-documents.component.html',
  styleUrls: ['./user-documents.component.scss'],
  providers: [FileNamePipe, DatePipe]
})
export class UserDocumentsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() hideBtn: boolean;
  @Input() role: Roles;
  @Input() previewPrevizualizationSettings: PrevisualizationSettings;
  @Input() typeAction: string;
  @Input() isSameOwner: boolean = false;
  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  @Output() emitPreviewsResources: EventEmitter<PrevisualizationSettings> = new EventEmitter();
  instanceLogo: FileStorage;
  basePath: string;
  photo: string;
  @ViewChild('inputImage', { static: false }) inputImage: ElementRef;
  @ViewChild('inputFrontDocument', { static: false }) inputFrontDocument: ElementRef;
  @ViewChild('inputBackDocument', { static: false }) inputBackDocument: ElementRef;
  selectDocumentView: string;
  nameFileSelected: string;
  listTypeDocuments = [
    {
      name: 'Foto de perfil',
      value: 'profilePicture'
    },
    {
      name: 'Parte frontal de documento',
      value: 'frontDocument'
    },
    {
      name: 'Parte trasera de documento',
      value: 'backDocument'
    }
  ];
  visualProfilePicture: FormControl = new FormControl('');
  visualFrontDocument: FormControl = new FormControl('');
  visualBackDocument: FormControl = new FormControl('');
  permission = Permission;
  errorLoadResource: boolean = false;
  docTypes: {name: string, loaded: boolean}[] = [{name: 'profilePicture', loaded: false}, {name: 'frontDocument', loaded: false}, {name: 'backDocument', loaded: false}];
  loadingResources: boolean = false;
  constructor(
    public utils: Utils,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private angularFireStorage: AngularFireStorage,
    public dialog: MatDialog,
    public adminUsersService: AdminUsersService,
    public fileNamePipe: FileNamePipe,
    public datePipe: DatePipe,
    public patterns: Patterns,
    public fileService: FileService
  ) { }

  /**
  * @description Initializes the visual documents formControls using the translateFileName method based on form input.
  */
  ngOnInit() {
    this.adminUsersService.checkRenderResources(this.docTypes, this.previewPrevizualizationSettings, this.form);
    this.processResourcesControls();
  }

  async processResourcesControls(): Promise<void> {
    let paths = [];
    this.visualBackDocument.setValue('');
    this.visualFrontDocument.setValue('');
    this.visualProfilePicture.setValue('');
    this.docTypes.forEach(type => {
      let controlValue: string;
      controlValue = this.adminUsersService.checkControlHaveStorageValue(type.name, this.form);
      controlValue && this[`visual${type.name.charAt(0).toUpperCase() + type.name.slice(1)}`].setValue(this.adminUsersService.traslateFileName(controlValue));
      // Si esta cargado se agrega a paths para que se consulte en loadResources
      if (type.loaded) paths = controlValue ? [...paths, {path: controlValue, type}] : paths;
    });
    if (paths && paths.length){
      this.loadingResources = true;
      await this.adminUsersService.loadResources(paths, this.role);
      this.loadingResources = false;
    }
  }

  /**
  * @description opens a modal to attach the user's profile photo, also executes openWindowFile method on modal closing.
  */
  openDialogProfilePhoto(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      observations: 'Por favor al adjuntar la foto verifica que : no debes tener gafas, tapabocas o  gorra. También se debe tomar a mitad de cuerpo',
      showBtnConfirmObservation: true
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.refuse) {
        this.openWindowFile();
      }
    });
  }

  /**
  * @param {Event} e is the input's event (file) coming from html 
  * @param {string} typeFile is the type of the image to upload
  * @description receives the image file, transforms and passes it to uploadFileStorage method
  */
  setInstanceFileStorage(e: Event, typeFile?: string): void {
    if (e.target['files'].length){
      const file = e.target['files'][0];
      const splittedNameFile = e.target['files'][0]['name'].split('.');
      const formatFile = splittedNameFile[splittedNameFile.length - 1];
      const dateInMs = new Date().getTime();
      const fileName = `${typeFile}_${dateInMs}.${formatFile}`;
      const modifiedFile = new File([e.target['files'][0]], fileName, {
        type: e.target['files'][0].type
      });
      if (!this.utils.isImage(e.target['files']) && typeFile === 'profilePicture') {
        this.snackBarService.openSnackBar('El archivo no es una imagen', undefined, 'error');
        return;
      }
      this.setResourcesPreview(typeFile, file, modifiedFile, fileName);
    }

  }

  async setResourcesPreview(typeFile: string, file: File, imageFile: File, fileName: string): Promise<void> {
    if (this.typeAction && this.typeAction === 'createVehicle') {
      this.basePath = 'user/' + this.form.get('information.document').value + '/';
      this.spinner.show();
      await this.fileService.uploadFile({path: this.basePath + fileName, imageFile: imageFile, key: `${typeFile}-${this.role}`});
      this.spinner.hide();
    }
    else this.basePath = 'user/' + 'temp' + '/';
    const key = this.role ? `${typeFile}-${this.role}` : `${typeFile}`;
    this.adminUsersService.previsulizationSettings[key] = {
      file: file,
      filePath: this.basePath + fileName,
      imageFile: imageFile,
      loaded: false
    };
    if (typeFile == 'backDocument') {
      this.form.get('backDocument').setValue(this.adminUsersService.previsulizationSettings[key].filePath);
      this.visualBackDocument.setValue(this.adminUsersService.traslateFileName(this.adminUsersService.previsulizationSettings[`${typeFile}-${this.role}`].filePath));
    }
    if (typeFile == 'frontDocument') {
      this.form.get('frontDocument').setValue(this.adminUsersService.previsulizationSettings[key].filePath);
      this.visualFrontDocument.setValue(this.adminUsersService.traslateFileName(this.adminUsersService.previsulizationSettings[key].filePath));
    }
    if (typeFile == 'profilePicture') {
      this.form.get('profilePicture').setValue(this.adminUsersService.previsulizationSettings[key].filePath);
      this.visualProfilePicture.setValue(this.adminUsersService.traslateFileName(this.adminUsersService.previsulizationSettings[key].filePath));
    }
  }

  // uploadStorage(): void {
  //   const filesToUpload = this.getFilesToUpload();
  //   if (!filesToUpload.length) return;
    
  //   this.spinner.show();
  
  //   this.fileService.uploadMultipleFiles(filesToUpload)
  //     .then((loadedResponse: { status: boolean, success: UploadTaskSnapshot, key: string }[]) => this.handleUploadResponse(loadedResponse))
  //     .then(() => {
  //       this.saveDriver();
  //     })
  //     .catch(() => {
  //       this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
  //     })
  //     .finally(() => {
  //       this.spinner.hide();
  //     });
  // }

  async uploadStorage(): Promise<void> {
    const filesToUpload = this.getFilesToUpload();
    if (!filesToUpload.length) return;
    this.spinner.show();
    try {
      const loadedResponse = await this.fileService.uploadMultipleFiles(filesToUpload);
      await this.handleUploadResponse(loadedResponse);
    } catch (error) {
      console.error("Error during upload or save resources:", error);
      this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
    } finally {
      this.spinner.hide();
    }
  }
  
  private getFilesToUpload(): { path: string; imageFile: File; key: string }[] {
    return Object.keys(this.adminUsersService.previsulizationSettings)
      .filter(key => !this.adminUsersService.previsulizationSettings[key].loaded && this.adminUsersService.previsulizationSettings[key].filePath)
      .map(key => ({
        path: this.adminUsersService.previsulizationSettings[key].filePath,
        imageFile: this.adminUsersService.previsulizationSettings[key].imageFile,
        key: key
      }));
  }
  
  private async handleUploadResponse(loadedResponse: { status: boolean; success: UploadTaskSnapshot; key: string }[]): Promise<void> {
    const updatePromises = loadedResponse.map(loadedFile => 
      this.updateFileMetadata(loadedFile)
    );
  
    return Promise.all(updatePromises)
      .then(() => {});
  }
  
  private async updateFileMetadata(loadedFile: { success: UploadTaskSnapshot; key: string }): Promise<void> {
    return loadedFile.success.ref.getDownloadURL()
      .then(url => {
        const formatFile = this.adminUsersService.previsulizationSettings[loadedFile.key].filePath.split('.').pop();
        this.adminUsersService.previsulizationSettings[loadedFile.key] = {
          file: {
            url: url,
            type: formatFile
          },
          loaded: true
        };
      })
      .catch(error => {
        console.error(`Error getting download URL for key ${loadedFile.key}:`, error);
        throw error;
      });
  }
  
  

  getResourceFile(typeFile: string): File | {url: string, type: string} | null {
    return this.adminUsersService.getResourceFile(typeFile);
  }

  getErrorLoadResource(typeFile: string): boolean {
    return this.adminUsersService.getErrorLoadResource(typeFile);
  }

  /**
  * @returns {boolean} if the form's information.document is invalid returns true, if it's valid returns false
  * @description returns true and disables the pictures controls if the document is invalid, 
  * enables the pictures controls and returns false if the document is valid.
  */
  get isDisable(): boolean {
    if (this.form.get('information.document').invalid) {
      this.form.get('profilePicture').disable();
      this.form.get('backDocument').disable();
      this.form.get('frontDocument').disable();
      return true;
    }
    this.form.get('profilePicture').enable();
    this.form.get('backDocument').enable();
    this.form.get('frontDocument').enable();
    return false;
  }

  /**
  * @param {('inputFrontDocument'|'inputImage'|'inputBackDocument')} typeFile is the input asociated (default:'inputImage')
  * @description clicks the input passed from params
  */
  openWindowFile(typeFile: 'inputFrontDocument' | 'inputImage' | 'inputBackDocument' = 'inputImage'): void {
    this[typeFile].nativeElement.click();
  }

  uploadFiles(files: File[], filePaths: string[]): void {
    this.spinner.show();
    const uploadPromises = files.map((file, index) => {
      const filePath = filePaths[index];
      const storageRef = this.angularFireStorage.ref(filePath);
      const uploadTask = this.angularFireStorage.upload(filePath, file);
      return uploadTask.then(() => {
        return storageRef.getDownloadURL().toPromise();
      });
    });
  
    Promise.all(uploadPromises)
      .then(() => {
        this.spinner.hide();
      })
      .catch((error) => {
        console.error('Error uploading files:', error);
        this.spinner.hide();
      });
  }

  /**
  * @description Checks forms.valid, if is not valid shows an snackBar of the error, 
  * else creates a body and passes it to updateUserDocuments method and emits an Output if the result is successfull
  */
  public async onSubmit(): Promise<void> {
    if (this.form.invalid) {
      if (this.utils.errorMessagesCustomized(this.form.get('information.document'), 'documento del conductor')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('profilePicture'), 'foto de perfil')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('frontDocument'), 'foto frontal del documento')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('backDocument'), 'foto trasera del documento')) return;
      else {
        this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
      }
    } else if (!this.form.getRawValue().profilePicture && !this.form.getRawValue().backDocument && !this.form.getRawValue().frontDocument) {
      this.snackBarService.openSnackBar(FormMessages.MISSING_FIELDS, undefined, 'alert');
    } else {
      this.uploadStorage();
      this.spinner.show();
      const data: UserDocuments = {
        profilePicture: this.form.getRawValue().profilePicture,
        backDocument: this.form.getRawValue().backDocument,
        frontDocument: this.form.getRawValue().frontDocument
      }
      this.adminUsersService.updateUserDocuments(this.form.getRawValue().information.document, data).subscribe(
        (success: any) => {

          this.spinner.hide();
          this.snackBarService.openSnackBar('Datos guardados correctamente', undefined, 'success');
          this.emitToParent.emit(this.form.getRawValue().information.document);
        },
        (error) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar('Ocurrió un error al guardar los datos', undefined, 'error');
        }
      );
    }
  }

  /**
  * @description checks the selectDocumentValue from form and assign its value into nameFileSelected if exists.
  */
  public onChangeTypeDocument(): void {
    const value = this.form.get(this.selectDocumentView).value;
    this.nameFileSelected = value ? value : null;
  }

  ngOnDestroy(): void {
    this.emitPreviewsResources.emit(this.adminUsersService.previsulizationSettings);
  }

}
