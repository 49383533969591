import {
  Component,
  OnInit,
  Inject,
  QueryList,
  ViewChildren,
  AfterViewInit,
} from "@angular/core";
import { MatDatepickerInputEvent } from "@angular/material";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Titles } from "src/app/core/resources/titles";
import { SnackBarService } from "src/app/core/services/snackBar.service";
import { Utils } from "src/app/core/resources/utils";
import { FormControl, Validators } from "@angular/forms";
import { FileComponent } from "../files/file.component";
import { DateManager } from "src/app/core/managers/date.manager";
import { FormMessages } from "src/app/core/messages/form-messages.enum";
import { Fmt } from "src/app/core/messages/fmt";
import { DomSanitizer } from "@angular/platform-browser";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { OptionsDialog } from "src/app/core/interfaces/options-dialog";
@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.scss"],
  providers: [DatePipe]
})
export class DialogComponent implements OnInit, AfterViewInit {
  myFilter = DateManager.filter({ from: this.data.fromDate, until: this.data.untilDate });
  @ViewChildren(FileComponent) fileComponent: QueryList<FileComponent>;
  textAreaMessage: string = "";
  date: FormControl = new FormControl('', Validators.required)
  dateFormatted: string = "";
  inputText: string = "";
  inputNumber: number = 0;
  itemList: any = {};
  inputExtraAdvance: string = "";
  refuse: string = "";
  checkbox = [];
  invalidDate: boolean = false;
  minDate: Date = new Date();
  address = new FormControl('', Validators.required);
  resourceEvent: Event;
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: OptionsDialog,
    public titles: Titles,
    private snackBarService: SnackBarService,
    public utils: Utils,
    private dateManager: DateManager,
    private sanitizer: DomSanitizer,
    public datePipe: DatePipe,
    private router: Router,
  ) {
    if (this.data && this.data.descriptionHTML)
      this.data.descriptionHTML = this.sanitizer.bypassSecurityTrustHtml(this.data.descriptionHTML as string);
    if (
      this.data &&
      !this.utils.isDefined(data.maxLength) &&
      this.data.inputText
    ) {
      this.data.maxLength = 20;
    }
  }

  ngOnInit() { }

  confirmDialog(refuse?: string) {
    this.refuse = refuse;
    this.closeDialog(true);
  }

  onSelectAddress($event) {
    this.address.setValue($event);
  }

  closeDialog(response?: boolean) {
    let dataResponse: any = {
      state: response,
      refuse: this.refuse,
    };
    if (response) {
      // textArea
      if (this.data.textArea && this.textAreaMessage.trim().length < 1) {
        this.snackBarService.openSnackBar(
          "Faltan datos por agregar",
          undefined,
          "alert"
        );
        return;
      } else if (this.data.textArea) {
        dataResponse.message = this.textAreaMessage.trim();
      }
      // date
      if (this.data.date && !DateManager.isValidDate(this.dateFormatted)) {
        this.date.markAsTouched();
        this.invalidDate = true;
        this.snackBarService.openSnackBar(
          "Faltan datos por agregar",
          undefined,
          "alert"
        );
        return;
      } else if (
        this.data.date &&
        DateManager.isValidDate(this.dateFormatted)
      ) {
        this.invalidDate = false;
        dataResponse.date = this.dateFormatted;
      }
      // select
      if (this.data.viewSelect && this.utils.objIsEmpty(this.itemList)) {
        this.snackBarService.openSnackBar(
          "No ha seleccionado ninguna opción",
          undefined,
          "alert"
        );
        return;
      } else if (this.data.viewSelect && this.utils.isDefined(this.itemList)) {
        dataResponse.itemSelected = this.itemList;
      }

      //autocomplete
      if (this.data.autocompleteAddress) {
        if (this.address.valid) dataResponse.address = this.address.value;
        else {
          this.snackBarService.openSnackBar(Fmt.string(FormMessages.MISSING_FIELD, 'dirección'), undefined, 'alert');
          return;
        }
      }
      if (this.data.inputNumber && this.inputNumber < 1) {
        this.snackBarService.openSnackBar(
          "Ingresa un valor para continuar",
          undefined,
          "alert"
        );
        return;
      } else if (this.inputNumber) {
        dataResponse.inputNumber = this.inputNumber;
      }
      if (this.data.inputText && this.inputText.trim().length < 1) {
        this.snackBarService.openSnackBar(
          "Ingresa un valor para continuar",
          undefined,
          "alert"
        );
        return;
      } else if (this.inputText) {
        dataResponse.inputText = this.inputText;
      }
      //
      if (
        this.data.inputExtraAdvance &&
        this.inputExtraAdvance.trim().length < 1
      ) {
        this.snackBarService.openSnackBar("Ingresa un valor para continuar");
        return;
      } else if (this.inputExtraAdvance) {
        dataResponse.inputExtraAdvance = this.inputExtraAdvance;
      }
      //
      if (this.data.file && this.data.inputFormControl.invalid) {
        this.snackBarService.openSnackBar(
          "Debes cargar un archivo",
          undefined,
          "alert"
        );
        return;
      } else if (
        this.data.inputFormControl &&
        this.data.inputFormControl.valid
      ) {
        if (this.data && this.data.fileOptions && !this.data.fileOptions.onlyProcessPaths) this.loadDocument();
        dataResponse.filePath = this.data.inputFormControl.value;
        dataResponse.resourceEvent = this.resourceEvent;
      }

      if (this.data.checkbox && this.checkbox.length > 0) {
        dataResponse.checkbox = this.checkbox;
      }
    }
    if (this.data.btnDetailCargo && !this.data.isTracking) {
      this.router.navigate([this.data.path]);
    }
    this.dialogRef.close(dataResponse);
  }

  ngAfterViewInit() { }

  loadDocument() {
    this.fileComponent.toArray().map((component) => {
      component.loadFileToStorage();
    });
  }

  onChangeDate($event: MatDatepickerInputEvent<Date>) {
    this.invalidDate = false;
    this.dateFormatted = DateManager.dateToString($event.value, 'YYYY-MM-DD HH:mm ZZ');
  }

  onCheckboxChange(checked: boolean, elemento: string, id: string) {
    if (checked) {
      this.checkbox.push({
        name: elemento,
        id: id,
      });
    } else {
      const index = this.checkbox.findIndex(
        (obj) => obj.name === elemento && obj.id === id
      );
      if (index >= 0) {
        this.checkbox.splice(index, 1);
      }
    }
  }
}
