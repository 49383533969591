<mat-form-field [appearance]="options?.appearance? options.appearance : 'standard'">
    <mat-label>{{options.title}}<span class="asterisk"
            *ngIf="utils.isRequiredField(formControlThirdPartyAddress)">*</span></mat-label>
    <input (keyup)="changeValue()" matInput [matAutocomplete]="autocompleteThirdParties"
        [formControl]="formControlThirdPartyAddress">
    <div matSuffix class="spinner-border text-info" role="status" *ngIf="loadingThirdParties">
        <span class="sr-only">Loading...</span>
    </div>
    <mat-autocomplete #autocompleteThirdParties="matAutocomplete" [displayWith]="displayThirdPartyName"
        (optionSelected)="onSelectAddress($event)">
        <mat-option *ngFor="let thirdPartyAddress of thirdPartiesAddressesFiltered" [value]="thirdPartyAddress">
            <span>{{thirdPartyAddress?.address?.address}}</span>
        </mat-option>
        <mat-option [value]="null" (click)="openDialog()" *ngIf="showCreateAddress">
            <span class="font-italic">Agregar {{options.title}}</span>
        </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="formControlThirdPartyAddress.errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
    </mat-error>
</mat-form-field>