<div class="download">
  <!-- Title  -->
  <mat-card-content>
    <label class="title-3">
      ¿A dónde vas a enviar el servicio?
    </label>
  </mat-card-content>
  <!-- Summay Origin -->
  <mat-card-content>
    <div class="download__summary-origin">
      <div class="download__summary-origin-titles">
        <figure class="container-icon-edit" (click)="editOrigin()">
          <img src="/assets/svg/icons/icon-edit.png" alt="">
        </figure>
        <span class="circle-title purple"></span>
        <div>
          Origen
        </div>
        <div class="subtitle-city">
          {{manualCreationCargoService.cargoForm.value.cargoFeature.uploadDownload.origin.name}}
        </div>
      </div>
      <span class="origin-line"></span>
      <div class="download__summary-origin-titles">
        <span class="circle-title blue"></span>
        <div>
          Destino
        </div>
      </div>
      <span class="destination-line"></span>
    </div>
  </mat-card-content>

  <mat-accordion>
    <mat-expansion-panel #matExpansionPanel class="mat-panel-destinations" [expanded]="true"
      *ngFor="let destination of manualCreationCargoService.formDestinations.controls; let indexDestination = index;"
      (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="mat-panel-header mat-panel-header-destination"
        [ngClass]="{'mat-panel-header-alone': manualCreationCargoService.formDestinations.controls.length === 1}">
        <label>
          Ciudad {{(1+indexDestination)}}
        </label>
        <br>
        <div [ngClass]="{'element-hide': manualCreationCargoService.formDestinations.controls.length > 1}"
          *ngIf="destinations.length > 1" class="subtitle-city">
          {{destination.get('name').value}}
        </div>
        <mdb-icon (click)="removeDestination(indexDestination)" *ngIf="indexDestination > 0" class="icon-times" fas
          icon="times"></mdb-icon>
      </mat-expansion-panel-header>
      <div class="row my-1">
        <div class="col-md-12 p-0">
          <label class="text-strong">¿Es internacional?&nbsp;&nbsp;</label>
          <mat-button-toggle-group #group="matButtonToggleGroup"
            [formControl]="manualCreationCargoService.getFormDestination(indexDestination).get('isInternational')">
            <mat-button-toggle [value]="true" (change)="onChangeInternational(indexDestination)">
              Si
            </mat-button-toggle>
            <mat-button-toggle [value]="false" (change)="onChangeInternational(indexDestination)">
              No
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <div class="mat-panel-body">
        <!-- Autocomplete City -->
        <mat-card-content>
          <span class="circle-title purple"></span>
          <app-autocomplete-city [options]="manualCreationCargoService.cityDownloadOptions.at(indexDestination).value"
            [inputFormControl]="manualCreationCargoService.cityDownloadControls.at(indexDestination)"
            [validate]="manualCreationCargoService.cityDownloadValidates.at(indexDestination).value"></app-autocomplete-city>
        </mat-card-content>
        <!-- List Address -->
        <ng-container *ngIf="(manualCreationCargoService.getCargoForm().get('ministry').value && ((manualCreationCargoService.getFormDestination(indexDestination).value.municipalityCode
          && manualCreationCargoService.formCargoOwner.value.documentNumber)||(manualCreationCargoService.getFormDestination(indexDestination).value.country.code!=='CO')))
          || !manualCreationCargoService.getCargoForm().get('ministry').value">
          <div
            *ngFor="let address of manualCreationCargoService.getFormDestination(indexDestination).get('addresses')['controls']; let i = index;"
            class="download__autocomplete">
            <span class="download__autocomplete-index">{{i + 1}}</span>
            <mdb-icon (click)="removeAddressField(indexDestination, i)" *ngIf="i > 0" class="icon-times" fas
              icon="times"></mdb-icon>
            <!-- <app-autocomplete-address *ngIf="address" [title]="'Dirección de cargue'" [options]="{index: i, indexDestination: indexDestination}" (emitToParent)="onSelectAddress($event)" class="download__autocomplete-address"></app-autocomplete-address> -->
            <app-third-party-consignment [ngClass]="{'even': 0 === i % 2, 'odd': !(0 === i % 2)}"
              [title]="'Destinatario'" [inputFormControl]="address"
              [options]="{index: i, indexDestination: indexDestination, city: destination.value, code: manualCreationCargoService.getFormDestination(indexDestination).value.country.code}"
              (emitToParent)="onSelectThirdParty($event)"
              [isInternational]="!!destination.get('isInternational').value"></app-third-party-consignment>
            <div *ngIf="i > 0" class="separator-horizontal"></div>
          </div>
          <!-- Btn add Address -->
          <mat-card-content class="text-right download__actions">
            <button mat-button class="button-width-auto" (click)="addAddressForm(indexDestination)">
              <img src="/assets/svg/icons/icon-plus-purple.png" alt="">
              <span>Agregar otra dirección</span>
            </button>
          </mat-card-content>
        </ng-container>
      </div>
    </mat-expansion-panel>
    <!-- Btn add Destination -->
    <mat-card-content>
      <button mat-button class="primary-secondary" color="primary" (click)="addDestinationForm()">
        <span>Agregar otra ciudad destino</span>
      </button>
    </mat-card-content>
  </mat-accordion>
  <br>
  <div class="button-container justify-content-center mt-3 w-100">
    <button mat-raised-button class="secondary-sharp mr-4" (click)="editOrigin()">Atrás</button>
    <button mat-raised-button class="bg-color-primary" (click)="nextStep()">Siguiente</button>
  </div>
</div>