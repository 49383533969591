export enum NotificationRole {
    GEOFENCE = "117b710a-9a00-468c-b9d6-c9fe3912afa4",
    PANIC = "18cd0760-8bbb-470f-93a1-551d25770941"
}

export enum NotificationTypes {
    NEGOTIATION = '1',
    TRACKING = '2',
    DELAY_BETWEEN_POINTS = '3',
    CREATE_CARGO = '4',
    USER_DEACTIVATE = '5',
    GEOFENCE = '6',
    PANIC = '7',
    GPS_OFF = '8',
    ANTENNA = '9',
    VEHICLE_OFF = '10',
    LOW_BATTERY = '11',
    OPENED_DOOR = '12',
    DEFAULT_ALERT = '13'
}
