<div [ngClass]="{'mb-5':addOptions,'multiple-container': dinamicStyles}">
  <mat-form-field [appearance]="appearance ? appearance : 'standard'">
    <mat-label>{{title}}<span class="asterisk" *ngIf="required">*</span></mat-label>
    <input type="text" #inputAutocomplete (keyup)="changeInput()" matInput autocomplete="off" matGoogleMapsAutocomplete
      [(ngModel)]="value" [disabled]="disabled">
    <i matSuffix matTooltip="Seleccionar ubicación desde el mapa" matTooltipPosition="right"
      class="fas fa-map-marked-alt autocomplete__tooltip-icon cursor-pointer mr-2" (click)="selectAddressOnMap()"
      *ngIf="addOptions && !disabled"></i>
    <i *ngIf="idAddress && idAddress > 0" matSuffix matTooltip="Eliminar dirección" matTooltipPosition="right"
      class="fas fa-times" (click)="emitToParent.emit({type: 'delete', idAddress: this.idAddress})"></i>
  </mat-form-field>

  <span *ngIf="!active && value && !simpleAddress && addOptions" class="mat-button-wrapper"
    (click)="openAddressCorrection()">
    <span class="alias"><u>Corregir dirección</u></span>
  </span>

  <div *ngIf="active && !simpleAddress" class="alias-form">
    <mat-form-field appearance="standard">
      <mat-label>Dirección corregida</mat-label>
      <input type="text" autocomplete="off" matInput [formControl]="newAddressSelected" [disabled]="disabled">
    </mat-form-field>
    <button matTooltip="Cerrar" class="close-btn" type="button" matTooltipPosition="right" (click)="closeAlias()"><i
        class="fas fa-times"></i></button>
  </div>

  <span class="pattern pattern--autocomplete font-secondary-dark"
    *ngIf="required && !addressSelected && !simpleAddress">
    Seleccione una dirección
  </span>
  <span class="pattern pattern--autocomplete font-secondary-dark" *ngIf="!isValid && !simpleAddress">
    La dirección no es valida
  </span>
</div>