import { CurrencyPipe } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AddressCargo } from "src/app/core/interfaces/addressCargo";
import { Cargo, SicetacResponse } from "src/app/core/interfaces/cargo";
import { Destination } from "src/app/core/interfaces/destination";
import { RequestApprovalCargo } from "src/app/core/interfaces/requestApprovalCargo";
import {
  ResponseApproval,
  VerifyCargoApproval,
} from "src/app/core/interfaces/verifyCargoApproval";
import { DateFormatPipe } from "src/app/core/pipe/dateFormat.pipe";
import { PdfTitlePipe } from "src/app/core/pipe/pdfTitle.pipe";
import { SafePipe } from "src/app/core/pipe/safe.pipe";
import { Permission } from "src/app/core/resources/permission";
import { Titles } from "src/app/core/resources/titles";
import { Utils } from "src/app/core/resources/utils";
import { AuthService } from "src/app/core/services/authentication.service";
import { SnackBarService } from "src/app/core/services/snackBar.service";
import { DialogComponent } from "src/app/shared/dialog/dialog.component";
import { OpenImgComponent } from "src/app/shared/open-img/open-img.component";
import { AdminUsersService } from "../../administration/admin-users/admin-users.service";
import { CargoDetailComponent } from "../cargo-detail/cargo-detail.component";
import { CargoDetailService } from "../cargo-detail/cargo-detail.service";
import { ExternalManifestCargoComponent } from "../cargo-detail/components/external-manifest-cargo/external-manifest-cargo.component";
import { FreightForwarderEvidenceService } from "../cargo-evidence/cargo-evidence.service";
import { CargoItemService } from "../cargo-item/cargo-item.service";
import { FreightListService } from "../cargo-list/cargo-list.service";
import { CargoTrackingService } from "../cargo-tracking/cargo-tracking.service";
import { DiscountsAndBonusesCargoComponent } from "../discounts-and-bonuses-cargo/discounts-and-bonuses-cargo.component";
import { AccountService } from "../../account/account.service";
import { User } from "src/app/core/interfaces/user";
import { VehiclesService } from "../../administration/vehicles/list-vehicles.service";
import { Vehicle } from "src/app/core/interfaces/vehicle";
import { AssignVehicleCargoComponent } from "../assign-vehicle-cargo/assign-vehicle-cargo.component";
import { ChangeCompanyComponent } from "../change-company/change-company.component";
import { CargoReteicaComponent } from "../cargo-reteica/cargo-reteica.component";
import { CargoRenewDatesComponent } from "../cargo-renew-dates/cargo-renew-dates.component";
import { RoundedCargoComponent } from "../rounded-cargo/rounded-cargo.component";
import { CargoHistoryNewsComponent } from "../cargo-history-news/cargo-history-news.component";
import { HttpErrorResponse } from "@angular/common/http";
import { Dialog } from "src/app/core/resources/dialog";
import { CargoUpdateComponent } from "../cargo-update/cargo-update.component";
import { VehicleManager } from "src/app/core/managers/vehicle.manager";
import { CargoLocationReportComponent } from "../cargo-location-report/cargo-location-report.component";
import { CargoService } from "src/app/core/services/cargo.service";
import { CompanyManager } from "src/app/core/managers/company.manager";
import { CargoManager } from "src/app/core/managers/cargo.manager";
import { LicenseDriverManager } from "src/app/core/managers/license-driver.manager";
import { environment } from "src/environments/environment";
import { PermissionRole } from "src/app/core/resources/permission-role";
import { CargoAdvancePercentageComponent } from "../cargo-advance-percentage/cargo-advance-percentage.component";
import { CargoRequestAdvanceComponent } from "../cargo-request-advance/cargo-request-advance.component";
import { EMPTY, Observable, Subscription, of } from "rxjs";
import { ContainerInspect } from "src/app/core/interfaces/container-inspect";
import { OptionsDialog } from "src/app/core/interfaces/options-dialog";
import { Traceability } from "src/app/core/interfaces/lastPointLocation";
import { CargoMessages } from "src/app/core/messages/cargo-messages.enum";
import { CargoStateEnum } from "src/app/core/enums/cargoState.enum";
import { DisableManifestReason } from "src/app/core/enums/manifestState.enum";
import { PDFTypes } from "src/app/core/enums/pdf-types.enum";
import { VehicleMessages } from "src/app/core/messages/vehicle-messages.enum";
import { CargoOptionsService } from "./cargo-options.service";
import { ServiceMessages } from "src/app/core/messages/service-messages.enum";
import { ReceivableService } from "../../administration/receivable/receivable.service";
import { finalize, switchMap } from "rxjs/operators";
import { ReceivableModificationsComponent } from "../../administration/receivable/receivable-modifications/receivable-modifications.component";
import { ModalEnum } from "src/app/core/enums/modal.enum";
import { UserClient } from "src/app/core/interfaces/userClient";
import { DateManager } from "src/app/core/managers/date.manager";
import { Fmt } from "src/app/core/messages/fmt";
import { UserManager } from "src/app/core/managers/user.manager";
import { Roles } from "src/app/core/enums/roles.enum";
import { CargoShippingCostDialogComponent } from "../cargo-shipping-cost-dialog/cargo-shipping-cost-dialog.component";
import { FormMessages } from "src/app/core/messages/form-messages.enum";
import { Company } from "src/app/core/interfaces/company";
import { ApproveCargoDialogComponent } from "src/app/shared/approve-cargo-dialog/approve-cargo-dialog.component";
import { CargoApprovalRequest } from "src/app/core/interfaces/cargo-approval-request";
import { ConfirmDriver } from "src/app/core/interfaces/confirmDriver";
import { ModificationReceivable } from "src/app/core/interfaces/modificationReceivable";
import { RequestDatesToMonitorComponent } from "../request-dates-to-monitor/request-dates-to-monitor.component";
import { BasicCompany } from "src/app/core/interfaces/basicCompany";
import { CargoGpsDTO, Gps } from "src/app/core/interfaces/gps";
import { Endpoints } from "src/app/core/resources/endpoints";
import { AppInterceptorService } from "src/app/core/interceptors/app.interceptor";
import { MoveAdvanceDialogComponent } from "../move-advance-dialog/move-advance-dialog.component";
import { UserMessages } from "src/app/core/messages/user-messages.enum";
import { ExtraDocument } from "src/app/core/interfaces/extraDocument";
import { ExpiredDocuments } from "src/app/core/interfaces/expired-documents";
import { CompanyService } from "src/app/core/services/company.service";
import { ServiceType } from "src/app/core/interfaces/serviceType";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AdditionalCertification } from "src/app/core/interfaces/additionalCertification";
import { AdditionalCertificationDict, AdditionalCertificationEnum } from "src/app/core/enums/additionalCertification.enum";
import { Companies } from "src/app/core/resources/companies";
import { FIELDS_SECURITY_STUDY, FIELDS_STATE } from "src/app/core/config/user.config";
import { TRIP_TYPES } from "src/app/core/enums/tripTypes.enum";

@Component({
  selector: "app-cargo-options",
  templateUrl: "./cargo-options.component.html",
  styleUrls: ["./cargo-options.component.scss"],
  providers: [
    CargoDetailService,
    CurrencyPipe,
    DateFormatPipe,
    VehicleManager,
    CargoManager,
    LicenseDriverManager,
    UserManager,
  ],
})
export class CargoOptionsComponent implements OnInit {
  static GpsProviders: { [key: string]: { monitor: boolean, name: string, companyId: string, id: string } } = null;

  @Input() cargo: Cargo;
  @Input() isNegotiation: boolean;
  @Input() type: string;
  @Input() typeList: string = null;
  @Input() tracepoints: Traceability;
  @Output() refreshCargo = new EventEmitter<any>();
  @Output() refreshListCargo = new EventEmitter<any>();
  @Output() executeParentMethod = new EventEmitter<string>();
  @Output() openVehicle = new EventEmitter<string>();
  @Output() showInspection = new EventEmitter<string>();
  @Output() loadDeleted: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild(AssignVehicleCargoComponent, { static: false })
  assignVehicleCargoComponent: AssignVehicleCargoComponent;
  @ViewChild(CargoDetailComponent, { static: false })
  cargoDetailComponent: CargoDetailComponent;

  driver: User;
  vehicle: Vehicle;
  permission = Permission;
  listActive = "";
  lastInspection;
  cannotShowManifestReason: string = DisableManifestReason.PRECONDITIONS_NOT_MET;
  cannotRequestAdvanceReason: string = CargoMessages.SHOW_REQUEST;
  canOpenRequestAdvanceModal = false;
  isRequestAdvanceDefined = false;
  flag: number = 0;
  disableViewManifest: boolean = true;
  public environment = environment;
  lastContainerInspection: ContainerInspect;
  historyFlag: boolean = false;
  holder: any;
  pendingReceivables: string = "";
  allowCreatingWithoutTruora: boolean = false;
  listCompanies: BasicCompany[] = [];
  company: Company;

  constructor(
    public titles: Titles,
    private currencyPipe: CurrencyPipe,
    public cargoItemService: CargoItemService,
    public matDialog: MatDialog,
    public authService: AuthService,
    public utils: Utils,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private cargoListService: FreightListService,
    private cargoTrackingService: CargoTrackingService,
    private pdfTitlePipe: PdfTitlePipe,
    private cargoDetailService: CargoDetailService,
    private adminUserService: AdminUsersService,
    private cargoEvidenceService: FreightForwarderEvidenceService,
    private router: Router,
    private dateFormatPipe: DateFormatPipe,
    private accountService: AccountService,
    private vehiclesService: VehiclesService,
    private dialog: Dialog,
    private vehicleManager: VehicleManager,
    private cargoService: CargoService,
    private companyManager: CompanyManager,
    private cargoManager: CargoManager,
    private licenseDriverManager: LicenseDriverManager,
    public permissionRole: PermissionRole,
    private cdref: ChangeDetectorRef,
    private cargoOptionsService: CargoOptionsService,
    public receivableService: ReceivableService,
    private userManager: UserManager,
    private activatedRoute: ActivatedRoute,
    private companyService: CompanyService
  ) {
    this.cannotRequestAdvanceReason = "";
  }
  /**
  * This method set the list active taking into account the last list of the session storage.
  */
  ngOnInit() {
    this.company = this.authService.getCompany();
    this.listCompanies = this.accountService.listRegisterCompanies();
    if (sessionStorage.getItem("_lastList")) {
      this.listActive = sessionStorage.getItem("_lastList").toString();
    }
    this.infoTruora();
  }

  /**
  * This method checks if the load have changes and if have changes change the historyflag.
  * @param {SimpleChanges} changes (SimpleChanges) read the changes of the inputs.
  */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.cargo && changes.cargo.currentValue) {
      this.historyFlag = false;
      this.loadCannotShowManifestReason();
    }
  }
  /**
  * This method will run after the content has been checked and most of the time it's during a change detection cycle
  */
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  /**
  * Fetches Truora entity info.
  */

  private infoTruora() {
    const holderCompany = this.companyService.holderCompany;
    if (holderCompany && holderCompany.companyId)
      this.allowCreatingWithoutTruora = !!(holderCompany && holderCompany.allowCreatingWithoutTruora);
  }

  //
  /**
  * This method open a dialog that allows the user to approve or reject the load.
  * @example
  * @param {boolean} state (boolean) is the approval state could be approved or rejected.
  */
  openDialogApproveCargo(state: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: state
        ? this.titles.titlesModalApproveCargo +
        this.currencyPipe.transform(
          this.cargoItemService.getRealTotalCost(this.cargo),
          "COP",
          "code"
        ) +
        " ?"
        : this.titles.titleRefuseCargo,
      cargos: [this.cargo]
    };

    if (state) {
      dialogConfig.data.date = true;
    } else if (!state) {
      dialogConfig.data.textArea = true;
    }

    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;

    // Cambiar por approveCargoDialogComponent para habilitar el nuevo formulario
    //ApproveCargoDialogComponent
    // DialogComponent
    const dialogRef = this.matDialog.open(ApproveCargoDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result: CargoApprovalRequest) => {
      if (!!result) {
        this.approveCargoRNDC(state, result);
      }
    });
  }
  /**
  * This method open a dialog window that allows the user to modify the advance percentage.
  */
  public openDialogNewAdvancePercentage() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      cargo: this.cargo,
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(
      CargoAdvancePercentageComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.refreshListCargo.emit();
        this.refreshCargo.emit(this.cargo.consecutive);
      }
    });
  }
  /**
  * This method open a dialog window that allows the user to change the reteica percent of the load.
  */
  public openDialogChangeReteica() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Modificar Reteica",
      idCargo: this.cargo.id,
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(CargoReteicaComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.refreshListCargo.emit();
        this.refreshCargo.emit(this.cargo.consecutive);
      }
    });
  }
  /**
  * This method open a dialog window that render the novelties history detail of the load.
  */
  public openDialogHistory() {
    if (this.tracepoints) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: "Historial de novedades",
        tracepoints: this.tracepoints,
        cargo: this.cargo,
      };
      dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
      dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
      dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
      const dialogRef = this.matDialog.open(
        CargoHistoryNewsComponent,
        dialogConfig
      );
      dialogRef.afterClosed().subscribe(() => {
        this.refreshListCargo.emit();
        this.refreshCargo.emit(this.cargo.consecutive);
      });
    } else {
      this.snackBarService.openSnackBar(FormMessages.ERROR_OPEN_HISTORY, undefined, 'error');
    }
  }
  /**
  * This method brings the tracepoints of the load.
  */
  public getTracepoints() {
    this.spinner.show();
    this.cargoTrackingService.getTracking(this.cargo.id).subscribe(
      (data: Traceability) => {
        this.spinner.hide();
        if (data) {
          this.tracepoints = data;
          this.openDialogHistory();
        } else {
          this.openDialogError(FormMessages.NOT_REGISTERED_HISTORY);
        }
      },
      error => {
        this.spinner.hide();
        this.openDialogError(FormMessages.NOT_REGISTERED_HISTORY);
      })
  }
  /**
  * This method open a dialog to confirm or reject the remove of the load.
  * @param {string} type (string - optional) optional parameter to redirect the user to different places in this case the only option is lowAgreedValue.
  */
  public confirmRemoveCargo(type?: string) {
    const hasPermissionRemovePaid: boolean = this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.removeCargoWithPayments);
    const hasPayments: boolean = !!(this.cargo.shippingCost && this.cargo.shippingCost.payments && !!this.cargo.shippingCost.payments.length);
    const dialogConfig = new MatDialogConfig();
    const title =
      hasPayments && hasPermissionRemovePaid
        ? 'Este servicio tiene pagos realizados, ¿Estás seguro que deseas eliminarlo?'
        : this.cargo.ministry && this.cargo.state !== CargoStateEnum.REQUEST
          ? `Este servicio tiene remesas, al eliminarlo se anularán ante el RNDC. ¿Estás seguro que deseas eliminarlo?`
          : `¿Estás seguro que deseas eliminar este servicio?`;
    dialogConfig.data = {
      title: title,
    };
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        dialogConfig.data = {
          title:
            "Estas a un paso de anular el servicio, Nos gustaria conocer la razón por la cual se eliminará",
          textArea: true,
          placeholder: "Observacion",
        };
        const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((result) => {
          if (result && result.state) {
            this.removeCargo(result.message, type, hasPayments && hasPermissionRemovePaid);
          }
        });
      }
    });
  }
  /**
  * This method consumes the service that delete a load.
  * @param {string} observation (string) is the reason of the user for delete the load.
  * @param {string} type (string - optional) is the value that indicates if need to redirect the user to other path.
  * @param {boolean} hasPaymentsAndPermission (boolean) is validation to know if the user have the permission to delete de load.
  */
  public removeCargo(observation: string, type?: String, hasPaymentsAndPermission: boolean = false) {
    this.spinner.show();
    let infoExtra = {
      fingerPrint: {
        userId: this.authService
          .getUserSession()
          .information.document,
        userName: this.authService.getUserSession().information.name,
        date: DateManager.dateToString(new Date()),
      },
      observation: observation,
    };
    this.cargoDetailService
      .removeCargo(this.cargo.id, { body: infoExtra }, hasPaymentsAndPermission)
      .subscribe(
        (data: any) => {
          this.spinner.hide();
          if (data && data.message === "Deleted") {
            this.snackBarService.openSnackBar(
              "El servicio se eliminó correctamente"
            );
            if (type) {
              if (type === 'lowAgreedValue') this.router.navigate(['/cargo/create']);
              else if (type === 'fromAdvanceAlert') this.loadDeleted.emit(this.cargo.licensePlate);
            }
            else {
              this.goToList();
              this.refreshListCargo.emit();
            }

          } else if (
            (data.message &&
              data ===
              `Error, no se ha podido anular la remesa atada a este servicio (${this.cargo.consecutive}), prueba mas tarde`,
              undefined,
              "error")
          ) {
            this.snackBarService.openSnackBar(data.message, undefined, "error");
          } else {
            this.snackBarService.openSnackBar(
              `Ocurrió un error al realizar el proceso con el servicio: ${this.cargo.consecutive}`,
              undefined,
              "error"
            );
          }
        },
        (error) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar(
            `Ocurrió un error al realizar el proceso con el servicio: ${this.cargo.consecutive}`,
            undefined,
            "error"
          );
        }
      );
  }

  get showOptionEditAndDisassociate(): boolean {
    return this.cargo && this.cargo.shippingCost && this.cargo.shippingCost.cashed;
  }
  /**
  * This method executes the dialog edit window of the specific bill element.
  */
  public editBill() {
    this.openDialogEdit("bill", "factura");
  }
  /**
  * This method executes the dialog edit window of the specific income element.
  */
  public editIncome() {
    this.openDialogEdit("income", "comprobante");
  }
  /**
  * This method executes the dialog confirmation window of dissasociation of the specific bill element.
  */
  public disassociateBill() {
    this.confirmDisassociate("bill", "factura");
  }
  /**
 * This method executes the dialog confirmation window of dissasociation of the specific income element.
 */
  public disassociateIncome() {
    this.confirmDisassociate("income", "comprobante");
  }

  /**
  * This method is to confirm the disassociation of the element could be bill or income.
  * @param {string} type (string) is type of element to dissasociate bill or income.
  * @param {string} data (string) the name of the element to dissasociate.
  */
  public confirmDisassociate(type: string, data: string) {
    const dialogConfig = new MatDialogConfig();
    const title = `¿Estás seguro que deseas desasociar ${data} seleccionad${type === 'bill' ? 'a' : 'o'}?`;
    dialogConfig.data = {
      title: title,
    };
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        let ids = [this.cargo.id];
        this.disassociate(ids, type);
      }
    });
  }
  /**
  * This method open a dialog window that allows the user to modify the bill or the income element.
  * @param {string} type (string) is type of element could be bill or income.
  * @param {string} data (string) is the label that the user would see.
  */
  public openDialogEdit(type: string, data: string) {
    const dialogConfig = new MatDialogConfig();
    const title = `Modificar ${data}`;
    dialogConfig.data = {
      title: title,
    };
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(CargoUpdateComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (this.utils.isDefined(result)) {
        let ids = [this.cargo.id];
        this.updateInvoice(ids, type, result);
      }
    });
  }
  /**
  * This method comsumes the service that modify the invoice number.
  * @param {string[]} idCargos (string[]) are the identifiers of the loads that needs to modify.
  * @param {string} type (string) is the type of element could be: bill or income.
  * @param {string} value (string) is the new invoice number that wants to set.
  */
  public updateInvoice(idCargos: string[], type: string, value: string) {
    this.spinner.show();
    this.cargoDetailService.editInvoice(idCargos, type, value).subscribe(
      (data: any) => {
        this.spinner.hide();
        if (data && data.message) {
          this.snackBarService.openSnackBar(data.message);
          this.refreshListCargo.emit();
        }
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(
          `Ocurrió un error al desasociar`,
          undefined,
          "error"
        );
      }
    );
  }

  /**
  * This method allows to dissasociate the bill or income.
  * @param {string[]} idCargos (string[]) the ids of the loads that wants to dissasociate.
  * @param {string} type (string) is type of element to dissasociate bill or income.
  */
  public disassociate(idCargos: string[], type: string) {
    this.spinner.show();
    this.cargoDetailService.disassociate(idCargos, type).subscribe(
      (data: any) => {
        this.spinner.hide();
        if (data && data.message) {
          this.snackBarService.openSnackBar(data.message);
          this.refreshListCargo.emit();
        }
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(
          `Ocurrió un error al desasociar`,
          undefined,
          "error"
        );
      }
    );
  }
  /**
  * This method redirect de user depends on the active list.
  */
  public goToList() {
    const paymentCargoList = [
      "paymentAdvanceCargo",
      "paymentExtraAdvanceCargo",
      "paymentAdditionalCostsCargo",
      "paymentBalanceCargo"
    ]
    const loadingRoutesList = [
      'loadingRoutes',
      'emptyContainers',
      'urbanRoutes',
      'lastMileRoutes',
      'nationalRoutes',
      'exportRoutes',
      'importRoutes',
      'internationalRoutes'
    ];
    if (paymentCargoList.includes(this.listActive)) {
      this.router.navigate(["cargo/list/paymentCargo"]);
    } else if (loadingRoutesList.includes(this.listActive)) {
      this.router.navigate(["cargo/list/loadingRoutes"]);
    } else {
      this.router.navigate(["cargo/list/" + this.listActive]);
    }
  }

  /**
   * This method consumes the service that set the approval state of a load.
   * @param {boolean} state (boolean) is the state that wanted to set this state could be approved or rejected.
   * @param {CargoApprovalRequest} cargoApprovalRequest (CargoApprovalRequest) are the loads that wanted to approved.
   */
  // TODO definir en una interfaz cargoApprovalRequest
  approveCargoRNDC(state: boolean, cargoApprovalRequest: CargoApprovalRequest) {
    this.spinner.show();
    this.cargoListService.approvedCargo(cargoApprovalRequest, state).subscribe({
      complete: () => {
        this.spinner.hide();
      },
    })
  }
  /**
  * This method verify if the load could credit to receivable.
  * @param {Cargo[]} cargos (Cargo[]) are the list of loads sended to verify receivables.
  */
  verifyReceivableModifications(cargos: Cargo[]) {
    const dialogConfig = new MatDialogConfig();
    let title = '';
    if (cargos && cargos[0] && cargos[0].message) {
      title = `${cargos[0].message}. ¿Desea hacer el abono a la cartera?`;
    }
    dialogConfig.data = {
      title: title,
      showYesBtn: true,
      showNoBtn: true
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.receivableList(cargos[0].licensePlate);
      }
    });
  }
  /**
  * This method brings the information of the receivables according to the information of the vehicle.
  * @param {string} licensePlate (string) is the license plate of the vehicle that needs to know the receivables associated.
  */
  receivableList(licensePlate: string) {
    let observableVehicleInfo;
    const $observerVehicleInfo = {
      next: (vehicle: Vehicle) => {
        if (vehicle && vehicle.bankAccountBalance && vehicle.bankAccountBalance.document) {
          this.holder = {
            name: vehicle.bankAccountBalance.name,
            document: vehicle.bankAccountBalance.document,
            documentTypeName: vehicle.bankAccountBalance.documentTypeName
          };
          return this.receivableService.getReceivableList(null, null, vehicle.bankAccountBalance.document, null, null, 'list');
        } else {
          this.snackBarService.openSnackBar('No existe un usuario asociado al balance de la cuenta', undefined, "error")
          return of(null);
        }
      },
      error: () => {
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
        return EMPTY;
      },
      finally: () => {
        this.spinner.hide();
        observableVehicleInfo.unsubscribe();
      }
    };
    this.spinner.show();
    const observableVehicle = this.vehiclesService.getVehicle(licensePlate).pipe(switchMap(vehicle => observableVehicleInfo = $observerVehicleInfo.next(vehicle)))
      .subscribe({
        next: (listModifications: ModificationReceivable) => {
          this.spinner.hide();
          if (listModifications) {
            this.openModificationsList(listModifications);
          } else {
            this.snackBarService.openSnackBar("No hay modificaciones para mostrar", undefined, "alert")
          }
        },
        error: (error) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar("Hubo un error trayendo las modificaciones. Vuelva a intentarlo", undefined, "alert")
        },
        complete: () => {
          this.spinner.hide();
          observableVehicle.unsubscribe();
        }
      })
  }
  /**
  * This method open a dialog window that shows to the user the detail of the modifications of a receivable associate to the load and also asks the user if wanted to approve or reject a modification in the receivable.
  * @param {ModificationReceivable} listReceivables (ModificationReceivable) is the list of modifications available to this receivable.
  */
  openModificationsList(listReceivables: ModificationReceivable) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      listReceivables: listReceivables,
      holder: this.holder
    }
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.disableClose = true;
    const dialogRef = this.matDialog.open(ReceivableModificationsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'undefined') {
        this.snackBarService.openSnackBar('Por favor rechace o apruebe la modificacion a la cartera para poder continuar', undefined, 'alert')
      }
    })
  }
  /**
  * This method verifies the state of the approval of the load.
  * @param {Cargo[]} cargos (Cargo[]) are the list of loads sended to approve o reject.
  * @param {boolean} state (boolean) is the approval state could be: approval or rejected.
  */
  verifyCargoApprovalResponse(cargos: Cargo[], state: boolean) {
    const response: VerifyCargoApproval = {
      RNDCError: [],
      generalError: [],
      generalSuccess: [],
    };
    cargos.map((cargo: Cargo) => {
      if (cargo.manifestError && cargo.manifestError.error) {
        response.RNDCError.push({
          title: `Consecutivo: ${cargo.consecutive}`,
          message: cargo.manifestError.error,
        });
      }
      cargo.cargoFeature.uploadDownload.origin.addresses.map(
        (address: AddressCargo, i: number) => {
          if (address.ministryError && address.ministryError.error) {
            response.RNDCError.push({
              title: `Dirección Origen ${i + 1}: ${address.address}`,
              message: address.ministryError.error,
            });
          }
        }
      );
      cargo.cargoFeature.uploadDownload.destination.map(
        (destination: Destination) => {
          destination.addresses.map((address: AddressCargo, i: number) => {
            if (address.ministryError && address.ministryError.error) {
              response.RNDCError.push({
                title: `Dirección Destino ${i + 1}: ${destination.name} - ${address.address
                  }`,
                message: address.ministryError.error,
              });
            }
          });
        }
      );
      if (response.RNDCError.length === 0) {
        if (
          cargo.approval &&
          cargo.approval !== "Approved" &&
          cargo.approval !== "Rejected"
        ) {
          response.generalError.push({
            title: `Consecutivo: ${cargo.consecutive}`,
            message: "El servicio no ha sido aprobado. Ha quedado pendiente por aprobación.",
          });
        } else {
          response.generalSuccess.push({
            title: `Consecutivo: ${cargo.consecutive}`,
            message: `Se ha ${state ? "aprobado" : "rechazado"
              } el servicio correctamente`,
          });
        }
      }
    });
    if (response.RNDCError.length) {
      this.showResponseApproval(false, response.RNDCError);
    } else {
      if (response.generalError.length) {
        this.showResponseApproval(false, response.generalError);
      } else {
        this.showResponseApproval(true, response.generalSuccess);
      }
    }
    if (cargos[0].message && (cargos[0].approval === 'Approved' || cargos[0].approval === 'Rejected')) {
      this.verifyReceivableModifications(cargos);
    }
  }
  /**
  * This method open a dialog window that shows errors related with the approval of the load.
  */
  showResponseApproval(
    stateResponse: boolean,
    messageList: ResponseApproval[]
  ) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Resultado de la operación",
      hideBtnCancel: true,
    };
    if (stateResponse) {
      dialogConfig.data.icon = true;
    } else {
      dialogConfig.data.iconError = true;
    }
    if (messageList.length) {
      dialogConfig.data.messageList = messageList.map(
        (item: ResponseApproval) => {
          return `${item.title} = ${item.message}`;
        }
      );
    }
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.refreshListCargo.emit();
      this.refreshCargo.emit(this.cargo.consecutive);
    });
  }

  /**
  * This method open a dialog window that allows to reverse the approval of a load.
  */
  openModalReturnApproveCargo() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title:
        this.titles.titlenModalReturnApproveCargo +
        this.cargo.consecutive +
        " ?",
    };
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        const data: CargoApprovalRequest = {
          cargos: [{ cargoId: this.cargo.id }],
          userId: this.authService
            .getUserSession()
            .information.document,
          userName: this.authService
            .getUserSession()
            .information.name,
        };
        this.spinner.show();
        this.cargoListService.approvedCargo(data).subscribe(
          (success) => {
            this.spinner.hide();
            this.refreshListCargo.emit();
            this.refreshCargo.emit(this.cargo.consecutive);
            this.snackBarService.openSnackBar(
              "Se ha reversado el estado exitosamente"
            );
          },
          (error) => {
            this.spinner.hide();
            this.snackBarService.openSnackBar(
              `Ocurrió un error al reversar el servicio: ${this.cargo.consecutive}`,
              undefined,
              "error"
            );
          }
        );
      }
    });
  }

  /**
  * This method open a dialog that shows the detail of the load.
  */
  openDetailCargo() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      cargo: this.utils.clone(this.cargo),
    };
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(CargoDetailComponent, dialogConfig);
    const dialogComponent = dialogRef.componentInstance;
    dialogRef.afterClosed().subscribe((result) => {
      if (result === undefined) {
        if (this.type === 'button') {
          this.refreshCargo.emit(this.cargo.consecutive);
        } else {
          this.goToList();
          this.refreshListCargo.emit();
        }

      }
    });
  }
  /**
  * This method open a dialog window that allows the user to change the company of the load.
  */
  openChangeCompany() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      cargo: this.utils.clone(this.cargo),
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(ChangeCompanyComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.snackBarService.openSnackBar(
          "Se ha cambiado la compañía correctamente"
        );
        this.refreshListCargo.emit();
        this.refreshCargo.emit(this.cargo.consecutive);
      } else if (result && result.state === false) {
        this.snackBarService.openSnackBar(
          `Ocurrió un error al cambiar de compañía a este servicio (${this.cargo.consecutive})`,
          undefined,
          "error"
        );
      }
    });
  }

  /**
  * This method open a dialog window that shows the detail of the bonus and discounts of the load.
  */
  openDiscountsAndBonuses() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      cargo: this.cargo,
    };
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(
      DiscountsAndBonusesCargoComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state && result.dataCargo) {
        this.refreshListCargo.emit();
        this.refreshCargo.emit(this.cargo.consecutive);
        const currentPath = this.activatedRoute.snapshot.url.map(segment => segment.path).join('/');
        const containsDetail = currentPath.includes('detail');
        let targetPath: string;
        if (containsDetail) {
          targetPath = `cargo/detail/${this.cargo.consecutive}`;
        } else {
          targetPath = `cargo/tracking/${this.cargo.consecutive}`;
        }
        this.belowSicetac(result.dataCargo, targetPath);
      }
    });
  }

  /**
  * This method open a dialog window that allows the user to load the external manifest of the load.
  */
  openLoadExternalManifest() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Cargar Manifiesto Externo",
      cargo: this.utils.clone(this.cargo),
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(
      ExternalManifestCargoComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.refreshListCargo.emit();
        this.refreshCargo.emit(this.cargo.consecutive);
      }
    });
  }
  /**
  * This method open a dialog window that shows the detail of the external manifest if the load have.
  */
  openExternalManifest() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: this.cargo.manifest,
      src: this.cargo.externalManifest,
      storage: true,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    this.matDialog.open(OpenImgComponent, dialogConfig);
  }

  /**
  * This method brings a pdf taking into account the pdfType could be: order vehicle, manifest, consignment, urban manifest and urban consignment.
  * @param {string} pdfType (string) could be: order vehicle, manifest, consignment, urban manifest and urban consignment.
  */
  openCargoPDF(pdfType: string) {
    this.spinner.show();
    this.cargoTrackingService
      .getCargoPDF(pdfType.replace("_urban", ""), this.cargo.id)
      .subscribe(
        (success: any) => {
          this.spinner.hide();
          const newBlob: Blob = new Blob([success], { type: "application/pdf" });
          if (window.navigator && window.navigator["msSaveOrOpenBlob"]) {
            window.navigator["msSaveOrOpenBlob"](newBlob);
            return;
          }
          this.openModalCargoPDF(pdfType, newBlob);
        },
        (error) => {
          this.refreshListCargo.emit();
          this.refreshCargo.emit(this.cargo.consecutive);
          this.spinner.hide();
          this.snackBarService.openSnackBar(
            `Ocurrió un error al traer el PDF del servicio: ${this.cargo.consecutive}`,
            undefined,
            "error"
          );
        }
      );
  }
  /**
  * This method open a dialog window that render a pdf depends on the pdfType.
  * @param {string} pdfType (string) is the pdfType could be: order vehicle, manifest, consignment, urban manifest and urban consignment.
  * @param {Blob} newBlob (Blob) sends the blob of the resource that want to render.
  */
  openModalCargoPDF(pdfType: string, newBlob: Blob) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: this.pdfTitlePipe.transform(pdfType.replace("_urban", "")),
      src: window.URL.createObjectURL(newBlob),
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    this.matDialog.open(OpenImgComponent, dialogConfig);
  }

  /**
  * This method open a dialog window that shows the detail of the history of payments.
  */
  openHistoryPayments() {
    const config = new MatDialogConfig();
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.width = ModalEnum.MEDIUM_WIDTH;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    config.autoFocus = false;
    config.data = {
      shippingCostToDisplay: this.cargoDetailService.getHistoryPaid(this.cargo),
      cargo: this.cargo,
      typePayment: 'Payments'
    };
    this.matDialog
      .open(CargoShippingCostDialogComponent, config)
      .afterClosed()
      .subscribe(
        () => {
          this.refreshListCargo.emit();
          this.refreshCargo.emit(this.cargo.consecutive);
        }
      );
  }

  get showAssignVehicleBtn(): boolean {
    const permissionSpecialLicensePlate = this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.specialLicensePlate
    );
    const permissionAssignLicensePlate = this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.assignLicensePlate
    );
    if (this.cargo && permissionSpecialLicensePlate && permissionAssignLicensePlate) return true;
    else if (permissionSpecialLicensePlate && this.cargo) return !(!!this.cargo.licensePlate)
    else if (permissionAssignLicensePlate && this.cargo) {
      if (this.cargo.licensePlate) {
        return this.cargo.licensePlate !== "C0N111";
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  get showConfirmLicensePlateBtn(): boolean {
    if (this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.specialLicensePlate) && this.cargo && this.cargo.licensePlate === "C0N111")
      return true;
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.confirmLicensePlate
    ) &&
      this.cargo &&
      this.cargo.licensePlate !== "C0N111"
  }
  /**
  * This method validates if the user could start the load.
  * @returns {boolean} (boolean) returns if whether or not the user is enabled to start the service.
  */
  showStartServiceBtn(): boolean {
    if (
      this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.specialLicensePlate
      ) &&
      this.cargo &&
      this.cargo.licensePlate === "C0N111"
    ) {
      return true;
    } else if (
      this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.startCargo
      ) &&
      this.cargo &&
      this.cargo.licensePlate !== "C0N111"
    ) {
      return true;
    } else {
      return false;
    }
  }
  /**
  * This method open a dialog window that asks the user if wanted to confirm the vehicle.
  */
  openModalConfirmDriver() {
    this.loadVehicle().subscribe({
      complete: () => {
        if ([TRIP_TYPES.NATIONAL, TRIP_TYPES.INTERNATIONAL].includes(this.cargoManager.getTypeCargo(this.cargo)) && !this.hasEscortServicesCompany) {
          if (this.lastInspection && this.lastInspection.id) {
            if (!!this.lastInspection.approval) {
              this.validateReceivables();
            } else {
              this.snackBarService.openSnackBar(
                "Aún no se ha aprobado la Inspección a las unidades de transporte de carga",
                undefined,
                "alert"
              );
            }
          } else {
            const dialogConfig = new MatDialogConfig();
            let who;
            if (this.cargo.assignedVehicleFingerPrint) {
              who = this.cargo.assignedVehicleFingerPrint.userName;
            } else {
              if (this.cargo.numberDocumentCreatorLoad === this.authService.getUserSession().information.document) {
                this.createInspection();
                return;
              }
              who = "Operador que asigno el vehiculo";
            }
            dialogConfig.data = {
              title:
                "No se encontro registrada una inspección para este vehiculo. Debes comunicarte con " +
                who +
                ` para solicitar el registro de la inspección.`,
              hideBtnCancel: true,
            };
            dialogConfig.width = ModalEnum.SMALL_WIDTH;
            dialogConfig.autoFocus = false;
            dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
            dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
            this.matDialog.open(
              DialogComponent,
              dialogConfig
            );
          }
        } else {
          this.validateReceivables();
        }
      },
      error: () => { this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error') }
    });
  }
  /**
  * This method checks the inspection of the load and if this is neccesary for this type of load.
  */
  private createInspection() {
    this.cargoManager.checkInspection(this.cargo, this.authService.getUserSession().information.document, this.cargo.licensePlate)
      .then((state) => {
        if (state) {
          this.openModalConfirmDriver();
        }
      }).catch(() => { });
  }
  /**
 * This method configure the information of a dialog that asks the user if wanted to continue without an inspection.
 * @returns {Promise<any>} (Promise<any>) returns the action of open a dialog.
 */
  confirmContinueWithoutContainerInspection(): Promise<any> {
    const config: OptionsDialog = {
      title: "No hay inspección de contenedor",
      description:
        "No hay ninguna inspección de contenedor para este servicio o no ha sido aprobada, ¿Desea continuar de todas formas?",
    };
    return this.dialog.openDialog(config).then().catch(err => err);
  }
  /**
  * This method open a dialog that asks to the user the confirmation or reject of the driver and the load.
  */
  dialogConfirmDriver() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: this.titles.titleConfirmDriver,
      subtitle: this.pendingReceivables ? this.pendingReceivables : '',
      licen: this.pendingReceivables ? '' : this.cargo.licensePlate,
      idCargo: this.pendingReceivables ? '' : this.cargo.consecutive,
    };

    if (
      this.isPrincipalCompany &&
      this.company && this.company.gpsConfiguration && this.company.gpsConfiguration.monitor && this.company.gpsConfiguration.monitor.active
    ) {
      dialogConfig.data.snackbarMessage =
        'El GPS de este vehículo no está disponible para la trazabilidad automática.';
    }

    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.width = this.pendingReceivables ? ModalEnum.LARGE_WIDTH : ModalEnum.MEDIUM_WIDTH;
    const dialoRef = this.matDialog.open(DialogComponent, dialogConfig);
    dialoRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        const data: ConfirmDriver = {
          cargoId: this.cargo.id,
          fingerprint: {
            userId: this.authService
              .getUserSession()
              .information.document,
            userName: this.authService
              .getUserSession()
              .information.name,
          },
        };
        const approval = result.refuse;
        this.confirmDriver(data, approval);
      }
    });
  }
  /**
  * This method allows to confirm the license plate and driver assigned to the load in the scheduled loads.
  * @param {ConfirmDriver} data (ConfirmDriver) is the data related with the user that confirm the assignation and the information of the load.
  * @param {string} approval (string) is the response of the user could be true false or a custom response.
  */
  confirmDriver(data: ConfirmDriver, approval: string) {
    this.spinner.show();
    this.cargoItemService.confirmDrive(data, approval).subscribe(
      (success: Cargo) => {
        this.spinner.hide();
        this.belowSicetac(this.cargo, `cargo/detail/${this.cargo.consecutive}`);
        this.refreshListCargo.emit();
        this.refreshCargo.emit(this.cargo.consecutive);
        if (!this.utils.isEmpty(success.message)) {
          this.dialog.openDialog({
            title: success.message,
            iconError: true,
            hideBtnConfirm: true,
          }).then().catch(err => err);
        } else {
          this.snackBarService.openSnackBar("Placa confirmada correctamente");
        }
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
        if (
          !this.utils.isEmpty(error) &&
          !this.utils.isEmpty(error.error) &&
          !this.utils.isEmpty(error.error.message)
        ) {
          this.snackBarService.openSnackBar(
            error.error.message,
            undefined,
            "alert"
          );
        } else {
          this.snackBarService.openSnackBar(
            "Ocurrió un error al confirmar la placa",
            undefined,
            "error"
          );
        }
      }
    );
  }
  /**
  * This method consumes the service that verifies if the owner of the vehicle have active receivables and sended the alert to the confirm driver method.
  */
  validateReceivables() {
    const observerListReceivables = {
      next: (data) => {
        if (data && data.some(receivable => receivable.state && receivable.state.description && receivable.state.description === 'pending')) {
          this.pendingReceivables = `El tenedor de este vehiculo ( ${this.vehicle.bankAccountBalance.name} ) tiene carteras pendientes. ¿Está seguro que desea confirmar la placa ${this.cargo.licensePlate} como vehiculo para el servicio ${this.cargo.consecutive}?`;
        } else {
          this.pendingReceivables = "";
        }
        this.spinner.hide();
      },
      error: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, "error");
      },
      complete: () => {
        this.dialogConfirmDriver();
        this.spinner.hide();
      }
    };
    if (this.vehicle && this.vehicle.bankAccountBalance && this.vehicle.bankAccountBalance.document) {
      this.spinner.show();
      this.receivableService.getReceivableList(null, null, this.vehicle.bankAccountBalance.document).subscribe(observerListReceivables);
    } else {
      this.pendingReceivables = "";
      this.dialogConfirmDriver();
    }
  }
  /**
  * This method validates if the user could start the service.
  */
  async startServiceDialog() {
    const dateLoad = DateManager.setStartOfDay(DateManager.stringToDate(this.cargo.dateLoad));
    const today = DateManager.setStartOfDay(new Date());
    let daysUntilLoad = DateManager.dateDiff(dateLoad, null, today);
    if (daysUntilLoad < 0) {
      //Fecha de cargue previa al día actual
      this.snackBarService.openSnackBar(
        ` Es demasiado tarde para iniciar el servicio, la fecha de cargue fue:
          ${DateManager.getCalendar(this.cargo.dateLoad)}.`,
        undefined,
        "error"
      );
    }
    else if (daysUntilLoad === 0) {
      // Iniciar
      if (
        this.companyManager.getOnlyDriverByCompanyNit(this.cargo.idCompany)
      ) {
        this.getDetailDriver();
      } else {
        this.dialog.openDialog({
          title:
            "Upss esta compañia esta restringida para simular las accciones del Conductor",
          iconError: true,
          hideBtnConfirm: true,
        }).then().catch(err => err);
      }
    } else {
      // Aún no se puede iniciar por que no ha llegado el día de cargue
      const result = await this.dialog.openDialog({
        title: `
          Es demasiado pronto para iniciar el servicio, la fecha de llegada al origen es a partir de:
          ${DateManager.getCalendar(this.cargo.dateLoad)}.
          ¿Desea moverla al día de hoy?`,
        iconError: true,
        hideBtnConfirm: true,
        hideBtnCancel: true,
        showYesBtn: true,
        showNoBtn: true,
      }).then().catch(err => err);
      if (result.state) {
        // Change cargo Date
        if (
          this.permissionRole.hasPermission(
            Permission.cargo.module,
            Permission.cargo.changeCargoLoadDate
          )
        ) {
          this.cargo.dateLoad = DateManager.dateToString(today);
          this.cargoService.updateFields(this.cargo).subscribe(
            () => {
              this.startServiceDialog();
            }
          );
        } else {
          this.snackBarService.openSnackBar(
            "No cuentas con los permisos necesarios",
            undefined,
            "error"
          );
        }
      }
    }

  }

  async checkConfirmStartService() {
    if (!this.cargo || !this.cargo.licensePlate) {
      this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error')
      return;
    }
    if (this.permissionRole.hasPermission(this.permission.administration.module, this.permission.administration.alertValidGps)) {
      const validGps = await this.validateVehicleGPS();
      if (!validGps) return;
    }
    let matchLoads = await this.getLoadingRoutesByParameters('licensePlate', this.cargo.licensePlate);
    if (!matchLoads || !matchLoads.length) {
      this.confirmStartService();
      return;
    }
    let enableSimultaneousInrouteLoading = this.permissionRole.hasPermission(Permission.cargo.module, Permission.cargo.enableSimultaneousInrouteLoading);
    if (enableSimultaneousInrouteLoading)
      this.dialogMessage(`¿Estas seguro que deseas iniciar el servicio con el vehículo ${this.cargo.licensePlate}?`, `<p class="ml-4"><i class="text-strong text-warning fas fa-exclamation-triangle mr-2"></i>Recuerda que este vehículo tiene ${matchLoads.length} servicios en curso.</p>`, false, false, true, this.confirmStartService.bind(this));
    else
      this.dialogMessage('No es posible iniciar el servicio porque el vehículo asignado ya tiene un servicio en curso', `<p class="ml-4">Por favor vuelva a intentarlo cuando el vehículo ${this.cargo.licensePlate} finalice la ruta.</p>`, true, true);

  }
  /**
  * This method allows to confirm the start of the load taking into account different conditions.
  */
  confirmStartService() {
    if (this.allowCreatingWithoutTruora || (FIELDS_SECURITY_STUDY[Roles.DRIVER] && !FIELDS_SECURITY_STUDY[Roles.DRIVER].includes('truora')))
      this.startServiceDialog();
    else if (FIELDS_STATE[Roles.DRIVER] && (FIELDS_STATE[Roles.DRIVER].includes('arl') || FIELDS_STATE[Roles.DRIVER].includes('eps')))
      this.validateArlAndEPS();
    else if (FIELDS_STATE[Roles.DRIVER] && FIELDS_STATE[Roles.DRIVER].includes('operationalAccreditation'))
      this.validateAdditionalCertifications();
    else this.validateSOATandRTM();
  }

  /**
 * This method consumes a service that bring the loads in route sending one parameter.
 */
  async getLoadingRoutesByParameters(parameter: string, value: string, pagekey: number = 1, pageSize: number = 10): Promise<Cargo[]> {
    try {
      this.spinner.show();
      let data: Cargo[] = await this.cargoListService.getListCargo(`&${parameter}=${value}&state=Start service`, pagekey, pageSize).toPromise();
      return data;
    } catch (error) {
      return [];
    } finally {
      this.spinner.hide();
    }
  }

  /**
 * This method checks if the vehicle's gps is valid to proceed
 */
  async validateVehicleGPS(): Promise<boolean> {
    this.spinner.show();
    const currentVehicle = await this.vehiclesService.getVehicle(this.cargo.licensePlate).toPromise();
    this.spinner.hide();
    if (!currentVehicle || !currentVehicle.id) {
      this.snackBarService.openSnackBar("No fue posible acceder al vehículo del servicio", undefined, 'error');
      return false;
    }
    if (this.vehicleManager.isValidGps(currentVehicle))
      return true;

    const form = new FormGroup({
      gpsType: new FormControl('', Validators.required),
      userGps: new FormControl(),
      passwordGps: new FormControl(),
      isPortableUnit: new FormControl(),
      gpsId: new FormControl(),
    });
    form.patchValue(currentVehicle.gps);
    form.get("isPortableUnit").setValue(!!(currentVehicle.gps && currentVehicle.gps.isPortableUnit));
    let valid: boolean = await this.vehicleManager.openGpsCargo(currentVehicle, form, this.cargo);
    if (valid) return true;
    this.snackBarService.openSnackBar("Se requiere un GPS válido para iniciar el servicio", undefined, 'error');
    return false;
  }
  /**
  * This method opens a dialog that show a message to the user could be a error or a confirm message.
  */
  dialogMessage(message: string, descriptionHTML?: string, iconError: boolean = false, hideBtnConfirm: boolean = false, confirmAction: boolean = false, confirmActionMethod?: () => void) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: message,
      descriptionHTML,
      iconError,
      hideBtnConfirm
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
    if (confirmAction) {
      dialogRef.afterClosed().subscribe((result) => {
        if (result && result.state) confirmActionMethod();
      });
    }
  }

  async validateArlAndEPS() {
    const notifications = [];
    try {
      const expiredDocuments: ExpiredDocuments[] | string = await this.getStateDriver(this.cargo.driver);
      if (typeof expiredDocuments === 'string') {
        if (expiredDocuments === UserMessages.DOCUMENT_NO_EXPIRATION)
          return this.snackBarService.openSnackBar(expiredDocuments, undefined, 'error');
        else
          return this.validateAdditionalCertifications();
      }
      expiredDocuments.forEach(document => {
        if (document && document.novelty && document.type && FIELDS_STATE[Roles.DRIVER] && FIELDS_STATE[Roles.DRIVER].includes(document.type.toLowerCase())) {
          if (document.novelty === 'outDated') notifications.push(Fmt.string(UserMessages.DOCUMENT_INSUFFICIENT_VALIDITY, document.type));
          else if (document.novelty === 'expired') notifications.push(Fmt.string(UserMessages.DOCUMENT_EXPIRATED, document.type));
          else if (document.novelty === 'approvalByMissed') notifications.push(Fmt.string(UserMessages.DOCUMENT_PENDING_APPROVAL, document.type));
          else if (document.novelty === 'securityStudyInProgress') notifications.push(Fmt.string(UserMessages.DOCUMENT_SECURITY_STUDY_IN_PROGRESS, document.type));
        }
      });
      if (!notifications.length)
        return this.validateAdditionalCertifications();

      let descriptionHTML: string = `
          El conductor del vehículo ${this.cargo.licensePlate} presenta alguna novedad en la documentación obligatoria: <br>
      <ol>${notifications.map(notification => `<li><i class="text-strong text-warning fas fa-exclamation-triangle"></i>${notification}</li>`).join('')}</ol><br>
        `;
      let allowStartService = expiredDocuments.every(document => {
        return (document && document.type === 'ARL' && this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.startServiceWithArlExpired))
          || (document && document.type === 'EPS' && this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.startServiceWithEpsExpired))
      })
      if (allowStartService) descriptionHTML = descriptionHTML + '<p class="text-center title-component" style="color:#495356">¿Desea continuar?</p>';
      this.newsOnMandatoryDocuments('Novedades en documentos del conductor', allowStartService, descriptionHTML, this.validateAdditionalCertifications.bind(this));

    } catch (error) {
      this.validateAdditionalCertifications();
    }
  }
  async validateAdditionalCertifications() {
    const notifications = [];
    const requiredCertifications: AdditionalCertificationEnum[] = [];

    if (FIELDS_STATE[Roles.DRIVER] && FIELDS_STATE[Roles.DRIVER].includes('operationalAccreditation'))
      requiredCertifications.push(AdditionalCertificationEnum.OPERATIONAL_ACCREDITATION);

    if (!requiredCertifications.length)
      return this.validateSOATandRTM();

    let userCertifications: AdditionalCertification[] = [];
    try {
      userCertifications = await this.adminUserService.getUserAdditionalCertifications(this.cargo.driver).toPromise();
    } catch (e) {
      userCertifications = [];
    }
    if (userCertifications && userCertifications.length)
      requiredCertifications.forEach(type => {
        const certification = userCertifications.find(cert => cert && cert.id === type);
        if (!certification)
          notifications.push(`${UserMessages.DOCUMENT_INACTIVE}, Se requiere el certificado de ${AdditionalCertificationDict[type]}`);
        else if (this.adminUserService.isExpiredAdditionalCertification(certification))
          notifications.push(Fmt.string(UserMessages.DOCUMENT_EXPIRATED, `certificación de ${AdditionalCertificationDict[type]}`));
        else if (!certification.approvalBy || !certification.approvalBy.userId)
          notifications.push(Fmt.string(UserMessages.DOCUMENT_PENDING_APPROVAL, `certificación de ${AdditionalCertificationDict[type]}`));
      })
    else
      notifications.push(`${UserMessages.DOCUMENT_INACTIVE}, Se requieren los certificados de ${requiredCertifications.map(cert => AdditionalCertificationDict[cert]).join(', ')}`);

    if (!notifications.length) {
      this.validateSOATandRTM();
      return;
    }
    let descriptionHTML: string = `
      El ${this.hasEscortServicesCompany ? 'escolta con vehículo' : 'conductor del vehículo'} ${this.cargo.licensePlate} presenta alguna novedad en las certificaciones adicionales obligatorias: <br>
      <ol>${notifications.map(notification => `<li><i class="text-strong text-warning fas fa-exclamation-triangle"></i>${notification}</li>`).join('')}</ol><br>`;
    let allowStartService = this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.startServiceWithAdditionalCertificationsInvalid);
    if (allowStartService) descriptionHTML = descriptionHTML + '<p class="text-center title-component" style="color:#495356">¿Desea continuar?</p>';
    this.newsOnMandatoryDocuments('Novedades en certificados adicionales', allowStartService, descriptionHTML, this.validateSOATandRTM.bind(this));
  }

  async validateSOATandRTM() {
    let lastTravelPointDate = new Date();
    for (const i in this.cargo.cargoFeature.uploadDownload.destination) {
      const destination = this.cargo.cargoFeature.uploadDownload.destination[i];
      if (
        this.utils.isDefined(destination.downloadDate) &&
        typeof destination.downloadDate === "string" &&
        destination.downloadDate.length > 9
      ) {
        const arrival = DateManager.stringToDate(destination.downloadDate);
        if (DateManager.isBefore(lastTravelPointDate, arrival, 'days')) lastTravelPointDate = arrival;
      } else {
        this.snackBarService.openSnackBar(
          `No hay registrada fecha de llegada al destino #${parseInt(i) + 1
          }`,
          undefined,
          "error"
        );
      }
    }
    let cargoCompany: Company = this.cargo.holderCompany
      ? await this.accountService.validateEntity(2, this.cargo.holderCompany).toPromise()
      : this.authService.getUserSession() && this.authService.getUserSession().clientCompanyId
        ? await this.accountService.validateEntity(2, this.authService.getUserSession().clientCompanyId).toPromise()
        : null;
    const $validateExpirationRTMSOAT =
      this.vehiclesService.validateExpirationRTMSOAT(
        this.cargo.licensePlate,
        lastTravelPointDate,
        cargoCompany
      );
    const notifications = [];
    $validateExpirationRTMSOAT.subscribe({
      next: (result: VehicleMessages) => {
        notifications.push(result);
      },
      complete: () => {
        const permissionSpecialLicensePlate = this.permissionRole.hasPermission(
          this.permission.cargo.module,
          this.permission.cargo.specialLicensePlate
        );
        if (
          (((notifications.includes(VehicleMessages.SOAT_MORE_N_DAYS) || notifications.includes(VehicleMessages.SOAT_VALID)) &&
            (notifications.includes(VehicleMessages.RTM_MORE_N_DAYS) || notifications.includes(VehicleMessages.RTM_EXONERATED) || notifications.includes(VehicleMessages.RTM_VALID))) ||
            (permissionSpecialLicensePlate && this.cargo.licensePlate === "C0N111") || this.allowCreatingWithoutTruora)
        ) {
          this.startServiceDialog();
        } else {
          let notificationsHtml = ``;

          const iconsStates = {
            danger: [
              VehicleMessages.RTM_NO_INFORMATION,
              VehicleMessages.RTM_NO_EXPIRATION,
              VehicleMessages.RTM_LESS_BLOCK_N_DAYS,
              VehicleMessages.RTM_EXPIRATED,

              VehicleMessages.SOAT_NO_INFORMATION,
              VehicleMessages.SOAT_NO_EXPIRATION,
              VehicleMessages.SOAT_LESS_BLOCK_N_DAYS,
              VehicleMessages.SOAT_EXPIRATED,
            ],
            warning: [
              VehicleMessages.RTM_LESS_ALERT_N_DAYS,
              VehicleMessages.RTM_POSSIBLE_EXONERATED,

              VehicleMessages.SOAT_LESS_ALERT_N_DAYS,
            ],
            success: [
              VehicleMessages.RTM_MORE_N_DAYS,
              VehicleMessages.RTM_VALID,
              VehicleMessages.RTM_EXONERATED,

              VehicleMessages.SOAT_MORE_N_DAYS,
              VehicleMessages.SOAT_VALID,
            ],
          };

          const RtmSoatMessagesMap = {
            [VehicleMessages.RTM_MORE_N_DAYS]: Fmt.string(
              VehicleMessages.RTM_MORE_N_DAYS, cargoCompany && cargoCompany.daysToAlertExpirationRTM ? cargoCompany.daysToAlertExpirationRTM : VehicleMessages.DAYS_TO_ALERT_EXPIRATION_DEFAULT
            ),
            [VehicleMessages.RTM_LESS_ALERT_N_DAYS]: Fmt.string(
              VehicleMessages.RTM_LESS_ALERT_N_DAYS, cargoCompany && cargoCompany.daysToAlertExpirationRTM ? cargoCompany.daysToAlertExpirationRTM : VehicleMessages.DAYS_TO_ALERT_EXPIRATION_DEFAULT
            ),
            [VehicleMessages.RTM_LESS_BLOCK_N_DAYS]: Fmt.string(
              VehicleMessages.RTM_LESS_ALERT_N_DAYS, cargoCompany && cargoCompany.daysToBlockRTM ? cargoCompany.daysToBlockRTM : VehicleMessages.DAYS_TO_BLOCK_DEFAULT
            ),
            [VehicleMessages.SOAT_MORE_N_DAYS]: Fmt.string(
              VehicleMessages.SOAT_MORE_N_DAYS, cargoCompany && cargoCompany.daysToAlertExpirationSOAT ? cargoCompany.daysToAlertExpirationSOAT : VehicleMessages.DAYS_TO_ALERT_EXPIRATION_DEFAULT
            ),
            [VehicleMessages.SOAT_LESS_ALERT_N_DAYS]: Fmt.string(
              VehicleMessages.SOAT_LESS_ALERT_N_DAYS, cargoCompany && cargoCompany.daysToAlertExpirationSOAT ? cargoCompany.daysToAlertExpirationSOAT : VehicleMessages.DAYS_TO_ALERT_EXPIRATION_DEFAULT
            ),
            [VehicleMessages.SOAT_LESS_BLOCK_N_DAYS]: Fmt.string(
              VehicleMessages.SOAT_LESS_ALERT_N_DAYS, cargoCompany && cargoCompany.daysToBlockSOAT ? cargoCompany.daysToBlockSOAT : VehicleMessages.DAYS_TO_BLOCK_DEFAULT
            )
          }

          for (const i in notifications) {
            notificationsHtml = `${notificationsHtml}
              <li>
                ${iconsStates.danger.includes(notifications[i]) ? `<i class="text-strong text-danger fas fa-exclamation-circle"></i>` : ""}
                ${iconsStates.warning.includes(notifications[i]) ? `<i class="text-strong text-warning fas fa-exclamation-triangle"></i>` : ""}
                ${iconsStates.success.includes(notifications[i]) ? `<i class="text-strong text-success fas fa-check-circle"></i>` : ""}
                ${RtmSoatMessagesMap[notifications[i]] ? RtmSoatMessagesMap[notifications[i]] : notifications[i]}
              </li>`;
          }
          let descriptionHTML: string = `
            El vehículo con placas ${this.cargo.licensePlate} presenta alguna novedad en la documentación obligatoria: <br>
              <ol>${notificationsHtml}</ol> <br>
            `;
          let allowStartService = false;
          let error = '';

          if (notifications.includes(VehicleMessages.SOAT_EXPIRATED) || notifications.includes(VehicleMessages.RTM_EXPIRATED)) {
            error = "Un vehículo sin documentación obligatoria no puede circular";
            if (this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.useCarWithExpiredDocuments)) allowStartService = true;
          } else if (notifications.includes(VehicleMessages.SOAT_LESS_BLOCK_N_DAYS) || notifications.includes(VehicleMessages.RTM_LESS_BLOCK_N_DAYS) ||
            notifications.includes(VehicleMessages.RTM_POSSIBLE_EXONERATED)) {
            if (this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.useCarNearExpirationDocuments)) {
              allowStartService = true;
            } else if (notifications.includes(VehicleMessages.RTM_POSSIBLE_EXONERATED)) {
              error = "El vehículo está en su último año de exoneración de RTM, Solicite la asignación a seguridad";
            } else if (notifications.includes(VehicleMessages.SOAT_LESS_BLOCK_N_DAYS)) {
              error = `El SOAT del vehículo tiene vigencia inferior a ${cargoCompany && cargoCompany.daysToBlockSOAT ? cargoCompany.daysToBlockSOAT : VehicleMessages.DAYS_TO_BLOCK_DEFAULT} días, solicite la asignación a seguridad`;
            } else {
              error = `La RTM del vehículo tiene vigencia inferior a ${cargoCompany && cargoCompany.daysToBlockRTM ? cargoCompany.daysToBlockRTM : VehicleMessages.DAYS_TO_BLOCK_DEFAULT} días, solicite la asignación a seguridad`;
            }
          } else if (notifications.includes(VehicleMessages.SOAT_LESS_ALERT_N_DAYS) || notifications.includes(VehicleMessages.RTM_LESS_ALERT_N_DAYS)) {
            error = "Los documentos del vehículo están próximos a vencer";
            allowStartService = true;
          } else {
            error = "Ha ocurrido un error con la validación de documentos obligatorios";
          }
          if (error) {
            descriptionHTML = descriptionHTML + `<div class="alert alert-${allowStartService ? 'warning' : 'danger'} mt-2 d-flex flex-row align-items-center justify-content-center" role="alert"><i class="fas fa-exclamation-triangle fa-lg mr-3"></i> <span class="text-bold">${error}</span></div>`
          }
          if (allowStartService) {
            descriptionHTML = descriptionHTML + '<p class="text-center title-component">¿Desea continuar?</p>';
          }
          this.newsOnMandatoryDocuments('Novedades en documentos obligatorios', allowStartService, descriptionHTML, this.startService.bind(this));
        }
      },
    });
  }
  /**
  * This method checkif the vehicle of the user have news in the mandatory documents.
  */
  newsOnMandatoryDocuments(title: string, allowStartService: boolean, descriptionHTML: string, funct: Function) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title,
      descriptionHTML,
    };
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    if (!allowStartService) {
      dialogConfig.data['hideBtnConfirm'] = true;
    }
    this.matDialog.open(DialogComponent, dialogConfig).afterClosed().subscribe((result) => {
      if (result && result.state && allowStartService)
        funct();
    });
  }

  /**
  * This method check the state of arl and eps of the driver
  */
  async getStateDriver(document: string): Promise<ExpiredDocuments[] | string> {
    return new Promise((resolve, reject) => {
      const userObserver = {
        next: (user: User) => {
          this.spinner.hide();
          let expiredDocuments: ExpiredDocuments[] = [];
          let types = ['ARL', 'EPS'];
          if (user && user.extraDocuments && user.extraDocuments.length && user.extraDocuments.some(document => types.includes(document.type))) {
            const currentDate = new Date();
            const estimatedDate = DateManager.add(currentDate, this.cargo.estimatedTime, "seconds");
            const lastAddress = DateManager.stringToDate(this.cargo.cargoFeature.uploadDownload.destination[this.cargo.cargoFeature.uploadDownload.destination.length - 1].downloadDate);
            expiredDocuments = user.extraDocuments.filter(document => {
              if (document.type && document.dueDate && types.includes(document.type)) {
                let docState = this.adminUserService[`get${document.type.toLowerCase()}State`](user);
                if (docState === 'Activo') return false;
                if (docState === 'Estudio de seguridad en proceso') {
                  document.novelty = 'securityStudyInProgress';
                  return true;
                }
                const dueDate = DateManager.stringToDate(document.dueDate);
                if (!document.approvalBy) {
                  document.novelty = 'approvalByMissed';
                  return true;
                }
                else if (DateManager.isBefore(dueDate, currentDate)) {
                  document.novelty = 'expired';
                  return true;
                }
                else if (DateManager.isBefore(dueDate, estimatedDate) || DateManager.isBefore(dueDate, lastAddress)) {
                  document.novelty = 'outDated';
                  return true;
                }
              }
              return false;
            });
          }

          if (user && types.some(type => user[type.toLowerCase()] && !user[type.toLowerCase()].active)) {
            types.forEach(type => {
              if (user[type.toLowerCase()] && !user[type.toLowerCase()].active && expiredDocuments && !expiredDocuments.some(document => document.type === type)) expiredDocuments.push({ type, novelty: 'expired' })
            })
          }
          if (expiredDocuments && expiredDocuments.length) resolve(expiredDocuments);
          else if (this.adminUserService.getTruoraInProgress(user)) {
            expiredDocuments.push({ type: 'ARL', novelty: 'securityStudyInProgress' });
            expiredDocuments.push({ type: 'EPS', novelty: 'securityStudyInProgress' });
            resolve(expiredDocuments);
          }
          else reject(UserMessages.DOCUMENT_ACTIVE);
        },
        error: () => {
          this.spinner.hide();
          reject(UserMessages.DOCUMENT_NO_EXPIRATION);
        }
      };
      this.spinner.show();
      this.adminUserService.getUsersDetailByDocument(document).subscribe(userObserver);
    });
  }
  /**
  * This method evaluates the difference of days between the load and today. could be a past date or a future date
  * @param {boolean} onlyValue (boolean) verification to send only the value of the difference or a message.
  * @returns {string | number} (string | number) returns the difference in days or a message.
  */
  diferenceInDays(onlyValue?: boolean): (string | number) {
    let value: any = "";
    try {
      const diff = DateManager.dateDiff(DateManager.transformDateUFC(this.cargo.dateLoad));
      value = !onlyValue
        ? diff < 0
          ? "No puedes iniciar el servicio, la fecha de cargue ya pasó, fue el " +
          this.dateFormatPipe.transform(this.cargo.dateLoad, "only-date")
          : "Faltan " +
          diff +
          (diff === 1 ? " día" : " días") +
          " para iniciar el servicio, la fecha de cargue es: " +
          this.dateFormatPipe.transform(this.cargo.dateLoad, "only-date")
        : diff;
    } catch (e) {
      value = !onlyValue
        ? "No puedes iniciar el servicio, la fecha de cargue es: " +
        this.dateFormatPipe.transform(this.cargo.dateLoad, "only-date")
        : 0;
    }
    return value;
  }

  /**
  * This method validates if the container inspection is mandatory in one hand and in the other hand only if is mandatory if this inspection is corretly validated.
  */
  confirmContainerInspection() {
    const containerInspection =
      !!this.lastContainerInspection &&
      !!this.lastContainerInspection.id &&
      !!this.lastContainerInspection.approval;

    if (!!this.vehicle.trailerId) {
      this.vehiclesService
        .getTrailerList(`?id=${this.vehicle.trailerId}`)
        .subscribe((trailer: any[]) => {
          if (!!trailer && trailer.length > 0) {
            trailer = trailer[0];
            /*const portators = [
              "PLATAFORMA",
              "PLANCHON",
              "ESTACAS",
              "SRS",
              "NIÑERA",
              "CAMABAJA",
              "PORTA CONTENEDOR",
              "PLATAFORMA CON ESTACAS DESMONTABLES",
              "MODULAR",
            ];*/

            if (!!this.cargo.container) {
              // Requiere inspección
              containerInspection
                ? this.startService()
                : this.snackBarService.openSnackBar(
                  "Debe realizar y aprobar la Inspección a las unidades de carga",
                  undefined,
                  "error"
                );
            } else {
              // No es obligatoria o no necesita inspección
              if (trailer) {
                // El VH puede portar un contenedor
                containerInspection
                  ? this.startService()
                  : this.confirmContinueWithoutContainerInspection().then(
                    () => this.startService(),
                    () => {
                      this.router.navigate(["cargo/detail/", this.cargo.consecutive.toString()]);
                      this.snackBarService.openSnackBar(
                        "Redireccionado para crear la Inspección a las unidades de carga",
                        undefined,
                        "alert"
                      );
                    }
                  );
              } else this.startService();
            }
          } else {
            if (!!this.cargo.container) {
              this.snackBarService.openSnackBar(
                "El vehículo no tiene trailer y el servicio es portada en contenedor",
                undefined,
                "error"
              );
            } else this.startService();
          }
        });
    } else if (!!this.cargo.container) {
      containerInspection ?
        this.startService() :
        this.snackBarService.openSnackBar(
          "Debe realizar y aprobar la Inspección a las unidades de carga",
          undefined,
          "error"
        );
    } else this.startService();
  }
  /**
  * This method consumes the service to set a state tracking in this case the start service state tracking.
  */
  startService() {
    // Preguntar si se requiere registrar vehículo en monitor.
    if (CargoOptionsComponent.GpsProviders === null) {
      CargoOptionsComponent.GpsProviders = {};

      this.cargoOptionsService.getGPSProviders().subscribe(
        (gpsProviders: Array<{ monitor: boolean, name: string, companyId: string, id: string }>) => {
          // Por seguridad salimos de las llamadas http setteando GpsProviders
          if (!!gpsProviders && Array.isArray(gpsProviders)) {
            gpsProviders.forEach(
              (gps) => {
                if (!environment.ignoredGPSByMonitor.includes(gps.name))
                  CargoOptionsComponent.GpsProviders[gps.name] = gps;
              }
            );

            this.checkMonitorOrStartService();
          }
        },
        (err) => {
          // Por seguridad salimos de las llamadas http setteando GpsProviders
          this.snackBarService
            .openSnackBar(
              "Ocurrió un error cargando los proveedores GPS habilitados",
              "x",
              "error"
            );
        }
      );
    } else {
      this.checkMonitorOrStartService();
    }
  }

  checkMonitorOrStartService() {
    this
      .askForMonitorIfNecesary()
      .subscribe({
        next: () => {
          const data = {
            idCargo: this.cargo.id,
          };
          this.spinner.show();
          this.cargoEvidenceService
            .setStateAddressTracking(data, CargoStateEnum.START_SERVICE)
            .subscribe(
              () => {
                this.router.navigate(["cargo/tracking/", this.cargo.consecutive.toString()]);
                this.spinner.hide();
              },
              () => {
                this.spinner.hide();
              }
            );
        },
        error: (_error) => {
          this.spinner.hide();
          console.error(_error);
        },
      });
  }

  getGpsProvider(gps: Gps) {
    const name = gps && gps.gpsType ? gps.gpsType.toUpperCase() : null;
    return name ? CargoOptionsComponent.GpsProviders[name] : null;
  }

  askForMonitorIfNecesary() {
    return new Observable<boolean>(
      observer => {
        const company = this.authService.getCompany();
        if (this.vehicle && this.vehicle.gps && this.getGpsProvider(this.vehicle.gps)
          && this.getGpsProvider(this.vehicle.gps).monitor) {
          if (
            company &&
            company.gpsConfiguration &&
            company.gpsConfiguration.monitor &&
            company.gpsConfiguration.monitor.active
          ) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.data = {
              title: `¿Deseas realizar la trazabilidad automática con GPS del vehículo ${this.vehicle.id}?`,
              description: `Recuerde que este servicio tiene costo adicional.`,
              showYesBtn: true,
              showNoBtn: true,
              hideBtnCancel: true
            };
            dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
            dialogConfig.autoFocus = false;
            dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
            dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
            const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
            dialogRef.afterClosed().subscribe((result) => {
              if (result === undefined)
                return;

              const must_register_vehicle = result && result.state;
              if (must_register_vehicle) {
                const dialogConfig = new MatDialogConfig();
                dialogConfig.data = { licensePlate: this.vehicle.id, startDate: (new Date()).toISOString() };
                dialogConfig.width = ModalEnum.LARGE_WIDTH;
                dialogConfig.autoFocus = false;
                dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
                dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
                dialogConfig.disableClose = true;
                const dialogRef = this.matDialog.open(RequestDatesToMonitorComponent, dialogConfig);
                dialogRef
                  .afterClosed()
                  .subscribe(
                    (result: { state: 'goBack' | 'confirm', dates: { start: string | null, end: string | null } }) => {
                      if (result.state === 'goBack') {
                        this.startService();
                        observer.complete();
                      } else {
                        this.cargoService
                          .registerVehicleInMonitor(this.cargo.id, result.dates.start, result.dates.end)
                          .subscribe({
                            next: (response) => {
                              this.snackBarService.openSnackBar("Vehículo registrado correctamente", "Aceptar", "success");
                              observer.next(true);
                            },
                            error:
                              observer.error,
                            complete:
                              observer.complete
                          });
                      }
                    },
                    observer.error
                  );
              } else {
                observer.next(false);
                observer.complete();
              }
            });
          } else {
            // Tiene monitor deshabilitado pero el soporta el GPS
            if (this.permissionRole.hasPermission(Permission.administration.module, Permission.administration.setupMonitorIntegration)) {
              const dialogConfig = new MatDialogConfig();
              dialogConfig.data = {
                title: `Configurar trazabilidad`,
                description: `
                  Puede activar la trazabilidad GPS automática
                  para mejorar la seguridad de este servicio,
                  recuerde que tiene costo adicional por uso.
                  ¿Quiere configurarla ahora?`,
                showYesBtn: true,
                showNoBtn: true,
                hideBtnCancel: true
              };
              dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
              dialogConfig.autoFocus = false;
              dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
              dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
              const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
              dialogRef.afterClosed().subscribe(
                (result) => {
                  if (result && result.state) {
                    observer.error();
                    observer.complete();
                    this.router.navigate(['administration/companies/detail', company.companyId]);
                  } else {
                    observer.next(false);
                    observer.complete();
                  }

                }
              );
            } else {
              observer.next(false);
              observer.complete();
            }
          }
        } else {
          observer.next(false);
          observer.complete();
        }
      }
    );
  }
  /**
  * This method set the state tracking of the first address in this case de first address of the origin.
  */
  setStatusFirstAddress() {
    const data = {
      idCargo: this.cargo.id,
      addressId: this.cargo.cargoFeature.uploadDownload.origin.addresses[0].id,
      address:
        this.cargo.cargoFeature.uploadDownload.origin.addresses[0].address,
      type: "Origin",
    };

    this.cargoEvidenceService
      .setStateAddressTracking(data, "Arrived to pickup cargo")
      .subscribe(
        (success) => {
          //this.spinner.hide();
          //this.router.navigate(['cargo/tracking/', this.cargo.id]);
        },
        (error) => {
          //this.spinner.hide();
        }
      );
  }

  /*private validateLicenseExpirationDate(driver): boolean {
    let isExpired: boolean = false;
    if (driver.driverLicenseCategory) {
      driver.driverLicenseCategory.map((data) => {
        let diff = this.dateManager.dateDiff(new Date(),null,data.expirationDate,null,'minutes');
        if (diff >= 0 && !isExpired) {
          isExpired = true;
        }
      });
    } else {
      isExpired = true;
    }
    return isExpired;
  }*/
  /**
  * This method brings the information of the driver.
  */
  getDetailDriver() {
    this.accountService.validateEntity(1, this.cargo.driver).subscribe(
      async (data: User) => {
        if (this.utils.isDefined(data)) {
          this.driver = this.userManager.sanitizeUser(data, Roles.DRIVER);
          try {
            const licenseActive: { active: boolean; category?: string } =
              FIELDS_STATE[Roles.DRIVER] && FIELDS_STATE[Roles.DRIVER].includes('driverLicense')
                ? this.hasEscortServicesCompany
                  ? await this.licenseDriverManager.licenseActiveForVehicleType(
                    this.driver.driverLicenseCategory,
                    this.utils.getNestedValue(this.cargo, 'cargoFeature.vehicleType.name'),
                    this.allowCreatingWithoutTruora
                  )
                  : await this.licenseDriverManager.licenseActiveForTripType(
                    this.driver.driverLicenseCategory,
                    this.cargoManager.getTypeCargo(this.cargo),
                    this.allowCreatingWithoutTruora
                  ) : { active: true };
            if (licenseActive) {
              if (licenseActive.active) this.getDetailVehicle();
              else if (licenseActive.category) this.showErrorByDriverStartService(
                `No se puede iniciar el servicio ${this.cargo.consecutive}, el conductor ${this.driver.information.name} tiene la licencia vencida. Se requiere por lo menos una licencia activa tipo ${licenseActive.category}`
              );
              else this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
            }
          } catch (error) {
            this.snackBarService.openSnackBar(error && error.message ? error.message : error ? error : ServiceMessages.GENERAL_HTTP_ERROR);
          }
        } else {
          this.driver = {
            information: {
              document: this.cargo.driver,
            },
          };
          this.showErrorByDriverStartService(
            "Ups! no se pudo obtener la información del conductor"
          );
        }
      },
      (error) => {
        this.driver = {
          information: {
            document: this.cargo.driver,
          },
        };
        this.showErrorByDriverStartService(
          "Ups! no se pudo obtener la información del conductor"
        );
      }
    );
  }
  /**
  * This method open a dialog that in this case is used to show errors related with the beginning of the load.
  * @param {string} title (string) is the text that would be contain in the dialog.
  */
  showErrorByDriverStartService(title: string) {
    this.dialog.openDialog({
      title,
      iconError: true,
      hideBtnConfirm: true,
    }).then().catch(err => err);
  }
  /**
  * This method open a dialog window that asks the user for the confirmation of the vehicle assigned.
  * @param {boolean} ignoreConfirmation (boolean) is a parameter that allows to ignore the confirmation dialog and open directly the inspection if is neccesary.
  */
  private async validateConfirmedDriver(ignoreConfirmation: boolean = false): Promise<void> {
    if (this.cargo.confirmedDriver === true) {
      if (
        this.driver.state &&
        this.driver.state.active &&
        this.vehicle &&
        this.vehicle.state &&
        this.vehicle.state.active
      ) {
        if (!ignoreConfirmation) {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = {
            title: `¿Estás seguro que deseas iniciar este servicio?`,
          };
          dialogConfig.width = ModalEnum.SMALL_WIDTH;
          dialogConfig.autoFocus = false;
          dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
          dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
          const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
          dialogRef.afterClosed().subscribe(async (result) => {
            if (result && result.state) {
              const validGPS = await this.setCargoGPS();
              if (!validGPS) {
                this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
                return;
              }
              this.confirmContainerInspection();
            }
          });
        } else {
          const validGPS = await this.setCargoGPS();
          if (!validGPS) {
            this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
            return;
          }
          this.confirmContainerInspection();
        }
      } else {
        this.adminUserService.confirmDriverInactive(this.driver, this.vehicle);
      }
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: `La placa ${this.cargo.licensePlate} aún no se ha confirmado para iniciar este servicio`,
        iconError: true,
        hideBtnConfirm: true,
      };
      dialogConfig.width = ModalEnum.SMALL_WIDTH;
      dialogConfig.autoFocus = false;
      dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
      dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
      this.matDialog.open(DialogComponent, dialogConfig);
    }
    this.belowSicetac(this.cargo, `cargo/tracking/${this.cargo.consecutive}`);
  }

  private async setCargoGPS(): Promise<boolean> {
    let currentVehicle: Vehicle;
    try {
      currentVehicle = await this.vehiclesService.getVehicle(this.cargo.licensePlate).toPromise();
    } catch (e) {
      return false;
    }
    if (!currentVehicle) return false;
    if (!currentVehicle.gps || (currentVehicle.gps && !currentVehicle.gps.gpsId)) return true;

    this.cargo['gpsId'] = currentVehicle.gps.gpsId;
    let response;
    try {
      response = await this.cargoService.updateFields(this.cargo).toPromise();
    } catch (e) {
      return false;
    }
    if (response) return true;
    return false;
  }

  /**
  * This method brings the information of the vehicle and the inspection respectively.
  * @returns {Observable<any>} (Observable<any>) returns the information of the vehicle
  */
  private __vehicleLoads = 0;
  public loadVehicle(): Observable<any> {
    const $vehicle = new Observable((observer) => {
      this.vehiclesService.getVehicle(this.cargo.licensePlate).subscribe(
        async (success: any) => {
          if (!!success) {
            this.vehicle = success;
            observer.next("vehicle");


            this.cargoItemService
              .getInspection(this.vehicle && this.vehicle.lastInspection ? this.vehicle.lastInspection : null, "vehicle")
              .subscribe(
                (res: any) => {
                  observer.next("inspection");
                  this.lastInspection = res;
                  this.__vehicleLoads++;
                  if (this.__vehicleLoads > 1) {
                    observer.complete();
                    this.__vehicleLoads = 0;
                  }
                },
                (error) => observer.error(error)
              );


            this.cargoItemService
              .getInspection(this.cargo && this.cargo.containerInspectionId ? this.cargo.containerInspectionId : null, "container")
              .subscribe(
                (res: ContainerInspect) => {
                  observer.next("container");
                  this.lastContainerInspection = res;
                  this.__vehicleLoads++;
                  if (this.__vehicleLoads > 1) {
                    observer.complete();
                    this.__vehicleLoads = 0;
                  }
                },
                (error) => observer.error(error)
              );


          } else observer.error(`Failed to get vehicle`);
        },
        (error) => observer.error(error)
      );
    });
    return $vehicle;
  }
  /**
  * This method brings the information of the vehicle.
  */
  public getDetailVehicle() {
    this.loadVehicle().subscribe(
      (loaded) => {
        if (loaded == "vehicle") {
          const validTrailer =
            (this.vehicle &&
              !this.utils.isEmpty(this.vehicle.trailerId) &&
              this.cargo.ministry &&
              this.cargo.cargoFeature &&
              this.cargo.cargoFeature.vehicleType &&
              this.vehicle.vehicleType &&
              this.vehicle.vehicleType.name.trim().toLowerCase() ===
              "TRACTOCAMION".trim().toLowerCase()) ||
            !this.cargo.ministry ||
            this.cargo.cargoFeature.vehicleType.name !== "TRACTOCAMION";
          if (validTrailer) {
            if (
              (this.cargoManager.getTypeCargo(this.cargo) == "Nacional" ||
                this.cargoManager.getTypeCargo(this.cargo) == "Internacional") &&
              this.lastInspection &&
              !this.lastInspection.approval
            ) {
              const dialogConfig = new MatDialogConfig();
              dialogConfig.data = {
                title:
                  "Debes aprobar la inspección del vehiculo, antes de iniciar el servicio",
                hideBtnCancel: false.valueOf,
              };
              dialogConfig.width = ModalEnum.SMALL_WIDTH;
              dialogConfig.autoFocus = false;
              dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
              dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
              const dialogRef = this.matDialog.open(
                DialogComponent,
                dialogConfig
              );
              dialogRef.afterClosed().subscribe((result) => {
                if (result && result.state) {
                  this.showInspection.emit();
                }
              });
            } else if (
              this.utils.isEmpty(this.cargoManager.getTypeCargo(this.cargo)) ||
              this.cargoManager.getTypeCargo(this.cargo) === "Urbana"
            ) {
              this.validateConfirmedDriver();
            } else {
              let validData =
                this.vehicleManager.completeDriverLongMile(this.driver) &&
                this.vehicleManager.completeVehicleLongMile(this.vehicle);
              if (validData) {
                this.validateConfirmedDriver();
              } else {
                const missingFiels = this.vehicleManager.getMissingFiels(
                  this.vehicle,
                  this.driver
                );
                const dialogConfig = new MatDialogConfig();
                dialogConfig.data = {
                  title: "Los siguientes datos aún no se han registrado",
                  iconError: true,
                  hideBtnConfirm: true,
                  messageList: missingFiels,
                };
                dialogConfig.width = ModalEnum.SMALL_WIDTH;
                dialogConfig.autoFocus = false;
                dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
                dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
                const dialogRef = this.matDialog.open(
                  DialogComponent,
                  dialogConfig
                );
                dialogRef.afterClosed().subscribe((result) => {
                  const dialogConfig = new MatDialogConfig();
                  dialogConfig.data = {
                    title: "¿Desea continuar con el inicio del servicio?",
                    showYesBtn: true,
                    showNoBtn: true,
                    hideBtnCancel: false.valueOf,
                  };
                  dialogConfig.width = ModalEnum.SMALL_WIDTH;
                  dialogConfig.autoFocus = false;
                  dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
                  dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
                  const dialogRef = this.matDialog.open(
                    DialogComponent,
                    dialogConfig
                  );
                  dialogRef.afterClosed().subscribe((result) => {
                    if (result && result.state) {
                      this.validateConfirmedDriver(true);
                    } else {
                      this.openVehicle.emit();
                    }
                  });
                });
              }
            }
          } else {
            let titleDialog: string;
            if (!validTrailer) {
              titleDialog = `La placa ${this.cargo.licensePlate} aún no registra un Trailer asociado, debe registrar uno para poder iniciar el servicio`;
            }
            if (titleDialog) {
              const dialogConfig = new MatDialogConfig();
              dialogConfig.data = {
                title: titleDialog,
                iconError: true,
                hideBtnConfirm: true,
              };
              dialogConfig.width = ModalEnum.SMALL_WIDTH;
              dialogConfig.autoFocus = false;
              dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
              dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
              const dialogRef = this.matDialog.open(
                DialogComponent,
                dialogConfig
              );
              dialogRef.afterClosed().subscribe((result) => {
                this.openVehicle.emit();
              });
            }
          }
        }
      },
      () => (this.vehicle = null)
    );
  }
  /**
  * This method open a dialog window that allows the user to assign a vehicle to the load.
  */
  openModalAssignVehicle() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Datos del Vehículo Asignado",
      cargo: this.cargo,
      type: 'assign'
    };
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    const dialogRef = this.matDialog.open(AssignVehicleCargoComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.refreshCargo.emit(this.cargo.consecutive);
        this.refreshListCargo.emit();
      }
    });
  }
  /**
  * This method open a dialog window that shows to the user the information of the person that assign the vehicle.
  */
  showAssignedVehicleData() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Datos del Vehículo Asignado",
      hideBtnCancel: true,
      messageList: [
        "Servicio: " + this.cargo.consecutive,
        "Confirmada por: " + this.cargo.confirmedDriverFingerprint.userName,
        "Fecha confirmación: " +
        this.dateFormatPipe.transform(
          this.cargo.confirmedDriverFingerprint.date,
          "only-date"
        ),
      ],
      icon: true,
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    this.matDialog.open(DialogComponent, dialogConfig);
  }

  get showBtnApproveCargo(): boolean {
    return this.cargo && this.cargo.approval &&
      ((this.cargo.approval === "Pending approval" || this.cargo.approval === "Rejected") && (this.cargo.state == CargoStateEnum.END_SERVICE))
      || (this.cargo.approval !== "Approved" && this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.approveCargoUnrestricted) && ![CargoStateEnum.DELETED, CargoStateEnum.EXPIRED, CargoStateEnum.REQUEST].includes(this.cargo.state));
  }

  get showBtnModificationsShippingCost() {
    const modifyShippingCost = this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.modifyShippingCost
    );
    const fullAccesChangeRate = this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.changeRateFullAccess
    );
    const notFullAccessRequired = this.cargo && this.cargo.approval && this.cargo.approval !== "Approved";
    const isNotPaidOrCashed = this.cargo && this.cargo.shippingCost && (!this.cargo.shippingCost.paid || !this.cargo.shippingCost.cashed);
    return ((isNotPaidOrCashed && notFullAccessRequired && modifyShippingCost) || fullAccesChangeRate);
  }

  /**
  * This method allows to delete the load.
  */
  public async btnDeleteCarga(type?: string, load?: Cargo) {
    if (load) this.cargo = load;
    if (this.cargo && this.cargo.state === CargoStateEnum.EXPIRED) {
      this.errorMessageDeleteCargo(this.cargo.state);
    } else if ((this.cargo.numberDocumentCreatorLoad && this.cargo.numberDocumentCreatorLoad.toString() === this.authService.getUserSession().information.document.toString())
      || this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.removeCargoUnrestricted)) {
      this.confirmRemoveCargo(type);
    }
    else if (this.cargo.numberDocumentCreatorLoad.toString() !==
      this.authService
        .getUserSession()
        .information.document
        .toString()) {
      let user: User = await this.getDetailUserClientByDocument(
        this.cargo.numberDocumentCreatorLoad
      );
      this.errorMessageDeleteCargo(user);
    } else {
      this.errorMessageDeleteCargo();
    }
  }
  /**
  * This method open a dialog with an specific message error when the user try to delete a load.
  * @param {string | User} condition (string | User - optional) evaluates the condition of the load and depends on this shows one message or the other message.
  */
  errorMessageDeleteCargo(condition?: (string | User)) {
    let title: string;
    if (typeof condition === 'string') {
      title = condition === CargoStateEnum.EXPIRED
        ? "Los servicios expirados no se pueden eliminar"
        : "Contacte a Soporte para anular el servicio";
    } else if (condition && 'information' in condition && 'email' in condition) {
      title = `Contacte al creador del servicio ${condition.information.name} al correo ${condition.email} para anular el servicio`;
    } else {
      title = "Contacte a Soporte para anular el servicio";
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title,
      iconError: true,
      hideBtnConfirm: true,
      resize: true
    };
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    this.matDialog.open(DialogComponent, dialogConfig);
  }

  /**
  * This method brings the information of a user according to the userdocument.
  * @param {string} userDocument (string) is the document of the user that needs to consult.
  */
  public getDetailUserByDocument(userDocument: string) {
    return new Promise((resolve, reject) => {
      this.adminUserService.getUsersDetailByDocument(userDocument).subscribe(
        (success: User) => {
          resolve(success);
        },
        (error) => {
          reject();
        }
      );
    });
  }
  /**
  * This method brings the information of a client according to the userdocument.
  * @param {string} userDocument (string) is the document of the client that needs to consult.
  */
  public getDetailUserClientByDocument(userDocument: string) {
    return new Promise((resolve, reject) => {
      this.adminUserService.getUserClientByDocument(userDocument).subscribe(
        (success: UserClient[]) => {
          if (success && success.length) resolve(success[0]);
          else reject();
        },
        (error) => {
          reject();
        }
      );
    });
  }
  /**
  * This method open a dialog window that allow the user to renew the load.
  */
  openRenewDate() {
    const creationDate = DateManager.stringToDate(this.cargo.creationDate, 'YYYY-MM-DD HH:mm Z');
    const limitDate = DateManager.stringToDate('2024-01-15', 'YYYY-MM-DD');
    if (DateManager.isBefore(creationDate, limitDate, "days")) {
      this.snackBarService.openSnackBar(CargoMessages.INVALID_DATE_RESTORE_LOAD, undefined, 'alert');
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      cargo: this.cargo
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(CargoRenewDatesComponent, dialogConfig);
  }
  /**
  * This method open a dialog window that allow the user to create a rounded load.
  */
  openRoundedCargo() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.cargo;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(RoundedCargoComponent, dialogConfig);
  }
  /**
  * This method open a dialog that asks the user if wanted to approve the pay of the balance of the load.
  */
  public openDialogApprovePayBalance(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title:
        "¿Deseas Aprobar el Pago del Saldo del servicio " +
        this.cargo.consecutive +
        " para la placa " +
        this.cargo.licensePlate +
        "?",
      showYesBtn: true,
      showNoBtn: true,
    };
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (!this.utils.isEmpty(result) && !this.utils.isEmpty(result.state)) {
        this.approvePayBalance(result.state);
      }
    });
  }
  /**
  * This method allows to approve or reject the balance pay.
  * @param {boolean} approve (boolean) is the response of the user if approve or reject the balance pay.
  */
  private approvePayBalance(approve: boolean): void {
    this.cargoItemService.approvePayBalance(this.cargo.id, approve).subscribe(
      (cargo: Cargo) => {
        if (
          !this.utils.isEmpty(cargo) &&
          !this.utils.isEmpty(cargo.shippingCost) &&
          !this.utils.isEmpty(cargo.shippingCost.approveBalance)
        ) {
          this.refreshListCargo.emit();
          this.refreshCargo.emit(this.cargo.consecutive);
          this.snackBarService.openSnackBar(
            "El pago del saldo ha sido " +
            (cargo.shippingCost.approveBalance ? "aprobado" : "rechazado") +
            " correctamente"
          );
        } else {
          this.snackBarService.openSnackBar(
            "Ocurrió un error " +
            (cargo.shippingCost.approveBalance ? "aprobando" : "rechazando") +
            " el pago del saldo",
            undefined,
            "error"
          );
        }
      },
      (error) => {
        this.snackBarService.openSnackBar(
          "Ocurrió un error registrando la acción del pago del saldo",
          undefined,
          "error"
        );
      }
    );
  }
  /**
  * This method open a dialog window to add a new location report of the vehicle of the load.
  */
  public openCargoLocationReport(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      cargo: this.cargo,
    };
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.disableClose = true;
    const dialogRef = this.matDialog.open(
      CargoLocationReportComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      this.refreshListCargo.emit();
      if (!this.utils.isEmpty(result) && !this.utils.isEmpty(result.state)) {
        this.snackBarService.openSnackBar("Registro agregado correctamente");
      }
    });
  }
  /**
 * This method allows to verified if is possible to open the request of the advance or to cancel the advance requested.
 * @param {string} request (string) indicates the type of query if is request advance or cancel the advance requested.
 */
  canOpenRequestAdvance(request: string) {
    if (this.cargo) {
      const assignedVehicleFingerprintUserId = this.utils.getNestedValue(this.cargo, 'assignedVehicleFingerPrint.userId');
      const authServiceUserId = this.authService && this.authService.getUserSession() && this.authService.getUserSession().information && this.authService.getUserSession().information.document;
      if (assignedVehicleFingerprintUserId && authServiceUserId) {
        if (assignedVehicleFingerprintUserId !== authServiceUserId && !this.permissionToRequestAdvanceUnrestricted) {
          this.dialogMessage(`No es posible solicitar el pago de anticipo. La solicitud debe ser realizada por la persona que asignó el vehículo. Por favor, contacte a ${this.cargo.assignedVehicleFingerPrint.userName}.`, '', true, true);
          return;
        }

      } else {
        if (!authServiceUserId) {
          this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, "error");
          return;
        }
        if (assignedVehicleFingerprintUserId !== authServiceUserId && !this.permissionToRequestAdvanceUnrestricted) {
          this.dialogMessage(
            `No es posible solicitar el pago de anticipo. La solicitud debe ser realizada por la persona que asignó el vehículo. Por favor, contacte a ${this.cargo.assignedVehicleFingerPrint.userName}. ${this.isTeclogiUser ? ' En caso de no poder hacerlo, comuníquese con Fabio Sarmiento o Jimena Sánchez.' : ''}`,
            '',
            true,
            true
          );
          return;
        }
      }
      this.requestAdvance(request);
    }
  }
  get isTeclogiUser(): boolean {
    return this.authService.getCompany().companyId === environment.rootNit;
  }

  requestAdvance(request: string) {
    if (
      this.cargo &&
      this.cargo.cargoFeature &&
      this.cargo.cargoFeature.uploadDownload &&
      this.cargo.cargoFeature.uploadDownload.origin &&
      this.cargo.cargoFeature.uploadDownload.origin.addresses &&
      this.cargo.cargoFeature.uploadDownload.origin.addresses.length >= 0
    ) {
      let address = this.cargo.cargoFeature.uploadDownload.origin.addresses[0];
      if (address) {
        this.spinner.show();
        this.cargoEvidenceService.getImageByAddress(this.cargo.id, address.address, address.id)
          .pipe(
            finalize(() => {
              this.spinner.hide();
              this.setStatusAdvanceMessage(request);
            })
          )
          .subscribe(
            (data) => {
              if (data !== null) this.canOpenRequestAdvanceModal = true;
              else this.canOpenRequestAdvanceModal = false;
            },
            (error) => {
              this.canOpenRequestAdvanceModal = false;
              console.error(error);
            }
          );
      }
      else {
        this.canOpenRequestAdvanceModal = false;
      }
    }
  }
  /**
  * This method set the advance message this could be an error or an information message.
  * @example
  * @param {Date | string} date (Date or string) is the date to use
  * @param {string} request (string) indicates the type of query if is request advance or cancel the advance requested.
  */
  setStatusAdvanceMessage(request: string) {
    if (
      this.cargo.ministry && !this.permissionToRequestAdvanceUnrestricted &&
      (!this.cargo.manifest || !this.cargo.manifestAuthorization)
    )
      this.cannotRequestAdvanceReason =
        CargoMessages.NO_MANIFEST;
    else if (
      this.cargo.state === CargoStateEnum.START_SERVICE &&
      !this.canOpenRequestAdvanceModal && !this.permissionToRequestAdvanceUnrestricted
    )
      this.cannotRequestAdvanceReason =
        CargoMessages.NO_UPLOAD_EVIDENCES;
    else if (this.cargo.shippingCost && !this.cargo.shippingCost.valueAdvance && this.authService.getCompany().companyId !== '8000130291')
      this.cannotRequestAdvanceReason =
        CargoMessages.VALUE_ADVANCE_ON_ZERO;
    else if (
      this.cargo.shippingCost &&
      !this.cargo.shippingCost.paid === false
    )
      this.cannotRequestAdvanceReason =
        CargoMessages.SHIPPING_COST_PAID;
    else if (
      this.cargo.shippingCost &&
      !this.cargo.shippingCost.advanceState === false
    )
      this.cannotRequestAdvanceReason =
        CargoMessages.ADVANCE_STATE_PAID;
    else
      if (this.cargo.shippingCost && !this.cargo.shippingCost.valueAdvance)
        this.cannotRequestAdvanceReason =
          CargoMessages.VALUE_ADVANCE_ON_ZERO;
      else if (
        this.cargo.shippingCost &&
        !this.cargo.shippingCost.paid === false
      )
        this.cannotRequestAdvanceReason =
          CargoMessages.SHIPPING_COST_PAID;
      else if (
        this.cargo.shippingCost &&
        !this.cargo.shippingCost.advanceState === false
      )
        this.cannotRequestAdvanceReason =
          CargoMessages.ADVANCE_STATE_PAID;
      else
        if (this.cargo.shippingCost && this.cargo.shippingCost.requestedAdvance &&
          this.cargo.shippingCost.requestedAdvance.fingerprint && this.cargo.shippingCost.requestedAdvance.requested) {
          this.cannotRequestAdvanceReason =
            `Solicitado por ${this.cargo.shippingCost.requestedAdvance.fingerprint.userName}
        (${this.cargo.shippingCost.requestedAdvance.fingerprint.userId})
        ${this.dateFormatPipe.transform(this.cargo.shippingCost.requestedAdvance.fingerprint.date, "only-date")}`
        }
        else this.cannotRequestAdvanceReason = CargoMessages.SHOW_REQUEST;


    if (this.disabledRequestAdvance) {
      this.openDialogError(this.cannotRequestAdvanceReason);
    } else {
      let fingerPrint = '';
      request && request === 'CancelRequest' ? fingerPrint = this.cannotRequestAdvanceReason : '';
      this.openDialogRequestAdvance(request, fingerPrint);
    }
  }
  /**
  * This method open a dialog window that shows to the user an error message depends on the value of the parameter.
  * @param {string} title (string) is the error message.
  */
  openDialogError(title: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: title,
      iconError: true,
      hideBtnConfirm: true
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    this.matDialog.open(DialogComponent, dialogConfig);
  }

  get disabledRequestAdvance() {
    return this.cannotRequestAdvanceReason &&
      this.cannotRequestAdvanceReason !== CargoMessages.SHOW_REQUEST &&
      this.cannotRequestAdvanceReason.split(' ')[0] !== 'Solicitado';
  }

  get disabledAddTracking(): boolean {
    return !!(this.cargo && (this.cargo.stateTracking === CargoStateEnum.END_SERVICE));
  }
  /**
  * This method open a dialog to confirm the request of the advance of the load.
  * @param {string} request (string) indicates if is request advance or cancel the advance requested.
  * @param {string} description (string - optional) is a fingerprint.
  */
  public openDialogRequestAdvance(request: string, description?: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      cargo: this.cargo,
      request: request,
      description: description ? description : ''
    };
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    const dialogRef = this.matDialog.open(
      CargoRequestAdvanceComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (!this.utils.isEmpty(result) && !this.utils.isEmpty(result.state)) {
        this.refreshListCargo.emit();
        this.refreshCargo.emit(this.cargo.consecutive);
      }
    });
  }
  /**
  * This method download the novelties report of the load.
  */
  public downloadReport(): void {
    this.spinner.show();
    this.cargoService.downloadCargoTraceability(this.cargo.id).subscribe(
      (response) => {
        if (!this.utils.isEmpty(response)) {
          this.utils.downloadFile(
            response,
            "Reporte Servicio " + this.cargo.consecutive
          );
        } else {
          this.snackBarService.openSnackBar(
            "No hay reportes registrados para el servicio " +
            this.cargo.consecutive,
            undefined,
            "alert"
          );
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(
          "Ocurrió un error al descargar el reporte del servicio " +
          this.cargo.consecutive,
          undefined,
          "error"
        );
      }
    );
  }
  /**
  * This method open a dialog window and in this window asks the user for the approval or rejection of the fast payment of the load.
  * @param {string} idCargo (string) is the identifier of the load.
  * @param {number} consecutive (number) is the consecutive of the load.
  * @param {boolean} state (boolean) is decision of the user could be approve or reject.
  * @param {string} tripType (string) is tripType of the load.
  */
  openDialogFastPayment(idCargo: string, consecutive: number, state: boolean, tripType: string) {
    const dialogConfig = new MatDialogConfig();
    const title = state ? 'solicitar el' : 'cancelar la solicitud del';
    dialogConfig.data = {
      title: `¿Está seguro que quiere ${title} pronto pago para el servicio #${consecutive}?`,
    };
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.approveFastPayment(idCargo, state, tripType)
      }
    });
  }

  /**
 * This method approved or reverse the fast payment.
 * @param {string} idCargo (string) is the identifier of the load.
 * @param {boolean} state (boolean) is parameter that indicates if is al approval or a reverse of the fast payment.
 * @param {string} tripType (string) is a parameter to set an exception sended to backend.
 */
  approveFastPayment(idCargo: string, state: boolean, tripType: string) {
    const fastPaymentObserver = {
      next: () => {
        this.spinner.hide();
        state ? this.snackBarService.openSnackBar(CargoMessages.SUCCESS_FAST_PAYMENT, undefined, "success") :
          this.snackBarService.openSnackBar(CargoMessages.SUCCESS_FAST_PAYMENT_REVERT, undefined, "success");
        this.refreshListCargo.emit();
        this.refreshCargo.emit(this.cargo.consecutive);
      },
      error: (error) => {
        this.spinner.hide();
        if (error && error.error && error.error.message) {
          this.snackBarService.openSnackBar(error.error.message, undefined, 'error');
        } else {
          this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
        }
      },
      complete: () => {
        this.spinner.hide();
      }
    };
    this.spinner.show();
    this.cargoOptionsService.fastPayment(idCargo, state, tripType).subscribe(fastPaymentObserver);
  }
  /**
  * This method open a dialog window that asks the user if wanted to restart the load.
  * @param {number} consecutive (number) is the consecutive of the load.
  * @param {string} cargoId (string) is the identifier of the load.
  */
  openDialogRestartCargo(consecutive: number, cargoId: string) {
    if (this.showRestartCargo) {
      this.snackBarService.openSnackBar(CargoMessages.PAYMENTS_IN_CARGO, undefined, 'alert');
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `¿Está seguro que quiere reiniciar el servicio #${consecutive}?`,
    };
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) this.restartCargo(consecutive, cargoId)
    });
  }
  /**
  * This method consumes the service that restart a load.
  * @param {number} consecutive (string) is the consecutive of the load.
  * @param {string} cargoId (string) is the identifier of the load.
  */
  restartCargo(consecutive: number, cargoId: string) {
    const restartCargoObserver = {
      next: () => {
        this.snackBarService.openSnackBar(CargoMessages.SUCCESS_RESTART_LOAD, undefined, "success");
        this.router.navigate(["cargo/tracking/", consecutive.toString()]);
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
        if (error && error.error && error.error.message) {
          this.snackBarService.openSnackBar(error.error.message, undefined, 'error');
        } else {
          this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
        }
      },
      complete: () => {
        this.spinner.hide();
      }
    };
    this.spinner.show();
    this.cargoOptionsService.restartCargo(cargoId).subscribe(restartCargoObserver);
  }

  openMoveAdvance() {
    const config = new MatDialogConfig();
    config.data = { cargos: [this.cargo] };
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.width = ModalEnum.SMALL_WIDTH;
    config.maxWidth = ModalEnum.MAX_WIDTH;

    this.matDialog.open(MoveAdvanceDialogComponent, config);
  }

  get showRestoreCargo(): boolean {
    return [CargoStateEnum.END_SERVICE, CargoStateEnum.EXPIRED, CargoStateEnum.DELETED].includes(this.cargo.state);
  }

  get showRestartCargo(): boolean {
    return !!(this.cargo && this.cargo.shippingCost && this.cargo.shippingCost.payments && this.cargo.shippingCost.payments.length && this.cargo.shippingCost.payments.some(payment => payment.type === 'balance'))
  }

  get showCreateRoundedCargo() {
    if (
      this.cargo &&
      (this.cargo.state === CargoStateEnum.END_SERVICE ||
        this.cargo.state === CargoStateEnum.START_SERVICE)
    ) {
      return true;
    } else {
      return false;
    }
  }

  get canReadManifest() {
    return this.permissionRole.hasPermission("cargo", "readManifest");
  }

  get PDFTypes() {
    return PDFTypes;
  }

  get isEscortedService(): boolean {
    const serviceType: ServiceType = this.utils.getNestedValue(this.cargo, 'cargoModel.serviceType');
    return serviceType && serviceType.id === 'escortServices';
  }

  get hasManifestPermission(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.documentManifest);
  }
  get hasConsignmentPermission(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.documentConsignment);
  }
  get hasManifestUrbanPermission(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.documentManifestUrban);
  }
  get hasConsignmentUrbanPermission(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.documentConsignmentUrban);
  }
  get hasOrderVehiclePermission(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.documentOrderVehicle);
  }

  /**
  * @returns {boolean} Returns true if the user's SaaS company has escort services
  * @description Verifies if the user's SaaS company has escort services
  */
  get hasEscortServicesCompany(): boolean {
    return this.authService.getCompanySaaS() && this.authService.getCompanySaaS().companyId === Companies.companiesNIT.SEGURIDAD_EXTREMA;
  }

  get balanceState(): boolean {
    return this.cargo && this.cargo.shippingCost && this.cargo.shippingCost.payments && this.cargo.shippingCost.payments.length ? this.cargo.shippingCost.payments.every(payment => payment.type !== 'balance') : true;
  }

  get haveAdvancePayments(): boolean {
    return !!(this.cargo && this.cargo.shippingCost && this.cargo.shippingCost.advanceState && this.cargo.shippingCost.payments && this.cargo.shippingCost.payments.length && this.cargo.shippingCost.payments.some(payment => payment.type === 'advance'))
  }

  get showCancelAdvance() {
    return (this.cargo &&
      this.cargo.state === CargoStateEnum.START_SERVICE &&
      this.cargo.shippingCost &&
      this.cargo.shippingCost.requestedAdvance &&
      this.cargo.shippingCost.requestedAdvance.requested)
  }

  get permissionToRequestAdvanceUnrestricted(): boolean {
    return this.permissionRole.hasPermission(Permission.payments.module, Permission.payments.unrestrictedAdvancePaymentsRequest);
  }

  get isPrincipalCompany(): boolean {
    return !!(this.listCompanies.length && this.company && this.company.companyId && this.listCompanies.some(company => company.nit === this.company.companyId));
  }

  get cargoStateEnum(): typeof CargoStateEnum {
    return CargoStateEnum;
  }

  public belowSicetac(cargo: SicetacResponse, path?: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const formatter = new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: true,
    });
    const min = formatter.format(cargo.minimumApprovedValueSicetac);
    if (this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.approvedBelowSicetac)) {
      if (cargo.belowSicetac && !cargo.freightValueApprovedBelowSicetac) {
        dialogConfig.data = {
          title: '¿Desea aprobar el flete por debajo del SICETAC?',
          descriptionHTML: `El flete mínimo permitido por SICETAC es de <strong>${min}</strong>, si desea ajustarlo por favor realice una bonificación para ajustarse al valor mínimo permitido.`,
          btnDetailCargo: true,
          path: path,
          closeModal: true,
        };
        dialogConfig.disableClose = true;
        const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          if (result && result.state) {
            this.cargoService.approvedBelowSiceTac(this.cargo.id).subscribe(
              () => {
                this.openConfirmBelowSicetac();
                this.refreshListCargo.emit();
                this.refreshCargo.emit(this.cargo.consecutive);
              },
              (error) => {
                this.snackBarService.openSnackBar("No se pudo aprobar", undefined, 'error');
              }
            );
          }
        })
      }
    } else {
      if (cargo.belowSicetac && !cargo.freightValueApprovedBelowSicetac) {
        dialogConfig.data = {
          title: `El flete mínimo es ${min}, por favor realice una bonificación para ajustarse al valor mínimo permitido`,
          showBtn: true,
        };
        const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => { })
      }
    }
  }

  openConfirmBelowSicetac() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      title: `Aprobación exitosa`,
      icon: true,
      description: `Se ha aprobado el servicio por un flete que está por debajo del valor mínimo permitido por el SICETAC`,
      hideBtnCancel: false,
      hideBtnConfirm: true,
    };
    this.matDialog.open(DialogComponent, dialogConfig);
  }

  private loadCannotShowManifestReason() {
    this.disableViewManifest = true;

    if (!this.cargo) {
      this.cannotShowManifestReason = DisableManifestReason.LOADING_CARGO;
      return;
    }

    const ministry = this.cargo.ministry === true;
    const manifest = !!this.cargo.manifest && this.cargo.manifest !== null;
    const autorization = !!this.cargo.manifestAuthorization && this.cargo.manifestAuthorization.length > 0;
    const error = this.cargo.manifestError && this.cargo.manifestError.error;

    if (ministry && error && !(manifest && autorization)) {
      this.cannotShowManifestReason = this.cargo.manifestError.error;
    } else if (ministry && !(manifest && autorization)) {
      this.cannotShowManifestReason = DisableManifestReason.NO_MANIFEST_RNDC;
    } else if (ministry && manifest && autorization) {
      this.disableViewManifest = false;
      this.cannotShowManifestReason = DisableManifestReason.SHOW_MANIFEST;
    } else {
      this.cannotShowManifestReason = DisableManifestReason.PRECONDITIONS_NOT_MET;
    }
  }

  public openDuplicateTransportRequest() {
    const creationDate = DateManager.stringToDate(this.cargo.creationDate, 'YYYY-MM-DD HH:mm Z');
    const limitDate = DateManager.stringToDate('2024-01-15', 'YYYY-MM-DD');
    if (DateManager.isBefore(creationDate, limitDate, "days")) {
      this.snackBarService.openSnackBar(CargoMessages.INVALID_DATE_RESTORE_LOAD, undefined, 'alert');
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      cargo: this.cargo,
      isDuplicate: true
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(CargoRenewDatesComponent, dialogConfig);
  }

  ngOnDestroy() {
    this.cdref.detach();
  }
}
