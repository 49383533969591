import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Utils } from 'src/app/core/resources/utils';
import { Global } from 'src/app/core/resources/global';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AccountService } from 'src/app/modules/account/account.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from 'src/app/core/interfaces/user';
import { UserState } from 'src/app/core/enums/user-state.enum';
import { StateUserPipe } from 'src/app/core/pipe/stateInactive.pipe';
import { AdminUsersService } from 'src/app/modules/administration/admin-users/admin-users.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { OpenDetailManifestsComponent } from 'src/app/modules/cargo/open-detail-manifests/open-detail-manifests.component';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { RelatedInfoUser } from 'src/app/core/interfaces/relatedInfoUser';
import { UserFormComponent } from 'src/app/modules/administration/admin-users/user-form/user-form.component';
import { VehiclesService } from 'src/app/modules/administration/vehicles/list-vehicles.service';
import { ChangeDriverComponent } from 'src/app/modules/cargo/change-driver/change-driver.component';
import { FormControl } from '@angular/forms';
import { RoleUser } from 'src/app/core/interfaces/roleUser';
import { Roles } from 'src/app/core/enums/roles.enum';
import { VehicleManager } from 'src/app/core/managers/vehicle.manager';
import { SetAdministratorComponent } from 'src/app/modules/administration/vehicles/set-administrator/set-administrator.component';
import { AuthService } from 'src/app/core/services/authentication.service';
import { environment } from 'src/environments/environment';
import { VehicleCreate } from 'src/app/core/interfaces/vehicleCreate';
import { HttpErrorResponse } from '@angular/common/http';
import { UserValidate } from 'src/app/core/interfaces/userValidate';
import { UserManager } from 'src/app/core/managers/user.manager';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { DuplicatedPhoneComponent } from 'src/app/modules/administration/vehicles/duplicated-phone/duplicated-phone.component';
import { merge } from 'rxjs';


@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
  providers: [VehicleManager, UserManager]
})
export class UserCardComponent implements OnInit {

  @Input() public user: User;
  @Input() public relatedInfo: RelatedInfoUser = {
    driver: {},
    owner: {},
    vehicle: {}
  };
  @Input() public validateTruora: boolean = true;
  @Input() public role: RoleUser;
  @Input() public disabledEdit: boolean = true;
  @Input() public notAllowedEdit: boolean = true;
  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  public roles = Roles;
  permission = Permission;

  constructor(
    public utils: Utils,
    public global: Global,
    public accountService: AccountService,
    public spinner: NgxSpinnerService,
    private stateUserPipe: StateUserPipe,
    private adminUserService: AdminUsersService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    private vehiclesService: VehiclesService,
    private vehicleManager: VehicleManager,
    private authService: AuthService,
    private userManager: UserManager,
    private permissionRole: PermissionRole,
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.relatedInfo && changes.relatedInfo.currentValue) {
      if (changes && changes.relatedInfo && changes.relatedInfo.currentValue && changes.relatedInfo.currentValue.driver) {
        this.relatedInfo.driver = this.userManager.sanitizeUser(changes.relatedInfo.currentValue.driver, this.roles.DRIVER);
      }
      if (changes && changes.relatedInfo && changes.relatedInfo.currentValue && changes.relatedInfo.currentValue.owner) {
        this.relatedInfo.owner = this.userManager.sanitizeUser(changes.relatedInfo.currentValue.owner, this.roles.OWNER);
      }
    }
    if (changes && changes.user && changes.user.currentValue) {
      this.user = this.userManager.sanitizeUser(changes.user.currentValue, this.role);
    }
    if (changes && changes.user && changes.user.currentValue && changes.user.currentValue.profilePicture) {
      this.getURlPictureUser();
    }
  }

  public openManifests(): void {
    const dialogConfig = new MatDialogConfig();
    const title = `Manifiestos asociados`;
    dialogConfig.data = {
      title: title,
      manifest: this.user.manifests,
      driverName: this.user.information.name
    };
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(OpenDetailManifestsComponent, dialogConfig);
  }

  public openDetailUser(): void {
    this.user.role = this.role;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      driver: this.user,
      expireArl: !!this.relatedInfo.expireArl,
      expireEps: !!this.relatedInfo.expireEps,
      showDocuments: this.authService.getCompany().companyId === environment.rootNit ? this.showCheckValidationIndividual : this.showCheckValidation,
      driverIsSameOwner: this.relatedInfo && this.relatedInfo.vehicle && this.relatedInfo.vehicle.driver && this.relatedInfo.vehicle.driver.document && this.relatedInfo.vehicle.owner && this.relatedInfo.vehicle.owner.document === this.relatedInfo.vehicle.driver.document,
      ownerIsSameAdmin: this.relatedInfo && this.relatedInfo.vehicle && this.relatedInfo.vehicle.administrator && this.relatedInfo.vehicle.administrator.document && this.relatedInfo.vehicle.owner && this.relatedInfo.vehicle.owner.document === this.relatedInfo.vehicle.administrator.document,
      validateTruora: this.validateTruora
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(UserFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.getDetailDriver();
    });
  }

  public changeDriver(): void {
    if (this.relatedInfo.vehicle && this.relatedInfo.vehicle.owner === undefined) {
      this.openEditVehicle();
    } else {
      const formControl = new FormControl();
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: 'Modificación de conductor',
        label: 'Ingresa el número de documento',
        idVehicle: this.relatedInfo.vehicle.id,
        options: {
          typeUser: 'driver',
        },
        inputFormControl: formControl
      };
      dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
      dialogConfig.autoFocus = false;
      dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
      const modalDialog = this.dialog.open(ChangeDriverComponent, dialogConfig);
      modalDialog.afterClosed().subscribe(result => {
        if (result && result.state && result.data) {
          this.vehicleChangeDriver(result.data);
        }
      });
    }
  }

  private userValidate(): void {
    this.spinner.show();
    const data: UserValidate = {
      forceCreation: true,
      vehicle: this.relatedInfo.vehicle,
      driver: {},
      owner: {}
    };

    if (this.utils.isDefined(this.relatedInfo.trailer)) {
      data.trailer = this.relatedInfo.trailer;
    }

    if (this.role === Roles.DRIVER) {
      data.driver = this.user;
      data.owner = this.relatedInfo.owner;
      if (this.relatedInfo.administrator)
        data.administrator = this.relatedInfo.administrator;
    }
    if (this.role === Roles.OWNER) {
      data.owner = this.user;
      data.driver = this.relatedInfo.driver;
      if (this.relatedInfo.administrator)
        data.administrator = this.relatedInfo.administrator;
    }
    if (this.role === Roles.ADMIN) {
      data.administrator = this.user;
      data.driver = this.relatedInfo.driver;
      data.owner = this.relatedInfo.owner;
    }

    data.driver.phone = data.driver.phone && data.driver.phone.startsWith("57")
      ? data.driver.phone
      : `57${data.driver.phone}`;
    data.owner.phone = data.owner.phone && data.owner.phone.startsWith("57")
      ? data.owner.phone
      : `57${data.owner.phone}`;

    this.vehiclesService.createVehicleDriverOwner(data).subscribe(
      (success: VehicleCreate) => {
        this.spinner.hide();
        if (
          !this.utils.isEmpty(success) &&
          !this.utils.isEmpty(success.message)
        ) {
          this.snackBarService.openSnackBar(success.message);
        } else {
          this.snackBarService.openSnackBar(
            "Estudio de seguridad realizado correctamente, puede tardar unos minutos",
            undefined,
            "success"
          );
        }
        setTimeout(() => {
          this.onEdit.emit(this.relatedInfo.vehicle.id);
        }, 400);
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
        if (error && error.status && error.error && error.error.message &&
          error.status === 400 && error.error.message === FormMessages.REPEATED_DRIVER_PHONE)
          this.duplicatePhoneErrorManagement(data);
        else if (
          !this.utils.isEmpty(error) &&
          !this.utils.isEmpty(error.error) &&
          !this.utils.isEmpty(error.error.message)
        ) {
          this.snackBarService.openSnackBar(
            error.error.message,
            undefined,
            "alert"
          );
        } else {
          this.snackBarService.openSnackBar(
            "Ocurrió un error al realizar el estudio de seguridad",
            undefined,
            "error"
          );
        }
      }
    );
  }

  private duplicatePhoneErrorManagement(data: UserValidate) {
    const phones = {
      driver: !!data.driver ? (!!data.driver.phone ? data.driver.phone : null) : null,
      owner: !!data.owner ? (!!data.owner.phone ? data.owner.phone : null) : null,
      administrator: !!data.administrator ? (!!data.administrator.phone ? data.administrator.phone : null) : null,
    }
    const observables = [];
    const subjects = ['driver', 'owner', 'admin'];
    subjects.forEach(subject => {
      if (!!phones[subject]) {
        const singlePhone = this.vehiclesService.removeCountryCode(phones[subject]);
        const countryPhone = this.vehiclesService.addCountryCode(phones[subject]);
        observables.push(
          this.vehiclesService.getUsersByPhone(singlePhone),
          this.vehiclesService.getUsersByPhone(countryPhone)
        );
      }
    })
    const results: User[] = [];
    merge(...observables).subscribe(
      {
        next: (users: User[]) => {
          if (Array.isArray(users))
            results.push(...users);
        },
        error: (error: Error) => {
          console.error(error);
        },
        complete: () => {
          const documents = [];
          const findedUsers = results.filter(user => {
            const document = user.information.document;
            const distinct = documents.indexOf(document) === -1;
            documents.push(document);
            return distinct;
          });
          this.showDuplicatedPhoneDialog(findedUsers);
        }
      }
    );

  }

  /**
  * @param {User[]} users is the list of users with the same phone number
  * @description Shows a modal to update the users with same phone number
  */
  public showDuplicatedPhoneDialog(users: User[]): void {
    const config: MatDialogConfig = {
      data: users
    };
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    config.width = ModalEnum.LARGE_WIDTH;
    config.autoFocus = false;
    this.dialog.open(DuplicatedPhoneComponent, config);
  }

  public checkValidationUser(): void {
    if ((this.relatedInfo && this.relatedInfo.vehicle && !this.relatedInfo.vehicle.checkId)) {
      this.userValidate();
    } else if (this.user && (!this.user.truora || !this.user.truora.checkId)) {
      this.checkValidationIndividual();
    } else {
      const driverDetail: User = this.utils.clone(this.user);
      delete driverDetail.profilePictureUrl;
      if (driverDetail && driverDetail.information && driverDetail.information.document) {
        this.spinner.show();
        const $sub = this.adminUserService.checkValidationUser(driverDetail.information.document).subscribe(
          {
            next: (success: any) => {
              if (success && success.driver && success.driver.state && success.driver.state.active) {
              } else {
                if (success && success.driver) {
                  if (success.driver.role) success.driver.role = this.role;
                  this.adminUserService.confirmDriverInactive(success.driver, this.relatedInfo.vehicle);
                }
              }
              this.getDetailDriver();
              this.spinner.hide();
            },
            error: () => {
              this.snackBarService.openSnackBar('Ocurrió un error con la petición', undefined, 'error');
              this.getDetailDriver();
              this.spinner.hide();
            },
            complete: () => {
              $sub.unsubscribe();
            }
          }
        )
      } else {
        this.snackBarService.openSnackBar('No fue posible acceder al documento del usuario', undefined, 'error');
        this.getDetailDriver();
      }
    }
  }

  private checkValidationIndividual(): void {
    const user: User = this.utils.clone(this.user);
    delete user.profilePictureUrl;
    if (user && user.information && user.information.document) {
      this.spinner.show();
      const $sub = this.adminUserService.checkValidationIndividual(user).subscribe(
        {
          next: (success: any) => {
            if (success && success && success.state && success.state.active) {
            } else {
              success.role = this.role;
              this.adminUserService.confirmDriverInactive(success, this.relatedInfo.vehicle);
            }
            this.getDetailDriver();
            this.spinner.hide();
          },
          error: () => {
            this.snackBarService.openSnackBar('Ocurrió un error con la petición', undefined, 'error');
            this.getDetailDriver();
            this.spinner.hide();
          },
          complete: () => {
            $sub.unsubscribe();
          }
        }
      )
    } else {
      this.snackBarService.openSnackBar('No fue posible acceder al documento del usuario', undefined, 'error');
      this.getDetailDriver();
    }
  }

  public openEditVehicle(): void {
    if (this.notAllowedEdit) {
      this.snackBarService.openSnackBar(FormMessages.NOT_ALLOWED_EDIT_USER, undefined, 'alert');
      return;
    }
    if (this.role === Roles.ADMIN) {
      this.openEditAdministrator();
    } else {
      this.vehicleManager.updateInformation(this.relatedInfo.vehicle).subscribe((success) => {
        this.onEdit.emit(this.relatedInfo.vehicle.id);
      });
    }
  }

  public openEditAdministrator(): void {
    if (!this.canEditAdmin) {
      this.snackBarService.openSnackBar("No tienes permisos para realizar esta acción", undefined, 'alert');
      return;
    }

    const config = new MatDialogConfig();
    config.data = {
      licensePlate: this.relatedInfo.vehicle.id,
      hasAdministrator: !!this.relatedInfo.vehicle.hasAdministrator,
      administrator: this.user,
      title: 'Asignar Administrador'
    };
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    config.autoFocus = false;
    const ref = this.dialog.open(SetAdministratorComponent, config);
    ref.afterClosed().subscribe(
      (vehicle: Vehicle) => {
        this.onEdit.emit(this.relatedInfo.vehicle.id);
      }
    )
  }

  private getURlPictureUser(): void {
    const storage = AuthService.fStorage;
    if (this.utils.isDefined(this.user) && this.utils.isDefined(this.user.profilePicture)) {
      const pathReference = storage.ref(this.user.profilePicture);
      pathReference.getDownloadURL().then(
        (data) => {
          this.user.profilePictureUrl = data;
        },
        (error) => {
          this.user.profilePictureUrl = null;
        }
      );
    }
  }

  private vehicleChangeDriver(user: User): void {
    this.spinner.show();
    this.vehiclesService.vehicleChangeDriver(this.relatedInfo.vehicle.id, user).toPromise()
      .then((success: User) => {
        const data = this.userManager.sanitizeUser(success, Roles.DRIVER);
        if (data) {
          if (data.errorRNDC && data.errorRNDC && data.errorRNDC.error === 'Debe ingresar un conductor diferente al actual') {
            this.snackBarService.openSnackBar('Debe ingresar un conductor diferente al actual', undefined, 'alert');
          } else {
            this.snackBarService.openSnackBar('Conductor asignado correctamente');
            this.getDetailDriver();
          }
        } else {
          this.snackBarService.openSnackBar('Ocurrió un error al cambiar de conductor', undefined, 'error');
        }
      })
      .catch((error) => {
        this.snackBarService.openSnackBar('Ocurrió un error al cambiar de conductor', undefined, 'error');
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  private getDetailDriver(): void {
    this.accountService.validateEntity(1, this.user.information.document).subscribe(
      (data: User) => {
        if (this.utils.isDefined(data)) {
          this.onEdit.emit(this.relatedInfo.vehicle.id);
          this.user = this.userManager.sanitizeUser(data, Roles.DRIVER);
          this.user.role = this.role;
          this.getURlPictureUser();
        } else {
          this.setDefaultDriver();
        }
      },
      (error) => {
        this.setDefaultDriver();
      }
    );
  }

  private setDefaultDriver(): void {
    this.user = {
      information: {
        document: this.relatedInfo && this.relatedInfo.cargo ? this.relatedInfo.cargo.driver : '--'
      }
    };
  }

  public get scoreDriver(): number | number[] {
    try {
      return Array(Math.round(this.user.teclogiScore.globalScore)).fill(0).map((x, i) => i);
    } catch (e) {
      return 0;
    }
  }

  public get userState(): string {
    if (
      !this.utils.isEmpty(this.user) &&
      !this.utils.isEmpty(this.user.state) &&
      !this.utils.isEmpty(this.user.state.active)
    ) {
      return this.stateUserPipe.transform(this.user.state.description);
    }
    return this.stateUserPipe.transform(UserState.PENDING);
  }

  public get isDriver(): boolean {
    return this.role === this.roles.DRIVER;
  }

  public get showCheckValidation(): boolean {
    return (this.isDriver) ||
      (this.authService.getCompany().companyId !== environment.rootNit && !!(this.relatedInfo && this.relatedInfo.vehicle && this.relatedInfo.vehicle.administrator) && this.role === Roles.ADMIN && this.relatedInfo.vehicle.administrator.documentTypeId && this.relatedInfo.vehicle.administrator.documentTypeId !== '3') ||
      (this.authService.getCompany().companyId !== environment.rootNit && !!(this.relatedInfo && this.relatedInfo.vehicle && !this.relatedInfo.vehicle.administrator && ((this.relatedInfo.vehicle.driver && this.relatedInfo.vehicle.driver.document && this.relatedInfo.vehicle.owner && this.relatedInfo.vehicle.owner.document !== this.relatedInfo.vehicle.driver.document) || (!this.relatedInfo.vehicle.driver))) && this.role === Roles.OWNER && this.relatedInfo.vehicle.owner.documentTypeId && this.relatedInfo.vehicle.owner.documentTypeId !== '3');
  }

  public get showCheckValidationIndividual(): boolean {
    return this.authService.getCompany().companyId === environment.rootNit && !this.isDriver && (
      (!!(this.relatedInfo && this.relatedInfo.vehicle && this.relatedInfo.vehicle.administrator) && this.role === Roles.ADMIN && this.relatedInfo.vehicle.administrator.documentTypeId && this.relatedInfo.vehicle.administrator.documentTypeId !== '3') ||
      (!!(this.relatedInfo && this.relatedInfo.vehicle && !this.relatedInfo.vehicle.administrator && (
        (this.relatedInfo.vehicle.driver && this.relatedInfo.vehicle.driver.document && this.relatedInfo.vehicle.owner && this.relatedInfo.vehicle.owner.document !== this.relatedInfo.vehicle.driver.document) ||
        (!this.relatedInfo.vehicle.driver)
      )) && this.role === Roles.OWNER && this.relatedInfo.vehicle.owner.documentTypeId && this.relatedInfo.vehicle.owner.documentTypeId !== '3'));
  }

  public get showCheckValidationUser(): boolean {
    try {
      const isUserActive = this.user.state.active;
      const userStateDescription = this.user.state.description;
      const isVehicleActive = this.relatedInfo.vehicle.state.active;
      const vehicleStateDescription = this.relatedInfo.vehicle.state.description;
      const documentType = this.user.information.documentTypeId;
      if (documentType !== '3' && ((!this.utils.isDefined(isUserActive) || (this.utils.isDefined(isUserActive) && userStateDescription === UserState.PENDING)) ||
        (!this.utils.isDefined(isVehicleActive) || (this.utils.isDefined(isVehicleActive) && vehicleStateDescription === UserState.PENDING)))) {
        return true;
      }
      return false;
    } catch (error) {
      if (!this.utils.objIsEmpty(this.user) && this.relatedInfo && this.relatedInfo.vehicle) {
        const userState = this.user.state;
        const vehicleState = this.relatedInfo.vehicle.state;
        const documentType = this.user.information.documentTypeId;
        if (documentType !== '3' && (!this.utils.isDefined(userState) ||
          !this.utils.isDefined(vehicleState))) {
          return true;
        }
      }
      return false;
    }
  }
  public canEditAdmin() {
    return this.permissionRole.hasPermission(
      this.permission.administration.module,
      this.permission.administration.editAdminVehicle
    );
  }

  get showDriverDetail(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.readDriverDetails);
  }

}
