import { Component, OnInit } from '@angular/core';
import { TrailersService } from '../trailers.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Trailer } from 'src/app/core/interfaces/trailer';
import { PaginationList } from 'src/app/core/models/pagination-list';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/authentication.service';

@Component({
  selector: 'app-list-trailers',
  templateUrl: './list-trailers.component.html',
  styleUrls: ['./list-trailers.component.scss']
})
export class ListTrailersComponent implements OnInit {
  paginationList: PaginationList = new PaginationList();
  filters: FormGroup = new FormGroup({
    id: new FormControl(''),
    owner: new FormControl(''),
    vehicle: new FormControl(''),
  });
  pageKey = 1;
  pageSize = 20;
  constructor(
    private router: Router,
    private trailersService: TrailersService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.search();
  }

  search() {
    this.resetPaginationList();
    this.getTrailers();
  }

  getTrailers() {
    const holderCompanyId: string = this.authService.getUserSession() && this.authService.getUserSession().clientCompanyId ? this.authService.getUserSession().clientCompanyId : null;
    this.trailersService.getTrailers(
      this.paginationList.getPageKey(),
      this.paginationList.getPageSize(),
      this.filters.get('id').value,
      this.filters.get('owner').value,
      this.filters.get('vehicle').value,
      holderCompanyId
    ).subscribe(
      (trailers: Trailer[]) => {
        this.paginationList.setList([...this.paginationList.getList(), ...trailers])
      }
    );
  }

  onScrollDown() {
    if (this.paginationList.getEnablePagingScroll()) {
      this.paginationList.setPageKey(
        this.paginationList.getPageKey() + 1
      );
      this.getTrailers();
    }
  }

  cleanFilters() {
    this.filters.patchValue({ id: '', owner: '', vehicle: '' });
    this.search();
  }

  resetPaginationList() {
    this.paginationList.setEnablePagingScroll(true);
    this.paginationList.setList([]);
    this.paginationList.setPageKey(1);
  }

  create() {
    this.router.navigate(['administration', 'trailers', 'create']);
  }
}
