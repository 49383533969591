import { HttpClient, HttpEvent, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { Cargo } from "../interfaces/cargo";
import { ParamsCargoList } from "../interfaces/paramsCargoList";
import { Endpoints } from "../resources/endpoints";
import { Utils } from "../resources/utils";
import { FirebaseApi } from "../classes/firebase-api";
import { DateManager } from "../managers/date.manager";
import { FulfillmentCargo } from "../interfaces/fulfillmentDocument";

@Injectable({ providedIn: 'root' })
export class CargoService {
  public onChange: EventEmitter<Cargo> = new EventEmitter();
  unsubscribeCargoChanges;
  public static secondsLeftToUploadEvidences = null;
  public static timeLeftToUploadEvidences = null;
  private static uncares: Array<Cargo> = [];
  public static onUncareStacked: EventEmitter<any> = new EventEmitter();
  constructor(
    private http: HttpClient,
    private endpoints: Endpoints,
    public utils: Utils,
  ) { }

  public static cron() {
    if (this.secondsLeftToUploadEvidences !== null && this.secondsLeftToUploadEvidences > 0) {
      this.timeLeftToUploadEvidences = DateManager.secondsToCronometer(this.secondsLeftToUploadEvidences);
      this.secondsLeftToUploadEvidences--;
      setTimeout(() => this.cron(), 1000);
    } else {
      this.secondsLeftToUploadEvidences = null;
      this.timeLeftToUploadEvidences = null;
    }
  }

  public cargoList(paramsCargoList: ParamsCargoList, customeFilters?: string): Observable<Cargo[]> {
    let params = new HttpParams();

    if (!this.utils.isEmpty(paramsCargoList.pageKey)) {
      params = params.append('pageKey', paramsCargoList.pageKey.toString());
    }
    if (!this.utils.isEmpty(paramsCargoList.pageSize)) {
      params = params.append('pageSize', paramsCargoList.pageSize.toString());
    }
    if (!this.utils.isEmpty(paramsCargoList.state)) {
      params = params.append('state', paramsCargoList.state);
    }
    if (!this.utils.isEmpty(paramsCargoList.states)) {
      params = params.append('states', JSON.stringify(paramsCargoList.states));
    }
    if (!this.utils.isEmpty(paramsCargoList.anomalies)) {
      params = params.append('anomalies', JSON.stringify(paramsCargoList.anomalies));
    }
    if (!this.utils.isEmpty(paramsCargoList.news)) {
      params = params.append('news', paramsCargoList.news);
    }
    if (!this.utils.isEmpty(paramsCargoList.nit)) {
      params = params.append('nit', paramsCargoList.nit);
    }
    if (!this.utils.isEmpty(paramsCargoList.exclusive)) {
      params = params.append('exclusive', paramsCargoList.exclusive.toString());
    }
    if (!this.utils.isEmpty(paramsCargoList.dateLoad)) {
      params = params.append('dateLoad', paramsCargoList.dateLoad);
    }
    if (!this.utils.isEmpty(paramsCargoList.endDate)) {
      params = params.append('endDate', paramsCargoList.endDate);
    }
    if (!this.utils.isEmpty(paramsCargoList.creationDateInitial)) {
      params = params.append('creationDateInitial', paramsCargoList.creationDateInitial);
    }
    if (!this.utils.isEmpty(paramsCargoList.creationDateFinal)) {
      params = params.append('creationDateFinal', paramsCargoList.creationDateFinal);
    }
    if (!this.utils.isEmpty(paramsCargoList.journal)) {
      params = params.append('journal', paramsCargoList.journal.toString());
    }
    if (!this.utils.isEmpty(paramsCargoList.assignedDriver)) {
      params = params.append('assignedDriver', paramsCargoList.assignedDriver.toString());
    }

    if (customeFilters) {
      const cleanedString = customeFilters.startsWith('&') ? customeFilters.slice(1) : customeFilters;
      const paramsArray = cleanedString.split('&');

      paramsArray.forEach(param => {
        const [key, value] = param.split('=');
        params = params.append(key, value);
      });
    }

    return this.http.get<Cargo[]>(
      `${environment.urlServerTeclogi}${this.endpoints.urlCargoList}`,
      { params }
    );
  }

  public moveAdvance(origin: string, destination: string) {
    return this.http.post(
      `${environment.urlServerTeclogi}${this.endpoints.cargoMoveAdvance}?originCargoConsecutive=${origin}&destinationCargoConsecutive=${destination}`,
      null
    );
  }

  public cargoListByFilters(paramsFilter: string): Observable<Cargo[]> {
    let url: string = environment.urlServerTeclogi + this.endpoints.urlCargoList + paramsFilter;
    return this.http.get<Cargo[]>(url);
  }

  public downloadCargoTraceability(cargoId: string) {
    return this.http.get(
      `${environment.urlServerTeclogi}${this.endpoints.downloadCargoTraceability}${cargoId}`,
      { responseType: 'blob' }
    );
  }

  public updateFields(cargo: Cargo) {
    return this.http.put(`${environment.urlServerTeclogi}${this.endpoints.updateCargo}`, cargo);
  }

  public watch(cargoId: string) {
    this.unsubscribeCargoChanges = FirebaseApi.firestore.collection('Cargo').doc(cargoId).onSnapshot((snapshot) => {
      const cargo = snapshot.data() as Cargo;
      this.onChange.emit(cargo);
    });
  }

  public stopWatch() {
    if (this.unsubscribeCargoChanges) this.unsubscribeCargoChanges();
  }

  public registerVehicleInMonitor(cargoId: string, start: string, end: string) {
    const fStartDate = DateManager.formatDate(start);
    const fEndDate = DateManager.formatDate(end);

    return this.http.post(`${environment.urlServerTeclogi}${this.endpoints.registerVehicleInMonitor}`, {
      cargoId: cargoId,
      startDate: fStartDate,
      endDate: fEndDate
    });
  }

  public initialFulfillConsignment(cargo: Cargo) {
    const consignments = {
      cargoId: cargo.id,
      consignment: []
    };

    for (const destination of cargo.cargoFeature.uploadDownload.destination) {
      for (const address of destination.addresses) {
        if (address.consignments && address.consignments[0])
          consignments.consignment.push({
            id: address.consignments[0], // RemeTec
            dateTimeDownload: address.durationTime.endDate,
            destinationId: destination.id,
            addressId: address.id
          })
      }
    }

    return this.http.post(`${environment.urlServerTeclogi}${this.endpoints.initialFulfillConsignments}`, consignments);
  }

  static get cargos(): Cargo[] {
    return CargoService.uncares;
  }

  public static stack(cargos: Cargo[]) {
    CargoService.uncares = cargos;
    if (cargos && cargos.length) CargoService.onUncareStacked.emit(cargos);
  }

  public approvedBelowSiceTac(cargoId: string) {
    return this.http.put(
      environment.urlServerTeclogi + this.endpoints.approvedBelowSiceTac + cargoId + "&approved=true", null
    )
  }

  public getCargoFulFillment(cargoId: string, addressId: number, destinationId: number): Observable<FulfillmentCargo[]> {
    let params = new HttpParams();
    params = params.append('cargoId', cargoId);
    params = params.append('addressId', addressId.toString());
    params = params.append('destinationId', destinationId.toString());
    return this.http.get<FulfillmentCargo[]>(
      `${environment.urlServerTeclogi}${this.endpoints.cargoCompliments}`,
      { params }
    );
  }

  public updateCargoFulFillment(body: FulfillmentCargo): Observable<FulfillmentCargo> {
    return this.http.put<FulfillmentCargo>(
      `${environment.urlServerTeclogi}${this.endpoints.cargoCompliments}`,
      body
    );
  }

  public createCargoFulFillment(body: FulfillmentCargo): Observable<FulfillmentCargo> {
    return this.http.post<FulfillmentCargo>(
      `${environment.urlServerTeclogi}${this.endpoints.cargoCompliments}`,
      body
    );
  }

}
