<div class="mb-3 mx-3 p-0 maintenance" *ngIf="maintenance">
  <div class="maintenance__container">
    <div class="maintenance__header d-flex flex-column flex-sm-row justify-content-between align-items-center px-3 px-sm-4 py-2 py-sm-0">
      <div class="d-flex align-items-center maintenance__header--title pb-2 pb-sm-0">
        <p class="text-bold m-0">
          {{maintenance?.maintenanceType?.name ? maintenance.maintenanceType.name : '-'}}
        </p>
        <p *ngIf="maintenance?.expirationDate" class="text-bold m-0" [style.color]="getCalculateMaintenancePeriod(maintenance?.expirationDate) <= 0 ? '#E2574C' : '#198754'">
          {{ this.getCalculateMaintenancePeriod(maintenance?.expirationDate) <= 0 ? 'Vencido' : 'Vigente' }}
        </p>
      </div>
      <div class="d-flex align-items-center maintenance__header--actions" *ngIf="enableToActions">
        <p
        class="text-bold m-0 color-primary cursor-pointer"
        (click)="editMaintenance(maintenance)"
        permission-display
        [module]="permission.administration.module"
        [functionality]="permission.administration.editMaintenance">
          <i class="fas fa-pen"></i><u><span>Editar</span></u>
        </p>
        <p
        class="text-bold m-0 cursor-pointer"
        (click)="confirmDelete(maintenance.id)"
        permission-display
        [module]="permission.administration.module"
        [functionality]="permission.administration.deleteMaintenance">
          <i class="fas fa-trash-alt"></i><u><span>Eliminar</span></u>
        </p>
      </div>
    </div>
    <div class="maintenance__body row p-0 pt-3 m-0 mx-4">

      <!-- Km at the time -->
      <div class="col-12 col-sm-6 col-md-4 col-lg maintenance__km-at-time d-flex flex-column justify-content-center p-0 m-0 pb-3 px-2">
        <span class="maintenance__km-at-time--title">
          Km al momento de realizar
        </span>
        <span class="text-bold color-primary-dark maintenance__km-at-time--value">
          {{ maintenance?.mileage ? (maintenance.mileage | number: '1.0-0') + 'Km': '-' }}
        </span>
      </div>

      <!-- Execution date -->
      <div class="col-12 col-sm-6 col-md-4 col-lg maintenance__execution-date d-flex flex-column justify-content-center p-0 m-0 pb-3 px-2">
        <span class="maintenance__execution-date--title">
          Fecha de ejecución
        </span>
        <span class="text-bold color-primary-dark maintenance__execution-date--value">
          {{ maintenance?.executionDate ? getMaintenanceDatesFormatted(maintenance.executionDate) : '-' }}
        </span>
      </div>

      <!-- Expiration date -->
      <div class="col-12 col-sm-6 col-md-4 col-lg maintenance__expiration-date d-flex flex-column justify-content-center p-0 m-0 pb-3 px-2">
        <span class="maintenance__expiration-date--title">
          Fecha de vencimiento
        </span>
        <span class="text-bold color-primary-dark maintenance__expiration-date--value">
          {{ maintenance?.expirationDate ? getMaintenanceDatesFormatted(maintenance.expirationDate) : '-' }}
        </span>
      </div>

      <!-- Period of validity -->
      <div class="col-12 col-sm-6 col-md-4 col-lg maintenance__period-validity d-flex flex-column justify-content-center p-0 m-0 pb-3 px-2">
        <span class="maintenance__period-validity--title">
          Periodo de vigencia
        </span>
        <span class="text-bold color-primary-dark maintenance__period-validity--value">
          {{ maintenance?.expirationDate ? getCalculateMaintenancePeriod(maintenance?.expirationDate) <= 0 ? '0 día(s)' : getCalculateMaintenancePeriod(maintenance?.expirationDate) + ' día(s)' : '-' }}
        </span>
      </div>

      <!-- Alert at the end of the term -->
      <div class="col-12 col-sm-6 col-md-4 col-lg maintenance__alert d-flex flex-column justify-content-center p-0 m-0 pb-3 px-2">
        <span class="maintenance__alert--title">
          Alerta al terminar vigencia
        </span>
        <span class="text-bold color-primary-dark maintenance__alert--value">
          {{ maintenance?.alert ? 'Sí' : 'No' }}
        </span>
      </div>

      <!-- Evidence -->
      <div class="col-12 col-sm-6 col-md-4 col-lg maintenance__evidence d-flex flex-column justify-content-center p-0 m-0 pb-3 px-2">
        <span class="maintenance__evidence--title">
          Evidencia
        </span>
        <span class="text-bold color-secondary-dark maintenance__evidence--value cursor-pointer" (click)="openMaintenanceEvidences()">
          <u>Ver imágenes</u><i class="fas fa-eye"></i>
        </span>
      </div>

    </div>

    <div class="row m-0 mx-4 p-0 maintenance__type-services" [class.border-detail]="showObservationAndServices" [ngClass]="{ 'show-div': showObservationAndServices, 'hide-div': !showObservationAndServices }">
      <div class="col-12 p-0 pl-2 d-flex flex-column pt-2">
        <span class="maintenance__type-services--title">
          Tipo(s) de servicio
        </span>
         <!-- Type services tags -->
        <div *ngIf="maintenance?.maintenanceRecords?.length; else notRecords" class="maintenance__cards pb-3 pt-3">
          <div *ngFor="let maintenanceRecord of maintenance?.maintenanceRecords">
            <div class="maintenance__cards--card">
              <span>{{maintenanceRecord.name}}</span>
            </div>
          </div>
        </div>
        <ng-template #notRecords>
          <div class="pt-2 pb-3 text-bold color-primary-dark">
            No hay tipo(s) de servicio registrado(s).
          </div>
        </ng-template>
      </div>
    </div>

    <div class="row maintenance__observations p-0 pt-2 mx-4 m-0"  [ngClass]="{ 'show-div': showObservationAndServices, 'hide-div': !showObservationAndServices}">
      <!-- Observations -->
      <div class="col-12 maintenance__observations--content d-flex flex-column justify-content-center p-0 m-0 pb-3 pl-2">
        <span class="maintenance__observations--title">
          Observaciones
        </span>
        <span class="text-bold color-primary-dark maintenance__observations--value">
          <p class="p-0 m-0 maintenance__observations--observation">
            {{ maintenance?.observations ? maintenance.observations : 'No hay observaciones registradas.' }}
          </p>
        </span>
      </div>
    </div>

    <div class="row m-0 p-0">
      <div class="col-12 p-0 m-0 pb-3">
        <p class="p-0 m-0 text-center">
          <u (click)="toggleText()" class="cursor-pointer color-secondary-dark text-bold">
            {{ showObservationAndServices ? 'Ocultar tipos de servicio y observaciones' : 'Ver tipos de servicio y observaciones' }}
            <i class="fas fa-chevron-down maintenance__arrow" [class.rotated]="showObservationAndServices"></i>
          </u>
        </p>
      </div>
    </div>
  </div>
</div>
