import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DriverCargoService } from './driver-cargo.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { AngularFireStorage } from '@angular/fire/storage';
import { Utils } from 'src/app/core/resources/utils';
import * as _ from 'lodash';
import { FileStorage } from 'src/app/core/interfaces/fileStorage';
import { AccountService } from '../../account/account.service';
import { User } from 'src/app/core/interfaces/user';
import { VehiclesService } from '../../administration/vehicles/list-vehicles.service';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { UserFormComponent } from '../../administration/admin-users/user-form/user-form.component';
import { AdminUsersService } from '../../administration/admin-users/admin-users.service';
import { DetailVehicleComponent } from '../../administration/vehicles/detail-vehicle/detail-vehicle.component';
import { InformationUser } from 'src/app/core/interfaces/informationUser';
import { InputDocumentUserComponent } from 'src/app/shared/input-document-user/input-document-user.component';
import { CreateDriverComponent } from '../../administration/vehicles/create-driver/create-driver.component';
import { Dialog } from 'src/app/core/resources/dialog';
import { DialogSurveyComponent } from 'src/app/shared/dialog-survey/dialog-survey.component';
import { CargoItemService } from '../cargo-item/cargo-item.service';
import { environment } from 'src/environments/environment';
import { OpenDetailManifestsComponent } from '../open-detail-manifests/open-detail-manifests.component';
import { ContainerChecklistComponent } from '../container-checklist/container-checklist.component';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { ChangeDriverComponent } from '../change-driver/change-driver.component';
import { Permission } from 'src/app/core/resources/permission';
import { UserManager } from 'src/app/core/managers/user.manager';
import { Roles } from 'src/app/core/enums/roles.enum';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Companies } from 'src/app/core/resources/companies';
import { CargoManager } from 'src/app/core/managers/cargo.manager';
import { TRIP_TYPES } from 'src/app/core/enums/tripTypes.enum';

@Component({
  selector: 'app-driver-cargo',
  templateUrl: './driver-cargo.component.html',
  styleUrls: ['./driver-cargo.component.scss'],
  providers: [AuthService, Global, DriverCargoService, UserManager]
})

export class DriverCargoComponent implements OnInit {
  @Input() cargo: Cargo;
  @Input() expireArl: boolean = false;
  @Input() expireEps: boolean = false;
  @Input() driver?: User;
  @Input() owner?: User;
  @Input() onlyDriver: boolean;
  @Input() isNegotiation: boolean;
  @Input() idVehicle?: string;
  @Input() vehicle?: Vehicle;
  @Input() vehicleAll?: Vehicle;
  @Input() administrator?: InformationUser;
  @Output() emitDriver: EventEmitter<any> = new EventEmitter();
  @Output() emitVehicle: EventEmitter<any> = new EventEmitter();
  @Output() containerInspectionSaved: EventEmitter<any> = new EventEmitter();
  @Output() emitNotOwner: EventEmitter<any> = new EventEmitter();
  public lastInspection;
  public lastContainerInspection;
  public environment = environment;
  public trailer = null;
  instanceLogo: FileStorage;
  basePath: string;
  photo: string;
  userActive = this.authService.getUserSession().information;
  haveVehicle: Vehicle;
  permission = Permission;
  constructor(
    public authService: AuthService,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private service: DriverCargoService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private angularFireStorage: AngularFireStorage,
    public utils: Utils,
    private accountService: AccountService,
    public vehiclesService: VehiclesService,
    public dialog: MatDialog,
    public adminUserService: AdminUsersService,
    private dialogUtils: Dialog,
    private storage: AngularFireStorage,
    public cargoItemService: CargoItemService,
    private userManager: UserManager,
    public permissionRole: PermissionRole,
    private cargoManager: CargoManager
  ) {

  }

  ngOnInit() {
    this.haveVehicle = this.vehicleAll;
    this.getURlPictureDriver();
    this.getDetailVehicle('inspection');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.driver && changes.driver.currentValue && changes.driver.currentValue.profilePicture) {
      this.getURlPictureDriver();
    }
  }

  getDetailDriver(idDriver) {
    this.accountService.validateEntity(1, idDriver).subscribe(
      (data: User) => {
        if (this.utils.isDefined(data)) {
          this.driver = this.userManager.sanitizeUser(data, Roles.DRIVER);
          this.emitDriver.emit(data);
        } else {
          this.setDefaultDriver();
        }
      },
      (error) => {
        this.setDefaultDriver();
      }
    );
  }

  getURlPictureDriver() {
    const storage = AuthService.fStorage;
    if (this.utils.isDefined(this.driver) && this.utils.isDefined(this.driver.profilePicture)) {
      const pathReference = storage.ref(this.driver.profilePicture);
      pathReference.getDownloadURL().then(
        (data) => {
          this.driver.profilePictureUrl = data;
        },
        (error) => {
          this.driver.profilePictureUrl = null;
        }
      );
    }
  }

  setDefaultDriver() {
    this.driver = {
      information: {
        document: this.cargo.driver
      }
    };
  }

  setInstanceFileStorage(e: Event) {

    if (this.utils.isImage(e.target['files'])) {
      this.instanceLogo = {
        fileData: {
          file: e.target['files'][0],
          name: e.target['files'][0]['name'],
          uploaded: true,
          size: this.utils.bytesToSize(e.target['files'][0].size),
          url: null
        },
        storageData: {
          storageRef: null,
          uploadTask: null,
          uploadProgress: null
        }
      };
      this.uploadFileStorage();
    } else {
      this.snackBarService.openSnackBar('El archivo no es una imagen', undefined, 'error')
    }

  }

  uploadFileStorage() {
    this.basePath = 'driver/' + this.driver.information.document + '/';
    const filePath = this.basePath + 'profile.jpg'
    this.instanceLogo.storageData.storageRef = this.angularFireStorage.ref(filePath);
    this.instanceLogo.storageData.uploadTask = this.angularFireStorage.upload(filePath, this.instanceLogo.fileData.file);

  }

  get showPhoneDriver(): boolean {
    return this.permissionRole.hasPermission(Permission.cargo.module, Permission.cargo.readDriverDetails);
  }

  get hasEscortServices(): boolean {
    return this.authService.getCompanySaaS() && this.authService.getCompanySaaS().companyId === Companies.companiesNIT.SEGURIDAD_EXTREMA;
  }

  getPreviewImage(e: Event) {
    this.utils.getImageBase64(e.target['files'][0])
      .then((url: string) => {
        this.photo = url;
      })
      .catch((error) => {
        console.error(error);
      });
  }


  getDetailVehicle(action?) {
    if (this.cargo && this.utils.isDefined(this.cargo.licensePlate)) {
      this.vehiclesService.getVehicle(this.cargo.licensePlate).subscribe(
        (success: any) => {
          if (success) {
            this.vehicle = success;

            if (!!this.vehicle.trailerId) {
              this.vehiclesService
                .getTrailerList(`?id=${this.vehicle.trailerId}`)
                .subscribe(
                  (trailers: any[]) => {
                    if (Array.isArray(trailers)) {
                      this.trailer = trailers[0];
                    }
                  }
                );
            }

            if (action === "emit") {
              this.emitVehicle.emit(success);
            }
            if (action === "inspection") {
              this.getInspect();
            }
          } else {
            this.vehicle = null;
          }
        },
        (error) => {
          this.vehicle = null;
        }
      );
    } else {
      this.vehicle = null;
    }
  }

  get scoreDriver() {
    try {
      return Array(Math.round(this.driver.teclogiScore.globalScore)).fill(0).map((x, i) => i);
    } catch (e) {
      return 0;
    }
  }

  openDetailDriver() {
    if (!this.showDriverDetail) return;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      driver: this.driver,
      expireArl: this.expireArl,
      expireEps: this.expireEps
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(UserFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.getDetailDriver(this.driver.information.document);
    });
  }

  openDetailVehicle() {
    if (!this.showVehicleDetail) return;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      vehicle: this.vehicle
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    const dialogRef = this.dialog.open(DetailVehicleComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.state) {
        this.getDetailVehicle('emit');
      }
    });
  }

  checkValidateVehicle() {
    const vehicleDetail = this.utils.clone(this.vehicle);
    this.spinner.show();
    this.adminUserService.checkValidationVehicle(vehicleDetail.id, false).toPromise()
      .then((success: any) => {
        this.snackBarService.openSnackBar('Validación en proceso', undefined, 'success');
      })
      .catch((error) => {
        this.snackBarService.openSnackBar('Ocurrio un error en la validacion', undefined, 'error');

      })
      .finally(() => {
        this.getDetailDriver(this.driver.information.document);
        this.spinner.hide();
      });

  }

  checkValidationUser() {
    const driverDetail: User = this.utils.clone(this.driver);
    delete driverDetail.profilePictureUrl;
    if (driverDetail && driverDetail.information && driverDetail.information.document) {
      this.spinner.show();
      const $sub = this.adminUserService.checkValidationUser(driverDetail.information.document).subscribe(
        {
          next: (success: any) => {
            if (success && success.driver && success.driver.state && success.driver.state.active) {
            } else {
              this.adminUserService.confirmDriverInactive(this.driver, this.vehicle);
            }
            this.getDetailDriver(this.driver.information.document);
            this.spinner.hide();
          },
          error: () => {
            this.snackBarService.openSnackBar('Ocurrió un error con la petición', undefined, 'error');
            this.getDetailDriver(this.driver.information.document);
            this.spinner.hide();
          },
          complete: () => {
            $sub.unsubscribe();
          }
        }
      )
    } else {
      this.snackBarService.openSnackBar('No fue posible acceder al documento del usuario', undefined, 'error');
      this.getDetailDriver(this.driver.information.document);
    }
  }

  changeDriver() {
    if (this.haveVehicle && this.haveVehicle.owner === undefined) {
      this.emitNotOwner.emit(true);
    } else {
      const formControl = new FormControl();
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: 'Modificación de conductor',
        label: 'Ingresa el número de documento',
        idVehicle: this.idVehicle,
        options: {
          typeUser: 'driver',
        },
        inputFormControl: formControl
      };
      dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
      dialogConfig.autoFocus = false;
      dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
      const modalDialog = this.dialog.open(ChangeDriverComponent, dialogConfig);
      modalDialog.afterClosed().subscribe(result => {
        if (result && result.state && result.data) {
          this.vehicleChangeDriver(result.data);
        }
      });
    }
  }

  vehicleChangeDriver(user: User) {
    this.spinner.show();
    this.vehiclesService.vehicleChangeDriver(this.idVehicle, user).toPromise()
      .then((success: User) => {
        if (success) {
          if (success.errorRNDC && success.errorRNDC && success.errorRNDC.error === 'Debe ingresar un conductor diferente al actual') {
            this.snackBarService.openSnackBar('Debe ingresar un conductor diferente al actual', undefined, 'alert');
          } else {
            this.snackBarService.openSnackBar('Conductor asignado correctamente');
            this.getDetailDriver(this.driver.information.document);
          }
        } else {
          this.snackBarService.openSnackBar('Ocurrió un error al cambiar de conductor', undefined, 'error');
        }
      })
      .catch((error) => {
        this.snackBarService.openSnackBar('Ocurrió un error al cambiar de conductor', undefined, 'error');
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  public getInspect() {
    if ((this.cargo && this.cargo.vehicleInspection) || (this.vehicle && this.vehicle.lastInspection)) {
      this.cargoItemService.getInspection(this.cargo.vehicleInspection ? this.cargo.vehicleInspection : this.vehicle.lastInspection, "vehicle").subscribe(res => {
        this.lastInspection = res;
      }, (error) => {
        console.error(error);
      });
    }

    if (this.cargo && (this.cargo.trailerInspection || this.cargo.containerInspectionId)) {
      this.cargoItemService.getInspection(this.cargo.trailerInspection ? this.cargo.trailerInspection : this.cargo.containerInspectionId, "container").subscribe(res => {
        this.lastContainerInspection = res;
      }, (error) => {
        console.error(error);
      });
    }
  }

  public requiredInspection(): boolean {
    return [TRIP_TYPES.NATIONAL, TRIP_TYPES.INTERNATIONAL].includes(this.cargoManager.getTypeCargo(this.cargo)) && !this.hasEscortServices;
  }

  public createInspection() {
    this.cargoManager.checkInspection(this.cargo, this.authService.getUserSession().information.document, this.cargo.licensePlate)
      .then((state) => {
        if (state) {
          this.containerInspectionSaved.emit(this.cargo.consecutive);
          this.getDetailVehicle('inspection');
        }
      }).catch(() => { });
  }

  public openInspectionVehicle() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      inspect: this.lastInspection,
      disabled: true,
      user: this.userActive,
      licensePlate: this.vehicle.id,
      id: this.cargo.id
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogSurveyComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      this.getDetailVehicle('inspection');
    });
  }

  public openManifests() {
    const dialogConfig = new MatDialogConfig();
    const title = `Manifiestos asociados`;
    dialogConfig.data = {
      title: title,
      manifest: this.driver.manifests,
      driverName: this.driver.information.name
    };
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(OpenDetailManifestsComponent, dialogConfig);

  }

  public openContainerChecklist() {
    const dialogConfig = new MatDialogConfig();
    const title = `Inspección a las unidades de carga`;
    dialogConfig.data = {
      title: title,
      manifest: this.driver.manifests,
      driverName: this.driver.information.name,
      user: this.userActive,
      licensePlate: this.vehicle.id,
      disabled: false,
      cargoId: this.cargo.id,
      cargoContainer: this.cargo.container,
      inspect: this.lastContainerInspection,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(ContainerChecklistComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
      this.containerInspectionSaved.emit(this.cargo.consecutive);
      this.getDetailVehicle('inspection');
    });
  }

  get showVehicleDetail(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.readVehicleDetails);
  }

  get showDriverDetail(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.readDriverDetails);
  }

  public get hasContainer() {

    /*let canPortContainer = false;
    if (!!this.trailer) {
      const portators = [
        "PLATAFORMA",
        "PLANCHON",
        "ESTACAS",
        "SRS",
        "NIÑERA",
        "CAMABAJA",
        "PORTA CONTENEDOR",
        "PLATAFORMA CON ESTACAS DESMONTABLES",
        "MODULAR",
      ];
      canPortContainer = (portators.indexOf(this.trailer["bodywork"].description) !== -1);
    }*/

    return (this.cargo && !!this.cargo.container) || this.trailer;
  }
}
