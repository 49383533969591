import { DatePipe } from '@angular/common';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { TRIP_TYPES } from 'src/app/core/enums/tripTypes.enum';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Permission } from 'src/app/core/resources/permission';
import { Utils } from 'src/app/core/resources/utils';
import { ManualCreationCargoService } from '../manual-creation-cargo/manual-creation-cargo.service';
import { Global } from 'src/app/core/resources/global';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Fmt } from 'src/app/core/messages/fmt';
import { Patterns } from 'src/app/core/resources/patterns';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { CatalogItem } from 'src/app/core/interfaces/catalogItem';
import { CargoManager } from 'src/app/core/managers/cargo.manager';
import { GoogleService } from 'src/app/core/services/google.service';
import { VehicleBodyWorkTypeComponent } from '../manual-creation-cargo/components/vehicle-body-work-type/vehicle-body-work-type.component';
import { AutocompleteThirdpartyAddressComponent } from 'src/app/shared/autocomplete-thirdparty-address/autocomplete-thirdparty-address.component';

@Component({
  selector: 'app-create-escort-service',
  templateUrl: './create-escort-service.component.html',
  styleUrls: ['./create-escort-service.component.scss']
})
export class CreateEscortServiceComponent implements OnInit {

  @ViewChild(VehicleBodyWorkTypeComponent, { static: false }) bodyworkTypeComponent: VehicleBodyWorkTypeComponent;
  @ViewChildren(AutocompleteThirdpartyAddressComponent) thirdPartyAddressesComponent: QueryList<AutocompleteThirdpartyAddressComponent>;
  permission = Permission;
  form: FormGroup;
  formAux: FormGroup;

  optionsCompany = {
    title: 'Nombre del cliente',
    optionAll: false,
    appearance: 'outline'
  };

  readonly timeList: Array<{ title: string, value: string }> = this.utils.getListTime();
  readonly minStartDate = new Date();
  originCityOptions = {
    title: 'Origen (Municipio o Ciudad)',
    appearance: 'outline',
    requireFullCity: true
  };
  originThirdPartyOptions = {
    title: "Remitente",
    appearance: 'outline'
  };
  minEndDate = new Date();
  destinationCityOptions = {
    title: 'Destino (Municipio o Ciudad)',
    appearance: 'outline',
    requireFullCity: true
  };
  destinationThirdPartyOptions = {
    title: "Destinatario",
    appearance: 'outline'
  };

  readonly tripTypes: { name: TRIP_TYPES }[] = [
    { name: TRIP_TYPES.IMPORT },
    { name: TRIP_TYPES.EXPORT },
    { name: TRIP_TYPES.NATIONAL },
    { name: TRIP_TYPES.URBAN },
    { name: TRIP_TYPES.LAST_MILE }
  ];
  tripTypesEnabled: { name: TRIP_TYPES }[];
  readonly riskProfiles = this.global.riskProfiles;

  documentTypes: CatalogItem[] = this.utils.clone(this.global.documenTypes);
  optionsTypeVehicle: OptionsAutocomplete = {
    showAutocomplete: true
  };
  optionsBodyWorkType: OptionsAutocomplete = {
    title: 'Tipo de carrocería'
  };

  formValidate = '';
  subscriptions: Subscription = new Subscription();

  constructor(
    private manualCreationCargoService: ManualCreationCargoService,
    private cargoManager: CargoManager,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private googleService: GoogleService,
    public datePipe: DatePipe,
    public dialogMaterial: MatDialog,
    public utils: Utils,
    private global: Global,
    private patterns: Patterns,
  ) { }

  ngOnInit() {
    this.documentTypes.splice(2, 1);
    this.initForm();
    this.setSubscriptions();
  }

  private initForm() {
    this.form = new FormGroup({
      dateLoad: new FormControl(''),
      ministry: new FormControl(false),
      idCompany: new FormControl(''),
      numberDocumentSender: new FormControl(),
      cargoModel: new FormGroup({
        tripType: new FormGroup({
          name: new FormControl('', Validators.required)
        }),
        serviceType: new FormGroup({
          id: new FormControl("escortServices"),
          name: new FormControl('Servicio de escolta'),
          cost: new FormControl(0)
        }),
      }),
      riskProfile: new FormGroup({
        name: new FormControl(),
        id: new FormControl()
      }),
      cargoFeature: new FormGroup({
        vehicleType: new FormGroup({
          name: new FormControl(''),
          quantity: new FormControl(1),
          bodyWorkType: new FormGroup({
            name: new FormControl(''),
          }),
        }),
        uploadDownload: new FormGroup({
          origin: new FormGroup({
            name: new FormControl(''),
            municipalityCode: new FormControl(''),
            country: new FormGroup({
              name: new FormControl("Colombia"),
              code: new FormControl("CO")
            }),
            addresses: new FormArray([new FormGroup({
              id: new FormControl("0"),
              address: new FormControl(''),
              location: new FormGroup({
                lat: new FormControl(''),
                lng: new FormControl('')
              }),
              time: new FormControl(''),
              thirdPartyConsignment: new FormGroup({
                documentType: new FormControl(''),
                documentNumber: new FormControl(''),
                name: new FormControl(''),
                branchOffice: new FormControl(''),
              }),
            })]),
          }),
          destination: new FormArray([new FormGroup({
            id: new FormControl("0"),
            name: new FormControl(''),
            municipalityCode: new FormControl(''),
            downloadDate: new FormControl(''),
            country: new FormGroup({
              name: new FormControl("Colombia"),
              code: new FormControl("CO")
            }),
            addresses: new FormArray([new FormGroup({
              id: new FormControl("0"),
              address: new FormControl(''),
              location: new FormGroup({
                lat: new FormControl(),
                lng: new FormControl()
              }),
              time: new FormControl(),
              thirdPartyConsignment: new FormGroup({
                documentType: new FormControl(''),
                documentNumber: new FormControl(''),
                name: new FormControl(''),
                branchOffice: new FormControl(''),
              }),
            })]),
          })
          ])
        }),
      }),
      licensePlate: new FormControl(),
      shippingCost: new FormGroup({
        rate: new FormControl(0, [Validators.required, Validators.min(1)]),
        totalCost: new FormControl(0),
        freightCost: new FormControl(0),
        reteftePercentage: new FormControl(1),
        reteicaPercentage: new FormControl(0),
        advancePercentage: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
        paymentTime: new FormControl(null, [Validators.required, Validators.min(0)]),
      }),
      escortedVehicle: new FormGroup({
        licensePlate: new FormControl('', Validators.required),
        brand: new FormControl(),
        color: new FormControl(),
        vehicleType: new FormControl(),
        container: new FormControl('', Validators.pattern(this.patterns.CONTAINER_FORMAT_FULL.source)),
        driver: new FormGroup({
          documentTypeId: new FormControl("1"),
          documentTypeName: new FormControl("Cédula de ciudadanía"),
          document: new FormControl(),
          name: new FormControl('', Validators.required),
          phoneNumber: new FormControl('', Validators.required)
        }),
      }),
      cargoOwner: new FormGroup({
        documentType: new FormControl("3"),
        documentNumber: new FormControl(),
        name: new FormControl()
      }),
      observation: new FormControl(),
      observationDriver: new FormControl()
    });
    this.formAux = new FormGroup({
      company: new FormControl('', Validators.required),
      originCity: new FormControl('', Validators.required),
      originDate: new FormControl('', Validators.required),
      originTime: new FormControl('', Validators.required),
      originTimeType: new FormControl('AM', Validators.required),
      originThirdParty: new FormControl('', Validators.required),
      originThirdPartyConsignment: new FormControl('', Validators.required),
      destinationCity: new FormControl('', Validators.required),
      destinationDate: new FormControl('', Validators.required),
      destinationTime: new FormControl('', Validators.required),
      destinationTimeType: new FormControl('AM', Validators.required),
      destinationThirdParty: new FormControl('', Validators.required),
      destinationThirdPartyConsignment: new FormControl('', Validators.required),
      riskProfile: new FormControl(),
      //Vehículo a escoltar
      vehicleType: new FormControl(),
      documentType: new FormControl({
        id: "1",
        name: "Cédula de ciudadanía"
      }),
      //Vehículo escolta
      escortVehicleType: new FormControl('', Validators.required),
      escortBodyWorkType: new FormControl('', Validators.required),
      cost: new FormControl(0, [Validators.required, Validators.min(1)]),
    })
    this.tripTypesEnabled = this.utils.clone(this.tripTypes);
    this.minEndDate = new Date();
  }

  private setSubscriptions() {
    const originSub = this.formAux.get('originCity').valueChanges.subscribe(() => this.checkTripTypes());
    const destinationsSub = this.formAux.get('destinationCity').valueChanges.subscribe(() => this.checkTripTypes());
    this.subscriptions.add(originSub);
    this.subscriptions.add(destinationsSub);
  }

  private checkTripTypes() {
    const originCity: { id: string, name: string } = this.formAux.get('originCity').value;
    const destinationCity: { id: string, name: string } = this.formAux.get('destinationCity').value;
    if (!originCity || !originCity.name || !destinationCity || !destinationCity.name) {
      this.tripTypesEnabled = this.utils.clone(this.tripTypes);
      return;
    }
    if (destinationCity.name === originCity.name)
      this.tripTypesEnabled = [
        { name: TRIP_TYPES.IMPORT },
        { name: TRIP_TYPES.EXPORT },
        { name: TRIP_TYPES.URBAN },
        { name: TRIP_TYPES.LAST_MILE }
      ];
    else
      this.tripTypesEnabled = [
        { name: TRIP_TYPES.IMPORT },
        { name: TRIP_TYPES.EXPORT },
        { name: TRIP_TYPES.NATIONAL }
      ];

    if (!this.tripTypesEnabled.includes(this.form.get('cargoModel.tripType.name').value))
      this.form.get('cargoModel.tripType.name').setValue(null);
  }

  updateDestinationDates() {
    let minDate: Date = new Date();
    if (this.formAux.get('originDate').value)
      minDate = new Date(this.formAux.get('originDate').value);

    this.minEndDate = minDate;
    const downloadDate: Date = this.formAux.get('destinationDate').value;
    if (downloadDate && DateManager.isBefore(downloadDate, minDate))
      this.formAux.get('destinationDate').setValue(minDate);
  }

  checkForm() {
    this.form.markAllAsTouched();
    this.formAux.markAllAsTouched();
    this.formValidate = 'touched';

    if (this.utils.errorMessagesCustomized(this.formAux.get('company'), 'nombre del cliente')) return;
    if (this.showErrorReteica) {
      this.snackBarService.openSnackBar("La ciudad de origen no tiene asignado un ReteIca por favor asocielo o cambie de ciudad", undefined, 'alert');
      return;
    }
    const originDestinationFields = {
      originCity: "ciudad de origen",
      originDate: "fecha de origen",
      originTime: "hora de origen",
      originThirdParty: "remitente",
      originThirdPartyConsignment: "dirección de remitente",
      destinationCity: "ciudad de destino",
      destinationDate: "fecha de destino",
      destinationTime: "hora de destino",
      destinationThirdParty: "destinatario",
      destinationThirdPartyConsignment: "dirección de destinatario",
    }
    let originDestinationError = false;
    for (let field of Object.keys(originDestinationFields)) {
      if (this.utils.errorMessagesCustomized(this.formAux.get(field), originDestinationFields[field])) {
        originDestinationError = true;
        break;
      }
    }
    if (originDestinationError) return;
    if (this.showErrorSameAddress) {
      this.snackBarService.openSnackBar("La dirección de origen y destino no puede ser la misma", undefined, 'alert');
      return;
    }

    const initialHours = parseInt(this.formAux.get('originTime').value.split(':')[0]) + (this.formAux.get('originTimeType').value === "PM" && parseInt(this.formAux.get('originTime').value.split(':')[0]) < 12 ? 12 : 0);
    const initialMinutes = parseInt(this.formAux.get('originTime').value.split(':')[1])
    const initialTime: Date = DateManager.setTimeToDate(
      this.formAux.get('originDate').value,
      initialHours,
      initialMinutes
    );
    const finalHours = parseInt(this.formAux.get('destinationTime').value.split(':')[0]) + (this.formAux.get('destinationTimeType').value === "PM" && parseInt(this.formAux.get('destinationTime').value.split(':')[0]) < 12 ? 12 : 0)
    const finalMinutes = parseInt(this.formAux.get('destinationTime').value.split(':')[1]);
    const finalTime: Date = DateManager.setTimeToDate(
      this.formAux.get('destinationDate').value,
      finalHours,
      finalMinutes
    );
    if (DateManager.isSameOrBefore(finalTime, initialTime, "minutes")) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.TIME_INVALID2, 'destino'), undefined, 'alert');
      return;
    }
    if (this.utils.errorMessagesCustomized(this.form.get('cargoModel.tripType.name'), 'tipo de viaje')) return;

    if (this.utils.errorMessagesCustomized(this.form.get('escortedVehicle.licensePlate'), 'placa del vehículo a escoltar')) return;
    if (!this.patterns.GET_REGEX('LICENSE_PLATES').test(this.form.get('escortedVehicle.licensePlate').value)) {
      this.snackBarService.openSnackBar(
        Fmt.string(
          ServiceMessages.LICENSE_PLATE_FORMAT_INCORRECT,
          this.form.get('escortedVehicle.licensePlate').value
        ), undefined, 'alert');
      return;
    }
    if (this.utils.errorMessagesCustomized(this.form.get('escortedVehicle.container'), 'contenedor del vehículo')) return;
    if (this.utils.errorMessagesCustomized(this.form.get('escortedVehicle.driver.name'), 'nombre del conductor a escoltar')) return;
    if (this.utils.errorMessagesCustomized(this.form.get('escortedVehicle.driver.phoneNumber'), 'celular del conductor a escoltar')) return;

    if (this.utils.errorMessagesCustomized(this.formAux.get('escortVehicleType'), 'tipo de vehículo escolta')) return;
    if (this.utils.errorMessagesCustomized(this.formAux.get('escortBodyWorkType'), 'carrocería del vehículo escolta')) return;

    if (this.utils.errorMessagesCustomized(this.formAux.get('cost'), 'flete', null, null, 1)) return;
    if (this.utils.errorMessagesCustomized(this.form.get('shippingCost.rate'), 'tarifa', null, null, 1)) return;

    if (this.utils.errorMessagesCustomized(this.form.get('shippingCost.advancePercentage'), 'porcentaje de anticipo', null, null, 0, 100)) return;
    if (this.utils.errorMessagesCustomized(this.form.get('shippingCost.paymentTime'), 'tiempo de pago del saldo', null, null, 0)) return;

    this.createEscortService(initialHours, initialMinutes, finalHours, finalMinutes);
  }

  private async createEscortService(initialHours: number, initialMinutes: number, finalHours: number, finalMinutes: number) {
    const cargo: Cargo = this.form.value;
    //Seteamos todos los campos que requirieron un formControl auxiliar
    //Compañía del servicio
    cargo.idCompany = this.formAux.get('company').value.companyId;
    cargo.cargoOwner.documentNumber = cargo.idCompany;
    cargo.cargoOwner.name = this.formAux.get('company').value.name;
    //Origen
    cargo.dateLoad = this.datePipe.transform(this.formAux.get('originDate').value, 'yyyy-MM-dd HH:mm Z', 'UTC');
    cargo.cargoFeature.uploadDownload.origin.name = this.formAux.get('originCity').value.name;
    cargo.cargoFeature.uploadDownload.origin.municipalityCode = this.formAux.get('originCity').value.id;
    cargo.cargoFeature.uploadDownload.origin.addresses[0].address = this.formAux.get('originThirdPartyConsignment').value.address.address;
    cargo.cargoFeature.uploadDownload.origin.addresses[0].location = this.formAux.get('originThirdPartyConsignment').value.address.location;
    cargo.cargoFeature.uploadDownload.origin.addresses[0].time = `${initialHours <= 9 ? '0' + initialHours : initialHours}:${initialMinutes ? initialMinutes : '00'}`;
    cargo.cargoFeature.uploadDownload.origin.addresses[0].thirdPartyConsignment.name = this.formAux.get('originThirdParty').value.name;
    cargo.cargoFeature.uploadDownload.origin.addresses[0].thirdPartyConsignment.branchOffice = this.formAux.get('originThirdPartyConsignment').value.address.id;
    cargo.cargoFeature.uploadDownload.origin.addresses[0].thirdPartyConsignment.documentType = this.formAux.get('originThirdPartyConsignment').value.information.documentTypeId;
    cargo.cargoFeature.uploadDownload.origin.addresses[0].thirdPartyConsignment.documentNumber = this.formAux.get('originThirdPartyConsignment').value.information.document;
    //Destino
    cargo.cargoFeature.uploadDownload.destination[0].downloadDate = this.datePipe.transform(this.formAux.get('destinationDate').value, 'yyyy-MM-dd HH:mm Z', 'UTC');
    cargo.cargoFeature.uploadDownload.destination[0].name = this.formAux.get('destinationCity').value.name;
    cargo.cargoFeature.uploadDownload.destination[0].municipalityCode = this.formAux.get('destinationCity').value.id;
    cargo.cargoFeature.uploadDownload.destination[0].addresses[0].address = this.formAux.get('destinationThirdPartyConsignment').value.address.address;
    cargo.cargoFeature.uploadDownload.destination[0].addresses[0].location = this.formAux.get('destinationThirdPartyConsignment').value.address.location;
    cargo.cargoFeature.uploadDownload.destination[0].addresses[0].time = `${finalHours <= 9 ? '0' + finalHours : finalHours}:${finalMinutes ? finalMinutes : '00'}`;
    cargo.cargoFeature.uploadDownload.destination[0].addresses[0].thirdPartyConsignment.name = this.formAux.get('destinationThirdParty').value.name;
    cargo.cargoFeature.uploadDownload.destination[0].addresses[0].thirdPartyConsignment.branchOffice = this.formAux.get('destinationThirdPartyConsignment').value.address.id;
    cargo.cargoFeature.uploadDownload.destination[0].addresses[0].thirdPartyConsignment.documentType = this.formAux.get('destinationThirdPartyConsignment').value.information.documentTypeId;
    cargo.cargoFeature.uploadDownload.destination[0].addresses[0].thirdPartyConsignment.documentNumber = this.formAux.get('destinationThirdPartyConsignment').value.information.document;
    //Perfil de riesgo
    cargo.riskProfile = this.formAux.get('riskProfile').value;
    //Vehículo a escoltar
    if (this.formAux.get('vehicleType').value && this.formAux.get('vehicleType').value.name)
      cargo.escortedVehicle.vehicleType = this.formAux.get('vehicleType').value.name;
    if (this.formAux.get('documentType').value && this.formAux.get('documentType').value.id) {
      cargo.escortedVehicle.driver.documentTypeId = this.formAux.get('documentType').value.id;
      cargo.escortedVehicle.driver.documentTypeName = this.formAux.get('documentType').value.name;
    }
    //Vehículo escolta
    cargo.cargoFeature.vehicleType.name = this.formAux.get('escortVehicleType').value.name;
    cargo.cargoFeature.vehicleType.bodyWorkType.name = this.formAux.get('escortBodyWorkType').value.name;

    //Flete
    cargo.shippingCost.freightCost = this.formAux.get('cost').value;
    cargo.shippingCost.totalCost = this.formAux.get('cost').value;

    //Reteica
    cargo.shippingCost.reteicaPercentage = this.formAux.get('originCity').value.reteica;
    cargo.shippingCost.reteicaValue = Math.floor(cargo.shippingCost.totalCost * cargo.shippingCost.reteicaPercentage) / 100;

    let { cargoDistancy, cargoEstimatedTime } = await this.googleService.getRouteDataCargo(cargo);
    if (cargoDistancy) cargo['distancy'] = cargoDistancy;
    if (cargoEstimatedTime) cargo['estimatedTime'] = cargoEstimatedTime;

    const validTimes = await this.cargoManager.checkTripTimes(cargo).toPromise();
    if (!validTimes || !validTimes.valid) {
      let message = 'Los tiempos estimados entre las direcciones son imposibles de cumplir.';
      if (validTimes && validTimes.min && validTimes.address) {
        message = `Para llegar a la dirección ${validTimes.address} se requieren al menos `;
        const duration = DateManager.durationFormat(validTimes.min, 'seconds');
        if (duration.hours) message += `${duration.hours} horas `;
        if (duration.minutes) message += `${duration.minutes} minutos `;
        if (duration.seconds) message += `${duration.seconds} segundos `;
      }
      return this.snackBarService.openSnackBar(message, undefined, 'error');
    }

    let path = '/cargo/list/scheduledLoads';
    this.spinner.show();
    this.manualCreationCargoService.createRenewCargo(cargo).subscribe(
      {
        next: (success: Cargo[]) => {
          this.spinner.hide();
          this.manualCreationCargoService.showResponseCreateCargo(
            'Tu solicitud de servicio ha sido creada exitosamente con el consecutivo',
            undefined,
            this.manualCreationCargoService.getConsecutive(success), path
          );
          this.initForm();
          this.cleanValues();
        },
        error: (error) => {
          this.spinner.hide();
          this.manualCreationCargoService.showResponseCreateCargo(
            'Ocurrió un error al crear la solicitud de servicio',
            error
          );
        },
      }
    );
  }

  private cleanValues() {
    this.optionsCompany['initialNit'] = '';
    this.optionsCompany = { ...this.optionsCompany };
    this.originCityOptions['initialValue'] = '';
    this.originCityOptions = { ...this.originCityOptions };
    this.originThirdPartyOptions['initialValue'] = '';
    this.originThirdPartyOptions = { ...this.originThirdPartyOptions };
    this.destinationCityOptions['initialValue'] = '';
    this.destinationCityOptions = { ...this.destinationCityOptions };
    this.destinationThirdPartyOptions['initialValue'] = '';
    this.destinationThirdPartyOptions = { ...this.destinationThirdPartyOptions };
    this.optionsTypeVehicle['initialValue'] = '';
    this.optionsTypeVehicle = { ...this.optionsTypeVehicle };
    this.bodyworkTypeComponent.bodyWorkTypeCtrl.setValue('');
    this.thirdPartyAddressesComponent.forEach(comp => comp.formControlThirdPartyAddress.setValue(''));
    //Clean errors
    this.initForm();
    this.formValidate = 'untouched';
  }

  get showErrorReteica(): boolean {
    return this.formAux.get('originCity').value && !this.formAux.get('originCity').value.reteica
      && (!this.formAux.get('originCity').value.mainCity || !this.formAux.get('originCity').value.mainCity.reteica);
  }

  get showErrorSameAddress(): boolean {
    return this.formAux.get('originThirdPartyConsignment').value && this.formAux.get('destinationThirdPartyConsignment').value
      && JSON.stringify(this.formAux.get('originThirdPartyConsignment').value) === JSON.stringify(this.formAux.get('destinationThirdPartyConsignment').value)
  }

  ngOnDestroy() {
    if (this.subscriptions) this.subscriptions.unsubscribe();
  }
}

