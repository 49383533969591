import { Validators } from '@angular/forms';
import { Patterns } from '../resources/patterns';
import { BankAccount } from './bankAccountAdvance';
import { CompanyConfiguration } from './companyConfiguration';
import { FieldsExcelGuide } from './companyFieldsExcelGuide';
import { Contact } from './contact';
import { CostCenter } from './costCenter';
import { FieldsExcelCargo } from './fieldsExcelCargo';
import { GeneralType } from './generalType';
import { InformationUser } from './informationUser';
import { TeclogiScore } from './teclogiScore';
import { BranchOffice } from './branch-offices';
import { ColorConfiguration } from './colorConfiguration';
import { CompanyResource } from './companyResource';
import { AmountsCargoEnum } from '../enums/amountsCargo.enum';
import { ShippingCost } from '../enums/shipping-cost.enum';
import { EmailStructure } from './emailStructure';
import { Injectable } from '@angular/core';
import { IntegrationCredentials } from './integrationCredentials';
import { RndcCredentials } from './rndcCredentials';
import { BasicRole } from './basicRole';
import { InsuranceInformation } from './insuranceInformation';
import { RndcPrefixes } from './rndcPrefixes';
import { AccountingConfig } from './accountingConfig';
import { GpsConfiguration } from './gps-configuration';
import { AuthService } from '../services/authentication.service';
import { environment } from 'src/environments/environment';
export interface Company {
  onlyDriverCanOperate?: boolean;
  name?: string;
  companyId?: string;
  ministry?: boolean;
  exclusive?: boolean;
  teclogiScore?: TeclogiScore;
  driverVisibility?: boolean;
  webSite?: string;
  emailSubject?: string;
  emailBody?: string;
  fieldsExcelGuide?: FieldsExcelGuide;
  fieldsExcelCargo?: FieldsExcelCargo;
  configuration?: CompanyConfiguration;
  subCompanies?: GeneralType[];
  parentCompany?: GeneralType[];
  logo?: string;
  bankAccounts?: BankAccount[];
  validateFreight?: boolean;
  email?: string;
  contact?: Contact;
  address?: string;
  municipalityCode?: string;
  phone?: string | number;
  insuranceInformation?: InsuranceInformation;
  role?: BasicRole;
  siigoId?: string;
  message?: string;
  businessPartner?: InformationUser;
  costCenter?: CostCenter[];
  utility?: number;
  utilityAdditionalService?: number;
  branchOffices?: BranchOffice[];
  countryCode?: string;
  departmentCode?: string;
  cityCode?: string;
  integration?: boolean;
  integrationCredentials?: IntegrationCredentials[];
  rndcCredentials?: RndcCredentials;
  manifestPrefix?: string;
  consigmentPrefix?: string;
  urbanPrefix?: string;
  nationalPrefix?: string;
  colorConfiguration?: ColorConfiguration;
  rndcPrefix?: RndcPrefixes[];
  resources?: CompanyResource[];
  advancePercentage?: number;
  emails?: EmailStructure[];
  accountingConfig?: AccountingConfig;
  daysToAlertExpirationSOAT?: number;
  daysToBlockSOAT?: number;
  daysToAlertExpirationRTM?: number;
  daysToBlockRTM?: number;
  soatAlertsAndBlocksEnabled?: boolean;
  rtmAlertsAndBlocksEnabled?: boolean;
  allowCreatingWithoutTruora?: boolean;
  daysToDeactivateVehicle?: number;
  gpsConfiguration?: GpsConfiguration
  chargeOwnFleet?: boolean;
}
@Injectable()
export class Model {


  constructor(
    private patterns: Patterns,
    private authService: AuthService
  ) {

  }
  public advancePercentageCompany(): number {
    // If the user logged belongs to teclogi
    let isTeclogi = this.authService.getCompany() && this.authService.getCompany().companyId && (this.authService.getCompany().companyId === environment.rootNit);
    // If the user logged belongs to another company and have advance percentage;
    let authCompany = this.authService.getCompany() && this.authService.getCompany().advancePercentage;

    if (isTeclogi) return AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_TECLOGI;
    if (authCompany) return this.authService.getCompany().advancePercentage;
    return AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_ALL_COMPANIES;
  }

  public model: Company = {
    name: '',
    companyId: '',
    webSite: '',
    logo: '',
    ministry: false,
    validateFreight: false,
    driverVisibility: true,
    exclusive: false,
    email: '',
    onlyDriverCanOperate: false,
    businessPartner: {
      documentTypeId: '',
      documentTypeName: '',
      document: '',
      name: ''
    },
    municipalityCode: '',
    address: '',
    phone: '',
    insuranceInformation: {
      policyNumber: '',
      companyName: '',
      companyId: '',
      expirationDate: '',
    },
    role: {
      id: '',
      name: '',
    },
    utility: ShippingCost.MINIMUN_UTILITY,
    utilityAdditionalService: ShippingCost.MINIMUN_UTILITY_ADDITIONAL_COST,
    advancePercentage: this.advancePercentageCompany(),
    integration: false,
    contact: {
      name: '',
      lastName: '',
      email: ''
    },
    rndcCredentials: {
      userName: '',
      password: '',
    },
    colorConfiguration: {
      primaryColor: "#02D7DC",
      secondaryColor: "#584796"
    },
    accountingConfig: {
      rteFteAccount: '',
      creditAccount: ''
    },
    chargeOwnFleet: false
    // rndcPrefix: [
    //   {
    //     type: 'manifestPrefix',
    //     name: ''
    //   },
    //   {
    //     type: 'consigmentPrefix',
    //     name: ''
    //   },
    //   {
    //     type: 'nationalPrefix',
    //     name: ''
    //   },
    //   {
    //     type: 'urbanPrefix',
    //     name: ''
    //   }
    // ],
  };
  public enabled: Company = {
    name: '',
    webSite: '',
    logo: '',
    ministry: false,
    validateFreight: false,
    driverVisibility: true,
    exclusive: true,
    email: '',
    municipalityCode: '',
    address: '',
    phone: '',
    contact: {
      name: '',
      lastName: '',
      email: ''
    }
  };

  public companyValidators = {
    name: [
      Validators.required,
      Validators.minLength(3)
    ],
    companyId: [
      Validators.required,
      Validators.minLength(9),
      Validators.maxLength(9)
    ],
    ministry: [
      Validators.required,
    ],
    validateFreight: [
      Validators.required,
    ],
    driverVisibility: [
      Validators.required,
    ],
    exclusive: [
      Validators.required,
    ],
    utility: [
      Validators.required,
      Validators.min(0),
      Validators.max(100)
    ],
    advancePercentage: [
      Validators.required,
      Validators.max(AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_ALL_COMPANIES),
      Validators.min(0)
    ],
    email: [
      Validators.required,
      Validators.pattern(this.patterns.EMAIL.source),
      Validators.maxLength(100)
    ],
    municipalityCode: [
      Validators.required
    ],
    address: [
      Validators.required
    ],
    onlyDriverCanOperate: [
      Validators.required,
    ],
    phone: [
      Validators.required,
      Validators.pattern(this.patterns.PHONE.source)
    ],
    contact: {
      name: [
        Validators.required,
        Validators.minLength(3),
      ],
      lastName: [
        Validators.required,
        Validators.minLength(3),
      ],
      email: [
        Validators.required,
        Validators.pattern(this.patterns.EMAIL.source),
        Validators.maxLength(100)
      ]
    }
  };
}

