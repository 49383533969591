<div>
    <mat-expansion-panel class="expansion-p send-report" *ngIf="showSendReport">
        <mat-expansion-panel-header>
            <mat-panel-title class="color-primary">
                <mat-icon>email</mat-icon><span class="ml-2 text-bold">Enviar reporte</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
            <div class="col-10">
                <app-select-company [options]="optionsCompany" [inputFormControl]="companyToOperations"
                    [validate]="validateToOperations">
                </app-select-company>
            </div>
            <div class="col-2 d-flex align-items-center justify-content-center">
                <mat-icon class="cursor-pointer color-primary ml-2" (click)="sendReport()">send</mat-icon>
            </div>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel class="expansion-p" *ngIf="showOperations">
        <mat-expansion-panel-header>
            <mat-panel-title class="color-primary text-bold text-center">
                Operaciones
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
            <!--Totals div-->
            <div class="col-12">
                <span *ngIf="typePaymentByTab==='balance'||options.showFilterAprovedCargo">
                    Total seleccionado({{itemsSelecteds.length}}):
                    {{itemsSelecteds.length?((totalValueBalanceCargos - totalTravelExpensesBalance) |
                    currency:'COP':'code'):'0 COP'}}
                    <span *ngIf="typePaymentByTab==='balance' && totalAdditionalStandBy > 0">
                        , Total adicionales({{totalAdditionalStandBy}}):
                        {{totalAdditionalCostsStandBy|currency:'COP':'code'}}
                    </span>
                </span>
                <span *ngIf="typePaymentByTab==='advance'||typePaymentByTab==='extraAdvance'">
                    Total seleccionado({{itemsSelecteds.length}}):
                    {{itemsSelecteds.length?(totalValueAdvanceCargos | currency:'COP':'code'):'0 COP'}}
                    <span *ngIf="typePaymentByTab==='advance' && totalAdditionals > 0">
                        , Total adicionales({{totalAdditionals}}): {{totalAdditionalCosts|currency:'COP':'code'}}
                    </span>
                </span>
                <span *ngIf="options.showFilterCharges">
                    Total seleccionado({{itemsSelecteds.length}}):
                    {{itemsSelecteds.length?(totalValueRateCargos | currency:'COP':'code'):'0 COP'}}
                </span>
                <span
                    *ngIf="typeList==='paymentAdditionalCostsCargo'&& staticFilters['additionalCost']==='Servicio cargue'">
                    Total seleccionado({{itemsSelecteds.length}}):
                    {{itemsSelecteds.length?(totalValueUploadCargos | currency:'COP':'code'):'0 COP'}}
                </span>
                <span
                    *ngIf="typeList==='paymentAdditionalCostsCargo'&& staticFilters['additionalCost']==='Servicio descargue'">
                    Total seleccionado({{itemsSelecteds.length}}):
                    {{itemsSelecteds.length?(totalValueDownloadCargos | currency:'COP':'code'):'0 COP'}}
                </span>
                <span *ngIf="typeList==='paymentAdditionalCostsCargo'&& staticFilters['additionalCost']==='Stand by'">
                    Total seleccionado({{itemsSelecteds.length}}):
                    {{itemsSelecteds.length?(totalValueStandByCargos | currency:'COP':'code'):'0 COP'}}
                </span>
                <span *ngIf="typeList==='withoutPayment'">
                    Número de seleccionados: {{itemsSelecteds.length}}
                </span>
            </div>
            <div class="col-12"
                *ngIf="canConfirmCargoPayment || canConfirmEntryPayment || canModifyBill || canModifyVoucher">
                <app-select-company [options]="optionsCompany" [inputFormControl]="companyToOperations"
                    [validate]="validateToOperations">
                </app-select-company>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-start cursor-pointer"
                [ngClass]="{'disabled-option':!itemsSelecteds.length}" *ngIf="canPayAdvance || canPayExtraAdvance"
                (click)="openModalPaymentCargo()">
                <i class="fas fa-check-circle my-2" [ngClass]="{'color-primary':itemsSelecteds.length}"></i>
                <div class="text-bold" [ngClass]="{'underline': itemsSelecteds.length}">Pagar servicios</div>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-start cursor-pointer"
                [ngClass]="{'disabled-option':!itemsSelecteds.length}" *ngIf="canPayTravelExpenses"
                (click)="openModalPaymentTravelExpenses()">
                <i class="fas fa-check-circle my-2" [ngClass]="{'color-primary':itemsSelecteds.length}"></i>
                <div class="text-bold" [ngClass]="{'underline': itemsSelecteds.length}">
                    Pagar viáticos
                </div>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-start cursor-pointer"
                *ngIf="canPayAdvance || canPayExtraAdvance" (click)="openModalMassivePaymentCargo()">
                <img src="../../../assets/general-icons/double-check.png" alt="Double check">
                <div class="text-bold underline">Pagar servicios masivamente</div>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-start cursor-pointer"
                [ngClass]="{'disabled-option':!itemsSelecteds.length}" *ngIf="canConfirmCargoPayment"
                (click)="openModalCashedCargo('bill')">
                <i class="fas fa-check-circle my-2" [ngClass]="{'color-primary':itemsSelecteds.length}"></i>
                <div class="text-bold" [ngClass]="{'underline': itemsSelecteds.length}">Confirmar cobro de servicios
                </div>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-start cursor-pointer"
                [ngClass]="{'disabled-option':!itemsSelecteds.length}" *ngIf="canConfirmEntryPayment"
                (click)="openModalCashedCargo('income')">
                <i class="fas fa-check-circle my-2" [ngClass]="{'color-primary':itemsSelecteds.length}"></i>
                <div class="text-bold" [ngClass]="{'underline': itemsSelecteds.length}">Confirmar ingreso de servicios
                </div>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-start cursor-pointer"
                *ngIf="canApproveCargos && staticFilters['approval']!=='Approved'"
                (click)="approveOrDisapproveCargo(true)" [ngClass]="{'disabled-option':!itemsSelecteds.length}">
                <i class="fas fa-check-circle my-2" [ngClass]="{'color-primary':itemsSelecteds.length}"></i>
                <div class="text-bold" [ngClass]="{'underline': itemsSelecteds.length}">Aprobar servicios</div>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-start cursor-pointer"
                *ngIf="canApproveCargos && staticFilters['approval']!=='Rejected'"
                (click)="approveOrDisapproveCargo(false)" [ngClass]="{'disabled-option':!itemsSelecteds.length}">
                <i class="fas fa-times-circle my-2" [ngClass]="{'color-primary':itemsSelecteds.length}"></i>
                <div class="text-bold" [ngClass]="{'underline': itemsSelecteds.length}">Rechazar servicios</div>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-start cursor-pointer" *ngIf="canModifyBill"
                (click)="openDialogEdit('bill', 'facturas')" [ngClass]="{'disabled-option':!itemsSelecteds.length}">
                <i class="fas fa-cog my-2" [ngClass]="{'color-primary':itemsSelecteds.length}"></i>
                <div class="text-bold" [ngClass]="{'underline': itemsSelecteds.length}">Modificar factura(s)</div>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-start cursor-pointer" *ngIf="canModifyBill"
                (click)="confirmDisassociate('bill', 'facturas')"
                [ngClass]="{'disabled-option':!itemsSelecteds.length}">
                <i class="fas fa-minus-circle my-2" [ngClass]="{'color-primary':itemsSelecteds.length}"></i>
                <div class="text-bold" [ngClass]="{'underline': itemsSelecteds.length}">Desasociar factura(s)</div>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-start cursor-pointer" *ngIf="canModifyVoucher"
                (click)="openDialogEdit('income', 'comprobantes')"
                [ngClass]="{'disabled-option':!itemsSelecteds.length}">
                <i class="fas fa-cog my-2" [ngClass]="{'color-primary':itemsSelecteds.length}"></i>
                <div class="text-bold" [ngClass]="{'underline': itemsSelecteds.length}">Modificar comprobante(s)</div>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-start cursor-pointer" *ngIf="canModifyVoucher"
                (click)="confirmDisassociate('income', 'comprobantes')"
                [ngClass]="{'disabled-option':!itemsSelecteds.length}">
                <i class="fas fa-minus-circle my-2" [ngClass]="{'color-primary':itemsSelecteds.length}"></i>
                <div class="text-bold" [ngClass]="{'underline': itemsSelecteds.length}">Desasociar comprobante(s)</div>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-start cursor-pointer"
                *ngIf="canGenerateJournal" (click)="confirmGenerateJournal()"
                [ngClass]="{'disabled-option':!itemsSelecteds.length}">
                <i class="fas fa-minus-circle my-2" [ngClass]="{'color-primary':itemsSelecteds.length}"></i>
                <div class="text-bold" [ngClass]="{'underline': itemsSelecteds.length}">Generar Comprobates Contables
                </div>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-start cursor-pointer"
                [ngClass]="{'disabled-option':!itemsSelecteds.length}" *ngIf="canMoveAdvance"
                (click)="openModalMoveAdvance()">
                <i class="fas fa-arrow-right my-2" [ngClass]="{'color-primary':itemsSelecteds.length}"></i>
                <div class="text-bold" [ngClass]="{'underline': itemsSelecteds.length}">
                    Trasladar anticipos
                </div>
            </div>
        </div>
    </mat-expansion-panel>
    <div *ngIf="showBillingXML" class="xml-button cursor-pointer" (click)="openDialogBillXML()">
        <i class="fas fa-plus-circle mr-2"></i> Cargar XML - Ministerio de Transporte
    </div>
</div>