import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { City } from 'src/app/core/interfaces/city';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CityService } from '../city.service';
import { Utils } from 'src/app/core/resources/utils';
import { AutocompleteCityComponent } from 'src/app/shared/autocomplete-city/autocomplete-city.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { IcaFormComponent } from '../ica-form/ica-form.component';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Permission } from 'src/app/core/resources/permission';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { AuthService } from 'src/app/core/services/authentication.service';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { IntegrationEnum } from 'src/app/core/enums/integration.enum';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { ApiResponseTax, Tax } from 'src/app/core/interfaces/tax';
import { ApiResponseSiigo, ProductSiigo } from 'src/app/core/interfaces/productSiigo';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-city-form',
  templateUrl: './city-form.component.html',
  styleUrls: ['./city-form.component.scss']
})
export class CityFormComponent implements OnInit {

  @ViewChild(AutocompleteCityComponent, { static: false }) autocompleteCityComponent: AutocompleteCityComponent;
  @ViewChild('mySelect', { static: false }) mySelect: MatSelect;
  permission = Permission;
  cityForm = new FormGroup({
    id: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    reteica: new FormControl('', Validators.required),
    taxSiigo: new FormControl('', Validators.required),
    productSiigo: new FormControl('', Validators.required),
    icaAccount: new FormControl(''),
    icaSiigo: new FormControl('', Validators.required),
    debitAccount: new FormControl('', Validators.required),
    zoneCode: new FormControl('', Validators.required),
  });
  mainCityName = new FormControl({ value: '', disabled: true });
  isCreate: boolean = false;
  city: City;
  subcities: any = [];
  reteicaCity: any;
  minTaxSiigo: number = 1;
  minIcaSiigo: number = 1;
  optionsSubcity: OptionsAutocomplete = {
    title: 'Añadir Subciudad',
    requireFullCity: true
  };
  taxOptions: Tax[];
  siigoOptions: ProductSiigo[];
  subCityControl: FormControl = new FormControl();
  subCitySub: Subscription;
  enablePagingScroll = true;
  pageKey = 1;
  pageSize = 50;
  constructor(
    private router: Router,
    public matDialog: MatDialog,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private cityService: CityService,
    private route: ActivatedRoute,
    private _location: Location,
    private permissionRole: PermissionRole,
    public utils: Utils,
    public dialog: MatDialog,
    public authService: AuthService
  ) { }

  ngOnInit() {
    this.getOptions();
    this.setSubscriptionCity();
    this.route.params.subscribe((params: ParamMap) => {
      this.isCreate = false;
      this.getCity(params['id'])
    });
  }

  private getOptions() {
    if (this.hasSiigoIntegration) {
      this.cityService.getTaxes().subscribe(
        (data: ApiResponseTax) => {
          this.taxOptions = data.data;
        },
        (error) => {
          this.snackBarService.openSnackBar('Ocurrió un error al traer los impuestos', undefined, 'error');
        }
      );
      this.getProductSiigo();
    }
  }

  onProductSiigoChange(value: string) {
    if (value == "0") {
      this.siigoOptions.pop()
      this.pageKey++;
      this.getProductSiigo();
    }
  }

  public getProductSiigo() {
    this.spinner.show();
    this.cityService.getProdutSiigo(this.pageKey, this.pageSize).subscribe(
      (data: ApiResponseSiigo) => {
        this.spinner.hide();
        if (this.pageKey && this.pageKey == 1) { this.siigoOptions = data.results; }
        else {
          this.siigoOptions = [...this.siigoOptions, ...data.results];
          this.mySelect.open();
        }
        if (data.results.length == 50) {
          this.siigoOptions.push({
            code: "0",
            active: true,
            taxes: [],
            type: "",
            name: "Cargar más"
          })
        }
      },
      (error) => {
        this.snackBarService.openSnackBar('Ocurrió un error al traer los productos siigo', undefined, 'error');
      }
    );
  }

  public getCity(id) {
    if (this.utils.isDefined(CityService.citySelected)) {
      this.city = this.utils.clone(CityService.citySelected);
      this.initFormCity(this.city);
      !this.isCreate && this.cityForm.disable();
      this.getSubcity(this.city);
    } else {
      this.getCityById(id);
    }
  }

  public getSubcity(city) {
    this.spinner.show();
    this.cityService.getSubcityByIdCity(city.name).toPromise()
      .then((success: any) => {
        if (success && success.length) {
          this.subcities = success
        }
      })
      .catch((error) => {
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  public initFormCity(city: City) {
    this.reteicaCity = this.cityService.getReteicaCity(this.city);
    this.cityForm = new FormGroup({
      id: new FormControl(city.id || ""),
      name: new FormControl(city.name || "", Validators.required),
      reteica: new FormControl(this.cityService.getReteicaCity(this.city) || 0, [Validators.required]),
      taxSiigo: new FormControl(this.cityService.getCityValue(this.city, "taxSiigo") || 0, this.hasSiigoIntegration ? [Validators.required, Validators.min(this.minTaxSiigo)] : null),
      productSiigo: new FormControl(this.cityService.getCityValue(this.city, "productSiigo") || "", this.hasSiigoIntegration ? Validators.required : null),
      icaAccount: new FormControl(this.cityService.getCityValue(this.city, "icaAccount") || ""),
      icaSiigo: new FormControl(this.cityService.getCityValue(this.city, "icaSiigo") || 0, this.hasSiigoIntegration ? [Validators.required, Validators.min(this.minIcaSiigo)] : null),
      debitAccount: new FormControl(this.cityService.getCityValue(this.city, "debitAccount") || ""),
      zoneCode: new FormControl(this.cityService.getCityValue(this.city, "zoneCode") || "", this.hasWorldOfficeIntegration ? Validators.required : null),
    })
    this.mainCityName = new FormControl({ value: city.mainCity ? city.mainCity.name : '', disabled: true });
  }

  public getCityById(id) {
    this.spinner.show();
    this.cityService.getCityById(id).toPromise()
      .then((success: City) => {
        if (success && success.id) {
          this.city = success;
          this.initFormCity(success);
          !this.isCreate && this.cityForm.disable();
          this.getSubcity(this.city);
        } else {
          this.snackBarService.openSnackBar('Ocurrió un error al traer la información de la ciudad', undefined, 'error');
          this.goToBack();
        }
      })
      .catch((error) => {
        this.snackBarService.openSnackBar('Ocurrió un error al traer la información de la ciudad', undefined, 'error');
        this.goToBack();
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  public editRteica() {
    if (this.isMainCity) return;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      city: this.city
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    this.matDialog.open(IcaFormComponent, dialogConfig).afterClosed()
      .subscribe((result) => {
        if (result && result.city) {
          this.city = result.city;
          this.initFormCity(this.city);
          !this.isCreate && this.cityForm.disable();
        }
      });

  }

  get isMainCity() {
    return (this.city && !this.city.mainCity && this.city.reteica && this.city.debitAccount && this.city.icaAccount &&
      (!this.hasSiigoIntegration || (this.city.productSiigo && this.city.taxSiigo && this.city.icaSiigo)));
  }

  get isSubCity() {
    return this.city && this.city.mainCity;
  }

  public editForm() {
    this.cityForm.enable();
  }

  public cancelEditForm() {
    this.getCityById(this.city.id);
    this.initFormCity(this.city);
    this.cityForm.disable();
  }

  public saveCity() {
    this.cityForm.markAllAsTouched();
    if (this.cityForm.invalid) {
      if (this.utils.errorMessagesCustomized(this.cityForm.get('name'), 'nombre de la ciudad')) return;
      else if (this.utils.errorMessagesCustomized(this.cityForm.get('reteica'), 'reteica')) return;
      else if (this.utils.errorMessagesCustomized(this.cityForm.get('taxSiigo'), 'código retefuente', null, null, this.minTaxSiigo)) return;
      else if (this.utils.errorMessagesCustomized(this.cityForm.get('productSiigo'), 'producto siigo')) return;
      else if (this.utils.errorMessagesCustomized(this.cityForm.get('icaAccount'), 'cuenta ica')) return;
      else if (this.utils.errorMessagesCustomized(this.cityForm.get('icaSiigo'), 'código ica', null, null, this.minIcaSiigo)) return;
      else if (this.utils.errorMessagesCustomized(this.cityForm.get('debitAccount'), 'cuenta débito')) return;
      else if (this.utils.errorMessagesCustomized(this.cityForm.get('zoneCode'), 'código zonal')) return;
      else this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
      return;
    }
    let body = this.utils.clone(this.cityForm.value);
    this.updateIndependentCity(body);
  }

  public updateSubcities(body) {
    this.spinner.show();
    this.cityService.updateMainCity(body).subscribe((response: any) => {
      this.spinner.hide();
      if (response.status == 200) {
        CityService.citySelected = body;
        this.getCity(body['id'])
        this.snackBarService.openSnackBar(ServiceMessages.CITY_UPDATED);
      } else {
        this.snackBarService.openSnackBar(ServiceMessages.ERROR_UPDATING_CITY, undefined, 'error');
        this.cancelEditForm();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.status == 200) {
        CityService.citySelected = body;
        this.getCity(body['id'])
        this.snackBarService.openSnackBar(ServiceMessages.CITY_UPDATED);
      } else {
        this.snackBarService.openSnackBar(ServiceMessages.ERROR_UPDATING_CITY, undefined, 'error');
        this.cancelEditForm();
      }
    })
  }

  public updateIndependentCity(body, showSpinner = true) {
    showSpinner && this.spinner.show();
    this.cityService.updateIndependentCity(body).toPromise()
      .then((success) => {
        if (success && success['id']) {
          this.spinner.hide();
          if (this.isMainCity && this.subcities) {
            this.updateSubcities(body);
          } else {
            CityService.citySelected = success;
            this.getCity(success['id'])
            this.cancelEditForm();
            this.snackBarService.openSnackBar(ServiceMessages.CITY_UPDATED);
          }
        } else {
          this.spinner.hide();
          this.snackBarService.openSnackBar(ServiceMessages.ERROR_UPDATING_CITY, undefined, 'error');
          this.cancelEditForm();
        }
      })
      .catch((error) => {
        this.spinner.hide();
        const errorMessage = error && typeof error.error === 'string' ? error.error : ServiceMessages.ERROR_UPDATING_CITY;
        this.snackBarService.openSnackBar(errorMessage, undefined, 'error');
        this.cancelEditForm();
      })
  }

  public onSubmit() {
    if (this.subcities && this.subcities.length) {
      this.subcities = this.subcities.map(function (subcity) {
        delete subcity.reteica;
        delete subcity.mainCity;
        return subcity;
      });
      const city = {
        mainCity: {
          id: this.cityForm.controls.id.value,
          name: this.cityForm.controls.name.value,
          reteica: this.cityForm.controls.reteica.value,
          taxSiigo: this.city.taxSiigo,
          productSiigo: this.city.productSiigo
        },
        subCities: this.subcities
      };
      this.createCity(city);
    } else {
      this.snackBarService.openSnackBar('Por favor seleccione una subciudad', undefined, 'alert')
    }
  }

  public createCity(city) {
    this.spinner.show();
    this.cityService.addSubcities(city).toPromise()
      .then((success) => {
        if (success) {
          this.getCity(city.mainCity.id)
          this.snackBarService.openSnackBar(ServiceMessages.CITIES_ASSOCIATED);
        } else {
          this.snackBarService.openSnackBar(ServiceMessages.ERROR_ASSOCIATING_CITIES, undefined, 'error');
        }
      })
      .catch((error) => {
        const errorMessage = error && typeof error.error === 'string' ? error.error : ServiceMessages.ERROR_ASSOCIATING_CITIES;
        this.snackBarService.openSnackBar(errorMessage, undefined, 'error');
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  public deleteSubcity(subcity) {
    const dialogConfig = new MatDialogConfig();
    const title = '¿Deseas eliminar permanentemente esta subciudad: ' + subcity.name + " ?";
    dialogConfig.data = {
      title: title,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.state) {
        if (subcity.mainCity) {
          this.removeSubcity(subcity);
        } else {
          const index = this.subcities.findIndex((element) => element.id === subcity.id);
          this.subcities.splice(index, 1);
        }
      }
    });
  }

  public removeSubcity(subcity) {
    this.spinner.show();
    this.cityService.removeSubCity(subcity.id).subscribe(
      (data: any) => {
        this.spinner.hide();
        if (data && data.message === 'Se ha eliminado con exito la relacion con la subciudad') {
          this.snackBarService.openSnackBar('Se ha eliminado con exito la relacion con la subciudad');
          const index = this.subcities.findIndex((element) => element.id === subcity.id);
          this.getCity(subcity.mainCity.id);
          this.subcities.splice(index, 1);
        } else if (data.message && data === 'Error, no se ha podido eliminar la subciudad', undefined, 'error') {
          this.snackBarService.openSnackBar(data.message, undefined, 'error');
        } else {
          this.snackBarService.openSnackBar('Ocurrió un error al realizar el proceso', undefined, 'error');
        }
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Ocurrió un error al realizar el proceso', undefined, 'error');
      }
    );
  }

  public goToBack() {
    this._location.back();
  }

  setSubscriptionCity() {
    this.subCitySub = this.subCityControl.valueChanges
      .subscribe(value => {
        if (value && value.id) {
          this.autocompleteCityComponent.formControlCity.setValue("");
          let results = this.subcities.filter(function (city) { return city.id == value.id; });
          if (results.length == 0) {
            this.subcities.unshift(value);
          } else {
            this.snackBarService.openSnackBar(ServiceMessages.CITY_ALREADY_LINKED, undefined, 'alert');
          }
        }
      })
  }

  public onSelectCity(info) {
    if (info && info.data && info.data.id) {
      this.autocompleteCityComponent.formControlCity.setValue("");
      let results = this.subcities.filter(function (city) { return city.id == info.data.id; });
      if (results.length == 0) {
        delete info.data['reteica']
        this.subcities.unshift(info.data);
      } else {
        this.snackBarService.openSnackBar(ServiceMessages.CITY_ALREADY_LINKED, undefined, 'alert');
      }
    }

  }

  ngOnDestroy() {
    if (this.subCitySub) this.subCitySub.unsubscribe();
  }

  //GETTERS
  get hasSiigoIntegration(): boolean {
    return this.authService.getCompany().integrationCredentials &&
      this.authService.getCompany().integrationCredentials.length &&
      this.authService.getCompany().integrationCredentials.some(integration => integration && integration.integrationChannel && integration.integrationChannel.toLowerCase() === IntegrationEnum.SIIGO.toLowerCase() && integration.state);
  }
  get hasWorldOfficeIntegration(): boolean {
    return this.authService.getCompany().integrationCredentials &&
      this.authService.getCompany().integrationCredentials.length &&
      this.authService.getCompany().integrationCredentials.some(integration => integration && integration.integrationChannel && integration.integrationChannel.toLowerCase() === IntegrationEnum.WORLD_OFFICE.toLowerCase());
  }
  get canLinkCities(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.administration.module,
      this.permission.administration.linkCities
    );
  }

  onScrollDown() {
    if (this.enablePagingScroll) {
      this.pageKey++;
      this.getOptions();
    }
  }
}
