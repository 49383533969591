import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Trailer } from 'src/app/core/interfaces/trailer';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrailersService {
  constructor(
    private http: HttpClient,
    private endpoints: Endpoints
  ) { }

  getTrailers(pageKey: number, pageSize: number, id?: string, owner?: string, vehicle?: string, holderCompanyId?: string): Observable<Trailer[]> {
    const params = new URLSearchParams();

    !!id ? params.set('id', id) : 0;
    !!owner ? params.set('owner', owner) : 0;
    !!vehicle ? params.set('vehicle', vehicle) : 0;
    !!holderCompanyId ? params.set('holderCompany', holderCompanyId) : 0;
    params.set('pageKey', `${pageKey}`);
    params.set('pageSize', `${pageSize}`);

    return this.http.get(`${environment.urlServerTeclogi}${this.endpoints.listTrailer}${params.toString()}`) as Observable<Trailer[]>;
  }
}
