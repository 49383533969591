import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material';
import { CargoLocationReportComponent } from '../cargo-location-report/cargo-location-report.component';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Permission } from 'src/app/core/resources/permission';
import { CargoTrackingService } from '../cargo-tracking/cargo-tracking.service';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { LastPointLocation, Traceability } from 'src/app/core/interfaces/lastPointLocation';
import { CargoMessages } from 'src/app/core/messages/cargo-messages.enum';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
@Component({
  selector: 'app-cargo-history-news',
  templateUrl: './cargo-history-news.component.html',
  styleUrls: ['./cargo-history-news.component.scss']
})
export class CargoHistoryNewsComponent implements OnInit {
  permission = Permission;
  isSomeChanged: boolean = false;
  tracepoints: Traceability;
  filteredHistoryNoveltyPoints: LastPointLocation[] = [];
  constructor(
    public matDialog: MatDialog,
    public dialogRef: MatDialogRef<CargoHistoryNewsComponent>,
    public utils: Utils,
    private snackBarService: SnackBarService,
    public cargoTrackingService: CargoTrackingService,
    private permissionRole: PermissionRole,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (this.data && this.data.tracepoints) {
      this.tracepoints = this.data.tracepoints;
    }
  }

  public updateNovelty(novelty) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      cargo: this.data.cargo,
      report: {
        parent: "tripAnomalies",
      }
    }
    if (novelty.name || novelty.anomaly) {
      dialogConfig.data.report['name'] = novelty.name ? novelty.name : novelty.anomaly;
    }
    if (novelty.observation) {
      dialogConfig.data.report['observation'] = novelty.observation;
    }
    if (novelty.fingerprint && novelty.fingerprint.date) {
      dialogConfig.data.report['date'] = novelty.fingerprint.date;
    }
    if (novelty.temperature) {
      dialogConfig.data.report['temperature'] = novelty.temperature;
    }
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    const dialogRef = this.matDialog.open(CargoLocationReportComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (!this.utils.isEmpty(result) && !this.utils.isEmpty(result.state) && result.state) {
        this.snackBarService.openSnackBar(CargoMessages.NOVELTY_UPDATED);
        if (result.cargo) {
          this.refreshTracepoints();
          this.isSomeChanged = true;
        }
      }
      if (!this.utils.isEmpty(result) && !this.utils.isEmpty(result.state) && !result.state) {
        this.snackBarService.openSnackBar(CargoMessages.NOVELTY_UPDATE_ERROR, undefined, 'error');
      }
    });
  }

  get canEditAnomaly(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.editAnomalies);
  }

  public refreshTracepoints() {
    this.cargoTrackingService.getTracking(this.data.cargo.id).subscribe(
      (data: Traceability) => {
        if (data) {
          this.tracepoints = data;
        } else {
        }
      },
      error => {
      })
  }

  public closeDialog() {
    this.isSomeChanged
      ? this.dialogRef.close({
        state: true,
      })
      : this.dialogRef.close();
  }

}
