
<div class="d-flex align-items-center justify-content-center flex-column maintenance-evidences px-2 py-4">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions"
    (click)="dialogRef.close()"></i>
  <p class="maintenance-evidences__title px-5 text-center">
    {{dialogParams?.maintenance?.maintenanceType?.name ? dialogParams?.maintenance?.maintenanceType?.name : 'Mantenimiento'}}
  </p>
  <app-upload-resources class="maintenance-evidences__content w-50" [media]="dialogParams?.maintenance?.evidence"
   [path]="maintenancePath" textName="evidencias" [showButtonActions]="true" [showAlertResources]="true" [unableDeleteAllEvidences]="true" (storageResources)="processPaths($event)" (closeDialog)="dialogRef.close()"></app-upload-resources>
</div>


