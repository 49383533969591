<div class="create-edit-maintenance py-3 p-sm-3">
  <i *ngIf="dialogParams && dialogParams.maintenance" matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions"
    (click)="dialogRef.close()"></i>
  <div class="back-button" (click)="returnListMaintenance()" *ngIf="!dialogParams?.maintenance">
    <i class="fas fa-arrow-left"></i>
    <p class="m-0">Volver al listado</p>
  </div>
  <div class="create-edit-maintenance__body">
    <div class="create-edit-maintenance__summary">
      <app-maintenance-summary [vehicle]="vehicle"></app-maintenance-summary>
    </div>
    <div>
      <app-create-edit-form-maintenance [licensePlate]="vehicle?.id" [maintenance]="dialogParams?.maintenance" (refreshList)="dialogRef.close({state:true})"></app-create-edit-form-maintenance>
    </div>
  </div>
</div>
