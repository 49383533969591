<div class="py-4 h-100-margin overflow-auto">
    <mat-accordion class="m-1 mb-4 mx-sm-3">
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title class="text-strong">
                    Configuración de SOAT Y RTM
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form class="row align-items-baseline" *ngIf="formSoatRtm" [formGroup]="formSoatRtm"
                (ngSubmit)="onSubmitSoatRtm()">
                <div class="col-12 mb-4 title-4">
                    Los campos a continuación te permitirán personalizar los tiempos en días para las validaciones de
                    SOAT y RTM.
                    Establece el período de advertencia y de bloqueo para iniciar los servicios, así como activar o
                    desactivar las alertas y bloqueos si así lo deseas.
                </div>
                <div class="col-12 text-bold">
                    SOAT
                </div>
                <div class="col-md-4 my-3">
                    <label class="subtitle-validate">Advertencia y bloqueo</label>
                    <mat-button-toggle-group #group="matButtonToggleGroup" name="soatAlertsAndBlocksEnabled"
                        formControlName="soatAlertsAndBlocksEnabled">
                        <mat-button-toggle [value]="true" (change)="onChangeEnable($event,'SOAT')">
                            Activar
                        </mat-button-toggle>
                        <mat-button-toggle [value]="false" (change)="onChangeEnable($event,'SOAT')">
                            Desactivar
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>
                            Período de advertencia (días)<span class="asterisk">*</span>
                        </mat-label>
                        <input matInput type="text" onlyNumbersInt name="daysToAlertExpirationSOAT"
                            formControlName="daysToAlertExpirationSOAT"
                            [placeholder]="vehicleMessages.DAYS_TO_ALERT_EXPIRATION_DEFAULT">
                        <mat-error *ngIf="formSoatRtm.get('daysToAlertExpirationSOAT').errors?.required">
                            {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                        <mat-error *ngIf="formSoatRtm.get('daysToAlertExpirationSOAT').errors?.min">
                            {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'periodo de advertencia', "1") }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>
                            Período de bloqueo (días)<span class="asterisk">*</span>
                        </mat-label>
                        <input matInput type="text" onlyNumbersInt name="daysToBlockSOAT"
                            formControlName="daysToBlockSOAT" [placeholder]="vehicleMessages.DAYS_TO_BLOCK_DEFAULT">
                        <mat-error *ngIf="formSoatRtm.get('daysToBlockSOAT').errors?.required">
                            {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                        <mat-error *ngIf="formSoatRtm.get('daysToBlockSOAT').errors?.min">
                            {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'periodo de bloqueo', "1") }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-12 text-bold mt-3 mt-md-0">
                    RTM
                </div>
                <div class="col-md-4 my-3">
                    <label class="subtitle-validate">Advertencia y bloqueo</label>
                    <mat-button-toggle-group #group="matButtonToggleGroup" name="rtmAlertsAndBlocksEnabled"
                        formControlName="rtmAlertsAndBlocksEnabled">
                        <mat-button-toggle [value]="true" (change)="onChangeEnable($event,'RTM')">
                            Activar
                        </mat-button-toggle>
                        <mat-button-toggle [value]="false" (change)="onChangeEnable($event,'RTM')">
                            Desactivar
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>
                            Período de advertencia (días)<span class="asterisk">*</span>
                        </mat-label>
                        <input matInput type="text" onlyNumbersInt name="daysToAlertExpirationRTM"
                            formControlName="daysToAlertExpirationRTM"
                            [placeholder]="vehicleMessages.DAYS_TO_ALERT_EXPIRATION_DEFAULT">
                        <mat-error *ngIf="formSoatRtm.get('daysToAlertExpirationRTM').errors?.required">
                            {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                        <mat-error *ngIf="formSoatRtm.get('daysToAlertExpirationRTM').errors?.min">
                            {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'periodo de advertencia', "1") }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>
                            Período de bloqueo (días)<span class="asterisk">*</span>
                        </mat-label>
                        <input matInput type="text" onlyNumbersInt name="daysToBlockRTM"
                            formControlName="daysToBlockRTM" [placeholder]="vehicleMessages.DAYS_TO_BLOCK_DEFAULT">
                        <mat-error *ngIf="formSoatRtm.get('daysToBlockRTM').errors?.required">
                            {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                        <mat-error *ngIf="formSoatRtm.get('daysToBlockRTM').errors?.min">
                            {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'periodo de bloqueo', "1") }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="button-container mt-2 w-100 justify-content-center">
                    <button mat-raised-button color="primary" class="bg-color-primary" type="submit">Guardar</button>
                </div>
            </form>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion class="m-1 mb-4 mx-sm-3" *ngIf="isPrincipalCompany">
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title class="text-strong">
                    Configurar bloqueo del vehículo
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form class="row align-items-baseline" *ngIf="formVehicle" [formGroup]="formVehicle"
                (ngSubmit)="onSubmitVehicle()">
                <div class="col-12 mb-4 title-4">
                    En el siguiente campo podrás personalizar el plazo máximo de días de inactividad para bloquear los
                    vehículos
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>
                            Máximo de días de inactividad<span class="asterisk">*</span>
                        </mat-label>
                        <input matInput type="text" onlyNumbersInt name="daysToDeactivateVehicle"
                            formControlName="daysToDeactivateVehicle"
                            [placeholder]="vehicleMessages.DAYS_TO_DEACTIVE_VEHICLES_DEFAULT">
                        <mat-error *ngIf="formVehicle.get('daysToDeactivateVehicle').errors?.required">
                            {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                        <mat-error *ngIf="formVehicle.get('daysToDeactivateVehicle').errors?.min">
                            {{ utils.giveMessageError('daysToDeactivateVehicle', 'días de inactividad', "1") }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="button-container mt-2 w-100 justify-content-center">
                    <button mat-raised-button color="primary" class="bg-color-primary" type="submit">Guardar</button>
                </div>
            </form>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion class="m-1 mb-4 mx-sm-3" *ngIf="isPrincipalCompany && hasPermissionToTruora">
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title class="text-strong">
                    Configurar creación de hojas de vida
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form class="row align-items-baseline" *ngIf="formResume" [formGroup]="formResume"
                (ngSubmit)="onSubmitResume()">
                <div class="col-12 mb-4 title-4">
                    En la siguiente sección podrás marcar si una hoja de vida requiere estudio de seguridad
                </div>
                <div class="col-12 text-bold">
                    Realizar estudio de seguridad
                </div>
                <div class="col-md-4 my-3">
                    <mat-button-toggle-group #group="matButtonToggleGroup" name="allowCreatingWithoutTruora"
                        formControlName="allowCreatingWithoutTruora">
                        <mat-button-toggle [value]="true">
                            Activar
                        </mat-button-toggle>
                        <mat-button-toggle [value]="false">
                            Desactivar
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <div class="button-container mt-2 w-100 justify-content-center">
                    <button mat-raised-button color="primary" class="bg-color-primary" type="submit">Guardar</button>
                </div>
            </form>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion class="m-1 mb-4 mx-sm-3" *ngIf="isPrincipalCompany && hasPermissionToIntegrateMonitor">
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title class="text-strong">
                    Configurar trazabilidad
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form class="row align-items-baseline" *ngIf="isPrincipalCompany" [formGroup]="formIntegration"
                (ngSubmit)="onSubmitIntegration()">
                <div class="col-12 mb-4 title-4">
                    El campo a continuación le permitirá decidir si se realiza la trazabilidad de vehículos
                    a través del sistema de integración de múltiples GPS. <br> Este servicio tiene costo adicional por
                    cada uso.
                </div>
                <div class="col-md-4">
                    <div>
                        <mat-label>
                            <b>Trazabilidad con múltiples GPS</b>
                        </mat-label>
                        <div class="col-md-4 my-3">
                            <mat-button-toggle-group #group="matButtonToggleGroup" name="monitor"
                                formControlName="monitor">
                                <mat-button-toggle (change)="onChangeMonitor($event.value)" [value]="true">
                                    Activar
                                </mat-button-toggle>
                                <mat-button-toggle (change)="onChangeMonitor($event.value)" [value]="false">
                                    Desactivar
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>
                </div>
                <div class="button-container mt-2 w-100 justify-content-center">
                    <button mat-raised-button color="primary" class="bg-color-primary" type="submit">Guardar</button>
                </div>
            </form>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion class="m-1 mb-4 mx-sm-3"
        *ngIf="formSecurityPolicies?.value && rules?.length && hasPermissionValidationRule">
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title class="text-strong">
                    Configurar políticas de seguridad
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form class="row align-items-baseline" [formGroup]="formSecurityPolicies"
                (ngSubmit)="onSubmitSecurityPolicies()">
                <div class="col-12 mb-4 title-4">
                    En esta sección podrá determinar los documentos que serán solicitados y los estudios que serán
                    realizados a los usuarios de forma obligatoria.
                    Recuerde que puede seguir realizandolos de forma opcional aunque no los marque.
                </div>
                <div *ngFor="let entity of entitiesFields" class="col-md-4"
                    [ngClass]="{'d-none':!hasValidationRules(entity)}">
                    <mat-label>
                        <b>{{entitiesDict[entity]}}</b>
                    </mat-label>
                    <div *ngIf="formSecurityPolicies.get(entity)?.get('state')" class="d-flex flex-column">
                        <div *ngFor="let stateField of stateFields"
                            [ngClass]="{'d-none':!formSecurityPolicies.get(entity)?.get('state')?.get(stateField)}">
                            <mat-checkbox *ngIf="formSecurityPolicies.get(entity)?.get('state')?.get(stateField)"
                                [formControl]="formSecurityPolicies.get(entity).get('state').get(stateField)">
                                {{stateDict[stateField]}}
                            </mat-checkbox>
                        </div>

                    </div>
                    <div *ngIf="formSecurityPolicies.get(entity)?.get('security')" class="d-flex flex-column">
                        <div *ngFor="let securityField of securityFields"
                            [ngClass]="{'d-none':!formSecurityPolicies.get(entity)?.get('security')?.get(securityField)}">
                            <mat-checkbox *ngIf="formSecurityPolicies.get(entity)?.get('security')?.get(securityField)"
                                [formControl]="formSecurityPolicies.get(entity).get('security').get(securityField)">
                                {{securityDict[securityField]}}
                            </mat-checkbox>
                        </div>

                    </div>
                </div>
                <div class="button-container mt-2 w-100 justify-content-center">
                    <button mat-raised-button color="primary" class="bg-color-primary" type="submit">Guardar</button>
                </div>
            </form>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion class="m-1 mb-4 mx-sm-3" *ngIf="isPrincipalCompany && hasPermissionToChangeFeeToOwnedFleet">
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title class="text-strong">
                    Configurar cobro de impuestos a vehículos flota propia
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form class="row align-items-baseline" *ngIf="isPrincipalCompany" [formGroup]="formFees"
                (ngSubmit)="onSubmitFees()">
                <div class="col-12 mb-4 title-4">
                    A continuación podrá elegir quién pagará los impuestos por servicio de transporte: <b>Rete ICA y
                        Retefuente.</b>
                </div>
                <div class="col-md-12">
                    <div>
                        <mat-label>
                            <b>El impuesto rete ICA y rete fuente se le cobra a vehículos de la flota propia</b>
                        </mat-label>
                        <div class="col-md-4 my-3">
                            <mat-button-toggle-group #group="matButtonToggleGroup" name="fees"
                                formControlName="feeToOwnedFleet">
                                <mat-button-toggle [value]="true">
                                    Sí
                                </mat-button-toggle>
                                <mat-button-toggle [value]="false">
                                    No
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>
                </div>
                <div class="button-container mt-2 w-100 justify-content-center">
                    <button mat-raised-button color="primary" class="bg-color-primary" type="submit">Guardar</button>
                </div>
            </form>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion class="m-1 mb-4 mx-sm-3" *ngIf="isPrincipalCompany && hasPermissionEditVehicleDocuments">
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title class="text-strong">
                    Configurar documentos adicionales del vehículo
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form class="row align-items-baseline" *ngIf="isPrincipalCompany && formVehicleDocuments">
                <div class="col-12 mb-4 title-4">
                    A continuación podrá elegir que documentos adicionales se mostrarán para los vehículos y cuales de
                    estos serán requeridos
                </div>
                <div *ngFor="let document of formVehicleDocuments?.controls"
                    class="col-lg-4 col-md-6 col-12 d-flex flex-wrap p-2 pb-4 document-card"
                    [ngClass]="{'disabled-check':document?.get('mandatory')?.disabled}">
                    <mat-checkbox (change)="onChangeActiveDocument(document)" [formControl]="document?.get('active')"
                        class="w-100">
                        {{document?.get('name').value}}
                    </mat-checkbox>
                    <mat-button-toggle-group #group="matButtonToggleGroup" name="fees"
                        [formControl]="document?.get('mandatory')">
                        <mat-button-toggle [value]="true">
                            <b>Obligatorio</b>
                        </mat-button-toggle>
                        <mat-button-toggle [value]="false">
                            Opcional
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <div class="button-container mt-2 w-100 justify-content-center">
                    <button mat-raised-button color="primary" class="bg-color-primary" type="button"
                        (click)="onSubmitVehicleDocuments()">Guardar</button>
                </div>
            </form>
        </mat-expansion-panel>
    </mat-accordion>
</div>