<div class="position-relative py-3 px-2">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <div class="title-component mx-3 mb-0 text-center">
        Convenciones
    </div>
    <div *ngIf="dialogParams?.payment" class="ml-3 mt-3">
        <h5 class="text-left text-bold">Convenciones por tiempo de pago</h5>
        <p>
            Los servicios con borde izquierdo de color hacen referencia al tiempo de pago respectivo siguiendo la
            siguiente
            convención:
        </p>
        <div class="d-flex justify-content-around align-items-center flex-wrap">
            <div class="card-convention" *ngFor="let item of paymentConventions; let i = index;" [ngClass]="item.color">
                {{item.text}}
            </div>
        </div>
    </div>
    <div *ngIf="dialogParams?.tracking" class="ml-3 mt-3">
        <h5 class="text-left text-bold">Tiempo sin trazabilidad</h5>
        <p>
            Los servicios con borde de color hacen referencia al tiempo transcurrido sin trazabilidad, siguiendo la
            convención:
        </p>
        <div>
            <div class="text-left w-75 m-auto">
                <div class="border-intermitente-card card-convention"
                    *ngFor="let item of trackingTimeConventions; let i = index;" [ngClass]="item.color">
                    {{item.text}}
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="dialogParams?.transported" class="ml-3 mt-3">
        <h5 class="text-left text-bold">Convenciones por valor transportado o perfil de riesgo</h5>
        <p>
            Los servicios con punto de color se refieren al valor transportado o perfil de riesgo y siguen la
            convención:
        </p>
        <div>
            <div class="text-left w-75 m-auto">
                <div class="dot-convention" *ngFor="let item of valueConventions; let i = index;">
                    <span [ngClass]="item.color">&nbsp;</span>{{item.text}}
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="dialogParams?.select" class="ml-3 mt-3">
        <h5 class="text-left text-bold">Convenciones al seleccionar</h5>
        <p>
            Al seleccionar, los objetos seleccionados cambian de color siguiendo la siguiente
            convención:
        </p>
        <div class="d-flex justify-content-around align-items-center flex-wrap">
            <div class="card-convention bg-gray">
                Objeto seleccionado
            </div>
            <div class="card-convention">
                Objeto no seleccionado
            </div>
        </div>
    </div>
    <div *ngIf="dialogParams?.paused" class="ml-3 mt-3">
        <h5 class="text-left text-bold">Convenciones por estado del servicio</h5>
        <p>
            Los servicios en ruta cambian de color siguiendo la siguiente
            convención:
        </p>
        <div class="d-flex justify-content-around align-items-center flex-wrap">
            <div class="card-convention bg-gray">
                Servicio pausado
            </div>
            <div class="card-convention">
                Servicio en movimiento
            </div>
        </div>
    </div>
    <div *ngIf="dialogParams?.vehicle" class="ml-3 mt-3">
        <h5 class="text-left text-bold">Convenciones de cuentas bancarias</h5>
        <p>
            Los vehículos con borde izquierdo de color hacen referencia al estado de cuentas bancarias respectivo
            siguiendo la siguiente
            convención:
        </p>
        <div class="d-flex justify-content-around align-items-center flex-wrap">
            <div class="card-convention" *ngFor="let item of vehicleConventions; let i = index;" [ngClass]="item.color">
                {{item.text}}
            </div>
        </div>
    </div>
    <div *ngIf="dialogParams?.tracking" class="ml-3 mt-3">
        <h5 class="text-left text-bold">Ruta en el mapa</h5>
        <p>
            Las líneas que representan cada una de las rutas:
        </p>
        <div>
            <div class="text-left w-75 m-auto">
                <div class="card-convention" *ngFor="let item of mapsConventions; let i = index;"
                    [ngClass]="item.color">
                    {{item.text}}
                </div>
            </div>
        </div>
    </div>
</div>