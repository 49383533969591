import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PublicCargoEvidencesService } from './public-cargo-evidences.service';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { Utils } from 'src/app/core/resources/utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { FreightForwarderEvidenceService } from 'src/app/modules/cargo/cargo-evidence/cargo-evidence.service';
import { AuthService } from 'src/app/core/services/authentication.service';
import { AddressCargo } from 'src/app/core/interfaces/addressCargo';
import { Location } from '@angular/common';
import { forkJoin, from, of, throwError } from 'rxjs';
import { catchError, delay, switchMap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageByAddress } from 'src/app/core/interfaces/imageByAddress';
import { FirebaseApi } from 'src/app/core/classes/firebase-api';


@Component({
  selector: 'app-public-cargo-evidences',
  templateUrl: './public-cargo-evidences.component.html',
  styleUrls: ['./public-cargo-evidences.component.scss']
})
export class PublicCargoEvidencesComponent implements OnInit {
  cargo: Cargo;
  originAddresses: Array<AddressCargo> = [];
  destinations = [];
  pathNoImage: string = "/assets/svg/icons/icon-no-file.svg";
  pathPdfFile: string = "/assets/svg/icons/file-pdf-solid.svg"
  showEvidences: boolean = false;
  mainImageUrl: string = '';
  typeMainImageUrl: string = '';
  dataCargo = {};
  expandedImage: boolean = false;
  addressSelected: AddressCargo;
  index: number = 0;
  type: string = '';
  notResults: { state: boolean, label: string } = { state: false, label: 'No se encontraron resultados' };
  constructor(
    private route: ActivatedRoute,
    private publicCargoEvidencesService: PublicCargoEvidencesService,
    public utils: Utils,
    public spinner: NgxSpinnerService,
    public snackBarService: SnackBarService,
    public service: FreightForwarderEvidenceService,
    private authService: AuthService,
    public location: Location,
    public domSanitizer: DomSanitizer
  ) {
    if (this.authService.sessionActive()) this.location.back();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(async queryParams => {
      this.notResults['state'] = false;
      if (queryParams && queryParams.consecutive && queryParams.companyId) {
        sessionStorage.setItem('_companyId', queryParams.companyId);
        const cargoObserver = {
          next: async (cargo) => {
            this.spinner.hide();
            if (cargo) {
              this.cargo = cargo;
              const loadConfig = await FirebaseApi.configFirebaseByCompanyId(queryParams.companyId);
              if (cargo.cargoFeature && cargo.cargoFeature.uploadDownload && loadConfig) {
                if (cargo.cargoFeature.uploadDownload.origin && cargo.cargoFeature.uploadDownload.origin.addresses) {
                  this.originAddresses = cargo.cargoFeature.uploadDownload.origin.addresses;
                  this.dataCargo = {
                    city: cargo.cargoFeature && cargo.cargoFeature.uploadDownload &&
                      this.utils.isDefined(cargo.cargoFeature.uploadDownload.origin) &&
                      this.utils.isDefined(cargo.cargoFeature.uploadDownload.origin.name) ?
                      cargo.cargoFeature.uploadDownload.origin.name : '-',
                    dateLoad: cargo.dateLoad ? cargo.dateLoad : '-',
                  }

                  this.originAddresses.forEach((address, index) => {
                    this.getEvidencesCargo(address.address, address.id, 'origin', index)
                  });
                }

                if (cargo.cargoFeature.uploadDownload.destination) {
                  this.destinations = cargo.cargoFeature.uploadDownload.destination;
                  this.destinations.forEach((destination, indexParent) => {
                    if (destination) {
                      destination.addresses.forEach((address, index) => {
                        this.getEvidencesCargo(address.address, address.id, 'destination', index, indexParent)
                      })
                    }
                  });

                }
              } else {
                this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
                this.notResults['state'] = true;
                this.notResults['label'] = 'Ha ocurrido un error procesando la solicitud. Intentelo de nuevo.'
              }
            }
            else {
              this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
              this.notResults['state'] = true;
              this.notResults['label'] = 'El servicio digitado no existe. Intentelo de nuevo.'
            }
          },
          error: () => {
            this.spinner.hide();
            this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
            this.notResults['state'] = true;
            this.notResults['label'] = 'Ha ocurrido un error procesando la solicitud. Intentelo de nuevo.'
          }
        };
        this.spinner.show();
        this.publicCargoEvidencesService.detailCargoByConsecutive(queryParams.consecutive, queryParams.companyId).pipe(delay(1000)).subscribe(cargoObserver);
      } else {
        this.notResults['state'] = true;
        this.notResults['label'] = 'El servicio digitado o el nit de la empresa no existe. Intentelo de nuevo.'
      }
    });
  }

  ngOnDestroy() {
    sessionStorage.removeItem('_companyId');
  }

  getEvidencesCargo(address: string, id: string, type: ('origin' | 'destination'), index: number, parent?: number) {
    this.spinner.show();
    this.service
      .getImageByAddress(
        this.cargo.id,
        address,
        id,
        parent
      )
      .toPromise()
      .then((data: ImageByAddress) => {
        if (data && data.imageByAddressFingerprint && data.imageByAddressFingerprint.length) {
          this.setListImagesDocument(data, type, index, parent);
          this.setListImagesTruck(data, type, index, parent);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.spinner.hide();
      });
  }


  setListImagesDocument(data: ImageByAddress, type: string, addressIndex, destinationIndex?) {
    const listImagesDocument = [];

    if (destinationIndex !== undefined) {
      this.destinations[destinationIndex].addresses[addressIndex]['imagesDocument'] = [];
    } else this.originAddresses[addressIndex]['imagesDocument'] = [];

    if (data.imageByAddressFingerprint[0].imageDocument.length) {
      for (const image of data.imageByAddressFingerprint[0].imageDocument) listImagesDocument.push(image.path);
    }
    listImagesDocument.forEach((image) => {
      this.getUrlFirestorage(image, type && type === 'origin' ? this.originAddresses[addressIndex].imagesDocument : this.destinations[destinationIndex].addresses[addressIndex].imagesDocument, 'si');
    });
  }

  setListImagesTruck(data: ImageByAddress, type: string, addressIndex, destinationIndex?) {
    const listImagesTruck = [];
    if (destinationIndex !== undefined) {
      this.destinations[destinationIndex].addresses[addressIndex]['imagesTruck'] = [];
    } else {
      this.originAddresses[addressIndex]['imagesTruck'] = [];
    }

    if (data.imageByAddressFingerprint[0].imageTruck.length) {
      for (const image of data.imageByAddressFingerprint[0].imageTruck) listImagesTruck.push(image.path);
    }
    //la lista de las url publicas
    listImagesTruck.forEach((image) => {
      this.getUrlFirestorage(image, type && type === 'origin' ? this.originAddresses[addressIndex].imagesTruck : this.destinations[destinationIndex].addresses[addressIndex].imagesTruck);
    });
  }

  async getUrlFirestorage(image: any, listImages: any[], type?) {
    const storage = AuthService.fStorage;
    const pathReference = storage.ref(image);
    this.spinner.show();
    pathReference
      .getDownloadURL()
      .then(
        (success) => {
          listImages.push({
            path: image,
            url: image && image.split('.')[1] !== 'pdf' ? this.sanitizeUrl(success) : this.pathPdfFile,
            type: image.split('.')[1],
            sanitizeUrlPdf: image && image.split('.')[1] === 'pdf' ? this.sanitizeUrl(success) : ''
          });
        },
        (error) => {
          listImages.push({
            path: image,
            url: this.pathNoImage,
          });
        }
      )
      .catch(() => {
        listImages.push({
          path: image,
          url: this.pathNoImage,
        });
      })
      .finally(() => {
        this.spinner.hide();
      });


  }

  showDetail(action: boolean, address?: AddressCargo, index?: number, mainImageUrl?: string, typeImage?: string, type?: string, destinationDownload?: string, destinationName?: string) {
    this.mainImageUrl = mainImageUrl;
    this.typeMainImageUrl = typeImage;
    this.showEvidences = action;
    this.addressSelected = address;
    this.index = index;
    this.type = type;
    if (type === 'destination') {
      this.dataCargo = {
        city: destinationName ? destinationName : '-',
        dateLoad: destinationDownload ? destinationDownload : '-',
      }
    }
  }

  processEvents($event) {
    if ($event && $event.type && $event.type === 'closeDetail') this.showDetail($event.value);
    if ($event && $event.type === 'expandImage') this.expandedImage = $event.value;
  }

  sanitizeUrl(url: string) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getIsPdfType(imageType): boolean {
    return imageType && imageType === 'pdf';
  }

}
