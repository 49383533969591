<div class="container">
    <div id="mainFreightAdditionalService">
        <app-additional-service [listAdditionalService]="listAdditionalService" [modelForm]="modelFormAdditionalCost"
            [companyId]="manualCreationCargoService.getCargoForm().value.idCompany">
        </app-additional-service>
        <div class="w-100 p-3 text-center text-bold alert alert-danger" role="alert"
            *ngIf="!listAdditionalService?.length">
            No tiene servicios adicionales disponibles.
        </div>
        <!-- Button -->
        <div class="col-12 button-container justify-content-center mt-3 pb-4">
            <button mat-raised-button class="secondary-sharp mr-4" (click)="stepBack()">Atrás</button>
            <app-request-button></app-request-button>
            <button mat-raised-button color="primary" class="bg-color-primary" (click)="nextStep()">
                {{isSomeAdditionalService? 'Guardar y continuar': 'Siguiente'}}
            </button>
        </div>
    </div>
</div>