<!-- NORMAL -->
<div class="preview-notification normal" *ngIf="!isCritic(data.type)">
  <div class="normal__body" (click)="goToDetail()">
    <div class="normal__text">
      <span class="normal__text--bold">Servicio {{data.consecutive}}</span> {{data.message}}
    </div>
  </div>
  <span class="normal__icon-close normal__text--bold" (click)="onClose.emit(index)">
    <i class="fas fa-times"></i>
  </span>
</div>

<!-- CRITIC -->
<div class="preview-notification critic" *ngIf="isCritic(data.type)">
  <div class="critic__body" (click)="goToDetail()">
    <div class="critic__text">
      <span class="critic__text--bold title">
        <i class="fas fa-exclamation-triangle critic-icon"></i>
        {{getTitle(data.type)}}
      </span>
      <div>Vehículo <b>{{data.extraInformation.licensePlate}}</b></div>
      <div>Conductor <b>{{data.extraInformation.driverName}}</b></div>
      <div>Contacto <b>{{data.extraInformation.phone}}</b></div>
    </div>
  </div>
  <span class="critic__icon-close critic__text--bold" (click)="onClose.emit(index)">
    <i class="fas fa-times"></i>
  </span>
</div>