import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cargo } from 'src/app/core/models/cargo.model';
import { Patterns } from 'src/app/core/resources/patterns';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ManualCreationCargoService } from '../manual-creation-cargo/manual-creation-cargo.service';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';
import { delay } from 'rxjs/operators';
import { CompanyManager } from 'src/app/core/managers/company.manager';
import { AuthService } from 'src/app/core/services/authentication.service';
import { environment } from 'src/environments/environment';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-rounded-cargo',
  templateUrl: './rounded-cargo.component.html',
  styleUrls: ['./rounded-cargo.component.scss']
})
export class RoundedCargoComponent implements OnInit {
  timeList: Array<Object> = [];
  form = new FormGroup({});
  todayDate: Date = new Date();
  step: number = 1;
  timeLoad = "AM";
  timeDownload = "AM";
  paymentDays = {
    'Contra entrega': 0,
    '8 días': 8,
    '15 días': 15,
    '30 días': 30,
    '60 días': 60,
    '90 días': 90
  }
  otherPaymentType = new FormControl(0, { validators: [Validators.required, Validators.min(0)], updateOn: 'blur' });
  dateTimeForm: FormGroup = new FormGroup({
    dateLoadControl: new FormControl('', Validators.required),
    timeLoadControl: new FormControl('', Validators.required),
    dateDownloadControl: new FormControl('', Validators.required),
    timeDownloadControl: new FormControl('', Validators.required)
  })

  minRate: number = 1;
  minFreightCost: number = 1;
  minAdvancePercentage: number = 0;
  maxAdvancePercentage: number = (this.authService.getCompany() && this.authService.getCompany().companyId && (this.authService.getCompany().companyId === environment.rootNit) ? AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_TECLOGI : this.authService.getCompany() && this.authService.getCompany().advancePercentage ? this.authService.getCompany().advancePercentage : AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_ALL_COMPANIES);
  minCommodityValue: number = 0;
  showOtherPaymentType: boolean = false;
  otherPaymentSub: Subscription;
  minDateLoad = new Date();
  minDateDownload = new Date();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private manualCreationCargoService: ManualCreationCargoService,
    public dialogRef: MatDialogRef<RoundedCargoComponent>,
    private patterns: Patterns,
    public utils: Utils,
    private spinner: NgxSpinnerService,
    private companyManager: CompanyManager,
    private snackBarService: SnackBarService,
    private authService: AuthService,
  ) {
    this.timeList = this.utils.getListTime();
  }

  ngOnInit() {
    this.maxAdvancePercentage = this.companyManager.getAdvancePercentageByCompanyId(this.data.idCompany);
    this.form = new FormGroup({
      cargoId: new FormControl('', [Validators.required]),
      loadDate: new FormControl('', [Validators.required]),
      timeLoad: new FormControl('', [Validators.required]),
      downloadDate: new FormControl('', [Validators.required]),
      timeDownload: new FormControl('', [Validators.required]),
      commodityValue: new FormControl(0, [Validators.required, Validators.min(this.minCommodityValue)]),
      shippingCost: new FormGroup({
        freightCost: new FormControl(0, [Validators.required, Validators.min(this.minFreightCost)]),
        totalCost: new FormControl(0, [Validators.required]),
        advancePercentage: new FormControl(0, [Validators.required, Validators.min(this.minAdvancePercentage), Validators.max(this.maxAdvancePercentage)]),
        advancePaymentMethod: new FormControl('Transferencia'),
        balancePaymentMethod: new FormControl('Transferencia'),
        reteftePercentage: new FormControl(0),
        paymentTime: new FormControl(0, [Validators.required]),
        rate: new FormControl(0, [Validators.required, Validators.min(this.minRate)]),
      }, [Validators.required]),
      observation: new FormControl('', [Validators.required])
    });
    this.form.get('cargoId').setValue(this.data.id);
    this.form.get('shippingCost.reteftePercentage').setValue(1);
    this.otherPaymentSub = this.otherPaymentType.valueChanges
      .pipe(delay(10))
      .subscribe(value => {
        this.form.get('shippingCost.paymentTime').setValue(value);
        this.showOtherPaymentType = this.validateValue(value);
      })
  }

  validateValue(value): boolean {
    return !!(value && !Object.values(this.paymentDays).includes(value))
  }

  othersSelected(value: boolean) {
    this.showOtherPaymentType = value;
  }
  //Funciones del paso 1
  onChangeDate($event: any, type: string) {
    if ($event.value && DateManager.isValidDate($event.value)) {
      if (type === "load") {
        this.form.get('loadDate').setValue(DateManager.dateToString($event.value, 'YYYY-MM-DD HH:mm ZZ'));
        this.minDateDownload = $event.value;
      } else
        this.form.get('downloadDate').setValue(DateManager.dateToString($event.value, 'YYYY-MM-DD HH:mm ZZ'));
    }
  }
  processInput($event: any, type: string) {
    if (type === "load" && $event.target.value !== '' && !this.patterns.DATE_YYYY_MM_DD.test($event.target.value)) {
      this.form.controls.loadDate.setErrors({ pattern: true });
    } else if (type === "load" && ($event.target.value === '' || this.patterns.DATE_YYYY_MM_DD.test($event.target.value))) {
      this.form.controls.loadDate.setErrors({ pattern: false });
    }
    if (type === "Download" && $event.target.value !== '' && !this.patterns.DATE_YYYY_MM_DD.test($event.target.value)) {
      this.form.controls.DownloadDate.setErrors({ pattern: true });
    } else if (type === "Download" && ($event.target.value === '' || this.patterns.DATE_YYYY_MM_DD.test($event.target.value))) {
      this.form.controls.DownloadDate.setErrors({ pattern: false });
    }
  }
  onChangeHour($event: any, type: string) {
    if (type === "load") {
      this.timeLoad === "AM"
        ? this.form.get('timeLoad').setValue($event.value)
        : this.form.get('timeLoad').setValue(this.diff12Hours($event.value, 12));

    } else {
      this.timeDownload === "AM"
        ? this.form.get('timeDownload').setValue($event.value)
        : this.form.get('timeDownload').setValue(this.diff12Hours($event.value, 12))
    }
  }
  diff12Hours(hour: string, n: number): string {
    let date = new Date();
    let [h, m] = hour.split(':');
    date.setHours(parseInt(h) + n, parseInt(m), 0)
    return DateManager.dateToString(date, 'HH:mm');
  }


  //Funciones paso 2
  setTotalCost() {
    this.form.get('shippingCost.totalCost').setValue(
      this.form.get('shippingCost.freightCost').value
    );
  }

  get messageAdvancePercentageAllowed(): string {
    return `El porcentaje máximo permitido por ${this.companyManager.getNameByCompanyId(this.data.idCompany)} es ${this.maxAdvancePercentage}%`;
  }

  get utilityCargo() {
    const utilityValue = ((this.form.get('shippingCost.rate').value - this.form.get('shippingCost.totalCost').value) / this.form.get('shippingCost.rate').value * 100);
    if (!Number.isNaN(utilityValue)) {
      return utilityValue;
    }
    return 0;
  }

  get utilityOwner() {
    return this.companyManager.getUtilityByCompanyId(this.data.idCompany);;
  }

  //Control de Avance

  nextStep(step: number) {
    let validate = false;
    if (step === 1) {
      this.dateTimeForm.markAllAsTouched();
      if (this.utils.errorMessagesCustomized(this.form.get('loadDate'), 'fecha de cargue')) { }
      else if (this.utils.errorMessagesCustomized(this.form.get('timeLoad'), 'hora de cargue')) { }
      else if (this.utils.errorMessagesCustomized(this.form.get('downloadDate'), 'fecha de descargue')) { }
      else if (this.utils.errorMessagesCustomized(this.form.get('timeDownload'), 'hora de descargue')) { }
      else if (this.utils.errorMessagesCustomized(this.form.get('commodityValue'), 'valor del contenedor', null, null, 1)) { }
      else {
        this.timeDownload === "PM" && this.form.get('timeDownload').setValue(this.diff12Hours(this.form.get('timeDownload').value, -12));
        this.timeLoad === "PM" && this.form.get('timeLoad').setValue(this.diff12Hours(this.form.get('timeLoad').value, -12));
        let [hoursLoad, minutesLoad] = this.form.get('timeLoad').value.split(':');
        let [hoursDownload, minutesDownload] = this.form.get('timeDownload').value.split(':');
        if (DateManager.isSameOrBefore(
          DateManager.setTimeToDate(DateManager.stringToDate(this.form.get('downloadDate').value), hoursDownload, minutesDownload),
          DateManager.setTimeToDate(DateManager.stringToDate(this.form.get('loadDate').value), hoursLoad, minutesLoad),
          'minutes'
        ))
          this.snackBarService.openSnackBar(Fmt.string(FormMessages.DATE_EXCEED, 'cargue', 'descargue'), undefined, 'alert');
        else
          validate = true;
      }
    } else if (step === 2) {
      this.form.get('shippingCost').markAllAsTouched();
      if (this.utilityCargo < this.utilityOwner) {
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.MINIMUN_UTILITY_NOT_REACHED, this.utilityOwner), undefined, 'alert');
      } else if (this.utils.errorMessagesCustomized(this.form.get('shippingCost.rate'), 'valor de la tarifa', null, null, 1)) { }
      else if (this.utils.errorMessagesCustomized(this.form.get('shippingCost.freightCost'), 'valor del flete', null, null, 1)) { }
      else if (this.utils.errorMessagesCustomized(this.form.get('shippingCost.advancePercentage'), 'porcentaje de anticipo', null, null, 0, this.maxAdvancePercentage)) { }
      else {
        validate = true;
      }
    }
    if (validate) this.step += 1;
  }
  onSubmit() {
    if (this.utils.errorMessagesCustomized(this.form.get('observation'), 'observación')) { }
    else if (this.utils.errorMessagesCustomized(this.form.get('shippingCost.paymentTime'), 'tiempo de pago del saldo')) { }
    else {
      if (this.form.get('shippingCost.paymentTime').value === -1) {
        if (!this.otherPaymentType.value) this.otherPaymentType.setValue(0);
        this.form.get('shippingCost.paymentTime').setValue(this.otherPaymentType.value);
      }
      this.spinner.show();
      this.manualCreationCargoService.createRoundedCargo(this.form.value).subscribe(
        (data: Cargo) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar("El servicio redondo fue creada exitosamente", undefined, 'success');
          this.closeDialog();
        }, (error) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar("Ocurrió un error al crear el servicio redondo", undefined, 'error');
        }
      );
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.otherPaymentSub) this.otherPaymentSub.unsubscribe();
  }
}
