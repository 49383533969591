<!-- DETAIL OF A LIST OF RECEIVABLES -->
<div *ngIf="listReceivables && listReceivables.length > 0 && !data.receivable">
  <!-- TITLES -->
  <div class="receivable-title color-secondary-dark mb-2 text-bold" *ngIf="type === 'active'">
    <i class="fas fa-file-invoice-dollar mr-2"></i>Cartera activa<i class="fas fa-question-circle ml-3"
      matTooltip="La cartera activa se refiere a la primera cartera generada en orden cronológico. Será aquella a la que primero se le procese el cobro de cartera"
      matTooltipPosition="above"></i>
  </div>
  <div class="receivable-title color-secondary-dark mb-2 text-bold" *ngIf="type === 'pending'">
    <i class="fas fa-exclamation-circle mr-2"></i>Lista de carteras pendientes<i class="fas fa-question-circle ml-3"
      matTooltip="Las carteras pendientes son aquellas carteras generadas después de la cartera activa en orden cronológico. Entran a calcularse los cobros (modificaciones) de dichas carteras luego de cancelar la totalidad de la cartera activa"
      matTooltipPosition="above"></i>
  </div>
  <div class="receivable-title color-secondary-dark mb-2 text-bold" *ngIf="type === 'paid'">
    <i class="fas fa-check-square mr-2"></i>Lista de carteras pagadas
  </div>
  <mat-accordion *ngFor="let receivable of listReceivables; let i = index" class="mb-3 p-0">
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header (click)="onClickDiv(i)">
        <mat-panel-title class="d-flex justify-content-between align-items-center">
          <div class="receivable-consecutive">
            Cartera # {{ receivable.consecutive }}
          </div>
          <div class="arrow-container ml-auto">
            <i class="fas fa-chevron-down arrow" [class.rotated]="rotateArrowStates[i]"></i>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-panel-description class="row p-3">
        <div class="col-12 col-lg-4 mb-1" *ngIf="receivable.creationDate"
          title="{{ receivable.creationDate | dateFormat:'only-date' }}">
          <span class="title-purple label">
            Fecha de creación: {{ receivable.creationDate | dateFormat:'only-date' }}
          </span>
        </div>
        <div class="col-12 col-lg-2 mb-1" *ngIf="receivable.creationDate"
          title="{{ receivable.creationDate | dateFormat:'time' }}">
          <span class="title-purple">
            {{ receivable.creationDate | dateFormat:'time'}}
          </span>
        </div>
        <div class="col-12 col-lg-6 mb-1" *ngIf="receivable.cargoConsecutive" title="{{ receivable.cargoConsecutive }}">
          <span class="title-purple">
            Id servicio asociado: {{ receivable.cargoConsecutive }}
          </span>
        </div>

        <div class="col-12 col-lg-6 mb-1" *ngIf="receivable.amountInitial"
          title="{{receivable.amountInitial | currency:'COP':'code'}}">
          <span class="title-purple label">
            Valor Inicial de la cartera:
          </span>
          <span class="text-bold label-field">
            {{receivable.amountInitial | currency:'COP':'code'}}
          </span>
        </div>

        <div class="col-12 col-lg-6 mb-1" *ngIf="receivable.amountReceived"
          title="{{receivable.amountReceived | currency:'COP':'code'}}">
          <span class="title-purple label">
            Valor abonado hasta la fecha a la cartera:
          </span>
          <span class="text-bold label-field">
            {{receivable.amountReceived | currency:'COP':'code'}}
          </span>
        </div>

        <div class="col-12 col-lg-6 mb-1" *ngIf="receivable.licensePlate" title="{{ receivable.licensePlate }}">
          <span class="title-purple label">
            Placa del vehiculo asociado:
          </span>
          <span class="text-bold label-field">
            {{receivable.licensePlate}}
          </span>
        </div>

        <div class="col-12 col-lg-6 mb-1" *ngIf="receivable.reason" title="{{receivable.reason}}">
          <span class="title-purple label">
            Razón de la cartera:
          </span>
          <span class="text-bold label-field">
            {{receivable.reason}}
          </span>
        </div>

        <div class="col-12 modification-list mt-3 mb-3">
          Lista de modificaciones
        </div>

        <div class="col-12 d-flex justify-content-center flex-column table-responsive">
          <table *ngIf="receivable.modifications && receivable.modifications.length > 0">
            <tr>
              <th class="begin">Id del servicio asociado</th>
              <th>Monto</th>
              <th>Fecha</th>
              <th>Hora</th>
              <th class="end">¿Aprueba el abono a cartera?</th>
            </tr>
            <tr *ngFor="let modification of getValidReceivables(receivable.modifications)">
              <td data-cell="Id del servicio asociado">{{ modification.cargoConsecutive }}</td>
              <td data-cell="Monto">{{ modification.amount | currency:'COP':'code'}}</td>
              <td data-cell="Fecha">{{modification.date | dateFormat:'only-date'}}</td>
              <td data-cell="Hora">{{modification.date | dateFormat:'time'}}</td>
              <td data-cell="¿Aprueba el abono a cartera?"
                class="d-flex flex-row justify-content-around align-items-center"
                *ngIf="modification.state.description !== 'Approved' && modification.state.description !== 'Rejected'">
                <button mat-raised-button class="primary button-width" color="primary"
                  (click)="modificationState(true, receivable.id, modification.id)">
                  Si
                </button>
                <button mat-raised-button class="primary-secondary button-width" color="primary"
                  (click)="modificationState(false, receivable.id, modification.id)">
                  No
                </button>
              </td>
              <td data-cell="¿Aprueba el abono a cartera?"
                class="d-flex flex-row justify-content-around align-items-center"
                *ngIf="modification.state.description === 'Approved'">
                <div *ngIf="modification.state.description === 'Approved'" class="modifications-state"
                  [ngClass]="{ 'paid': modification.state.description === 'Approved', 'pending': modification.state.description === 'Pending'}">
                  {{ modification.state.description === 'Approved' ? 'Aprobada' : modification.state.description ===
                  'Pending' ? 'Pendiente' : '-' }}
                </div>
              </td>
            </tr>
          </table>
          <div class="alert alert-info" role="alert" *ngIf="!(receivable.modifications)">
            No hay cálculos para abono a esta cartera. Recordar que primero se debe cancelar la <b>cartera activa</b>
          </div>
          <div class="alert alert-warning" role="alert"
            *ngIf="receivable.modifications && receivable.modifications.length === 0">
            No hay cálculos para abono a esta cartera.
          </div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<!-- DETAIL OF ONE RECEIVABLE -->
<div *ngIf="!listReceivables && data.receivable" class="receivable-container position-relative">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <p class="title mx-2 pt-4">Detalle de Abonos a la cartera # {{ data.receivable.consecutive}}</p>
  <div class="row px-3 px-sm-5 pt-2 pb-5">
    <div class="card col-12 pt-2 mb-2" *ngIf="data.holder">
      <div class="card-title row">
        <div class="receivable-title color-secondary-dark col-12">
          <i class="fas fa-user mr-2"></i>Información del tenedor
        </div>
      </div>
      <div class="card-body row pl-0 pr-0 pt-1">
        <div class="col" *ngIf="data.holder.name">
          <span class="title-purple label">
            Nombre:
          </span>
          <span class="text-bold label-field" title="{{ data.holder.name }}">
            {{ data.holder.name }}
          </span>
        </div>
        <div class="col" *ngIf="data.holder.documentTypeName">
          <span class="title-purple label">
            Tipo de documento:
          </span>
          <span class="text-bold label-field" title="{{ data.holder.documentTypeName }}">
            {{ data.holder.documentTypeName }}
          </span>
        </div>
        <div class="col" *ngIf="data.holder.document">
          <span class="title-purple label">
            N° de documento:
          </span>
          <span class="text-bold label-field" title="{{ data.holder.document }}">
            {{ data.holder.document }}
          </span>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-4 mb-1" *ngIf="data.receivable.creationDate"
      title="{{ data.receivable.creationDate | dateFormat:'only-date' }}">
      <span class="title-purple label">
        Fecha de creación: {{ data.receivable.creationDate | dateFormat:'only-date' }}
      </span>
    </div>
    <div class="col-12 col-lg-2 mb-1" *ngIf="data.receivable.creationDate"
      title="{{ data.receivable.creationDate | dateFormat:'time' }}">
      <span class="title-purple">
        {{ data.receivable.creationDate | dateFormat:'time'}}
      </span>
    </div>
    <div class="col-12 col-lg-6 mb-1" *ngIf="data.receivable.cargoConsecutive"
      title="{{ data.receivable.cargoConsecutive }}">
      <span class="title-purple">
        Id servicio asociado: {{ data.receivable.cargoConsecutive }}
      </span>
    </div>

    <div class="col-12 col-lg-6 mb-1" *ngIf="data.receivable.amountInitial"
      title="{{data.receivable.amountInitial | currency:'COP':'code'}}">
      <span class="title-purple label">
        Valor Inicial de la cartera:
      </span>
      <span class="text-bold label-field">
        {{data.receivable.amountInitial | currency:'COP':'code'}}
      </span>
    </div>

    <div class="col-12 col-lg-6 mb-1" *ngIf="data.receivable.amountReceived"
      title="{{data.receivable.amountReceived | currency:'COP':'code'}}">
      <span class="title-purple label">
        Valor abonado hasta la fecha a la cartera:
      </span>
      <span class="text-bold label-field">
        {{data.receivable.amountReceived | currency:'COP':'code'}}
      </span>
    </div>

    <div class="col-12 col-lg-6 mb-1" *ngIf="data.receivable.licensePlate" title="{{ data.receivable.licensePlate }}">
      <span class="title-purple label">
        Placa del vehiculo asociado:
      </span>
      <span class="text-bold label-field">
        {{data.receivable.licensePlate}}
      </span>
    </div>

    <div class="col-12 col-lg-6 mb-1" *ngIf="data.receivable.reason" title="{{data.receivable.reason}}">
      <span class="title-purple label">
        Razón de la cartera:
      </span>
      <span class="text-bold label-field">
        {{data.receivable.reason}}
      </span>
    </div>

    <div class="col-12 modification-list mt-3 mb-3">
      Lista de modificaciones
    </div>

    <div class="col-12 d-flex justify-content-center flex-column table-responsive">
      <table *ngIf="data.receivable.modifications && data.receivable.modifications.length > 0">
        <tr>
          <th class="begin">Id del servicio asociado</th>
          <th>Monto</th>
          <th>Fecha</th>
          <th>Hora</th>
          <th class="end">Estado de la aprobación</th>
        </tr>
        <tr *ngFor="let modification of data.receivable.modifications">
          <td data-cell="Id del servicio asociado">{{ modification.cargoConsecutive }}</td>
          <td data-cell="Monto">{{ modification.amount | currency:'COP':'code'}}</td>
          <td data-cell="Fecha">{{modification.date | dateFormat:'only-date'}}</td>
          <td data-cell="Hora">{{modification.date | dateFormat:'time'}}</td>
          <td data-cell="Estado de la aprobación" class="d-flex flex-row align-items-center">
            <div class="modifications-state"
              [ngClass]="{ 'paid': modification.state.description === 'Approved', 'pending': modification.state.description === 'Pending approval', 'rejected': modification.state.description === 'Rejected'}">
              {{ modification.state.description === 'Approved' ? 'Aprobada' : modification.state.description ===
              'Pending approval' ? 'Pendiente' : 'Rechazada' }}
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>