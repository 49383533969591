

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard } from 'src/app/core/guards/role.guard';
import { UserResolver } from 'src/app/core/resolves/user.resolve';
import { BanksResolver } from 'src/app/core/resolves/banks.resolve';
import { UserActiveGuard } from 'src/app/core/guards/user-active.guard';
import { FormLegalTermsComponent } from './legal-terms/form-legal-terms/form-legal-terms.component';
import { AdminUsersListComponent } from './admin-users/admin-users-list/admin-users-list.component';
import { UserFormComponent } from './admin-users/user-form/user-form.component';
import { ListVehiclesComponent } from './vehicles/list-vehicles/list-vehicles.component';
import { DetailVehicleComponent } from './vehicles/detail-vehicle/detail-vehicle.component';
import { ListCompaniesComponent } from './companies/list-companies/list-companies.component';
import { DetailCompanyComponent } from './companies/form-company/form-company.component';
import { FormVehicleComponent } from './vehicles/form-vehicle/form-vehicle.component';
import { ListRolesComponent } from './roles/list-roles/list-roles.component';
import { FormRoleComponent } from './roles/form-role/form-role.component';
import { ModuleListComponent } from './module/module-list/module-list.component'
import { ModuleFormComponent } from './module/module-form/module-form.component';
import { CityListComponent } from './city/city-list/city-list.component';
import { CityFormComponent } from './city/city-form/city-form.component';
import { ReceivableListComponent } from './receivable/receivable-list/receivable-list.component';
import { ReceivableCreateComponent } from './receivable/receivable-create/receivable-create.component';
import { UserClientFormComponent } from './admin-users/user-client-form/user-client-form.component';
import { LegalTermsComponent } from './legal-terms/legal-terms.component';
import { ListTrailersComponent } from './trailers/list-trailers/list-trailers.component';
import { CreateTrailerComponent } from './trailers/create-trailer/create-trailer.component';
import { CompanyConfigComponent } from './companies/company-config/company-config.component';
import { CompanyAccessGuard } from 'src/app/core/guards/company-access.guard';
import { MaintenanceListVehicleComponent } from './maintenance/maintenance-list-vehicle/maintenance-list-vehicle.component';
import { MaintenanceListDetailComponent } from './maintenance/maintenance-list-detail/maintenance-list-detail.component';
import { CreateEditMaintenanceComponent } from './maintenance/create-edit-maintenance/create-edit-maintenance.component';
import { CreateDriverFormComponent } from './admin-users/create-driver-form/create-driver-form.component';

const routes: Routes = [
  {
    path: '',
    data: { breadcrumb: 'Administración' },
    children: [
      {
        path: 'legal-terms',
        data: { breadcrumb: 'Términos Legales' },
        children: [
          {
            path: 'list',
            component: LegalTermsComponent,
            data: { breadcrumb: 'Listado' },
            canActivate: [UserActiveGuard, RoleGuard],
            resolve: {
              UserResolver
            },
          },
          {
            path: 'formTermsAndConditions',
            data: { breadcrumb: 'Creación' },
            component: FormLegalTermsComponent,
            canActivate: [UserActiveGuard],
            resolve: {
              UserResolver
            },
          },
          {
            path: 'formPrivacyPolicies',
            data: { breadcrumb: 'Creación' },
            component: FormLegalTermsComponent,
            canActivate: [UserActiveGuard],
            resolve: {
              UserResolver
            },
          },
          {
            path: 'formPersonalDataPolicy',
            data: { breadcrumb: 'Creación' },
            component: FormLegalTermsComponent,
            canActivate: [UserActiveGuard],
            resolve: {
              UserResolver
            },
          },
          { path: '**', redirectTo: '/administration/legal-terms/list' }
        ]
      },
      {
        path: 'cities',
        data: { breadcrumb: 'Ciudades' },
        children: [
          {
            path: 'list',
            component: CityListComponent,
            data: { breadcrumb: 'Listado' },
            canActivate: [UserActiveGuard, RoleGuard],
            resolve: {
              UserResolver
            },
          },
          {
            path: 'create',
            component: CityFormComponent,
            data: { breadcrumb: 'Creación' },
            canActivate: [UserActiveGuard],
            resolve: {
              UserResolver
            },
          },
          {
            path: 'detail/:id',
            component: CityFormComponent,
            data: { breadcrumb: 'Detalle' },
            canActivate: [UserActiveGuard],
            resolve: {
              UserResolver
            },
          },
          { path: '**', redirectTo: '/administration/cities/list' }
        ]
      },
      {
        path: 'admin-users',
        data: { breadcrumb: 'Usuarios' },
        children: [
          {
            path: 'list',
            component: AdminUsersListComponent,
            data: { breadcrumb: 'Listado' },
            canActivate: [UserActiveGuard, RoleGuard],
            resolve: {
              UserResolver
            }
          },
          {
            path: 'detail/:id',
            component: UserFormComponent,
            data: { breadcrumb: 'Detalle' },
            canActivate: [UserActiveGuard],
            resolve: {
              UserResolver
            }
          },
          {
            path: 'detail-user-client-form/:id',
            component: UserClientFormComponent,
            data: { breadcrumb: 'Detalle' },
            canActivate: [UserActiveGuard],
            resolve: {
              UserResolver
            }
          },
          {
            path: 'create',
            component: UserFormComponent,
            data: { breadcrumb: 'Creación' },
            canActivate: [UserActiveGuard],
            resolve: {
              UserResolver
            }
          },
          {
            path: 'create-driver',
            component: CreateDriverFormComponent,
            data: { breadcrumb: 'Crear conductor' },
            canActivate: [UserActiveGuard],
            resolve: {
              UserResolver
            }
          },
          { path: '**', redirectTo: '/administration/admin-users/list' }
        ]
      },
      {
        path: 'vehicles',
        resolve: {
          BanksResolver
        },
        data: { breadcrumb: 'Vehículos' },
        children: [
          {
            path: 'list',
            component: ListVehiclesComponent,
            data: { breadcrumb: 'Listado' },
            canActivate: [UserActiveGuard, RoleGuard],
            resolve: {
              UserResolver
            }
          },
          {
            path: 'detail/:id',
            component: DetailVehicleComponent,
            data: { breadcrumb: 'Detalle' },
            canActivate: [UserActiveGuard],
            resolve: {
              UserResolver
            }
          },
          {
            path: 'create',
            component: FormVehicleComponent,
            data: { breadcrumb: 'Creación' },
            canActivate: [UserActiveGuard],
            resolve: {
              UserResolver
            }
          },
          { path: '**', redirectTo: '/administration/vehicles/list' }
        ]
      },
      {
        path: 'trailers',
        resolve: {
          BanksResolver
        },
        data: { breadcrumb: 'Trailers' },
        children: [
          {
            path: 'list',
            component: ListTrailersComponent,
            data: { breadcrumb: 'Listado' },
            canActivate: [UserActiveGuard, RoleGuard],
            resolve: {
              UserResolver
            }
          },
          {
            path: 'create',
            component: CreateTrailerComponent,
            data: { breadcrumb: 'Creación' },
            canActivate: [UserActiveGuard],
            resolve: {
              UserResolver
            },
          },
          {
            path: 'detail/:id',
            component: CreateTrailerComponent,
            data: { breadcrumb: 'Detalle' },
            canActivate: [UserActiveGuard],
            resolve: {
              UserResolver
            },
          },
          { path: '**', redirectTo: '/administration/trailers/list' }
        ]
      },
      {
        path: 'companies',
        data: { breadcrumb: 'Compañías' },
        children: [
          {
            path: 'list',
            component: ListCompaniesComponent,
            data: { breadcrumb: 'Listado' },
            canActivate: [UserActiveGuard, RoleGuard],
            resolve: {
              UserResolver
            }
          },
          {
            path: 'detail/:id',
            component: DetailCompanyComponent,
            data: { breadcrumb: 'Detalle' },
            canActivate: [UserActiveGuard],
            resolve: {
              UserResolver
            }
          },
          {
            path: 'create',
            component: DetailCompanyComponent,
            data: { breadcrumb: 'Creación' },
            canActivate: [UserActiveGuard],
            resolve: {
              UserResolver
            }
          },
          {
            path: 'config',
            component: CompanyConfigComponent,
            data: { breadcrumb: 'Configuración SaaS' },
            canActivate: [UserActiveGuard, RoleGuard, CompanyAccessGuard],
            resolve: {
              UserResolver
            }
          },
          { path: '**', redirectTo: '/administration/companies/list' }
        ]
      },
      {
        path: 'roles',
        data: { breadcrumb: 'Roles' },
        children: [
          {
            path: 'list',
            data: { breadcrumb: 'Listado' },
            component: ListRolesComponent,
            canActivate: [UserActiveGuard, RoleGuard],
            resolve: {
              UserResolver
            }
          },
          {
            path: 'detail/:id',
            data: { breadcrumb: 'Detalle' },
            component: FormRoleComponent,
            canActivate: [UserActiveGuard],
            resolve: {
              UserResolver
            }
          },
          {
            path: 'create',
            data: { breadcrumb: 'Creación' },
            component: FormRoleComponent,
            canActivate: [UserActiveGuard],
            resolve: {
              UserResolver
            }
          },
          { path: '**', redirectTo: '/administration/roles/list' }
        ]
      },
      {
        path: 'module',
        data: { breadcrumb: 'Modulos' },
        children: [
          {
            path: 'list',
            component: ModuleListComponent,
            data: { breadcrumb: 'Listado' },
            canActivate: [UserActiveGuard, RoleGuard],
            resolve: {
              UserResolver
            }
          },
          {
            path: 'create',
            component: ModuleFormComponent,
            data: { breadcrumb: 'Creación' },
            canActivate: [UserActiveGuard],
            resolve: {
              UserResolver
            }
          },
          {
            path: 'detail/:id',
            component: ModuleFormComponent,
            data: { breadcrumb: 'Detalle' },
            canActivate: [UserActiveGuard],
            resolve: {
              UserResolver
            }
          },
          { path: '**', redirectTo: '/administration/module/list' }
        ]
      },
      {
        path: 'receivable',
        data: { breadcrumb: 'Cartera' },
        children: [
          {
            path: 'list',
            component: ReceivableListComponent,
            data: { breadcrumb: 'Listado' },
            canActivate: [UserActiveGuard, RoleGuard],
          },
          {
            path: 'create',
            component: ReceivableCreateComponent,
            data: { breadcrumb: 'Creación' },
            canActivate: [UserActiveGuard],
          },
          { path: '**', redirectTo: '/administration/receivable/list' }
        ]
      },
      {
        path: 'maintenance',
        data: { breadcrumb: 'Mantenimientos' },
        children: [
          {
            path: 'list',
            component: MaintenanceListVehicleComponent,
            data: { breadcrumb: 'Listado de vehículos' },
            canActivate: [UserActiveGuard, RoleGuard],
          },
          {
            path: 'detail/:id',
            component: MaintenanceListDetailComponent,
            data: { breadcrumb: 'Detalle de mantenimiento' },
            canActivate: [UserActiveGuard],
          },
          {
            path: 'create/:id',
            component: CreateEditMaintenanceComponent,
            data: { breadcrumb: 'Creación de mantenimiento' },
            canActivate: [UserActiveGuard],
          },

          { path: '**', redirectTo: '/administration/maintenance/list' }
        ]
      }

    ]
  },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministrationRoutingModule { }
