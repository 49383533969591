import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Utils } from '../resources/utils';
import { Subscription } from 'rxjs';

@Directive({
  selector: 'input[currencyMask]',
})
export class CurrencyMaskDirective implements OnInit {

  @Input() maskId: string;
  private subscription: Subscription;

  constructor(
    public ngControl: NgControl,
    private elementRef: ElementRef,
    public utils: Utils,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    const initialValue = this.ngControl.value;
    if (initialValue) this.checkValueState(initialValue);
    if (this.ngControl && this.ngControl.control) {
      this.subscription = this.ngControl.valueChanges.subscribe(value => {
        if (value !== null) this.checkValueState(value);
      });
    }
  }

  @HostListener('input', ['$event']) onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.checkValueState(input.value);
  }

  checkValueState(value: string | number): void {
    if (typeof value === 'number') this.setValues('formatStorageValues', value.toString());
    else this.setValues('formatNumber', value);
  }

  setValues(method: 'formatStorageValues' | 'formatNumber', value: string): void {
    const viewValue: string = value ? this.utils[method](value) : '';
    const normalizeNumber: number = value ? this.normalizeNumber(viewValue) : 0;
    this.ngControl.control.setValue(normalizeNumber, { emitEvent: false });
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', viewValue);
  }


  normalizeNumber(value: string): number {
    let cleanedValue = value.replace(/\./g, '');
    cleanedValue = cleanedValue.replace(/,/g, '.');
    return parseFloat(cleanedValue);
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  public formatValue(value: string | number): void {
    this.checkValueState(value);
  }
}
