<div class="list-transport-requests" infinite-scroll [infiniteScrollDistance]="paginationList.getScrollDistance()"
    [infiniteScrollThrottle]="paginationList.getThrottle()" [scrollWindow]="false" (scrolled)="onScrollDown()">
    <div class="row">
        <div class="col-md-9">
            <mat-card-actions class="d-flex justify-content-start flex-wrap m-0">
                <div class="d-xl-flex flex-column mb-2 mr-3">
                    <div class="secondary-button-t-group">
                        <div class="button-t flex-fill" *ngFor="let status of consignmentsStatus"
                            (click)="changeConsignmentStatus(status)" [ngClass]="{'selected': status.selected}">
                            {{status.title}}</div>
                    </div>
                </div>
            </mat-card-actions>
        </div>
        <div class="col-md-3">
            <app-list-filter class="d-flex justify-content-end" [options]="optionsFilter" [typeList]="typeList"
                (refreshList)="refreshList($event)"></app-list-filter>
        </div>
    </div>
    <!--app-cargo-item-request *ngFor="let transportRequet of paginationList.getList()" [cargoItemList]="transportRequet"
        [item]="transportRequet">
    </app-cargo-item-request-->
    <app-cargo-item *ngFor="let transportRequet of paginationList.getList()" [cargoItemList]="transportRequet"
        [typeList]="typeList" [option]="optionsFilter"
        [filteredCargosTurns]="getFilteredCargo(transportRequet?.consecutive, transportRequet?.cargoFeature?.vehicleType?.name, transportRequet?.cargoFeature?.uploadDownload?.origin?.municipalityCode, transportRequet?.dateLoad)"
        (applyFilter)="refreshList()">
    </app-cargo-item>
</div>