import { Injectable } from '@angular/core';
import { TravelExpense, TravelExpenseDetail, TravelExpensesFormState } from '../interfaces/travel-expense';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Endpoints } from '../resources/endpoints';
import { Observable } from 'rxjs';
import { Catalog } from '../interfaces/catalog';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TravelExpensesService {
  public static state: TravelExpensesFormState = null;

  constructor(
    private http: HttpClient,
    private endpointResources: Endpoints
  ) { }

  public createTravelExpense(expense: TravelExpense) {
    return this.http
      .post(`${environment.urlServerTeclogi}${this.endpointResources.travelExpenses}`, expense);
  }

  public byCargoId(cargoId: string): Observable<TravelExpense[]> {
    return this.http.get<TravelExpense[]>(`${environment.urlServerTeclogi}${this.endpointResources.travelExpenses}/${cargoId}`);
  }

  public removeTravelExpense(id: string) {
    return this.http.delete(`${environment.urlServerTeclogi}${this.endpointResources.travelExpenses}/${id}`);
  }

  public removeTravelExpenseDetail(travelExpenseId, travelExpensesDetailId) {
    return this.http.delete(`${environment.urlServerTeclogi}cargo/${travelExpenseId}/${this.endpointResources.travelExpensesDetail}/${travelExpensesDetailId}`);
  }

  public updateTravelExpense(id: string, expense: TravelExpense) {
    return this.http.put(`${environment.urlServerTeclogi}${this.endpointResources.travelExpenses}/${id}`, expense);
  }

  public createTravelExpensesDetail(travelExpenseId, travelExpensesDetail: TravelExpenseDetail) {
    return this.http.post(`${environment.urlServerTeclogi}cargo/${travelExpenseId}/${this.endpointResources.travelExpensesDetail}`, travelExpensesDetail);
  }

  public updateTravelExpensesDetail(travelExpenseId, travelExpensesDetail: TravelExpenseDetail) {
    return this.http.put(`${environment.urlServerTeclogi}cargo/${travelExpenseId}/${this.endpointResources.travelExpensesDetail}/${travelExpensesDetail.id}`, travelExpensesDetail);
  }

  public getTypes() {
    return this.http
      .get<Catalog>(`${environment.urlServerTeclogi}/${this.endpointResources.urlCatalog}/private/TravelExpensesType`)
      .pipe(
        map((catalog) => catalog.catalog)
      );
  }
}
