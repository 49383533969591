import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { Patterns } from 'src/app/core/resources/patterns';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { LegalTermsService } from 'src/app/modules/administration/legal-terms/legal-terms.service';
import { VehiclesService } from 'src/app/modules/administration/vehicles/list-vehicles.service';

@Component({
  selector: 'app-request-terms',
  templateUrl: './request-terms.component.html',
  styleUrls: ['./request-terms.component.scss']
})
export class RequestTermsComponent implements OnInit {
  private isUniquePhone: AsyncValidatorFn = (control: AbstractControl): Promise<ValidationErrors | null> => {
    return new Promise<ValidationErrors | null>((resolve) => {
      const phone = control.value;
      if (this.patterns.CELLPHONE.test(phone)) {
        this.vehiclesService
          .getUsersByPhone(phone)
          .subscribe(
            (result: any) => {
              const isTheSame = result && result.information && result.information.document ?
                this.data.document == result && result.information && result.information.document : false;
              if (!!result && !isTheSame)
                resolve({ 'duplicatedPhone': 'El número de celular ya corresponde a otra persona' })
              else resolve(null);
            }
          )
      }
    });
  };

  form = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.pattern(this.patterns.ALPHANUMERIC)
    ]),
    phone: new FormControl('', [
      Validators.required,
      Validators.pattern(this.patterns.CELLPHONE.source)
    ], [
      this.isUniquePhone
    ])
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { document: string, isDriverCreationEdition: boolean },
    private ref: MatDialogRef<RequestTermsComponent>,
    private vehiclesService: VehiclesService,
    private patterns: Patterns,
    private termsAndConditionsService: LegalTermsService,
    public utils: Utils,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit() {
  }

  onSubmit(): void {
    try {
      if (this.form.invalid) return this.snackBarService.openSnackBar('Por favor, complete todos los campos requeridos.', undefined, 'alert');
      let phone = this.form.get('phone').value;
      phone = phone.startsWith('57') ? phone.slice(2) : phone;
      this.spinner.show();
      this.termsAndConditionsService
        .requestTermsAndPoliciesAcceptance(this.data.document, this.form.get('name').value, `57${phone}`)
        .subscribe((result) => {
          this.spinner.hide();
          this.ref.close(result);
        },
          (error) => {
            this.spinner.hide();
            this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
          }
        )
    } catch (error) {
      this.spinner.hide();
      this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
    }
  }

  close() {
    this.ref.close(null);
  }

}
