<div class="col-md-12 driver">
    <div class="row">
        <!-- Image -->
        <div class="col-lg-3 driver__picture__column my-3">
            <figure class="driver__picture">
                <img src="/assets/svg/icons/icon-profile.png" alt="">
            </figure>
        </div>
        <!-- Detail -->
        <div class="col-lg-9">
            <div class="row mx-0 px-2">

                <!-- Driver name -->
                <div class="col-md-4 col-sm-6">
                    <div class="field__card">
                        <label class="field__card--label">
                            Conductor
                        </label>
                        <span class="field__card--value">
                            {{driver?.name ? (driver.name| capitalizeText):'-'}}
                        </span>
                    </div>
                </div>

                <!-- Phone -->
                <div class="col-md-4 col-sm-6">
                    <div class="field__card">
                        <label class="field__card--label">Celular</label>
                        <span class="field__card--value">
                            {{driver?.phoneNumber ? (driver.phoneNumber | phoneNumber): '-'}}
                        </span>
                    </div>
                </div>

                <!-- licensePlate -->
                <div class="col-md-4 col-sm-6">
                    <div class="field__card">
                        <label class="field__card--label">
                            Placa
                        </label>
                        <span class="field__card--value">
                            {{escortedVehicle?.licensePlate ? escortedVehicle?.licensePlate : '-'}}
                        </span>
                    </div>
                </div>

                <!-- vehicleType -->
                <div class="col-md-4 col-sm-6">
                    <div class="field__card">
                        <label class="field__card--label">Tipo de vehículo</label>
                        <span class="field__card--value">
                            {{escortedVehicle?.vehicleType ? (escortedVehicle?.vehicleType | capitalizeText) : '-'}}
                        </span>
                    </div>
                </div>

                <div class="col-md-4 col-sm-6" *ngIf="driver?.documentTypeName">
                    <div class="field__card">
                        <label class="field__card--label">Tipo de identificación</label>
                        <span class="field__card--value">
                            {{driver.documentTypeName}}
                        </span>
                    </div>
                </div>

                <div class="col-md-4 col-sm-6">
                    <div class="field__card">
                        <label class="field__card--label">N° identificación</label>
                        <span class="field__card--value">
                            {{driver?.document ? driver.document : '-'}}
                        </span>
                    </div>
                </div>

                <div class="col-md-4 col-sm-6" *ngIf="escortedVehicle?.brand">
                    <div class="field__card">
                        <label class="field__card--label">Marca</label>
                        <span class="field__card--value">
                            {{escortedVehicle?.vehicleType}}
                        </span>
                    </div>
                </div>

                <div class="col-md-4 col-sm-6" *ngIf="escortedVehicle?.color">
                    <div class="field__card">
                        <label class="field__card--label">Color</label>
                        <span class="field__card--value">
                            {{escortedVehicle?.color}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>