<aside class="col-md-12 mb-0 h-100-margin px-0 px-sm-4">
  <div *ngIf="hasOperations" [ngClass]="{'operations-div-inline':hasOperationsInline||hasOperationsInlineLeft, 
                'operations-div-top':hasOperationsTop,
                'operations-div-top-extra': hasOperationsTopExtra
              }">
    <app-list-operations [itemsSelecteds]="itemsSelecteds" [options]="optionsFilter" [typeList]="typeList"
      (refreshActualList)="refreshActualList()" (showDialog)="showDialog($event)"
      [filters]="filters"></app-list-operations>
  </div>
  <div id="mainListFreightList" class="container-list" [ngClass]="{
                'h-operations-inline':hasOperationsInline||hasOperationsInlineLeft,
                'h-operations-top':hasOperationsTop,
                'h-operations-top-extra':hasOperationsTopExtra,
                'h-100-margin':!hasOperations}">
    <div
      [ngClass]="{'d-flex flex-column-reverse flex-sm-row mt-3 mt-sm-0 justify-content-between':hasOperationsInlineLeft }">
      <div *ngIf="hasOperationsInlineLeft" class="total-container">
        Total servicios: <b class="text-bold">{{unfilteredList? unfilteredList.length: '0'}}</b>
      </div>
      <app-list-filter [options]="optionsFilter" [typeList]="typeList"
        (refreshList)="refreshList($event)"></app-list-filter>
    </div>
    <div *ngIf="paginationList.getList().length" class="container-list-body" infinite-scroll
      [infiniteScrollDistance]="paginationList.getScrollDistance()"
      [infiniteScrollThrottle]="paginationList.getThrottle()" [scrollWindow]="false" (scrolled)="onScrollDown()">
      <div class="d-flex justify-content-end">
        <div class="list-select-all mr-2" select-item-list [list]="paginationList.getList()"
          (onSelectItem)="onSelectItem($event)" [active]="showSelectAll" *ngIf="showSelectAll" [type]="'selectAll'"
          [exclude]="excludeItemsList"></div>
      </div>

      <div class="col-md-12 mb-md-0 mb-12" *ngFor="let cargo of paginationList.getList(); let i = index;">
        <app-cargo-item [cargoItemList]="cargo" [typeList]="typeList" select-item-list [option]="optionsFilter"
          [active]="getActiveItemSelected(cargo)" [item]="cargo" [showBells]="showBells"
          [list]="paginationList.getList()"
          [filteredCargosTurns]="getFilteredCargo(cargo?.consecutive, cargo?.cargoFeature?.vehicleType?.name, cargo?.cargoFeature?.uploadDownload?.origin?.municipalityCode, cargo?.dateLoad)"
          (onSelectItem)="onSelectItem($event)" (applyFilter)="refreshList()">
        </app-cargo-item>
        <!--app-cargo-item-request *ngIf="typeList === 'request'"  [cargoItemList]="cargo" [typeList]="typeList" select-item-list [option]="optionsFilter"
          [active]="getActiveItemSelected(cargo)" [item]="cargo" [showBells]="showBells"
          [list]="paginationList.getList()" 
          [filteredCargosTurns]="getFilteredCargo(cargo?.consecutive, cargo?.cargoFeature?.vehicleType?.name, cargo?.cargoFeature?.uploadDownload?.origin?.municipalityCode, cargo?.dateLoad)"
          (onSelectItem)="onSelectItem($event)" (applyFilter)="refreshList()">
        </app-cargo-item-request-->
      </div>
    </div>

    <div *ngIf="!paginationList.getList().length" class="container_no_results">
      <div class="icon"><i class="fas fa-calendar-times"></i></div>
      <h4 class="text-bold text-center">No hay resultados que coincidan con la búsqueda</h4>
    </div>
    <div class="my-1 d-flex justify-content-start"
      *ngIf="showConventionsPayment || showConventionsTransportedValue || showConventionsSelectItems || showConventionsPausedCargos">
      <div class="conv-btn" (click)="openConventions()">
        <i class="fas fa-circle"></i> Convenciones
      </div>
    </div>
  </div>
</aside>