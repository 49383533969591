interface Field {
    control: string;
    name?: string;
}
export const basicDataFields: Field[] = [
    {
    control: 'information.documentTypeName',
    name: 'tipo de identificación',
    },
    {
    control: 'information.document',
    name: 'n° de identificación',
    },
    {
    control: 'information.name',
    name: 'nombre',
    },
    {
    control: 'phone',
    name: 'celular',
    },
    {
    control: 'expeditionDateId',
    name: 'fecha de expedición'
    },
    {
    control: 'extraDocuments',
    },
    {
    control: 'licenses',
    }
];

export const basicDataFieldsEdition: Field[] = [
    {
        control: 'information.documentTypeName',
        name: 'tipo de identificación',
        },
        {
        control: 'information.document',
        name: 'n° de identificación',
        },
        {
        control: 'information.name',
        name: 'nombre',
        },
        {
        control: 'phone',
        name: 'celular',
        },
        {
        control: 'expeditionDateId',
        name: 'fecha de expedición'
        },
        {
        control: 'operationId',
        name: 'Operación'
        }
];

export const contactsAndReferencesFields: Field[] = [
    {
        control: 'emergencyContact.name',
        name: 'nombre del contacto de emergencia',
    },
    {
        control: 'emergencyContact.lastName',
        name: 'apellido del contacto de emergencia',
    },
    {
        control: 'emergencyContact.phone',
        name: 'celular del contacto de emergencia',
    },
    {
        control: 'referenceLaboral.name',
        name: 'empresa de la referencia laboral',
    },
    {
        control: 'referenceLaboral.phone',
        name: 'celular de la referencia laboral',
    },
    {
        control: 'referencePersonal.name',
        name: 'nombre de la referencia personal',
    },
    {
        control: 'referencePersonal.lastName',
        name: 'apellido de la referencia personal',
    },
    {
        control: 'referencePersonal.phone',
        name: 'celular de la referencia personal',
    }
]

export const driverDocumentsFields: Field[] = [
    {
        control: 'profilePicture',
        name: 'foto de perfil',
    },
    {
        control: 'frontDocument',
        name: 'foto documento de identidad (parte frontal)',
    },
    {
        control: 'backDocument',
        name: 'foto documento de identidad (parte trasera)',
    },
]
