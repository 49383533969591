import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { VehiclesService } from '../list-vehicles.service';
import { Utils } from 'src/app/core/resources/utils';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Global } from 'src/app/core/resources/global';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { DateManager } from 'src/app/core/managers/date.manager';
import { KeyValue } from '@angular/common';
import { DriverLicenseCategory } from 'src/app/core/interfaces/driverLicenseCategory';
import { DriverLicensesDTO, User } from 'src/app/core/interfaces/user';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { Subscription } from 'rxjs';
import { VehicleConfig } from 'src/app/core/interfaces/vehicleConfig';

@Component({
  selector: 'app-update-vehicle-fields',
  templateUrl: './update-vehicle-fields.component.html',
  styleUrls: ['./update-vehicle-fields.component.scss']
})
export class UpdateVehicleFieldsComponent implements OnInit {
  registrationDateFilter = DateManager.filter({ until: new Date() });
  vehicle: Vehicle;
  driver: User;
  permission = Permission;
  form: FormGroup = new FormGroup({});
  vehicleTypeControl = new FormControl();
  vehicleTypeSub: Subscription;
  optionsVehicleType: OptionsAutocomplete = {
    showAutocomplete: true
  };
  vehicleValidate = '';
  bodyworkTypeControl = new FormControl();
  bodyworkTypeSub: Subscription;
  optionsBodyworkType: OptionsAutocomplete = {
    title: 'Tipo de carrocería'
  };
  bodyworkValidate = '';
  fieldsRTM = [
    'validRTM.active',
    'validRTM.description',
    'validRTM.expirationDate',
  ]
  fieldsSOAT = [
    'validSOAT.active',
    'validSOAT.description',
    'validSOAT.expirationDate',
    'validSOAT.companyName',
    'validSOAT.companyNumber',
    'validSOAT.number'
  ];
  constructor(
    public dialogRef: MatDialogRef<UpdateVehicleFieldsComponent>,
    @Inject(MAT_DIALOG_DATA) public paramsDialog: {
      vehicle?: Vehicle,
      driver?: User,
      type: 'SOAT' | 'RTM' | 'vehicle' | 'license',
      license?: DriverLicenseCategory
    },
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private vehiclesService: VehiclesService,
    private permissionRole: PermissionRole,
    public utils: Utils,
    public global: Global,
  ) { }

  ngOnInit() {
    if (this.paramsDialog && this.paramsDialog.type === 'license') {
      if (!this.paramsDialog.driver) {
        this.dialogRef.close();
        return;
      }
      this.driver = this.paramsDialog.driver;
    }
    else if (this.paramsDialog && ['SOAT', 'RTM', 'vehicle'].includes(this.paramsDialog.type)) {
      if (!this.paramsDialog.vehicle) {
        this.dialogRef.close();
        return;
      }
      this.vehicle = this.paramsDialog.vehicle;
    }
    else this.dialogRef.close();

    this.initForm();
    this.verifyFields();
    this.setSubscriptions();
  }

  /**
  * @description Initializes the form
  */
  private initForm() {
    switch (this.paramsDialog.type) {
      case 'vehicle':
        this.form = new FormGroup({
          bodywork: new FormGroup({
            description: new FormControl(),
            code: new FormControl()
          }),
          brand: new FormGroup({
            description: new FormControl()
          }),
          manufacturingYear: new FormControl(),
          axles: new FormControl(),
          color: new FormControl(),
          fuelType: new FormGroup({
            description: new FormControl()
          }),
          chassisId: new FormControl(),
          motorId: new FormControl(),
          line: new FormGroup({
            description: new FormControl()
          }),
          vehicleType: new FormGroup({
            name: new FormControl()
          }),
          registrationDate: new FormControl(),
          emptyWeight: new FormControl()
        });
        break;
      case 'RTM':
        this.form.addControl('validRTM', new FormGroup({
          active: new FormControl(),
          description: new FormControl(),
          expirationDate: new FormControl()
        }));
        break;
      case 'SOAT':
        this.form.addControl('validSOAT', new FormGroup({
          active: new FormControl(),
          description: new FormControl(),
          expirationDate: new FormControl(),
          companyName: new FormControl(),
          companyNumber: new FormControl(),
          number: new FormControl(),
        }));
        break;
      case 'license':
        this.form = new FormGroup({
          category: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]),
          numberLicense: new FormControl('', Validators.required),
          restrictions: new FormControl(),
          expeditionDate: new FormControl('', Validators.required),
          expirationDate: new FormControl('', Validators.required),
          active: new FormControl(true, Validators.required),
        });
        break;
    }
  }

  /**
  * @description Verifies the vehicle fields to fill the form
  */
  private verifyFields() {
    switch (this.paramsDialog.type) {
      case 'vehicle':
        [...this.global.vehicleEditableFields, 'vehicleType.name', 'bodywork.description', 'bodywork.code'].forEach(field => {
          let value = this.utils.getNestedValue(this.vehicle, field);
          if (this.utils.isDefined(value)) {
            this.form.get(field).setValue(value);
            if (!this.canOverwriteInfo) {
              this.form.get(field).disable();
              if (field === 'vehicleType.name') this.vehicleValidate = 'disable';
              if (field === 'bodywork.description') this.bodyworkValidate = 'disable';
            }
          }
        });
        this.optionsVehicleType['initialValue'] = this.utils.getNestedValue(this.vehicle, 'vehicleType.name') ? this.utils.getNestedValue(this.vehicle, 'vehicleType.name') : '';
        this.optionsVehicleType = { ...this.optionsVehicleType };
        this.optionsBodyworkType['initialValue'] = this.utils.getNestedValue(this.vehicle, 'bodywork.description')
          ? this.utils.getNestedValue(this.vehicle, 'bodywork.description') : '';
        this.optionsBodyworkType['initialVehicleTypeName'] = this.utils.getNestedValue(this.vehicle, 'vehicleType.name')
          ? this.utils.getNestedValue(this.vehicle, 'vehicleType.name') : '';
        this.optionsBodyworkType = { ...this.optionsBodyworkType };
        if (this.vehicle.registrationDate) {
          this.form.get('registrationDate').setValue(DateManager.stringToDate(this.vehicle.registrationDate));
          !this.canOverwriteInfo && this.form.get('registrationDate').disable();
        }
        break;
      case 'RTM':
        this.fieldsRTM.forEach(field => {
          let value = this.utils.getNestedValue(this.vehicle, field);
          if (this.utils.isDefined(value)) {
            this.form.get(field).setValue(field.includes('.expirationDate') ? DateManager.stringToDate(value) : value);
            if (!this.canOverwriteInfo) this.form.get(field).disable();
          }
        });
        break;
      case 'SOAT':
        this.fieldsSOAT.forEach(field => {
          let value = this.utils.getNestedValue(this.vehicle, field);
          if (this.utils.isDefined(value)) {
            this.form.get(field).setValue(field.includes('.expirationDate') ? DateManager.stringToDate(value) : value);
            if (!this.canOverwriteInfo) this.form.get(field).disable();
          }
        });
        break;
      case 'license':
        if (this.paramsDialog.license) {
          Object.keys(this.paramsDialog.license).forEach(key => {
            const value = ['expeditionDate', 'expirationDate'].includes(key)
              ? DateManager.stringToDate(this.paramsDialog.license[key], 'YYYY-MM-DDTHH:mm:ssT')
              : this.paramsDialog.license[key];
            if (this.form.get(key)) this.form.get(key).setValue(value);
          });
          this.form.get("category").disable();
        }
        break;
    }
  }

  private setSubscriptions() {
    if (this.paramsDialog.type === 'vehicle')
      this.vehicleTypeSub = this.vehicleTypeControl.valueChanges.subscribe(value => {
        this.form.get('vehicleType.name').setValue(value && value.name ? value.name : '');
        if (value) {
          this.optionsBodyworkType['initialVehicleTypeId'] = value.id ? value.id : '';
          this.optionsBodyworkType['initialVehicleTypeName'] = null;
          this.optionsBodyworkType['initialValue'] = '';
          this.optionsBodyworkType = { ...this.optionsBodyworkType };
        }
      })
    this.bodyworkTypeSub = this.bodyworkTypeControl.valueChanges.subscribe(value => {
      this.form.get('bodywork.description').setValue(value && value.name ? value.name : '');
      this.form.get('bodywork.code').setValue(value && value.id ? value.id : '');
    });
  }

  /**
  * @description Organizes the ngFor by keyvalue in the original order of the object.keys
  */
  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  /**
  * @description Creates the body to update the vehicle by the form's changes
  */
  onSubmit() {
    if (this.paramsDialog.type === 'license') {
      if (this.utils.errorMessagesCustomized(this.form.get("category"), "Categoría", 2, 2)) return;
      if (this.utils.errorMessagesCustomized(this.form.get("numberLicense"), "N° de licencia")) return;
      if (this.utils.errorMessagesCustomized(this.form.get("expeditionDate"), "Fecha de expedición")) return;
      if (this.utils.errorMessagesCustomized(this.form.get("expirationDate"), "Fecha de expiración")) return;

      let body: DriverLicensesDTO = {
        driverLicense: {
          active: false
        },
        driverLicenseCategory: [...(this.driver && this.driver.driverLicenseCategory ? this.driver.driverLicenseCategory : [])]
      };
      let license = this.form.getRawValue();
      license.category = license.category.toUpperCase();
      license.expeditionDate = DateManager.dateToString(license.expeditionDate, 'YYYY-MM-DDTHH:mm:ssZ');
      license.expirationDate = DateManager.dateToString(license.expirationDate, 'YYYY-MM-DDTHH:mm:ssZ');
      if (!license.restrictions) delete license.restrictions;

      const index = body.driverLicenseCategory.findIndex(vehicleLicense => vehicleLicense.category === license.category);
      if (index !== -1) body.driverLicenseCategory[index] = { ...license };
      else body.driverLicenseCategory.push(license);

      const everyLicenseExpired: boolean = body.driverLicenseCategory.every((license: DriverLicenseCategory) => {
        let diff = DateManager.dateDiff(new Date(), null, license.expirationDate, 'YYYY-MM-DDTHH:mm:ssZ');
        return diff >= 0 || !license.active;
      });
      body.driverLicense = {
        active: !everyLicenseExpired,
        description: everyLicenseExpired ? 'Licencia de conducción no activa' : 'Licencia de conducción activa'
      };
      return this.updateLicenses(this.driver.information.document, body);
    }
    let body = this.utils.clone(this.vehicle);
    switch (this.paramsDialog.type) {
      case 'vehicle':
        [...this.global.vehicleEditableFields, 'vehicleType.name', 'bodywork.description', 'bodywork.code', 'registrationDate'].forEach(field => {
          const value = this.form.get(field);
          if (value && value.value && value.enabled) {
            if (field.includes(".")) {
              if (!body[field.split('.')[0]]) body[field.split('.')[0]] = {};
              body[field.split('.')[0]][field.split('.')[1]] = value.value
            }
            else if (field === 'registrationDate') body[field] = DateManager.dateToString(value.value);
            else if (field === 'emptyWeight') body[field] = Number(value.value);
            else body[field] = value.value;
          }
        });
        break;
      case 'RTM':
        if (!body.validRTM) body['validRTM'] = {};
        const activeRTM = this.form.get(`validRTM.active`);
        if (activeRTM && this.utils.isDefined(activeRTM.value) && activeRTM.enabled) {
          body['validRTM'].active = activeRTM.value;
          body['validRTM'].description = activeRTM.value ? 'Active' : 'Inactive';
        };
        const expirationDateRTM = this.form.get(`validRTM.expirationDate`);
        if (expirationDateRTM && expirationDateRTM.value && expirationDateRTM.enabled)
          body['validRTM'].expirationDate = DateManager.dateToString(expirationDateRTM.value, 'YYYY-MM-DDT00:00:00-05');
        break;
      case 'SOAT':
        if (!body.validSOAT) body['validSOAT'] = {};
        const active = this.form.get(`validSOAT.active`);
        if (active && this.utils.isDefined(active.value) && active.enabled) {
          body['validSOAT'].active = active.value;
          body['validSOAT'].description = active.value ? 'Active' : 'Inactive';
        };
        const expirationDate = this.form.get(`validSOAT.expirationDate`);
        if (expirationDate && expirationDate.value && expirationDate.enabled)
          body['validSOAT'].expirationDate = DateManager.dateToString(expirationDate.value, 'YYYY-MM-DDT00:00:00-05');

        ['companyName', 'companyNumber', 'number'].forEach(field => {
          const value = this.form.get(`validSOAT.${field}`);
          if (value && value.value && value.enabled)
            body['validSOAT'][field] = value.value;
        });
        break;
    }
    this.getVehicleConfig(body);
  }

  /**
  * @param {string} document is the driver's document
  * @param {} body is the vehicle's licenses
  * @description updates the vehicle fields and closes the dialog
  */
  public updateLicenses(document: string, body: DriverLicensesDTO) {
    this.spinner.show();
    this.vehiclesService.updateUserLicenses(document, body).subscribe(
      () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_SUCCESS);
        this.dialogRef.close({ state: true });
      },
      () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      },
    );
  }

  /**
  * @param {Vehicle} body is the vehicle updated
  * @description updates the vehicle fields and closes the dialog
  */
  public async updateVehicleFields(body: Vehicle): Promise<boolean> {
    try {
      await this.vehiclesService.updateVehicleFields(body).toPromise();
      return true;
    } catch (error) {
      this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      return false;
    }
  }

  /**
  * @returns {boolean} Returns true if the user the "editFieldsRUNT" permission, otherwise false
  * @description Verifies if the user has the "editFieldsRUNT" permission
  */
  private get canOverwriteInfo(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.administration.module,
      this.permission.administration.editFieldsRUNT
    );
  }

  private async getVehicleConfig(vehicle: Vehicle) {
    this.spinner.show();
    try {
      let response = await this.vehiclesService.getVehicleConfig(vehicle.axles).toPromise();
      this.setVehicleConfig(response, vehicle);
    } catch (error) { console.error(error) };
    let success = await this.updateVehicleFields(vehicle);
    if (success) await this.validateVehicleToRNDC(vehicle);
    this.spinner.hide();
  }

  setVehicleConfig(response: VehicleConfig[], vehicle: Vehicle) {
    if (response.length > 0) {
      let vehicleTypeName: string = vehicle.vehicleType.name.toLowerCase();

      if (!this.utils.isEmpty(vehicleTypeName) && vehicleTypeName === "camion") {
        vehicleTypeName = "camion rigido";
      }

      const listConfig: VehicleConfig[] = response.filter(config => config.name.toLowerCase().includes(vehicleTypeName));
      if (listConfig.length > 0) {
        listConfig.sort((a: VehicleConfig, b: VehicleConfig) => {
          return a.code.localeCompare(b.code);
        });
        vehicle.vehicleType.configuration = {
          code: listConfig[0].code,
          description: listConfig[0].name
        }
        /*if (listConfig[0].name === "tractocamion") {
          if (vehicle.emptyWeight > 10000) {
            vehicle.emptyWeight = listConfig[0].weight;
          }
        }*/
      }
    }
  }

  async validateVehicleToRNDC(vehicle: Vehicle) {
    try {
      await this.vehiclesService.rndcVehicleCreation(vehicle.id).toPromise();
      this.snackBarService.openSnackBar(ServiceMessages.VEHICLE_CHANGED_SUCCESS);
      this.dialogRef.close({ state: true });
    } catch (error) {
      this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
    }
  }

  ngOnDestroy() {
    if (this.bodyworkTypeSub) this.bodyworkTypeSub.unsubscribe();
    if (this.vehicleTypeSub) this.vehicleTypeSub.unsubscribe();
  }

}
