import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material';
import { Fingerprint } from 'src/app/core/interfaces/fingerprint';
import { LastPointLocation } from 'src/app/core/interfaces/lastPointLocation';
import { Utils } from 'src/app/core/resources/utils';
interface tripAnomalies{
  id: string
  lat: number
  lng: number
  data: Data
}
interface Data {
  key: string
  anomaly: string
  fingerprint: Fingerprint
  fromWeb: boolean
  observation: string
}

@Component({
  selector: 'app-filter-tracking-reports',
  templateUrl: './filter-tracking-reports.component.html',
  styleUrls: ['./filter-tracking-reports.component.scss']
})
export class FilterTrackingReportsComponent {
  @Input() tripAnomalies: tripAnomalies[] = [];
  @Input() lastPointLocations: LastPointLocation[] = [];
  @Output() emitFilteredHistoryNoveltyPoints: EventEmitter<tripAnomalies[] | LastPointLocation[]> = new EventEmitter<tripAnomalies[] | LastPointLocation[]>();
  filteredHistoryNoveltyPoints: tripAnomalies[] | LastPointLocation[] = [];
  typeTrackingValue: FormControl = new FormControl('Todos');
  typesTracking: string[] = ['Todos', 'Gps', 'Manuales'];
  constructor(
    private utils: Utils
  ) { }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes.tripAnomalies || changes.lastPointLocations) this.filterHistoryNoveltyPoints();
  }

  onSelectionChange($event: MatSelectChange): void {
    if (!$event || !$event.value) return;
    this.filterHistoryNoveltyPoints($event.value);
  }

  private filterHistoryNoveltyPoints(filterType: string = this.typeTrackingValue.value): void {
    this.filteredHistoryNoveltyPoints = [];
    if (this.tripAnomalies.length > 0) this.filteredHistoryNoveltyPoints = this.filterData(this.tripAnomalies, 'data.fingerprint.userId', filterType);
    else if (this.lastPointLocations.length > 0) this.filteredHistoryNoveltyPoints = this.filterData(this.lastPointLocations, 'fingerprint.userName', filterType);
    this.emitFilteredHistoryNoveltyPoints.emit(this.filteredHistoryNoveltyPoints);
  }

  private filterData<T>(data: T[], userIdPath: string, filterType: string): T[] {
    return data.filter(item => {
      let userId = this.utils.getNestedValue(item, userIdPath);
      if (!userId) return false;
      userId = userId.toLowerCase();
      switch (filterType) {
        case 'Gps':
          return userId === 'satrack';
        case 'Manuales':
          return userId !== 'satrack';
        default:
          return true;
      }
    });
  }


}
