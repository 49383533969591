<div class="position-relative pt-5 my-4 mx-2 mx-sm-4">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <app-type-vehicle class="pt-4 input-type" [inputFormControl]="vehicleTypeControl" [options]="optionsTypeVehicle"
    [validate]="validateTypeVehicle">
  </app-type-vehicle>
  <div class="input-type">
    <app-vehicle-body-work-type [inputFormControl]="bodyworkTypeControl" [validate]="validateBodyworkType"
      [options]="optionsBodyworkType">
    </app-vehicle-body-work-type>
  </div>
  <div class="button-container">
    <button mat-raised-button color="primary" type="button" class="button-width-auto bg-color-primary" (click)="edit()">
      Actualizar
    </button>
  </div>
</div>