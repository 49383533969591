export enum SCENARIO_STATUS {
    Geocoding = "Geocoding",
    Incomplete = "Incomplete",
    Ready = "Ready",
    Queueing = "Queueing",
    Optimizing = "Optimizing",
    Canceled = "Canceled",
    Error = "Error",
    Infeasible = "Infeasible",
    Optimized = "Optimized",
    Modified = "Modified",
    Approved = "Approved",
    Reoptimizing = "Reoptimizing",
    Started = "Started",
    Uploading = "Uploading"
};

export enum SCENARIO_STATUS_DICT {
    Geocoding = "Localizando las direcciones",
    Incomplete = "Faltan datos para ser optimizado",
    Ready = "Listo para ser optimizado",
    Queueing = "A la espera a ser optimizado",
    Optimizing = "Optimizando",
    Canceled = "Optimización cancelada",
    Error = "Error en la optimización",
    Infeasible = "No es posible encontrar una solución",
    Optimized = "Listo para creación de servicios",
    Modified = "Se modificó el escenario",
    Approved = "Optimización aprobada",
    Reoptimizing = "Reoptimizando",
    Started = "Iniciada",
    Uploading = "A la espera de crearse"
}

