
import { Injectable } from '@angular/core';

import { map, timeout } from 'rxjs/operators';
import * as _ from 'lodash';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Global } from 'src/app/core/resources/global';
import { Filter } from 'src/app/core/models/filter';
import { environment } from '../../../../environments/environment';
import { Utils } from 'src/app/core/resources/utils';
import { Company } from 'src/app/core/interfaces/company';
import { AuthService } from 'src/app/core/services/authentication.service';
import { DataPaymenCargo } from 'src/app/core/interfaces/dataPaymenCargo';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { CargoItemService } from '../cargo-item/cargo-item.service';
import { CargoBilling } from 'src/app/core/interfaces/cargoBilling';
import { CargoBillingPending } from 'src/app/core/interfaces/cargoBillingPending';
import { Observable, of } from 'rxjs';
import { BasicResponse } from 'src/app/core/interfaces/basicResponse';
import { AdditionalCostCargo } from 'src/app/core/interfaces/additionalCostCargo';
import { Tax } from 'src/app/core/interfaces/tax';
import { DateFormatPipe } from 'src/app/core/pipe/dateFormat.pipe';
import { EmailStructure } from 'src/app/core/interfaces/emailStructure';
import { DateManager } from 'src/app/core/managers/date.manager';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { CargoApprovalRequest } from 'src/app/core/interfaces/cargo-approval-request';
import { CargoStateEnum } from 'src/app/core/enums/cargoState.enum';
import { TravelExpensesService } from 'src/app/core/services/travel-expenses.service';
import { ApprovalStateEnum, TravelExpense, TravelExpenseDetail } from 'src/app/core/interfaces/travel-expense';
import { ServiceRequests } from 'src/app/core/interfaces/service-requests';
import { ServiceRequestsStates } from 'src/app/core/resources/serviceRequestStates';
import { Companies } from 'src/app/core/resources/companies';

@Injectable({
  providedIn: 'root'
})
export class FreightListService {

  cargoTabsLoadingRoutes = ["loadingRoutes", "emptyContainers", "urbanRoutes", "lastMileRoutes", "nationalRoutes", 'exportRoutes', 'importRoutes', "internationalRoutes"];
  sliceList = [];
  orderBy: string = '';
  permission = Permission;

  constructor(
    private _http: HttpClient,
    private _endpointResources: Endpoints,
    private _globalResources: Global,
    public utils: Utils,
    private authService: AuthService,
    private cargoItemService: CargoItemService,
    private dateFormatPipe: DateFormatPipe,
    public permissionRole: PermissionRole,
    private travelExpensesService: TravelExpensesService
  ) {

  }

  // public getListCargo(vehicleType, paramsFilter?) {
  public getListCargo(paramsFilter: string, pageKey?: number, pageSize?: number, typeList?: string) {
    // vehicleType(Filtro por tipo de vehiculo) Siempre se debe enviar por el vehiculo que active
    // origin
    // destination
    // dateLoad
    // cargoType(Se deben actualizar en las pantallas front)(Carga suelta, Contenedor)
    // initialRank
    // finalRank
    if (paramsFilter.startsWith('&')) {
      paramsFilter = paramsFilter.replace('&', '');
    }
    let url: string = environment.urlServerTeclogi + this._endpointResources.urlCargoList + paramsFilter;

    if (!this.cargoTabsLoadingRoutes.includes(typeList)) {
      if (pageKey && pageSize) url += '&pageKey=' + pageKey + '&pageSize=' + pageSize;
    }
    return this._http.get<Cargo[]>(
      url
    );
  }

  public getListCargoToPay(paramsFilter: string, pageKey?: number, pageSize?: number) {
    // vehicleType(Filtro por tipo de vehiculo) Siempre se debe enviar por el vehiculo que active
    // origin
    // destination
    // dateLoad
    // cargoType(Se deben actualizar en las pantallas front)(Carga suelta, Contenedor)
    // initialRank
    // finalRank
    if (paramsFilter.startsWith('&')) paramsFilter = paramsFilter.replace('&', '');
    if (pageKey && pageSize) paramsFilter += '&pageKey=' + pageKey + '&pageSize=' + pageSize;
    const url: string = environment.urlServerTeclogi + this._endpointResources.urlCargoListToPay + paramsFilter;

    return this._http.get<{ cargo: Cargo, validatedBankAccount: boolean }[]>(
      url
    );
  }

  public getListCargoTravelExpenses(paramsFilter: string, pageKey?: number, pageSize?: number) {
    if (paramsFilter.startsWith('&')) paramsFilter = paramsFilter.replace('&', '');
    if (pageKey && pageSize) paramsFilter += '&pageKey=' + pageKey + '&pageSize=' + pageSize;

    type Resource = { cargo: Cargo, travelExpenses: Array<TravelExpense> };

    return this._http
      .get<{ pagination: { pageKey: number, pageSize: number, total: number }, results: Array<Resource> }>(`${environment.urlServerTeclogi}${this._endpointResources.urlCargoListTravelExpenses}?${paramsFilter}`)
      .pipe(
        map(resource => resource.results)
      );
  }

  getListCargoServiceRequests(paramsFilter: string, pageKey?: number, pageSize?: number) {

    let params = new HttpParams();
    if (paramsFilter) {
      paramsFilter.split('&').forEach(param => {
        if (param) {
          const [key, value] = param.split('=');
          if (key && value) {
            params = params.append(key, value);
          }
        }
      });
    }

    if (pageKey && pageSize) {
      params = params.append('pageKey', pageKey.toString());
      params = params.append('pageSize', pageSize.toString());
    }
    return this._http.get<ServiceRequests[]>(
      `${environment.urlServerTeclogi}${this._endpointResources.urlCargoServiceRequests}`,
      { params }
    );
  }

  public getListCargoNegotiation(documentId) {
    return this._http.get(
      environment.urlServerTeclogi + this._endpointResources.urlCargoListNegotiation + documentId
    );
  }

  public getListCargoRequestNegotiation(nit: string, userType: string) {
    return this._http.get(
      // tslint:disable-next-line: max-line-length
      environment.urlServerTeclogi + this._endpointResources.urlCargoRequestListNegotiation + '?idCompany=' + nit + '&userType=' + userType
    );
  }

  public approvedCargo(data: CargoApprovalRequest, state?: boolean) {
    let url = environment.urlServerTeclogi + this._endpointResources.approvedCargo;
    if (this.utils.isDefined(state)) {
      url += 'approval=' + state;
    }
    return this._http.post(
      url,
      data
    );
  }

  public cashedCargo(body: CargoBilling, type: string): Observable<BasicResponse> {
    let params = new HttpParams();
    params = params.append('billingType', type);
    params = params.append('userId', this.authService.getUserSession().information.document);

    return this._http.post<BasicResponse>(
      environment.urlServerTeclogi + this._endpointResources.billingRegister,
      body,
      { params }
    );
  }

  public pendingBillingCargo(body: CargoBillingPending, type: string): Observable<BasicResponse> {
    let params = new HttpParams();
    params = params.append('billingType', type);
    params = params.append('userId', this.authService.getUserSession().information.document);

    return this._http.post<BasicResponse>(
      environment.urlServerTeclogi + this._endpointResources.billingRegister,
      body,
      { params }
    );
  }

  public getFileForPaymentsCargo(data: DataPaymenCargo, state, extraAmount: string, payWithAdditionalCost?: boolean, payWithTravelExpenses?: boolean) {
    let url = environment.urlServerTeclogi + this._endpointResources.getFileForPayments;
    if (extraAmount)
      url += 'extraAdvance' + '&userId=' + this.authService.getUserSession().information.document + '&extraAmount=' + extraAmount;
    else
      url += state + '&userId=' + this.authService.getUserSession().information.document;

    url += `&payWithAdditionalCost=${!!payWithAdditionalCost}`;

    if (this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.payWithTravelExpenses)) {
      url += `&payWithTravelExpenses=${!!payWithTravelExpenses}`;
    }

    return this._http.post(
      url,
      data,
      {
        responseType: 'blob',
        observe: 'response'
      }
    ).pipe(
      timeout(960000)
    );
  }

  public getOptionsFilter(typeList: string) {
    let optionsFilter: Filter = {};
    if (Object.keys(ServiceRequestsStates).includes(typeList)) {
      optionsFilter = {
        showfilterIdCargo: true,
      };
    } else {
      optionsFilter = {
        showFilterErrorManifest: false,
        showFilterDate: false,
        showFilterDriver: false,
        showFilterRaiting: false,
        showFilterNumberCargo: false,
        showFilterNumberDocument: false,
        showFilterContainer: true,
        showFilterAprovedCargo: false,
        showFilterAssignmentsCargo: false,
        showFilterTripTypeName: true,
        showFilterJournalId: false,
        showFilterJournalError: false,
        showFilterPaymentAdvanceCargo: false,
        showFilterPaymentBalanceCargo: false,
        showFilterPaymentAdditionalCostsCargo: false,
        showFilterPaymentExtraAdvanceCargo: false,
        showFilterPaymentState: false,
        showFilterCompany: true,
        showFilterMultipleCompanies: false,
        showFilterCharges: false,
        showFilterIncident: false,
        showfilterlicensePlate: true,
        showfilterEscortedLicensePlate: false,
        showfilterManifestCargo: true,
        showfilterIdCargo: true,
        showfilterIdBill: true,
        showFilterAmount: false,
        showFilterConfirmedDriver: false,
        showFilterFintech: false,
        showFilterTypeOfDate: false,
        showFilterOriginDestination: true,
        showFilterRegimeType: false,
        showFilterCreationDateFinal: false,
        showFilterCreationDateInitial: false,
        showFilterLastPointExistance: false,
        showFilterLastPointLapse: false,
        showFilterNumberDocumentDriver: true,
        showFilterOrderByTracking: false,
        showFilterByTag: true,
        showFilterCategory: true,

        // Viáticos
        showFilterExpensesState: false,
        showFilterExpensesApproval: false,
        showFilterExpensesPaid: false,
        showFilterPaymentExpenses: false,
        showFilterCargoConsecutive: false,
      };
    }

    switch (typeList) {
      case 'loadingRoutes':
        optionsFilter.showFilterErrorManifest = this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.showManifestError);
        optionsFilter.showFilterDate = true;
        optionsFilter.showFilterNumberDocument = true;
        optionsFilter.showFilterIncident = true;
        optionsFilter.showFilterAmount = true;
        optionsFilter.showFilterTypeOfDate = true;
        optionsFilter.showFilterRegimeType = true;
        optionsFilter.showFilterJournalId = true;
        optionsFilter.showFilterJournalError = true;
        optionsFilter.showFilterLastPointExistance = true;
        optionsFilter.showFilterLastPointLapse = true;
        optionsFilter.showFilterOrderByTracking = true;
        optionsFilter.showFilterCategory = true;
        break;
      case 'globalTracking':
        optionsFilter.showFilterIncident = true;
        optionsFilter.showFilterAmount = true;
        optionsFilter.showFilterTypeOfDate = true;
        optionsFilter.showfilterIdBill = false;
        optionsFilter.showFilterContainer = false;
        optionsFilter.showfilterManifestCargo = false;
        break;
      case 'latestLoads':
        optionsFilter.showFilterDate = true;
        optionsFilter.showFilterNumberDocument = true;
        break;
      case 'scheduledLoads':
        optionsFilter.showFilterDate = true;
        optionsFilter.showFilterDriver = true;
        optionsFilter.showFilterNumberDocument = true;
        optionsFilter.showFilterConfirmedDriver = true;
        optionsFilter.showFilterNumberDocumentDriver = false;
        break;
      case 'assignedServiceRequests':
      case 'notAssignedServiceRequests':
      case 'notPublishedServiceRequests':
        optionsFilter.showFilterDate = true;
        break;
      case 'loadsInNegotiation':
        optionsFilter.showfilterlicensePlate = false;
        optionsFilter.showFilterContainer = false;
        break;
      case 'expired':
        optionsFilter.showFilterDate = true;
        optionsFilter.showFilterNumberDocument = true;
        optionsFilter.showFilterJournalId = true;
        optionsFilter.showFilterJournalError = true;
        break;
      case 'loadsFinished':
        optionsFilter.showFilterDate = true;
        optionsFilter.showFilterNumberDocument = true;
        optionsFilter.showFilterRaiting = true;
        optionsFilter.showFilterJournalId = true;
        optionsFilter.showFilterJournalError = true;
        break;
      case 'approvePayments':
        optionsFilter.showFilterDate = true;
        optionsFilter.showFilterNumberDocument = true;
        optionsFilter.showFilterAprovedCargo = true;
        optionsFilter.showFilterPaymentState = true;
        optionsFilter.showFilterFintech = true;
        break;
      case 'paymentAdvanceCargo':
        optionsFilter.showFilterDate = true;
        optionsFilter.showFilterNumberDocument = true;
        optionsFilter.showFilterPaymentAdvanceCargo = true;
        optionsFilter.showFilterNumberDocumentDriver = false;
        break;
      case 'paymentExtraAdvanceCargo':
        optionsFilter.showFilterDate = true;
        optionsFilter.showFilterNumberDocument = true;
        optionsFilter.showFilterPaymentExtraAdvanceCargo = true;
        break;
      case 'paymentAdditionalCostsCargo':
        optionsFilter.showFilterDate = true;
        optionsFilter.showFilterNumberDocument = true;
        optionsFilter.showFilterPaymentAdditionalCostsCargo = true;
        break;
      case 'paymentBalanceCargo':
        optionsFilter.showFilterDate = true;
        optionsFilter.showFilterNumberDocument = true;
        optionsFilter.showFilterPaymentBalanceCargo = true;
        break;
      case 'paymentTravelExpenses':
        optionsFilter.showFilterContainer = false;
        optionsFilter.showFilterCompany = false;
        optionsFilter.showfilterlicensePlate = false;
        optionsFilter.showfilterManifestCargo = false;
        optionsFilter.showfilterIdCargo = false;
        optionsFilter.showfilterIdBill = false;
        optionsFilter.showFilterOriginDestination = false;
        optionsFilter.showFilterNumberDocumentDriver = false;
        optionsFilter.showFilterByTag = false;
        optionsFilter.showFilterCategory = false;

        optionsFilter.showFilterNumberCargo = true;
        optionsFilter.showFilterExpensesState = true;
        optionsFilter.showFilterExpensesApproval = true;
        optionsFilter.showFilterExpensesPaid = true;
        optionsFilter.showFilterPaymentExpenses = true;
        optionsFilter.showFilterCargoConsecutive = true;
        break;
      ////
      case 'charges':
        optionsFilter.showFilterDate = true;
        optionsFilter.showFilterNumberDocument = true;
        optionsFilter.showFilterCharges = true;
        break;
      case 'deleted':
        optionsFilter.showFilterDate = true;
        optionsFilter.showFilterNumberDocument = true;
        optionsFilter.showFilterCategory = true;
        break;
      case 'request':
        optionsFilter.showFilterDate = true;
        optionsFilter.showFilterNumberDocument = true;
        optionsFilter.showFilterCategory = true;
        optionsFilter.showFilterAssignmentsCargo = true;
        break;
      case 'urbanRoutes':
      case 'nationalRoutes':
      case 'exportRoutes':
      case 'importRoutes':
      case 'lastMileRoutes':
      case 'internationalRoutes':
      case 'emptyContainers':
        optionsFilter.showFilterErrorManifest = this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.showManifestError);
        optionsFilter.showFilterDate = true;
        optionsFilter.showFilterNumberDocument = true;
        optionsFilter.showFilterIncident = true;
        optionsFilter.showFilterAmount = true;
        optionsFilter.showFilterTypeOfDate = true;
        optionsFilter.showFilterRegimeType = true;
        optionsFilter.showFilterLastPointExistance = true;
        optionsFilter.showFilterLastPointLapse = true;
        optionsFilter.showFilterOrderByTracking = true;
        optionsFilter.showFilterCategory = true;
        break;
      case 'withoutPayment':
        optionsFilter.showFilterNumberDocument = true;
        optionsFilter.showFilterJournalId = true;
        optionsFilter.showFilterJournalError = true;
        optionsFilter.showFilterCreationDateInitial = true;
        optionsFilter.showFilterCreationDateFinal = true;
        break;
      case 'assignedRequests':
        optionsFilter.showFilterDate = true;
        optionsFilter.showFilterNumberDocument = true;
        optionsFilter.showFilterConfirmedDriver = true;
        optionsFilter.showFilterNumberDocumentDriver = false;
        break;
      case 'unassignedRequests':
        optionsFilter.showFilterDate = true;
        optionsFilter.showFilterNumberDocument = true;
        optionsFilter.showFilterCategory = true;
        optionsFilter.showFilterAssignmentsCargo = true;
        break;
    }

    if (this.authService.getCompanySaaS() && this.authService.getCompanySaaS().companyId === Companies.companiesNIT.SEGURIDAD_EXTREMA)
      optionsFilter.showfilterEscortedLicensePlate = true;

    if (this.authService.getUserSession().clientCompanyId === environment.rootNit) {
      if (["approvePayments", "paymentAdvanceCargo", "paymentExtraAdvanceCargo",
        "paymentAdditionalCostsCargo", "paymentBalanceCargo"].includes(typeList)) {
        optionsFilter.showFilterMultipleCompanies = true;
        optionsFilter.showFilterCompany = false;
      }
    }

    return optionsFilter;
  }

  public getStatesByTypeList(typeList: string): CargoStateEnum[] {
    let value = [];
    switch (typeList) {
      case 'withoutPayment':
        value.push(CargoStateEnum.END_SERVICE);
        value.push(CargoStateEnum.START_SERVICE);
        break;
    };
    return value;
  };

  public getStateTypeList(typeList: String, filterDriver?: boolean): CargoStateEnum {
    let stateType: CargoStateEnum = null;
    switch (typeList) {
      case 'loadingRoutes':
      case 'advance':
      case 'emptyContainers':
      case 'urbanRoutes':
      case 'lastMileRoutes':
      case 'nationalRoutes':
      case 'exportRoutes':
      case 'importRoutes':
      case 'internationalRoutes':
        stateType = CargoStateEnum.START_SERVICE;
        break;
      case 'latestLoads':
        stateType = CargoStateEnum.CREATED;
        break;
      case 'scheduledLoads':
        stateType = CargoStateEnum.CREATED;
        if (filterDriver) {
          stateType = CargoStateEnum.ACCEPTED;
        }
        break;
      case 'loadsInNegotiation':
        // otro servicio
        stateType = null;
        break;
      case 'loadsFinished':
      case 'balance':
      case 'approvePayments':
        stateType = CargoStateEnum.END_SERVICE;
        break;
      case 'request':
        stateType = CargoStateEnum.REQUEST;
        break;
      case 'expired':
        stateType = CargoStateEnum.EXPIRED;
        break;
      case 'deleted':
        stateType = CargoStateEnum.DELETED;
        break;
    }
    return stateType;
  }

  public getAllCompanies() {

    return this._http.get<Company[]>(
      environment.urlServerTeclogi + this._endpointResources.getAllCompanies
    );
  }

  public getTotalValueAdvanceCargos(cargos: Cargo[]): number {
    let value = 0;
    cargos.map((cargo: Cargo) => {
      value += cargo.shippingCost.valueAdvance;
    });
    return parseFloat(value.toString());
  }

  public async getTotalValueTravelExpenses(cargos: Cargo[]): Promise<number> {
    let value = 0;
    const promises = [];

    cargos.forEach(
      (cargo: Cargo) => {
        const promise = this.travelExpensesService
          .byCargoId(cargo.id)
          .toPromise();
        promises.push(promise);
      }
    );

    const results: Array<Array<TravelExpense>> = await Promise.all(promises);
    if (Array.isArray(results))
      results.forEach(
        (travelExpenses: Array<TravelExpense>) => {
          if (Array.isArray(travelExpenses))
            travelExpenses.forEach(
              (travelExpense: TravelExpense | null) => {
                if (!!travelExpense && !travelExpense.paid)
                  value += parseFloat(`${travelExpense.totalPaid}`);
              }
            );
        }
      );

    return parseFloat(value.toString());
  }

  public getTravelExpensesBalance(travelExpenses: Array<TravelExpense>): number {
    const totalPaid = travelExpenses
      .filter(travelExpense => travelExpense.paid)
      .reduce((acc, travelExpense: TravelExpense) => acc + travelExpense.totalPaid, 0);

    const totalValidSpent = travelExpenses
      .map(travelExpenses => travelExpenses.travelExpensesDetail)
      .reduce((acc, travelExpenseDetails: Array<TravelExpenseDetail>) => acc.concat(travelExpenseDetails), [])
      .filter(travelExpenseDetail => travelExpenseDetail.approval === ApprovalStateEnum.APPROVED)
      .reduce((acc, travelExpenseDetail) => acc + travelExpenseDetail.value, 0);

    return totalPaid - totalValidSpent;
  }

  public async getTotalTravelExpensesBalance(cargos: Cargo[]) {
    let value = 0;
    const promises = [];

    cargos.forEach(
      (cargo: Cargo) => {
        const promise = this.travelExpensesService
          .byCargoId(cargo.id)
          .toPromise();
        promises.push(promise);
      }
    );

    const results: Array<Array<TravelExpense>> = await Promise.all(promises);
    if (Array.isArray(results))
      results.forEach(
        (travelExpenses: Array<TravelExpense>) => {
          if (Array.isArray(travelExpenses)) {
            value += this.getTravelExpensesBalance(travelExpenses);
          }
        }
      );

    return parseFloat(value.toString());
  }

  public getTotalValueBalanceCargos(cargos: Cargo[]): number {
    let value = 0;
    cargos.map((cargo: Cargo) => {
      value += this.cargoItemService.getTotalValueBalance(cargo);
    });
    return parseFloat(value.toString());
  }

  public getTotalValueRateCargos(cargos: Cargo[]): number {
    // let value = 0;
    // cargos.map((cargo: Cargo) => {
    //     value += cargo.shippingCost.rate;
    // });
    // return parseFloat(value.toString());
    let totalBill: number = 0;
    cargos.forEach((cargo) => {
      totalBill += this.getTotalValueRateCargo(cargo);
    });
    return totalBill;
  }

  public totalValueUploadCargos(cargos: Cargo[]): number {
    let total: number = 0;
    cargos.forEach((cargo) => {
      if (cargo.additionalCosts && cargo.additionalCosts.length) {
        total = cargo.additionalCosts.filter((additionalCost) => {
          return additionalCost.type.name === 'Servicio cargue';
        }).reduce((accum, item) => item.financialInformation && item.financialInformation.cost ? accum + item.financialInformation.cost : accum, 0);
      }
    });
    return total;
  }

  public totalValueDownloadCargos(cargos: Cargo[]): number {
    let total: number = 0;
    cargos.forEach((cargo) => {
      if (cargo.additionalCosts && cargo.additionalCosts.length) {
        total = cargo.additionalCosts.filter((additionalCost) => {
          return additionalCost.type.name === 'Servicio descargue';
        }).reduce((accum, item) => item.financialInformation && item.financialInformation.cost ? accum + item.financialInformation.cost : accum, 0);
      }
    });
    return total;
  }

  public totalValueStandByCargos(cargos: Cargo[]): number {
    let total: number = 0;
    cargos.forEach((cargo) => {
      if (cargo.additionalCosts && cargo.additionalCosts.length) {
        total = cargo.additionalCosts.filter((additionalCost) => {
          return additionalCost.type.name === 'Stand by';
        }).reduce((accum, item) => item.financialInformation && item.financialInformation.cost ? accum + item.financialInformation.cost : accum, 0);
      }
    });
    return total;
  }

  public getTotalValueRateCargo(cargo: Cargo): number {
    // let value = 0;
    // cargos.map((cargo: Cargo) => {
    //     value += cargo.shippingCost.rate;
    // });
    // return parseFloat(value.toString());
    let isRootNit = this.authService.getCompany().companyId === environment.rootNit;
    let rate: number = 0;
    let totalBill: number = 0;
    let rateAdditional: number = 0;
    let rteFte: number = 0;
    let iva: number = 0;
    // if (cargo && cargo.shippingCost && cargo.shippingCost.productSiigo) {
    if (cargo && cargo.shippingCost) {
      rate = cargo.shippingCost.rate;
      totalBill += rate;
      if (cargo.additionalCosts && cargo.additionalCosts.length) {
        cargo.additionalCosts.forEach((additionalCost: AdditionalCostCargo) => {
          if (additionalCost.financialInformation && additionalCost.financialInformation.rate) {
            rateAdditional = additionalCost.financialInformation.rate;
          };
          if (additionalCost.type && additionalCost.type.billingTaxes) {
            additionalCost.type.billingTaxes.forEach((tax: Tax) => {
              const valuePercentage = this.utils.getPercentage(rateAdditional, tax.percentage);
              switch (tax.name) {
                case 'IVA 19%':
                  iva = valuePercentage;
                  break;
                case 'Retefuente 4%':
                  rteFte = valuePercentage;
                  break;
              }
            });
          }
          if (sessionStorage.getItem('_activeTab') === 'charges') {
            if (isRootNit) rateAdditional = (rateAdditional + iva - rteFte);
          }
          totalBill += rateAdditional;
        });
      }
    }
    return totalBill;
  }

  isPaymentCargo(typeList: string): boolean {
    switch (typeList) {
      case 'paymentAdvanceCargo':
      case 'paymentExtraAdvanceCargo':
      case 'paymentAdditionalCostsCargo':
      case 'paymentBalanceCargo':
      case 'paymentTravelExpenses':
        return true;
    }
    return false;
  }

  isTravelExpensePayment(typeList): boolean {
    if (typeList == 'paymentTravelExpenses')
      return true;
    return false;
  }

  getPaymentType(typeList: string): string {
    switch (typeList) {
      case 'paymentAdvanceCargo':
        return 'advance';
      case 'paymentExtraAdvanceCargo':
        return 'extraAdvance';
      case 'paymentAdditionalCostsCargo':
        return 'additionalCost';
      case 'paymentBalanceCargo':
        return 'balance';
    }
  }

  public sendReport(nitCompany, emails: EmailStructure[]) {
    let dateLoad = DateManager.dateToString(DateManager.substract(new Date(), 20, 'days'), 'YYYY-MM-DD ZZ');
    let endDate = DateManager.dateToString(new Date(), 'YYYY-MM-DD ZZ');
    return this._http.post(
      environment.urlServerTeclogi + this._endpointResources.sendReport + nitCompany + "?reportType=lastPoint&dateLoad=" + dateLoad + "&endDate=" + endDate,
      emails,
      { responseType: 'blob' }
    );
  }

  public getTotalAdditionalCosts(cargos: Cargo[]): number {
    let total: number = 0;
    cargos.forEach((cargo) => {
      if (cargo.additionalCosts && cargo.additionalCosts.length) {
        total += cargo.additionalCosts.filter((additionalCost) => {
          return (additionalCost.type.name === 'Servicio cargue' || additionalCost.type.name === 'Servicio descargue');
        }).reduce((accum, item) => item.financialInformation && item.financialInformation.cost && !item.financialInformation.paid ? accum + item.financialInformation.cost : accum, 0);
      }
    });
    return total;
  }

  public getTotalAdditionalCostsStandBy(cargos: Cargo[]): number {
    let total: number = 0;
    cargos.forEach((cargo) => {
      if (cargo.additionalCosts && cargo.additionalCosts.length) {
        total += cargo.additionalCosts.filter((additionalCost) => {
          return additionalCost.type.name === 'Stand by';
        }).reduce((accum, item) => item.financialInformation && item.financialInformation.cost && !item.financialInformation.paid ? accum + item.financialInformation.cost : accum, 0);
      }
    });
    return total;
  }

  public getTotalAdditionals(cargos: Cargo[]): number {
    let total: number = 0;
    cargos.forEach((cargo) => {
      if (cargo.additionalCosts && cargo.additionalCosts.length) {
        let data = cargo.additionalCosts.filter((additionalCost) => {
          return (additionalCost.type.name === 'Servicio cargue' || additionalCost.type.name === 'Servicio descargue');
        });
        total += data.length;
      }
    });
    return total;
  }

  public getTotalAdditionalStandBy(cargos: Cargo[]): number {
    let total: number = 0;
    cargos.forEach((cargo) => {
      if (cargo.additionalCosts && cargo.additionalCosts.length) {
        let data = cargo.additionalCosts.filter((additionalCost) => {
          return (additionalCost.type.name === 'Stand by');
        });
        total += data.length;
      }
    });
    return total;
  }

  public getOrderedListByTypeOfDate(list: Cargo[], isFromLoadingRoutes: boolean, start?: number, end?: number, orderBy?: string, filterErrorManifest?: boolean): Cargo[] {
    if (isFromLoadingRoutes && list) {
      let returnedList: Cargo[] = [];
      if (list.some(cargo => !cargo.lastPointLocation || !cargo.lastPointLocation.fingerprint || !cargo.lastPointLocation.fingerprint.date)) {
        returnedList = list.filter(cargo => !cargo.lastPointLocation || !cargo.lastPointLocation.fingerprint || !cargo.lastPointLocation.fingerprint.date);
      }
      if (list.some(cargo => !!(cargo.lastPointLocation && cargo.lastPointLocation.fingerprint && cargo.lastPointLocation.fingerprint.date))) {
        let filledList = [...list.filter(cargo => !!(cargo.lastPointLocation && cargo.lastPointLocation.fingerprint && cargo.lastPointLocation.fingerprint.date))];
        returnedList = returnedList.concat(
          filledList.sort((a, b) => this.lastPointLocationDiff(a) - this.lastPointLocationDiff(b))
        )
      }
      if (filterErrorManifest) {
        returnedList = returnedList.filter(cargo =>
          cargo.manifestError && cargo.manifestError.error && !cargo.manifestAuthorization)
      }
      if (orderBy) this.orderBy = orderBy;
      if (this.orderBy && this.orderBy === 'recentFirst') returnedList = returnedList.reverse();
      if (start && end) this.sliceList = this.sliceList.concat(returnedList.slice(start, end))
      else this.sliceList = returnedList.slice(0, 10);
      return this.sliceList;
    }
    return list;
  }

  private lastPointLocationDiff(cargo: Cargo, unit: 'days' | 'hours' | 'minutes' | 'seconds' = 'days') {
    return DateManager.dateDiff(cargo.lastPointLocation.fingerprint.date, "YYYY-MM-DD HH:mm ZZ", new Date(), null, unit);
  }

  public billXML(body) {
    return this._http.post(
      environment.urlServerTeclogi + this._endpointResources.billXML,
      body,
    );
  }

  /*private CalculateLastTripAnomaly(value) {
    let diff = DateManager.durationFormat(DateManager.dateDiff(value.tripAnomalies[value.tripAnomalies.length - 1].date, "DD/MM/YYYY", new Date(), null, "seconds"), "seconds");
    return diff.days;
  }*/

}
