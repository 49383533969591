import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { StandardMapComponent } from '../standard-map/standard-map.component';
import { GpsNotification } from 'src/app/core/interfaces/gps-notification';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { CargoDetailService } from 'src/app/modules/cargo/cargo-detail/cargo-detail.service';
import { Router } from '@angular/router';
import { Driver } from 'src/app/core/models/driver';
import { AccountService } from 'src/app/modules/account/account.service';
import { User } from 'src/app/core/interfaces/user';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Notification } from 'src/app/core/interfaces/notification';
import { NotificationTypes } from 'src/app/core/enums/notification-types.enum';

@Component({
  selector: 'app-gps-notification',
  templateUrl: './gps-notification.component.html',
  styleUrls: ['./gps-notification.component.scss']
})
export class GpsNotificationComponent implements OnInit {
  @ViewChild(StandardMapComponent, { static: true }) map: StandardMapComponent;
  mapOptions: OptionsAutocomplete = {};
  notification: Notification;
  cargo: Partial<Cargo> = {
    licensePlate: ''
  };
  driver: Partial<User> = {
    information: { name: '', document: '' },
    phone: ''
  };
  description: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private cargoService: CargoDetailService,
    private accountService: AccountService,
    private snackbarService: SnackBarService,
    private matDialogRef: MatDialogRef<GpsNotificationComponent>,
    private router: Router
  ) {
    this.notification = data;

    this.cargoService.detailCargo(this.notification.cargoId).subscribe((cargo: Cargo) => {
      this.cargo = cargo;

      if (!!this.cargo.lastPointLocationGps) {
        const { lat, lng } = this.cargo.lastPointLocationGps.location;
        this.mapOptions = { lat, lng };
      } else {
        this.snackbarService.openSnackBar("No se ha podido geolocalizar el vehículo", "Aceptar", "error", 10 * 60 * 1000);
      }

      this.accountService.validateEntity(1, this.cargo.driver).subscribe((driver: Driver) => {
        this.driver = driver as any;
      });
    });
  }

  ngOnInit() {

  }

  goToCargoDetail() {
    this.router.navigate(['cargo', 'tracking', this.cargo.consecutive]);
    this.matDialogRef.close();
  }

  getDescription(): string {
    const { driverDocument, driverName, licensePlate, phone } = this.notification.extraInformation;

    switch (this.notification.type) {
      case NotificationTypes.PANIC:
        return `El vehículo <b>${licensePlate}</b> con conductor <b>${driverName} (${driverDocument})</b> ha presionado el botón de pánico. Contacte al conductor al <b><a href="tel:+${phone}">+${phone}</a></b> inmediatamente.`;
      case NotificationTypes.GPS_OFF:
        return `Se ha apagado el GPS del vehículo <b>${licensePlate}</b> con conductor <b>${driverName} (${driverDocument})</b> Contacte al conductor al <b><a href="tel:+${phone}">+${phone}</a></b> inmediatamente.`;
      case NotificationTypes.ANTENNA:
        return `Se ha desconectado la antena del GPS del vehículo <b>${licensePlate}</b> con conductor <b>${driverName} (${driverDocument})</b> Contacte al conductor al <b><a href="tel:+${phone}">+${phone}</a></b> inmediatamente.`;
      case NotificationTypes.VEHICLE_OFF:
        return `Se ha apagado el vehículo <b>${licensePlate}</b> con conductor <b>${driverName} (${driverDocument})</b> Contacte al conductor al <b><a href="tel:+${phone}">+${phone}</a></b> inmediatamente.`;
      case NotificationTypes.LOW_BATTERY:
        return `El vehículo <b>${licensePlate}</b> con conductor <b>${driverName} (${driverDocument})</b> tiene la batería baja.Contacte al conductor al <b><a href="tel:+${phone}">+${phone}</a></b> inmediatamente.`;
      case NotificationTypes.OPENED_DOOR:
        return `Se ha abierto la puerta del vehículo <b>${licensePlate}</b> con conductor <b>${driverName} (${driverDocument})</b> Contacte al conductor al <b><a href="tel:+${phone}">+${phone}</a></b> inmediatamente.`;
      case NotificationTypes.DEFAULT_ALERT:
        return `Algún evento pone en riesgo el servicio del vehículo <b>${licensePlate}</b> con conductor ${driverName.toUpperCase()} (${driverDocument}). Contacte al conductor al <b><a href="tel:+${phone}">+${phone}</a></b> inmediatamente.`;
    }
  }
}
