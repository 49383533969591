import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileStorage } from 'src/app/core/interfaces/fileStorage';
import { ImageWithFingerprints, TravelConcept, TravelExpense, TravelExpenseDetail, TravelExpensesFormState } from 'src/app/core/interfaces/travel-expense';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { TravelExpensesService } from 'src/app/core/services/travel-expenses.service';
import { FilesStorageService } from 'src/app/shared/files-storage/files-storage.service';
import { FileService } from 'src/app/shared/files/file.service';

@Component({
  selector: 'app-travel-expenses-detail',
  templateUrl: './travel-expenses-detail.component.html',
  styleUrls: ['./travel-expenses-detail.component.scss']
})
export class TravelExpensesDetailComponent implements OnInit {

  public travelExpense: TravelExpense
  public form: FormGroup;
  public files: FileStorage[] = [];
  public listPreviewImages: string[] = [];

  @ViewChild('inputFile', { static: true }) inputFile: ElementRef;
  private basePath: string;
  private cargoId: string;
  private pathNoImage: string = "/assets/svg/icons/icon-no-file.svg";

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public ref: MatDialogRef<TravelExpensesDetailComponent>,
    private travelExpensesService: TravelExpensesService,
    private spinner: NgxSpinnerService,
    private snackbarService: SnackBarService,
    private fb: FormBuilder,
    public utils: Utils,
    private fileService: FileService,
  ) {
    this.initForm();
    this.initDialogData();
  }

  ngOnInit() {

  }

  private initDialogData(): void {
    if (this.data) {
      if (this.data.travelExpense) {
        this.travelExpense = this.data.travelExpense;
        this.cargoId = this.data.cargoId;
        this.basePath = `cargos/${this.cargoId}/travel-expenses/${this.travelExpense.id}/legalizations/`;
      }
    }
  }

  private initForm(): void {
    this.form = this.fb.group({
      description: [''],
      value: [null, [Validators.required, Validators.min(1)]],
    });
  }

  onSubmit(): void {
    if (this.utils.errorMessagesCustomized(this.form.get('value'), 'valor', null, null, 1)) return;
    else if (!this.files.length) {
      this.snackbarService.openSnackBar("Es obligatorio cargar por lo menos un documento", undefined, "alert");
      return;
    }
    this.uploadFileToStorage();
  }

  public handleFileInput($event: Event & { target: { files: FileList } }) {
    if ($event.target['files'].length) {
      for (let i = 0; i < $event.target['files'].length; i++) {
        const splittedNameFile = $event.target['files'][i]['name'].split('.');
        const formatFile = splittedNameFile[splittedNameFile.length - 1];
        const dateInMs = new Date().getTime();
        const fileActive: FileStorage = {
          storageData: {},
          fileData: {
            name: `travel-expenses-detail_${dateInMs}.${formatFile}`
          }
        };
        const modifiedFile = new File([$event.target['files'][i]], fileActive.fileData.name, {
          type: $event.target['files'][i].type
        });
        fileActive.fileData.file = modifiedFile;
        fileActive.fileData.uploaded = true;
        fileActive.fileData.size = this.utils.bytesToSize(fileActive.fileData.file.size);
        fileActive.fileData.url = `${this.basePath}${fileActive.fileData.name}`
        this.getPreviewImage(fileActive);
      }
    }
  }

  openWindowFile() {
    if (this.inputFile)
      this.inputFile.nativeElement.click();
  }

  private uploadFileToStorage(): void {
    this.spinner.show();
    let count = 1;
    this.files.map((file) => {
      this.fileService
        .addMultipleFilesToStorages(
          this.basePath,
          file.fileData.name,
          file.fileData.file
        )
        .then((response: any) => {
          if (count === this.files.length) {
            this.spinner.hide();
            this.saveTravelExpensesDetail();
            return;
          } else {
            count++;
          }
        })
        .catch((error) => {
          this.spinner.hide();
          this.snackbarService.openSnackBar(
            "Ocurrió un error al cargar el documento",
            undefined,
            "error"
          );
          this.spinner.hide();
          return;
        });
    });
  }

  private async saveTravelExpensesDetail() {
    this.spinner.show();
    const travelExpensesDetail: TravelExpenseDetail = this.form.value;
    travelExpensesDetail.imageWithFingerprints = await this.imageWithFingerprints;
    this.travelExpensesService.createTravelExpensesDetail(this.travelExpense.id, travelExpensesDetail).subscribe(
      (success) => {
        this.spinner.hide();
        this.ref.close(true);
      },
      (error) => {
        this.spinner.hide();
        this.snackbarService.openSnackBar(
          "Ocurrió un error al añadrír la legalización del viático",
          undefined,
          "error"
        );
      }
    );
  }

  private get imageWithFingerprints(): ImageWithFingerprints[] {
    return this.files.map(file => {
      return file.fileData.url;
    }).map((path) => {
      return { path };
    })
  }

  private async getPreviewImage(fileActive: FileStorage) {
    let url;
    if (fileActive.fileData.file.type && fileActive.fileData.file.type.match(/image\/*/))
      url = await this.utils.getImageBase64(fileActive.fileData.file);
    else
      url = this.pathNoImage;
    this.listPreviewImages.push(url);
    this.files.push(fileActive);
  }

  cancel() {
    this.ref.close();
  }

}
