<mat-form-field appearance="standard">
    <mat-label #tooltipMerchandise="matTooltip" matTooltipClass="tooltip__alert"
        matTooltip="Si no encuentras el tipo de mercancía, puedes seleccionar {{merchandiseDefault?.description}}"
        matTooltipPosition="right">
        Tipo de mercancía<span class="asterisk" *ngIf="utils.isRequiredField(formControltypeMerchandise)">*</span>
    </mat-label>
    <input (keyup)="changeValue()" type="text" placeholder="Tipo de mercancía" matInput id="typeMerchandise"
        [matAutocomplete]="auto" [formControl]="formControltypeMerchandise">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectValue($event)" [displayWith]="displayFn"
        [returnWith]="returnFn">
        <mat-option *ngIf="showDefaultMerchandise" [value]="merchandiseDefault"
            [matTooltip]="merchandiseDefault.description">
            <span>{{merchandiseDefault.description}}</span>
        </mat-option>
        <mat-option *ngFor="let merchandise of filteredTypesMerchandise | async" [value]="merchandise"
            [matTooltip]="merchandise.description">
            <span>{{merchandise.description}}</span>
        </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="formControltypeMerchandise?.errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
    </mat-error>
</mat-form-field>