import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VehicleNegotiation } from 'src/app/core/interfaces/negotiation/vehicle-negotiation';
import { VehicleNegotiationInfoUser } from 'src/app/core/interfaces/negotiation/vehicle-negotiation-info-user';
import { ResponseList } from 'src/app/core/interfaces/response-list';
import { VehicleNegotiationParams } from 'src/app/core/interfaces/VehicleNegotiationsParams';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransportRequestsService {

  constructor(
    private httpClient: HttpClient,
    private endpoints: Endpoints
  ) { }

  public getVehicleNegotiations(vehicleNegotiationParams: VehicleNegotiationParams = {}): Observable<ResponseList<VehicleNegotiationInfoUser>> {
    let params = new HttpParams();
    if (vehicleNegotiationParams.cargoId) {
      params = params.append('cargoId', vehicleNegotiationParams.cargoId);
    }
    if (vehicleNegotiationParams.consecutive) {
      params = params.append('consecutive', vehicleNegotiationParams.consecutive);
    }
    if (vehicleNegotiationParams.creatorUserId) {
      params = params.append('creatorUserId', vehicleNegotiationParams.creatorUserId);
    }
    if (vehicleNegotiationParams.licensePlates) {
      params = params.append('licensePlates', vehicleNegotiationParams.licensePlates);
    }
    if (vehicleNegotiationParams.pageKey) {
      params = params.append('pageKey', vehicleNegotiationParams.pageKey.toString());
    }
    if (vehicleNegotiationParams.pageSize) {
      params = params.append('pageSize', vehicleNegotiationParams.pageSize.toString());
    }
    if (vehicleNegotiationParams.state) {
      params = params.append('state', vehicleNegotiationParams.state);
    }
    if (vehicleNegotiationParams.userId) {
      params = params.append('userId', vehicleNegotiationParams.userId);
    }
    return this.httpClient.get<ResponseList<VehicleNegotiationInfoUser>>(
      `${environment.urlServerTeclogi}${this.endpoints.getVehicleNegotiations}`,
      { params }
    );
  }

  public negotiationsVehiclesOffers(idOffer: string, action: 'Accepted' | 'Refuse' | 'Negotiate'): Observable<any> {
    const body = {
      action
    }
    return this.httpClient.put<any>(
      `${environment.urlServerTeclogi}${this.endpoints.negotiationsVehiclesOffers}${idOffer}`,
      body
    );
  }
}
