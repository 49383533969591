export class PaginationList {
  private pageKey = 1;
  private pageSize = 25;
  private throttle = 300;
  private scrollDistance = 1;
  private enablePagingScroll = true;
  private list = [];

  public getPageKey(): number {
    return this.pageKey;
  }

  public setPageKey(pageKey: number): void {
    this.pageKey = pageKey;
  }

  public getPageSize(): number {
    return this.pageSize;
  }

  public setPageSize(pageSize: number): void {
    this.pageSize = pageSize;
  }

  public getThrottle(): number {
    return this.throttle;
  }

  public setThrottle(throttle: number): void {
    this.throttle = throttle;
  }

  public getScrollDistance(): number {
    return this.scrollDistance;
  }

  public setScrollDistance(scrollDistance: number): void {
    this.scrollDistance = scrollDistance;
  }

  public getEnablePagingScroll(): boolean {
    return this.enablePagingScroll;
  }

  public setEnablePagingScroll(enablePagingScroll: boolean): void {
    this.enablePagingScroll = enablePagingScroll;
  }

  public getList(): Array<any> {
    return this.list;
  }

  public setList(list: Array<any>): void {
    this.list = list;
  }

}
