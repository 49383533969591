import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Endpoints } from "src/app/core/resources/endpoints";
import { environment } from "../../../../environments/environment";
import { User } from "src/app/core/interfaces/user";
import { Utils } from "src/app/core/resources/utils";
import { AuthService } from "src/app/core/services/authentication.service";
import { Vehicle } from "src/app/core/interfaces/vehicle";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { DialogComponent } from "src/app/shared/dialog/dialog.component";
import { StateUserPipe } from "src/app/core/pipe/stateInactive.pipe";
import { UserDocuments } from "src/app/core/interfaces/userDocuments";
import { UserState } from "src/app/core/enums/user-state.enum";
import { UserClient } from "src/app/core/interfaces/userClient";
import { Roles } from "src/app/core/enums/roles.enum";
import { Observable} from "rxjs";
import { ExtraDocument } from "src/app/core/interfaces/extraDocument";
import { ContactUser } from "src/app/core/interfaces/contactUser";
import { DateManager } from "src/app/core/managers/date.manager";
import { CatalogItem } from "src/app/core/interfaces/catalogItem";
import { AdditionalCertification } from "src/app/core/interfaces/additionalCertification";
import { AdditionalCertificationEnum } from "src/app/core/enums/additionalCertification.enum";
import { PrevisualizationSettings } from "src/app/core/interfaces/previsualizationSettings";
import { Patterns } from "src/app/core/resources/patterns";
import { FormArray, FormGroup } from "@angular/forms";
import { FileNamePipe } from "src/app/core/pipe/fileName.pipe";
import { DriverLicenseCategory } from "src/app/core/interfaces/driverLicenseCategory";
import { DynamicFirebaseService } from "src/app/core/services/dynamic-firebase.service";
import { Fmt } from "src/app/core/messages/fmt";

@Injectable({
  providedIn: "root"
})
export class AdminUsersService {
  public static userSelected: User;
  previsulizationSettings: PrevisualizationSettings = {};
  previewPrevizualizationSettings: PrevisualizationSettings = {};
  constructor(
    private http: HttpClient,
    private endpointResources: Endpoints,
    private utils: Utils,
    private authService: AuthService,
    public dialog: MatDialog,
    private stateUserPipe: StateUserPipe,
    private patterns: Patterns,
    public fileNamePipe: FileNamePipe,
    public dynamicService: DynamicFirebaseService 
  ) { }

  getResourceFile(typeFile: string): File | {url: string, type: string} | null {
    return this.previsulizationSettings && this.previsulizationSettings[typeFile] && this.previsulizationSettings[typeFile].file ? this.previsulizationSettings[typeFile].file : null;
  }

  getErrorLoadResource(typeFile: string): boolean {
    return this.previsulizationSettings && this.previsulizationSettings[typeFile] && this.previsulizationSettings[typeFile].errorLoadResource ? this.previsulizationSettings[typeFile].errorLoadResource : false;
  }

  async getResourceFromStorage(filePath: string, isPrincipalCompanyId: boolean = false, nit?: string): Promise<string> {
    try {
      const storage = isPrincipalCompanyId ? AuthService.fStorage : this.dynamicService.getStorage(nit);
      const storageRef = storage.ref(filePath);
      const fileMeta = await storageRef.getMetadata();
      if (!fileMeta) return '';
      const url = await storageRef.getDownloadURL();
      return url;
    } catch {
      return '';
    }
  }

  async searchResourceDynamicallyAmongCompanies(filePath: string, typeFile: string, role?: string) {
    let resourceFound = false;
    let companyIds = this.dynamicService.getCompanyIds();
    companyIds.unshift(this.authService.getUserSession().clientCompanyId);
    for (const companyId of companyIds) {
      const url = await this.getResourceFromStorage(filePath, companyId === this.authService.getUserSession().clientCompanyId, companyId);
      if (url) {
        this.previsulizationSettings[role ? `${typeFile}-${role}` : typeFile] = {
          file: {
            url: url,
            type: filePath.split('.').reverse()[0].toLowerCase(),
          },
          loaded: true
        };
          resourceFound = true;
          break;
        }
    }
    if (!resourceFound) {
      this.previsulizationSettings[role ? `${typeFile}-${role}` : typeFile] = {
        errorLoadResource: true
      };
    }
  }
  
  async loadResources(paths: { path: string, type: {name: string, loaded: boolean} }[], role?: string): Promise<void> {
    if (paths && paths.length) {
      const promises = paths.map(path => this.searchResourceDynamicallyAmongCompanies(path.path, path.type.name, role));
      await Promise.all(promises);
    }
  }

  public checkControlHaveStorageValue(type: string, form: FormGroup): string{
    const controlValue = form && form.get(type) && form.get(type).value;
    return controlValue;
  }

  public checkRenderResources(types: {name: string, loaded: boolean}[], previewPrevizualizationSettingsSended: PrevisualizationSettings, form?: FormGroup, arrayValidation?: FormArray): { name: string; loaded: boolean; }[] {
    this.previewPrevizualizationSettings = {...previewPrevizualizationSettingsSended}
    // Siempre que ingrese elimine los recursos cargados anteriormente para evitar que se muestren los recursos cargados anteriormente
    Object.keys(this.previsulizationSettings).forEach(key => {
      let existsInTypes = types.some(type => type.name === key);
      if (existsInTypes) delete this.previsulizationSettings[key];
    });

    let hasMatchTypes: boolean = false;
    if (this.previewPrevizualizationSettings) {
      const keys = Object.keys(this.previewPrevizualizationSettings);
      hasMatchTypes = keys.some(key => types.some(type => type.name === key));
    }
    if (hasMatchTypes) {
      this.previsulizationSettings = this.previewPrevizualizationSettings;
      Object.keys(this.previsulizationSettings).forEach(key => {
        let matchKey: boolean = false;
        if (arrayValidation) matchKey = !!(arrayValidation.controls.find((document: FormGroup) => document.get('type').value.toLowerCase() === key));
        else matchKey = !!(form &&form.get(key) && form.get(key).value);
        if (this.previsulizationSettings[key] && this.previsulizationSettings[key].loaded && matchKey && types.find(type => type.name === key)) types.find(type => type.name === key).loaded = true;
      });
    }
    else {
      // Si no hay nada previo significa que solo hay recursos cargados
      types.forEach(type => type.loaded = true) 
    }
    return types;
  }



  /**
  * @param {string} fileName is the file's name
  * @returns {string}  returns the file's name in the format expected to be uploaded.
  * @description transforms the file's name from route format into another specific string format using the userViewFileName method
  */
  traslateFileName(fileName: string): string {
    const nameSplit = fileName.split('/');
    const fileNameFormatted = nameSplit.length > 0 ? nameSplit[nameSplit.length - 1] : nameSplit[0];
    if (this.patterns.FILE_FORMAT_FULL.test(fileNameFormatted) || this.patterns.FILE_FORMAT.test(fileNameFormatted)) return this.userViewFileName(fileNameFormatted);
    return fileName;
  }


  
  /**
  * @param {string} fileName is the file's name formatted
  * @returns {string} returns the file's name transformed by fileNamePipe.
  * @description transforms the file's name using the fileNamePipe
  */
  userViewFileName(fileName: string): string {
    return this.fileNamePipe.transform(fileName);
  }



  /**
  * @param {'user'|'userClient'} type is the type of the user
  * @param {number} pageKey is the current page
  * @param {number} pageSize is the size of the page
  * @param {boolean} state is the user's state (active, inactive, pending...)
  * @param {string} document is the user's document
  * @param {string} role is the user's role (admin, reader, driver, owner...)
  * @param {string} description is an aux param to find users with state "webUserPendingActivate"
  * @param {string} phone is the user's phone number as string
  * @param {string} name is the user's name
  * @param {string} email is the user's email
  * @param {string} nit is the user's nit
  * @param {string} holderCompanyId is the user's holder company (nit)
  * @returns {Observable<User[]>} returns an array of users or userclients (as User) filtered by method's params.
  * @description gets a user/userclient list filtered depending by method's params using backend service.
  */
  getUsersList(
    type: 'user' | 'userClient',
    pageKey: number,
    pageSize: number,
    state?: boolean,
    document?: string,
    role?: string,
    description?: string,
    phone?: string,
    name?: string,
    email?: string,
    nit?: string,
    holderCompanyId?: string
  ): Observable<User[] | UserClient[]> {
    let params = new HttpParams();
    params = params.append("pageKey", pageKey.toString());
    params = params.append("pageSize", pageSize.toString());
    let url = `${environment.urlServerTeclogi}${type === 'user' ? this.endpointResources.usersList : this.endpointResources.usersClientList}?`;
    if (holderCompanyId && type === 'user') params = params.append("holderCompany", holderCompanyId);
    if (document) params = params.append("document", document);
    if (phone) params = params.append("phone", phone);
    if (state !== null && state !== undefined) params = params.append("state", state.toString());
    if (role) params = params.append("role", role);
    if (description) params = params.append("description", description);
    if (name) params = params.append("name", name);
    if (email) params = params.append("email", email);
    if (nit) params = params.append("nit", nit);

    return this.http.get<User[] | UserClient[]>(url, { params });
  }

  /**
  * @param {string} document is the user's document
  * @returns {Observable<User>} returns one single user
  * @description gets a user by document param using backend service
  */
  getUsersDetailByDocument(document: string): Observable<User> {
    return this.http.get<User>(
      environment.urlServerTeclogi +
      this.endpointResources.urlDetailUserByDocument +
      document
    );
  }

  /**
  * @param {string} document is the user's document
  * @returns {Observable<UserClient[]>} returns an array of userclients
  * @description gets a user by document param using backend service
  */
  getUserClientByDocument(document: string): Observable<UserClient[]> {
    return this.http.get<UserClient[]>(
      environment.urlServerTeclogi +
      this.endpointResources.urlDetailUserClientByDocument + document
    );
  }

  /**
  * @param {User} user is the user to update
  * @returns {Observable<User>} returns the user updated
  * @description updates a user using backend service
  */
  updateUser(user: User): Observable<User> {
    return this.http.put<User>(
      environment.urlServerTeclogi + this.endpointResources.urlUpdateUser,
      user
    );
  }

  /**
  * @param {string} document is the user's document to activate
  * @returns {Observable<User>} returns the user activated
  * @description Activates a user
  */
  activateUser(userDocument: string): Observable<User> {
    return this.http.put<User>(`${environment.urlServerTeclogi}${this.endpointResources.activateUser}${userDocument}`, {});
  }

  /**
  * @param {string} document is the user's document
  * @returns {Observable<UserClient>} returns the userClient activated
  * @description activates a userClient or assigns him a role
  */
  activateUserClient(
    userDocument: string,
    roleUserToActive?: string
  ): Observable<UserClient> {
    let params = new HttpParams();
    params = params.append('document', userDocument);
    params = params.append('active', 'true');
    if (roleUserToActive)
      params = params.append("roleId", roleUserToActive);

    return this.http.put<UserClient>(
      environment.urlServerTeclogi +
      this.endpointResources.activateUserClient +
      params,
      {}
    );
  }

  /**
  * @param {string} idUser is the user's document
  * @param {string} reason is the reason to deactivate the user
  * @returns {Observable<User|UserClient>} returns the user deactivated
  * @description deactivates a user
  */
  deactivateUser(idUser: string, reason: string): Observable<User | UserClient> {
    return this.http.post(
      environment.urlServerTeclogi +
      this.endpointResources.deactivateUser +
      idUser,
      { message: reason }
    );
  }

  /**
 * @param {string} idUser is the userClient's document
 * @param {string} reason is the reason to deactivate the userClien
 * @returns {Observable<UserClient>} returns the userClient deactivated
 * @description deactivates a userClient
 */
  deactivateUserClient(idUser: string, reason: string): Observable<UserClient> {
    return this.http.put<UserClient>(
      environment.urlServerTeclogi +
      this.endpointResources.deactivateUserClient +
      idUser,
      { message: reason }
    );
  }

  /**
  * @param {string} userDocument is the document of the user to activate
  * @returns {Observable<Object>} returns the result of the activation process
  * @description forces a user activation using backend service
  */
  forceActivationDriver(userDocument: string): Observable<Object> {
    let params: string =
      "document=" +
      userDocument +
      "&responsable=" +
      this.authService.getUserSession().information.document;
    return this.http.put<Object>(
      environment.urlServerTeclogi +
      this.endpointResources.forceActivationDriver +
      params,
      {}
    );
  }

  /**
  * @param {string} document is the document of the user
  * @returns {Observable<Object>} returns the Truora's PDF
  * @description Gets the Truora's PDF of the user passed by params using backend service
  */
  getPDFTruoraUser(document: string): Observable<Object> {
    return this.http.get<Object>(
      environment.urlServerTeclogi +
      this.endpointResources.getPDFTruoraUser +
      document
    );
  }

  /**
  * @param {string} nitCompany is the nit of the company to get its roles
  * @returns {Observable<Object>} returns the list of roles associated to the company
  * @description Gets the list of roles by company param using backend service
  */
  getRolesCompany(nitCompany: string) {
    const url =
      environment.urlServerTeclogi + this.endpointResources.rolesCompant;
    if (nitCompany === null) {
      return this.http.get(url);
    } else {
      return this.http.get(url + "nit=" + nitCompany);
    }
  }

  /**
  * @param {string} userId is the document of the user to check
  * @returns {Observable<Object>} returns the result of checking user's license plate states
  * @description Checks the user's license plate using backend service
  */
  checkValidationUser(userId: string): Observable<Object> {
    return this.http.get(
      environment.urlServerTeclogi + this.endpointResources.checkValidationUser + userId
    );
  }

  /**
  * @param {User} user is the user to check
  * @returns {Observable<Object>} returns the result of checking user's Truora
  * @description Checks the user's Truora using backend service
  */
  checkValidationIndividual(user: User): Observable<Object> {
    return this.http.post(
      environment.urlServerTeclogi + this.endpointResources.checkValidationIndividual + '?forceCreation=true',
      user
    );
  }

  /**
  * @param {User} user is the user to check
  * @returns {Observable<Object>} returns the result of checking user's Truora
  * @description Checks the user's Truora using backend service
  */
  checkValidationDynamicallyIndividual(user: User, forceCreation: boolean): Observable<Object> {
    let url = `${environment.urlServerTeclogi}${this.endpointResources.checkValidationIndividual}`;
    if (forceCreation) url += `?forceCreation=${forceCreation}`;
    return this.http.post(url, user);
  }

  /**
  * @param {string} licensePlate is the vehicle's license plate to check
  * @param {boolean} forceCreation is a boolean to force the creation of the vehicle
  * @returns {Observable<Object>} returns the result of vehicle's validation and creation if it's necessary
  * @description Checks the vehicle using backend service
  */
  checkValidationVehicle(licensePlate: string, forceCreation: boolean): Observable<Object> {
    let params = new HttpParams();
    params = params.append("licensePlate", licensePlate);
    params = params.append("forceCreation", forceCreation.toString());
    return this.http.post<Object>(
      environment.urlServerTeclogi +
      this.endpointResources.checkValidationVehicle,
      {},
      { params }
    );
  }

  /**
  * @param {User} user is the user to check (can be driver, admin or owner)
  * @param {Vehicle} vehicle is the vehicle to check
  * @returns {string} returns an error from user or vehicle
  * @description analizes the user and vehicle and returns an error message if there is some error.
  */
  getMessageStateDriver(user: User, vehicle?: Vehicle): string {
    let message = "";
    if (user && (!user.state || (user.state && !user.state.active))) {
      if (
        (user && user.role === Roles.DRIVER && user.state && user.state.description === "Pending" &&
          vehicle && vehicle.state && vehicle.state.description === "Pending") ||
        (user && user.role && user.state && user.state.description === "Pending")
      ) {
        message =
          "Aún no termina el estudio de seguridad, espera unos minutos. ";
      } else {
        if (
          (user.role === Roles.DRIVER &&
            user.arl &&
            user.arl.active &&
            user.eps &&
            user.eps.active &&
            user.truora &&
            this.utils.isDefined(user.truora.scoreTruora) &&
            user.truora.scoreTruora > 0.7) ||
          ((this.authService.getCompany().companyId !== environment.rootNit && !!(vehicle && vehicle.administrator) && user.role === Roles.ADMIN) ||
            (this.authService.getCompany().companyId !== environment.rootNit && !!(vehicle && !vehicle.administrator) && user.role === Roles.OWNER))
        ) {
          if (
            this.utils.isDefined(user.truora.reason) &&
            (user.truora.reason ==
              UserState.TRUORA_WRONG_DRIVER_DATA ||
              user.truora.reason ==
              UserState.WRONG_DRIVER_DATA)
          ) {
            message = UserState.TRUORA_WRONG_DRIVER_DATA;
          } else {
            message = "Se requiere activar al conductor";
          }
        } else {
          if (
            !user.truora ||
            (user.truora &&
              this.utils.isDefined(user.truora.reason) &&
              (user.truora.reason ==
                UserState.TRUORA_WRONG_DRIVER_DATA ||
                user.truora.reason ==
                UserState.WRONG_DRIVER_DATA))
          ) {
            message += UserState.TRUORA_WRONG_DRIVER_DATA;
          } else {
            message = "Se requiere validar el estado de ";
            if (!user.arl || (user.arl && !user.arl.active)) {
              message += "la ARL";
            } else if (!user.eps || (user.eps && !user.eps.active)) {
              message += "la EPS";
            } else {
              message += "el puntaje de seguridad";
            }
          }
        }
      }
    }
    else if (vehicle) {
      if (!vehicle.state || (vehicle.state && !vehicle.state.active)) {
        message = "Se requiere validar el vehículo";
      }
      if (!vehicle.validRTM || (vehicle.validRTM && !vehicle.validRTM.active)) {
        message += "Se requiere validar la RTM del vehículo";
      }
      else if (
        !vehicle.validSOAT ||
        (vehicle.validSOAT && !vehicle.validSOAT.active)
      ) {
        message += "Se requiere validar el SOAT del vehículo";
      } else if (vehicle.state &&
        !vehicle.state.active &&
        !vehicle.repowered &&
        vehicle.state.description ==
        "Este vehículo tiene mas de 25 años y para circular debe ser autorizado") {
        message = "Este vehículo tiene mas de 25 años, para circular debe ser autorizado";
      } else if (!vehicle.state || (vehicle.state && !vehicle.state.active)) {
        message = "Se requiere validar el vehículo";
      }
    } else {
      message = "El conductor no ha sido validado correctamente";
    }
    return message;
  }

  /**
  * @param {User | UserModel} driver is the driver to disable
  * @param {Vehicle} vehicle is the vehicle of the driver to check
  * @description Checks the driver and vehicle validity and shows a modal with the error
  */
  confirmDriverInactive(driver, vehicle?: Vehicle): void {
    const message = this.getMessageStateDriver(driver, vehicle);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: message,
      hideBtnConfirm: true,
      iconError: true,
    };
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    this.dialog.open(DialogComponent, dialogConfig);
  }

  /**
  * @param {Object} data is the file to upload
  * @param {string} document is the name of the document to upload
  * @returns {Observable<Object>} returns the result of uploading document process
  * @description Uploads the document by params using backend service
  */
  userUploadDocument(data: Object, document: string): Observable<Object> {
    let params = new HttpParams();
    params = params.append("document", document);
    return this.http.put(
      `${environment.urlServerTeclogi}${this.endpointResources.userUploadDocument}`,
      data,
      { params }
    );
  }

  /**
  * @param {'ARL'|'EPS'} type is the document of the user
  * @param {'string'} approvalBy is the name of the user who approves
  * @param {string} userDoc is the document of the user to use
  * @returns {Observable<Object>} returns the result of the document approval process
  * @description approves a user's document using backend service
  */
  userActivateAffiliation(type: 'ARL' | 'EPS' | AdditionalCertificationEnum, approvalBy: string, userDoc: string): Observable<Object> {
    let params = new HttpParams();
    params = params.append("type", type);
    params = params.append("approvalBy", approvalBy);
    params = params.append("document", userDoc);
    const url = `${environment.urlServerTeclogi}${this.endpointResources.userActivateAffiliation
      }?${params.toString()}`;
    return this.http.put(url, null);
  }

  /**
  * @param {'ARL'|'EPS'} type is the user's document to obtain
  * @param {User} user is the user to get extra documents
  * @returns {ExtraDocument | null} returns the user's document if exists, null in other case
  * @description Gets the user's document if exists
  */
  getExtraDocumentByType(type: 'ARL' | 'EPS' | AdditionalCertificationEnum, user: User): ExtraDocument | null {
    if (user && user.extraDocuments) {
      const document = user.extraDocuments.filter((document) => {
        return (document.type === type || document.type === type.toLowerCase()) && document.path && (!this.getIsActive(type, user) || this.isExpiredDoc(user, type));
      });
      if (document.length) return document[0];
    }
    return null;
  }

  getIsActive(type: 'ARL' | 'EPS' | AdditionalCertificationEnum, driver: User): boolean {
    if(type === 'ARL' || type === 'EPS'){
      return driver && driver[type.toLowerCase()] && driver[type.toLowerCase()].active;
    } else {
      return driver && driver.extraDocuments && driver.extraDocuments.find(document => document.type === type) && driver.extraDocuments.find(document => document.type === type).active;
    }
  }

  /**
  * @param {'AdditionalCertificationEnum} type is the user's document to obtain
  * @param {AdditionalCertification[]} list is the list of user's additional certifications
  * @returns {AdditionalCertification | null} returns the user's additional certification if exists, null in other case
  * @description Gets the user's additional certification if exists
  */
  getAdditionalCertificationByType(type: AdditionalCertificationEnum, list: AdditionalCertification[]): AdditionalCertification | null {
    if (!list || !list.length) return null;
    return list.find(cert => cert && cert.id === type);
  }

  /**
  * @param {User} user is the user to validate
  * @param {boolean} forceCreation indicates if is required to create the user to validate
  * @returns {Observable<Object>} returns the result of validation process
  * @description Validates a user and creates him if it's required using backend service
  */
  validateUser(user: User, forceCreation: boolean): Observable<any> {
    let params = new HttpParams();
    params = params.append("forceCreation", forceCreation.toString());
    return this.http.post(
      `${environment.urlServerTeclogi}${this.endpointResources.validateUser}`,
      user,
      { params }
    );
  }

  /**
  * @param {User} user is the user to get the state
  * @returns {string} returns the user's state transformed if exists, pending in other case.
  * @description Gets and transforms the user's state to be shown.
  */
  getUserState(user: User): string {
    if (
      !this.utils.isEmpty(user) &&
      !this.utils.isEmpty(user.state) &&
      !this.utils.isEmpty(user.state.active)
    ) {
      return this.stateUserPipe.transform(user.state.description);
    }
    if (!this.utils.isDefined(user.state) || !this.utils.isDefined(user.state.active) || !user.state.active) return '';
    return this.stateUserPipe.transform(UserState.PENDING);
  }

  /**
  * @param {Vehicle} vehicle is the vehicle to get the state
  * @returns {string} returns the vehicle's state transformed if exists
  * @description Gets and transforms the vehicle's state to be shown.
  */
  getVehicleState(vehicle: Vehicle): string {
    if (
      !this.utils.isEmpty(vehicle.state) &&
      !this.utils.isEmpty(vehicle.state.active)
    ) {
      if (
        vehicle.state.description != "Active" &&
        vehicle.state.description != "Pending"
      ) {
        return "Inactivo";
      } else if (vehicle.state.description == "Pending") {
        return "Estudio de seguridad en proceso";
      } else {
        return "Activo";
      }
    }
    return "Inactivo";
  }

  public isExpiredDoc(user: User, type: ('ARL' | 'EPS' | AdditionalCertificationEnum)): boolean {
    let matchDoc = user.extraDocuments.find(document => document.type === type || document.type.toLowerCase() === type.toLowerCase());
    if (!matchDoc || !matchDoc.dueDate || !matchDoc.approvalBy) return true;
    return DateManager.isBefore(DateManager.stringToDate(matchDoc.dueDate), new Date());
  }

  public isExpiredAdditionalCertification(certification: AdditionalCertification): boolean {
    if (!certification || !certification.dueDate) return true;
    return DateManager.isBefore(DateManager.stringToDate(certification.dueDate), new Date());
  }

  /**
 * @param {'ARL'|'EPS'} type is the type of the document
 * @returns {boolean} returns true if the userInfoSupport has the document required
 * @description Indicates if the userInfoSupport has the document required
 */
  getDocumentSupport(type: 'ARL' | 'EPS' | AdditionalCertificationEnum, documents: ExtraDocument[]): boolean {
    if (!documents || documents.length === 0) return false;
    return documents.some(support => {
      const supportType = String(support.type);
      const typeLowerCase = String(type).toLowerCase();
      return (supportType === type || supportType === typeLowerCase) && !!support.path;
    });
  }

  /**
  * @param {User} driver is the user to get EPS state
  * @returns {string} returns the user's EPS state transformed.
  * @description Gets and transforms the user's EPS state to be shown.
  */
  getepsState(driver: User, typesRules?: string[]): string {
    if (driver) {
      if (typesRules && !typesRules.includes('eps')) return 'Opcional';
      if (this.getTruoraInProgress(driver)) return "Estudio de seguridad en proceso";
      if (this.utils.getNestedValue(driver, 'eps.active') && !this.utils.getNestedValue(driver, 'eps.dueDate')) return "Activo";
      else if (driver.extraDocuments && driver.extraDocuments.length > 0) {
        const eps = driver.extraDocuments.find((doc) => doc.type === 'EPS' || doc.type.toLowerCase() === 'eps');
        if (eps) {
          if (!eps.approvalBy && eps.path) return "Pendiente"
          if (eps.dueDate && !this.isExpiredDoc(driver, 'EPS')) return "Activo";
        }
      }
    }
    return 'Inactivo'
  }

/**
* @param {User} driver is the user to get ARL state
* @returns {string} returns the user's ARL state transformed.
* @description Gets and transforms the user's ARL state to be shown.
*/
  getarlState(driver: User, typesRules?: string[]): string {
    if (driver) {
      if (typesRules && !typesRules.includes('arl')) return 'Opcional';
      if (this.getTruoraInProgress(driver)) return "Estudio de seguridad en proceso";
      if (this.utils.getNestedValue(driver, 'arl.active') && !this.utils.getNestedValue(driver, 'arl.dueDate')) return "Activo";
      else if (driver.extraDocuments && driver.extraDocuments.length > 0) {
        const arl = driver.extraDocuments.find((doc) => doc.type === 'ARL' || doc.type.toLowerCase() === 'arl');
        if (arl) {
          if (!arl.approvalBy && arl.path) return "Pendiente"
          if (arl.dueDate && !this.isExpiredDoc(driver, 'ARL')) return "Activo";
        }
      }
    }
    return 'Inactivo'
  }

  getAdditionalCertificationState(driver: User, additionalCertification: AdditionalCertificationEnum, typesRules?: string[]): string {
    if (driver) {
      if (typesRules && !typesRules.includes(additionalCertification)) return 'Opcional';
      if (this.getTruoraInProgress(driver)) return "Estudio de seguridad en proceso";
      if (driver.extraDocuments && driver.extraDocuments.length > 0) {
        const operationalAccreditation = driver.extraDocuments.find((doc) => doc.type === additionalCertification);
        if (!operationalAccreditation) return 'Inactivo';
        if (!operationalAccreditation.approvalBy && operationalAccreditation.path) return 'Pendiente';
        if (operationalAccreditation.path && operationalAccreditation.active && operationalAccreditation.approvalBy && !this.isExpiredDoc(driver, additionalCertification)) return 'Activo';
        return 'Inactivo';
      }
    }
    return 'Inactivo';
  }


  getTruoraInProgress(driver: User): boolean {
    if (driver) {
      const userStateDescription = this.utils.getNestedValue(driver, 'state.description') ? driver.state.description : '';
      return !!(!this.utils.isDefined(driver) || (this.utils.isDefined(driver) && userStateDescription === UserState.PENDING));
    } return false;
  }

  /**
  * @param {string} document is the user to update the documents
  * @param {UserDocuments} body are the user documents to be sent
  * @returns {Observable<Object>} returns the result of updating user's documents
  * @description Updates the user's documents using backend service.
  */
  public updateUserDocuments(document: string, body: UserDocuments): Observable<Object> {
    let params = new HttpParams();
    params = params.append("document", document);
    return this.http.post(
      environment.urlServerTeclogi + this.endpointResources.updateDocumentsUser,
      body,
      { params }
    );
  }

  /**
  * @param {string} document is the user's document
  * @param body is an object with the current user's contacts
  * @returns {Observable<Object>} returns the result of updating user's contacts
  * @description Updates the user's contact using backend service.
  */
  public updateUserContacts(
    document: string,
    body: {
      emergencyContact: ContactUser,
      referencePersonal: ContactUser,
      referenceLaboral: ContactUser
    }): Observable<Object> {
    let params = new HttpParams();
    params = params.append("document", document);
    return this.http.post(
      environment.urlServerTeclogi + this.endpointResources.updateContactsUser,
      body,
      { params }
    );
  }

  public getVehicleTypes() {
    return this.http.get<{ catalog: CatalogItem[] }>(`${environment.urlServerTeclogi}${this.endpointResources.vehicleTypeCatalog}`);
  }
  /**
  * @param {string} document is the user's document
  * @returns {Observable<AdditionalCertification[]>} returns the list of user's additional certifications
  * @description Gets the list of user's additional certifications
  */
  public getUserAdditionalCertifications(document: string): Observable<AdditionalCertification[]> {
    return this.http.get<AdditionalCertification[]>(
      `${environment.urlServerTeclogi}user/${document}/${this.endpointResources.getUserAdditionalCertifications}`
    );
  }

  /**
  * @param {string} document is the user's document
  * @param {AdditionalCertification[]} certifications are the user's certifications
  * @returns {Observable<Object>} returns the updated list of user's additional certifications
  * @description Updates the list of user's additional certifications
  */
  public updateUserAdditionalCertifications(document: string, certification: AdditionalCertification): Observable<Object> {
    return this.http.post<Object>(
      `${environment.urlServerTeclogi}user/${document}/${this.endpointResources.getUserAdditionalCertifications}`,
      certification
    );
  }


  /**
  * @returns {boolean} Returns true if the user has role Driver
  * @description Checks if the user has role Driver
  */
  getIsDriver(user: User): boolean {
    return user.role === Roles.DRIVER;
  }
  /**
  * @returns {boolean} Returns true if the  has role Owner
  * @description Checks if the user has role Owner
  */
  getIsOwner(user: User): boolean {
    return user.role === Roles.OWNER;
  }
  /**
  * @returns {boolean} Returns true if the user has role Admin
  * @description Checks if the user has role Admin
  */
  getIsAdmin(user: User): boolean {
    return user.role === Roles.ADMIN;
  }

  
  checkDuplicatedLicenses(form: FormGroup): boolean {
    let categories = (form.get('driverLicenseCategory') as FormArray).getRawValue().map((license: DriverLicenseCategory) => license.category.toUpperCase());
    const hasDuplicates = new Set(categories).size !== categories.length;
    return hasDuplicates;
  }

  /**
  * @param {string} document is the user's document
  * @param {ExtraDocument[]} body are the user's documents
  * @returns {Observable<Object>} returns the result of updating user's documents
  * @description Updates the user's documents using backend service.
  */
  userUploadListDocuments(document: string, body: ExtraDocument[]): Observable<Object> {
    let url = `${environment.urlServerTeclogi}${Fmt.string(this.endpointResources.extraDocumentsList, document)}`
    return this.http.put(
      url,
      body
    );
  }

  saveBasicInformation(formToSend: User){
    let url = `${environment.urlServerTeclogi}${Fmt.string(this.endpointResources.saveBasicInformation, formToSend.information.document)}`
    return this.http.put(
      url,
      formToSend
    );
  }

  checkOnlyUser(document: string){
    let url = `${environment.urlServerTeclogi}${Fmt.string(this.endpointResources.checkOnlyUser, document)}`
    return this.http.post(url, null);
  }

}
