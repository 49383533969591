<mat-form-field appearance="outline">
  <mat-label>Tipo de GPS<span class="asterisk" *ngIf="utils.isRequiredField(gpsControl)">*</span></mat-label>
  <input type="text" #inputAutoComplete matInput [formControl]="gpsControl" [matAutocomplete]="auto">
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectGps()">
    <mat-option *ngFor="let type of listGeneralGpsType | async" [value]="type.name">
      {{type.name}}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="gpsControl?.errors?.required">
    {{ utils.giveMessageError('AT_LEAST_ONE_OPTION') }}
  </mat-error>
  <mat-error *ngIf="gpsControl?.errors?.pattern">
    {{ utils.giveMessageError('INCORRECT_FORMAT', 'tipo de gps') }}
  </mat-error>
  <mat-hint *ngIf="options?.hint && !inputFormControl?.value" class="color-secondary-dark text-bold">
    {{ options?.hint }}
  </mat-hint>
</mat-form-field>