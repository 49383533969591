<div class="evidences-container">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <div *ngIf="cargo" class="evidences">
    <div class="col-md-12">
      <div class="text-strong mr-5">
        Evidencias
      </div>
      <div class="row-popup">
        <div class="row-popupInt">
          <div class="row">
            <!-- Muestra las imagenes guardadas evidencias  -->
            <div class="non-evidences-hint" *ngIf="evidencesLoaded && !address?.imagesDocument?.length">
              Aún no se han subido evidencias
            </div>
            <div class="popup-photos">
              <div class="photo" *ngFor="let image of address.imagesDocument; let i = index;">
                <i class="fa fa-times-circle icon-close" (click)="confirmDelete('document', image)"
                  *ngIf="enableEvidenceActions()"></i>
                <i class="fas fa-info-circle" permission-display [module]="permission.cargo.module"
                  [functionality]="permission.cargo.evidencesFingerprint" *ngIf="image.fingerprint"
                  [matTooltip]="getFingerprintToolTip(image.fingerprint)" matTooltipClass="custom-tooltip"></i>
                <img #i [src]="image.url" alt="" (click)="openImageNewTab(image.url)"
                  (error)="onErrorRenderImage($event)">
              </div>
            </div>
            <!-- Tap para gregar nuevas evidencias  -->
            <div class="popup-photos">
              <figure class="photo" *ngFor="let imageInstace of instancesFilesStorageDocument; let i=index;">
                <img #i [src]="imageInstace.fileData.url" alt="">
              </figure>
              <label class="photo photo-add" matTooltip="Agregar evidencia" *ngIf="enableEvidenceActions()">
                <input type="file" #documentFileInput class="display-none"
                  (change)="handleFileInput($event, 'document')" accept="application/pdf,image/png,image/jpeg" multiple>
                <div class="icon-plus">
                  <i class="fas fa-plus "></i>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="text-strong" *ngIf="!!address?.imagesTruck?.length">
        {{ typeAddress === 'Origin' ? 'Evidencia del camión cargado' : 'Evidencia del camión descargado'}}
      </div>
      <div class="row-popup" *ngIf="!!address?.imagesTruck?.length">
        <div class="row-popupInt">
          <div class="row">
            <!-- Muestra las imagenes guardadas evidencias del camion  -->
            <div class="popup-photos">
              <div class="photo" *ngFor="let image of address.imagesTruck; let i = index;">
                <i class="fa fa-times-circle icon-close" (click)="confirmDelete('truck', image)"
                  *ngIf="enableEvidenceActions()"></i>
                <i class="fas fa-info-circle" permission-display [module]="permission.cargo.module"
                  [functionality]="permission.cargo.evidencesFingerprint" *ngIf="image.fingerprint"
                  [matTooltip]="getFingerprintToolTip(image.fingerprint)" matTooltipClass="custom-tooltip"></i>
                <img #i [src]="image.url" alt="" (click)="openImageNewTab(image.url)"
                  (error)="onErrorRenderImage($event)">
              </div>
            </div>
            <!-- Tap para gregar nuevas evidencias camion  -->
            <div class="popup-photos">
              <figure class="photo" *ngFor="let imageInstace of instancesFilesStorageTruck; let i=index;">
                <img #i [src]="imageInstace.fileData.url" alt="">
              </figure>
              <label class="photo photo-add" matTooltip="Agregar evidencia" *ngIf="enableEvidenceActions()">
                <input type="file" #truckFileInput class="display-none" (change)="handleFileInput($event, 'truck')"
                  accept="application/pdf,image/png,image/jpeg" multiple>
                <div class="icon-plus">
                  <i class="fas fa-plus "></i>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row-popup">
        <mat-card-actions class="text-center" *ngIf="showSaveButton">
          <button mat-raised-button permission-display color="primary" type="button" class="button-width-auto"
            (click)="addEvidenciesToStorage()">
            Guardar
          </button>
        </mat-card-actions>
        <mat-card-actions class="text-center"
          *ngIf="!hasImages && showContinueWithoutPhotos && allowToEndService && !removeEvidence && paramsDialog.showBtnWithoutEvidence">
          <button mat-raised-button color="primary" type="button" class="button-width-auto"
            (click)="goSetStateAddress()">
            Continuar sin evidencias
          </button>
        </mat-card-actions>
      </div>
    </div>
  </div>

  <div class="evidences" *ngIf="guide && listImageGuies.length">
    <span class="separator-horizontal"></span>
    <div class="row-popup popup-guides">
      <div class="col-md-12">
        <div class="text-strong">
          Evidencia De Entrega
        </div>
        <div class="popup-photos">
          <figure class="photo" *ngFor="let url of listImageGuies; let i=index;" (click)="openImageNewTab(url)">
            <img #i [src]="url" alt="">
          </figure>
        </div>
      </div>
    </div>
  </div>
</div>