import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Utils } from 'src/app/core/resources/utils';
import { Global } from 'src/app/core/resources/global';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { RolesService } from '../roles.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { Permission, PermissionJLRB } from 'src/app/core/resources/permission';
import { MAT_DIALOG_DATA, MatCheckboxChange } from '@angular/material';
import { Role, RoleModel } from 'src/app/core/interfaces/role';
import { ModulesService } from '../../module/modules.service';
import { ModuleRole } from 'src/app/core/interfaces/moduleRole';
import { FunctionalitiesService } from '../../Functionalities/functionalities.service';
import { FunctionalitiesModuleRole } from 'src/app/core/interfaces/functionalitiesModuleRole';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Companies } from 'src/app/core/resources/companies';
import { NotificationCatalog } from 'src/app/core/interfaces/notification-catalog';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
@Component({
  selector: 'app-form-role',
  templateUrl: './form-role.component.html',
  styleUrls: ['./form-role.component.scss'],
  providers: [AuthService, RoleModel, ModulesService, FunctionalitiesService]
})
export class FormRoleComponent implements OnInit {

  title: string;
  companySelected: FormControl = new FormControl();
  mode: string;
  roleData: Role;
  form: FormGroup = new FormGroup({});
  reactiveForm: ReactiveForm;
  model: any;
  listModules: ModuleRole[];
  optionsCompany = {
    title: 'Compañía'
  };
  isJLRBCompany: boolean = this.authService.getUserSession().clientCompanyId === '9007387182';
  permission = this.isJLRBCompany ? PermissionJLRB : Permission;
  notificationsCatalog: Array<NotificationCatalog> = [];
  formNotifications = new FormGroup({});
  notificationFormLoaded = false;

  constructor(
    private authService: AuthService,
    private rolesService: RolesService,
    public utils: Utils,
    private global: Global,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private router: Router,
    private route: ActivatedRoute,
    public formBuilder: FormBuilder,
    public roleModel: RoleModel,
    private modulesService: ModulesService,
    private functionalitiesService: FunctionalitiesService,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
    private permissionRole: PermissionRole
  ) {

  }

  ngOnInit() {
    this.getListModules();
    if (this.route.routeConfig === null || this.route.routeConfig.path === 'create') {
      this.mode = 'create';
      this.title = 'Crear rol';
    } else {
      this.mode = 'detail';
    }
  }

  private normalize(role: Role) {
    const _modules = {};
    // Creación del template con todos los posibles permisos. Accesible por nombre de permiso.
    for (const m in this.permission) {
      const module = this.permission[m];
      const template = {
        name: module['module'],
        functionalities: {}
      }
      for (const p in module) {
        if (p !== 'module') {
          const permission = {
            name: module[p],
            visible: false
          };
          template.functionalities[module[p]] = (permission);
        }
      }
      _modules[template.name] = template;
    }
    // Asignación de permisos actuales a modules.
    for (const m of role.modules) {
      for (const p of m.functionalities) {
        p.name;
        p.visible;
        if (_modules[m.name] && _modules[m.name].functionalities[p.name]) _modules[m.name].functionalities[p.name] = p;
      }
    }

    const normalizedRole: Role = role;

    normalizedRole.modules = [];
    for (const m in _modules) {
      const module = {
        name: m,
        functionalities: []
      }

      const functionalities = _modules[m].functionalities;
      for (const f in functionalities) {
        module.functionalities.push(functionalities[f]);
      }

      normalizedRole.modules.push(module);
    }
    return normalizedRole;
  }

  createForm() {
    this.model = this.roleModel.model;
    this.model.modules = this.listModules;
    this.reactiveForm = new ReactiveForm(
      this.formBuilder,
      this.model
    );
    this.model = this.normalize(this.model);
    this.form = this.reactiveForm.createFormGroup(this.model);
    this.form.get('name').setValidators(Validators.required);
    const params = this.route.snapshot.params;
    if (!this.utils.objIsEmpty(params)) {
      if (this.utils.isDefined(params.id)) {
        this.setDetailRole(params['id']);
      }
    }
    if ((this.mode == 'detail' && !this.permissionRole.hasPermission(this.permission.administration.module, this.permission.administration.updateRoles) ||
      (this.mode == 'create' && !this.permissionRole.hasPermission(this.permission.administration.module, this.permission.administration.createRoles)))) {
      this.form.disable();
    }
  }

  private LoadNotifications() {
    if (this.canEditNotifications)
      this.rolesService
        .getNotifications()
        .subscribe((notificationsCatalog: Array<NotificationCatalog>) => {
          this.notificationsCatalog = notificationsCatalog;
          this.notificationsCatalog.forEach(
            (notificationCatalog: NotificationCatalog) => {
              const active = !!this.roleData &&
                this.roleData.notificationTypes &&
                this.roleData.notificationTypes.includes(notificationCatalog.id);
              this.formNotifications.addControl(
                notificationCatalog.id,
                new FormControl(active)
              );
            }
          );
          this.notificationFormLoaded = true;
        });
  }

  getDetailRole(id: string, cb?: CallableFunction) {
    this.rolesService.getRol(id).subscribe(
      (success: Role) => {
        this.roleData = this.normalize(success);
        this.title = this.roleData.name;
        this.form.patchValue(this.roleData);
        if (cb) {
          cb();
        }
      },
      (error) => {

      }
    );
  }

  setDetailRole(id: string) {
    if (this.rolesService.roleSelected) {
      this.roleData = this.normalize(this.rolesService.roleSelected);
      this.title = this.roleData.name;
      this.form.patchValue(this.roleData);
      this.LoadNotifications();
    } else if (id) {
      this.getDetailRole(id, () => this.LoadNotifications());
    } else {
      this.goToList();
    }
  }

  onSubmit() {
    if (this.mode === 'create') {
      if (this.companySelected.value && this.companySelected.value.companyId) {
        this.setCompanyValueForm();
      }

      if (!this.form.value.nit) {
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.MISSING_FIELD, 'compañía asociada'), undefined, 'alert');
      } else if (!this.form.value.name) {
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.MISSING_FIELD, 'nombre del rol'), undefined, 'alert');
      } else if (!this.form.value.modules.some(module => module.functionalities.some(func => func.visible))) {
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.UNSELECTED_FIELD, 'ninguna funcionalidad en este rol'), undefined, 'alert');
      } else {
        this.createRole();
      }
    } else if (this.mode === 'detail') {
      this.updateRole();
    }
  }

  createRole() {
    this.spinner.show();
    this.rolesService.createRole(this.form.value).subscribe(
      (success: any) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Rol creado correctamente');
        this.goToList();
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(error.message || 'Ocurrió un error al crear el rol', 'error');
      }
    );
  }

  updateRole() {
    this.spinner.show();
    this.rolesService.updateRole(this.form.value).subscribe(
      (success: any) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Rol actualizado correctamente');
        this.goToList();
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(error.message || 'Ocurrió un error al crear el rol', 'error');
      }
    );
  }

  setCompanyValueForm() {
    this.form.get('nit').setValue(this.companySelected.value.companyId);
  }

  goToList() {
    this.router.navigate(['administration/roles/list']);
  }

  goToDetail(id: string) {
    this.router.navigate(['administration/roles/detail', id]);
  }

  get formControlModules(): FormArray {
    return this.form.get('modules') as FormArray;
  }

  getFormControlFunctionalities(indexModule: number): FormArray {
    return (this.form.get('modules') as FormArray).at(indexModule).get('functionalities') as FormArray;
  }

  getListModules() {
    this.modulesService.getAllModules().toPromise()
      .then((success: ModuleRole[]) => {
        this.listModules = success;
        this.getListFunctionalities();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  getListFunctionalities() {
    this.spinner.show();
    this.functionalitiesService.getFunctionalitiesByIdModule().toPromise()
      .then((success: FunctionalitiesModuleRole[]) => {
        const functGroypByModule = this.utils.arrayGroupBy(success, 'moduleId');
        this.listModules = this.listModules.map((module) => {
          module.functionalities = functGroypByModule[module.id];
          return module;
        });
        this.removeIdsModules();
        this.createForm();
      })
      .catch((error) => {
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  removeIdsModules() {
    this.listModules = this.listModules.map((module) => {
      delete module.id;
      module.functionalities = module.functionalities.map((functionality) => {
        delete functionality.id;
        delete functionality.moduleId;
        functionality.visible = false;
        return functionality;
      });
      return module;
    });
  }

  get canEditPermissions() {
    if (this.mode == 'detail')
      return this.permissionRole.hasPermission(this.permission.administration.module, this.permission.administration.updateRoles);
    return this.permissionRole.hasPermission(this.permission.administration.module, this.permission.administration.createRoles);
  }

  get canEditNotifications() {
    return this.permissionRole.hasPermission(
      this.permission.administration.module,
      this.permission.administration.changeRolNotifications
    );
  }

  canEditModule(module: string): boolean {
    return Companies.modulesAccess(module, this.authService.getUserSession().clientCompanyId, sessionStorage.getItem('_companyId'));
  }

  canEditPermission(permission: string): boolean {
    return Companies.permissionAccess(permission, this.authService.getUserSession().clientCompanyId, sessionStorage.getItem('_companyId'));
  }

  copy(text: string) {
    navigator.clipboard
      .writeText(text)
      .then(
        () => this.snackBarService.openSnackBar('¡ID copiado al portapapeles!')
      );
  }

  updateNotification() {
    const notifications = this.formNotifications.value;

    for (const id in notifications) {
      const notification = notifications[id];
      if (notification &&
        (
          !Array.isArray(this.roleData.notificationTypes) ||
          !this.roleData.notificationTypes.includes(id)
        )
      ) {
        this.rolesService
          .addNotificationToRole(id, this.roleData.id)
          .subscribe({
            next: (role: Role) => {
              this.roleData = role;
              this.spinner.hide();
            },
            error: () => {
              this.spinner.hide();
              this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, 'x', 'error');
              this.formNotifications.get(id).setValue(false);
            }
          });
      } else if (
        (
          Array.isArray(this.roleData.notificationTypes) &&
          this.roleData.notificationTypes.includes(id)
        )
      ) {
        this.rolesService
          .removeNotificationToRole(id, this.roleData.id)
          .subscribe({
            next: (role: Role) => {
              this.roleData = role;
              this.spinner.hide();
            },
            error: () => {
              this.spinner.hide();
              this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, 'x', 'error');
              this.formNotifications.get(id).setValue(true);
            }
          });
      }
    }
  }

  everyPermissionIsActive(index: number): boolean {
    if (!this.getFormControlFunctionalities(index) || !this.getFormControlFunctionalities(index).controls) return false
    return this.getFormControlFunctionalities(index).controls.every(permission => {
      return !this.canEditPermission(permission.value.name) ||
        (permission && permission.get('visible') && permission.get('visible').value)
    });
  }

  onSelectAll($event: MatCheckboxChange, index: number) {
    if ($event && this.utils.isDefined($event.checked)) {
      this.getFormControlFunctionalities(index).controls.forEach(permission => {
        if (this.canEditPermission(permission.value.name))
          permission.get('visible').setValue(!!$event.checked);
      });
    }
  }
}

