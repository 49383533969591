import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { MatSelectChange } from '@angular/material';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { Utils } from 'src/app/core/resources/utils';
import { CatalogItem } from 'src/app/core/interfaces/catalogItem';
import { Tag } from 'src/app/core/interfaces/tag';

@Component({
  selector: 'app-type-trip',
  templateUrl: './type-trip.component.html',
  styleUrls: ['./type-trip.component.scss'],
  providers: [AuthService, Global]
})
export class TypeTripComponent implements OnInit {

  tagControl = new FormControl([]);
  tags: Tag[] = [];
  fileLetterRetirement = {
    file: null,
    name: '',
    uploaded: false,
    size: null,
    url: null
  };
  productType: string = "No";
  storageRef: any;
  uploadTask: any;
  uploadProgress: any;
  riskProfileName = this.global.riskProfiles.map(profile => profile.name);
  public types = [
    {
      type: "1",
      name: "Importación",
      image: "/assets/svg/icons/icon-import.svg"
    },
    {
      type: "2",
      name: "Exportación",
      image: "/assets/svg/icons/icon-export.svg"
    },
    {
      type: "3",
      name: "Nacional",
      image: "/assets/svg/icons/icon-national.svg"
    },
    {
      type: "4",
      name: "Urbana",
      image: "/assets/svg/icons/icon-urban.svg",
      tip: "Viajes dentro de la misma ciudad que no corresponden a paqueteo"
    },
    {
      type: "5",
      name: "Última milla",
      image: "/assets/svg/icons/lastmile_icon.svg",
      tip: "Aquellas que corresponden a viajes de paqueteo"
    }
  ]

  constructor(
    public manualCreationCargoService: ManualCreationCargoService,
    private angularFireStorage: AngularFireStorage,
    public snackBarService: SnackBarService,
    private router: Router,
    public utils: Utils,
    public global: Global
  ) {

  }

  ngOnInit() {
    this.setValidators();
    this.getTags();
  }

  private getTags() {
    //Aquí se usaría un servicio para obtener las tags
    this.manualCreationCargoService.getLabelTags().subscribe(
      (success) => {
        if (success && success.length) this.tags = success;
      },
      (error) => {
      }
    )
  }

  public selectTag($event) {
    this.tagControl.setValue('');
    const tag: string = $event.value;
    if (!this.manualCreationCargoService.cargoForm.get('labels').value.includes(tag))
      (this.manualCreationCargoService.cargoForm.get('labels') as FormArray).push(new FormControl(tag));
  }

  public clearTag(index: number) {
    (this.manualCreationCargoService.cargoForm.get('labels') as FormArray).removeAt(index);
  }


  onSelectRiskProfile($event: MatSelectChange) {
    let riskProfile = this.global.riskProfiles.find(riskProfile => riskProfile.name === $event.value);
    if (riskProfile) this.manualCreationCargoService.formRiskProfile.setValue(riskProfile);
  }

  setValidators() {
    this.manualCreationCargoService.formRiskProfile.get('name').setValidators(Validators.required);
  }

  verifyProductTypeValue() {
    if (this.manualCreationCargoService.cargoForm.value.cargoFeature.productType.type === 'FixedCargo') {
      this.productType = 'Si';
    } else {
      this.productType = 'No';
    }
  }

  get OriginDiferentDestination() {
    let isDiferent: boolean = false;
    this.manualCreationCargoService.cargoForm.value.cargoFeature.uploadDownload.destination.forEach(destino => {
      if (this.manualCreationCargoService.cargoForm.value.cargoFeature.uploadDownload.origin.name !== destino.name) {
        isDiferent = true;
      }
    });
    return isDiferent;
  }

  public selectTrip(event) {
    this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].tripType.controls.name.setValue(
      event.name
    );
    if (event.name !== 'Exportación') {
      this.setDataCargoByStep();
    }
  }

  setDataCargoByStep() {
    switch (this.manualCreationCargoService.cargoForm.value.cargoModel.tripType.name) {
      case 'Importación':
        // Carta de retiro
        this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].tripType.controls.letterRetirement.setValue(
          ''
        );
        this.manualCreationCargoService.fileLetterRetirement = {
          file: null,
          name: '',
          uploaded: false,
          size: null,
          url: null
        };
        break;
      case 'Exportación':
        break;
      case 'Nacional':
      case 'Internacional':
      case 'Urbana':
      case 'Última milla':
        // Tipo de regimen
        this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].tripType.controls.regimeType.setValue(
          ''
        );
        // Carta de retiro
        this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].tripType.controls.letterRetirement.setValue(
          ''
        );
        this.manualCreationCargoService.fileLetterRetirement = {
          file: null,
          name: '',
          uploaded: false,
          size: null,
          url: null
        };
        // Tipo de carga
        this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].cargoType.controls.name.setValue(
          ''
        );
        break;
    }
  }

  nextStep() {
    this.manualCreationCargoService.formRiskProfile.get('name').markAsTouched();
    if (this.manualCreationCargoService.isValidTripType(true)) {
      switch (this.manualCreationCargoService.cargoForm.value.cargoModel.tripType.name) {
        case 'Importación':
          // Tipo de Regimen
          this.manualCreationCargoService.getComponentActive().setId(1);
          this.manualCreationCargoService.getComponentActive().getStep().setId(2);
          this.router.navigate([this.manualCreationCargoService.steps.typeRegime.url]);
          break;
        case 'Exportación':
          // Carta retiro y después va a regimen
          this.manualCreationCargoService.getComponentActive().setId(1);
          this.manualCreationCargoService.getComponentActive().getStep().setId(2);
          this.router.navigate([this.manualCreationCargoService.steps.tripTypeDocuments.url]);
          break;
        case 'Nacional':
        case 'Urbana':
          // Tipo de servicio
          this.manualCreationCargoService.cargoForm.get('cargoModel.packagingType.description').setValue('');
          this.manualCreationCargoService.cargoForm.get('cargoModel.packagingType.code').setValue('');
          // this.manualCreationCargoService.cargoForm.value.cargoModel
          //   .packagingType.description.setValue('');
          this.manualCreationCargoService.getComponentActive().setId(1);
          this.manualCreationCargoService.getComponentActive().getStep().setId(3);
          this.router.navigate([this.manualCreationCargoService.steps.typeCargo.url])
          break;
        case 'Última milla':
          // Tipo de servicio
          this.manualCreationCargoService.cargoForm.get('cargoModel.packagingType.description').setValue('');
          this.manualCreationCargoService.cargoForm.get('cargoModel.packagingType.code').setValue('');
          this.manualCreationCargoService.getComponentActive().setId(1);
          this.manualCreationCargoService.getComponentActive().getStep().setId(5);
          this.router.navigate([this.manualCreationCargoService.steps.typeService.url]);
      }
    } else {
      if (!this.manualCreationCargoService.cargoForm.get('cargoModel.tripType.name').value) this.snackBarService.openSnackBar(Fmt.string(FormMessages.UNSELECTED_FIELD, 'el tipo de viaje'), undefined, 'alert');
      else if (this.utils.errorMessagesCustomized(this.manualCreationCargoService.formRiskProfile.get('name'), 'perfil de riesgo del servicio')) return;
      else this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
    }
  }

  onChangeFixedCargo($event?) {
    // let value: string = '';
    // this.productType = $event.value;
    // if ($event && $event.value && $event.value === 'Si') {
    //   value = 'FixedCargo';
    // } else {

    // value = '';
    // }
    // const value = $event.value === 'No' ? '' : $event.value;
    this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.type.setValue(
      $event.value
    );
  }

  stepBack() {
    this.manualCreationCargoService.getComponentActive().setId(0);
    this.manualCreationCargoService.getComponentActive().getStep().setId(2);
    this.router.navigate([this.manualCreationCargoService.steps.dateLoad.url], { state: { type: 2 } });
  }

}
