import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AdminUsersService } from '../../admin-users/admin-users.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { CatalogItem } from 'src/app/core/interfaces/catalogItem';
import { DateManager } from 'src/app/core/managers/date.manager';

@Component({
  selector: 'app-maintenance-filter',
  templateUrl: './maintenance-filter.component.html',
  styleUrls: ['./maintenance-filter.component.scss']
})
export class MaintenanceFilterComponent implements OnInit {
  // Outputs
  @Output() emitFilters: EventEmitter<{ type: string, filtersValues: string }> = new EventEmitter();
  // Properties
  id: FormControl = new FormControl('');
  type: FormControl = new FormControl('');
  maintenanceState: FormControl = new FormControl('');
  vehicleTypes: CatalogItem[] = [];
  statesMaintenance: CatalogItem[] = [
    { name: 'Preventivo vencido', id: 'Expired' },
    { name: 'Preventivo vigente', id: 'Active' }
  ]
  constructor(
    private adminService: AdminUsersService,
    private spinner: NgxSpinnerService,
    private snackbarService: SnackBarService
  ) { }

  ngOnInit() {
    this.getVehicleTypes();
    this.checkStorageFilters();
  }


  checkStorageFilters() {
    const filtersValues = sessionStorage.getItem('maintenanceFilters');
    if (filtersValues) {
      const filters = filtersValues.split('&');
      filters.forEach(filter => {
        const [key, value] = filter.split('=');
        if (key === 'id') this.id.setValue(value.trim());
        if (key === 'type') this.type.setValue(value);
        if (key === 'maintenanceState') this.maintenanceState.setValue(value);
      });
      this.applyFilters();
    }
  }


  getVehicleTypes() {
    const vehicleTypesObserver = {
      next: (data: { catalog: CatalogItem[] }) => {
        this.spinner.hide();
        if (data && data.catalog && data.catalog.length) this.vehicleTypes = data.catalog;
        else this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      },
      error: () => {
        this.spinner.hide();
        this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      },
    };
    this.spinner.show();
    this.adminService.getVehicleTypes().subscribe(vehicleTypesObserver);
  }

  cleanFilters() {
    this.id.setValue('');
    this.type.setValue('');
    this.maintenanceState.setValue('');
    sessionStorage.removeItem('maintenanceFilters');
    this.emitFilters.emit({ type: 'reset', filtersValues: '' })
  }

  applyFilters() {
    const filterControls = [
      { control: this.id, label: 'id' },
      { control: this.type, label: 'type' },
      { control: this.maintenanceState, label: 'maintenanceState' },
    ];
    const filtersValues = filterControls
      .filter(({ control }) => control.value !== null && control.value !== '')
      .map(({ control, label }) => {
        let value = control.value;
        if (label === 'maintenanceExecutionDate') value = DateManager.dateToString(value);
        if (label === 'id') value = value.toUpperCase().trim();
        return `&${label}=${value}`;
      })
      .join('');
    if (filtersValues.length > 0) {
      sessionStorage.setItem('maintenanceFilters', filtersValues);
      this.emitFilters.emit({ type: 'apply', filtersValues: filtersValues });
    }
    else {
      sessionStorage.removeItem('maintenanceFilters');
      this.emitFilters.emit({ type: 'reset', filtersValues: '' })
    }
  }

}
