<div class="position-relative py-3">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <ng-container [ngSwitch]="viewType">
        <div class="update__additional__cost__cargo col-md-12" *ngSwitchCase="'changeAdvance'">
            <!-- Button -->
            <div class="col-md-12">
                <div class="row">
                    <form [formGroup]="advancePercentage">
                        <div class="col-md-12">
                            <label class="title-component mr-3">
                                Modificar porcentaje de anticipo
                            </label>
                        </div>
                        <div *ngIf="cargo" class="text-center color-secondary-dark">
                            Porcentaje actual del servicio <b>{{cargo.shippingCost.advancePercentage}}%</b>
                            ({{cargo.shippingCost.valueAdvance | currency :'COP':'code'}})
                        </div>
                        <div class="col-md-12">
                            <mat-form-field appearance="standard">
                                <mat-label>Ingresa el nuevo porcentaje<span class="asterisk">*</span></mat-label>
                                <input type="text" matInput formControlName="percentage" onlyNumbersInt>
                                <i class="fas fa-info-circle fa-lg color-secondary-dark" matSuffix
                                    [matTooltip]="messageAdvancePercentageAllowed"></i>
                                <mat-error *ngIf="advancePercentage.get('percentage').errors?.required">
                                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                                </mat-error>
                                <mat-error *ngIf="advancePercentage.get('percentage').errors?.min">
                                    {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'nuevo porcentaje', 0) }}
                                </mat-error>
                                <mat-error *ngIf="advancePercentage.get('percentage').errors?.max">
                                    {{ utils.giveMessageError('MAX_VALUE_EXCEED', 'nuevo porcentaje',
                                    maxAdvancePercentage )}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <mat-card-actions>
                            <a (click)="closeDialog(false)"
                                class="font-secondary-dark secondary-btn"><b>Cancelar</b></a>
                            <button mat-raised-button class="button-width-auto" color="primary"
                                (click)="updateAdvancePercentage()">
                                Guardar
                            </button>
                        </mat-card-actions>
                    </form>
                </div>
            </div>
        </div>
        <div class="update__additional__cost__cargo col-md-12" *ngSwitchCase="'viewAdvance'">
            <!-- Button -->
            <div class="col-md-12">
                <div class="row justify-content-center">
                    <form [formGroup]="advancePercentage">
                        <div class="col-md-12">
                            <label class="title-component mr-3">
                                Confirmar Valor de Anticipo
                            </label>
                        </div>
                        <div *ngIf="cargo" class="text-center color-secondary-dark">
                            <h5>
                                {{cargo.shippingCost.advancePercentage}}%
                                ({{cargo.shippingCost.valueAdvance | currency :'COP':'code'}})
                            </h5>
                        </div>
                        <mat-card-actions>
                            <a (click)="viewType = 'changeAdvance'"
                                class="font-secondary-dark secondary-btn"><b>Modificar</b></a>
                            <button mat-raised-button color="primary" class="button-width-auto"
                                (click)="closeDialog(true)" mdbWavesEffect>Confirmar</button>
                        </mat-card-actions>
                    </form>
                </div>
            </div>
        </div>
    </ng-container>
</div>