import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef, MatSelectChange } from '@angular/material';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { ApprovalState, ApprovalStateEnum, ImageFingerprint, PreviewImagesMap, TravelExpense, TravelExpenseDetail, TravelExpensesImages } from 'src/app/core/interfaces/travel-expense';
import { LegalizeStatePipe } from 'src/app/core/pipe/legalize-state.pipe';
import { Dialog } from 'src/app/core/resources/dialog';
import { TravelExpensesService } from 'src/app/core/services/travel-expenses.service';
import { TravelExpensesDetailComponent } from '../travel-expenses-detail/travel-expenses-detail.component';
import { AuthService } from 'src/app/core/services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { Utils } from 'src/app/core/resources/utils';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';

@Component({
  selector: 'app-legalize-travel-expenses',
  templateUrl: './legalize-travel-expenses.component.html',
  styleUrls: ['./legalize-travel-expenses.component.scss']
})
export class LegalizeTravelExpensesComponent implements OnInit {

  showMoreState: Array<boolean> = [];
  travelExpenses: Array<TravelExpense> = [];
  states: string[] = ['Pending approval', 'Rejected', 'Approved'];
  travelExpenseForm: FormGroup;
  private pathNoImage: string = "/assets/svg/icons/icon-no-file.svg";
  public listPreviewImages: PreviewImagesMap = {};

  travelExpensesDetailValues: FormGroup = new FormGroup({});
  fieldEditionState: Array<boolean>[] = [];

  constructor(
    public ref: MatDialogRef<LegalizeTravelExpensesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      cargo: Cargo,
      travelExpenses: Array<TravelExpense>
    },
    private fb: FormBuilder,
    private dialog: Dialog,
    private dialogMat: MatDialog,
    private travelExpensesService: TravelExpensesService,
    private legalizeStatePipe: LegalizeStatePipe,
    private spinner: NgxSpinnerService,
    private snackbarService: SnackBarService,
    private permissionRole: PermissionRole,
    private utils: Utils
  ) {

  }

  ngOnInit() {
    this.travelExpenses = this.data.travelExpenses;
    this.initData();
  }

  get canChangeApprovedTravelExpenses() {
    return this.permissionRole.hasPermission(
      Permission.cargo.module,
      Permission.cargo.changeApprovedTravelExpenses
    );
  }

  get isClosed() {
    if (!Array.isArray(this.travelExpenses))
      return false;
    const anyOpen = this.travelExpenses.some(
      (travelExpense: TravelExpense) => travelExpense.approval !== ApprovalStateEnum.APPROVED
    );
    return !anyOpen;
  }

  private initData(): void {
    this.listPreviewImages = {};
    this.getUrlFirestorage();
    this.createForm();
    for (const i in this.travelExpenses) {
      this.showMoreState[i] = false;
      this.expenses.push(this.newExpense(this.travelExpenses[i]));
    }

    this.loadTravelExpensesDetailValues();
  }

  private createForm(): void {
    this.travelExpenseForm = this.fb.group({
      expenses: this.fb.array([])
    });
  }

  get expenses(): FormArray {
    return this.travelExpenseForm.get('expenses') as FormArray;
  }

  private newExpense(travelExpenses: TravelExpense): FormGroup {
    return this.fb.group({
      id: [travelExpenses.id, Validators.required],
      travelExpensesType: this.fb.group({
        id: [travelExpenses.travelExpensesType.id, Validators.required],
        name: [travelExpenses.travelExpensesType.name, Validators.required]
      }),
      totalPaid: [travelExpenses.totalPaid, [Validators.required, Validators.min(0)]],
      state: [travelExpenses.state, Validators.required],
      approval: [travelExpenses.approval, Validators.required],
    });
  }

  public closeDialog(): void {
    this.ref.close();
  }

  public calculateUnlegalized(travelExpense: TravelExpense): number {
    return travelExpense.approval === ApprovalStateEnum.PENDING ? travelExpense.totalSpentValue : 0;
  }

  public calculateRejected(travelExpense: TravelExpense): number {
    return travelExpense.approval === ApprovalStateEnum.REJECTED ? travelExpense.totalSpentValue : 0;
  }

  private filterDetailsByApprovalState(travelExpense: TravelExpense, approvalState: ApprovalState): TravelExpenseDetail[] {
    return travelExpense.travelExpensesDetail.filter(
      (travelExpenseDetail: TravelExpenseDetail) =>
        travelExpenseDetail.approval == approvalState
    )
  }

  public showMore(i: string): void {
    this.showMoreState[i] = !this.showMoreState[i];
  }

  public onChangeApprovalExpense(travelExpense: TravelExpense, $event: MatSelectChange): void {
    const hasPending = travelExpense && travelExpense.travelExpensesDetail.some(
      (travelExpenseDetail) => travelExpenseDetail && travelExpenseDetail.approval === ApprovalStateEnum.PENDING
    );

    const hasApproved = travelExpense && travelExpense.travelExpensesDetail.some(
      (travelExpenseDetail) => travelExpenseDetail && travelExpenseDetail.approval === ApprovalStateEnum.APPROVED
    );

    if ($event.value == ApprovalStateEnum.REJECTED && (hasApproved || hasPending)) {
      this.snackbarService.openSnackBar('No puede asignar el estado por que tiene items con estado aprobado o sin legalizar.', 'x', 'error');
      $event.source.value = travelExpense.approval;
      return;
    }

    if ($event.value == ApprovalStateEnum.APPROVED && (hasPending || !hasApproved)) {
      this.snackbarService.openSnackBar('No puede asignar el estado por que tiene items con estado sin legalizar o no tiene ninguno aprobado.', 'x', 'error');
      $event.source.value = travelExpense.approval;
      return;
    }


    this.dialog
      .openDialog({
        title: `¿Estás seguro que deseas dejar ${this.legalizeStatePipe.transform($event.value)} el viatico ${travelExpense.travelExpensesType.name}?`,
      })
      .then((response) => {
        if (response && response.state)
          this.changeApprovalExpense(travelExpense, $event.value);
        else
          this.getDataLegalizeTravelExpenses();
      })
      .catch((error) => this.getDataLegalizeTravelExpenses());
  }

  public onChangeApprovalExpenseDetail(travelExpense: TravelExpense, travelExpenseDetailIndex: number, $event: { value: ApprovalState }) {
    this.dialog
      .openDialog({
        title: `¿Estás seguro que cambiar el estado a ${this.legalizeStatePipe.transform($event.value as ApprovalStateEnum)}?`,
      })
      .then((response) => {
        if (response && response.state)
          this.changeApprovalExpenseDetail(travelExpense, travelExpenseDetailIndex, $event.value);
        else
          this.getDataLegalizeTravelExpenses();
      })
      .catch((error) => this.getDataLegalizeTravelExpenses());

  }

  private changeApprovalExpense(travelExpense: TravelExpense, approval: ApprovalState): void {
    this.spinner.show();
    const body: TravelExpense = {
      approval
    }
    this.travelExpensesService.updateTravelExpense(travelExpense.id, body).subscribe(
      (success) => {
        this.spinner.hide();
        this.getDataLegalizeTravelExpenses();
        this.snackbarService.openSnackBar(
          "Se ha cambiado el estado de la legalización correctamente",
          undefined,
          "success"
        );
      },
      (error) => {
        this.spinner.hide();
        this.snackbarService.openSnackBar(
          "Ocurrió un error al cambiar el estado de la legalización",
          undefined,
          "error"
        );
      }
    );
  }

  public getTravelExpenseState(travelExpense: TravelExpense) {
    travelExpense.approval = ApprovalStateEnum.REJECTED;

    travelExpense
      .travelExpensesDetail
      .forEach(
        (travelExpenseDetail: TravelExpenseDetail) => {
          if ([ApprovalStateEnum.REJECTED].includes(travelExpense.approval as ApprovalStateEnum) && travelExpenseDetail.approval == ApprovalStateEnum.APPROVED) {
            travelExpense.approval = ApprovalStateEnum.APPROVED;
          }

          if ([ApprovalStateEnum.REJECTED, ApprovalStateEnum.APPROVED].includes(travelExpense.approval as ApprovalStateEnum) && travelExpenseDetail.approval == ApprovalStateEnum.PENDING) {
            travelExpense.approval = ApprovalStateEnum.PENDING;
          }
        }
      );

    if (travelExpense.travelExpensesDetail.length === 0)
      travelExpense.approval = ApprovalStateEnum.PENDING;

    return travelExpense.approval;
  }

  private changeApprovalExpenseDetail(travelExpense: TravelExpense, travelExpenseDetailIndex: number, approval: ApprovalState): void {
    // Check if global state has changed?
    const travelExpenseDetail = travelExpense.travelExpensesDetail[travelExpenseDetailIndex];
    travelExpenseDetail.approval = approval;

    this.spinner.show();
    this.travelExpensesService.updateTravelExpensesDetail(travelExpense.id, travelExpenseDetail).subscribe(
      (success) => {
        this.getDataLegalizeTravelExpenses();
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.snackbarService.openSnackBar(
          "Ocurrió un error al cambiar el estado de la legalización",
          undefined,
          "error"
        );
      }
    );
  }

  public openFormAddTravelExpensesDetail(travelExpense: TravelExpense): void {
    if (travelExpense.approval === 'Approved') {
      this.snackbarService.openSnackBar(`El viático ya ha sido aprobado`, 'x', 'error');
      return;
    }

    if (travelExpense && !travelExpense.paid) {
      this.snackbarService.openSnackBar(`El viático aún no ha sido girado`, 'x', 'error');
      return;
    }

    const config = new MatDialogConfig();
    config.data = {
      travelExpense,
      cargo: this.data.cargo
    }
    config.width = ModalEnum.MEDIUM_WIDTH;
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.maxWidth = ModalEnum.MAX_WIDTH;

    this.dialogMat
      .open(TravelExpensesDetailComponent, config)
      .afterClosed()
      .subscribe(
        (refresh) => {
          this.getDataLegalizeTravelExpenses();
        }
      );
  }

  private getListPathImages(): TravelExpensesImages {
    const listPaths = {};
    if (!Array.isArray(this.travelExpenses))
      return {};

    this.travelExpenses.forEach((travelExpense) => {
      travelExpense.travelExpensesDetail.forEach((travelExpenseDetail) => {
        if (!listPaths[travelExpense.id])
          listPaths[travelExpense.id] = []
        listPaths[travelExpense.id] = listPaths[travelExpense.id].concat([...travelExpenseDetail.imageWithFingerprints]);
      });
    });
    return listPaths;
  }

  private getUrlFirestorage(): void {
    const imagesById: TravelExpensesImages = this.getListPathImages();
    const storage = AuthService.fStorage;
    for (let id in imagesById) {
      imagesById[id].forEach(image => {
        const pathReference = storage.ref(image.path);
        pathReference
          .getDownloadURL()
          .then(
            (success) => {
              if (!this.listPreviewImages[id])
                this.listPreviewImages[id] = [];
              this.listPreviewImages[id].push({
                path: success,
                fingerprint: image.fingerprint
              });
            },
            (error) => {
              if (!this.listPreviewImages[id])
                this.listPreviewImages[id] = [];
              this.listPreviewImages[id].push({
                path: this.pathNoImage,
                fingerprint: image.fingerprint
              });
            }
          )
          .catch(() => {
            if (!this.listPreviewImages[id])
              this.listPreviewImages[id] = [];
            this.listPreviewImages[id].push({
              path: this.pathNoImage,
              fingerprint: image.fingerprint
            });
          });
      });
    }
  }

  private loadTravelExpensesDetailValues() {
    if (!Array.isArray(this.travelExpenses))
      return;

    this.travelExpensesDetailValues = new FormGroup({});
    this.travelExpenses.forEach(
      (travelExpense, index) => {
        const detailControls = new FormGroup({});

        travelExpense
          .travelExpensesDetail
          .forEach((travelExpenseDetail, index) => {
            const control = new FormControl(travelExpenseDetail.value, [Validators.required, Validators.min(1)]);
            detailControls.addControl(`${index}`, control);
          });

        this.travelExpensesDetailValues.addControl(`${index}`, detailControls);
      }
    );
    this.fieldEditionState = [];
  }

  private getDataLegalizeTravelExpenses(): void {
    this.spinner.show();
    this.travelExpensesService.byCargoId(this.data.cargo.id).subscribe(
      (expenses: Array<TravelExpense>) => {
        this.travelExpenses = expenses;
        this.initData();
        this.spinner.hide();
      }
    );
  }

  canUploadImages(expense: TravelExpense): boolean {
    return expense.approval !== 'Approved';
  }

  isEditable(i: number, j: number) {
    return !!this.utils.getNestedValue(this.fieldEditionState, `${i}.${j}`);
  }

  confirmEdit(i: number, j: number) {
    this.spinner.show();
    const travelExpense = this.travelExpenses[i];
    const travelExpenseDetail = travelExpense.travelExpensesDetail[j];

    const value = this.travelExpensesDetailValues.get(`${i}.${j}`).value;

    travelExpenseDetail.value = parseFloat(value);

    this.travelExpensesService
      .updateTravelExpensesDetail(travelExpense.id, travelExpenseDetail)
      .subscribe({
        next: () => {
          this.spinner.hide();
          this.getDataLegalizeTravelExpenses();
          this.snackbarService.openSnackBar('Cambio exitoso!', 'x', 'success');
        },
        error: () => {
          this.spinner.hide();
          this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, 'x', 'error');
        },
        complete: () => {
          this.spinner.hide();
          this.cancelEdit(i, j);
        },
      })
  }

  cancelEdit(i: number, j: number) {
    if (this.fieldEditionState[i] === undefined)
      this.fieldEditionState[i] = [];
    this.fieldEditionState[i][j] = false;
  }

  enableEdition(i: number, j: number) {
    if (this.fieldEditionState[i] === undefined)
      this.fieldEditionState[i] = [];
    this.fieldEditionState[i][j] = true;
  }

  deleteTravelExpense(i: number, j: number) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      title: `¿Está seguro que quiere eliminar el detalle del viático?`,
      maxHeight: ModalEnum.MAX_HEIGHT,
      width: ModalEnum.SMALL_WIDTH,
      maxWidth: ModalEnum.MAX_WIDTH,
      autoFocus: false,
    };

    const dialogRef = this.dialogMat.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.spinner.show();
        const travelExpense = this.travelExpenses[i];
        const travelExpenseDetail = travelExpense.travelExpensesDetail[j];
        this.travelExpensesService
          .removeTravelExpenseDetail(travelExpense.id, travelExpenseDetail.id)
          .subscribe({
            next: () => {
              if (travelExpense.travelExpensesDetail.length > 1 || travelExpense.totalPaid > 0) {
                this.getDataLegalizeTravelExpenses();
                this.snackbarService.openSnackBar('Cambio exitoso!', 'x', 'success');
                this.spinner.hide();
              } else {
                this.travelExpensesService
                  .removeTravelExpense(travelExpense.id)
                  .subscribe({
                    next: () => {
                      this.getDataLegalizeTravelExpenses();
                      this.snackbarService.openSnackBar('Cambio exitoso!', 'x', 'success');
                      this.spinner.hide();
                    },
                    error: () => {
                      this.spinner.hide();
                      this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, 'x', 'error');
                    },
                    complete: () => {
                      this.spinner.hide();
                    }
                  })
              }
            },
            error: () => {
              this.spinner.hide();
              this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, 'x', 'error');
            },
            complete: () => {
              this.spinner.hide();
            }
          });
      }
    });
  }

  public getCanRemoveTravelExpenses(i, j) {
    const travelExpense = this.travelExpenses[i];
    const travelExpenseDetail = travelExpense.travelExpensesDetail[j];
    return this.permissionRole.hasPermission(Permission.cargo.module, Permission.cargo.removeTravelExpenses) && travelExpenseDetail.approval != ApprovalStateEnum.APPROVED;
  }

  get canRemoveApprovedTravelExpenses() {
    return this.permissionRole.hasPermission(Permission.cargo.module, Permission.cargo.removeAcceptedTravelExpenses);
  }

}
