<div class="maintenance-filter pt-4 px-0 pr-sm-4 pl-sm-2">
  <div class="maintenance-filter__body row m-0">
    <div class="col-12 col-lg-10 p-0 m-0">
      <div class="row p-0 m-0">
        <!-- License plate filter -->
        <mat-form-field class="col-12 col-md-4 maintenance-filter__body--license-plate" appearance="outline">
          <mat-label>Placa del vehículo</mat-label>
          <input matInput appLicensePlate placeholder="Por ej. ABC123" [formControl]="id">
        </mat-form-field>
        <!-- Type vehicle filter -->
        <mat-form-field class="col-12 col-md-4 maintenance-filter__body--type-vehicle" appearance="outline">
          <mat-label>Tipo de vehículo</mat-label>
          <mat-select [formControl]="type">
            <mat-option *ngFor="let vehicleType of vehicleTypes" value="{{vehicleType.name}}">
              {{vehicleType.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- State maintenance filter -->
        <mat-form-field class="col-12 col-md-4 maintenance-filter__body--state-maintenance" appearance="outline">
          <mat-label>Estado del mantenimiento</mat-label>
          <mat-select [formControl]="maintenanceState">
            <mat-option *ngFor="let maintenanceState of statesMaintenance" value="{{maintenanceState.id}}">
              {{maintenanceState.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="col-12 col-lg-2 p-0 m-0 button-container maintenance-filter__actions">
      <button mat-raised-button class="primary-secondary" color="primary" title="Eliminar Filtros"
      (click)="cleanFilters()">
      <i class="fas fa-trash-alt"></i>
      </button>
      <button mat-raised-button class="bg-color-primary" color="primary" title="Aplicar Filtros" (click)="applyFilters()">
        Buscar
      </button>
    </div>
  </div>
</div>
