<mat-card-actions class="d-inline-flex justify-content-start align-items-baseline flex-wrap m-0 pl-3 p-sm-2"
    [ngClass]="{'d-flex w-100':hasExternalFiltersFirstLine || hasExternalFiltersSecondLine}">
    <div class="d-none d-xl-inline-flex" *ngIf="hasExternalFiltersFirstLine"
        [ngClass]="{'d-xl-flex w-100':hasExternalFiltersSecondLine}">
        <!--Filtros que puedan ir en la primera linea-->
        <div class="button-t-group" *ngIf="options.showFilterAprovedCargo || options.showFilterCharges">
            <div class="button-t flex-fill" (click)="setStaticFilterAndSearch('approval', 'all')"
                [ngClass]="{'selected': staticFilters['approval'] === 'all'}">Todos
            </div>
            <div class="button-t flex-fill" (click)="setStaticFilterAndSearch('approval','Pending approval')"
                *ngIf="staticFilters['paid']!==true"
                [ngClass]="{'selected': staticFilters['approval']==='Pending approval'}">En revisión
            </div>
            <div class="button-t flex-fill" (click)="setStaticFilterAndSearch('approval','Approved')"
                [ngClass]="{'selected': staticFilters['approval']==='Approved'}">Aprobados</div>
            <div class="button-t flex-fill" (click)="setStaticFilterAndSearch('approval','Rejected')"
                *ngIf="staticFilters['paid']!==true" [ngClass]="{'selected': staticFilters['approval']==='Rejected'}">
                Rechazados</div>
        </div>
        <!--div class="button-t-group" *ngIf="options.showFilterAssignmentsCargo">
            <div class="button-t flex-fill" (click)="setStaticFilterAndSearch('approval','Pending approval')"
                *ngIf="staticFilters['paid']!==true"
                [ngClass]="{'selected': staticFilters['approval']==='Pending approval'}">Sin asignar
            </div>
            <div class="button-t flex-fill" (click)="setStaticFilterAndSearch('approval','Approved')"
                [ngClass]="{'selected': staticFilters['approval']==='Approved'}">Asignadas
            </div>
        </div-->
        <div class="button-t-group" *ngIf="options.showFilterDriver">
            <div class="button-t flex-fill" (click)="setFilterDriver(true)"
                [ngClass]="{'selected': staticFilters['state']===cargoStateEnum.ACCEPTED}">Con conductor asignado
            </div>
            <div class="button-t flex-fill" (click)="setFilterDriver(false)"
                [ngClass]="{'selected': staticFilters['state']===cargoStateEnum.CREATED}">Sin conductor asignado
            </div>
        </div>
        <div class="button-t-group" *ngIf="options.showFilterRaiting">
            <div class="button-t flex-fill" (click)="setStaticFilterAndSearch('qualified','')"
                [ngClass]="{'selected': staticFilters['qualified']===''}">Todos
            </div>
            <div class="button-t flex-fill" (click)="setStaticFilterAndSearch('qualified',true)"
                [ngClass]="{'selected': staticFilters['qualified']===true}">Calificados
            </div>
            <div class="button-t flex-fill" (click)="setStaticFilterAndSearch('qualified',false)"
                [ngClass]="{'selected': staticFilters['qualified']===false}">
                Sin calificación
            </div>
        </div>
        <div class="button-t-group" *ngIf="options.showFilterTypeUser">
            <div class="button-t flex-fill" (click)="setUserType('user')"
                [ngClass]="{'selected': staticFilters['type']==='user'}">Conductores
            </div>
            <div class="button-t flex-fill" (click)="setUserType('userClient')"
                [ngClass]="{'selected': staticFilters['type']==='userClient'}">Usuarios web
            </div>
        </div>
    </div>
    <hr *ngIf="hasExternalFiltersFirstLine && hasExternalFiltersSecondLine" class="w-100 m-0 d-none d-xl-block">
    <div class="d-none d-xl-inline-flex" *ngIf="hasExternalFiltersSecondLine">
        <!--Filtros que puedan ir en una segunda linea-->
        <div *ngIf="options.showFilterPaymentBalanceCargo" class="mt-3 d-flex align-items-baseline">
            <span>Tipo:</span>
            <mat-form-field appearance="outline" class="external-form-field">
                <mat-select [(ngModel)]="filterPayment" (selectionChange)="setFilterPayment($event.value)">
                    <mat-option value="fastPay" *ngIf="utils.isTeclogiUserOrClient">
                        Pronto pago
                    </mat-option>
                    <mat-option value="pendingToPay">
                        Por pagar
                    </mat-option>
                    <mat-option value="paid">
                        Pagados
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="options.showFilterPaymentAdvanceCargo" class="mt-3 d-flex align-items-baseline">
            <span>Estado:</span>
            <mat-form-field appearance="outline" class="external-form-field">
                <mat-select [(ngModel)]="staticFilters['requestedAdvance']"
                    (selectionChange)="setAdvanceState($event.value)">
                    <mat-option [value]="true">
                        Solicitados
                    </mat-option>
                    <mat-option [value]="false">
                        No solicitados
                    </mat-option>
                    <mat-option [value]="''">
                        Pagados
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="options.showFilterPaymentAdditionalCostsCargo" class="mt-3 d-flex align-items-baseline">
            <span>Tipo:</span>
            <mat-form-field appearance="outline" class="external-form-field">
                <mat-select [(ngModel)]="staticFilters['additionalCost']"
                    (selectionChange)="setAdditionalCostPaid($event.value)">
                    <mat-option value="Servicio cargue">
                        Cargues por pagar
                    </mat-option>
                    <mat-option value="Servicio descargue">
                        Descargues por pagar
                    </mat-option>
                    <mat-option value="Stand by">
                        Stand by por pagar
                    </mat-option>
                    <mat-option [value]="''">
                        Servicios pagados
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="options.showFilterPaymentExpenses && travelExpenseTypes?.length"
            class="mt-3 d-flex align-items-baseline">
            <span>Concepto:</span>
            <mat-form-field appearance="outline" class="external-form-field">
                <mat-select [(ngModel)]="staticFilters['travelExpensesType']" (selectionChange)="search()">
                    <mat-option *ngFor="let travelExpenseType of travelExpenseTypes" [value]="travelExpenseType.name">
                        {{travelExpenseType.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="options.showFilterCharges" class="mt-3 d-flex align-items-baseline" style="width: 400px;">
            <span>Estado:</span>
            <mat-form-field appearance="outline" class="external-form-field">
                <mat-select [(ngModel)]="filterCharges" (selectionChange)="setFilterCharges($event.value)">
                    <mat-option value="1">
                        Sin factura asignada
                    </mat-option>
                    <mat-option value="2">
                        Facturada - Sin Comprobante
                    </mat-option>
                    <mat-option value="3">
                        Comprobadas
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="options.showFilterPublicationZone" class="mt-3 d-flex align-items-baseline">
            <span>Zona:</span>
            <mat-form-field appearance="outline" class="external-form-field">
                <mat-select [formControl]="form.get('zone')" (selectionChange)="search()">
                    <mat-option value="private">
                        Privada
                    </mat-option>
                    <mat-option value="public">
                        Pública
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <button mat-raised-button class="btn-filter mb-2 mb-sm-3" title="Filtrar" (click)="openLateralFilter()"
        [ngClass]="{'d-inline-block d-xl-none':hasExternalFiltersFirstLine||hasExternalFiltersSecondLine}">
        <i *ngIf="numberOfAppliedFilters" class="fas fa-filter ml-2" [matBadge]="numberOfAppliedFilters"
            matBadgeColor="warn"></i>
        <i *ngIf="!numberOfAppliedFilters" class="fas fa-filter"></i>
        <span class="ml-2">Filtrar</span>
    </button>
    <button mat-raised-button class="btn-filter--external-filters mb-3 ml-xl-3 d-none d-xl-inline-block"
        title="Más filtros" (click)="openLateralFilter()"
        *ngIf="hasExternalFiltersFirstLine||hasExternalFiltersSecondLine">
        <i *ngIf="numberOfAppliedFilters" class="fas fa-filter ml-2" [matBadge]="numberOfAppliedFilters"
            matBadgeColor="warn"></i>
        <i *ngIf="!numberOfAppliedFilters" class="fas fa-filter"></i>
        <span class="ml-2">Más filtros</span>
    </button>
</mat-card-actions>
<div class="container-filters" *ngIf="showLateralFilter">
    <div class="background-exit" (click)="closeLateralFilter()"></div>
    <div class="filters" id="filters">
        <div class="filter-list">
            <div class="title-1">
                <i class="fas fa-filter" title="Mas filtros"></i>
                Filtros
            </div>
            <div class="scrollable-list">
                <div class="my-3 d-xl-none d-block">
                    <div class="button-t-group ml-1"
                        *ngIf="options.showFilterAprovedCargo || options.showFilterCharges">
                        <div class="button-t flex-fill" (click)="setStaticFilterAndSearch('approval', 'all')"
                            [ngClass]="{'selected': staticFilters['approval'] === 'all'}">Todos
                        </div>
                        <div class="button-t flex-fill"
                            (click)="setStaticFilterAndSearch('approval','Pending approval')"
                            *ngIf="staticFilters['paid']!==true"
                            [ngClass]="{'selected': staticFilters['approval']==='Pending approval'}">En revisión
                        </div>
                        <div class="button-t flex-fill" (click)="setStaticFilterAndSearch('approval','Approved')"
                            [ngClass]="{'selected': staticFilters['approval']==='Approved'}">Aprobados</div>
                        <div class="button-t flex-fill" (click)="setStaticFilterAndSearch('approval','Rejected')"
                            *ngIf="staticFilters['paid']!==true"
                            [ngClass]="{'selected': staticFilters['approval']==='Rejected'}">Rechazados</div>
                    </div>
                    <div class="secondary-button-t-group ml-1" *ngIf="options.showFilterPaymentBalanceCargo">
                        <div *ngIf="utils.isTeclogiUserOrClient" class="button-t flex-fill"
                            (click)="setFilterPayment('fastPay')" [ngClass]="{'selected': filterPayment==='fastPay'}">
                            Pronto pago
                        </div>
                        <div class="button-t flex-fill" (click)="setFilterPayment('pendingToPay')"
                            [ngClass]="{'selected': filterPayment==='pendingToPay'}">Por pagar</div>
                        <div class="button-t flex-fill" (click)="setFilterPayment('paid')"
                            [ngClass]="{'selected': filterPayment==='paid'}">Pagados</div>
                    </div>
                    <div class="secondary-button-t-group ml-1" *ngIf="options.showFilterPaymentAdvanceCargo">
                        <div class="button-t flex-fill" (click)="setAdvanceState(true)"
                            [ngClass]="{'selected': staticFilters['requestedAdvance']===true}">Solicitados
                        </div>
                        <div class="button-t flex-fill" (click)="setAdvanceState(false)"
                            [ngClass]="{'selected': staticFilters['requestedAdvance']===false}">No solicitados</div>
                        <div class="button-t flex-fill" (click)="setAdvanceState('')"
                            [ngClass]="{'selected': staticFilters['requestedAdvance']===''}">Pagados</div>
                    </div>
                    <div class="secondary-button-t-group  ml-1" *ngIf="options.showFilterPaymentAdditionalCostsCargo">
                        <div class="button-t flex-fill" (click)="setAdditionalCostPaid('Servicio cargue')"
                            [ngClass]="{'selected': staticFilters['additionalCost']==='Servicio cargue'}">Cargues por
                            pagar
                        </div>
                        <div class="button-t flex-fill" (click)="setAdditionalCostPaid('Servicio descargue')"
                            [ngClass]="{'selected': staticFilters['additionalCost']==='Servicio descargue'}">Descargues
                            por
                            pagar</div>
                        <div class="button-t flex-fill" (click)="setAdditionalCostPaid('Stand by')"
                            [ngClass]="{'selected': staticFilters['additionalCost']==='Stand by'}">Stand By por pagar
                        </div>
                        <div class="button-t flex-fill" (click)="setAdditionalCostPaid('')"
                            [ngClass]="{'selected': staticFilters['additionalCost']===''}">Servicios pagados</div>
                    </div>
                    <div class="button-t-group ml-1" *ngIf="options.showFilterDriver">
                        <div class="button-t flex-fill" (click)="setFilterDriver(true)"
                            [ngClass]="{'selected': staticFilters['state']===cargoStateEnum.ACCEPTED}">Con conductor
                            asignado
                        </div>
                        <div class="button-t flex-fill" (click)="setFilterDriver(false)"
                            [ngClass]="{'selected': staticFilters['state']===cargoStateEnum.CREATED}">Sin conductor
                            asignado
                        </div>
                    </div>
                    <div class="secondary-button-t-group ml-1" *ngIf="options.showFilterCharges">
                        <div class="button-t flex-fill" (click)="setFilterCharges('1')"
                            [ngClass]="{'selected': staticFilters['cashed']===false}">Sin factura asignada
                        </div>
                        <div class="button-t flex-fill" (click)="setFilterCharges('2')"
                            [ngClass]="{'selected': staticFilters['cashed']===true && staticFilters['checked']===false}">
                            Facturada - Sin Comprobante
                        </div>
                        <div class="button-t flex-fill" (click)="setFilterCharges('3')"
                            [ngClass]="{'selected': staticFilters['cashed']===true && staticFilters['checked']===true}">
                            Comprobadas
                        </div>
                    </div>
                    <div class="button-t-group ml-1" *ngIf="options.showFilterRaiting">
                        <div class="button-t flex-fill" (click)="setStaticFilterAndSearch('qualified','')"
                            [ngClass]="{'selected': staticFilters['qualified']===''}">Todos
                        </div>
                        <div class="button-t flex-fill" (click)="setStaticFilterAndSearch('qualified',true)"
                            [ngClass]="{'selected': staticFilters['qualified']===true}">Calificados
                        </div>
                        <div class="button-t flex-fill" (click)="setStaticFilterAndSearch('qualified',false)"
                            [ngClass]="{'selected': staticFilters['qualified']===false}">
                            Sin calificación
                        </div>
                    </div>
                    <div class="secondary-button-t-group ml-1" *ngIf="options.showFilterPaymentState">
                        <div class="button-t" (click)="setStaticFilterAndSearch('paid',false)"
                            [ngClass]="{'selected': staticFilters['paid']===false}">Por pagar
                        </div>
                        <div class="button-t" (click)="setStaticFilterAndSearch('paid',true)"
                            *ngIf="staticFilters['approval']==='Approved'"
                            [ngClass]="{'selected': staticFilters['paid']===true}">Pagados
                        </div>
                    </div>
                    <div class="button-t-group ml-1" *ngIf="options.showFilterTypeUser">
                        <div class="button-t flex-fill" (click)="setUserType('user')"
                            [ngClass]="{'selected': staticFilters['type']==='user'}">Conductores
                        </div>
                        <div class="button-t flex-fill" (click)="setUserType('userClient')"
                            [ngClass]="{'selected': staticFilters['type']==='userClient'}">Usuarios web
                        </div>
                    </div>
                    <div class="secondary-button-t-group ml-1" *ngIf="options.showFilterPaymentExpenses">
                        <div class="button-t"
                            (click)="setStaticFilterAndSearch('travelExpensesType', travelExpenseType.name)"
                            *ngFor="let travelExpenseType of travelExpenseTypes"
                            [ngClass]="{'selected': staticFilters['travelExpensesType'] === travelExpenseType.name}">
                            {{travelExpenseType.name}}
                        </div>
                    </div>
                </div>
                <!-- Ordenamiento -->
                <div *ngIf="options.showFilterOrderByTracking" class="my-3">
                    <div class="title-2 mt-3 w-100">
                        <span class="text-bold">Ordenar por: </span>Seguimiento
                    </div>
                    <div class="secondary-button-t-group ml-1">
                        <div class="button-t flex-fill" (click)="setStaticFilterAndSearch('orderBy', 'oldFirst')"
                            [ngClass]="{'selected': staticFilters['orderBy']==='oldFirst'}">Del más antiguo al más
                            reciente
                        </div>
                        <div class="button-t flex-fill" (click)="setStaticFilterAndSearch('orderBy', 'recentFirst')"
                            [ngClass]="{'selected': staticFilters['orderBy']==='recentFirst'}">Del más reciente al más
                            antiguo</div>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center flex-wrap mt-2"
                    *ngIf="numberOfAppliedFilters>0">
                    <div class="title-2 text-bold">Filtros aplicados:</div>
                    <div (click)="cleanAllFilters()" class="underline color-secondary-dark">Limpiar filtros</div>
                </div>
                <div class="pl-2" *ngIf="numberOfAppliedFilters>0">
                    <mat-chip-list>
                        <div *ngIf="appliedFilters?.nits?.length">
                            <mat-chip class="chip" *ngFor="let company of appliedFilters.nits; let i = index">
                                {{company | companyName}}
                                <mat-icon matChipRemove (click)="cleanFilter('nits',i)">cancel</mat-icon>
                            </mat-chip>
                        </div>
                        <mat-chip class="chip" *ngIf="appliedFilters?.errorManifest">
                            Con errores en el manifiesto
                            <mat-icon matChipRemove (click)="cleanFilter('errorManifest')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.nit">
                            Compañía: {{appliedFilters.nit | companyName}}
                            <mat-icon matChipRemove (click)="cleanFilter('nit')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.licensePlate">
                            Placa: {{appliedFilters.licensePlate}}
                            <mat-icon matChipRemove (click)="cleanFilter('licensePlate')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.escortedLicensePlate">
                            Placa escoltada: {{appliedFilters.escortedLicensePlate}}
                            <mat-icon matChipRemove (click)="cleanFilter('escortedLicensePlate')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.dateLoad">
                            Desde: {{appliedFilters.dateLoad | dateFormat:'only-date'}}
                            <mat-icon matChipRemove (click)="cleanFilter('dateLoad')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.endDate">
                            Hasta: {{appliedFilters.endDate | dateFormat:'only-date'}}
                            <mat-icon matChipRemove (click)="cleanFilter('endDate')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.creationDateInitial">
                            Desde: {{appliedFilters.creationDateInitial | dateFormat:'only-date'}}
                            <mat-icon matChipRemove (click)="cleanFilter('creationDateInitial')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.creationDateFinal">
                            Hasta: {{appliedFilters.creationDateFinal | dateFormat:'only-date'}}
                            <mat-icon matChipRemove (click)="cleanFilter('creationDateFinal')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.lastPointExistance">
                            Servicios {{appliedFilters.lastPointExistance==="true"? 'con': 'sin'}} seguimiento
                            registrado
                            <mat-icon matChipRemove (click)="cleanFilter('lastPointExistance')">cancel</mat-icon>
                        </mat-chip>
                        <div *ngIf="appliedFilters?.labels?.length">
                            <mat-chip class="chip" *ngFor="let tag of appliedFilters?.labels; let i = index">
                                Etiqueta: {{tag}}
                                <mat-icon matChipRemove (click)="cleanFilter('labels', i)">cancel</mat-icon>
                            </mat-chip>
                        </div>
                        <mat-chip class="chip" *ngIf="appliedFilters?.lastPointLapse">
                            Último seguimiento hace {{appliedFilters.lastPointLapse}} horas
                            <mat-icon matChipRemove (click)="cleanFilter('lastPointLapse')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.consecutive">
                            Nº ID servicio: {{appliedFilters.consecutive}}
                            <mat-icon matChipRemove (click)="cleanFilter('consecutive')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.container">
                            Contenedor: {{appliedFilters.container}}
                            <mat-icon matChipRemove (click)="cleanFilter('container')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.tripCategory">
                            Categoría: {{appliedFilters.tripCategory}}
                            <mat-icon matChipRemove (click)="cleanFilter('tripCategory')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.zone">
                            Publicación: {{appliedFilters.zone === 'private'? 'Privada' : 'Pública'}}
                            <mat-icon matChipRemove (click)="cleanFilter('zone')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.numberDocumentSender">
                            Nº doc remitente: {{appliedFilters.numberDocumentSender}}
                            <mat-icon matChipRemove (click)="cleanFilter('numberDocumentSender')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.driver">
                            Nº doc conductor: {{appliedFilters.driver}}
                            <mat-icon matChipRemove (click)="cleanFilter('driver')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.manifest">
                            Nº Manifiesto: {{appliedFilters.manifest}}
                            <mat-icon matChipRemove (click)="cleanFilter('manifest')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.billId">
                            Nº ID Factura: {{appliedFilters.billId}}
                            <mat-icon matChipRemove (click)="cleanFilter('billId')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.tripTypeName">
                            Tipo de carga: {{appliedFilters.tripTypeName}}
                            <mat-icon matChipRemove (click)="cleanFilter('tripTypeName')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.regimenType">
                            Tipo de régimen: {{appliedFilters.regimenType}}
                            <mat-icon matChipRemove (click)="cleanFilter('regimenType')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.fastPayment">
                            Servicios {{appliedFilters.fastPayment==="true"? "con" : "sin"}} pronto pago
                            <mat-icon matChipRemove (click)="cleanFilter('fastPayment')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.confirmedDriver">
                            Servicios {{appliedFilters.confirmedDriver==="true"? "con": "sin"}} conductor confirmado
                            <mat-icon matChipRemove (click)="cleanFilter('confirmedDriver')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.startAmount||appliedFilters?.endAmount">
                            Valor:
                            {{appliedFilters.startAmount? (appliedFilters.startAmount| currency:'COP'): "0 COP"}}
                            {{appliedFilters.endAmount? ' - '+(appliedFilters.endAmount| currency:'COP'):
                            " en adelante"}}
                            <mat-icon matChipRemove (click)="cleanFilter('amount')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.news">
                            Incidencia: {{appliedFilters.news}}
                            <mat-icon matChipRemove (click)="cleanFilter('news')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.origin">
                            Origen: {{appliedFilters.origin}}
                            <mat-icon matChipRemove (click)="cleanFilter('origin')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.destination">
                            Destino: {{appliedFilters.destination}}
                            <mat-icon matChipRemove (click)="cleanFilter('destination')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.state">
                            Estado: {{appliedFilters.state === '1'?"Con documentos pendientes por
                            validar":appliedFilters.state === "true"?"Activo":"Inactivo"}}
                            <mat-icon matChipRemove (click)="cleanFilter('state')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.role">
                            Rol: {{appliedFilters.role | roleTranslate}}
                            <mat-icon matChipRemove (click)="cleanFilter('role')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.document">
                            Documento: {{appliedFilters.document}}
                            <mat-icon matChipRemove (click)="cleanFilter('document')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.phone">
                            Celular: {{appliedFilters.phone}}
                            <mat-icon matChipRemove (click)="cleanFilter('phone')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.email">
                            Correo: {{appliedFilters.email}}
                            <mat-icon matChipRemove (click)="cleanFilter('email')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.cargoConsecutive">
                            Consecutivo: {{appliedFilters.cargoConsecutive}}
                            <mat-icon matChipRemove (click)="cleanFilter('cargoConsecutive')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.approval">
                            Estado de aprobación: {{appliedFilters.approval==='Approved'? 'Aprobado':
                            appliedFilters.approval === 'Rejected' ? 'Rechazado': 'Pendiente de aprobación'}}
                            <mat-icon matChipRemove (click)="cleanFilter('approval')">cancel</mat-icon>
                        </mat-chip>
                        <mat-chip class="chip" *ngIf="appliedFilters?.paid">
                            Pagado: {{appliedFilters.paid ? 'Si' : 'No'}}
                            <mat-icon matChipRemove (click)="cleanFilter('paid')">cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>

                <div class="row" [formGroup]="form">
                    <div class="title-2 text-bold mt-3 col-12">
                        Filtrar por:
                    </div>
                    <!--General filters-->
                    <div class="col-12 mt-3" *ngIf="options.showFilterErrorManifest && !hasEscortServicesCompany">
                        <mat-checkbox class="check" formControlName="errorManifest">
                            Error en manifiesto
                        </mat-checkbox>
                    </div>
                    <mat-form-field appearance="outline" *ngIf="options.showFilterPublicationZone"
                        class="col-12 d-xl-none d-block">
                        <mat-label>Zona</mat-label>
                        <mat-select [formControl]="form.get('zone')" (selectionChange)="search()">
                            <mat-option value="private">
                                Privada
                            </mat-option>
                            <mat-option value="public">
                                Pública
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="col-12" *ngIf="options.showFilterCompany && isRootNit">
                        <app-select-company [options]="optionsCompanyfilter" [inputFormControl]="companySelected">
                        </app-select-company>
                    </div>
                    <div class="col-12" *ngIf="options.showFilterMultipleCompanies && isRootNit">
                        <app-select-company [options]="optionsCompanies" [inputFormControl]="companiesControl">
                        </app-select-company>
                    </div>
                    <div class="col-12"
                        *ngIf="options.showFilterMultipleCompanies && isRootNit && form.get('nits')?.value?.length">
                        <mat-chip class="chip" *ngFor="let nit of form.get('nits')?.value">
                            {{nit| companyName}}
                            <mat-icon matChipRemove (click)="clearCompany(nit)">cancel</mat-icon>
                        </mat-chip>
                    </div>
                    <!--Cargo filters-->
                    <div *ngIf="filterBy==='cargo'" class="w-100 d-flex flex-wrap">
                        <div class="col-12" *ngIf="options.showfilterlicensePlate">
                            <mat-form-field>
                                <mat-label>Placa</mat-label>
                                <input appLicensePlate type="text" matInput formControlName="licensePlate">
                            </mat-form-field>
                        </div>
                        <div class="col-12" *ngIf="options.showfilterEscortedLicensePlate">
                            <mat-form-field>
                                <mat-label>Placa escoltada</mat-label>
                                <input appLicensePlate type="text" matInput formControlName="escortedLicensePlate">
                            </mat-form-field>
                        </div>
                        <div class="col-6 xs-12" *ngIf="options.showFilterDate">
                            <mat-form-field>
                                <mat-label>Desde</mat-label>
                                <input matInput [max]="maxDate" [matDatepicker]="pickerLoadDate" readonly
                                    (dateChange)="setDate($event, 'load')" [formControl]="dateLoad"
                                    (click)="pickerLoadDate.open()">
                                <mat-datepicker-toggle matPrefix [for]="pickerLoadDate"></mat-datepicker-toggle>
                                <mat-datepicker #pickerLoadDate></mat-datepicker>
                                <mat-icon matSuffix class="x-icon" *ngIf="actualFilters.dateLoad"
                                    (click)="cleanFilter('dateLoad')">cancel</mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-6 xs-12" *ngIf="options.showFilterDate">
                            <mat-form-field>
                                <mat-label>Hasta</mat-label>
                                <input matInput [min]="minDate" [matDatepicker]="pickerEndDate" readonly
                                    (dateChange)="setDate($event, 'end')" [formControl]="endDate"
                                    (click)="pickerEndDate.open()">
                                <mat-datepicker-toggle matPrefix [for]="pickerEndDate"></mat-datepicker-toggle>
                                <mat-datepicker #pickerEndDate></mat-datepicker>
                                <mat-icon matSuffix class="x-icon" *ngIf="actualFilters.endDate"
                                    (click)="cleanFilter('endDate')">cancel</mat-icon>
                            </mat-form-field>
                        </div>


                        <div class="col-6 xs-12" *ngIf="options.showFilterCreationDateInitial">
                            <mat-form-field>
                                <mat-label>Desde</mat-label>
                                <input matInput [max]="maxCreationDateInitial"
                                    [matDatepicker]="pickerCreationDateInitial"
                                    (dateChange)="onChangeCreationDate($event, 'creationDateInitial')"
                                    [(formControl)]="creationDateInitial">
                                <mat-datepicker-toggle matSuffix
                                    [for]="pickerCreationDateInitial"></mat-datepicker-toggle>
                                <mat-datepicker #pickerCreationDateInitial></mat-datepicker>
                                <mat-icon matSuffix class="x-icon" *ngIf="actualFilters.dateLoad"
                                    (click)="cleanFilter('creationDateInitial')">cancel</mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-6 xs-12" *ngIf="options.showFilterCreationDateFinal">
                            <mat-form-field>
                                <mat-label>Hasta</mat-label>
                                <input matInput [max]="maxCreationDateFinal" [min]="minCreationDateFinal"
                                    [matDatepicker]="pickerCreationDateFinal"
                                    (dateChange)="onChangeCreationDate($event, 'creationDateFinal')"
                                    [(formControl)]="creationDateFinal"
                                    [readonly]="utils.isEmpty(creationDateInitial.value)"
                                    [disabled]="utils.isEmpty(creationDateInitial.value)">
                                <mat-datepicker-toggle matSuffix
                                    [for]="pickerCreationDateFinal"></mat-datepicker-toggle>
                                <mat-datepicker #pickerCreationDateFinal></mat-datepicker>
                                <mat-icon matSuffix class="x-icon" *ngIf="actualFilters.dateLoad"
                                    (click)="cleanFilter('creationDateFinal')">cancel</mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="col-12" *ngIf="options.showFilterByTag && !hasEscortServicesCompany">
                            <mat-form-field appearance="standard">
                                <mat-label>Etiqueta</mat-label>
                                <mat-select [(formControl)]="tagControl" (selectionChange)="selectTag($event)">
                                    <mat-option *ngFor="let tag of tags" [value]="tag.name"
                                        [disabled]="form.get('labels')?.value?.includes(tag.name)">
                                        {{tag.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                        </div>
                        <div class="col-12" *ngIf="options.showFilterByTag && form.get('labels')?.value?.length">
                            <mat-chip class="chip" *ngFor="let tag of form.get('labels')?.value">
                                {{tag}}
                                <mat-icon matChipRemove (click)="clearTag(tag)">cancel</mat-icon>
                            </mat-chip>
                        </div>

                        <div class="col-12" *ngIf="options.showFilterLastPointExistance">
                            <mat-form-field>
                                <mat-label>Último seguimiento registrado</mat-label>
                                <mat-select formControlName="lastPointExistance"
                                    (selectionChange)="clearLastPointLapse($event)">
                                    <mat-option value="true">
                                        Con seguimientos
                                    </mat-option>
                                    <mat-option value="false">
                                        Sin seguimientos
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12"
                            *ngIf="options.showFilterLastPointLapse && form.get('lastPointExistance')?.value!=='false'">
                            <mat-form-field>
                                <mat-label>Lapso a último seguimiento</mat-label>
                                <input type="text" matInput onlyNumbersInt formControlName="lastPointLapse">
                                <p matSuffix class="mr-2">hrs</p>
                            </mat-form-field>
                        </div>
                        <div class="col-12" *ngIf="options.showfilterIdCargo">
                            <mat-form-field>
                                <mat-label>Nº ID servicio</mat-label>
                                <input type="text" matInput onlyNumbersInt formControlName="consecutive">
                            </mat-form-field>
                        </div>
                        <div class="col-12" *ngIf="options.showFilterContainer && !hasEscortServicesCompany">
                            <mat-form-field>
                                <mat-label>Contenedor</mat-label>
                                <input type="text" matInput containerNumber formControlName="container">
                            </mat-form-field>
                        </div>

                        <div class="col-12" *ngIf="options.showFilterErrorManifest && !hasEscortServicesCompany">
                            <mat-form-field>
                                <mat-label>Con error en el manifiesto</mat-label>
                                <mat-select formControlName="errorManifest">
                                    <mat-option value="true">
                                        Con error
                                    </mat-option>
                                    <mat-option value="true">
                                        Con error
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-12" *ngIf="options.showFilterCategory">
                            <mat-form-field>
                                <mat-label>Categoría</mat-label>
                                <mat-select formControlName="tripCategory">
                                    <mat-option value="Urbana">
                                        Urbana
                                    </mat-option>
                                    <mat-option value="Nacional">
                                        Nacional
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12" *ngIf="options.showFilterNumberDocument">
                            <mat-form-field>
                                <mat-label>Nº doc remitente</mat-label>
                                <input type="text" matInput formControlName="numberDocumentSender">
                            </mat-form-field>
                        </div>
                        <div class="col-12" *ngIf="options.showFilterNumberDocumentDriver || scheduledLoadsTab">
                            <app-input-document-user [inputFormControl]="documentDriverControl"
                                [options]="optionsDocumentUser"></app-input-document-user>
                        </div>
                        <div class="col-12" *ngIf="options.showfilterManifestCargo && !hasEscortServicesCompany">
                            <mat-form-field>
                                <mat-label>Nº Manifiesto</mat-label>
                                <input type="text" matInput formControlName="manifest">
                            </mat-form-field>
                        </div>
                        <div class="col-12" *ngIf="options.showfilterIdBill">
                            <mat-form-field>
                                <mat-label>Nº ID factura</mat-label>
                                <input type="text" matInput formControlName="billId">
                            </mat-form-field>
                        </div>
                        <div class="col-12" *ngIf="!isTabLoadingRoutes && options.showFilterTripTypeName">
                            <mat-form-field>
                                <mat-label>Tipo de carga</mat-label>
                                <mat-select formControlName="tripTypeName">
                                    <mat-option *ngFor="let type of cargoTypes" [value]="type">
                                        {{type}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12" *ngIf="options.showFilterRegimeType && !hasEscortServicesCompany">
                            <mat-form-field>
                                <mat-label>Tipo de régimen</mat-label>
                                <mat-select formControlName="regimenType">
                                    <mat-option value="OTM">
                                        OTM
                                    </mat-option>
                                    <mat-option value="DTA">
                                        DTA
                                    </mat-option>
                                    <mat-option value="DUTA">
                                        DUTA
                                    </mat-option>
                                    <mat-option value="DTAI">
                                        DTAI
                                    </mat-option>
                                    <mat-option value="Nacionalizado">
                                        Nacionalizado
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12" *ngIf="options.showFilterFintech && utils.isTeclogiUserOrClient">
                            <mat-form-field>
                                <mat-label>Pronto Pago</mat-label>
                                <mat-select formControlName="fastPayment">
                                    <mat-option value=true>
                                        Servicios con pronto pago
                                    </mat-option>
                                    <mat-option value=false>
                                        Servicios sin pronto pago
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12" *ngIf="options.showFilterConfirmedDriver">
                            <mat-form-field>
                                <mat-label>Por confirmación del conductor</mat-label>
                                <mat-select formControlName="confirmedDriver">
                                    <mat-option value="true">
                                        Servicios con conductor confirmado
                                    </mat-option>
                                    <mat-option value="false">
                                        Servicios sin conductor confirmado
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12" *ngIf="options.showFilterAmount && !hasEscortServicesCompany">
                            <mat-form-field>
                                <mat-label>Valor declarado de la mercancia</mat-label>
                                <mat-select [(ngModel)]="filterAmountValue" (selectionChange)="setAmount($event)"
                                    [ngModelOptions]="{standalone: true}">
                                    <mat-option *ngFor="let amount of cargoManager.amountsCargo" [value]="amount">
                                        <div *ngIf="amount?.start <= 0">Hasta {{amount?.end | currency:'COP'}}</div>
                                        <div *ngIf="!isFinite(amount?.end)">Desde {{amount?.start | currency:'COP'}}
                                        </div>
                                        <div *ngIf="amount?.start > 0 && isFinite(amount?.end)">
                                            Entre {{amount?.start | currency:'COP'}} y {{amount?.end | currency:'COP'}}
                                        </div>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12" *ngIf="options.showFilterIncident">
                            <mat-form-field>
                                <mat-label>Tipo de incidencia</mat-label>
                                <mat-select formControlName="news">
                                    <mat-option *ngFor="let incident of incidents" [value]="incident.name">
                                        {{incident.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12" *ngIf="options.showFilterOriginDestination">
                            <app-autocomplete-city [options]="optionsCityOrigin"
                                [inputFormControl]="cityControlOrigin"></app-autocomplete-city>
                        </div>
                        <div class="col-12" *ngIf="options.showFilterOriginDestination">
                            <app-autocomplete-city [options]="optionsCityDestination"
                                [inputFormControl]="cityControlDestination"></app-autocomplete-city>
                        </div>

                        <div class="col-12" *ngIf="options.showFilterCargoConsecutive">
                            <mat-form-field>
                                <mat-label>Nº ID servicio</mat-label>
                                <input type="text" matInput onlyNumbersInt formControlName="cargoConsecutive">
                            </mat-form-field>
                        </div>

                        <div class="col-12" *ngIf="options.showFilterExpensesApproval">
                            <mat-form-field>
                                <mat-label>Estado de aprobación</mat-label>
                                <mat-select formControlName="approval">
                                    <mat-option value="Approved">
                                        Aprobado
                                    </mat-option>
                                    <mat-option value="Rejected">
                                        Rechazado
                                    </mat-option>
                                    <mat-option value="Pending approval">
                                        Pendiente de aprobación
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-12" *ngIf="options.showFilterExpensesPaid">
                            <mat-form-field>
                                <mat-label>Pagado</mat-label>
                                <mat-select formControlName="paid">
                                    <mat-option value="true">
                                        Sí
                                    </mat-option>
                                    <mat-option value="false">
                                        No
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <!-- Service requests filters -->
                    <div *ngIf="filterBy==='serviceRequests'" class="w-100 d-flex flex-wrap">
                        <div class="col-12" *ngIf="options.showfilterIdCargo">
                            <mat-form-field>
                                <mat-label>Nº ID servicio</mat-label>
                                <input type="text" matInput onlyNumbersInt formControlName="consecutive">
                            </mat-form-field>
                        </div>
                    </div>
                    <!--User filters-->
                    <div *ngIf="filterBy==='user'" class="w-100 d-flex flex-wrap">
                        <div class="col-12" *ngIf="options.showFilterRole && staticFilters['type']==='userClient'">
                            <mat-form-field>
                                <mat-label>Rol</mat-label>
                                <mat-select formControlName="role">
                                    <mat-option *ngFor="let role of roles" [value]="role.name">
                                        {{role.name | roleTranslate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12"
                            *ngIf="options.showFilterStateUser && (staticFilters['type']==='user'||(form.get('role').value && form.get('role').value!=='webUserPendingActivate'))">
                            <mat-form-field>
                                <mat-label>Estado</mat-label>
                                <mat-select formControlName="state">
                                    <mat-option *ngFor="let state of userStates" [value]="state.value">
                                        {{state.name}}
                                    </mat-option>
                                    <mat-option [value]="'1'" *ngIf="staticFilters['type']==='user'" permission-display
                                        [module]="permission.administration.module"
                                        [functionality]="permission.administration.showDocumentsPendingDrivers">
                                        Con documentos pendientes por validar
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-12" *ngIf="options.showFilterDocument">
                            <mat-form-field>
                                <mat-label>Documento</mat-label>
                                <input type="text" matInput onlyNumbersInt formControlName="document">
                                <mat-error *ngIf="form.get('document').errors?.maxlength">
                                    {{ utils.giveMessageError('MAX_LENGTH_EXCEED', 'documento', 15) }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12" *ngIf="options.showFilterPhone">
                            <mat-form-field>
                                <mat-label>Celular</mat-label>
                                <input type="text" matInput formControlName="phone">
                            </mat-form-field>
                        </div>
                        <div class="col-12" *ngIf="options.showFilterEmail">
                            <mat-form-field>
                                <mat-label>Correo electrónico</mat-label>
                                <input type="text" matInput formControlName="email">
                                <mat-error *ngIf="form.get('email').errors?.pattern">
                                    {{ utils.giveMessageError('INCORRECT_FORMAT', 'correo') }}
                                </mat-error>
                                <mat-error *ngIf="form.get('email').errors?.maxlength">
                                    {{ utils.giveMessageError('MAX_LENGTH_EXCEED', 'correo', 100) }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
            </div>

            <mat-card-actions class="d-flex justify-content-around">
                <button mat-raised-button class="primary-secondary flex-fill" color="primary" title="Cerrar"
                    (click)="closeLateralFilter()">Cerrar
                </button>
                <button mat-raised-button class="flex-fill" color="primary" title="Filtrar"
                    (click)="applyFilter()">Filtrar
                </button>
            </mat-card-actions>

        </div>

    </div>
</div>