<div class="maintenance-summary pb-3 pb-lg-0 pt-4 px-3 px-lg-5">
  <div class="row m-0 p-0 maintenance-summary__info">
    <div class="col-12 m-0 p-0 maintenance-summary__body" [ngClass]="{'col-lg-8': enableActions}">
      <div class="row m-0 p-0">
        <div class="px-2 col-12 col-xl-1 d-flex maintenance-summary__icon mb-3">
          <img src="{{vehicle?.typeVehiclePath ? vehicle.typeVehiclePath : defaultTypeVehiclePath}}" alt="truck-icon">
        </div>

        <!-- License plate -->
        <div class="px-2 pb-3 col-12 col-sm-6 col-md-4 col-xl-2 maintenance-summary__license-plate d-flex flex-column text-xl-center">
          <span class="maintenance-summary__license-plate--title">
            Placa
          </span>
          <span class="text-bold color-primary-dark maintenance-summary__license-plate--value">
            {{ vehicle?.id ? vehicle.id : '-' }}
          </span>
        </div>

        <!-- Vehicle type -->
        <div class="px-2 pb-3 col-12 col-sm-6 col-md-4 col-xl-2 maintenance-summary__type d-flex flex-column text-xl-center">
          <span class="maintenance-summary__type--title">
            Tipo de vehículo
          </span>
          <span class="text-bold color-primary-dark maintenance-summary__type--value">
            {{ vehicle?.vehicleType?.name ? vehicle.vehicleType.name : '-' }}
          </span>
        </div>

        <!-- SOAT expiration -->
        <div class="px-2 pb-3 col-12 col-sm-6 col-md-4 col-xl-2 maintenance-summary__soat-expiration d-flex flex-column text-xl-center">
          <span class="maintenance-summary__soat-expiration--title">
            Vencimiento SOAT
          </span>
          <span class="text-bold color-primary-dark maintenance-summary__soat-expiration--value">
            {{ vehicle?.validSOAT?.expirationDate ? getDocumentExpedirationDate(vehicle?.validSOAT?.expirationDate) : vehicle?.validSOAT?.description ? vehicle?.validSOAT?.description : 'No se registra fecha de expiración' }}
          </span>
        </div>

        <!-- SOAT expiration -->
        <div class="px-2 pb-3 col-12 col-sm-6 col-md-4 col-xl-2 maintenance-summary__rtm-expiration d-flex flex-column text-xl-center">
          <span class="maintenance-summary__rtm-expiration--title">
            Vencimiento RTM
          </span>
          <span class="text-bold color-primary-dark maintenance-summary__rtm-expiration--value">
            {{ vehicle?.validRTM?.expirationDate ? getDocumentExpedirationDate(vehicle?.validRTM?.expirationDate) : vehicle?.validRTM?.description ? vehicle?.validRTM?.description : 'No se registra fecha de expiración' }}
          </span>
        </div>

        <!-- Driver license -->
        <div class="px-2 pb-3 col-12 col-sm-6 col-md-4 col-xl-3 maintenance-summary__driver-license d-flex flex-column text-xl-center">
          <span class="maintenance-summary__driver-license--title">
            Licencia conductor
          </span>
          <ng-container *ngIf="driver?.driverLicenseCategory?.length; else notDriverLicense">
            <ng-container *ngFor="let licenseCategory of driver.driverLicenseCategory">
              <span class="text-bold color-primary-dark maintenance-summary__driver-license--value">
                {{ licenseCategory?.category ? licenseCategory.category : '-' }}{{ licenseCategory?.active && licenseCategory?.category ? ' (Activa)' : ' (Inactiva)' }}
              </span>
            </ng-container>
          </ng-container>
          <ng-template #notDriverLicense>
            -
          </ng-template>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-4 p-0 m-0 mt-3 mt-lg-0 button-container maintenance-summary__actions"
    *ngIf="enableActions"
    permission-display
    [module]="permission.administration.module"
    [functionality]="permission.administration.createMaintenance">
      <button
      mat-raised-button
      color="primary"
      class="bg-color-primary button-width-auto text-bold maintenance-summary__actions--button"
      (click)="createMaintenance()">
        <i class="fas fa-plus-circle mr-2 d-none d-sm-inline"></i>
        <span>Registrar mantenimiento</span>
      </button>
    </div>
  </div>
</div>
