<div class="modal-body wrapper-modal">
    <i matTooltip="Cerrar" class="fa-solid fa-xmark fa-2xl close-icon-actions" (click)="dialogRef.close()"></i> <br>

    <p class="title text-break text-center">
        ¿Desea trasladar el anticipo?
    </p>
    <p>Al realizar un traslado de anticipo la carga de origen no es eliminada automáticamente.</p>
    <form class="body" [formGroup]="form">
        <div class="row" *ngFor="let cargo of data.cargos; let i = index;" style="padding: 20px;"
            [ngClass]="{'top-borded': i != '0', i: true}">
            <div class="col-md-6">
                <b class="deep-bold">Traslado {{i + 1}}</b> <br>
                <span>Carga de origen:&nbsp;</span>
                <b class="deep-bold">{{cargo.consecutive}}</b>
            </div>
            <div class="col-md-6" formArrayName="destinations">
                <mat-form-field appearance="outline">
                    <mat-label>Consecutivo carga destino</mat-label>
                    <input matInput type="text" autocomplete="off" [name]="'destinationSerach-' + i"
                        (keyup)="searchCargo(i, $event)" [matAutocomplete]="auto">
                    <mat-autocomplete (optionSelected)="changeSelectedCargo(i, $event)" #auto [formControlName]="i">
                        <mat-option *ngFor="let cargo of cargoEvents[i] | async"
                            [value]="cargo ? cargo.consecutive : ''">
                            <span *ngIf="cargo">{{cargo.consecutive}}</span>
                            <small *ngIf="!cargo" style="color: gray;">No hay resultados.</small>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
    </form>
    <br>
    <div class="actions text-center">
        <!-- No -->
        <button mat-raised-button class="primary-secondary button-width-auto" color="primary"
            (click)="dialogRef.close()">
            Cancelar
        </button>
        &nbsp;
        <!-- Yes -->
        <button mat-raised-button class="button-width-auto bg-color-primary" color="primary" (click)="submit()"
            [disabled]="!valid">
            Confirmar
        </button>
    </div>
</div>