<aside id="mainCollectionPointList" class="col-md-12 mb-0 h-100-margin">
  <div class="container-list h-100-margin">
    <div class="row">
      <div class="col-12 col-sm-9">
        <div class="container-list-filter">
          <app-list-filter [options]="optionsFilter" (refreshList)="applyFilter($event)"
            [filterBy]="'user'"></app-list-filter>
        </div>
      </div>
      <div class="col-12 col-sm-3 admin-users-list__actions"
      *ngIf="!hideDriverButton"
      permission-display
      [module]="permission.administration.module"
      [functionality]="permission.administration.createDriver">
        <button
        mat-raised-button
        color="primary"
        class="bg-color-primary button-width-auto text-bold admin-users-list__actions--button mb-4 mb-sm-2"
        [routerLink]="['/administration/admin-users/create-driver']">
          <i class="fas fa-plus-circle mr-2"></i>
          <span>Crear conductor</span>
        </button>
      </div>
    </div>
    <div class="container-list-body" infinite-scroll [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollThrottle]="throttle" [scrollWindow]="false" (scrolled)="onScrollDown()">
      <div class="col-md-12 mb-md-0 mb-12" *ngFor="let user of listUsers; let i = index;">
        <app-admin-users-item [user]="user" *ngIf="user.information && user.information.document"
          (emitToParent)="refreshList()"></app-admin-users-item>
      </div>
    </div>
  </div>
</aside>