import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VehicleCargo } from 'src/app/core/interfaces/vehicle-cargo';
import { Patterns } from 'src/app/core/resources/patterns';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { VehiclesService } from 'src/app/modules/administration/vehicles/list-vehicles.service';
import { ManualCreationCargoService } from '../../manual-creation-cargo.service';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { VehicleMessages } from 'src/app/core/messages/vehicle-messages.enum';
import { Fmt } from 'src/app/core/messages/fmt';
import { AuthService } from 'src/app/core/services/authentication.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { AdminUsersService } from 'src/app/modules/administration/admin-users/admin-users.service';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { CompanyService } from 'src/app/core/services/company.service';
import { Subscription } from 'rxjs';
import { Company } from 'src/app/core/interfaces/company';

@Component({
  selector: 'app-assign-license-plate',
  templateUrl: './assign-license-plate.component.html',
  styleUrls: ['./assign-license-plate.component.scss']
})
export class AssignLicensePlateComponent implements OnInit {
  listLicensePlate = [];
  vehiclesByLicensePlate = {};
  allowCreatingWithoutTruora: boolean = false;

  constructor(
    public manualCreationCargoService: ManualCreationCargoService,
    private router: Router,
    public patterns: Patterns,
    private snackBarService: SnackBarService,
    private vehiclesService: VehiclesService,
    private authService: AuthService,
    private adminUserService: AdminUsersService,
    public utils: Utils,
    public spinner: NgxSpinnerService,
    private companyService: CompanyService
  ) { }

  ngOnInit() {
    this.listLicensePlate = this.utils.clone(this.manualCreationCargoService.getVehiclesWithLicensePlate());
    this.infoTruora();
  }

  private infoTruora() {
    const holderCompany = this.companyService.holderCompany;
    if (holderCompany && holderCompany.companyId)
      this.allowCreatingWithoutTruora = !!(holderCompany && holderCompany.allowCreatingWithoutTruora);
  }

  getIconVehicle(vehicle: VehicleCargo) {
    return '/assets/svg/icons/icon-' + vehicle.name.toLowerCase() + '.svg';
  }

  onKeyUp($event, vehicle: VehicleCargo) {
    vehicle.licensePlate = $event.target.value;
  }

  realNextStep() {
    if (this.manualCreationCargoService.isValidLicensePlate()) {
      this.manualCreationCargoService.setVehiclesWithLicensePlate(this.listLicensePlate);
      // Load measurement
      const serviceTypeName = this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].serviceType.controls.name.value;

      if (serviceTypeName === 'Contenedor vacío con carga de compensación' || serviceTypeName === 'Contenedor vacío express' || serviceTypeName === 'Carga consolidada') {
        this.manualCreationCargoService.getComponentActive().setId(2);
        this.manualCreationCargoService.getComponentActive().getStep().setId(5);
        this.router.navigate([this.manualCreationCargoService.steps.optional.url]);
      } else {
        this.manualCreationCargoService.getComponentActive().setId(2);
        this.manualCreationCargoService.getComponentActive().getStep().setId(4);
        this.router.navigate([this.manualCreationCargoService.steps.typeMerchandise.url]);
      }
    } else {
      this.snackBarService.openSnackBar('Por favor verifica el formato de las placas', undefined, 'alert');
    }

  }

  async nextStep() {
    let lastTravelPointDate = new Date();
    for (const destination of this.manualCreationCargoService.cargoForm.value.cargoFeature.uploadDownload.destination) {
      const arrival = new Date(destination.downloadDate.slice(0, 10));
      if (arrival > lastTravelPointDate) lastTravelPointDate = arrival;
    }
    let validVehicles = true;
    for (const i in this.listLicensePlate) {
      this.listLicensePlate[i].licensePlate = this.listLicensePlate[i].licensePlate.toUpperCase();
      const licensePlate = this.listLicensePlate[i].licensePlate;

      if (licensePlate == '')
        continue;

      if (!this.patterns.GET_REGEX('LICENSE_PLATES').test(licensePlate)) {
        this.snackBarService.openSnackBar(Fmt.string(ServiceMessages.LICENSE_PLATE_FORMAT_INCORRECT, licensePlate), undefined, 'alert');
        validVehicles = false;
        break;
      }

      const holderCompany = this.authService.getUserSession() && this.authService.getUserSession().clientCompanyId ? this.authService.getUserSession().clientCompanyId : null;
      if (!holderCompany) {
        this.snackBarService.openSnackBar(Fmt.string(ServiceMessages.LICENSE_PLATE_NOT_FOUND, licensePlate), undefined, 'error');
        validVehicles = false;
        break;
      }
      validVehicles = await this.checkMissingDocuments(licensePlate, holderCompany);
      if (!validVehicles) break;
      // Revisar tipo de vehículo y carrocería
      validVehicles = !this.allowCreatingWithoutTruora ? this.checkBodywork(this.listLicensePlate[i]) : true;
      if (!validVehicles) break;

      validVehicles = this.checkVehicleState(this.listLicensePlate[i]);
      if (!validVehicles) break;

      validVehicles = await this.checkDriverState(this.listLicensePlate[i]);
    }

    if (validVehicles) this.realNextStep();
  }

  async checkDriverState(requiredVehicle: any) {
    const vehicle: Vehicle = this.vehiclesByLicensePlate[requiredVehicle.licensePlate];

    return new Promise<boolean>((resolve, reject) => {
      this.adminUserService
        .getUsersDetailByDocument(vehicle.driver.document)
        .toPromise()
        .then(
          (user) => {
            const result: boolean = !!(user && user.state && user.state.active);
            if (!result) {
              this.snackBarService.openSnackBar(`El conductor del vehículo ${vehicle.id} no está activo`, 'x', 'error');
            }
            resolve(result);
          },
          reject
        );
    });
  }

  checkVehicleState(requiredVehicle: any): boolean {
    const vehicle: Vehicle = this.vehiclesByLicensePlate[requiredVehicle.licensePlate];
    return this.vehiclesService.getVehicleState(vehicle) == 'Activo';
  }

  checkBodywork(requiredVehicle: any): boolean {
    const vehicle: Vehicle = this.vehiclesByLicensePlate[requiredVehicle.licensePlate.toUpperCase()];
    const valid = vehicle && vehicle.vehicleType && vehicle.vehicleType.name === requiredVehicle.name;

    if (!valid) {
      this.snackBarService.openSnackBar(
        Fmt.string(VehicleMessages.VEHICLE_TYPE_DIFF, `${vehicle.vehicleType.name} (${vehicle.id})`, requiredVehicle.name),
        'x',
        'error'
      );
    }

    return valid;
  }

  async checkMissingDocuments(licensePlate, holderCompany): Promise<boolean> {
    let validVehicle = true;
    let vehicle: Vehicle = undefined;

    if (this.vehiclesByLicensePlate[licensePlate] === undefined) {
      await this.vehiclesService.getVehicleById(licensePlate, holderCompany).toPromise().then((vehicles) => {
        this.spinner.hide();
        vehicle = vehicles[0];
        this.vehiclesByLicensePlate[vehicle.id.toUpperCase()] = vehicle;
      }).catch(() => {
        this.snackBarService.openSnackBar(Fmt.string(ServiceMessages.LICENSE_PLATE_NOT_FOUND, licensePlate), undefined, 'error');
        this.spinner.hide();
        validVehicle = false;
      });
    } else {
      vehicle = this.vehiclesByLicensePlate[licensePlate];
    }

    if (!vehicle) {
      this.snackBarService.openSnackBar(Fmt.string(ServiceMessages.LICENSE_PLATE_NOT_FOUND, licensePlate), undefined, 'error');
      validVehicle = false;
    } else if (!vehicle.driver || !vehicle.driver.document || !vehicle.driver.name) {
      this.snackBarService.openSnackBar(Fmt.string(ServiceMessages.VEHICLE_WITHOUT_DRIVER, licensePlate), undefined, 'error');
      validVehicle = false;
    }

    const missingDocuments = vehicle ? await this.vehiclesService.getMissingDocuments(vehicle, 'unaccepted') : [];
    if (missingDocuments.length) {
      this.snackBarService.openSnackBar(
        `${licensePlate}: ${VehicleMessages.VEHICLE_DOCUMENTATION_INCOMPLETE} ${missingDocuments.join(', ')}`,
        undefined,
        "error"
      );
      validVehicle = false;
    }

    return validVehicle;
  }

  stepBack() {
    this.manualCreationCargoService.getComponentActive().setId(2);
    this.manualCreationCargoService.getComponentActive().getStep().setId(2);
    this.router.navigate([this.manualCreationCargoService.steps.quantityVehicles.url]);
  }
}
