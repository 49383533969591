<div class="container">
  <div id="mainFreightTotalValue" class="col-md-12 mb-md-0 mb-12">
    <div class="row">
      <div class="container">
        <div class="col-md-12 mb-md-0 mb-12 wraper-additional-service">
          <br><br>
          <div class="title-service-2">
            <span class="title-value-field">Valor de la tarifa:</span>
            <span
              class="color-secondary-dark value-field">{{manualCreationCargoService.cargoForm.value.shippingCost.rate |
              currency:'COP':'code':'0.2'}}</span>
          </div>
          <div class="title-service-2">
            <span class="title-value-field">Valor total del servicio:</span>
            <span
              class="color-secondary-dark value-field">{{manualCreationCargoService.cargoForm.value.shippingCost.totalCost
              | currency:'COP':'code':'0.2'}}</span>
          </div>
          <div class="title-service-2" show-cargo-tax>
            <span class="title-value-field">Valor del Reteica
              ({{(freeTaxes ? 0 : manualCreationCargoService.cargoForm.value.cargoFeature.uploadDownload.origin.reteica
              ) +
              '%'}}):</span>
            <span class="color-secondary-dark value-field">{{(reteicaValue) |
              currency:'COP':'code':'0.2'}}</span>
          </div>
          <div class="title-service-2" show-cargo-tax>
            <span class="title-value-field">Valor del Retefuente ({{freeTaxes ? 0 : 1}} %):</span>
            <span class="color-secondary-dark value-field">{{(retefuenteValue) |
              currency:'COP':'code':'0.2'}}</span>
          </div>
          <div class="title-service-2" *ngIf="isTeclogiUser">
            <span class="title-value-field">Valor del Servicios Integrales (1%):</span>
            <span
              class="color-secondary-dark value-field">{{cargoItemService.getVehicleInsuranceDiscountCargo(manualCreationCargoService.cargoForm.value)
              | currency:'COP':'code':'0.2'}}</span>
          </div>
          <div class="col-12 button-container justify-content-center mt-3 px-0">
            <button mat-raised-button class="secondary-sharp mr-4" (click)="stepBack()">Atrás</button>
            <app-request-button></app-request-button>
            <button mat-raised-button color="primary" class="bg-color-primary" (click)="nextStep()">
              {{hasManifest? 'Crear remesas' : 'Crear servicios'}}
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>