import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';

import { Global } from 'src/app/core/resources/global';
import { CreateCargoFromGuidesService } from './create-cargo-from-guides.service';
import { Guide } from 'src/app/core/interfaces/guide';
import { ListCargoFromGuidesService } from './list-cargo-from-guides/list-cargo-from-guides.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { GoogleService } from 'src/app/core/services/google.service';
import { Company } from 'src/app/core/interfaces/company';
import { Utils } from 'src/app/core/resources/utils';

@Component({
  selector: 'app-create-cargo-from-guides',
  templateUrl: './create-cargo-from-guides.component.html',
  styleUrls: ['./create-cargo-from-guides.component.scss'],
  providers: [AuthService, Global, GoogleService]
})
export class CreateCargoFromGuidesComponent implements OnInit {

  typeGuideActive: string;
  guidesStateCreated: Guide[] = [];
  guidesStateInWarehouse: Guide[] = [];
  processGuidesStateCreatedSelected: Cargo;
  processGuidesStateInWarehouseSelected: Cargo;
  companyUserData: Company;
  tabsGuides: any[] = [];

  constructor(
    public createCargoFromGuidesService: CreateCargoFromGuidesService,
    public listCargoFromGuidesService: ListCargoFromGuidesService,
    private authService: AuthService,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    public utils: Utils
  ) {
    this.companyUserData = this.authService.getCompany();
  }

  ngOnInit() {
    this.createTabsGuides();
  }

  createTabsGuides() {
    if (this.companyUserData && this.companyUserData.configuration && this.companyUserData.configuration.serviceType && this.companyUserData.configuration.serviceType === "fulfillment") {
      this.tabsGuides.push({
        label: 'Guias para recolección',
        state: 'Created'
      });
      this.tabsGuides.push({
        label: 'Guias para distribución',
        state: 'In warehouse'
      });
    } else {
      this.tabsGuides.push({
        label: 'Guias',
        state: 'Created'
      });
    }
    this.onChangeTypeGuideActive('Created');
  }

  onChangeTypeGuideActive(typeGuideActive: string) {
    this.typeGuideActive = typeGuideActive;
  }

  onSelectGuide($event) {
    switch ($event.state) {
      case 'Created':
        this.guidesStateCreated = $event.guides;
        break;
      case 'In warehouse':
        this.guidesStateInWarehouse = $event.guides;
        break;
    }
  }

  createCargo() {

    // this.processGuides(this.guidesStateCreated, this.guidesStateInWarehouse);
    this.spinner.show();
    if (this.guidesStateCreated.length || this.guidesStateInWarehouse.length) {
      this.listCargoFromGuidesService.createCargoObjectByGuides(this.companyUserData.configuration.serviceType, this.guidesStateCreated, this.guidesStateInWarehouse).subscribe(
        (data: any) => {
          this.spinner.hide();
          this.showMessageCreateCargo(true);
          this.clearAllData();
          this.onChangeTypeGuideActive(this.typeGuideActive);
        },
        (error) => {
          this.spinner.hide();
          this.showMessageCreateCargo(false);
        }
      );
    } else {
      this.spinner.hide();
      this.snackBarService.openSnackBar('Debe seleccionar por lo menos una guía', undefined, 'alert');
    }
  }

  clearAllData() {
    this.guidesStateCreated = [];
    this.guidesStateInWarehouse = [];
    this.processGuidesStateCreatedSelected = undefined;
    this.processGuidesStateInWarehouseSelected = undefined;
  }

  showMessageCreateCargo(isSuccess: boolean) {
    if (isSuccess)
      this.snackBarService.openSnackBar('Se ha creado el servicio correctamente');
    else
      this.snackBarService.openSnackBar('No se ha podido crear el servicio', undefined, 'error');
  }

  // async processGuides(guidesStateCreatedSelected, guidesStateInWarehouseSelected) {
  //   if (guidesStateCreatedSelected && guidesStateCreatedSelected.length) {
  //     this.processGuidesStateCreatedSelected = this.listCargoFromGuidesService.processGuidesStateCreatedSelected(
  //       guidesStateCreatedSelected
  //     );
  //   }
  //   if (guidesStateInWarehouseSelected && guidesStateInWarehouseSelected.length) {
  //     this.processGuidesStateInWarehouseSelected = this.listCargoFromGuidesService.processGuidesStateInWarehouseSelected(
  //       guidesStateInWarehouseSelected
  //     );
  //   }

  //   // forkJoin(
  //   //   this.googleService.getRouteDataCargo(this.processGuidesStateCreatedSelected),
  //   //   this.googleService.getRouteDataCargo(this.processGuidesStateInWarehouseSelected)
  //   // ).subscribe((results: any) => {

  //   //   const [promise1Result, promise2Result] = results;

  //   //   if (promise1Result) {
  //   //     this.processGuidesStateCreatedSelected.distancy = promise1Result.cargoDistancy;
  //   //     this.processGuidesStateCreatedSelected.estimatedTime = promise1Result.cargoEstimatedTime;
  //   //   }

  //   //   if (promise2Result) {
  //   //     this.processGuidesStateInWarehouseSelected.distancy = promise2Result.cargoDistancy;
  //   //     this.processGuidesStateInWarehouseSelected.estimatedTime = promise2Result.cargoEstimatedTime;
  //   //   }

  //   this.onCreateCargo();
  //   // });
  // }

  // onCreateCargo() {

  //   let dataCreate = {
  //     cargo: []
  //   };

  //   if (this.processGuidesStateCreatedSelected && this.processGuidesStateCreatedSelected.dateLoad) {
  //     dataCreate.cargo.push(this.processGuidesStateCreatedSelected);
  //   }

  //   if (this.processGuidesStateInWarehouseSelected && this.processGuidesStateInWarehouseSelected.dateLoad) {
  //     dataCreate.cargo.push(this.processGuidesStateInWarehouseSelected);
  //   }

  //   if (dataCreate.cargo.length) {
  //     this.listCargoFromGuidesService.createCargo(dataCreate).subscribe(
  //       (data: any) => {
  //         this.spinner.hide();
  //         this.clickButtonMessageCreateCargo(true);
  //         this.clearAllData();
  //         this.onChangeTypeGuideActive(this.typeGuideActive);
  //       },
  //       (error) => {
  //         this.spinner.hide();
  //         this.clickButtonMessageCreateCargo(false);
  //       }
  //     );
  //   }
  // }

}
