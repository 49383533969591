<aside class="cargo__list__payment col-md-12">
    <div class="cargo__list__payment--filter">
        <div *ngIf="hasOperations"
            [ngClass]="{'operations-div-inline':hasOperationsInline, 'operations-div-top':hasOperationsTop}">
            <app-list-operations [options]="optionsFilter" [typeList]="typeList" [itemsSelecteds]="itemsSelecteds"
                *ngIf="typeList !== 'loadsInNegotiation' && typeList !== 'cargoRequestNegotiation'"
                (refreshActualList)="refreshActualList()" (resetActualList)="resetActualList()"
                [filters]="filters"></app-list-operations>
        </div>
        <app-list-filter [options]="optionsFilter" [typeList]="typeList"
            *ngIf="typeList !== 'loadsInNegotiation' && typeList !== 'cargoRequestNegotiation'"
            (refreshList)="refreshList($event)"></app-list-filter>

        <div class="d-flex justify-content-end">
            <div class="list-select-all mr-2" select-item-list [list]="paginationList.getList()"
                (onSelectItem)="onSelectItem($event)" [active]="showSelectAll"
                *ngIf="showSelectAll && paginationList.getList().length" [type]="'selectAll'"
                [exclude]="excludeItemsList">
            </div>
        </div>
    </div>
    <div *ngIf="paginationList.getList().length" class="cargo__list__payment--body" infinite-scroll
        [infiniteScrollDistance]="paginationList.getScrollDistance()"
        [infiniteScrollThrottle]="paginationList.getThrottle()" [scrollWindow]="false" (scrolled)="onScrollDown()">
        <div class="col-md-12 mb-md-0 mb-12" *ngFor="let cargo of paginationList.getList(); let i = index;">
            <app-cargo-item [cargoItemList]="cargo" [typeList]="typeList" select-item-list
                [active]="isActiveSelectedItemCargo(cargo)" [item]="cargo" [list]="paginationList.getList()"
                (onSelectItem)="onSelectItem($event)" (applyFilter)="refreshList()"
                [travelExpense]="cargo?.travelExpense">
            </app-cargo-item>
        </div>
    </div>
    <div *ngIf="!paginationList.getList().length" class="container_no_results">
        <div class="icon"><i class="fas fa-calendar-times"></i></div>
        <h4 class="text-bold text-center">No hay resultados que coincidan con la búsqueda</h4>
    </div>
    <div class="my-1 d-flex justify-content-start">
        <div class="conv-btn" (click)="openConventions()">
            <i class="fas fa-circle"></i> Convenciones
        </div>
    </div>
</aside>