import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { InformationUser } from "./informationUser";
import { BankAccount } from "./userBank";
import { VehicleRtm } from "./vehicleRtm";
import { VechileSoat } from "./vehicleSoat";
import { VehicleState } from "./vehicleState";
import { VehicleType } from "./vehicleType";
import { Patterns } from "src/app/core/resources/patterns";
import { Gps } from "./gps";
import { BasicResponse } from "./basicResponse";
import { Fingerprint } from "./fingerprint";
import { GeneralType } from "./generalType";
import { VehicleExtraDocument } from "./vehicleExtraDocument";
import { Injectable } from "@angular/core";
import { DateManager } from "../managers/date.manager";
export interface Vehicle extends BasicResponse {
  holderIsExclusive?: boolean;
  registrationDate?: string; // Format 1993-12-16 05:00 +0000
  id?: string;
  owner?: InformationUser;
  driver?: InformationUser;
  administrator?: InformationUser;
  hasAdministrator?: boolean;
  extraImages?: Array<VehicleExtraDocument>;
  checkId?: string;
  state?: VehicleState;
  vehicleType?: VehicleType;
  repowered?: boolean;
  trailerId?: string;
  loadCapacity?: string;
  validSOAT?: VechileSoat;
  validRTM?: VehicleRtm;
  bankAccountAdvance?: BankAccount;
  bankAccountBalance?: BankAccount;
  companyId?: string;
  propertyCard?: string;
  frontPicture?: string;
  rightLateralPicture?: string;
  leftLateralPicture?: string;
  tenureContract?: string;
  extraDocuments?: string[];
  checked?: boolean;
  numberOfTrips?: number;
  municipalityCode?: string;
  city?: string;
  errorRNDC?: { error: string };
  gps?: Gps;
  holderCompany?: Array<string>;
  bodywork?: any;
  createdBy?: Fingerprint;
  picture?: string;
  lastInspection?: string;
  chassisId?: number;
  motorId?: number;
  brand?: GeneralType;
  manufacturingYear?: string;
  axles?: number;
  color?: string;
  fuelType?: GeneralType;
  lastUploadDate?: string;
  description?: string;
  validateTruora?: boolean;
  stateCompany?: VehicleState;
  ownFleet?: boolean;
  typeVehiclePath?: string;
  maintenanceExecutionDate?: string;
  preventiveExecutionDate?: string;
  preventiveExpirationDate?: string;
  correctiveExecutionDate?: string;
  correctiveExpirationDate?: string;
}

@Injectable()
export class Model {
  currentYear = DateManager.getYear();
  constructor(
    private patterns: Patterns,
  ) { }

  public model: Vehicle = {
    id: "",
    hasAdministrator: false,
    extraImages: [],
    owner: {
      documentTypeId: "",
      documentTypeName: "",
      document: "",
      name: "",
      municipalityCode: "",
      city: "",
      address: "",
    },
    driver: {
      documentTypeId: "",
      documentTypeName: "",
      document: "",
      name: "",
      municipalityCode: "",
      city: "",
      address: "",
    },
    administrator: {
      document: "",
      documentTypeId: "",
      documentTypeName: "",
      name: "",
      municipalityCode: "",
      city: "",
      address: "",
    },
    state: {
      active: true,
      description: "",
    },
    checkId: "",
    vehicleType: {
      name: "",
      quantity: 0,
    },
    tenureContract: "",
    repowered: false,
    extraDocuments: [],
    validSOAT: {
      description: "",
      expirationDate: "",
      active: true,
    },
    validRTM: {
      description: "",
      expirationDate: "",
      active: true,
    },
    gps: {
      gpsType: "",
      userGps: "",
      passwordGps: "",
      isPortableUnit: false,
      gpsId: "",
    },
    companyId: "",
    bankAccountAdvance: {
      address: "",
      licensePlate: "",
      document: "",
      email: "",
      phone: "",
      bank: {
        name: "",
        code: "",
        id: "",
        codeBancolombia: "",
      },
      accountType: "",
      accountTypeCode: "",
      accountNumber: "",
      certificate: "",
      rut: "",
      authorization: "",
      documentTypeId: "",
      documentTypeName: "",
      name: "",
      state: {
        active: false,
        date: "",
        description: "",
        userId: "",
      },
    },
    bankAccountBalance: {
      address: "",
      licensePlate: "",
      document: "",
      email: "",
      phone: "",
      bank: {
        name: "",
        code: "",
        id: "",
        codeBancolombia: "",
      },
      accountType: "",
      accountTypeCode: "",
      accountNumber: "",
      certificate: "",
      rut: "",
      authorization: "",
      documentTypeId: "",
      documentTypeName: "",
      name: "",
      state: {
        active: false,
        date: "",
        description: "",
        userId: "",
      },
    },
    ownFleet: false
  };

  public modelValidators = {
    bankAccountAdvance: {
      address: [
        Validators.required,
      ],
      document: [
        Validators.required,
        Validators.pattern(this.patterns.ONLY_NUMBERS.source),
        Validators.minLength(3),
        Validators.maxLength(10),
      ],
      email: [
        Validators.required,
        Validators.pattern(this.patterns.EMAIL.source),
        Validators.maxLength(100)
      ],
      phone: [
        Validators.required,
        Validators.pattern(this.patterns.CELLPHONE.source),
      ],
      bank: {
        name: [Validators.required],
        code: [Validators.required],
        id: [Validators.required],
        codeBancolombia: [Validators.required],
      },
      accountType: [Validators.required],
      accountTypeCode: [Validators.required],
      accountNumber: [
        Validators.required,
        Validators.pattern(this.patterns.ONLY_NUMBERS.source),
      ],
      certificate: [Validators.required],
      rut: [Validators.required],
      documentTypeId: [Validators.required],
      documentTypeName: [Validators.required],
      name: [Validators.required],
    },
    bankAccountBalance: {
      document: [
        Validators.required,
        Validators.pattern(this.patterns.ONLY_NUMBERS.source),
        Validators.minLength(3),
        Validators.maxLength(10),
      ],
      email: [
        Validators.required,
        Validators.pattern(this.patterns.EMAIL.source),
        Validators.maxLength(100)
      ],
      phone: [
        Validators.required,
        Validators.pattern(this.patterns.CELLPHONE.source),
      ],
      bank: {
        name: [Validators.required],
        code: [Validators.required],
        id: [Validators.required],
        codeBancolombia: [Validators.required],
      },
      accountType: [Validators.required],
      accountTypeCode: [Validators.required],
      accountNumber: [
        Validators.required,
        Validators.pattern(this.patterns.ONLY_NUMBERS.source),
      ],
      certificate: [Validators.required],
      rut: [Validators.required],
      documentTypeId: [Validators.required],
      documentTypeName: [Validators.required],
      name: [Validators.required],
      address: [
        Validators.required
      ]
    },
  };

  public modelCreate = {
    driver: {
      simpleRegimen: false,
      exclusive: false,
      nitCompany: "",
      email: "",
      phone: "",
      role: "",
      expeditionDateId: "",
      operationId: "",
      information: {
        documentTypeId: "",
        documentTypeName: "",
        document: "",
        name: "",
      },
      municipalityCode: "",
      city: "",
      address: "",
      profilePicture: "",
      frontDocument: "",
      backDocument: "",
      emergencyContact: {
        name: "",
        lastName: "",
        phone: "",
        remark: "",
        validated: false,
      },
      referencePersonal: {
        name: "",
        lastName: "",
        phone: "",
        remark: "",
        validated: false,
      },
      referenceLaboral: {
        name: "",
        phone: "",
        remark: "",
        validated: false,
      },
    },
    owner: {
      simpleRegimen: false,
      exclusive: false,
      nitCompany: "",
      phone: "",
      role: "",
      operationId: "",
      information: {
        documentTypeId: "",
        documentTypeName: "",
        document: "",
        name: "",
      },
      municipalityCode: "",
      city: "",
      address: "",
      profilePicture: "",
      frontDocument: "",
      backDocument: "",
    },
    administrator: {
      simpleRegimen: false,
      exclusive: false,
      phone: "",
      operationId: "",
      information: {
        documentTypeId: "",
        documentTypeName: "",
        document: "",
        name: "",
      },
      municipalityCode: "",
      city: "",
      address: "",
    },
    vehicle: {
      companyId: "",
      id: "",
      gps: {
        gpsType: "",
        userGps: "",
        passwordGps: "",
        isPortableUnit: false,
        gpsId: "",
      },
      propertyCard: "",
      rightLateralPicture: "",
      leftLateralPicture: "",
      frontPicture: "",
      extraImages: "",
      ownFleet: false,
    },
  };

  public vehicleCreationValidators = {
    vehicle: {
      companyId: [],
      id: [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(8),
        Validators.pattern(this.patterns.GET_REGEX('LICENSE_PLATES').source),
      ],
      propertyCard: [],
      rightLateralPicture: [],
      leftLateralPicture: [],
      frontPicture: [],
      gps: {
        gpsType: [],
        passwordGps: [],
        userGps: [],
      },
    },
    owner: {
      phone: [
        Validators.required,
        Validators.pattern(this.patterns.CELLPHONE.source),
        Validators.minLength(7),
        Validators.maxLength(12),
      ],
      information: {
        document: [
          Validators.required,
          Validators.pattern(this.patterns.ONLY_NUMBERS.source),
          Validators.minLength(3),
          Validators.maxLength(10),
        ],
        documentTypeId: [
          Validators.required,
          Validators.pattern(this.patterns.ONLY_NUMBERS.source),
        ],
        documentTypeName: [
          Validators.required,
          Validators.pattern(this.patterns.ALL_EXPRESSIONS.source),
        ],
        name: [
          Validators.required,
        ],
      },
    },
    administrator: {
      information: {
        document: [
          Validators.required,
          Validators.pattern(this.patterns.ONLY_NUMBERS.source),
          Validators.minLength(3),
          Validators.maxLength(10),
        ],
        documentTypeId: [
          Validators.required,
          Validators.pattern(this.patterns.ONLY_NUMBERS.source),
        ],
        documentTypeName: [
          Validators.required,
          Validators.pattern(this.patterns.ONLY_WORDS.source),
        ],
        name: [
          Validators.required,
        ],
      },
      address: [Validators.required],
      city: [Validators.required],
      municipalityCode: [Validators.required],
    },
    driver: {
      email: [
        Validators.pattern(this.patterns.EMAIL.source),
        Validators.maxLength(100)
      ],
      phone: [
        Validators.required,
        Validators.pattern(this.patterns.CELLPHONE.source),
        Validators.minLength(7),
        Validators.maxLength(12),
      ],
      expeditionDateId: [
        Validators.required,
        Validators.pattern(this.patterns.DATE_YYYY_MM_DD.source),
      ],
      information: {
        document: [
          Validators.required,
          Validators.pattern(this.patterns.ONLY_NUMBERS.source),
          Validators.minLength(3),
          Validators.maxLength(10),
        ],
        documentTypeId: [
          Validators.required,
          Validators.pattern(this.patterns.ONLY_NUMBERS.source),
        ],
        documentTypeName: [
          Validators.required,
          Validators.pattern(this.patterns.ALL_EXPRESSIONS.source),
        ],
        name: [
          Validators.required,
        ],
      },
      address: [Validators.required],
      city: [Validators.required],
      profilePicture: [
        // Validators.required
      ],
      emergencyContact: {
        /*name: [Validators.required],
        lastName: [Validators.required],
        phone: [
          Validators.required,
          Validators.pattern(this.patterns.PHONE.source),
        ],
        remark: [],
        validated: [],*/
      },
      referenceContact: {
        name: [Validators.required],
        lastName: [Validators.required],
        phone: [
          Validators.required,
          Validators.pattern(this.patterns.PHONE.source),
        ],
        remark: [],
        validated: [],
      },
      referencePersonal: {
        /*name: [Validators.required],
        lastName: [Validators.required],
        phone: [
          Validators.required,
          Validators.pattern(this.patterns.PHONE.source),
        ],*/
      },
      referenceLaboral: {
        /*name: [Validators.required],
        phone: [
          Validators.required,
          Validators.pattern(this.patterns.PHONE.source),
        ],
        remark: [],
        validated: [],*/
      },
    },
  };

  public trailerValidators = {
    id: [
      Validators.required,
      Validators.maxLength(7),
      Validators.minLength(6),
      Validators.pattern(this.patterns.GET_REGEX('TRAILER_PLATES').source),
    ],
    model: [
      Validators.required,
      Validators.max(this.currentYear),
      Validators.maxLength(4),
      Validators.minLength(4),
      Validators.pattern(this.patterns.ONLY_NUMBERS.source),
    ],
    emptyWeight: [
      Validators.required,
      Validators.min(3000),
      Validators.pattern(this.patterns.ONLY_NUMBERS.source),
    ],
    axles: [
      Validators.required,
      Validators.min(1),
      Validators.pattern(this.patterns.ONLY_NUMBERS.source),
    ],
    licensePlate: [
      Validators.required,
      Validators.maxLength(6),
      Validators.minLength(6),
    ],
    hasAdministrator: [Validators.required],
    configuration: {
      code: [
        Validators.required,
        Validators.pattern(this.patterns.ALL_EXPRESSIONS.source),
      ],
      description: [
        Validators.required,
        Validators.pattern(this.patterns.ALL_EXPRESSIONS.source),
      ],
    },
    brand: {
      code: [
        Validators.required,
        Validators.pattern(this.patterns.ALL_EXPRESSIONS.source),
      ],
      description: [
        Validators.required,
        Validators.pattern(this.patterns.ALL_EXPRESSIONS.source),
      ],
    },
    bodywork: {
      code: [
        Validators.required,
        Validators.pattern(this.patterns.ALL_EXPRESSIONS.source),
      ],
      description: [
        Validators.required,
        Validators.pattern(this.patterns.ALL_EXPRESSIONS.source),
      ],
    },
    owner: {
      document: [
        Validators.required,
        Validators.pattern(this.patterns.ONLY_NUMBERS.source),
        Validators.minLength(3),
        Validators.maxLength(10),
      ],
      documentTypeId: [
        Validators.required,
        Validators.pattern(this.patterns.ONLY_NUMBERS.source),
      ],
      documentTypeName: [
        Validators.required,
        Validators.pattern(this.patterns.ALL_EXPRESSIONS.source),
      ],
      name: [
        Validators.required,
        Validators.pattern(this.patterns.WORDS_POINTS.source),
      ],
    },
    administrator: {
      document: [
        Validators.required,
        Validators.pattern(this.patterns.ONLY_NUMBERS.source),
        Validators.minLength(3),
        Validators.maxLength(10),
      ],
      documentTypeId: [
        Validators.required,
        Validators.pattern(this.patterns.ONLY_NUMBERS.source),
      ],
      documentTypeName: [
        Validators.required,
        Validators.pattern(this.patterns.ALL_EXPRESSIONS.source),
      ],
      name: [
        Validators.required,
        Validators.pattern(this.patterns.WORDS_POINTS.source),
      ],
    },
    propertyCard: [Validators.required],
    picture: [Validators.required],
  };

  public trailer = {
    id: "",
    model: "",
    emptyWeight: "",
    axles: "",
    licensePlate: "",
    hasAdministrator: true,
    configuration: {
      code: "",
      description: "",
    },
    brand: {
      code: "",
      description: "",
    },
    bodywork: {
      code: "",
      description: "",
    },
    owner: {
      document: "",
      documentTypeId: "",
      documentTypeName: "",
      name: "",
      address: "",
      municipalityCode: "",
    },
    administrator: {
      document: "",
      documentTypeId: "",
      documentTypeName: "",
      name: "",
      address: "",
      municipalityCode: "",
    },
    propertyCard: "",
    picture: "",
  };
}
