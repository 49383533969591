<div class="create-driver-form">
    <mat-horizontal-stepper labelPosition="bottom" [linear]="true" #stepper class="create-driver-form__stepper read-only-stepper">
        <!-- Basic data step -->
        <mat-step>
            <ng-template matStepLabel>
                <span>Datos básicos</span>
            </ng-template>
            <app-person-vehicle 
                *ngIf="stepper?.selectedIndex === 0" 
                [form]="form" 
                [title]="'Conductor'" 
                [onlyRequiredFieldsInForm]="true" 
                [previewPrevizualizationSettings]="previsualizationSettings" 
                [typeAction]="'createDriver'" 
                (emitPreviewsResources)="setPreviewsResources($event)">
            </app-person-vehicle>
        </mat-step>
        <!-- References and emergency contact step -->
        <mat-step>
            <ng-template matStepLabel>
                <span>Referencias y contacto de emergencia</span>
            </ng-template>
            <app-vehicle-contacts 
                *ngIf="stepper?.selectedIndex === 1" 
                [form]="form" 
                [fromCreation]="false" 
                [typeAction]="'createDriver'"
                [onlyDriver]="true">
            </app-vehicle-contacts>
        </mat-step>
        <!-- Documents step -->
        <mat-step>
            <ng-template matStepLabel>
                <span>Documentos</span>
            </ng-template>
            <app-user-documents 
                *ngIf="stepper?.selectedIndex === 2"
                [form]="form" 
                [hideBtn]="true" 
                [previewPrevizualizationSettings]="previsualizationSettings" 
                (emitPreviewsResources)="setPreviewsResources($event)">
            </app-user-documents>
        </mat-step>
    </mat-horizontal-stepper>
    <div class="create-driver-form__actions button-container">
        <!-- Actions buttons -->
        <button
        *ngIf="stepper?.selectedIndex > 0"
        mat-raised-button
        color="primary"
        class="secondary-sharp button-width-auto text-bold create-driver-form__actions--button"
        (click)="stepper.previous()">
        <span>Atrás</span>    
        </button>
        <button
        mat-raised-button
        color="primary"
        type="button"
        class="bg-color-primary button-width-auto text-bold create-driver-form__actions--button"
        (click)="checkStep()">
        <span>Guardar y continuar</span>    
        </button>
    </div>
</div>