import { Component, OnInit, Inject, Input, EventEmitter, Output, SimpleChanges, NgZone, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, Form, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { AllowedCountriesEnum } from 'src/app/core/enums/allowedCountries.enum';
import { IntegrationEnum } from 'src/app/core/enums/integration.enum';
import { BasicCity } from 'src/app/core/interfaces/basicCity';
import { City } from 'src/app/core/interfaces/city';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { Utils } from 'src/app/core/resources/utils';
import { AuthService } from 'src/app/core/services/authentication.service';
import { CatalogService } from 'src/app/core/services/catalog.service';
import { CityService } from 'src/app/modules/administration/city/city.service';

@Component({
  selector: 'app-autocomplete-city',
  templateUrl: './autocomplete-city.component.html',
  styleUrls: ['./autocomplete-city.component.scss'],
  providers: [CityService]
})
export class AutocompleteCityComponent implements OnInit {
  @Input() options: OptionsAutocomplete = {};
  @Input() inputFormControl: FormControl;
  @Input() validate: string;
  @ViewChild("inputAutocomplete", { static: false }) inputAutocomplete: any;
  internationalCity = "";
  formControlCity: FormControl = new FormControl();
  listCities: Observable<City[]> | Observable<BasicCity[]>;
  constructor(
    private catalogService: CatalogService,
    private cityService: CityService,
    private ngZone: NgZone,
    private authService: AuthService,
    public utils: Utils,
    public cdRef: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    if (!!this.options.disabled) {
      this.options.disabled
        .subscribe((disabled: boolean) => { disabled ? this.formControlCity.disable() : this.formControlCity.enable() });
    }
    const validator = this.inputFormControl && this.inputFormControl.validator ? this.inputFormControl.validator({} as AbstractControl) : '';
    if (validator && validator.required) this.formControlCity.setValidators(Validators.required);
    const formControl = this.formControlCity;
    if (formControl) {
      this.initAutocomplete();
    }
  }

  ngAfterViewInit() {
    this.options && this.options.isInternational && this.initInternationalSearch(true);
    this.cdRef.detectChanges();
  }

  private initAutocomplete(): void {
    if (this.formControlCity) {
      this.listCities = this.formControlCity.valueChanges.pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap((data) => {
          try {
            if (typeof data === 'object') {
              if (data.name.trim().length >= 2) {
                return this.filterCities(data.name.trim() || '');
              } else if (data.city.trim().length >= 2) {
                return this.filterCities(data.city.trim() || '');
              }
            } else if (typeof data === 'string') {
              if (data.trim().length >= 2) {
                return this.filterCities(data.trim() || '');
              } else {
                return [];
              }
            } else {
              return [];
            }
          } catch (e) {
            return [];
          }
        })
      );
    }
  }

  changeValue() {
    if (this.inputFormControl) this.inputFormControl.setValue("");
  }

  cleanField() {
    if (this.inputFormControl) this.inputFormControl.setValue("clean");
    this.formControlCity.setValue('');
  }

  initInternationalSearch(isInternational: boolean) {
    if (isInternational) {
      const autocomplete = new google.maps.places.Autocomplete(
        this.inputAutocomplete.nativeElement,
        {
          types: ['(cities)'],
          componentRestrictions: { country: Object.values(AllowedCountriesEnum) },
        }
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          const place = autocomplete.getPlace();
          let country = place.address_components.length ? place.address_components.filter(add => add.short_name.length === 2)[0] : { short_name: "", long_name: "" };
          let data = {
            name: this.inputAutocomplete.nativeElement.value,
            code: country.short_name,
            country: country.long_name,
          }
          this.sendCityData(data);
        });
      });
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.options && changes.options.currentValue) {
        if (changes.options.currentValue.isInternational) {
          setTimeout(() => {
            this.initInternationalSearch(changes.options.currentValue.isInternational);
          }, 20);
          this.formControlCity.setValue('');
        }
        if (changes.options.currentValue.initialValue || changes.options.currentValue.initialValue === "") {
          if (this.options.isInternational) this.formControlCity.setValue(changes.options.currentValue.initialValue);
          else {
            if (this.inputFormControl) this.selectCityByParameter(changes.options.currentValue.initialValue, 'name')
          }
        }
        if (changes.options.currentValue.initialMunicipalityCode || changes.options.currentValue.initialMunicipalityCode === "") {
          if (this.inputFormControl) this.selectCityByParameter(changes.options.currentValue.initialMunicipalityCode, 'municipalityCode')
        }
      }

      if (changes.validate) {
        switch (this.validate) {
          case 'touched':
            this.formControlCity.markAsTouched();
            break;
          case 'untouched':
            this.formControlCity.markAsUntouched();
            break;
          case 'enable':
            this.formControlCity.enable();
            break;
          case 'disable':
            this.formControlCity.disable();
            break;
          case 'disable&untouched':
            this.formControlCity.markAsUntouched();
            this.formControlCity.disable();
            break;
          case 'setValidators':
            this.formControlCity.setValidators(Validators.required);
            this.formControlCity.updateValueAndValidity();
            break;
          case 'clearValidators':
            this.formControlCity.clearValidators();
            this.formControlCity.updateValueAndValidity();
            break;
          default:
            break;
        }
      }
    }

  }

  selectCityByParameter(parameter: string, type: string) {
    if (parameter && type) {
      this.filterCities(parameter, type)
        .subscribe(cities => {
          if (cities && cities.length) {
            cities = cities.filter((city) => (city.name && city.name.includes(parameter)) ||
              (city.id && (city.id.includes(parameter) || city.id.includes(this.options.initialId))));
            if (cities.length > 0) {
              this.inputFormControl.setValue(cities[0]);
              this.formControlCity.setValue(cities[0]);
            }
          }
        });
    }
    else {
      this.inputFormControl.setValue('');
      this.formControlCity.setValue('');
    }
  }

  private filterCities(value: string, type: string = 'name'): Observable<City[]> | Observable<BasicCity[]> {
    let cityService;
    if (this.options && this.options.requireFullCity) {
      cityService = type === 'name' ? 'getCityByName' : 'getCityByMunicipalityCode';
    } else {
      cityService = type === 'name' ? 'getBasicCityByName' : 'getBasicCityByMunicipalityCode';
    }
    return this.catalogService[cityService](value, 1, 5).pipe(
      map(response => {
        if (response) {
          return response;
        } else {
          return [];
        }
      })
    );
  }

  selectCity($event: MatAutocompleteSelectedEvent) {
    if (this.inputFormControl) this.inputFormControl.setValue($event.option.value);
  }

  sendCityData(city) {
    if (this.inputFormControl) {
      this.inputFormControl.setValue({
        name: city && city.name ? city.name : '',
        country: city && city.country ? city.country : '',
        code: city && city.code ? city.code : ''
      })
    }
  }

  displayCityName(city?: any): string | undefined {
    if (typeof city === 'string') {
      return city;
    } else if (typeof city === 'object' && city && city.name) {
      return city.name;
    }
    return '';
  }

  // cleanFields() {
  //   this.formControlCity.setValue('');
  //   this.emitToParent.emit({
  //     data: {
  //       id: null
  //     },
  //     options: null
  //   });
  // }

  public disableCity(city: City) {
    if ((this.options && !this.options.filterByMainCities) || !this.options || !this.options.requireFullCity) return false;
    else return !(!city.mainCity && city.reteica && (this.hasSiigoIntegration ? city.productSiigo && city.taxSiigo : true));
  }

  ngOnDestroy() {
    this.cdRef.detach();
  }

  get hasSiigoIntegration(): boolean {
    return this.authService.getCompany().integrationCredentials &&
      this.authService.getCompany().integrationCredentials.length &&
      this.authService.getCompany().integrationCredentials.some(integration => integration && integration.integrationChannel && integration.integrationChannel.toLowerCase() === IntegrationEnum.SIIGO.toLowerCase() && integration.state);
  }
}
