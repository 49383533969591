import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SnackBarService } from 'src/app/core/services/snackBar.service';

@Component({
  selector: 'app-previsualization-resources',
  templateUrl: './previsualization-resources.component.html',
  styleUrls: ['./previsualization-resources.component.scss']
})
export class PrevisualizationResourcesComponent implements OnInit {
  @Input() resource: File | {url: string, type: string} | string;
  @Input() errorLoadResource: boolean;
  srcToRender: {src: string | SafeResourceUrl, type: string, loaded: boolean};
  pathNoImage: string = "/assets/svg/icons/icon-no-file.svg";
  constructor(
    private snackBarService: SnackBarService,
    public domSanitizer: DomSanitizer
  ) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.resource && changes.resource.currentValue) this.getPreviewImage(changes.resource.currentValue);
  }

  getPreviewImage(file: File | {url: string, type: string}): void {
    if (file instanceof File) {
      const reader = new FileReader();
      reader.onload = () => {
        const fileUrl = URL.createObjectURL(file);
        let formatFile: string = file.name.split('.').reverse()[0].toLowerCase();
        this.srcToRender = {
          src: this.sanitizeUrl(fileUrl),
          type: formatFile === 'pdf' ? 'pdf' : 'image',
          loaded: false
        }
      };
      reader.readAsDataURL(file);
    } else {
       this.srcToRender = {
        src: file.url,
        type: file.type === 'pdf' ? 'pdf' : 'image',
        loaded: true
      }
    }
  }
  
  sanitizeUrl(url: string) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  onImageLoad(image: { src: string | SafeResourceUrl, type: string, loaded: boolean }) {
    image.loaded = true;
  }

  onErrorRenderImage($event: { srcElement: { src: string } }) {
    $event.srcElement.src = this.pathNoImage;
  }

  openPreviewResource(): void {
    if (this.srcToRender && this.srcToRender.src) {
      const sanitizedUrl = this.domSanitizer.sanitize(4, this.srcToRender.src); // 4 = SecurityContext.URL
      if (sanitizedUrl) {
        window.open(sanitizedUrl, '_blank', 'noopener,noreferrer');
      } else {
        this.snackBarService.openSnackBar(
          'URL no válida o insegura.',
          undefined,
          'error'
        );
      }
    } else {
      this.snackBarService.openSnackBar(
        'Error al cargar el recurso.',
        undefined,
        'error'
      );
    }
  }
}
