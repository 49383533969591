<div [ngClass]="{'disable-tracking': enableStateTrackingAddresses}" class="stepper-tracking">
  <div class="load-download-state-points-container" permission-display [module]="permission.cargo.module"
    [functionality]="permission.cargo.loadDownloadStatePoints">
    <h6 *ngIf="cargo" class="text-strong text-center color-secondary-dark">Estado puntos del servicio</h6>
    <mat-vertical-stepper [linear]="true">
      <mat-step *ngFor="let upload of listOrigins; let i = index;">
        <ng-template matStepLabel>
          <img *ngIf="showIconByAddress(upload)" src="/assets/svg/icons/icon-correct.svg" class="icon-step-done">
          <div *ngIf="!showIconByAddress(upload)" class="container-circle-step">
            <span class="circle-step"></span>
          </div>
          {{getDataByAddress(upload)}}
        </ng-template>
        <div class="font-primary-dark">
          Ciudad Origen {{cargo.cargoFeature.uploadDownload.origin.name}}
        </div>
        <span class="text-strong">
          <app-cargo-control-tracking class="float-right" (loadedEvidences)="onLoadedEvidences($event)"
            [typeAddress]="'Origin'" [index]="upload.id" [address]="upload" [cargo]="cargo">
          </app-cargo-control-tracking>
        </span>
        <div class="duration-time-label" *ngIf="cargo?.dateLoad && upload.state!=='Pickup load'">
          Fecha {{cargo.dateLoad | dateFormat}}
        </div>
        <div class="duration-time-label" *ngIf="cargo?.dateLoad && upload.state!=='Pickup load'">
          Hora {{upload.time}}
        </div>
        <div class="duration-time-label" *ngIf="upload.durationTime && upload.durationTime.startDate">Inicio:
          {{upload.durationTime.startDate | dateFormat}}</div>
        <div class="duration-time-label" *ngIf="upload.durationTime && upload.durationTime.endDate">Finalización:
          {{upload.durationTime.endDate | dateFormat}}</div>
        <div class="duration-time-label" *ngIf="upload.durationTime && upload.durationTime.duration">Duración:
          {{upload.durationTime.duration | millisecondsDigitalClock}}</div>
      </mat-step>
      <div *ngFor="let destination of listDestinations; let iD=index;">
        <mat-step *ngFor="let download of destination.addresses; let i = index;">
          <ng-template matStepLabel>
            <img *ngIf="showIconByAddress(download)" src="/assets/svg/icons/icon-correct.svg" class="icon-step-done">
            <div *ngIf="!showIconByAddress(download)" class="container-circle-step">
              <span class="circle-step"></span>
            </div>
            {{getDataByAddress(download)}}
          </ng-template>
          <div class="font-primary-dark">
            Ciudad Destino {{destination.name}}
          </div>
          <span class="text-strong">
            {{download.address}}
            <app-cargo-control-tracking class="float-right" (loadedEvidences)="onLoadedEvidences($event)"
              [typeAddress]="'Destination'" [parent]="destination.id" [index]="download.id" [address]="download"
              [cargo]="cargo" [driver]="driver" [vehicle]="vehicle"></app-cargo-control-tracking>
          </span>
          <div class="duration-time-label" *ngIf="destination?.downloadDate && download.state!=='Cargo unloaded'">
            Fecha pactada: {{destination.downloadDate | date:'y-MM-dd'}}
            <div *ngIf="download?.time">
              {{download.time}} horas
            </div>
          </div>
          <div class="duration-time-label" *ngIf="download.durationTime && download.durationTime.startDate">
            Inicio: {{download.durationTime.startDate | dateFormat}}</div>
          <div class="duration-time-label" *ngIf="download.durationTime && download.durationTime.endDate">
            Finalización: {{download.durationTime.endDate | dateFormat}}</div>
          <div class="duration-time-label" *ngIf="download.durationTime && download.durationTime.duration">
            Duración: {{download.durationTime.duration | millisecondsDigitalClock}}
          </div>
          <div class="consignment-error" *ngIf="download.ministryError && download.ministryError.error">
            <i class="fas fa-triangle-exclamation"></i> {{download.ministryError.error}}
          </div>

        </mat-step>
      </div>
    </mat-vertical-stepper>
  </div>

  <div class="tracking-reports-container" *ngIf="historyNoveltyPoints.length" permission-display
    [module]="permission.cargo.module" [functionality]="permission.cargo.trackingReports">
    <div>
      <h6 class="text-strong text-center color-secondary-dark mb-2">
        Reportes de Seguimiento
      </h6> 
      <app-filter-tracking-reports [tripAnomalies]="historyNoveltyPoints" (emitFilteredHistoryNoveltyPoints)="filteredHistoryNoveltyPoints = $event"></app-filter-tracking-reports>
    </div>
    <div *ngIf="isSomeNoveltyUserPoint" class="d-flex justify-content-center flex-wrap">
      <mat-checkbox *ngFor="let userName of noveltyUsers" [(ngModel)]="userName.checked" [matTooltip]="userName.alias"
        class="mx-3 my-1">
        <img [src]="userName.icon" class="checkbox-icon mr-1"><span class="d-none d-md-inline">{{userName.alias}}</span>
      </mat-checkbox>
    </div>
    <mat-vertical-stepper [linear]="true">
      <div *ngFor="let history of filteredHistoryNoveltyPoints">
        <mat-step *ngIf="isUserNameChecked(history.data?.fingerprint?.userName)">
          <ng-template matStepLabel>
            <img [src]="getNoveltyIcon(history.data?.fingerprint?.userName)" class="icon-step-done">
            {{history.data.anomaly}}
          </ng-template>
          <div class="duration-time-label" *ngIf="history.data?.observation">
            <b>Observaciones:</b> {{history.data?.observation}}
          </div>
          <div class="duration-time-label" *ngIf="history.data?.fingerprint?.date">
            <b>Fecha:</b> {{history.data?.fingerprint.date | dateFormat}}
          </div>
          <div class="duration-time-label" *ngIf="history.data?.fingerprint?.userName">
            <b>Usuario:</b> {{history.data.fingerprint.userName}}
          </div>
        </mat-step>
      </div>
    </mat-vertical-stepper>
  </div>
</div>