import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { MaintenanceDetail } from 'src/app/core/interfaces/maintenance-detail';
import { Media } from 'src/app/core/interfaces/media';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { MaintenanceService } from '../maintenance.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { Fmt } from 'src/app/core/messages/fmt';

@Component({
  selector: 'app-maintenance-evidences',
  templateUrl: './maintenance-evidences.component.html',
  styleUrls: ['./maintenance-evidences.component.scss']
})
export class MaintenanceEvidencesComponent implements OnInit {
  maintenancePath: string = '';
  maintenance: MaintenanceDetail;
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogParams: { maintenance: MaintenanceDetail },
    public dialogRef: MatDialogRef<MaintenanceEvidencesComponent>,
    private spinner: NgxSpinnerService,
    private snackbarService: SnackBarService,
    private maintenanceService: MaintenanceService
  ) { }

  ngOnInit() {

    if (this.dialogParams && this.dialogParams.maintenance) this.maintenance = this.dialogParams.maintenance;
    if (this.dialogParams && this.dialogParams.maintenance && this.dialogParams.maintenance.licensePlate) this.maintenancePath = `maintenances/evidences/${this.dialogParams.maintenance.licensePlate}/maintenanceEvidence`;
  }

  processPaths($event: { result: { path: string }[], type: string }) {
    if ($event) {
      if ($event.type === 'add') {
        if (this.maintenance && this.maintenance.evidence && this.maintenance.evidence.length) this.maintenance.evidence = [...$event.result, ...this.maintenance.evidence];
        else this.maintenance.evidence = $event.result;
      } else {
        $event.result.forEach((file) => {
          const index = this.maintenance.evidence.findIndex(evidence => evidence.path === file.path);
          if (index !== -1) this.maintenance.evidence.splice(index, 1);
        });
      }
      this.updateMaintenance($event.type);
    }
  }

  updateMaintenance(type: string) {
    const updateMaintenanceObserver = {
      next: (data: MaintenanceDetail) => {
        this.spinner.hide();
        if (data) {
          if (type) {
            if (type === 'add') this.dialogRef.close({ state: true });
            this.snackbarService.openSnackBar(Fmt.string(ServiceMessages.EVIDENCE_SUCCESS_DELETE, type === 'add' ? 'agregada(s)' : 'eliminada(s)'), undefined, 'success');
          } else {
            this.snackbarService.openSnackBar(Fmt.string(ServiceMessages.SUCCESS_CREATE_EDIT_MAINTENANCE, 'editado'), undefined, 'success');
          }

        }
        else this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      },
      error: (error) => {
        this.spinner.hide();
        this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    };
    this.spinner.show();
    this.maintenanceService.updateMaintenanceVehicle(this.maintenance.id, this.maintenance).subscribe(updateMaintenanceObserver)
  }



}
