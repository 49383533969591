import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material';
import { Permission } from 'src/app/core/resources/permission';
import { ServiceRequestsStates } from 'src/app/core/resources/serviceRequestStates';
@Component({
  selector: 'app-service-requests-tabs',
  templateUrl: './service-requests-tabs.component.html',
  styleUrls: ['./service-requests-tabs.component.scss']
})
export class ServiceRequestsTabsComponent implements OnInit {
  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;
  selectedTab: number = 0;
  activeTab: string = 'Asignadas';
  permission = Permission;
  serviceRequestsTabs = ServiceRequestsStates;
  constructor() { }

  ngOnInit() {
    if (sessionStorage.getItem("_activeTab")) {
      let storageTab = sessionStorage.getItem("_activeTab");
      if (storageTab && this.serviceRequestsTabs[storageTab]) {
        let index = Object.keys(this.serviceRequestsTabs).indexOf(storageTab);
        if (index !== -1) this.tabGroup.selectedIndex = index;
        this.activeTab = this.serviceRequestsTabs[storageTab];
      }
    }
  }
  asIsOrder() {
    return 1;
  }
  onSelectTab($event: number) {
    const selectedIndex = $event;
    this.activeTab = this.tabGroup && this.tabGroup._tabs && this.tabGroup._tabs.toArray()[selectedIndex] && this.tabGroup._tabs.toArray()[selectedIndex].textLabel ? this.tabGroup._tabs.toArray()[selectedIndex].textLabel : '';
  }

}
